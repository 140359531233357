/* eslint-disable react-hooks/exhaustive-deps */
import { Loading3QuartersOutlined, MoreOutlined } from "@ant-design/icons";
import { Table, Typography, Select, Space, Input, Button, Form, message, Modal, Drawer, Dropdown, Menu, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Moment from "react-moment";
import { getPlanList } from "store/actions/revenueAction";
import sdk from "sdk/Accounts";
import { companySize, emailPattern, industries } from "utility/Constants";

const Companies = (props) => {
  const [Companies, setCompanies] = useState([]);
  const [SearchParams, setSearchParams] = useState({});
  const [TableLoading, setTableLoading] = useState(false);
  const [SearchOption, setSearchOption] = useState("Name");
  // const [PlanOption, setSelectPlan] = useState("Name");
  const [loading, setLoading] = useState(false);
  const [addCompanyModelShow, setaddCompanyModelShow] = useState(false);
  const [inviteModelShow, setinviteModelShow] = useState(false);
  const [compniesBackup, setcompniesBackup] = useState([])
  const [inviteform] = Form.useForm();
  const [addcompanyform] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const [inviteData,setinviteData] = useState()
  // eslint-disable-next-line no-unused-vars
  const [companyToSusupend, setCompanyToSusupend] = useState({})
  const [isCompanySuspendModelOpen, setIsCompanysuspendModelOpen] = useState(false);
  const [companySuspendLoading, setCompanySuspendLoading] = useState(false);
  const [nextToken, setnextToken] = useState(null)
  const dispatch = useDispatch()
  const [searchForm] = Form.useForm();

  const [getCompanyDataLoader, setgetCompanyDataLoader] = useState(true)

  const {setUsersFilter}  =  props

  useEffect(() => {
    setgetCompanyDataLoader(true)
    getCompanyData().then(res=> {
      // setgetCompanyDataLoader(false)
    });
    fetchPlans(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 
  
  const getCompanyData = async () => { 
    Companies?.length > 0 && setTableLoading(true)

    try {
      if (!(Companies?.length > 0 && !nextToken)) {
        let response = sdk.fetchCompanies(nextToken)
        response.then((companyList) => {
          setCompanies(olddata => olddata.concat(companyList?.data?.listAccounts?.items))
          setcompniesBackup(olddata => olddata.concat(companyList?.data?.listAccounts?.items))
          setnextToken(companyList?.data?.listAccounts?.nextToken)
          setTableLoading(false)
          setgetCompanyDataLoader(false)
          return companyList.data;


        }).catch((e) => {
          console.log(e);
          setTableLoading(false)
          setgetCompanyDataLoader(false)
          return { errors: ["Failed retrieving account"] };
        })
      }
      else {
        setTableLoading(false)
        setgetCompanyDataLoader(false)
      }
    } catch (e) {
      console.log(e);
      setgetCompanyDataLoader(false)
      return { errors: ["Failed retrieving account"] };
    }
  }

  const handleCompanySuspend = async () => {
    if (companyToSusupend) {
      
      setCompanySuspendLoading(true)
      let companyId = companyToSusupend?.id
      let suspend = companyToSusupend?.suspend   
      let result = await sdk.companySuspension(companyId, suspend);
      if (result.error) {
        message.error(result.error, 5);
      } else {
        message.success(
          suspend? "Company suspended successfully" : "Company unsuspended successfully",
          5
        );
      }
    }
    setIsCompanysuspendModelOpen(false)
    setCompanySuspendLoading(false)
    getCompanyData()
    }

    const handleSearchCompany = (data) => {
      setUsersFilter({
        Name: 'Company',
        Value: data?.company_loweredcased ? String(data?.company_loweredcased).toLowerCase() : "",
      })
    }

  const columns = [
    {
      title: "Id",
      width:"25%",
      dataIndex: "GroupName",
      key: "name",
      align: "center",
      render: (name, record) => <small>{record?.id}</small>,
      sorter: (a, b) => {String(a?.id).localeCompare(b?.id)},
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "Name",
      width:"15%",
      dataIndex: "Description",
      key: "Description",
      render: (Description, record) => {
        return (
        <>
          <small>
          {record?.company}
        </small>
        </>
        );
      },
      sorter: (a, b) => String(a?.company).localeCompare(b?.company),
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "View As",
      width:"25%",
      dataIndex: "",
      key: "viewas",
      render: (Description, record) => {
        return (
        <>
          <small title = "Click here to select company for admin view" className = "company-name" onClick={() => props.selectCompany(record,'admin')}>
         Admin
        </small> <span style = {{fontSize:12}}></span> | {" "}
          <small title = "Click here to select company for user view" className = "company-name" onClick={() => props.selectCompany(record, 'companyadmin')}>
          Company Admin 
            </small>
            <span style = {{fontSize:12}}></span> | {" "}
          <small title = "Click here to select company for user view" className = "company-name" onClick={() => props.selectCompany(record, 'companycontributor')}>
          Company Contributor
            </small>
            
        </>
        );
      },
      // sorter: (a, b) => String(a.company).localeCompare(b.company),
      // sortDirections: ["ascend", "descend", "ascend"],
      // showSorterTooltip: false,
    },
    {
      title: "Industry",
      width:"15%",
      dataIndex: "industry",
      key: "industry",
      render: (Description, record) => {
        return (
        <>
          <small>
          {record?.industry ? record?.industry : "-"}
        </small>
        </>
        );
      },
      sorter: (a, b) => String(a?.industry).localeCompare(b?.industry),
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "Company Size",
      width:"13%",
      dataIndex: "company_size",
      key: "company_size",
      render: (Description, record) => {
        return (
        <>
          <small>
          {record?.company_size ? record?.company_size : "-"}
        </small>
        </>
        );
      },
      sorter: (a, b) => String(a?.company_size).localeCompare(b?.company_size),
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "Plan",
      width:"10%",
      dataIndex: "plan",
      key: "plan",
      render: (Description, record) => {
        return (
        <>
          <small>
          {record?.plan?.name ? record?.plan?.name : "-"}
        </small>
        </>
        );
      },
      sorter: (a, b) => String(a?.company_size).localeCompare(b?.company_size),
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "Last modified",
      width:"15%",
      dataIndex: "updatedAt",
      key: "last_modified",
      render: (modifyDate) => (
        <small>
          <Moment date={modifyDate} format="lll" />
        </small>
      ),
      sorter: (a, b) => {
        return String(a?.updatedAt).localeCompare(b?.updatedAt);
      },
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Users",
      width:"8%",
      dataIndex: "users",
      key: "users",
      render: (data,record) => (
        <small>
          <small className = "company-name" onClick={() => handleSearchCompany(record)}>{data?.length}</small>
        </small>
      ),
      sorter: (a, b) => {
        return String(a?.users?.length).localeCompare(b?.users?.length);
      },
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "",
      width:"3%",
      dataIndex: "id",
      key: "actions",
      render: (id, record) => <div style={{display:'flex', justifyContent:"center"}}><Dropdown
      trigger={["click"]}
      overlay={
        <Menu>
          <Menu.Item
            key="delete Company"
            onClick={() => {

              let temp = {id: id}
              setcompanyToDelete(temp)

            }}
          >

              <strong>Delete Company</strong>

          </Menu.Item>
        </Menu>
      }
    >
      <MoreOutlined style={{ fontSize: "25px" }} />
    </Dropdown></div>,
    }
  ];

  const handle_search = (value) => {
    setCompanies(compniesBackup);
    setSearchParams({
      Name: value["search_type"],
      Value: value["search_value"] ? value["search_value"] : "",
    });
  };

  useEffect(() => {
    searchCompany(SearchParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchParams]);

  useEffect(() => {
    searchForm.setFieldsValue({ search_value: null });
  }, [SearchOption])

  const sortingOrder = (a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  };
  const [plans, setplans] = useState([])
  const fetchPlans = async (force = false) =>{
    if(plans?.length === 0 || force){
      let response = dispatch(getPlanList())
      response.then(data => {
        data.sort(sortingOrder)
        setplans(data)
      }).catch(err => {
        console.log("Error : ",err);
      })
    }
  }

  const searchCompany = async (SearchParams) => {
      await setTableLoading(true)
      let tempNextToken = null
    if (Object.keys(SearchParams).length > 0) {
      if (String(SearchParams.Value).length === 0) {
        setCompanies(compniesBackup)
        setTableLoading(false)
      }
      else {
        let filter = {}
        if (SearchParams.Name === "Name") {
          filter['company_loweredcased'] = { contains: String(SearchParams.Value).toLowerCase() }
        }
        else  {
          filter[SearchParams.Name] = { contains: SearchParams.Value }
        }

        setCompanies([])
        do {
          let companyList = await sdk.fetchCompanies(tempNextToken, filter)
            setCompanies(olddata => olddata.concat(companyList?.data?.listAccounts?.items))
        tempNextToken = companyList?.data?.listAccounts?.nextToken;
          if (!tempNextToken) {
            await setTableLoading(false)
        }
          
        }while(tempNextToken)
      }
      
    }
    
  }

  
  const addCompany = () => {
    setaddCompanyModelShow(true)
  }
  
  const handleSubmit = async (e) => {
    setLoading(true);
    let result = await sdk.createAccount(e.company, e.url, [], true,  e.industry, e.company_size);
    if (result.error) {
      message.error(result.error, 5);
    } else {
      message.success(
        `Company added successfully`,
        5
      );
      addcompanyform.resetFields()
    }
    getCompanyData();
    setLoading(false);
    setaddCompanyModelShow(false)

  };
  
  const sendInvitation = async (value) => {
    value.account_id = inviteData.account_id
    value.name = inviteData.name
      let result = await sdk.sendEmailInvitation(value);
  
      if (result.error) {
        message.error(result.error, 5);
        setLoading(false);
      } else {
        setLoading(false);
        inviteform.resetFields();
        message.success(`Invitation sent successfully.`, 5);
      }
      setinviteModelShow(false)
  }
  const onInviteSend = () => {
    inviteform
      .validateFields()
      .then((values) => {
        const formatedValues = {
          ...values,
        };
        sendInvitation(formatedValues);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const validateEmail = (e) => {
    const email_pattern = emailPattern
    if (e.length > 0) {
      const found = e[e.length - 1].match(email_pattern)
      if (!found) {
        e.pop()
        message.error("Please enter valid email")
      }
      else {
        e[e.length - 1] = e[e.length - 1].toLowerCase()
      }
    }
  }
  function itemRender(current, type, originalElement) {
    if (type === 'prev') {
      return  <Button className="nextbtn">{"<"}</Button>;
    }
    if (type === 'next') {
      return  <Button disabled={Companies?.length > 0 && !nextToken} className="nextbtn">{">"}</Button>;
    }
    return originalElement;
  }
  
  const paginationChanged = (currentPage) => {
    if (parseInt((Companies.length / 10).toFixed(0)) === currentPage) {
      if (nextToken) {
        getCompanyData()
      }
    }
  }

  const [companyToDelete, setcompanyToDelete] = useState({})
  const handleDeleteCompany = async () =>{
    if(companyToDelete){
      setTableLoading(true)
          let result = await sdk.DeleteCompanyAccount(companyToDelete)

          if (result && result?.error) {
            message.error(result?.error, 5);
            setcompanyToDelete({})
          } else {
            message.success("Company Deleted Successfully");
            setcompanyToDelete({})
          }

      setTableLoading(false)
      setnextToken(null)
      setCompanies([])
      getCompanyData()

      }
  }

  return (
    <>
      <div className="user-container" style={{marginTop:"20px"}}>
      {/* { TableLoading && <div className="loading">Loading;</div>} */}
        <Space direction="vertical" style={{ width: "100%" }} size={1} >
          <Typography.Title level={5} style={{
                marginBottom: "30px",
                fontSize: "18px",
                background: "#f5f7fa",
                padding: "10px 5px",
                textAlign: "center",
                width:"100%",
                display:"block",
                color:"#1D2939",
                fontWeight:500
              }}
              className="titleText fw500 panel-design">Company Management</Typography.Title>

          <div className="componiesForm" style={{display:"flex", justifyContent:"space-between"}}>
          <Form
            name="search_Companies"
            onFinish={handle_search}
            initialValues={{ search_type: "Name" }}
            form={searchForm}
          >
            <Space direction="horizontal" style={{ padding: "0px 5px" }}>
              <Form.Item name="search_type">
                <Select
                  style={{ width: "125px" }}
                  size="large"
                  onSelect={(e) => setSearchOption(e)}
                >
                  <Select.Option key="Name">Name</Select.Option>

                  <Select.Option key="id">Id</Select.Option>
                  <Select.Option key="industry">Industry</Select.Option>
                  <Select.Option key="planID">Plan</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="search_value">
                
                {SearchOption === "planID" ?<Select
                  style={{ width: "206px" }}
                  size="large"
                  placeholder = "Select Plan"
                  key="search_value"
                  initialValue= ""
                >
                    <Select.Option value="" key="">All</Select.Option>
                    
                    {
                       // eslint-disable-next-line
                      plans?.map((val) => {
                      if (val?.listed) {
                        return (
                          <Select.Option key={val?.id}>{val?.name}</Select.Option>
                        );
                      }
                  })}
                </Select>:<Input placeholder={`Enter ${SearchOption}`} size="large" key="search_value" className="general-input"/>}

              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" size="middle" className="secondaryButton" style={{width:"120px"}} disabled={TableLoading}>
                  Search
                </Button>
              </Form.Item>

            </Space>
            </Form>
            <div>
        {TableLoading && <Loading3QuartersOutlined spin style={{fontSize:22,marginRight:20}}/>}
            <Button type="primary" size="middle" onClick={addCompany} className="primaryButton">
                  Add company
            </Button>
            </div>
          </div>
          <div className="table-wrapper table-scroll">
            {getCompanyDataLoader ? <Skeleton active paragraph={{rows:7}} /> : 
            <Table
              bordered
              size="middle"
              dataSource={Companies}
              columns={columns}
              className="adminTable adminCompanyTable general-table"
              // eslint-disable-next-line no-func-assign
              pagination={{ itemRender, onChange: paginationChanged }}
            ></Table>
          }
          </div>
        </Space>
      </div>
      
      {/* add company modal */}
        <Drawer visible={addCompanyModelShow}
        closable={true}
        title={<strong>Add Company</strong>}
        bodyStyle={{ paddingBottom: 80 }}
        width={416}
        onClose={() => {setaddCompanyModelShow(false); addcompanyform.resetFields()}}
        className='AddCompany'
        >

            <Form
              labelCol={{ xs: 24 }}
              requiredMark={false}
              layout="vertical"
              name="new_account"
              className="login-form"
              onFinish={(e) => handleSubmit(e)}
              form={addcompanyform}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                <Form.Item
                  name="company"
				  style={{
                  marginTop: "0px",
                  marginBottom: "12px"
                }}
                label={<small><strong>Company name</strong></small>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the name of your company",
                    },
                    {
                      pattern: /(^[a-zA-Z0-9]{1})([a-zA-Z0-9& .]+)$/,
                      message: "Please enter valid company name",
                    },
                  ]}
                >
                  <Input
                    size="middle"
                    id="company"
                    key="company"
                    name="company"
                    placeholder="Company Name"
                    disabled={loading}
                    className="general-input"
                  />
                </Form.Item>
                <Form.Item
                  name="url"
                  label={<small><strong>Website</strong></small>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the URL of your company",
                    },
                    {
                      pattern:
                        /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                      message: "Please enter valid URL",
                    },
                  ]}
                >
                  <Input
                    size="middle"
                    id="url"
                    key="url"
                    name="url"
                    placeholder="Company Website"
                    disabled={loading}
                    className="general-input"
                  />
                </Form.Item>

                <Form.Item
                  name="industry"
                  label={<small><strong>Industry</strong></small>}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Industry",
                    },
                  ]}
                 
                >
                  <Select placeholder="Select company industry" showSearch size="large" 
                   className="add-company-drawer general-input-select"
                    disabled={loading}

                    // tabIndex={3}
                    >
                  {industries.map(item => {
                    return <Select.Option value={item} key={item}>{item}</Select.Option>
                  })}
                  
                  </Select>
                </Form.Item>

                <Form.Item
                  name="company_size"
                  label={<small><strong>Company size</strong></small>}
                  rules={[
                    {
                      required: true,
                      message: "Please Select company size",
                    },
                  ]}
                >
                  <Select placeholder="Select Company Size" showSearch size="large" 
                  className="add-company-drawer general-input-select"
                    disabled={loading}
                    >
                  {companySize.map(item => {
                    return <Select.Option value={item} key={item}>{item}</Select.Option>
                  })}
                  </Select>
                  
                </Form.Item>


              </Space>
            <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
            display:'flex',
            alignItems:"center",
            justifyContent:'center'

          }}
        >
          <Button  className="secondaryButton" onClick={() => {setaddCompanyModelShow(false);addcompanyform.resetFields()}} style={{ fontSize: 18, marginRight: 8, width: "48%" }}>
            Cancel
          </Button>
          <Button  htmlType="submit" className="primaryButton" type="primary" style={{ fontSize: 18, width: "48%" }} loading={loading}>
            Save
          </Button>
        </div>
            </Form>
         </Drawer>
      {/* Invitation Model */}
      <Modal
        title="Add email address to send invitation"
        visible={inviteModelShow}
        className="invite-dialogue"
        footer={null}
        closable={false}
      >
        <Form form={inviteform} className="invite-modal">
          <Form.Item
            name="emails"
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              tokenSeparators={[","]}
              notFoundContent=""
              onChange={validateEmail}
              placeholder="Please enter email address"
            >
              {/* {children} */}
            </Select>
          </Form.Item>
        </Form>
        <div style={{paddingTop: "35px"}} >
        <Button
          className="secondary-btn"
          onClick={()=>{setinviteModelShow(false)}}
          style={{ fontSize: 18, marginRight: 8, width: "48%" }}
        >
          Cancel
        </Button>
        <Button
          onClick={onInviteSend}
          type="primary"
          style={{ fontSize: 18, width: "50%" }}
        >
          Send
        </Button>
        </div>
      </Modal>

      <Modal
          visible={isCompanySuspendModelOpen}
          closable={false}
          centered
          footer={[]}
          style={{ borderRadius: "20px" }}
        >
          <div className="delete-modal-body">
            <Typography.Text style={{ marginTop: "15px", fontSize: "20px", marginBottom:"15px" }}>
              {companyToSusupend.suspend ? "Are you sure you want to suspend the company?" : "Are you sure you want to unsuspend the company?"}
            </Typography.Text>
            <div className="delete-modal-footer">
              <Button
                type="primary"
                size="small"
                ghost
                onClick={() => {
                  handleCompanySuspend();
                }}
                style={{ borderColor: "#0F61DB", color: "#0F61DB" }}
                loading={companySuspendLoading}
              >
                Yes
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={() => setIsCompanysuspendModelOpen(false)}
              >
                No
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          visible={Object.keys(companyToDelete)?.length > 0}
          closable={false}
          centered
          footer={[]}
          style={{ borderRadius: "20px" }}
        >
          <div className="delete-modal-body">
            <Typography.Text style={{ marginTop: "15px", fontSize: "20px", marginBottom:"15px" }}>
              Are you sure you want to delete the company?
            </Typography.Text>
            <div className="delete-modal-footer">
              <Button
                type="primary"
                size="small"
                ghost
                onClick={() => {
                  handleDeleteCompany();
                }}
                style={{ borderColor: "#0F61DB", color: "#0F61DB" }}
                loading={TableLoading}
              >
                Yes
              </Button>
              <Button
                size="large"
                type="primary"
                disabled={TableLoading}
                onClick={() => setcompanyToDelete({})}
              >
                No
              </Button>
            </div>
          </div>
        </Modal>
    </>
  );
};

export default Companies;
