/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Row,
  Tooltip,
  Typography,
  Button,
  message,
  Form,
  Table,
  Radio,
  Dropdown,
  Menu,
  Modal,
  Skeleton,
  Drawer,
  Space,
  Collapse,
  Select,
  Input,
  Checkbox,
} from "antd";
import RevenueTextField from "commons/RevenueTextField";
import {
  createFunnelConfiguration,
  createFunnelStages,
  getCalculationMetrics,
  setFunnelStagesList,
  updateBaseLineMetric,
  updateFunnelConfiguration,
  updateFunnelStages,
  getBaselineMetricData,
  setBaseLineData,
  getFiscalYear,
  deleteBaselineMetric,
  deleteFunnelStages,
  deleteFunnelConfig,
  getBaseLineMetricList,
  getBaselineMetricMultipleData,
  getFunnelConfigList,
} from "store/actions/revenueAction";
import { API } from "aws-amplify";
import Title from "antd/lib/typography/Title";
import { numberWithCommas } from "utility/context/CommonMethods";
import totalWonCountImg from "../../assets/Images/total_won_count.svg";
import marketingSourcedImg from "../../assets/Images/marketing_sourced.svg";
import salesSourcedImg from "../../assets/Images/sales_sourced.svg";
import rightArrowImg from "../../assets/Images/right_arrow.svg";
import CreateBaseLineMetrics from "./CreateBaseLineMetrics";
import Text from "antd/lib/typography/Text";
import settingsImg from "assets/Images/table_settings.svg";
import saveConversationIcon from "./../../assets/Images/save_conversation.svg";
import resetConversationIcon from "./../../assets/Images/reset_conversation.svg";
import verticalCollapseImg from "assets/Images/vertical_collapse.svg";
import verticalExpandImg from "assets/Images/vertical_expand.svg";

import ResetIcon from "assets/Images/reset_icon.svg";
import SaveIconGrey from "assets/Images/save_icon_grey.svg";
import binIcon from "../../assets/Images/bin_icon.svg";
import WarningIcon from "../../assets/Images/warning_star.svg";
import {
  InfoCircleOutlined,
  LinkOutlined,
  LoadingOutlined,
  EditOutlined,
  ApiOutlined,
} from "@ant-design/icons";
import "./RevenuePlanning.less";
import { getConnectedCRMs } from "store/actions/reportAction";
import SalesforceImg from "assets/Images/Social/Salesforce.png";
import HubspotImg from "assets/Images/Social/hubspot.jpg";
import {TrackClickFunction} from '../../commons/HeapTrack/TrackClickFunction'

function BaselineCompanyMetrics(props) {
  const { getFiscalYearListLoader } = props;
  const [addQuarterlyGoals, setaddQuarterlyGoals] = useState(false);
  const [
    CreateBaselineMetricsDrawerVisible,
    setCreateBaselineMetricsDrawerVisible,
  ] = useState(false);
  const [isEditModalOpen, setisEditModalOpen] = useState(false);
  const [summaryTableData, setsummaryTableData] = useState([]);
  const [selectedRowKeys, setselectedRowKeys] = useState([]);

 

  const [defaultSelectedKey, setDefaultSelectedKey] = useState(null);


  const [multiSelectedKey, setMultiSelectedKey] = useState(false);

  const [baseLineDatatoEdit, setbaseLineDatatoEdit] = useState();
  const [isDeleteMetricsModelOpen, setisDeleteMetricsModelOpen] =
    useState(false);
  const [metrictodelete, setmetrictodelete] = useState();
  const [MarketingSourced, setMarketingSourced] = useState(false);
  const [metricDrawer, setMetricDrawer] = useState(false);
  const { Panel } = Collapse;
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const revenueData = useSelector((state) => state.revenueReducer);
  const { userData, viewType } = useSelector((state) => state.loginReducer);

  const { email } = userData;
  const account = useSelector((state) => state.loginReducer.groups);
  const {
    newRevenueGoal,
    marketingSourced,
    averageDealSize,
    baseLineData,
    funnelConfiguration,
    funnelStages,
    marketingSourcedCount,
    saleSourcedCount,
    marketingSourcedRevenue,
    salesSourcedRevenue,
    fiscalYear,
    isLoading,
    isCheckLoading,
    totalPipeLineCoverage,
  } = revenueData;
  
  const { salesAndMarketingStages } = useSelector(
    (state) => state.salesAndMarketingReducer
  );

  const [funnelStageCustom, setfunnelStageCustom] = useState([]);
  const [segmentMappingsData, setSegmentMappingsData] = useState();
  const [isMappingIcon, setIsMappingIcon] = useState(false);
  const [isConnectedAndSelected, setIsConnectedAndSelected] = useState(false);
  const [noCRMConnected, setnoCRMConnected] = useState(false);
  const [selectedCRM, setselectedCRM] = useState();

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const init = async () => {
    let ConnectedCRMs = await dispatch(getConnectedCRMs());
    let CRMS = [];
    if (Object.keys(ConnectedCRMs)?.length) {
      Object.keys(ConnectedCRMs)?.forEach((key) => {
        if (["Hubspot", "Salesforce"]?.includes(key)) {
          CRMS.push(key);
        }
      });
    }
    setnoCRMConnected(CRMS?.length === 0);

    
    // Check if "Salesforce" is available in the CRMS array
    CRMS.includes("Salesforce")
      ? setselectedCRM("Salesforce")
      : setselectedCRM(CRMS[0]);
  };

  const handleMappingIcon = async () => {
    const path = `/mapping/show_segment_mapping_icon?account_id=${account[0]}`;

    await API.get("backendRest", path)
      .then((res) => {
        setIsMappingIcon(res[0]);
        setIsConnectedAndSelected(res[1]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleMappingIcon();
    //setselectedRowKeys([]);
  }, []);

  const summaryTableColumns = [
    {
      title: "",
      children: [
        {
          title: "",
          key: "value",
          render: (text, record) => (
            <Checkbox
              checked={selectedRowKeys.includes(record.key)}
              onChange={() => {
                const updatedKeys = Array.isArray(selectedRowKeys)
                  ? selectedRowKeys.includes(record.key)
                    ? selectedRowKeys.filter((key) => key !== record.key)
                    : [...selectedRowKeys, record.key]
                  : [record.key];

                HandleBaseLineMetricsChange(updatedKeys);
                
              }}
              disabled={isCheckLoading}
            ></Checkbox>
          ),
        },
      ],
    },
    {
      title: (
        <h4
          style={{
            textAlign: "left",
            marginBottom: 0,
            background: "transparent",
            color: "#fff",
            userSelect: "none",
          }}
        >
          <strong>.</strong>
        </h4>
      ),
      className: "revenue-table-head",
      children: [
        {
          title: (
            <Typography.Text
              className="text_label"
              style={{ textAlign: "left", marginBottom: 0, color:"##1D2939", fontWeight:500 }}
            >
              Name of Segment
            </Typography.Text>
          ),
          dataIndex: "name",
          key: "name",
          render: (data, record) => (
            <Row>
              <Col>
                <Typography.Text className="text_label">{data}</Typography.Text>
              </Col>
              {isMappingIcon && (
                <Col
                  className="non-print"
                  style={{ marginLeft: 5, fontSize: 16 }}
                >
                  <LinkOutlined
                    onClick={() => {
                      setSegmentMappingsData(record.name);
                      handleEditMappings(record);
                    }}
                  />
                </Col>
              )}
            </Row>
          ),
        },
      ],
    },
    {
      title: "",
      className: "table-cell-style",
      children: [
        {
          title: "",
          render: (text, record) => (
            <Dropdown
              trigger={["click"]}
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      handleEditBaselineMetric(record);
                    }}
                  >
                    Edit
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      setisDeleteMetricsModelOpen(true);
                      setmetrictodelete(record.key);
                    }}
                  >
                    Delete
                  </Menu.Item>
                </Menu>
              }
              disabled={multiSelectedKey}
            >
              <img src={settingsImg} className="cursor-pointer" alt="" />
            </Dropdown>
          ),
        },
      ],
    },
    {
      title: (
        <h4
          style={{
            textAlign: "left",
            marginBottom: 0,
            color: "#fff",
            padding: 0,
            userSelect: "none",
            background: "#fff !important",
          }}
        >
          <strong></strong>
        </h4>
      ),
      className: "table-cell-style",
      children: [
        {
          title: (
            <Typography.Text
              className="text_label"
              style={{ textAlign: "left", marginBottom: 0, color:"##1D2939", fontWeight:500  }}
            >
              Goal defined
            </Typography.Text>
          ),
          dataIndex: "goal",
          key: "goal",
          render: (data) => (
            <Typography.Text
              className="text_label"
              style={{ textAlign: "left" }}
            >
              ${numberWithCommas(Number(data).toFixed(0))}
            </Typography.Text>
          ),
        },
      ],
    },
    {
      title: (
        <Typography.Text
          className="text_label"
          style={{ marginBottom: 0, display: "flex", alignItems: "center" }}
        >
          <strong>Marketing sourced</strong>{" "}
          {/* <LinkOutlined
            style={{ color: "#4B4B4B", marginLeft: 5 }}
            onClick={() => handleEditMarketMappings()}
          /> */}
          <div
            onClick={() => handleEditMarketMappings()}
            style={{ marginLeft: "10px", cursor: "pointer" }}
          >
            {selectedCRM === "Salesforce" && (
              <Tooltip placement="top" key="#fff" title="Marketing Sourced Mapping">
                <img
                  src={SalesforceImg}
                  alt={"Salesforce"}
                  style={{ width: "28px" }}
              />
              </Tooltip>
            )}
            {selectedCRM === "Hubspot" && (
              <Tooltip placement="top" key="#fff" title="Marketing Sourced Mapping">
                <img src={HubspotImg} alt={"Hubspot"} style={{ width: "24px" }} />
              </Tooltip>
            )}
            {selectedCRM !== "Salesforce" && selectedCRM !== "Hubspot" && (
              <div className="social_default_image" style={{height:'22px', width:'22px'}}>
                <ApiOutlined style={{ fontSize: "16px", color: "#fff" }} />
              </div>
            )}
            
          </div>
        </Typography.Text>
      ),
      className: "revenue-table-head",
      children: [
        {
          title: (
            <Typography.Text className="text_label" style={{ marginBottom: 0, color:"##1D2939", fontWeight:500  }}>
              Pct.
            </Typography.Text>
          ),
          dataIndex: "marketing_percent",
          key: "marketing_percent",
          render: (data) => (
            <Typography.Text
              className="text_label"
              style={{ textAlign: "left" }}
            >
              {data}%
            </Typography.Text>
          ),
        },
        {
          title: (
            <Typography.Text
              className="text_label"
              style={{ marginBottom: 0, textAlign: "left", color:"##1D2939", fontWeight:500  }}
            >
              Revenue
            </Typography.Text>
          ),
          dataIndex: "marketing_revenue",
          key: "revenue",
          render: (data) => (
            <Typography.Text
              className="text_label"
              style={{ textAlign: "left" }}
            >
              ${numberWithCommas(Number(data).toFixed(0))}
            </Typography.Text>
          ),
        },
        {
          title: (
            <Typography.Text
              className="text_label"
              style={{ marginBottom: 0, textAlign: "left", color:"##1D2939", fontWeight:500  }}
            >
              {props.lastStageName}
            </Typography.Text>
          ),
          dataIndex: "marketing_deals",
          key: "marketing_deals",
          render: (data) => (
            <Typography.Text
              className="text_label"
              style={{ textAlign: "left" }}
            >
              {/* {multiSelectedKey ?  Number.parseInt(data):numberWithCommas(
                                Number.parseInt(marketingSourcedCount)
                              )}  */}
              {Number.parseInt(data)}
            </Typography.Text>
          ),
        },
      ],
    },

    {
      title: (
        <Typography.Text className="text_label" style={{ marginBottom: 0 }}>
          <strong>Sales sourced</strong>
        </Typography.Text>
      ),
      children: [
        {
          title: (
            <Typography.Text
              className="text_label"
              style={{ marginBottom: 0, textAlign: "left", color:"##1D2939", fontWeight:500  }}
            >
              Pct.
            </Typography.Text>
          ),
          dataIndex: "sales_percent",
          key: "sales_percent",
          render: (data) => (
            <Typography.Text
              className="text_label"
              style={{ textAlign: "left" }}
            >
              {data}%
            </Typography.Text>
          ),
        },
        {
          title: (
            <Typography.Text
              className="text_label"
              style={{ marginBottom: 0, textAlign: "left", color:"##1D2939", fontWeight:500  }}
            >
              Revenue
            </Typography.Text>
          ),
          dataIndex: "sales_revenue",
          key: "sales_revenue",
          render: (data) => (
            <Typography.Text
              className="text_label"
              style={{ textAlign: "left" }}
            >
              ${numberWithCommas(Number(data).toFixed(0))}
            </Typography.Text>
          ),
        },
        {
          title: (
            <Typography.Text
              className="text_label"
              style={{ marginBottom: 0, textAlign: "left", color:"##1D2939", fontWeight:500  }}
            >
              {props.lastStageName}
            </Typography.Text>
          ),
          dataIndex: "sales_deals",
          key: "sales_deals",
          render: (data) => (
            <Typography.Text
              className="text_label"
              style={{ textAlign: "left" }}
            >
              {Number.parseInt(data)}
              {/* {multiSelectedKey ?  Number.parseInt(data):numberWithCommas(
                                Number.parseInt(saleSourcedCount)
                              )}  */}
              {/* {numberWithCommas(
                                Number.parseInt(saleSourcedCount)
                              )} */}
            </Typography.Text>
          ),
        },
      ],
    },
  ];

  // Operation for quarterly table

  const [revenueQtrData, setRevenueQtrData] = useState([]);
  const [wonQtrData, setWonQtrData] = useState([]);

  const [quarterCollapses, SetQuarterCollapses] = useState({
    revenue: false,
    closedWon: false,
  });
  const [isClosedQuarterEdit, setIsClosedQuarterEdit] = useState(false);
  const [isRevenueQuarterEdit, setIsRevenueQuarterEdit] = useState(false);

  const handleEdit = (record) => {
    setIsClosedQuarterEdit(true);
  };
  const handleEditRevenue = (record) => {
    setIsRevenueQuarterEdit(true);
  };

  const handleRevenueInputChange = (record, e, fieldName) => {
    const newData = [...revenueQtrData];

    const index = newData.findIndex((item) => item.key === record.key);
    newData[index][fieldName] = isNaN(parseInt(e.target.value))
      ? 0
      : parseInt(e.target.value);
    newData[index + 1][fieldName] = parseInt(
      (newData[index][fieldName] * (100 - marketingSourced)) / marketingSourced
    );
    let totalMarketingRevenue = 0;
    let totalRevenue = 0;
    newData.forEach((data) => {
      totalMarketingRevenue =
        data?.revenue_q1 +
        data?.revenue_q2 +
        data?.revenue_q3 +
        data?.revenue_q4;
      totalRevenue += totalMarketingRevenue;
    });

    dispatch(
      getCalculationMetrics(totalRevenue, marketingSourced, averageDealSize)
    );
    handleQuarterlyClosedWonChange(
      totalRevenue,
      marketingSourced,
      averageDealSize
    );
    setRevenueQtrData(newData);
  };

  const [fieldValue, setFieldValue] = useState("");
  const [showSaveImage, setShowSaveImage] = useState(true);
  const handleClosedInputChange = (record, e, fieldName) => {
    setFieldValue(fieldName);
    const closedData = [...wonQtrData];
    const index = closedData.findIndex((item) => item.key === record.key);
    closedData[index][fieldName] = isNaN(parseInt(e.target.value))
      ? 0
      : parseInt(e.target.value);
    let totalMarketingClosedWon = 0;
    closedData.forEach((item) => {
      if (item?.source === "Marketing Sourced") {
        totalMarketingClosedWon =
          item?.closed_q1 + item?.closed_q2 + item?.closed_q3 + item?.closed_q4;
      }
    });
    if (totalMarketingClosedWon !== marketingSourcedCount) {
      let diff = Math.abs(marketingSourcedCount - totalMarketingClosedWon);
      if (totalMarketingClosedWon > marketingSourcedCount) {
        closedData[index]["closed_q4"] -= diff;
      } else {
        closedData[index]["closed_q4"] += diff;
      }
    }
    closedData[index]["closed_q4"] < 0
      ? setShowSaveImage(false)
      : setShowSaveImage(true);
    setWonQtrData(closedData);
  };

  const handleQuarterClosedWonReset = (record) => {
    setShowSaveImage(true);
    setIsClosedQuarterEdit(false);
    let mrktWonParse = JSON.parse(baseLineData?.mrkt_qtr_won_leads);
    let salesWonParse = JSON.parse(baseLineData?.sales_qtr_won_leads);
    setWonQtrData([
      {
        source: "Marketing Sourced",
        closed_q1: mrktWonParse?.closed_q1,
        closed_q2: mrktWonParse?.closed_q2,
        closed_q3: mrktWonParse?.closed_q3,
        closed_q4: mrktWonParse?.closed_q4,
      },
      {
        source: "Sales Sourced",
        closed_q1: salesWonParse?.closed_q1,
        closed_q2: salesWonParse?.closed_q2,
        closed_q3: salesWonParse?.closed_q3,
        closed_q4: salesWonParse?.closed_q4,
      },
    ]);
  };

  const handleQuarterRevenueGoalReset = (record) => {
    setIsRevenueQuarterEdit(false);
    let mrktRevenueParse = JSON.parse(baseLineData?.mrkt_qtr_budget_allocation);

    let salesRevenueParse = JSON.parse(
      baseLineData?.sales_qtr_budget_allocation
    );
    setRevenueQtrData([
      {
        source: "Marketing Sourced",
        revenue_q1: mrktRevenueParse.revenue_q1,
        revenue_q2: mrktRevenueParse.revenue_q2,
        revenue_q3: mrktRevenueParse.revenue_q3,
        revenue_q4: mrktRevenueParse.revenue_q4,
      },
      {
        source: "Sales Sourced",
        revenue_q1: salesRevenueParse.revenue_q1,
        revenue_q2: salesRevenueParse.revenue_q1,
        revenue_q3: salesRevenueParse.revenue_q1,
        revenue_q4: salesRevenueParse.revenue_q1,
      },
    ]);
  };

  const quarterlyRevenueColumns = [
    {
      dataIndex: "source",
      key: "source",
      width: "20%",
      render: (text, record) => {
        if (record.source === "Marketing Sourced") {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography.Text style={{color:'#7F56D9', fontWeight:500}}>{text}</Typography.Text>
              {!multiSelectedKey && (
                <div>
                  {isRevenueQuarterEdit ? (
                    <div
                      className="campaign-table-save-reset-icon"
                      style={{ marginRight: "10px" }}
                    >
                      <img
                        src={SaveIconGrey}
                        alt=""
                        style={{ cursor: "pointer", paddingBottom: 5 }}
                        title="Save Changes"
                        onClick={() => handleSave()}
                      />
                      <img
                        src={ResetIcon}
                        alt=""
                        style={{ cursor: "pointer", paddingTop: 5 }}
                        title="Reset Changes"
                        onClick={() => handleQuarterRevenueGoalReset()}
                      />
                    </div>
                  ) : (
                    <EditOutlined onClick={() => handleEditRevenue(record)} />
                  )}
                </div>
              )}
            </div>
          );
        } else {
          return <div style={{color:'#7F56D9', fontWeight:500}}>{text}</div>;
        }
      },
    },
    {
      key: "revenue_total",
      width: "16%",
      render: (text, record) => {
        let totalQuarterRevenue =
          record?.revenue_q1 +
          record?.revenue_q2 +
          record?.revenue_q3 +
          record?.revenue_q4;
        return (
          <Typography.Text className="text_label" style={{ textAlign: "left", color:"#1D2939" }}>
            {/* { record?.source === "Marketing Sourced" ? `$${numberWithCommas(Number.parseInt(marketingSourcedRevenue))}` : `$${numberWithCommas(Number.parseInt(salesSourcedRevenue))}`} */}
            {numberWithCommas(totalQuarterRevenue)}
          </Typography.Text>
        );
      },
    },
    {
      dataIndex: "revenue_q1",
      key: "revenue_q1",
      width: "16%",

      render: (text, record) => {
        if (isRevenueQuarterEdit && record.source === "Marketing Sourced") {
          return (
            <Input
              value={text ? text : 0}
              onChange={(e) =>
                handleRevenueInputChange(record, e, "revenue_q1")
              }
            />
          );
        } else {
          return (
            <Typography.Text
              className="text_label"
              style={{ textAlign: "left", color:"#1D2939" }}
            >
              ${numberWithCommas(text)}
            </Typography.Text>
          );
        }
      },
    },
    {
      dataIndex: "revenue_q2",
      key: "revenue_q2",
      width: "16%",
      render: (text, record) => {
        if (isRevenueQuarterEdit && record.source === "Marketing Sourced") {
          return (
            <Input
              value={text ? text : 0}
              onChange={(e) =>
                handleRevenueInputChange(record, e, "revenue_q2")
              }
            />
          );
        } else {
          return (
            <Typography.Text
              className="text_label"
              style={{ textAlign: "left", color:"#1D2939" }}
            >
              ${numberWithCommas(text)}
            </Typography.Text>
          );
        }
      },
    },
    {
      dataIndex: "revenue_q3",
      key: "revenue_q3",
      width: "16%",
      render: (text, record) => {
        if (isRevenueQuarterEdit && record.source === "Marketing Sourced") {
          return (
            <Input
              value={text ? text : 0}
              onChange={(e) =>
                handleRevenueInputChange(record, e, "revenue_q3")
              }
            />
          );
        } else {
          return (
            <Typography.Text
              className="text_label"
              style={{ textAlign: "left", color:"#1D2939" }}
            >
              ${numberWithCommas(text)}
            </Typography.Text>
          );
        }
      },
    },
    {
      dataIndex: "revenue_q4",
      key: "revenue_q4",
      width: "16%",
      render: (text, record) => {
        if (isRevenueQuarterEdit && record.source === "Marketing Sourced") {
          return (
            <Input
              value={text ? text : 0}
              onChange={(e) =>
                handleRevenueInputChange(record, e, "revenue_q4")
              }
            />
          );
        } else {
          return (
            <Typography.Text
              className="text_label"
              style={{ textAlign: "left" }}
            >
              ${numberWithCommas(text)}
            </Typography.Text>
          );
        }
      },
    },
  ];

  const quarterlyClosedColumns = [
    {
      dataIndex: "source",
      key: "source",
      width: "20%",
      render: (text, record) => {
        if (record.source === "Marketing Sourced") {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography.Text style={{color:'#7F56D9', fontWeight:500}}>{text}</Typography.Text>
              {!multiSelectedKey && (
                <div>
                  {isClosedQuarterEdit ? (
                    <div
                      className="campaign-table-save-reset-icon"
                      style={{ marginRight: "10px" }}
                    >
                      {showSaveImage && (
                        <img
                          src={SaveIconGrey}
                          alt=""
                          style={{ cursor: "pointer", paddingBottom: 5 }}
                          title="Save Changes"
                          onClick={() => handleSave()}
                        />
                      )}
                      <img
                        src={ResetIcon}
                        alt=""
                        style={{ cursor: "pointer", paddingTop: 5 }}
                        title="Reset Changes"
                        onClick={() => handleQuarterClosedWonReset()}
                      />
                    </div>
                  ) : (
                    <EditOutlined onClick={() => handleEdit(record)} />
                  )}
                </div>
              )}
            </div>
          );
        } else {
          return <div style={{color:'#7F56D9', fontWeight:500}}>{text}</div>;
        }
      },
    },
    {
      key: "closed_total",
      render: (text, record) => {
        let showWarning = false;
        let totalQuarterClosed =
          record?.closed_q1 +
          record?.closed_q2 +
          record?.closed_q3 +
          record?.closed_q4;
        if (record.source === "Marketing Sourced") {
          let marketingClosed =
            record?.closed_q1 +
            record?.closed_q2 +
            record?.closed_q3 +
            record?.closed_q4;
          showWarning =
            parseInt(marketingClosed) === parseInt(marketingSourcedCount)
              ? false
              : true;
        }
        return (
          <div style={{ display: "flex" }}>
            <div>
              <Typography.Text
                className="text_label"
                style={{ textAlign: "left", color:"#1D2939" }}
              >
                {numberWithCommas(totalQuarterClosed)}
              </Typography.Text>
            </div>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              {showWarning && (
                <Tooltip title="Please adjust the quarterly Closed Won">
                  <img src={WarningIcon} alt="warning" />
                </Tooltip>
              )}
            </div>
          </div>
        );
      },
    },
    {
      dataIndex: "closed_q1",
      key: "closed_q1",
      width: "16%",
      render: (text, record) => {
        if (isClosedQuarterEdit && record?.source === "Marketing Sourced") {
          return (
            <div>
              <Input
                value={isNaN(text) ? 0 : text}
                onChange={(e) =>
                  handleClosedInputChange(record, e, "closed_q1")
                }
              />
              {fieldValue === "closed_q1" && record?.closed_q4 < 0 && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  Please Enter valid value
                </div>
              )}
            </div>
          );
        }
        return (
          <Typography.Text className="text_label" style={{ textAlign: "left", color:"#1D2939" }}>
            {numberWithCommas(text)}
          </Typography.Text>
        );
      },
    },
    {
      dataIndex: "closed_q2",
      key: "closed_q2",
      width: "16%",
      render: (text, record) => {
        if (isClosedQuarterEdit && record.source === "Marketing Sourced") {
          return (
            <div>
              <Input
                value={text ? text : 0}
                onChange={(e) =>
                  handleClosedInputChange(record, e, "closed_q2")
                }
              />
              {fieldValue === "closed_q2" && record?.closed_q4 < 0 && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  Please Enter valid value
                </div>
              )}
            </div>
          );
        }
        return (
          <Typography.Text className="text_label" style={{ textAlign: "left", color:"#1D2939" }}>
            {numberWithCommas(text)}
          </Typography.Text>
        );
      },
    },
    {
      dataIndex: "closed_q3",
      key: "closed_q3",
      width: "16%",
      render: (text, record) => {
        if (isClosedQuarterEdit && record.source === "Marketing Sourced") {
          return (
            <div>
              <Input
                value={text ? text : 0}
                onChange={(e) =>
                  handleClosedInputChange(record, e, "closed_q3")
                }
              />
              {fieldValue === "closed_q3" && record?.closed_q4 < 0 && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  Please Enter valid value
                </div>
              )}
            </div>
          );
        }
        return (
          <Typography.Text className="text_label" style={{ textAlign: "left", color:"#1D2939" }}>
            {numberWithCommas(text)}
          </Typography.Text>
        );
      },
    },
    {
      dataIndex: "closed_q4",
      key: "closed_q4",
      width: "16%",
      render: (text, record) => {
        if (isClosedQuarterEdit && record.source === "Marketing Sourced") {
          return (
            <Input
              disabled
              value={isNaN(text) ? 0 : text}
              onChange={(e) => handleClosedInputChange(record, e, "closed_q4")}
            />
          );
        }
        return (
          <Typography.Text className="text_label" style={{ textAlign: "left", color:"#1D2939" }}>
            {numberWithCommas(text)}
          </Typography.Text>
        );
      },
    },
  ];

  const handleDeleteMetrics = async () => {
    if (metrictodelete) {
      setLoader(true);
      let stageToDelete = [];
      let funnelConfigToDelete = [];
      const { baselineMetrics } = fiscalYear;

      let segment = baselineMetrics?.items?.find(
        (segment) => segment.id === metrictodelete
      );

      if (segment) {
        segment?.funnelConfigs?.items?.forEach((funnelconfig) => {
          funnelConfigToDelete.push(funnelconfig.id);
          funnelconfig.stages?.items?.forEach((stage) => {
            stageToDelete.push(stage?.id);
          });
        });
      }
      await Promise.all(
        stageToDelete.map((stage) =>
          dispatch(deleteFunnelStages({ id: stage }))
        )
      );
      await Promise.all(
        funnelConfigToDelete.map((funnel) =>
          dispatch(deleteFunnelConfig({ id: funnel }))
        )
      );
      await dispatch(deleteBaselineMetric({ id: metrictodelete }));
      setisDeleteMetricsModelOpen(false);
      setLoader(false);
    }
  };
  const handleEditBaselineMetric = (record) => {
    setbaseLineDatatoEdit({
      name: record.name,
      id: record.key,
      segment_type: record?.segment_type,
      marketing_percent: record?.marketing_percent,
    });
    setisEditModalOpen(true);
  };

  const handleOnChange = (name, value) => {
    if (name === "newRevenueGoal") {
      dispatch(getCalculationMetrics(value, marketingSourced, averageDealSize));
      handleQuarterlyClosedWonChange(value, marketingSourced, averageDealSize);
    } else if (name === "marketingSourced") {
      dispatch(getCalculationMetrics(newRevenueGoal, value, averageDealSize));
      handleQuarterlyClosedWonChange(newRevenueGoal, value, averageDealSize);
    } else {
      dispatch(getCalculationMetrics(newRevenueGoal, marketingSourced, value));
      handleQuarterlyClosedWonChange(newRevenueGoal, marketingSourced, value);
    }
    props.isFunnelChanges(true);
    setisitUnsaved(true);
  };
  const GetSortOrder = (prop) => {
    return (a, b) => {
      if (a[prop] > b[prop]) {
        return -1;
      } else if (a[prop] < b[prop]) {
        return 1;
      }
      return 0;
    };
  };
  const HandelTableDataChange = () => {
    let { baselineMetrics } = fiscalYear;

    if (baselineMetrics?.items.length > 0) {
      baselineMetrics.items.sort(GetSortOrder("updatedAt"));
      var tableData = baselineMetrics.items.map((item) => {
        let tableRow = {};
        tableRow["key"] = item?.id;
        tableRow["name"] = item?.name;
        tableRow["goal"] = item?.goal ? item?.goal : 0;
        tableRow["segment_type"] = item?.segment_type;
        tableRow["marketing_revenue"] =
          item?.goal && item?.mrktSourcePercent
            ? ((item?.goal * item?.mrktSourcePercent) / 100).toFixed(0)
            : 0;
        tableRow["marketing_deals"] =
          tableRow["marketing_revenue"] && item?.avgDealSize
            ? (tableRow["marketing_revenue"] / item?.avgDealSize).toFixed(0)
            : 0;

        tableRow["marketing_percent"] = item?.mrktSourcePercent;

        tableRow["sales_percent"] = 100 - item?.mrktSourcePercent;
        tableRow["sales_revenue"] =
          item?.goal && tableRow["sales_percent"]
            ? ((item?.goal * tableRow["sales_percent"]) / 100).toFixed(0)
            : 0;

        tableRow["sales_deals"] =
          item?.goal && item?.mrktSourcePercent && item?.avgDealSize
            ? item?.goal / item?.avgDealSize - tableRow["marketing_deals"]
            : 0;
        let sum =
          parseInt(tableRow["marketing_revenue"]) +
          parseInt(tableRow["sales_revenue"]);
        if (sum !== item?.goal) {
          tableRow["sales_revenue"] -= sum - item?.goal;
        }
        return tableRow;
      });
      setsummaryTableData(tableData);
    } else {
      setsummaryTableData([]);
      dispatch(setBaseLineData());

      dispatch(getCalculationMetrics());
    }
  };

  useEffect(() => {
    if (baseLineData?.budget_allocation && baseLineData?.goal) {
      let mrktWonParse = JSON.parse(baseLineData?.mrkt_qtr_won_leads);
      let salesWonParse = JSON.parse(baseLineData?.sales_qtr_won_leads);
      setWonQtrData([
        {
          source: "Marketing Sourced",
          closed_q1: mrktWonParse?.closed_q1,
          closed_q2: mrktWonParse?.closed_q2,
          closed_q3: mrktWonParse?.closed_q3,
          closed_q4: mrktWonParse?.closed_q4,
        },
        {
          source: "Sales Sourced",
          closed_q1: salesWonParse?.closed_q1,
          closed_q2: salesWonParse?.closed_q2,
          closed_q3: salesWonParse?.closed_q3,
          closed_q4: salesWonParse?.closed_q4,
        },
      ]);

      let mrktRevenueParse = JSON.parse(
        baseLineData?.mrkt_qtr_budget_allocation
      );

      let salesRevenueParse = JSON.parse(
        baseLineData?.sales_qtr_budget_allocation
      );
      setRevenueQtrData([
        {
          source: "Marketing Sourced",
          revenue_q1: mrktRevenueParse.revenue_q1,
          revenue_q2: mrktRevenueParse.revenue_q2,
          revenue_q3: mrktRevenueParse.revenue_q3,
          revenue_q4: mrktRevenueParse.revenue_q4,
        },
        {
          source: "Sales Sourced",
          revenue_q1: salesRevenueParse.revenue_q1,
          revenue_q2: salesRevenueParse.revenue_q2,
          revenue_q3: salesRevenueParse.revenue_q3,
          revenue_q4: salesRevenueParse.revenue_q4,
        },
      ]);
      form.setFieldsValue(JSON.parse(baseLineData?.budget_allocation));
      let budget = JSON.parse(baseLineData?.budget_allocation);
      if (
        budget.budget_q1 !== baseLineData?.goal / 4 ||
        budget.budget_q2 !== baseLineData?.goal / 4 ||
        budget.budget_q3 !== baseLineData?.goal / 4 ||
        budget.budget_q4 !== baseLineData?.goal / 4
      ) {
        setaddQuarterlyGoals(true);
      } else {
        setaddQuarterlyGoals(false);
      }
    } else {
      form.setFieldsValue({
        budget_q1: null,
        budget_q2: null,
        budget_q3: null,
        budget_q4: null,
      });
      setaddQuarterlyGoals(false);
    }
    // setselectedRowKeys(baseLineData?.id ? [baseLineData.id] : []);
    //setselectedRowKeys(baseLineData?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseLineData]);

  // useEffect(() => {
  //   setselectedRowKeys(baseLineData?.id);
  // }, [])

  useEffect(() => {
    dispatch(
      getCalculationMetrics(
        baseLineData?.goal,
        baseLineData?.mrktSourcePercent,
        baseLineData?.avgDealSize,
        baseLineData?.mrkt_qtr_budget_allocation,
        baseLineData?.sales_qtr_budget_allocation,
        baseLineData?.mrkt_qtr_won_leads,
        baseLineData?.sales_qtr_won_leads
      )
    );
  }, [baseLineData]);

  useEffect(() => {
    
    // Set the default selected key when baselineData is available

    setselectedRowKeys(baseLineData?.selectedIds ?? []);
    //HandleBaseLineMetricsChange([baseLineData.id]);
  }, [baseLineData]);

  useEffect(() => {
    if (selectedRowKeys.length > 1) {
      setMultiSelectedKey(() => true);
      props.onMultiSelectedChange(true);
    } else {
      setMultiSelectedKey(() => false);
      props.onMultiSelectedChange(false);
    }
    if (selectedRowKeys.length === 0) {
      props.onEmptySelect(true);
    } else {
      props.onEmptySelect(false);
    }
  }, [selectedRowKeys]);

  const resetQuarterlyData = () => {
    props.isFunnelChanges(false);
    setisitUnsaved(false);
    if (baseLineData?.budget_allocation) {
      form.setFieldsValue(JSON.parse(baseLineData?.budget_allocation));
    } else {
      handleQuarterlyDataChange(baseLineData.goal);
    }
    handleQuarterRevenueGoalReset();
    handleQuarterClosedWonReset();
    dispatch(
      getCalculationMetrics(
        baseLineData.goal,
        baseLineData.mrktSourcePercent,
        baseLineData.avgDealSize
      )
    );
  };

  useEffect(() => {
    if (baseLineData?.budget_allocation) {
      form.setFieldsValue(JSON.parse(baseLineData?.budget_allocation));
    }
    HandelTableDataChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiscalYear]);

  const reloadTableData = (props) => {
    dispatch(getFiscalYear(props));
  };
  const handleQuarterlyDataChange = (value) => {
    let perQuarterBudget = parseInt(value / 4);
    let quarterlyRevenueGoal = {
      q1: perQuarterBudget,
      q2: perQuarterBudget,
      q3: perQuarterBudget,
      q4: perQuarterBudget,
    };

    let sum =
      quarterlyRevenueGoal["q1"] +
      quarterlyRevenueGoal["q2"] +
      quarterlyRevenueGoal["q3"] +
      quarterlyRevenueGoal["q4"];
    if (sum !== value) {
      let diff = Math.abs(value - sum);
      if (value > sum) {
        quarterlyRevenueGoal["q4"] += diff;
      } else {
        quarterlyRevenueGoal["q4"] -= diff;
      }
    }
    form.setFieldsValue({
      budget_q1: quarterlyRevenueGoal["q1"],
      budget_q2: quarterlyRevenueGoal["q2"],
      budget_q3: quarterlyRevenueGoal["q3"],
      budget_q4: quarterlyRevenueGoal["q4"],
    });
  };

  const handleQuarterlyClosedWonChange = (
    newRevenueGoal = 0,
    marketingSourced = 1,
    averageDealSize = 0
  ) => {
    let marketingSourcedRevenue = (
      (newRevenueGoal * marketingSourced) /
      100
    ).toFixed(0);
    let marketingSourcedCount =
      isNaN(marketingSourcedRevenue / averageDealSize) ||
      !isFinite(marketingSourcedRevenue / averageDealSize)
        ? 0
        : (marketingSourcedRevenue / averageDealSize).toFixed(0);
    let salesSourced = 100 - marketingSourced;
    let salesSourcedRevenue = ((newRevenueGoal * salesSourced) / 100).toFixed(
      0
    );
    let saleSourcedCount = (salesSourcedRevenue / averageDealSize).toFixed(0);
    let sum = parseInt(marketingSourcedRevenue) + parseInt(salesSourcedRevenue);
    if (sum !== newRevenueGoal) {
      salesSourcedRevenue -= sum - newRevenueGoal;
    }
    let perQuarterMarketingLeads = parseInt(marketingSourcedCount / 4);
    let quarterlyMarketingClosedWonGoal = {
      q1: perQuarterMarketingLeads,
      q2: perQuarterMarketingLeads,
      q3: perQuarterMarketingLeads,
      q4: perQuarterMarketingLeads,
    };
    let totalMarketingClosedWon =
      quarterlyMarketingClosedWonGoal["q1"] +
      quarterlyMarketingClosedWonGoal["q2"] +
      quarterlyMarketingClosedWonGoal["q3"] +
      quarterlyMarketingClosedWonGoal["q4"];
    if (totalMarketingClosedWon !== marketingSourcedCount) {
      let diff = Math.abs(marketingSourcedCount - totalMarketingClosedWon);
      if (diff === 1) {
        quarterlyMarketingClosedWonGoal["q4"] += 1;
      }
      if (diff === 2) {
        quarterlyMarketingClosedWonGoal["q4"] += 1;
        quarterlyMarketingClosedWonGoal["q3"] += 1;
      }
      if (diff === 3) {
        quarterlyMarketingClosedWonGoal["q4"] += 1;
        quarterlyMarketingClosedWonGoal["q3"] += 1;
        quarterlyMarketingClosedWonGoal["q3"] += 1;
      }
    }
    let perQuarterSalesLeads = parseInt(saleSourcedCount / 4);
    let quarterlySalesClosedWonGoal = {
      q1: perQuarterSalesLeads,
      q2: perQuarterSalesLeads,
      q3: perQuarterSalesLeads,
      q4: perQuarterSalesLeads,
    };
    let totalSalesClosedWon =
      quarterlySalesClosedWonGoal["q1"] +
      quarterlySalesClosedWonGoal["q2"] +
      quarterlySalesClosedWonGoal["q3"] +
      quarterlySalesClosedWonGoal["q4"];
    if (totalSalesClosedWon !== saleSourcedCount) {
      let diff = Math.abs(saleSourcedCount - totalSalesClosedWon);
      if (diff === 1) {
        quarterlySalesClosedWonGoal["q4"] += 1;
      }
      if (diff === 2) {
        quarterlySalesClosedWonGoal["q4"] += 1;
        quarterlySalesClosedWonGoal["q3"] += 1;
      }
      if (diff === 3) {
        quarterlySalesClosedWonGoal["q4"] += 1;
        quarterlySalesClosedWonGoal["q3"] += 1;
        quarterlySalesClosedWonGoal["q3"] += 1;
      }
    }
    setWonQtrData([
      {
        source: "Marketing Sourced",
        closed_q1: quarterlyMarketingClosedWonGoal["q1"],
        closed_q2: quarterlyMarketingClosedWonGoal["q2"],
        closed_q3: quarterlyMarketingClosedWonGoal["q3"],
        closed_q4: quarterlyMarketingClosedWonGoal["q4"],
      },
      {
        source: "Sales Sourced",
        closed_q1: quarterlySalesClosedWonGoal["q1"],
        closed_q2: quarterlySalesClosedWonGoal["q2"],
        closed_q3: quarterlySalesClosedWonGoal["q3"],
        closed_q4: quarterlySalesClosedWonGoal["q4"],
      },
    ]);

    let perQuarterMarketingRevenue = parseInt(marketingSourcedRevenue / 4);
    let quarterlyMarketingRevenueGoal = {
      q1: perQuarterMarketingRevenue,
      q2: perQuarterMarketingRevenue,
      q3: perQuarterMarketingRevenue,
      q4: perQuarterMarketingRevenue,
    };
    let totalMarketingRevenue =
      quarterlyMarketingRevenueGoal["q1"] +
      quarterlyMarketingRevenueGoal["q2"] +
      quarterlyMarketingRevenueGoal["q3"] +
      quarterlyMarketingRevenueGoal["q4"];
    if (totalMarketingRevenue !== marketingSourcedRevenue) {
      let diff = Math.abs(marketingSourcedRevenue - totalMarketingRevenue);
      if (diff === 1) {
        quarterlyMarketingRevenueGoal["q4"] += 1;
      }
      if (diff === 2) {
        quarterlyMarketingRevenueGoal["q4"] += 1;
        quarterlyMarketingRevenueGoal["q3"] += 1;
      }
      if (diff === 3) {
        quarterlyMarketingRevenueGoal["q4"] += 1;
        quarterlyMarketingRevenueGoal["q3"] += 1;
        quarterlyMarketingRevenueGoal["q3"] += 1;
      }
    }
    let perQuarterSalesRevenue = parseInt(salesSourcedRevenue / 4);
    let quarterlySalesRevenueGoal = {
      q1: perQuarterSalesRevenue,
      q2: perQuarterSalesRevenue,
      q3: perQuarterSalesRevenue,
      q4: perQuarterSalesRevenue,
    };
    let totalSalesRevenue =
      quarterlySalesRevenueGoal["q1"] +
      quarterlySalesRevenueGoal["q2"] +
      quarterlySalesRevenueGoal["q3"] +
      quarterlySalesRevenueGoal["q4"];
    if (totalSalesRevenue !== salesSourcedRevenue) {
      let diff = Math.abs(salesSourcedRevenue - totalSalesRevenue);

      if (diff === 1) {
        quarterlySalesRevenueGoal["q4"] += 1;
      }
      if (diff === 2) {
        quarterlySalesRevenueGoal["q4"] += 1;
        quarterlySalesRevenueGoal["q3"] += 1;
      }
      if (diff === 3) {
        quarterlySalesRevenueGoal["q4"] += 1;
        quarterlySalesRevenueGoal["q3"] += 1;
        quarterlySalesRevenueGoal["q3"] += 1;
      }
    }

    setRevenueQtrData([
      {
        source: "Marketing Sourced",
        revenue_q1: quarterlyMarketingRevenueGoal["q1"],
        revenue_q2: quarterlyMarketingRevenueGoal["q2"],
        revenue_q3: quarterlyMarketingRevenueGoal["q3"],
        revenue_q4: quarterlyMarketingRevenueGoal["q4"],
      },
      {
        source: "Sales Sourced",
        revenue_q1: quarterlySalesRevenueGoal["q1"],
        revenue_q2: quarterlySalesRevenueGoal["q2"],
        revenue_q3: quarterlySalesRevenueGoal["q3"],
        revenue_q4: quarterlySalesRevenueGoal["q4"],
      },
    ]);
  };
  const handleSave = async (fromParent = false) => {
    setIsRevenueQuarterEdit(false);
    setIsClosedQuarterEdit(false);
    !fromParent && summaryTableData?.length === 1 && props.saveFiscalyear(true);
    props.isFunnelChanges(false);
    setisitUnsaved(false);
    let quarterWiseData = form.getFieldsValue([
      "budget_q1",
      "budget_q2",
      "budget_q3",
      "budget_q4",
    ]);
    if ((summaryTableData?.length <= 1 && fromParent) || !addQuarterlyGoals) {
      quarterWiseData["budget_q1"] = Number.parseInt(newRevenueGoal / 4);
      quarterWiseData["budget_q2"] = Number.parseInt(newRevenueGoal / 4);
      quarterWiseData["budget_q3"] = Number.parseInt(newRevenueGoal / 4);
      quarterWiseData["budget_q4"] = Number.parseInt(newRevenueGoal / 4);
    }
    let marketQuarterClosedWon = {};
    let salesQuarterClosedWon = {};
    let marketingYearTotalWonLeads = 0;
    let salesYearTotalWonLeads = 0;
    wonQtrData.forEach((item) => {
      if (item.source === "Marketing Sourced") {
        marketQuarterClosedWon["closed_q1"] = Number.parseInt(item.closed_q1);
        marketQuarterClosedWon["closed_q2"] = Number.parseInt(item.closed_q2);
        marketQuarterClosedWon["closed_q3"] = Number.parseInt(item.closed_q3);
        marketQuarterClosedWon["closed_q4"] = Number.parseInt(item.closed_q4);
        marketingYearTotalWonLeads =
          Number.parseInt(item.closed_q1) +
          Number.parseInt(item.closed_q2) +
          Number.parseInt(item.closed_q3) +
          Number.parseInt(item.closed_q4);
      } else {
        salesQuarterClosedWon["closed_q1"] = Number.parseInt(item.closed_q1);
        salesQuarterClosedWon["closed_q2"] = Number.parseInt(item.closed_q2);
        salesQuarterClosedWon["closed_q3"] = Number.parseInt(item.closed_q3);
        salesQuarterClosedWon["closed_q4"] = Number.parseInt(item.closed_q4);
        salesYearTotalWonLeads =
          Number.parseInt(item.closed_q1) +
          Number.parseInt(item.closed_q2) +
          Number.parseInt(item.closed_q3) +
          Number.parseInt(item.closed_q4);
      }
    });

    let marketQuarterBudgetAllocation = {};
    let salesQuarterBudgetAllocation = {};
    revenueQtrData.forEach((item) => {
      if (item.source === "Marketing Sourced") {
        marketQuarterBudgetAllocation["revenue_q1"] = Number.parseInt(
          item.revenue_q1
        );
        marketQuarterBudgetAllocation["revenue_q2"] = Number.parseInt(
          item.revenue_q2
        );
        marketQuarterBudgetAllocation["revenue_q3"] = Number.parseInt(
          item.revenue_q3
        );
        marketQuarterBudgetAllocation["revenue_q4"] = Number.parseInt(
          item.revenue_q4
        );
      } else {
        salesQuarterBudgetAllocation["revenue_q1"] = Number.parseInt(
          item.revenue_q1
        );
        salesQuarterBudgetAllocation["revenue_q2"] = Number.parseInt(
          item.revenue_q2
        );
        salesQuarterBudgetAllocation["revenue_q3"] = Number.parseInt(
          item.revenue_q3
        );
        salesQuarterBudgetAllocation["revenue_q4"] = Number.parseInt(
          item.revenue_q4
        );
      }
    });

    const requestData = {
      name: baseLineData.name,
      fiscalYearId: fiscalYear.id,
      goal: Number.parseFloat(newRevenueGoal),
      mrktSourcePercent: Number.parseFloat(marketingSourced),
      avgDealSize: Number.parseFloat(averageDealSize),
      updatedBy: email,
      account: account[0],
      mrkt_year_won_leads: marketingYearTotalWonLeads,
      sales_year_won_leads: salesYearTotalWonLeads,
      budget_allocation: JSON.stringify(quarterWiseData),
      mrkt_qtr_won_leads: JSON.stringify(marketQuarterClosedWon),
      sales_qtr_won_leads: JSON.stringify(salesQuarterClosedWon),
      mrkt_qtr_budget_allocation: JSON.stringify(marketQuarterBudgetAllocation),
      sales_qtr_budget_allocation: JSON.stringify(salesQuarterBudgetAllocation),
    };
    if (baseLineData && baseLineData.id && baseLineData.id !== "") {
      requestData.id = baseLineData.id;
      let updateMetricData = await dispatch(updateBaseLineMetric(requestData));
      let updateFunnelConfig = await createFunnelConfig(updateMetricData?.id);
      let funnelStage = await createFunnel(
        updateFunnelConfig?.id,
        updateFunnelConfig?.stages
      );
      dispatch(setFunnelStagesList(funnelStage));
      await dispatch(getFiscalYear(fiscalYear.id));
      props.isFunnelChanges(false);
    }
  };

  const createFunnelConfig = (metricId) => {
    const requestData = {
      baselineMetricId: metricId,
      name: "FunnelConfig",
      account: account[0],
    };

    if (
      funnelConfiguration &&
      funnelConfiguration.id &&
      funnelConfiguration.id !== ""
    ) {
      requestData.id = funnelConfiguration?.id;
      return dispatch(updateFunnelConfiguration(requestData))
        .then((response) => {
          return response;
        })
        .catch((e) => {
          return e;
        });
    } else {
      return dispatch(createFunnelConfiguration(requestData))
        .then((response) => {
          return response;
        })
        .catch((e) => {
          return e;
        });
    }
  };

  const createFunnel = async (id, stages) => {
    let apiCall = [];

    funnelStageCustom.map((item, index) => {
      let requestData = {};
      if (item?.id) {
        requestData = {
          id: item.id,
          funnelConfigID: id,
          order: item?.salesAndMarketingAlignment?.order,
          name: item.name,
          conversion: item.conversion,
          account: item.account,
          salesAndMarketingAlignmentID: item?.salesAndMarketingAlignmentID,
        };
      } else {
        requestData = {
          funnelConfigID: id,
          order: item?.salesAndMarketingAlignment?.order,
          name: item.name,
          conversion: item.conversion,
          account: account[0],
          salesAndMarketingAlignmentID: item?.salesAndMarketingAlignmentID,
        };
      }

      apiCall.push(requestData);
      return 0;
    });
    return Promise.all(
      apiCall.map((item) =>
        stages?.items?.length > 0 && item.id
          ? dispatch(updateFunnelStages(item))
          : dispatch(createFunnelStages(item))
      )
    ).then((response) => {
      return response;
    });
  };

  const handleCancelCreateMetrics = () => {
    setCreateBaselineMetricsDrawerVisible(false);
    setisEditModalOpen(false);
  };

  // const HandleBaseLineMetricsChange = (e) => {

  //   dispatch(getBaselineMetricData(e));

  // };

  const HandleBaseLineMetricsChange = (selectedKeys) => {
    
    setselectedRowKeys(selectedKeys);

    //dispatch(getBaselineMetricData(e));
    dispatch(getBaselineMetricMultipleData(selectedKeys));
  };

  useEffect(() => {
    let customstages = [];
    // eslint-disable-next-line array-callback-return
    funnelStages.map((item) => {
      let obj = item;
      // eslint-disable-next-line array-callback-return
      let funnel = salesAndMarketingStages?.filter((data) => {
        if (data.name === item.name) return data.id;
      });
      if (funnel?.length > 0) {
        obj.salesAndMarketingAlignmentID = funnel[0]?.id;
      }
      !customstages.includes(obj) && customstages.push(obj);
    });
    setfunnelStageCustom(customstages);
  }, [funnelStages, salesAndMarketingStages]);

  const [isitUnsaved, setisitUnsaved] = useState(false);

  useEffect(() => {
    props.setNumberOfSegments(summaryTableData?.length);
  }, [summaryTableData]);

  useEffect(() => {
    if (props.saveSegmentClicked) {
      handleSave(true);
    }
  }, [props.saveSegmentClicked]);

  useEffect(() => {
    if (props.removeQuarterwiseData) {
      setaddQuarterlyGoals(false);
    }
  }, [props.removeQuarterwiseData]);

  // Changes related to get sales saving mapping

  const [hubspotMapObj, setHubspotMapObj] = useState([]);
  const [saleForceMapObj, setSalesForcefMapObj] = useState([]);
  const [testSalesData, setTestSalesData] = useState([]);
  const [testSalesDataTitle, setTestSalesDataTitle] = useState("");
  const [salesLabelValue, setSalesLabelValue] = useState([]);
  const [hubLableValue, setHubLabelValue] = useState([]);
  const [resData, setResData] = useState([]);
  const [salesLoader, setSalesLoader] = useState(false);
  const [marketLoader, setMarketLoader] = useState(false);

  const handleEditMappings = async (record) => {
    setMetricDrawer(true);
    const requestData = {
      account: account[0],
      data: [
        {
          virtualSegmentId: record.key,
          Salesforce: [""],
          Hubspot: [""],
        },
      ],
    };
    await getSegmentMappings(requestData);
  };
  const getSegmentMappings = async (requestData) => {
    setSalesLoader(true);
    let path = `/mapping/getSegmentMappings`;

    await API.post("backendRest", path, { body: requestData })
      .then((res) => {
        Object.keys(res[0]["Hubspot"][0]).forEach((key) => {
          if (key === "savedSegmentMappings") {
            let tempHubObj = [];
            let tempSavedHub = {
              platform_id: res[0]["Hubspot"][0].plateformId,
              values: [],
            };
            res[0]["Hubspot"][0][key].forEach((value) => {
              tempHubObj.push(Object.values(value)[0]);
              tempSavedHub["values"].push({
                type: Object.keys(value)[0],
                id: Object.values(value)[0],
              });
            });
            setHubLabelValue(tempHubObj);
            sethubspotData(tempSavedHub);
          }
        });
        Object.keys(res[0]["Salesforce"][0]).forEach((key) => {
          if (key === "savedSegmentMappings") {
            let tempSalesObj = [];
            let tempSavedSales = {
              platform_id: res[0]["Salesforce"][0].plateformId,
              values: [],
            };
            res[0]["Salesforce"][0][key].forEach((value) => {
              tempSalesObj.push(Object.values(value)[0]);
              tempSavedSales["values"].push({
                type: Object.keys(value)[0],
                id: Object.values(value)[0],
              });
            });
            setSalesLabelValue(tempSalesObj);
            setSalesForceData(tempSavedSales);
          }
          if (res[0]["Salesforce"][0][key].title) {
            setTestSalesDataTitle(res[0]["Salesforce"][0][key].title);
            setTestSalesData(res[0]["Salesforce"][0][key]?.values);
          }
        });
        setHubspotMapObj(res[0]["Hubspot"]);
        setSalesForcefMapObj(res[0]["Salesforce"]);
        setResData(res);
        setSalesLoader(false);
        return res;
      })
      .catch((err) => {
        console.log(err);
        setSalesLoader(false);
      });
  };

  const SaveMappingConfig = async () => {
    setLoader(true);
    let inputData = {
      account: account[0],
      virtualSegmentId: resData[0].virtualSegmentId[0],
      Hubspot: [hubspotData],
      Salesforce: [SalesForceData],
    };
    let init = {
      body: inputData,
    };

    API.post("backendRest", "/mapping/saveSegmentMappings", init)
      .then((res) => {
        message.success("Mapping values updated successfully");
        setLoader(false);
        setMetricDrawer(false);

        return res;
      })
      .catch((err) => {
        setLoader(false);
        setMetricDrawer(false);

        console.log("err", err);
      });
  };

  const [hubspotData, sethubspotData] = useState([]);
  const [SalesForceData, setSalesForceData] = useState([]);
  const handleSalesSpoteData = (data) => {
    let textsalesData = {};
    Object.entries(saleForceMapObj[0]).forEach(([item, value]) => {
      textsalesData = {
        platform_id: saleForceMapObj[0].plateformId,
        values: [],
      };
    });
    Object.entries(saleForceMapObj[0]).forEach(([key]) => {
      if (saleForceMapObj[0][key].title) {
        data.forEach((i) => {
          textsalesData["values"].push({
            type: saleForceMapObj[0][key].type,
            id: i,
          });
        });
      }
    });
    setSalesForceData(textsalesData);
  };

  const handleHubSpoteData = (data) => {
    let textData = {};
    Object.entries(hubspotMapObj[0]).forEach(([item, value]) => {
      textData = { platform_id: hubspotMapObj[0].plateformId, values: [] };
    });
    Object.entries(hubspotMapObj[0]).forEach(([key]) => {
      if (hubspotMapObj[0][key].title) {
        data.forEach((i) => {
          textData["values"].push({ type: hubspotMapObj[0][key].type, id: i });
        });
      }
    });
    sethubspotData(textData);
  };

  const handleCloseMpppingDrawer = () => {
    setSalesLabelValue([]);
    setHubLabelValue([]);
    setMetricDrawer(false);
  };

  // Changes related to market saving mapping

  const [marketData, setMarketData] = useState();
  const [savedSalesValue, setSavedSalesValue] = useState([]);
  const [savedHubsValue, setSavedHubsValue] = useState([]);
  const [salesMarketValues, setSalesMarketValues] = useState();
  const [hubMarketValues, setHubMarketValues] = useState();
  const [saveHubMarketValues, setSaveHubMarketValues] = useState();
  const [saveSalesMarketValues, setSaveSalesMarketValues] = useState([]);

  const handleEditMarketMappings = async () => {
    setMarketingSourced(true);
    const requestData = {
      account: account[0],
      data: [
        {
          Salesforce: [""],
          Hubspot: [""],
        },
      ],
    };
    await getMarketingMappings(requestData);
  };
  const getMarketingMappings = async (requestData) => {
    setMarketLoader(true);
    let path = `/mapping/getMarketingSourcedMappings`;

    await API.post("backendRest", path, { body: requestData })
      .then((res) => {
        setMarketData(res[0]);
        
        Object.keys(res[0]["Salesforce"][0]).forEach((key) => {
          if (key === "savedMarketingSourcedMappings") {
            let tempSalesMarketObj = [];
            let tempMarketSales = {
              platform_id: res[0]["Salesforce"][0].plateformId,
              values: [],
            };
            res[0]["Salesforce"][0][key].forEach((value) => {
              
              tempSalesMarketObj.push(Object.values(value)[0]);
              tempMarketSales["values"].push({
                type: Object.keys(value)[0],
                id: Object.values(value)[0],
              });
            });
            
            setSavedSalesValue(tempSalesMarketObj);
            setSaveSalesMarketValues(tempMarketSales);
          }
          if (res[0]["Salesforce"][0][key].title) {
            setSalesMarketValues(res[0]["Salesforce"][0][key]);
          }
        });
        Object.keys(res[0]["Hubspot"][0]).forEach((key) => {
          if (key === "savedMarketingSourcedMappings") {
            let tempHubsMarketObj = [];
            let tempMarketHub = {
              platform_id: res[0]["Hubspot"][0].plateformId,
              values: [],
            };
            res[0]["Hubspot"][0][key].forEach((value) => {
              tempHubsMarketObj.push(Object.values(value)[0]);
              tempMarketHub["values"].push({
                type: Object.keys(value)[0],
                id: Object.values(value)[0],
              });
            });
            setSavedHubsValue(tempHubsMarketObj);
            setSaveHubMarketValues(tempMarketHub);
          }
          if (res[0]["Hubspot"][0][key].title) {
            setHubMarketValues(res[0]["Hubspot"][0][key]);
          }
        });
        setMarketLoader(false);
        return res;
      })
      .catch((err) => {
        setMarketLoader(false);
        console.log(err);
      });
  };

  const handleSalesMarketingData = (data) => {
    let tempsalesMarketData = {};
    tempsalesMarketData = {
      platform_id: marketData["Salesforce"][0].plateformId,
      values: [],
    };

    if (salesMarketValues.title) {
      data.forEach((i) => {
        tempsalesMarketData["values"].push({
          type: salesMarketValues.type,
          id: i,
        });
      });
    }
    setSaveSalesMarketValues(tempsalesMarketData);
  };

  const handleHubMarketingData = (data) => {
    let tempsalesMarketData = {};
    tempsalesMarketData = {
      platform_id: marketData["Hubspot"][0].plateformId,
      values: [],
    };
    if (hubMarketValues.title) {
      data.forEach((i) => {
        tempsalesMarketData["values"].push({
          type: hubMarketValues.type,
          id: i,
        });
      });
    }
    setSaveHubMarketValues(tempsalesMarketData);
  };

  const SaveMarketMappingConfig = async () => {
    setLoader(true);
    let inputData = {
      account: account[0],
      Hubspot: [saveHubMarketValues],
      Salesforce: [saveSalesMarketValues],
    };
    let init = {
      body: inputData,
    };

    API.post("backendRest", "/mapping/saveMarketingSourcedMappings", init)
      .then((res) => {
        message.success("Marketing Mapping values updated successfully");
        setLoader(false);
        setMarketingSourced(false);
        return res;
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  const handleCloseMarketingMpppingDrawer = () => {
    setSavedHubsValue([]);
    setSavedSalesValue([]);
    setMarketingSourced(false);
  };

  return (
    <Fragment>
      <Row>
        <Col
          xs={24}
          md={24}
          lg={24}
          style={{
            padding:"20px 15px",
            backgroundColor: "#fff",
            borderBottomLeftRadius: 7,
            borderBottomRightRadius: 7,
            marginBottom: "20px"
          }}
          className={`revenue-table panel-design ${
            summaryTableData?.length <= 5 ? "h-55" : ""
          }`}
        >
          {getFiscalYearListLoader ? (
            <Skeleton active />
          ) : (
            <>
              <Table
                pagination={false}
                size="small"
                dataSource={summaryTableData}
                columns={summaryTableColumns}
                bordered
                className="baselineMetricsTable general-table revenue-planning-table"
                summary={(pageData) => {
                  let totalGoal = 0;
                  let totalmrktingGoal = 0;
                  let totalsalesGoal = 0;
                  pageData.forEach(
                    ({ goal, marketing_revenue, sales_revenue }) => {
                      totalGoal += goal;
                      totalmrktingGoal += Number(marketing_revenue);
                      totalsalesGoal += Number(sales_revenue);
                    }
                  );
                  return (
                    <>
                      {pageData.length > 0 && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Typography.Text
                              className="text_label"
                              style={{ textAlign: "left" }}
                            >
                              <strong style={{color:"#7F56D9", fontWeight:500}}>Total Value</strong>
                            </Typography.Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell style={{ paddingBottom: 30 }}>
                            <Typography.Text
                              className="text_label"
                              style={{
                                textAlign: "center",
                                display: "block",
                                paddingBottom: 1,
                                paddingTop: 5,
                              }}
                            >
                              <Text style={{color:"#F04438", fontWeight:500}}>${numberWithCommas(totalGoal)}</Text>
                              <br />
                              {fiscalYear?.budget - totalGoal > 0 ? (
                                <small style={{ color: "red" }}>
                                  ($
                                  {numberWithCommas(
                                    fiscalYear.budget - totalGoal
                                  )}
                                  )
                                </small>
                              ) : fiscalYear?.budget - totalGoal < 0 ? (
                                <small style={{ color: "#42D77E" }}>
                                  ($
                                  {numberWithCommas(
                                    Math.abs(fiscalYear.budget - totalGoal)
                                  )}
                                  )
                                </small>
                              ) : (
                                ""
                              )}
                            </Typography.Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell colSpan={3} className="textCenter">
                            <Typography.Text
                              className="text_label"
                              style={{
                                textAlign: "center",
                                
                              }}
                            >
                              <Text style={{color:"#F04438", fontWeight:500}}>
                                $ {numberWithCommas(totalmrktingGoal)}
                                {/* $ {numberWithCommas(Number.parseInt(marketingSourcedRevenue))} */}
                              </Text>
                            </Typography.Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell colSpan={3} className="textCenter">
                            <Typography.Text
                              className="text_label"
                              style={{
                                textAlign: "center",
                                
                              }}
                            >
                              <Text style={{color:"#F04438", fontWeight:500}}>
                                $ {numberWithCommas(totalsalesGoal)}
                                {/* $ {numberWithCommas(
                                Number.parseInt(salesSourcedRevenue)
                              )} */}
                              </Text>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      )}
                    </>
                  );
                }}
              />
              <Tooltip
                title={
                  fiscalYear.year !== props.selectedFiscalYear ||
                  !props.selectedFiscalYear
                    ? "Enter Annual Revenue Goal to create new Segment"
                    : fiscalYear?.baselineMetrics?.items?.length >= 10
                    ? "Can not add more than 10 Segment in one financial year"
                    : ""
                }
              >
                {(props.userPermission?.revenuePlanning &&
                  props.userPermission?.revenuePlanning !== "viewonly" &&
                  summaryTableData.length <
                    parseInt(
                      props.userPermission?.["revenuePlanning.maxSegments"]
                    )) ||
                (props.userPermission?.["revenuePlanning.segments"] !==
                  "viewonly" &&
                  props.userPermission?.["revenuePlanning.segments"] !==
                    "hidden") ||
                (account?.[2] === "admin" &&
                  viewType !== "companyadmin" &&
                  viewType !== "companycontributor") ? (
                  <Button
                    data-tour="tour__createMetric"
                    type="link"
                    onClick={() => {
                      setCreateBaselineMetricsDrawerVisible(true);
                    }}
                    disabled={
                      fiscalYear.year !== props.selectedFiscalYear ||
                      !props.selectedFiscalYear ||
                      fiscalYear?.baselineMetrics?.items?.length >= 10
                    }
                    style={{ color: "#0F61DB", padding:0 }}
                  >
                    <strong className="non-print">
                      <Button
                        className="primaryButton"
                        style={{fontSize:'14px', height:"24px", width:"150px", marginTop:'15px'}}
                      >
                        + Create New
                      </Button>
                    </strong>
                  </Button>
                ) : (
                  props.userPermission?.["revenuePlanning.segments"] ===
                    "viewonly" && (
                    <Button
                      data-tour="tour__createMetric"
                      type="link"
                      title={
                        summaryTableData.length >=
                        parseInt(
                          props.userPermission?.["revenuePlanning.maxSegments"]
                        )
                          ? "Max Segment Limit"
                          : "View only permission"
                      }
                      style={{ color: "#D3D3D3" }}
                    >
                      <strong className="non-print">
                        <u
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          + Create New
                        </u>
                      </strong>
                    </Button>
                  )
                )}
              </Tooltip>
            </>
          )}
        </Col>
        {isEditModalOpen && (
          <CreateBaseLineMetrics
            handleCancel={handleCancelCreateMetrics}
            isEdit={true}
            visible={isEditModalOpen}
            baselineMetrics={baseLineDatatoEdit}
            reloadTableData={reloadTableData}
            userPermission={props.userPermission}
            viewType={viewType}
          />
        )}
        {CreateBaselineMetricsDrawerVisible && (
          <CreateBaseLineMetrics
            handleCancel={handleCancelCreateMetrics}
            visible={CreateBaselineMetricsDrawerVisible}
            reloadTableData={reloadTableData}
            year={fiscalYear?.year}
            userPermission={props.userPermission}
            viewType={viewType}
            isConnectedAndSelected={isConnectedAndSelected}
          />
        )}
        {selectedRowKeys.length === 0 ? (
          <>
            <Col
              lg={24}
              md={24}
              xs={24}
              style={{ marginTop: 15 }}
              className={`revenue_calculation ${
                summaryTableData?.length > 5 ? "mt" : ""
              }`}
            >
              <div
                style={{
                  minHeight: "150px",
                  fontWeight: 500,
                  fontSize: "22px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Data Available. Please Select Any Segment
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col
              lg={24}
              md={24}
              xs={24}
              style={{ marginBottom:'20px', padding:"20px 15px" }}
              className={`revenue_calculation panel-design ${
                summaryTableData?.length > 5 ? "mt" : ""
              }`}
            >
              <div
                className="revenue_calculation"
                style={{
                  borderTop: "none",
                }}
                data-tour="tour__baselineMetrics"
              >
                <Row
                  gutter={[20, 20]}
                  style={{
                    margin: 0,
                    paddingBottom: "15px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Col
                    style={{
                      
                      fontSize: "19px",
                      fontStyle: "normal",
                      fontWeight: "bold",
                    }}
                    className="revenue-sub-title"
                  >
                    <strong style={{color:"#F04438"}}>Step 2</strong>
                    <strong style={{color:"#1D2939", fontWeight:'700'}}>
                      {" "}
                      - Set Your Segment Goal Configuration{" "}
                      <span style={{fontWeight:700, color:"#7F56D9"}}>{baseLineData?.name && `(${baseLineData?.name})`}</span>
                    </strong>{" "}
                  </Col>
                </Row>

                <Row
                  gutter={[20, 20]}
                  style={{
                    margin: 0,
                    borderTop: "2px solid #E9F2FF",
                    paddingTop: '30px',
                    paddingBottom: 10,
                    
                  }}
                  className="p-10"
                >
                  <Col xs={24} md={24} lg={14} style={{ padding: "0 10px" }}>
                    <Row
                      xs={24}
                      md={24}
                      lg={24}
                      gutter={[20, 20]}
                      style={{ margin: 0, padding: "0px 0" }}
                    >
                      <Col xs={24} sm={24} md={14} lg={14}>
                        {getFiscalYearListLoader || isCheckLoading ? (
                          <div>
                            <Skeleton.Button
                              active
                              size="small"
                              style={{ marginBottom: 20 }}
                            />{" "}
                            <Skeleton.Button active size="small" block />{" "}
                          </div>
                        ) : (
                          <RevenueTextField
                            //  dollercirclestyle={{
                            //   position: "absolute",
                            //   zIndex: 12,
                            //   top: 53,
                            //   left: 19,
                            //   backgroundSize: "3rem",
                            //   fontSize:"18px"
                            // }}
                            className="revenue-text"
                            style={{
                              margin: "0px",
                            }}
                            tooltipText={
                              "This should be net new revenue only. Do not include renewals or upsell/cross sell revenue. This field should correlate with your average deals size field. If you use MRR, ARR, Bookings make sure they match."
                            }
                            uperText={
                              <span
                                style={{
                                  fontSize: "18px",
                                  color:"#1D2939",
                                  fontWeight:500
                                }}
                              >
                                Net Revenue Goal
                              </span>
                            }
                            name={"newRevenueGoal"}
                            placeholder="Net Revenue Goal"
                            onChange={(value) => {
                              dispatch(
                                getCalculationMetrics(
                                  value,
                                  marketingSourced,
                                  averageDealSize
                                )
                              );
                              handleQuarterlyDataChange(value);
                              handleQuarterlyClosedWonChange(
                                value,
                                marketingSourced,
                                averageDealSize
                              );
                              props.isFunnelChanges(true);
                              setisitUnsaved(true);
                            }}
                            value={newRevenueGoal === 0 ? "" : newRevenueGoal}
                            disabled={
                              addQuarterlyGoals ||
                              summaryTableData.length === 0 ||
                              multiSelectedKey ||
                              props.userPermission?.revenuePlanning ===
                                "viewonly"
                            }
                          />
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={10} lg={10}>
                        {getFiscalYearListLoader || isCheckLoading ? (
                          <div>
                            <Skeleton.Button
                              active
                              size="small"
                              style={{ marginBottom: 20 }}
                            />{" "}
                            <Skeleton.Button active size="small" block />{" "}
                          </div>
                        ) : (
                          <RevenueTextField
                            // dollercirclestyle={{
                            //   position: "absolute",
                            //   zIndex: 12,
                            //   top: 53,
                            //   left: 19,
                            //   backgroundSize: "3rem",
                            //   fontSize:"18px"
                            // }}
                            tooltipText={
                              "Average Deal Size should match the context of the net new revenue goal. If you use MRR use the average Monthly deal size, if you use ARR use the average Annual Deal Size."
                            }
                            uperText={
                              <span
                                style={{
                                  fontSize: "18px",
                                  color:"#1D2939",
                                  fontWeight:500
                                }}
                              >
                                Average Deal Size
                              </span>
                            }
                            name={"averageDealSize"}
                            placeholder="Average Deal Size"
                            onChange={(value) => {
                              dispatch(
                                getCalculationMetrics(
                                  newRevenueGoal,
                                  marketingSourced,
                                  value
                                )
                              );
                              handleQuarterlyClosedWonChange(
                                newRevenueGoal,
                                marketingSourced,
                                value
                              );
                              props.isFunnelChanges(true);
                              setisitUnsaved(true);
                            }}
                            value={averageDealSize === 0 ? "" : averageDealSize}
                            disabled={
                              summaryTableData.length === 0 ||
                              multiSelectedKey ||
                              props.userPermission?.revenuePlanning ===
                                "viewonly"
                            }
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={24} lg={10} style={{ padding: "0 10px " }}>
                    <Row
                      xs={24}
                      md={24}
                      lg={24}
                      gutter={[20, 20]}
                      className="step2Box"
                      style={{ margin: 0, padding: "0px 15px 0 0" }}
                    >
                      <Col xs={24} md={24} lg={24} style={{ padding: "0px 0" }}>
                        {getFiscalYearListLoader || isCheckLoading ? (
                          <div>
                            <Skeleton.Button
                              active
                              size="small"
                              style={{ marginBottom: 20 }}
                            />{" "}
                            <Skeleton.Button active size="small" block />{" "}
                          </div>
                        ) : (
                          <RevenueTextField
                            uperText={
                              <span
                                style={{
                                  fontSize: "18px",
                                  color:"#1D2939",
                                  fontWeight:500
                                }}
                              >
                                Marketing Sourced
                              </span>
                            }
                            tooltipText={
                              "This is the percentage of net new revenue that the Marketing team will source. This should be discussed and agreed to between Sales & Marketing leadership."
                            }
                            minVal={0}
                            maxVal={100}
                            name={"marketingSourced"}
                            value={marketingSourced}
                            onChange={(name, value) => {
                              handleOnChange(name, value);
                            }}
                            isSlider={true}
                            marketingSourcedText={true}
                            disabled={
                              summaryTableData.length === 0 ||
                              multiSelectedKey ||
                              props.userPermission?.revenuePlanning ===
                                "viewonly"
                            }
                            multiSelectedKey={multiSelectedKey}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row gutter={[20, 20]}>
                  <Row
                    style={{ padding: "0px 23px", width: "100%" }}
                    gutter={[10]}
                    justify="space-between"
                  >
                    <Col className="non-print">
                      {(props.userPermission?.revenuePlanning &&
                        props.userPermission?.revenuePlanning !== "viewonly" &&
                        props.userPermission?.[
                          "revenuePlanning.setQuarterlyGoals"
                        ] &&
                        props.userPermission?.[
                          "revenuePlanning.setQuarterlyGoals"
                        ] !== "viewonly" &&
                        props.userPermission?.[
                          "revenuePlanning.setQuarterlyGoals"
                        ] !== "hidden") ||
                      (account?.[2] === "admin" &&
                        viewType !== "companyadmin" &&
                        viewType !== "companycontributor") ||
                      addQuarterlyGoals ? (
                        <>
                          {getFiscalYearListLoader ? (
                            <div>
                              <Skeleton.Button
                                active
                                size="small"
                                block
                                style={{ marginLeft: 8, marginTop: 20 }}
                              />{" "}
                            </div>
                          ) : (
                            <Button
                              type="link"
                              className="quarterly-btn"
                              onClick={() => {
                                setaddQuarterlyGoals(!addQuarterlyGoals);
                                if (addQuarterlyGoals === true) {
                                  handleQuarterlyDataChange(newRevenueGoal);
                                }
                              }}
                              style={{ color: "#0F61DB", marginBottom: 10 }}
                              disabled={summaryTableData.length === 0}
                            >
                              <strong>
                                {!addQuarterlyGoals ? (
                                  <Button
                                  style={{fontSize:'14px', height:"24px", width:"200px", marginTop:'15px'}}
                                  className="primaryButton"
                                  >
                                    +Set Quarterly Goals
                                  </Button>
                                ) : (
                                  <Button
                                  style={{fontSize:'14px', height:"24px", width:"200px", marginTop:'15px'}}
                                  className="secondaryButton"
                                  >
                                    Remove Quarterly Goals
                                  </Button>
                                )}
                              </strong>
                            </Button>
                          )}{" "}
                        </>
                      ) : (
                        props.userPermission?.revenuePlanning &&
                        (props.userPermission?.revenuePlanning === "viewonly" ||
                          props.userPermission?.[
                            "revenuePlanning.setQuarterlyGoals"
                          ] === "viewonly") && (
                          <>
                            {getFiscalYearListLoader ? (
                              <div>
                                <Skeleton.Button
                                  active
                                  size="small"
                                  block
                                  style={{ marginLeft: 8, marginTop: 20 }}
                                />{" "}
                              </div>
                            ) : (
                              <Button
                                type="link"
                                className="quarterly-btn"
                                title="View only permission"
                                style={{ color: "#0F61DB" }}
                                disabled
                              >
                                <strong>
                                  {!addQuarterlyGoals ? (
                                    <u
                                      style={{
                                        fontSize: "16px",
                                      }}
                                    >
                                      +Set Quarterly Goals
                                    </u>
                                  ) : (
                                    <u
                                      style={{
                                        fontSize: "16px",
                                      }}
                                    >
                                      Remove Quarterly Goals
                                    </u>
                                  )}
                                </strong>
                              </Button>
                            )}
                          </>
                        )
                      )}
                    </Col>
                  </Row>
                  {addQuarterlyGoals && (
                    <Col
                      lg={24}
                      md={24}
                      sm={24}
                      style={{ padding: "0px 30px" }}
                    >
                      <div>
                        <div
                          className="quarter-table-header"
                          style={{
                            margin: 0,
                            paddingTop: 15,
                            paddingBottom: 10,
                            width: "100%",
                            background:'#7F56D9',
                            borderBottom:'1px solid #7F56D9'
                          }}
                        >
                          <Typography.Text
                            className="table-stages-title"
                            style={{ width: "20%", color:"#fff", fontWeight:700 }}
                          >
                            Sources
                          </Typography.Text>
                          <Typography.Text
                            className="table-stages-title"
                            style={{ width: "16%", color:"#fff", fontWeight:700 }}
                          >
                            Total
                          </Typography.Text>
                          <Typography.Text
                            className="table-stages-title"
                            style={{ width: "16%", color:"#fff", fontWeight:700 }}
                          >
                            Q1
                          </Typography.Text>
                          <Typography.Text
                            className="table-stages-title"
                            style={{ width: "16%", color:"#fff", fontWeight:700 }}
                          >
                            Q2
                          </Typography.Text>
                          <Typography.Text
                            className="table-stages-title"
                            style={{ width: "16%", color:"#fff", fontWeight:700 }}
                          >
                            Q3
                          </Typography.Text>
                          <Typography.Text
                            className="table-stages-title"
                            style={{ width: "16%", color:"#fff", fontWeight:700 }}
                          >
                            Q4
                          </Typography.Text>
                        </div>
                        <div>
                          <div className="quarter-table-header1" style={{background:'#f5f7f9', borderLeft:'1px solid #D0D5DD', borderRight:'1px solid #D0D5DD', borderBottom:'1px solid #D0D5DD'}}>
                            <Typography.Text className="quarter-table-title" style={{color:"#1D2939", fontWeight:500}}>
                              Quarterly Revenue Goals
                            </Typography.Text>
                            {quarterCollapses.revenue ? (
                              <span>
                                <img
                                  className="expand-collapse-icon-style"
                                  src={verticalExpandImg}
                                  onClick={() => {
                                    SetQuarterCollapses({
                                      revenue: false,
                                      closedWon: quarterCollapses.closedWon,
                                    });
                                  }}
                                  alt=""
                                />
                              </span>
                            ) : (
                              <span>
                                <img
                                  className="expand-collapse-icon-style"
                                  src={verticalCollapseImg}
                                  onClick={() => {
                                    SetQuarterCollapses({
                                      revenue: true,
                                      closedWon: quarterCollapses.closedWon,
                                    });
                                  }}
                                  alt=""
                                />
                              </span>
                            )}
                          </div>
                          {!quarterCollapses.revenue && (
                            <Table
                              className="table_col_hide general-table"
                              bordered
                              dataSource={revenueQtrData}
                              columns={quarterlyRevenueColumns}
                              pagination={false}
                              summary={(rowData) => {
                                let totalRevenueCal = 0;
                                let totalMarketingRevenueCal = 0;
                                rowData.forEach((data) => {
                                  totalMarketingRevenueCal =
                                    data?.revenue_q1 +
                                    data?.revenue_q2 +
                                    data?.revenue_q3 +
                                    data?.revenue_q4;
                                  totalRevenueCal += totalMarketingRevenueCal;
                                });
                                return (
                                  <>
                                    <Table.Summary.Row>
                                      <Table.Summary.Cell></Table.Summary.Cell>
                                      <Table.Summary.Cell colSpan={5}>
                                        <Typography.Text
                                          className="text_label"
                                          style={{
                                            textAlign: "center",
                                          }}
                                        >
                                          <Text style={{color:"#F04438", fontWeight:'500'}}>
                                            ${numberWithCommas(totalRevenueCal)}
                                          </Text>
                                          {baseLineData.goal - totalRevenueCal >
                                          0 ? (
                                            <small
                                              style={{
                                                color: "red",
                                                marginLeft: "5px",
                                              }}
                                            >
                                              ($
                                              {numberWithCommas(
                                                baseLineData.goal -
                                                  totalRevenueCal
                                              )}
                                              )
                                            </small>
                                          ) : baseLineData.goal -
                                              totalRevenueCal <
                                            0 ? (
                                            <small
                                              style={{
                                                color: "#42D77E",
                                                marginLeft: "5px",
                                              }}
                                            >
                                              ($
                                              {numberWithCommas(
                                                Math.abs(
                                                  baseLineData.goal -
                                                    totalRevenueCal
                                                )
                                              )}
                                              )
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                        </Typography.Text>
                                      </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                  </>
                                );
                              }}
                            />
                          )}
                        </div>
                        <div>
                          <div className="quarter-table-header1" style={{background:'#f5f7f9', borderLeft:'1px solid #D0D5DD', borderRight:'1px solid #D0D5DD', borderBottom:'1px solid #D0D5DD'}}>
                            <Typography.Text className="quarter-table-title" style={{color:"#1D2939", fontWeight:500}}>
                              Quarterly Closed Won
                            </Typography.Text>
                            {quarterCollapses.closedWon ? (
                              <span>
                                <img
                                  className="expand-collapse-icon-style"
                                  src={verticalExpandImg}
                                  onClick={() => {
                                    SetQuarterCollapses({
                                      revenue: quarterCollapses.revenue,
                                      closedWon: false,
                                    });
                                  }}
                                  alt=""
                                />
                              </span>
                            ) : (
                              <span>
                                <img
                                  className="expand-collapse-icon-style"
                                  src={verticalCollapseImg}
                                  onClick={() => {
                                    SetQuarterCollapses({
                                      revenue: quarterCollapses.revenue,
                                      closedWon: true,
                                    });
                                  }}
                                  alt=""
                                />
                              </span>
                            )}
                          </div>
                          {!quarterCollapses.closedWon && (
                            <Table
                              className="table_col_hide general-table"
                              bordered
                              dataSource={wonQtrData}
                              columns={quarterlyClosedColumns}
                              pagination={false}
                              summary={(rowData) => {
                                let totalClosedWon = 0;
                                let totalMarketingClosedWon = 0;

                                rowData.forEach((data) => {
                                  totalMarketingClosedWon =
                                    data?.closed_q1 +
                                    data?.closed_q2 +
                                    data?.closed_q3 +
                                    data?.closed_q4;
                                  totalClosedWon += totalMarketingClosedWon;
                                });
                                return (
                                  <>
                                    <Table.Summary.Row>
                                      <Table.Summary.Cell
                                        colSpan={1}
                                      ></Table.Summary.Cell>
                                      <Table.Summary.Cell colSpan={5}>
                                        <Typography.Text
                                          
                                          className="text_label"
                                          style={{color:"#F04438", fontWeight:'500'}}
                                        >
                                          {numberWithCommas(totalClosedWon)}
                                        </Typography.Text>
                                      </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                  </>
                                );
                              }}
                            />
                          )}
                        </div>

                        {/* <Form
                        form={form}
                        onChange={() => {
                          let sum =
                            Number(form.getFieldValue("budget_q1")) +
                            Number(form.getFieldValue("budget_q2")) +
                            Number(form.getFieldValue("budget_q3")) +
                            Number(form.getFieldValue("budget_q4"));
                          dispatch(
                            getCalculationMetrics(
                              sum,
                              marketingSourced,
                              averageDealSize
                            )
                          );
                          props.isFunnelChanges(true)
                          setisitUnsaved(true)
                        }}
                      >
                        <Row
                          style={{ padding: "0px 8px 0px 20px", margin: "0 10px" }}
                          justify="center"
                          gutter={[20, 20]}
                        >
                          <Col span={6} md={6} lg={6} sm={24} xs={24} style={{ padding: '0 0 10px 10px' }}>
                            <Form.Item
                              name="budget_q1"
                              style={{ margin: "0 10px 0 0"}}
                            >
                              <RevenueTextField 
                                placeholder="Enter Amount"
                                disabled = {props.userPermission?.revenuePlanning === "viewonly"}
                                dollercirclestyle={{
                                  position: "absolute",
                                  zIndex: 12,
                                  top: 43,
                                  left: 10,
                                }}
                                uperText={<span style={{
                                  fontSize: "18px"
                                }}>Quarter 1</span>}
                                onChange={() => {
                                  let sum =
                                    Number(form.getFieldValue("budget_q1")) +
                                    Number(form.getFieldValue("budget_q2")) +
                                    Number(form.getFieldValue("budget_q3")) +
                                    Number(form.getFieldValue("budget_q4"));
                                  dispatch(
                                    getCalculationMetrics(
                                      sum,
                                      marketingSourced,
                                      averageDealSize
                                    )
                                  );
                       

                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6} md={6} lg={6} sm={24} xs={24} style={{ padding: '0 0 10px 10px' }}>
                            <Form.Item
                              name="budget_q2"
                              style={{ margin: "0 10px 0 0" }}
                            >
                              <RevenueTextField 
                                placeholder="Enter Amount"
                                disabled = {props.userPermission?.revenuePlanning === "viewonly"}
                                dollercirclestyle={{
                                  position: "absolute",
                                  zIndex: 12,
                                  top: 43,
                                  left: 10,
                                }}
                                uperText={<span style={{
                                  fontSize: "18px"
                                }}>Quarter 2</span>}
                                onChange={() => {
                                  let sum =
                                    Number(form.getFieldValue("budget_q1")) +
                                    Number(form.getFieldValue("budget_q2")) +
                                    Number(form.getFieldValue("budget_q3")) +
                                    Number(form.getFieldValue("budget_q4"));
                                  dispatch(
                                    getCalculationMetrics(
                                      sum,
                                      marketingSourced,
                                      averageDealSize
                                    )
                                  );

                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6} md={6} lg={6} sm={24} xs={24} style={{ padding: '0 0 10px 10px' }}>
                            <Form.Item
                              name="budget_q3"
                              style={{ margin: "0 10px 0 0" }}
                            >
                              <RevenueTextField
                                placeholder="Enter Amount"
                                disabled = {props.userPermission?.revenuePlanning === "viewonly"}
                                dollercirclestyle={{
                                  position: "absolute",
                                  zIndex: 12,
                                  top: 43,
                                  left: 10,
                                }}
                                uperText={<span style={{
                                  fontSize: "18px"
                                }}>Quarter 3</span>}
                                onChange={() => {
                                  let sum =
                                    Number(form.getFieldValue("budget_q1")) +
                                    Number(form.getFieldValue("budget_q2")) +
                                    Number(form.getFieldValue("budget_q3")) +
                                    Number(form.getFieldValue("budget_q4"));
                                  dispatch(
                                    getCalculationMetrics(
                                      sum,
                                      marketingSourced,
                                      averageDealSize
                                    )
                                  );

                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6} md={6} lg={6} sm={24} xs={24} style={{ padding: '0 0 10px 10px' }}>
                            <Form.Item
                              name="budget_q4"
                              style={{ margin: "0 10px 0 0" }}
                            >
                              <RevenueTextField
                                placeholder="Enter Amount"
                                disabled = {props.userPermission?.revenuePlanning === "viewonly"}
                                dollercirclestyle={{
                                  position: "absolute",
                                  zIndex: 12,
                                  top: 43,
                                  left: 10,
                                }}
                                uperText={<span style={{
                                  fontSize: "18px"
                                }}>Quarter 4</span>}
                                onChange={() => {
                                  let sum =
                                    Number(form.getFieldValue("budget_q1")) +
                                    Number(form.getFieldValue("budget_q2")) +
                                    Number(form.getFieldValue("budget_q3")) +
                                    Number(form.getFieldValue("budget_q4"));
                                  dispatch(
                                    getCalculationMetrics(
                                      sum,
                                      marketingSourced,
                                      averageDealSize
                                    )
                                  );
                                  
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      
                      </Form> */}
                        {/* <Row
                          style={{ padding: "0px 8px 0px 20px", margin: "0 10px" }}
                          justify="center"
                          gutter={[20, 20]}
                        >
                        <Table dataSource={qdata} columns={quarterlyColumns} />
                      </Row> */}
                      </div>
                    </Col>
                  )}
                  <Row
                    style={{ padding: " 0px 17px 15px 0px", width: "100%" }}
                    gutter={[10]}
                    justify="end"
                    className="non-print"
                  >
                    <Col style={{ padding: "0 5px" }}>
                      <Tooltip
                        align="top"
                        title={
                          averageDealSize > newRevenueGoal &&
                          "Avarage deal size should less than net revenue goal"
                        }
                      >
                        {props.userPermission?.revenuePlanning !==
                        "viewonly" ? (
                          <div
                            style={{
                              display: "flex",
                              alignContent: "center",
                            }}
                          >
                            {isitUnsaved && (
                              <span
                                className="conversation-difference-dot"
                                style={{ opacity: 1 }}
                              ></span>
                            )}
                            <Button
                              type="secondary"
                              onClick={() => {
                                TrackClickFunction('demand-gen-revenue-planning-segment-goal-save-button') 
                                handleSave()
                              }}
                              style={{ height: '40px', width:'150px' }}
                              disabled={
                                !newRevenueGoal ||
                                !averageDealSize ||
                                multiSelectedKey ||
                                averageDealSize > newRevenueGoal
                              }
                              className="primaryButton"
                            >
                              <img
                                src={saveConversationIcon}
                                style={{
                                  paddingRight: 5,
                                  opacity:
                                    !newRevenueGoal ||
                                    !averageDealSize ||
                                    averageDealSize > newRevenueGoal
                                      ? 0.5
                                      : 1,
                                }}
                                alt="save"
                              ></img>{" "}
                              Save
                            </Button>
                          </div>
                        ) : (
                          <Button
                            type="secondary"
                            title="View only permission"
                            style={{ height: '40px', width:'150px' }}
                            disabled
                            className="primaryButton"
                          >
                            <img
                              src={saveConversationIcon}
                              style={{ paddingRight: 5 }}
                              alt="save"
                            ></img>{" "}
                            Save
                          </Button>
                        )}
                      </Tooltip>
                    </Col>
                    <Col style={{ padding: "0 5px" }}>
                      {props.userPermission?.revenuePlanning !== "viewonly" ? (
                        <Button
                          type="secondary"
                          onClick={resetQuarterlyData}
                          style={{
                            height: '40px',
                            width:'150px',
                            color:'#fff',
                            marginLeft: 5,
                            fontSize: "16px",
                          }}
                          disabled={
                            !(baseLineData && baseLineData.id) ||
                            !newRevenueGoal ||
                            multiSelectedKey
                          }
                          className="secondaryButton"
                         
                        >
                          <img
                            src={resetConversationIcon}
                            style={{
                              paddingRight: 5,
                              opacity: `${
                                !(
                                  !(baseLineData && baseLineData.id) ||
                                  !newRevenueGoal
                                )
                                  ? 1
                                  : 0.5
                              }`,
                            }}
                            alt="reset"
                          ></img>{" "}
                          Reset
                        </Button>
                      ) : (
                        <Button
                          type="secondary"
                          title="View only permission"
                          style={{
                            height: '40px',
                            width:'150px',
                            color:'#fff',
                            marginLeft: 5,
                            fontSize: "16px",
                          }}
                          disabled
                          className="secondaryButton"
                        >
                          <img
                            src={resetConversationIcon}
                            style={{ paddingRight: 5 }}
                            alt="reset"
                          ></img>{" "}
                          Reset
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Row>
              </div>
            </Col>

            <Col lg={24} md={24} xs={24}>
              <Row
                gutter={[20, 20]}
                style={{
                  margin: 0,
                  background: "#fff",
                  borderRadius: 7,
                  padding:"15px 10px"
                }}
                className={`step-2-print panel-design ${
                  addQuarterlyGoals ? "h-70" : "h-80"
                }`}
              >
                <Col xs={24} md={12} lg={9}>
                  {getFiscalYearListLoader || isCheckLoading ? (
                    <Skeleton
                      active
                      style={{ padding: 20 }}
                      paragraph={{ rows: 9 }}
                    />
                  ) : (
                    <div className="total-closed-won-section" style={{display:'flex', alignItems:'center'}}>
                      {" "}
                      {/* <div style={{ textAlign: "center", paddingTop: 25 }}>
                        <img src={totalWonCountImg} alt="total won" />
                      </div> */}
                      <div
                        style={{
                          paddingTop: 22,
                          textAlign: "center",
                          flexGrow: 1,
                        }}
                      >
                        {newRevenueGoal &&
                        (marketingSourcedCount || saleSourcedCount) ? (
                          <Typography.Title level={4}>
                            <span className="total-won-text titleText" style={{fontWeight:500}}>
                              You need a total of{" "}
                            </span>
                            <span className="total-won-count" style={{color:"#7F56D9", fontWeight:700, fontSize:'40px', margin:"20px 0"}}>
                              {numberWithCommas(
                                Number.parseInt(marketingSourcedCount) +
                                  Number.parseInt(saleSourcedCount)
                              )}
                            </span>{" "}
                            <span className="total-won-text-closed" style={{color:'#F04438'}}>
                              {props.lastStageName} deals
                            </span>
                          </Typography.Title>
                        ) : (
                          <Typography.Title level={4} type="secondary">
                            Please fill in the values above to calculate the
                            closed won deal counts.
                          </Typography.Title>
                        )}
                      </div>
                    </div>
                  )}
                </Col>

                <Col xs={24} md={12} lg={15}>
                  {!getFiscalYearListLoader && (
                    <img
                      src={rightArrowImg}
                      className="right-arrow non-print"
                      alt="right"
                    />
                  )}
                  {getFiscalYearListLoader || isCheckLoading ? (
                    <Skeleton active style={{ padding: 20 }} />
                  ) : (
                    <Row
                      xs={12}
                      md={24}
                      lg={12}
                      className="marketing_source_block"
                      gutter={[20, 20]}
                      style={{ margin: 10 }}
                    >
                      <Title level={4} className="sourced-title">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span style={{ paddingLeft: 10 }}>
                              <img src={marketingSourcedImg} alt="marketing" />
                            </span>
                            <span style={{ margin: "10px 0 10px 10px" }}>
                              <strong style={{color:'#7F56D9', fontWeight:700}}>Marketing Sourced</strong>
                            </span>
                          </div>
                          <div style={{ marginRight: 20 }}></div>
                        </div>
                      </Title>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        style={{ paddingLeft: 20, paddingBottom: 10 }}
                      >
                        <Title level={5} style={{ marginTop: 0 }}>
                          <span className="sourced-amount" style={{color:'#1D2939'}}>
                            $
                            {!isFinite(marketingSourcedRevenue)
                              ? 0
                              : numberWithCommas(
                                  Number.parseInt(marketingSourcedRevenue)
                                )}
                          </span>
                        </Title>
                        <Title level={5} className="optional_icon">
                          <span className="sourced-text" style={{color:"#98A2B3"}}>Revenue</span>
                        </Title>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        style={{ paddingLeft: 20 }}
                      >
                        <Title level={5} style={{ marginTop: 0 }}>
                          <span className="marketing-won-amount" style={{color:'#7F56D9'}}>
                            {!isFinite(marketingSourcedCount)
                              ? 0
                              : numberWithCommas(
                                  Number.parseInt(marketingSourcedCount)
                                )}
                          </span>
                        </Title>
                        <Title level={5} className="optional_icon">
                          <span className="sourced-text" style={{color:"#98A2B3"}}>
                            {props.lastStageName} Count
                          </span>
                        </Title>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        style={{ paddingLeft: 20 }}
                      >
                        <Title level={5} style={{ marginTop: 0 }}>
                          <span className="sourced-amount" style={{color:'#1D2939'}}>
                            $
                            {!isFinite(
                              marketingSourcedRevenue * totalPipeLineCoverage
                            )
                              ? 0
                              : numberWithCommas(
                                  Number.parseInt(
                                    marketingSourcedRevenue *
                                      totalPipeLineCoverage
                                  )
                                )}
                          </span>
                        </Title>
                        <Title level={5} className="optional_icon">
                          <span className="sourced-text" style={{color:"#98A2B3"}}>Pipeline</span>
                        </Title>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        className="more-info-btn"
                        style={{ paddingLeft: 20, paddingTop: 19 }}
                      >
                        <Tooltip
                          placement="top"
                          title={
                            "Marketing Sourced represents the value of closed won deals Marketing will be responsible for sourcing in the year. (see Marketing funnel below to understand how many top of funnel leads you will need in order to hit your goals.) This number will adjust up or down based on revenue goal, Marketing sourced percent and average deal size."
                          }
                        >
                          <Button className="more-info non-print tertiaryButton" style={{width:'120px', height:'40px', color:'#98A2B3'}}>More info</Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  )}
                  {getFiscalYearListLoader || isCheckLoading ? (
                    <Skeleton active style={{ padding: 20 }} />
                  ) : (
                    <Row
                      xs={12}
                      md={24}
                      lg={12}
                      className="marketing_source_block"
                      gutter={[20, 20]}
                      style={{ margin: 10 }}
                    >
                      <Title
                        level={4}
                        className="sourced-title source-title-sales"
                      >
                        <span style={{ paddingLeft: 10 }}>
                          <img src={salesSourcedImg} alt="sales" />
                        </span>
                        <span style={{ margin: "10px 0 10px 10px" }}>
                          <strong style={{color:'#F04438', fontWeight:700}}>Sales Sourced </strong>
                        </span>
                      </Title>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        style={{ paddingLeft: 20, paddingBottom: 10 }}
                      >
                        <Title level={5} style={{ marginTop: 0 }}>
                          <span className="sourced-amount" style={{color:'#1D2939'}}>
                            $
                            {!isFinite(salesSourcedRevenue)
                              ? 0
                              : numberWithCommas(
                                  Number.parseInt(salesSourcedRevenue)
                                )}
                          </span>
                        </Title>
                        <Title level={5} className="optional_icon">
                          <span className="sourced-text" style={{color:"#98A2B3"}}>Revenue</span>
                        </Title>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        style={{ paddingLeft: 20 }}
                      >
                        <Title level={5} style={{ marginTop: 0 }}>
                          <span className="sales-won-amount" style={{color:'#F04438'}}>
                            {!isFinite(saleSourcedCount)
                              ? 0
                              : numberWithCommas(
                                  Number.parseInt(saleSourcedCount)
                                )}
                          </span>
                        </Title>
                        <Title level={5} className="optional_icon">
                          <span className="sourced-text" style={{color:"#98A2B3"}}>
                            {props.lastStageName} Count
                          </span>
                        </Title>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        style={{ paddingLeft: 20 }}
                      >
                        <Title level={5} style={{ marginTop: 0 }}>
                          <span className="sourced-amount" style={{color:'#1D2939'}}>
                            $
                            {!isFinite(
                              salesSourcedRevenue * totalPipeLineCoverage
                            )
                              ? 0
                              : numberWithCommas(
                                  Number.parseInt(
                                    salesSourcedRevenue * totalPipeLineCoverage
                                  )
                                )}
                          </span>
                        </Title>
                        <Title level={5} className="optional_icon">
                          <span className="sourced-text" style={{color:"#98A2B3"}}>Pipeline</span>
                        </Title>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        className="more-info-btn"
                        style={{ paddingLeft: 20, paddingTop: 19 }}
                      >
                        <Tooltip
                          placement="top"
                          title={
                            "Sales Souced represents the value of closed won deals Sales will be responsible for sourcing in the year."
                          }
                        >
                          <Button className="more-info non-print tertiaryButton" style={{width:'120px', height:'40px', color:'#98A2B3'}}>More info</Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
      <Modal
        className="delete-revenue-model"
        visible={isDeleteMetricsModelOpen}
        closable={false}
        centered
        footer={[]}
        style={{ borderRadius: "20px" }}
      >
        <div className="delete-modal-body">
          <img src={binIcon} alt="delete icon" />
          <Typography.Text style={{ marginTop: "10px", fontSize: "20px" }}>
            Are you sure you want to delete this Segment?
          </Typography.Text>
          <div className="delete-modal-footer" style={{marginTop:'20px'}}>
            <Button
              type="primary"
              size="small"
              ghost
              onClick={() => {
                handleDeleteMetrics();
              }}
              style={{ borderColor: "#7F56D9",backgroundColor: "#7F56D9", color: "#fff" }}
              loading={isLoading}
              className="primaryButton"
            >
              Yes
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={() => setisDeleteMetricsModelOpen(false)}
              disabled={isLoading}
              className="secondaryButton"
            >
              No
            </Button>
          </div>
        </div>
      </Modal>
      <Drawer
        title={
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <Space>
              <div style={{ fontWeight: "700" }}>{segmentMappingsData}</div>
              {salesLoader && <LoadingOutlined style={{ marginLeft: 10 }} />}
            </Space>
            <Tooltip
              placement="bottom"
              color={"white"}
              trigger={"click"}
              overlayStyle={{ minWidth: "300px" }}
              overlayInnerStyle={{
                padding: "10px",
                fontSize: "16px",
                marginRight: 54,
              }}
              title={
                <Space direction="vertical" style={{ fontWeight: "400" }}>
                  <div style={{ color: "black" }}>{segmentMappingsData}</div>
                </Space>
              }
            >
              <InfoCircleOutlined style={{ color: "#A9A9A9" }} />
            </Tooltip>
          </Space>
        }
        visible={metricDrawer}
        className="createAssetModal market-drawer"
        onClose={handleCloseMpppingDrawer}
      >
        <Collapse
          accordion
          expandIconPosition="right"
          className="pannel-arrow collapse-style"
          ghost={true}
        >
          {hubspotMapObj.length > 0 &&
            hubspotMapObj[0].availableContactMappings.values.length > 0 && (
              <Panel header="Hubspot" key="1">
                <div>
                  <div>
                    {hubspotMapObj.length > 0 && (
                      <span className="header-style">
                        {hubspotMapObj[0].availableContactMappings.title}
                      </span>
                    )}
                    <InfoCircleOutlined
                      style={{
                        float: "right",
                        marginTop: 12,
                        color: "#A9A9A9",
                      }}
                    />
                  </div>
                  {hubspotMapObj.length > 0 && (
                    <Select
                      mode="multiple"
                      allowClear
                      size="large"
                      value={hubLableValue}
                      style={{ width: "100%" }}
                      placeholder={`Select ${hubspotMapObj[0].availableContactMappings.title} Mappings`}
                      onChange={(e) => {
                        setHubLabelValue(e);
                        handleHubSpoteData(e);
                      }}
                      className="market-select"
                    >
                      {hubspotMapObj[0]?.availableContactMappings?.values?.map(
                        (item) => {
                          let obj = Object.keys(item);
                          return (
                            <Select.Option value={item[obj[0]]}>
                              {obj}
                            </Select.Option>
                          );
                        }
                      )}
                    </Select>
                  )}
                </div>
              </Panel>
            )}
          {testSalesData.length > 0 && (
            <Panel header="SalesForce" key="2">
              <div>
                <span className="header-style">{testSalesDataTitle}</span>
                <InfoCircleOutlined
                  style={{ float: "right", marginTop: 12, color: "#A9A9A9" }}
                />
                <Select
                  mode="multiple"
                  allowClear
                  size="large"
                  value={salesLabelValue}
                  style={{ width: "100%" }}
                  placeholder={`Select ${testSalesDataTitle}  Mappings`}
                  onChange={(e) => {
                    setSalesLabelValue(e);
                    handleSalesSpoteData(e);
                  }}
                >
                  {testSalesData.map((item) => {
                    let obj = Object.keys(item);
                    return (
                      <Select.Option value={item[obj[0]]}>{obj}</Select.Option>
                    );
                  })}
                </Select>
              </div>
            </Panel>
          )}
        </Collapse>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            zIndex: 2,
            width: "100%",
            borderTop: "1px solid #E9E9E9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
            display: "flex",
          }}
        >
          <Button
            type="primary"
            size="small"
            ghost
            style={{
              borderColor: "#0F61DB",
              color: "#0F61DB",
              fontSize: 18,
              marginRight: 8,
              width: "48%",
            }}
            onClick={handleCloseMpppingDrawer}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            // loading={loader}
            size="large"
            type="primary"
            style={{ fontSize: 18, width: "48%" }}
            onClick={() => SaveMappingConfig()}
            disabled={isLoading}
          >
            Save
          </Button>
        </div>
      </Drawer>
      <Drawer
        title={
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <Space>
              <div style={{ fontWeight: "700" }}>Marketing Sourced Mapping</div>
              {marketLoader && <LoadingOutlined style={{ marginLeft: 10 }} />}
            </Space>
            <Tooltip
              placement="bottom"
              color={"white"}
              trigger={"click"}
              overlayStyle={{ minWidth: "300px" }}
              overlayInnerStyle={{
                padding: "10px",
                fontSize: "16px",
                marginRight: 54,
              }}
              title={
                <Space direction="vertical" style={{ fontWeight: "400" }}>
                  <div style={{ color: "black" }}>
                    Marketing Sourced Mapping
                  </div>
                </Space>
              }
            >
              <InfoCircleOutlined style={{ color: "#A9A9A9" }} />
            </Tooltip>
          </Space>
        }
        visible={MarketingSourced}
        className="createAssetModal market-drawer"
        onClose={handleCloseMarketingMpppingDrawer}
      >
        <Collapse
          accordion
          expandIconPosition="right"
          className="pannel-arrow collapse-style"
          ghost={true}
        >
          {hubMarketValues && (
            <Panel header="Hubspot" key="1">
              <div>
                <div>
                  {hubMarketValues && (
                    <span className="header-style">
                      {hubMarketValues.title}
                    </span>
                  )}
                  <InfoCircleOutlined
                    style={{ float: "right", marginTop: 12, color: "#A9A9A9" }}
                  />
                </div>
                <Select
                  mode="multiple"
                  allowClear
                  size="large"
                  value={savedHubsValue}
                  style={{ width: "100%" }}
                  placeholder={`Select ${hubMarketValues.title} Mappings`}
                  onChange={(e) => {
                    setSavedHubsValue(e);
                    handleHubMarketingData(e);
                  }}
                  className="market-select"
                >
                  {hubMarketValues &&
                    hubMarketValues.values.map((item) => {
                      let obj = Object.keys(item);
                      return (
                        <Select.Option value={item[obj[0]]}>
                          {obj}
                        </Select.Option>
                      );
                    })}
                </Select>
              </div>
            </Panel>
          )}
          {salesMarketValues && (
            <Panel header="SalesForce" key="2">
              <div>
              <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', margin:'12px 0'}}>
                <span className="header-style" style={{fontSize:'15px'}}>{salesMarketValues.title}</span>
                <InfoCircleOutlined
                  style={{ float: "right", marginTop: 0, color: "#A9A9A9" }}
                />
                </div>
                <Select
                  mode="multiple"
                  allowClear
                  size="large"
                  value={savedSalesValue}
                  style={{ width: "100%" }}
                  placeholder={`Select ${salesMarketValues.title} Mappings`}
                  onChange={(e) => {
                    setSavedSalesValue(e);
                    handleSalesMarketingData(e);
                  }}
                  className="general-input-select"
                >
                  {salesMarketValues.values.map((item) => {
                    let obj = Object.keys(item);
                    return (
                      <Select.Option value={item[obj[0]]}>{obj}</Select.Option>
                    );
                  })}
                </Select>
              </div>
            </Panel>
          )}
        </Collapse>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            zIndex: 2,
            width: "100%",
            borderTop: "1px solid #E9E9E9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
            display: "flex",
            alignItems:"center",
            justifyContent:"center"
          }}
        >
          <Button
            type="primary"
            size="small"
            ghost
            style={{
              borderColor: "#EC4A0A",
              backgroundColor: "#EC4A0A",
              color: "#fff",
              fontSize: 18,
              marginRight: 8,
              width: "48%",
            }}
            onClick={handleCloseMarketingMpppingDrawer}
            disabled={isLoading}
            className="secondaryButton"
          >
            Cancel
          </Button>
          <Button
            loading={loader}
            size="large"
            type="primary"
            style={{ fontSize: 18, width: "48%" }}
            onClick={() => SaveMarketMappingConfig()}
            disabled={isLoading}
            className="primaryButton"
          >
            Save
          </Button>
        </div>
      </Drawer>
    </Fragment>
  );
}

export default BaselineCompanyMetrics;
