import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Input, Drawer, Tooltip, message, Select, InputNumber } from "antd";
import { API } from "aws-amplify";
import { InfoCircleOutlined } from "@ant-design/icons";
import { setServerError } from "store/actions/budgetAction";
import {
  updatePackagePropertyValue,
  listPropertyPackageData,
} from "store/actions/reportAction";

const EditProperty = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { isLoading, serverError } = useSelector(
    (state) => state.reportReducer
  );

  const [propertyInfo, setPropertyInfo] = useState([]);
  const getProperties = () => {
    const prperty_path = `/properties/default_properties`;
    const resultInArrayProperty = [];
    try {
      API.get("exploricsREST", prperty_path)
        .then((result) => {
          for (const [key, value] of Object.entries(result)) {
            resultInArrayProperty.push({ name: key, value: value['default_value'], options:value['possible_values'], type:value['type'] });
          }
          setPropertyInfo(resultInArrayProperty);
        })
        .catch((e) => {
          console.log("Error :", e);
        });
    } catch (e) {
      console.log("Error :", e);
    }
  };
  useEffect(() => {
    getProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedPropertyInfo, setSelectedPropertyInfo] = useState()
  useEffect(() => {
    const propInfoSelected = propertyInfo.find((val) => val.name === props.valueObj.key)
    setSelectedPropertyInfo(propInfoSelected)
  }, [propertyInfo,props.valueObj.key, props.visibleEdit])

  useEffect(() => {
    form.setFieldsValue({ property_name: props.valueObj.key });
    form.setFieldsValue({ property_value: props.valueObj.value });
  }, [props]); // eslint-disable-line

  const handleOk = (value) => {
    value.packageId = props.selectedPackageId;
    value.id = props.valueObj.id;
    dispatch(updatePackagePropertyValue(value));
  };

  useEffect(() => {
    if (serverError === "success") {
      form.resetFields();
      message.success("Successfully updated");
      props.handleCancelPropertyEdit();
      dispatch(setServerError(""));
      dispatch(listPropertyPackageData());
    }
  }, [serverError]); // eslint-disable-line

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        handleOk(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <Drawer
        title={<strong>Edit Property Value</strong>}
        width={416}
        onClose={props.handleCancelPropertyEdit}
        visible={props.visibleEdit}
        bodyStyle={{ paddingBottom: 80 }}
        maskClosable={false}
        className="create-package-drawer"
      >
        {/* {isLoading && <div className="loading">Loading;</div>} */}
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
            property_name: props.valueObj.key,
          }}
          requiredMark={"optional"}
        >
          <Form.Item
            name="property_name"
            label={
              <span>
                <strong>Property Name&nbsp;</strong>
                <Tooltip title="info" placement="left">
                  <InfoCircleOutlined style={{ float: "right" }} />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              }
            ]}
          >
            <Input style={{ marginBottom: 10 }} disabled />
          </Form.Item>
          <Form.Item
            name="property_value"
            label={
              <span>
                <strong>Property Value&nbsp;</strong>
                <Tooltip title="info" placement="left">
                  <InfoCircleOutlined style={{ float: "right" }} />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
              {
                pattern: /^[a-zA-Z0-9_ ]+$/,
                message: "Special charactors are not allowed.",
              },
            ]}
          >
            {selectedPropertyInfo?.type === "select" ? <Select
              size="large"
              // showSearch
              style={{ width: "100%" }}
              placeholder="Select properties"
              defaultValue={props.valueObj.value}
              key="property_value"
              // value={props.valueObj.value}
            >
              {selectedPropertyInfo?.options &&
                selectedPropertyInfo?.options.length > 0 &&
                selectedPropertyInfo?.options?.map((item, indexval) => {
                  return (
                    <Select.Option key={indexval} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
            </Select>:selectedPropertyInfo?.type === "int" ? <InputNumber style={{ marginBottom: 10, width:"100%" }} min = {1} /> : <Input style={{ marginBottom: 10 }} />}


          </Form.Item>

        </Form>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
          }}
        >
          <Button
            className="secondary-btn"
            onClick={props.handleCancelPropertyEdit}
            style={{ fontSize: 18, marginRight: 8, width: "48%" }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            type="primary"
            style={{ fontSize: 18, width: "48%" }}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default EditProperty;
