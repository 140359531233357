/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  List,
  message,
  Avatar,
  Typography,
  Row,
  Col,
  Checkbox,
  Modal,
  Tooltip,
  Table,
  Tabs,
  Skeleton,
  Popover,
  Space,
  Drawer,
  Radio,
  Select,
  Divider,
  Collapse,
} from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { updateWebProfiles } from "graphql/mutations";
import { useHistory, useParams } from "react-router-dom";
import {
  getConnectedCRMs,
  getCRMsyncHistory,
} from "store/actions/reportAction";
import {
  CloseOutlined,
  InfoCircleOutlined,
  Loading3QuartersOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import { onCreateCrmSyncHistory } from "graphql/subscriptions";
import { swapKeyValue } from "utility/context/CommonMethods";
import { connectionURLs, crmConnections } from "utility/Constants";
import _ from "lodash";
import "./Setting.less";
import { integrateData } from "utility/Constants";
import {TrackClickFunction} from '../../commons/HeapTrack/TrackClickFunction'
const { Title, Text } = Typography;
const { confirm } = Modal;
const { Option } = Select;
const { Panel } = Collapse;
const defaultConfig = {
  Hubspot: {
    "Stage Mapping": [
      {
        objectName: "Contact",
        value: "Lifecycle Stage",
        fields: [
          {
            type: "radio",
            label: "Lifecycle Stage",
            value: "Lifecycle Stage",
            disabled: true,
            displaySelect: false,
            tooltip: "Choose the Lifecyle Stage field from the Hubspot Contact object to use for this mapping within Demand Gen.",
          },
        ],
      },
      {
        objectName: "Deal",
        value: "Deal Stage",
        fields: [
          {
            type: "radio",
            label: "Deal Stage",
            value: "Deal Stage",
            disabled: true,
            displaySelect: false,
            tooltip: "Choose the Deal Stage field from the Hubspot Deal object to use for this mapping within Demand Gen.",
          },
        ],
      },
    ],
    "Segment Mapping": [
      {
        objectName: "Contact",
        isCustomField: true,
        value: "custom field 3",
        fields: [
          {
            type: "radio",
            label: "Custom Field",
            value: "Custom Field",
            displaySelect: true,
            options: [
              "custom field 1",
              "custom field 2",
              "custom field 3",
              "custom field 4",
            ],
            tooltip: "Choose a custom field from the Hubspot Contact object to use for this mapping within Demand Gen.",
          },
        ],
      },
    ],
    "Marketing Sourced Mapping": [
      {
        objectName: "Contact",
        value: null,
        isCustomField: true,
        fields: [
          {
            type: "radio",
            label: "Custom Field",
            value: "Custom Field",
            displaySelect: true,
            options: [
              "custom field 1",
              "custom field 2",
              "custom field 3",
              "custom field 4",
            ],
            tooltip: "Choose a custom field from the Hubspot Contact object to use for this mapping within Demand Gen.",
          },
        ],
      },
    ],
  },
  Salesforce: {
    "Stage Mapping": [
      {
        isCustomField: false,
        value: "Lead Status",
        objectName: "Lead",
      },
      {
        isCustomField: false,
        value: "Opportunity Stage",
        objectName: "Opportunity",
      },
    ],
    "Segment Mapping": [
      {
        isCustomField: false,
        value: "No Segment Mapping Required",
        objectName: "NotRequired",
      },
    ],
    "Marketing Sourced Mapping": [
      {
        isCustomField: false,
        value: "Lead Source",
        objectName: "Lead",
      },
    ],
    "Campaign Mapping": [
      {
        isCustomField: false,
        value: "Salesforce Campaign",
        objectName: null,
      },
    ],
    "Pipeline and Revenue Mapping": [
      {
        isCustomField: false,
        value: "Amount",
        objectName: "Opportunity",
      },
    ],
  },
};

const hubspotTooltip= {
  "Stage Mapping" :{
    value : "Demand Generation Stages are mapped within Sales & Marketing Alignment using CRM fields specified here."
  },
  "Segment Mapping" :{
    value : "Demand Generation Segments are mapped within Revenue Planning (step #1) using CRM fields specified here."
  },
  "Marketing Sourced Mapping" :{
    value : "Demand Generation Marketing Sourced is mapped within Revenue Planning (step #2) using CRM fields specified here."
  }
}



const defaultCampaignFieldnValue = [
    [
        "Original Source fields",
        "Original Source",
        "org_source",
        "Original Source Drilldown 1",
        "org_source_dd_1",
        "Original Source Drilldown 2",
    "org_source_dd_2",
        null
    ],
    [
        "Lead Source fields",
        "Lead Source",
        "lead_source",
        "Lead Source Detail",
        "lead_source_detail_1",
        "Lead Source Detail 2",
      "lead_source_detail_2",
        null
    ]
]

let tempStageList = []
let tempSalesStageList = []

const Integration = (props) => {
  const{content} = useParams();

  let isContent = content?true:false;

  const handleConfigChange = (mappingType, isCustom, value, objIndex) => {
    Object.entries(tempHubspotObj).forEach(([key , values]) => {
      values.forEach(data => {
          if(key === mappingType){
            if(data.value !==value){
                if(tempStageList.length>0){
                  if (!tempStageList.includes(mappingType)) {
                    tempStageList.push(mappingType);
                  }
                }
                else{
                tempStageList.push(mappingType)

                }
              }
              else{
                tempStageList.splice(tempStageList.indexOf(mappingType), 1);
              }
            }
          })})
          setHubSpotMessageObj(tempStageList)
    const updateConfig = _.cloneDeep(config);
    const obj = updateConfig !== undefined && updateConfig[mappingType][objIndex];
    obj.isCustomField = isCustom;
    obj.value = value;
    obj.label = mappingType
    setConfig(updateConfig);
  };
  const handleUpdateChange=  (value) => {
    if(value[0] !== btnMessageValue[0]){
      if(tempStageList.length>0){
        if (!tempStageList.includes("Campaign Mapping")) {
          tempStageList.push("Campaign Mapping");
        }
      }else{
        tempStageList.push("Campaign Mapping")
      }
    }
    else{
      tempStageList.splice(tempStageList.indexOf("Campaign Mapping"), 1);
    }
    setHubSpotMessageObj(tempStageList)
   }
   const handleHubSpotUpdateChange = (value) => {
    value.forEach((item)=>{
      if(defaultLabelValue.length > 0){
        if(!defaultLabelValue.includes(item) || value.length !== defaultLabelValue.length ){
          if (!tempStageList.includes("Campaign Mapping")) {
            tempStageList.push("Campaign Mapping");
          }
        }
        else{
          if (tempStageList.includes("Campaign Mapping")) {
            tempStageList.splice(tempStageList.indexOf("Campaign Mapping"), 1);
          }
        }
      }
    })
    setHubSpotMessageObj(tempStageList)

   }


  // FOLLOW THIS FOR NEW CRM

  // 1:Set Connection URL
  // 2:Set name to Enum
  // 3:set Data in AllCrms
  // 4:set Option in useEffect after connection done.

  // INIT

  const { ConnectedCRMs } = useSelector((state) => state.reportReducer);
  const [isLoading, setisLoading] = useState(false);
  const [selectedcrm, setselectedcrm] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.loginReducer.groups);
  const [ShowSyncHistoryModal, setShowSyncHistoryModal] = useState(false);
  const { TabPane } = Tabs;
  const [AllSyncHistory, setAllSyncHistory] = useState({});
  const { groups, viewType } = useSelector((state) => state.loginReducer);
  const [activeTab, setActiveTab] = useState("crm");
  const [openConfig, setOpenConfig] = useState("");
  const [configBackup, setConfigBackup] = useState({});
  const [tempHubspotObj, setTempHubspotObj] = useState([])
  const [segementBtnValue, setSegmentBtnValue] = useState("")
  const [segmentOptionChangeValue, setSegmentOptionChangeValue] = useState("")
  const [hubSpotMessageObj,setHubSpotMessageObj] = useState([])
  const [saleForceMessageObj,setSaleForceMessageObj] = useState([])
  const [config, setConfig] = useState(defaultConfig.Hubspot); //for hubspot
  const [salesforceConfigValue, setSaleforceConfigValue] = useState(defaultConfig.Salesforce); //forSalesForce
  const [tempSalesForceObj,setTempSalesForceObj] = useState([])
  const [salesforceConfigValueBackup, setSaleforceConfigValueBackup] = useState(
    {}
  ); //forSalesForce

  console.log("salesforceConfigValue",salesforceConfigValue);

  let hubspotID = ConnectedCRMs?.["Hubspot"] !== undefined ? ConnectedCRMs?.["Hubspot"]?.map((item) => item?.profile_id) : null
  let getSalseId = ConnectedCRMs?.["Salesforce"] !== undefined ? ConnectedCRMs?.Salesforce.map((item) => item?.profile_id) : null
  const handleSalesForceConfigChange = (
    mappingType,
    Value,
    objIndex = 0,
    object_name,
    isCustom,
    custumValue
  ) => {

    Object.entries(tempSalesForceObj).forEach(([key, values]) => {
      values.forEach(data => {

        if(key === mappingType && data.value !== "Opportunity Stage" ){
            if(data.value !==Value ){
                if(tempSalesStageList.length>0){
                  if (!tempSalesStageList.includes(mappingType)) {
                    tempSalesStageList.push(mappingType);
                  }
                }
                else{
                  tempSalesStageList.push(mappingType)

                }
              }
              else{
                tempSalesStageList.splice(tempStageList.indexOf(mappingType), 1);
            }
          if (Value === "Lead_Custom_Field" || Value === "Contact_Custom_Field" || Value==="Opportunity_Custom_Field") {
            if (data.value === custumValue) {
              tempSalesStageList.splice(tempStageList.indexOf(mappingType), 1);
            }
          }
            }
          })})
          setSaleForceMessageObj(tempSalesStageList)
    let isCustomField = isCustom;
    let objectName = object_name;
    let value = Value;
    if (!objectName || !isCustomField) {
      if (Value.includes("Custom_Field")) {
        isCustomField = true;
        objectName = Value.split("_")[0];
        value = null;
      }
    }
    const latestConfig = _.cloneDeep(salesforceConfigValue);
    const newObj = {
      isCustomField: isCustomField,
      objectName: objectName,
      value: value,
    };

    latestConfig[mappingType][objIndex] = newObj;
    setSaleforceConfigValue(latestConfig);
  };
  // 1:
  const ConnectionUrls = useMemo(() => connectionURLs, []);
  useEffect(() => {
    if (openConfig === "Hubspot") {
      const config = _.cloneDeep(defaultConfig["Hubspot"]);
      setConfig(config);
      setConfigBackup(config);
    }
    if (openConfig === "Salesforce") {
      const salesforceConfig = _.cloneDeep(defaultConfig["Salesforce"]);
      console.log("salesforceConfig",salesforceConfig);
      setSaleforceConfigValue(salesforceConfig);
      setSaleforceConfigValueBackup(salesforceConfigValueBackup);
    }
  }, [openConfig]);

  //2:
  const nameToEnumMap = {
    Hubspot: "HUBSPOT",
    GA: "GOOGLE_ANALYTICS",
    LinkedIn: "LINKEDIN",
    Salesforce: "SALESFORCE",
    GoogleAds: "GOOGLE_ADS",
    Facebook: "FACEBOOK",
  };

  //3 :
  const [AllCRMs, setAllCRMs] = useState(crmConnections);

  // useEffect(() => {
  //   // Retrieve the stored URL from session storage
  //   const redirectUrl = sessionStorage.getItem("connectRedirectUrl");
  //   if (redirectUrl) {
  //     // Clear the stored URL from session storage
  //     sessionStorage.removeItem("connectRedirectUrl");
  //     // Redirect the user back to the stored URL
  //     window.location.href = redirectUrl;
  //   }
  // }, []);

  // CONNECT CRM
  const HandleConnectCRM = (platform) => {
    //sessionStorage.setItem("connectRedirectUrl", window.location.href);
    if(isContent){
      if(platform === 'Salesforce'){

        window.open(ConnectionUrls[`${platform}Content`]);
      }else{
        window.open(ConnectionUrls[platform]);
      }
    }else{
      window.open(ConnectionUrls[platform]);
    }
  };

  // 4:
  // After Connection done redirection
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    
    async function fetchCRMData(option) {
      if (params.has("code")) {
        if (["ga", "LinkedIn", "googleads", "facebook"].includes(option)) {
          setActiveTab("marketing");
        } else {
          setActiveTab("crm");
        }
        const code = params.get("code");
        var response = await API.get(
          "exploricsREST",
          `/integrations/${option}/auth?code=${code}&account=${account?.[0]}`,
          {}
        );

        if (response.errors || response.error) {
          message.error(response.errors || response.error);
        } else {
          if (response[0].platform === "Salesforce") {

            getSalseId = [response[0].profile_id]
          }
          if (response[0].platform === "Hubspot") {
            hubspotID = [response[0].profile_id]
          }
          await HandleGetConnectedCRMs();
          await getHubspotCustomeValue()
          await getHubspotCampaignMapping(response[0])
          await HandleDefaultIntegrationChecked(response[0])
        }
      }
    }
    if (params.has("state")) {
      const option = params.get("state");
      fetchCRMData(option);
    }
    for (var key of params.keys()) {
      params.delete(key);
    }
    history.replace({
      search: params.toString(),
    });
    if (params.has("error")) {
      history.replace({
        search: "",
      });
    }
    // eslint-disable-next-line
  }, [window.location.search]);

  const [getCRMsLoader, setgetCRMsLoader] = useState(false);

  // GET CONNECTEDCRM
  const HandleGetConnectedCRMs = () => {
    setgetCRMsLoader(true);
    dispatch(getConnectedCRMs())
      .then(async (res) => {
        setgetCRMsLoader(false);
        await HandleGetCRMSyncHistory(res);
      })
      .catch((err) => {
        console.log("Error:", err);
        setgetCRMsLoader(false);
      });
  };

  useEffect(() => {
    HandleGetConnectedCRMs();
  }, []);

  // SET CRMS
  useEffect(() => {
    let dataObj = {};
    Object.keys(AllCRMs).forEach((key) => {
      dataObj[key] = ConnectedCRMs[key]
        ? { integrations: ConnectedCRMs[key], ...AllCRMs[key] }
        : crmConnections[key];
    });

    setAllCRMs((pre) => dataObj);
  }, [ConnectedCRMs]);



  // GET CONNECTEDCRM SYNC HISTORY
  const HandleGetCRMSyncHistory = async (ConnectedCRMs) => {
    setisLoading(true);
    let dataObj = {};

    let ApiInputData = [];

    Object.keys(ConnectedCRMs).forEach((key) => {
      ConnectedCRMs[key]?.map(async (item) => {
        let metaData = JSON.parse(item?.metadata);
        let inputData = {
          crm:
            item?.platform === "GA"
              ? nameToEnumMap[item?.platform] + "_" + metaData?.type
              : nameToEnumMap[item?.platform],
          sortDirection: "DESC",
          filter: {
            account: { eq: account[0] },
            profile_id: { eq: item?.profile_id },
          },
        };
        ApiInputData.push(inputData);
      });
    });

    Promise.all(ApiInputData.map((item) => dispatch(getCRMsyncHistory(item))))
      .then((res) => {
        res.forEach((item, index) => {
          dataObj[ApiInputData[index]?.filter?.profile_id?.eq] = item;
        });
      })
      .then((_) => {
        setAllSyncHistory((old) => ({ ...old, ...dataObj }));
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    return dataObj;
  };

// Syncup DEFAULT CRM
  const syncDefaultCRM = async(inputData) => {
    const path = "/mapping/syncupDefaultCrmStageMapping";
    await API.post("backendRest", path, inputData).then(() => {
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setisLoading(false);
      });
  }
  // SYNC CRM
  const syncCRM = async (data) => {
    setisLoading(true);
    const path = "/integrations/sync";
    let metaData = JSON.parse(data?.metadata);
    let init = {
      body: {
        account: data.account,
        profile_id: data.profile_id,
        crm: data.platform === "GA" ? metaData.type : data.platform,
      },
    };

    await API.post("exploricsREST", path, init)
      .then(() => {
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setisLoading(false);
      });
  };

  // SET SYNC HISTORY ON subscription
  const setSyncHistoryOnSubscribe = (event) => {
    let crmtype = event.crm;
    let inputData = {};
    let enumToNameMap = swapKeyValue(nameToEnumMap);
    if (String(crmtype).includes("GOOGLE_ANALYTICS")) {
      inputData[enumToNameMap["GOOGLE_ANALYTICS"]] =
        ConnectedCRMs[enumToNameMap["GOOGLE_ANALYTICS"]];
      HandleGetCRMSyncHistory(inputData);
    } else {
      inputData[enumToNameMap[crmtype]] = ConnectedCRMs[enumToNameMap[crmtype]];
      HandleGetCRMSyncHistory(inputData);
    }
  };

  // subscription for updating CRM status
  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateCrmSyncHistory)
    ).subscribe({
      next: ({ provider, value }) => {
        setSyncHistoryOnSubscribe(value?.data?.onCreateCrmSyncHistory);
      },
      error: (error) => console.warn(error),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Default sync crm  for the connection
    const HandleDefaultIntegrationChecked = async (data) => {
    await API.graphql(
      graphqlOperation(updateWebProfiles, {
        input: {
          id: data.id,
          enabled: true,
        },
      })
    )
      .then(async (_) => {
        syncCRM(data);
         let inputData =
          {
            "account": account[0],
            "platform_id": data.profile_id,
            "platform": data.platform
          }
          let init = {
            body: inputData,
          };
         if (data.platform === "Salesforce") {
            await saveSalesForceDefaultData()
          }
          if (data.platform === "Hubspot") {
            await SaveDefaultHubSpotData()
          }
          syncDefaultCRM(init)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // HANDLE CHECK UNCHECK BUTTON
  const HandleIntegrationChecked = async (e, data) => {
    API.graphql(
      graphqlOperation(updateWebProfiles, {
        input: {
          id: data.id,
          enabled: e.target.checked,
        },
      })
    )
      .then((_) => {
        if (e.target.checked) {
          syncCRM(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // COULMNS FOR HISTORY MODEL TABLE
  const columns = useMemo(
    () => [
      {
        title: (
          <small>
            <strong style={{ fontWeight: 600 }}>Time</strong>
          </small>
        ),
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (data) => <small>{new Date(data).toLocaleString()}</small>,
      },
      {
        title: (
          <small>
            <strong style={{ fontWeight: 600 }}>Status</strong>
          </small>
        ),
        dataIndex: "status",
        key: "status",
        render: (data) => <small>{data}</small>,
      },
      {
        title: (
          <small>
            <strong style={{ fontWeight: 600 }}>Source</strong>
          </small>
        ),
        dataIndex: "source",
        key: "source",
        render: (data) => {
          if (data === "FRONT_END") {
            data = "User";
          } else if (data === "AUTO") {
            data = "System";
          }
          return <small>{data}</small>;
        },
      },
    ],
    []
  );

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const syncAll = (platform) => {
    if (ConnectedCRMs[platform]?.filter((item) => item?.enabled)?.length > 0) {
      setisLoading(true);
      Promise.all(
        ConnectedCRMs[platform]
          ?.filter((item) => item?.enabled)
          .map((item) => syncCRM(item))
      )
        .then(() => {
          setisLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setisLoading(false);
        });
    }
  };

  const disconnectCRM = async (item) => {
    setisLoading(true);

    try {
      // return 1;
      await API.del(
        "exploricsREST",
        `/integrations?account=${account?.[0]}`,
        { body: { crm: item[0]?.platform } }
      );
      let inputData = {
        "account": account?.[0],
        "platform_id": item[0]?.profile_id,
        "platform": item[0]?.platform
      }
      let init = {
            body: inputData,
          };
      await API.post("backendRest","/mapping/removeCRMConnections",init)

      HandleGetConnectedCRMs();
      message.success(item[0]?.platform + " disconnected Successfully.");
      setisLoading(false);
    } catch (e) {
      setisLoading(false);
      console.log("error Ocured During disconnecting CRM", e);
    }
  };

  const handleDisconnectCrm = async (data) => {
    confirm({
      title: (
        <Typography.Title level={4}>
          Are you sure you would like to continue?
        </Typography.Title>
      ),
      icon: <ExclamationCircleOutlined className="custom-info-icon"/>,
      content: (
        <Typography.Text level={5}>
          Disconnecting this integration will remove it from Demand Gen.
        </Typography.Text>
      ),
      okText: "Continue",
      okType: "primary",
      cancelText: "No",
      cancelButtonProps: { className: "custom-button-style" },
      onOk() {
        disconnectCRM(data);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleCloseConfigDrawer = () => {
    setConfig(configBackup);
    setSaleforceConfigValue(salesforceConfigValueBackup);
    setOpenConfig("");
    setHubSpotMessageObj([])
    setLabelValue([])
    tempStageList = []
    tempSalesStageList = []
    setSaleForceMessageObj([])
  };


  //salseforce mapping
  const [leadValue,setLeadValue] = useState([])
  const [contactValue,setContactValue] = useState([])
  const [opportunityAmountValue,setopportunityAmountValue] = useState([])


  //getStageMapping value
  const getStageMapping = async() => {
    if(ConnectedCRMs?.Salesforce !== undefined){
      let path = `/mapping/salesforce/getStageMappingAvailableOptions?sf_id=${getSalseId}`
      await API.get("backendRest",path).then(async(res)=> {
       await getSelectedSalesForceData(res?.Lead,res?.Contact)
        setLeadValue(res?.Lead)
        setContactValue(res?.Contact)
        setopportunityAmountValue(res?.['Opportunity Amount Fields'])
      }).catch((err) => {
        console.log(err)
      })
      }
    }

  //hubspot mapping
  const [hubspotContactValue,setHubspotContactValue] = useState([])
  const [campaignFeildValue,setCampaignFieldValue] = useState(defaultCampaignFieldnValue)
  const [btnValue,setBtnValue] = useState([])
  const [btnMessageValue,setBtnMessageValue] = useState([])
  const [labelValue,setLabelValue] = useState([])
  const [defaultLabelValue,setDefaultLabelValue] = useState([])
  const [customeField,setCustomeField] = useState({label : "" , value : [] , mainLabel : integrateData?.Campaign })
  const [campaignCustomValue,setCampaignCustomValue] = useState([])
  const [loader,setLoader] = useState(false)
  const [dataLoader,setDataLoader] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false);
//  Open Confirm
const showConfirm = () => {
  setConfirmVisible(true);
};
const handleConfirmCancel = () => {
  setConfirmVisible(false);
};


 //for hubspot contact value mapping
  const getHubspotCustomeValue = async() => {
    if(ConnectedCRMs?.Hubspot !== undefined){
      let hubspotID = ConnectedCRMs?.["Hubspot"].map((item) => item?.profile_id)
      let path = `/mapping/hubspot/getCustomProperties?hub_id=${hubspotID}`
      await API.get("backendRest",path).then((res)=> {
        setHubspotContactValue(res?.contact)
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  //hubspot campaign mapping
  let defUltAvailableData  = []
  const getHubspotCampaignMapping = async (data) => {
    if (ConnectedCRMs?.Hubspot !== undefined || data?.platform ===  "Hubspot") {
      let hubspotID =  ConnectedCRMs?.Hubspot ? ConnectedCRMs?.Hubspot.map((item) => item?.profile_id) : data?.profile_id
      let path = `/mapping/hubspot/getCampaignMappingAvailableOptions?hub_id=${hubspotID}`
      await API.get("backendRest",path).then(async(res)=> {
        setCampaignCustomValue(res?.custom)
        if (res?.default && res?.default?.[0]?.length > 0 && res?.default?.[1]?.length > 0) {
          setCampaignFieldValue(res?.default)
          await getSelectedData(res?.default,res?.custom)
        } else {
           await getSelectedData(campaignFeildValue,res?.custom)
        }
        defUltAvailableData = res?.default
      }).catch((err) => {
        console.log(err)
      })
    }
  }

//for hubspot values
  const MappingConfigs = async(configPlatForm) => {
    if(hubspotID !== null){
      let hubID =  parseInt(hubspotID[0])
      setLoader(true)
      setOpenConfig("");
      let platformName = configPlatForm
      let temp = []
      Object.entries(config).forEach(([key , value]) => {
        value.map(data => {
          if(data?.value !== null){
            if(key === integrateData?.SegmentMapping){
              if(data.value === "No Segment Mapping Required"){
                temp.push({
                          account_id : account[0],
                            platform_id : hubID,
                            platform : platformName,
                            category : key,
                            mapping_field : data?.value,
                            type : "NotRequired"
                })
              }
              else {
                  temp.push({
                    account_id: account[0],
                    platform_id: hubID,
                    platform: platformName,
                    category: key,
                    mapping_field: data?.value,
                    type: data?.objectName
                  }
                  )
              }
            }else{
              temp.push({
                account_id : account[0],
                  platform_id : hubID,
                  platform : platformName,
                  category : key,
                  mapping_field : data?.value,
                  type : data?.objectName
              })
            }
          }
        return value
        })
      })
      if(customeField?.label !== ""){
        let customeFieldValue = customeField?.label === "Custom Field" ? customeField?.value : [customeField?.value[2],customeField?.value[4], customeField?.value[6]]
        customeFieldValue.map((item) => {
          temp.push({
                  account_id : account[0],
                    platform_id : hubID,
                    platform : platformName,
                    category : customeField?.mainLabel,
                    mapping_field : item,
                    type : customeField?.label.split(" ")[0]
                })
        return  customeFieldValue
        })
      }
      await API.post("backendRest","/mapping/mapping_configs",{
       body: {
        mapping_configs : temp
       }
      }).then(async(res)=> {
        await getSelectedData(campaignFeildValue,campaignCustomValue)
        setLoader(false)
        setConfirmVisible(false);
        setHubSpotMessageObj([])
        tempStageList = []
        message.success("Hubspot values updated successfully")
      }).catch((err) => {
        console.log(err)
        setLoader(false)
        setHubSpotMessageObj([])
        tempStageList = []
        setConfirmVisible(false);
      })

    }
  }

  //for salseforce values
  const SalesforceMappingConfig = async(configPlatForm) => {
    if(getSalseId !== null){
      let sfID = parseInt(getSalseId[0])
      setLoader(true)
      setOpenConfig("");
    let platformName = configPlatForm
    let temp = []
    Object.entries(salesforceConfigValue).forEach(([key , value]) => {
    value.map(data => {
      if(data?.value !== "Opportunity Stage"){
        let LeadObj = ""
        if(data?.value === "Lead Status"){
          LeadObj = "LeadStatus"
        }
        else if(data?.value === "Lead Source"){
          LeadObj = "LeadSource"
        }
        else if(data?.value === "No Segment Mapping Required"){
          LeadObj = "NotRequired"
        }
        else if(data?.value === "Amount"){
          LeadObj = "Opportunity"
        }
        else{
          LeadObj = data?.objectName
        }
        temp.push({
          account_id: account[0],
          platform_id : sfID,
          platform: platformName,
          category : key,
          mapping_field: data?.value,
          // type : data?.value !== "Lead Status" ? data?.objectName : "LeadStatus"
          type : LeadObj
        })
      }
      return value
    })
    })

    await API.post("backendRest","/mapping/mapping_configs",{
      body: {
       mapping_configs : temp
      }
     }).then(async(res)=> {
       setConfirmVisible(false);
      message.success("SalseForce values updated successfully")
      tempSalesStageList = []
      setSaleForceMessageObj([])
    //  await getSelectedSalesForceData()
    await getStageMapping()
     await getSelectedData()
     setLoader(false)
     }).catch((err) => {
       console.log(err)
       setLoader(false)
       tempSalesStageList = []
       setSaleForceMessageObj([])
       setConfirmVisible(false);
     })
    }
}
  //Save the default data when the user first time conncet
  const SaveDefaultHubSpotData = async () => {
        let platformName = "Hubspot"
        let hubID =  parseInt(hubspotID[0])
        let HubsTempTest = []
        let defaultData = { ...defaultConfig["Hubspot"] }
        Object.entries(defaultData).forEach(([key, value]) => {
          value.map(data => {
              if (key === integrateData?.Market) {
                HubsTempTest.push({
                  account_id: account[0],
                  platform_id: hubID,
                  platform: platformName,
                  category: key,
                  mapping_field: "lead_source",
                  type: data?.objectName
                })
              }
             else if (key === integrateData?.SegmentMapping) {
               HubsTempTest.push({
                  account_id: account[0],
                  platform_id: hubID,
                  platform: platformName,
                  category: key,
                  mapping_field: "No Segment Mapping Required",
                  type: "NotRequired"
                })
            }
              else {
                HubsTempTest.push({
                  account_id: account[0],
                  platform_id: hubID,
                  platform: platformName,
                  category: key,
                  mapping_field: data?.value,
                  type: data?.objectName
                })
              }
            return value
          })
        })
    if (customeField?.label === "") {
          let customeFieldValue = customeField?.label === "Custom Field" ? customeField?.value : [defUltAvailableData?.[0]?.[2], defUltAvailableData?.[0]?.[4], defUltAvailableData?.[0]?.[6]]
      customeFieldValue.map((item) => {
            HubsTempTest.push({
              account_id: account[0],
              platform_id: hubID,
              platform: platformName,
              category: customeField?.mainLabel,
              mapping_field: item,
              type: "Original"
            })
            return customeFieldValue
          })
        }
        await API.post("backendRest", "/mapping/mapping_configs", {
          body: {
            mapping_configs: HubsTempTest
          }
        }).then(async (res) => {
          await getSelectedData(campaignFeildValue, campaignCustomValue)
        }).catch((err) => {
          console.log(err)
        })

      }

  //for hubspot get data
  const getSelectedData = async (customDefault, contactCustom) => {
    let a = 1
    setDataLoader(true)
    await API.get("backendRest", `/mapping/mapping_configs?account_id=${account[0]}&platform_id=${hubspotID[0]}`).then(async (res) => {
      if (res && res.length === 0 && campaignFeildValue.length > 0) {
        let platformName = "Hubspot"
        let hubID =  parseInt(hubspotID[0])
        let HubsTempTest = []
        let defaultData = { ...defaultConfig["Hubspot"] }
        Object.entries(defaultData).forEach(([key, value]) => {
          value.map(data => {
              if (key === integrateData?.Market) {
                HubsTempTest.push({
                  account_id: account[0],
                  platform_id: hubID,
                  platform: platformName,
                  category: key,
                  mapping_field: "lead_source",
                  type: data?.objectName
                })
              }
             else if (key === integrateData?.SegmentMapping) {
               HubsTempTest.push({
                  account_id: account[0],
                  platform_id: hubID,
                  platform: platformName,
                  category: key,
                  mapping_field: "No Segment Mapping Required",
                  type: "NotRequired"
                })
            }
              else {
                HubsTempTest.push({
                  account_id: account[0],
                  platform_id: hubID,
                  platform: platformName,
                  category: key,
                  mapping_field: data?.value,
                  type: data?.objectName
                })
              }
            return value
          })
        })
        if (customeField?.label === "") {
          let customeFieldValue = customeField?.label === "Custom Field" ? customeField?.value : [campaignFeildValue?.[0]?.[2], campaignFeildValue?.[0]?.[4], campaignFeildValue?.[0]?.[6]]
          customeFieldValue.map((item) => {
            HubsTempTest.push({
              account_id: account[0],
              platform_id: hubID,
              platform: platformName,
              category: customeField?.mainLabel,
              mapping_field: item,
              type: "Original"
            })
            return customeFieldValue
          })
        }
        await API.post("backendRest", "/mapping/mapping_configs", {
          body: {
            mapping_configs: HubsTempTest
          }
        }).then(async (res) => {
          await getSelectedData(campaignFeildValue, campaignCustomValue)
        }).catch((err) => {
          console.log(err)
        })

      }
      else {
        let temp = { ...defaultConfig["Hubspot"] }
        let campaigntemp = { ...customeField }
        let tempdata = []
        let tempContact = []
        let tempType
        res.map((i) => {
          if (i[5] === "Custom" && i[3] === "Campaign Mapping") {
            tempType = i[5]
          }
          if (i[3] === integrateData?.SegmentMapping) {
            if (i[4] === "No Segment Mapping Required") {
              setSegmentBtnValue(i[4])
            }
            else {
              setSegmentBtnValue("Custom Field")
              setSegmentOptionChangeValue(i[4])
            }
          }
          if (i[3] !== integrateData?.StageMapping) {
            let tempValue = temp[i[3]]
            if (tempValue?.length > 0) {
              tempValue[0]["value"] = i[4]
              tempValue[0]['isCustomField'] = true
              let disabledField = tempValue[0]["fields"][0]
              disabledField["disabled"] = false
            }
            if (campaigntemp?.mainLabel === i[3]) {
              campaigntemp["value"] = [i[4]]
              customDefault.map((item) => {
                if (item.includes(i[4]) === true) {
                  setBtnValue(item)
                  setBtnMessageValue(item)
                }
                return customDefault
              })
              tempdata.push(i[4])

              if (tempType === "Custom" && i[3] === "Campaign Mapping") {
                tempContact.push(i[4])
              }
              if (i[4] === "lead_source_detail_1") {
                a = 1
              }
              if (i[4] === "org_source_dd_1") {
                a = 0
              }
            }
          }
          setDataLoader(false)
          return res
        })
        let btn = false
        tempdata.map(item => {
          customDefault[a].map(value => {
            if (item === value && tempType !== "Custom") {
              btn = true
            }
            return value
          })
          return item
        })
        if (!btn && tempdata?.length > 0) {
          setBtnValue("Custom Field")
          setBtnMessageValue("Custom Field")
          setLabelValue(tempContact)
          setDefaultLabelValue(tempContact)
        } else {
          setLabelValue([])
          setDefaultLabelValue([])
        }

        setCustomeField(campaigntemp)
        setConfig(temp)
        setTempHubspotObj(temp)
      }
}).catch((err) => {
        setDataLoader(false)
        console.log(err)
      })
}
  const [salesCustom, setSalesCustom] = useState({})

  // Save salesforce data while first time connect
  const saveSalesForceDefaultData = async () => {
      let sfID = parseInt(getSalseId[0])
      let salesforceConfigValue  = {...defaultConfig["Salesforce"]}
      let platformName = "Salesforce"
      let tempTEST = []
      Object.entries(salesforceConfigValue).forEach(([key, value]) => {
        console.log("vall",value);
        value.map(data => {
          if (data?.value !== "Opportunity Stage") {
            let LeadObj = ""
            if (data?.value === "Lead Status") {
              LeadObj = "LeadStatus"
            }
            else if (data?.value === "Lead Source") {
              LeadObj = "LeadSource"
            }
            else if (data?.value === "Amount") {
              LeadObj = "Opportunity"
            }
            else {
              LeadObj = data?.objectName
            }
            tempTEST.push({
              account_id: account[0],
              platform_id: sfID,
              platform: platformName,
              category: key,
              mapping_field: data?.value,
              type: LeadObj
            })
          }
          return value
        })
      })
      await API.post("backendRest","/mapping/mapping_configs",{
      body: {
       mapping_configs : tempTEST
      }
     }).then(async(res)=> {
    await getStageMapping()
     }).catch((err) => {
       console.log(err)
     })
    }

//for SalesForce
const getSelectedSalesForceData = async(leadData,contactData) => {
  setDataLoader(true)
  await API.get("backendRest", `/mapping/mapping_configs?account_id=${account[0]}&platform_id=${getSalseId}`).then(async (res) => {
    if (res && res.length === 0) {
      let sfID = parseInt(getSalseId[0])
      let salesforceConfigValue  = {...defaultConfig["Salesforce"]}
      let platformName = "Salesforce"
      let tempTEST = []
      Object.entries(salesforceConfigValue).forEach(([key, value]) => {
        value.map(data => {
          if (data?.value !== "Opportunity Stage") {
            let LeadObj = ""
            if (data?.value === "Lead Status") {
              LeadObj = "LeadStatus"
            }
            else if (data?.value === "Lead Source") {
              LeadObj = "LeadSource"
            }
            else if (data?.value === "Amount") {
              LeadObj = "Opportunity"
            }
            else {
              LeadObj = data?.objectName
            }
            tempTEST.push({
              account_id: account[0],
              platform_id: sfID,
              platform: platformName,
              category: key,
              mapping_field: data?.value,
              type: LeadObj
            })
          }
          return value
        })
      })
      await API.post("backendRest","/mapping/mapping_configs",{
      body: {
       mapping_configs : tempTEST
      }
     }).then(async(res)=> {
    await getStageMapping()
     }).catch((err) => {
       console.log(err)
     })
    }
    else {
      let temp = { ...defaultConfig["Salesforce"] }
      let tempObj = {...salesCustom}

      res.map((i) => {

        let tempSalseObj = temp[i[3]][0]
        tempSalseObj['value'] = i[4]
        if(i[4] === 'Lead Status' || i[4] === 'Lead Source' || i[4] === 'No Segment Mapping Required' || i[4] === 'Amount' ){
          tempSalseObj['isCustomField'] = false
        }else{
          tempSalseObj['isCustomField'] = true
        }
        tempSalseObj["objectName"] = i[5]
        let leadTemp = []
        tempObj[i[3]] = i[4]
        leadData.map((item)=> {
            if(item === i[4]){
              leadTemp.push(i[4])
            }
          return leadData;
        })
        setDataLoader(false)
        return res;
      })
      setSalesCustom(tempObj)
      setTempSalesForceObj(temp)
        setSaleforceConfigValue(temp)
      }

    }).catch((err) => {
      setDataLoader(false)
      console.log(err)
    })

}
const setSalesforceValue = (item) => {
  if(item.isCustomField){
    return `${item.objectName}_Custom_Field`
  }else{
   return item.value
  }
}


// Hnadle mapping config drawer Open
const handleOpenConfig = (value, customEvent)=>{
  TrackClickFunction(customEvent);
  setOpenConfig(value)
  if(value === "Hubspot"){
    getHubspotCustomeValue()
    getHubspotCampaignMapping()
  }
  if(value ===  "Salesforce"){
    getStageMapping()
  }

 }

  useEffect(()=> {
    getStageMapping()
    getHubspotCustomeValue()
    getHubspotCampaignMapping()
  }, [])


  return (
    <div>
      <div className="integrationTab" style={{ marginTop: "15px" }}>
        <Tabs
          defaultActiveKey="1"
          centered
          activeKey={activeTab}
          onChange={handleTabChange}
          tabPosition="left"
          className="tabs-wrapper"
        >
          <TabPane tab="CRM" key="crm" >
            {props.userPermission?.["settings.integrations"] !== "viewonly" && (
              <div style={{ textAlign: "center" }}>
                <Title
                  level={5}
                  style={{
                    marginTop: "10px",
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  Please select which CRM views we should sync.{" "}
                  <Text strong>
                    Once selected, you should review your stage mappings{" "}
                    <Button
                      style={{
                        paddingInline: 0,
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                      type="link"
                      onClick={() => {
                        if(content === 'content'){
                          history.push("/sales-and-marketing/content")
                        }else{
                        history.push("/sales-and-marketing")
                        }
                     }}
                    >
                      here
                    </Button>
                    .
                  </Text>
                  {isLoading && (
                  <Loading3QuartersOutlined
                    spin
                    className="loder-3quarter"
                  />
                )}
                </Title>
              </div>
            )}
            {getCRMsLoader ? (
              <Skeleton active paragraph={{ rows: 6 }} />
            ) : (
              <List>
                {Object.keys(AllCRMs)?.map(
                  (key) =>
                  AllCRMs[key]?.type === "crm" && (
                      <>
                        <List.Item
                          style={{
                            display: "display",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              marginLeft: "10px",
                              alignItems: "center",
                              textAlign: "center",
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <Avatar shape="square" src={AllCRMs?.[key]?.logo} />
                            <p
                              style={{
                                marginLeft: "15px",
                                marginTop: "15px",
                                fontSize: "16px",
                              }}
                            >
                              {AllCRMs?.[key]?.name}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "16px",
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                marginTop: "15px",
                                color: "#4BB543",
                                fontSize: 18,
                              }}
                            >
                              {AllCRMs?.[key]?.integrations &&
                              AllCRMs?.[key]?.integrations?.length > 0
                                ? "Active"
                                : "Inactive"}
                            </p>
                            {props.userPermission?.["settings.integrations"] !==
                              "viewonly" && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "16px",
                                  width: "100%",
                                }}
                              >
                                {!AllCRMs?.[key]?.integrations?.length > 0 && (
                                  <Button
                                    onClick={() => HandleConnectCRM(key)}
                                    type="primary"
                                    disabled = {viewType === "companycontributor"  || groups?.[1] === "companycontributor"}
                                    className="primaryButton"
                                    style={{width:'120px', fontWeight:500}}
                                  >
                                    <strong>Connect</strong>
                                  </Button>
                                )}
                                {groups?.length > 0 &&
                                  groups?.[2] === "admin" &&
                                  (viewType !== "companyadmin" && viewType !== "companycontributor") && (
                                    <Button
                                      type="primary"
                                      onClick={() => syncAll(key)}
                                      style={{width:'120px', fontWeight:500}}
                                      className="secondaryButton"
                                    >
                                      {" "}
                                      <strong>Sync All</strong>
                                    </Button>
                                  )}
                                  {AllCRMs[key]?.integrations?.length > 0 &&  (
                                <Button
                                  type="primary"
                                  style={{
                                    width: "170px",
                                  }}
                                  className="tertiaryButton"
                                    ghost
                                    disabled = { viewType === "companycontributor"  || groups?.[1] === "companycontributor"  }
                                  onClick={() => { 
                                    switch (key) {
                                      case 'Salesforce':
                                        handleOpenConfig(key, 'demand-gen-mapping-config-salesforce');
                                        break;
                                      case 'Hubspot':
                                        handleOpenConfig(key, 'demand-gen-mapping-config-hubspot');
                                        break; 
                                      default:
                                        handleOpenConfig(key);
                                        break;
                                    }
                                    
                                }}
                                >
                                  Mapping Config
                                </Button>
                                  )}

                                {AllCRMs?.[key]?.integrations?.length > 0 && viewType !== "companycontributor" &&  groups?.[1] !== "companycontributor" &&(
                                  <Popover
                                    trigger={"click"}
                                    placement="bottom"
                                    content={
                                      <Space direction="vertical" size={[0, 0]}>
                                        <Button
                                          onClick={() => HandleConnectCRM(key)}
                                          type="text"Segment Mapping
                                        >
                                          Reconnect
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            handleDisconnectCrm(AllCRMs?.[key]?.integrations)
                                          }
                                          type="text"
                                        >
                                          Disconnect
                                        </Button>
                                      </Space>
                                    }
                                  >
                                    <Button
                                      style={{ marginLeft: "-16px" }}
                                      type="text"
                                      icon={
                                        <EllipsisOutlined
                                          style={{ fontSize: "25px" }}
                                          rotate={90}
                                        />
                                      }
                                      onClick={() => {}}
                                    />
                                  </Popover>
                                )}
                              </div>
                            )}
                          </div>
                        </List.Item>

                        {AllCRMs?.[key]?.integrations &&
                          AllCRMs?.[key]?.integrations?.length > 0 && (
                            <div
                              className="syncBox"
                              style={{
                                marginLeft: "30px",
                                marginTop: "20px",
                                marginRight: "14px",
                                marginBottom: "10px",
                              }}
                            >
                              
                              {AllCRMs?.[key]?.integrations?.map(
                                (data, index) => {
                                  
                                  const metaData = JSON.parse(data.metadata);
                                  data.syncHistory =
                                    AllSyncHistory[data?.profile_id];
                                  return (
                                    <Row gutter={[12, 12]} key={index}>
                                      <Col
                                        span={24}
                                        key={index}
                                        className="analyticsList"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>
                                          {props.userPermission?.[
                                            "settings.integrations"
                                          ] !== "viewonly" && (
                                            <Checkbox
                                                defaultChecked={
                                                  data.platform === 'Salesforce' &&
                                                    ConnectedCRMs['Salesforce'] &&
                                                    ConnectedCRMs['Salesforce'].some(
                                                      (item) => item.profile_id === data.profile_id
                                                    )
                                                }
                                                disabled = {viewType === "companycontributor"  || groups?.[1] === "companycontributor"}
                                              onChange={(e) =>
                                                HandleIntegrationChecked(
                                                  e,
                                                  data
                                                )
                                              }
                                            >
                                              {metaData.account_name}
                                            </Checkbox>
                                          ) }
                                        </div>
                                        <div>
                                          {props.userPermission?.[
                                            "settings.integrations"
                                          ] !== "viewonly" && (
                                            <>
                                              <Typography.Text
                                                strong
                                                style={{
                                                  color:
                                                    data?.syncHistory &&
                                                    data?.syncHistory[0]
                                                      ?.status === "FAILED"
                                                      ? "#F04438"
                                                      : data?.syncHistory &&
                                                        data?.syncHistory[0]
                                                          ?.status === "DONE"
                                                      ? "#1ABC5B"
                                                      : "#1D2939",
                                                }}
                                              >
                                                {data?.syncHistory &&
                                                  data?.syncHistory?.length >
                                                    0 &&
                                                  data?.syncHistory[0]
                                                    ?.status[0] +
                                                    data?.syncHistory[0]?.status
                                                      .slice(1)
                                                      .toLowerCase()}
                                              </Typography.Text>
                                              <Typography.Text
                                                style={{ padding: "4px 15px" }}
                                              >
                                                {data?.syncHistory &&
                                                  data?.syncHistory?.length >
                                                    0 &&
                                                  `${new Date(
                                                    data?.syncHistory[0]?.updatedAt
                                                  ).toDateString()} ${new Date(
                                                    data?.syncHistory[0]?.updatedAt
                                                  ).toLocaleTimeString()}`}
                                              </Typography.Text>
                                              <InfoCircleOutlined
                                                onClick={() => {
                                                  setselectedcrm({
                                                    ...data,
                                                    name: `${metaData.account_name}`,
                                                  });
                                                  setShowSyncHistoryModal(true);
                                                }}
                                              />
                                            </>
                                          )}
                                          <Tooltip
                                            title={
                                              !data?.enabled
                                                ? "Enable to Sync"
                                                : ""
                                            }
                                          >
                                            {props.userPermission?.[
                                              "settings.integrations"
                                            ] !== "viewonly" && viewType !== "companycontributor" && groups?.[1] !== "companycontributor" &&  (
                                              <Button
                                                type="link"
                                                loading={
                                                  data.loading ||
                                                  data?.syncHistory?.[0]
                                                    ?.status === "SYNCING"
                                                }
                                                disabled={
                                                  !(
                                                    (data?.syncHistory?.[0]
                                                      ?.status ===
                                                      "REQUESTED" &&
                                                      parseInt(
                                                        (new Date().getTime() -
                                                          new Date(
                                                            data?.syncHistory?.[0].updatedAt
                                                          ).getTime()) /
                                                          3600000
                                                      ) >= 1) ||
                                                    data?.syncHistory?.[0]
                                                      ?.status === "FAILED" ||
                                                    data?.syncHistory?.[0]
                                                      ?.status === "DONE"
                                                  )
                                                }
                                                onClick={() => {
                                                  syncCRM(data);
                                                }}
                                              >
                                                <strong style={{color:"#7F56D9"}}>Sync Now</strong>
                                              </Button>
                                            )}
                                          </Tooltip>
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                              )}
                            </div>
                          )}
                      </>
                    )
                )}
              </List>
            )}
          </TabPane>

          <TabPane tab="Marketing" key="marketing">
            {getCRMsLoader ? (
              <Skeleton active paragraph={{ rows: 6 }} />
            ) : (
              <List>
                {Object.keys(AllCRMs)?.map(
                  (key) =>
                    AllCRMs[key]?.type === "marketing" && (
                      <>
                        <List.Item
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 33%)",
                          }}
                        >
                          <div
                            style={{
                              marginLeft: "10px",
                              alignItems: "center",
                              textAlign: "center",
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <Avatar shape="square" src={AllCRMs?.[key]?.logo} />
                            <p
                              style={{
                                marginLeft: "15px",
                                marginTop: "15px",
                                fontSize: "16px",
                              }}
                            >
                              {AllCRMs?.[key]?.name}
                            </p>
                          </div>
                          <p
                            style={{
                              marginTop: "15px",
                              color: "#4BB543",
                              fontSize: 18,
                            }}
                          >
                            {AllCRMs?.[key]?.integrations &&
                            AllCRMs?.[key]?.integrations?.length > 0
                              ? "Active"
                              : "Inactive"}
                          </p>
                          <div>
                            {props.userPermission?.["settings.integrations"] !==
                              "viewonly" && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  width: "100%",
                                }}
                              >
                                {!AllCRMs?.[key]?.integrations?.length > 0 && (
                                  <Button
                                    onClick={() => HandleConnectCRM(key)}
                                    type="primary"
                                    disabled = {viewType === "companycontributor" || groups?.[1] === "companycontributor"}
                                    className="primaryButton"
                                  >
                                    Connect
                                  </Button>
                                )}
                                {groups?.length > 0 &&
                                  groups?.[2] === "admin" &&
                                  (viewType !== "companyadmin" && viewType !== "companycontributor") && (
                                    <Button
                                      type="link"
                                      onClick={() => syncAll(key)}
                                    >
                                      {" "}
                                      <strong style={{color:"#7F56D9"}}>Sync All</strong>
                                    </Button>
                                  )}
                                {AllCRMs?.[key]?.integrations?.length > 0 && viewType !== "companycontributor" &&  groups?.[1] !== "companycontributor" && (
                                  <Popover
                                    placement="bottom"
                                    trigger={"click"}
                                    content={
                                      <Space direction="vertical" size={[0, 0]}>
                                        <Button
                                          onClick={() => HandleConnectCRM(key)}
                                          type="text"
                                        >
                                          Reconnect
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            handleDisconnectCrm(AllCRMs?.[key]?.integrations)
                                          }
                                          type="text"
                                        >
                                          Disconnect
                                        </Button>
                                      </Space>
                                    }
                                  >
                                    <Button
                                      type="text"
                                      icon={<EllipsisOutlined rotate={90} />}
                                      // loading={loadings[2]}
                                      onClick={() => {}}
                                    />
                                  </Popover>
                                )}
                              </div>
                            )}
                          </div>
                        </List.Item>

                        {AllCRMs?.[key]?.integrations &&
                          AllCRMs?.[key]?.integrations?.length > 0 && (
                            <div
                              className="syncBox"
                              style={{
                                marginLeft: "30px",
                                marginTop: "20px",
                                marginRight: "14px",
                                marginBottom: "10px",
                              }}
                            >
                              {AllCRMs?.[key]?.integrations?.map(
                                (data, index) => {
                                  const metaData = JSON.parse(data.metadata);
                                  data.syncHistory =
                                    AllSyncHistory[data?.profile_id];
                                  return (
                                    <Row gutter={[12, 12]} key={index}>
                                      <Col
                                        span={24}
                                        key={index}
                                        className="analyticsList"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>
                                          {props.userPermission?.[
                                            "settings.integrations"
                                          ] !== "viewonly" && (
                                            <Checkbox
                                              defaultChecked={data.enabled}
                                              disabled ={viewType === "companycontributor"}
                                              onChange={(e) =>
                                                HandleIntegrationChecked(
                                                  e,
                                                  data
                                                )
                                              }
                                            >
                                              {metaData.account_name}
                                            </Checkbox>
                                          )}
                                        </div>
                                        <div>
                                          {props.userPermission?.[
                                            "settings.integrations"
                                          ] !== "viewonly" && (
                                            <>
                                              <Typography.Text
                                                strong
                                                style={{
                                                  color:
                                                    data?.syncHistory &&
                                                    data?.syncHistory[0]
                                                      ?.status === "FAILED"
                                                      ? "#E13A3A"
                                                      : data?.syncHistory &&
                                                        data?.syncHistory[0]
                                                          ?.status === "DONE"
                                                      ? "#1ABC5B"
                                                      : "#727272",
                                                }}
                                              >
                                                {data?.syncHistory &&
                                                  data?.syncHistory?.length >
                                                    0 &&
                                                  data?.syncHistory[0]
                                                    ?.status[0] +
                                                    data?.syncHistory[0]?.status
                                                      .slice(1)
                                                      .toLowerCase()}
                                              </Typography.Text>
                                              <Typography.Text
                                                style={{ padding: "4px 15px" }}
                                              >
                                                {data?.syncHistory &&
                                                  data?.syncHistory?.length >
                                                    0 &&
                                                  `${new Date(
                                                    data?.syncHistory[0]?.updatedAt
                                                  ).toDateString()} ${new Date(
                                                    data?.syncHistory[0]?.updatedAt
                                                  ).toLocaleTimeString()}`}
                                              </Typography.Text>
                                              <InfoCircleOutlined
                                                onClick={() => {
                                                  setselectedcrm({
                                                    ...data,
                                                    name: `${metaData.account_name}`,
                                                  });
                                                  setShowSyncHistoryModal(true);
                                                }}
                                              />
                                            </>
                                          )}
                                          <Tooltip
                                            title={
                                              !data?.enabled
                                                ? "Enable to Sync"
                                                : ""
                                            }
                                          >
                                            {props.userPermission?.[
                                              "settings.integrations"
                                            ] !== "viewonly" && (
                                              <Button
                                                type="link"
                                                loading={
                                                  data.loading ||
                                                  data?.syncHistory?.[0]
                                                    ?.status === "SYNCING"
                                                }
                                                disabled={
                                                  !(
                                                    (data?.syncHistory?.[0]
                                                      ?.status ===
                                                      "REQUESTED" &&
                                                      parseInt(
                                                        (new Date().getTime() -
                                                          new Date(
                                                            data?.syncHistory?.[0].updatedAt
                                                          ).getTime()) /
                                                          3600000
                                                      ) >= 1) ||
                                                    data?.syncHistory?.[0]
                                                      ?.status === "FAILED" ||
                                                    data?.syncHistory?.[0]
                                                      ?.status === "DONE"
                                                  )
                                                }
                                                onClick={() => {
                                                  syncCRM(data);
                                                }}
                                              >
                                                <strong>Sync Now</strong>
                                              </Button>
                                            )}
                                          </Tooltip>
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                              )}
                            </div>
                          )}
                      </>
                    )
                )}
              </List>
            )}
          </TabPane>
        </Tabs>
      </div>
      <Modal
        visible={ShowSyncHistoryModal}
        closable={true}
        footer={[]}
        centered
        closeIcon={
          <strong>
            <CloseOutlined
              style={{
                fontSize: 20,
                marginTop: 10,
                color: "#ffffff",
                marginLeft: "60px",
                transform: "scale(1.5)",
              }}
              onClick={() => {
                setShowSyncHistoryModal(false);
                setselectedcrm({});
              }}
            />
          </strong>
        }
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography.Text strong style={{ marginBottom: 10 }}>
            Syncing History{" "}
            <span style={{ fontWeight: 100 }}>{selectedcrm?.name}</span>
          </Typography.Text>
          <Table
            columns={columns}
            bordered
            dataSource={selectedcrm?.syncHistory}
            size="small"
            pagination={false}
          />
        </div>
      </Modal>
      <Drawer
        title={
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <Space>
              <img
                src={crmConnections[openConfig]?.logo || null}
                alt={openConfig}
                style={{ height: "24px" }}
              />
              <div style={{ fontWeight: "700" }}>{openConfig}</div>
              { dataLoader && <LoadingOutlined style={{marginLeft:10}} />}
            </Space>
            <Tooltip
            className="tooltip-arrow"
              placement="bottom"
              color={"white"}
              trigger={"click"}
              overlayStyle={{ minWidth: "450px" }}
              overlayInnerStyle={{ padding: "15px", fontSize: "16px",marginRight: 54 }}
              title={
                <Space
                  direction="vertical"
                  style={{ fontWeight: "400" }}
                  size={[10, 10]}
                >
                  <div style={{ color: "black" }}>
                    Demand Generation maps to your CRM in a variety of places throughout the
                    platform. Configure which CRM fields should be used for
                    those mappings.
                  </div>
                  <div style={{ color: "#727272" }}>
                    IMPORTANT: changes made here will remove any existing
                    mappings that have been configured for this CRM.
                  </div>
                </Space>
              }
            >
              <InfoCircleOutlined style={{color: "#A9A9A9"}} />
            </Tooltip>
          </Space>
        }
        width={416}
        onClose={handleCloseConfigDrawer}
        visible={!!openConfig}
        bodyStyle={{ paddingBottom: 80, paddingTop: 0 }}
        className={`crm-config-drawer crm-config-drawer-${openConfig}`}
        style={{ zIndex: 100 }}
        maskClosable={false}
      >
        <div>
          <>

          <Collapse
           ghost={true}
            className="section pannel-arrow collapse-style"
            defaultActiveKey={"Segment Mapping"}
            accordion
            expandIconPosition="right"
            bordered={false}
          >
            {openConfig === "Hubspot" &&
            <>
              {Object.keys(config || {})?.map((key, i) => (
                <>
                  {key === "Segment Mapping" ? (
                    <Panel
                      header={
                  <>
                    <Space style={{ width: "100%", justifyContent: "space-between" }}>{integrateData?.SegmentMapping}
                      <Tooltip
                          className="tooltip-arrow"
                          placement="bottom"
                          color={"white"}
                          title={<Space
                            direction="vertical"
                            style={{ fontWeight: "400" }}
                            size={[10, 10]}
                          >
                            <div style={{ color: "black" }}>
                            Demand Generation Segments are mapped within Revenue Planning (step #1) using CRM fields specified here.
                            </div>

                  </Space>}
                            >
                        <div className="tooltip-wrapper" >

                          { hubSpotMessageObj.includes(integrateData?.SegmentMapping) && <div
                          className="mapping-stages-dot show-stage-chnages-text"
                          style={{ marginLeft: 10 }}
                          ></div>   }
                          <InfoCircleOutlined
                          style={{ color: "#A9A9A9" }}
                          />
                          </div>
                      </Tooltip>
                     </Space>
                      </>
                      }
                 key={integrateData?.SegmentMapping}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "18px 0",
                      width: "100%",
                    }}
                  >
                    <Radio.Group
                      style={{ width: "100%" }}
                      value={segementBtnValue}
                      onChange={(event) =>
                        {
                          handleConfigChange(
                                key,
                                true,
                                event.target.value,
                                0
                          );
                        setSegmentBtnValue(event.target.value);
                        }
                      }
                    >
                      <>

                        <div>
                          <Space
                            direction="vertical"
                            style={{ width: "100%" }}
                            size={[22, 22]}
                          >
                            <Radio
                              value={"No Segment Mapping Required"}
                              className="radio-text"
                              disabled={false}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                No Segment Mapping Required
                                <Tooltip
                                   className="tooltip-arrow"
                        placement="bottom"
                        color={"white"}
                        title={<Space
                          direction="vertical"
                          style={{ fontWeight: "400" }}
                          size={[10, 10]}
                        >
                          <div style={{ color: "black" }}>
                          Recommended for situations where only one Demand Gen segment exists
                          </div>

                  </Space>}
                            >
                        <InfoCircleOutlined
                          style={{ color: "#A9A9A9" }}
                        />
                                </Tooltip>
                              </div>
                              </Radio>
                          </Space>
                        </div>
                        <Divider style={{ margin: "18px 0" }} />
                        <div>
                          <div className="object-name">Contact</div>
                          <Space
                            direction="vertical"
                            style={{ width: "100%" }}
                            size={[22, 22]}
                          >
                            <Radio
                              value={"Custom Field"}
                              className="radio-text"
                              disabled={false}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                Custom Field
                                <Tooltip
                                  className="tooltip-arrow"
                                  placement="bottom"
                                  color={"white"}
                                  title={<Space
                                  direction="vertical"
                                  style={{ fontWeight: "400" }}
                                  size={[10, 10]}
                                  >
                                  <div style={{ color: "black" }}>
                                  Choose a custom field from the Hubspot Contact object to use for this mapping within Demand Gen.
                                  </div>

                               </Space>}
                                >
                              <InfoCircleOutlined
                                style={{ color: "#A9A9A9" }}
                              />
                                </Tooltip>
                              </div>
                            </Radio>
                          <Select
                                      size="large"
                                      style={{
                                        width: "100%",
                                        marginTop: "-10px",
                                      }}
                                      key={key}
                                      value={segmentOptionChangeValue}
                                      disabled={segementBtnValue === "Custom Field" ? false : true}
                                      defaultValue={hubspotContactValue?.[0]
                                      }
                                  onChange={(value) => {
                                    handleConfigChange(
                                      key,
                                      true,
                                      value,
                                      0
                                    );
                                      setSegmentOptionChangeValue(value)
                                      }
                                      }
                                    >
                                      {hubspotContactValue !== undefined && hubspotContactValue !== null && hubspotContactValue.map((option, indexval) => {
                                        return (
                                          <Option key={indexval} value={option[0]}>
                                            {option[1]}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                          </Space>
                        </div>
                      </>
                    </Radio.Group>
                  </div>
                </Panel>) : (
                    <>
                    <Panel header = {
                  <>
                  <Space style={{ width: "100%", justifyContent: "space-between"}}>{key}

                      <Tooltip
                        className="tooltip-arrow"
                        placement="bottom"
                        color={"white"}
                        title={<Space
                          direction="vertical"
                          style={{ fontWeight: "400" }}
                          size={[10, 10]}
                        >
                          <div style={{ color: "black" }}>
                          {hubspotTooltip[key].value}
                          </div>

                  </Space>}
                            >
                      <div className="tooltip-wrapper" >

                             { hubSpotMessageObj.includes(key) && <div
                          className="mapping-stages-dot show-stage-chnages-text"
                          style={{ marginLeft: 10 }}
                      ></div>   }
                        <InfoCircleOutlined
                          style={{ color: "#A9A9A9" }}
                        />
                            </div>
                      </Tooltip>
                     </Space>
                      </>
                      }
                  key={key}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "18px 0",
                      width: "100%",
                    }}
                  >
                    {config &&
                      config[key]?.map((objDetail, index) => (
                        <div>
                          {objDetail.objectName && (
                            <div className="object-name">
                              {objDetail.objectName}
                            </div>
                          )}
                          <Radio.Group
                            style={{ width: "100%" }}
                            value={
                              objDetail.isCustomField
                                ? "Custom Field"
                                : objDetail.value
                            }
                            onChange={(event) => {
                              const isCustom =
                                event.target.value === "Custom Field";
                              handleConfigChange(
                                key,
                                isCustom,
                                event.target.value,
                                index
                              );
                            }}
                          >
                            <Space
                              direction="vertical"
                              style={{ width: "100%" }}
                              size={[22, 22]}
                            >
                              {objDetail?.fields?.map((field, i) => (
                                <>
                                  <Radio
                                  value={field.value}
                                    className="radio-text"
                                    disabled={field?.disabled || false}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}
                                    >
                                      {field.label}
                                      <Tooltip
                                        className="tooltip-arrow"
                                        placement="bottom"
                                        color={"white"}
                                        title={<Space
                                        direction="vertical"
                                        style={{ fontWeight: "400" }}
                                        size={[10, 10]}
                                        trigger="click"
                                         >
                                    <div style={{ color: "black" }}>
                                    {field.tooltip}
                                    </div>
                                    </Space>}
                                    >
                                        <InfoCircleOutlined
                                          style={{ color: "#A9A9A9" }}
                                        />
                                      </Tooltip>
                                    </div>
                                  </Radio>
                                  {field.options && (
                                    <Select
                                      size="large"
                                      style={{
                                        width: "100%",
                                        marginTop: "-10px",
                                      }}
                                      key={key}
                                      value={objDetail.value}
                                      disabled={!objDetail.isCustomField}
                                      defaultValue={
                                        objDetail.isCustomField
                                          ? "Select"
                                          : "Select"
                                      }
                                      onChange={(value) =>
                                        handleConfigChange(
                                          key,
                                          true,
                                          value,
                                          index
                                        )
                                      }
                                    >
                                      {hubspotContactValue !== undefined && hubspotContactValue !== null && hubspotContactValue?.map((option, indexval) => {
                                        return (
                                          <Option key={indexval} value={option[0]}>
                                            {option[1]}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  )}
                                </>
                              ))}
                            </Space>
                          </Radio.Group>
                          {index !== config[key].length - 1 && (
                            <Divider style={{ margin: "18px 0" }} />
                          )}
                        </div>
                      ))}
                  </div>
            </Panel>
            </>
                 )}

                 </>
              ))}
                <Panel header={
                  <>
                  <Space style={{ width: "100%", justifyContent: "space-between"}}>{integrateData?.Campaign}

                      <Tooltip
                        className="tooltip-arrow"
                        placement="bottom"
                        color={"white"}
                        title={<Space
                          direction="vertical"
                          style={{ fontWeight: "400" }}
                          size={[10, 10]}
                        >
                          <div style={{ color: "black" }}>
                            Demand Generation Campaigns are mapped within Budget & Campaigns using CRM fields specified here. Note: Hubspot does not make their Campaign object available for this.
                          </div>

                  </Space>}
                            >
                      <div className="tooltip-wrapper" >

                             { hubSpotMessageObj.includes(integrateData?.Campaign) && <div
                          className="mapping-stages-dot show-stage-chnages-text"
                          style={{ marginLeft: 10 }}
                      ></div>   }
                        <InfoCircleOutlined
                          style={{ color: "#A9A9A9" }}
                        />
                            </div>
                      </Tooltip>
                     </Space>
                      </>
                      } key={integrateData?.Campaign}>
                   <div
                     style={{
                       display: "flex",
                       flexDirection: "column",
                       padding: "18px 0",
                       width: "100%",
                     }}
                   >
                   <div className="object-name">Contact</div>
                     <Radio.Group
                       style={{ width: "100%" }}
                       value={btnValue}
                       onChange={(event) =>
                        {
                          handleUpdateChange(
                                event.target.value,
                              );
                        setBtnValue(event.target.value)
                        if(event.target.value !== "Custom Field"){
                          setCustomeField({...customeField ,label :event.target.value[0].split(" ")[0],  value : event.target.value})
                        }
                      }
                       }
                     >
                       <Radio
                         value={campaignFeildValue?.[0]}
                         className="radio-text"
                       >
                         <div
                           style={{
                             display: "flex",
                             justifyContent: "space-between",
                             width: "100%",
                           }}
                         >
                         {campaignFeildValue?.[0]?.[0]}
                           <Tooltip
                            className="tooltip-arrow"
                            placement="bottom"
                            color={"white"}
                            title={<Space
                            direction="vertical"
                            style={{ fontWeight: "400" }}
                            size={[10, 10]}
                          >
                          <div style={{ color: "black" }}>
                          Choose the three Original Source fields from the Hubspot Contact object to use for this mapping within Demand Gen.
                          </div>

                  </Space>}
                            >
                             <InfoCircleOutlined style={{ color: "#A9A9A9" }} />
                           </Tooltip>
                         </div>
                         <div >
                         <p className="campaign-p">{campaignFeildValue?.[0]?.[1]}</p>
                         <p className="campaign-p">{campaignFeildValue?.[0]?.[3]}</p>
                         <p className="campaign-p">{campaignFeildValue?.[0]?.[5]}</p>
                         </div>
                       </Radio>

                       <Radio
                         value={campaignFeildValue?.[1]}
                         className="radio-text"
                       >
                         <div
                           style={{
                             display: "flex",
                             justifyContent: "space-between",
                             width: "100%",
                             marginTop: 20
                           }}
                         >
                         {campaignFeildValue?.[1]?.[0]}
                         <Tooltip
                            className="tooltip-arrow"
                            placement="bottom"
                            color={"white"}
                            title={<Space
                            direction="vertical"
                            style={{ fontWeight: "400" }}
                            size={[10, 10]}
                          >
                          <div style={{ color: "black" }}>
                              Choose the three custom Lead Source fields (if applicable) from the Hubspot Contact object to use for this mapping within Demand Gen.
                          </div>

                            </Space>}
                            >
                             <InfoCircleOutlined style={{ color: "#A9A9A9" }} />
                           </Tooltip>
                         </div>
                         <div >
                         <p className="campaign-p">{campaignFeildValue?.[1]?.[1]}</p>
                         <p className="campaign-p">{campaignFeildValue?.[1]?.[3]}</p>
                         <p className="campaign-p">{campaignFeildValue?.[1]?.[5]}</p>
                         </div>
                       </Radio>
                       <div style={{marginTop:15}}>

                          <Space
                            direction="vertical"
                            style={{ width: "100%" }}
                            size={[22, 22]}
                          >
                            <Radio
                              value={"Custom Field"}
                              className="radio-text"
                              disabled={false}
                              onChange={()  =>
                                setCustomeField({...customeField ,label : "Custom Field"  , value : []})}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  marginTop: 10
                                }}
                              >
                                Custom Field
                                <Tooltip
                                  className="tooltip-arrow"
                                  placement="bottom"
                                  color={"white"}
                                  title={<Space
                                  direction="vertical"
                                  style={{ fontWeight: "400" }}
                                  size={[10, 10]}
                                >
                                <div style={{ color: "black" }}>
                                Choose a custom field from the Hubspot Contact object to use for this mapping within Demand Gen.
                                </div>

                                  </Space>}
                                  >
                                  <InfoCircleOutlined
                                    style={{ color: "#A9A9A9" }}
                                  />
                                </Tooltip>
                              </div>
                            </Radio>

                            <Select
                              size="large"
                              mode="tags"
                              style={{
                                width: "100%",
                                marginTop: "-10px",
                              }}
                               disabled={btnValue === "Custom Field" ? false : true}

                              value={labelValue}
                              onChange={(value) =>
                               {handleHubSpotUpdateChange(
                                value,
                              );
                                setLabelValue(value)
                                 setCustomeField({...customeField ,label : "Custom Field", value : value})}
                              }
                            >
                              {campaignCustomValue !== undefined && campaignCustomValue !== null && campaignCustomValue.map((option, indexval) => {
                                return (
                                  <Option key={option} value={option?.[0]}>
                                    {option?.[1]}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Space>
                        </div>
                     </Radio.Group>

                   </div>
                 </Panel>
            </>
              }
          </Collapse>
          </>
          {openConfig === "Salesforce" && !!Object.keys(salesforceConfigValue).length && (
            <>
              <Collapse
              ghost={true}
                className="section collapse-style pannel-arrow"
                defaultActiveKey={"Stage Mapping"}
                accordion
                expandIconPosition="right"
                bordered={false}
              >
                <Panel
                header={
                  <>
                  <Space style={{ width: "100%", justifyContent: "space-between" }}>{integrateData?.StageMapping}
                      <Tooltip
                        className="tooltip-arrow"
                        placement="bottom"
                        color={"white"}
                        title={<Space
                          direction="vertical"
                          style={{ fontWeight: "400" }}
                          size={[10, 10]}
                        >
                          <div style={{ color: "black" }}>
                          Demand Generation Stages are mapped within Sales & Marketing Alignment using CRM fields specified here.
                          </div>

                  </Space>}
                            >
                        <div className="tooltip-wrapper" >

                          { saleForceMessageObj.includes(integrateData?.StageMapping) && <div
                          className="mapping-stages-dot show-stage-chnages-text"
                          style={{ marginLeft: 10 }}
                          ></div>   }
                          <InfoCircleOutlined
                          style={{ color: "#A9A9A9" }}
                          />
                          </div>
                      </Tooltip>
                     </Space>
                      </>
                      }
                 key={integrateData?.StageMapping}>
                <div className="heading-text">Select either Lead or contact object from the two</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "18px 0",
                      width: "100%",
                    }}
                  >
                    <Radio.Group
                      style={{ width: "100%" }}
                      value={setSalesforceValue(salesforceConfigValue["Stage Mapping"][0])
                      }
                      onChange={(event) =>
                        {
                          handleSalesForceConfigChange(
                            integrateData?.StageMapping,
                            event.target.value,
                            0,
                            "LeadStatus",
                            "",
                            salesCustom[integrateData?.StageMapping]
                          )
                        }
                      }
                    >
                      <>

                        <div>
                          <div className="object-name">Lead</div>
                          <Space
                            direction="vertical"
                            style={{ width: "100%" }}
                            size={[22, 22]}
                          >
                            <Radio
                              value={"Lead Status"}
                              className="radio-text"
                              disabled={false}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                Lead Status
                                <Tooltip
                                   className="tooltip-arrow"
                        placement="bottom"
                        color={"white"}
                        title={<Space
                          direction="vertical"
                          style={{ fontWeight: "400" }}
                          size={[10, 10]}
                        >
                          <div style={{ color: "black" }}>
                          Choose the Lead Status field from the Salesforce Lead object to use for this mapping within Demand Gen.
                          </div>

                  </Space>}
                            >
                        <InfoCircleOutlined
                          style={{ color: "#A9A9A9" }}
                        />
                                </Tooltip>
                              </div>
                            </Radio>
                            <Radio
                            value={"Lead_Custom_Field"}
                              className="radio-text"
                              disabled={!leadValue}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                Custom Field
                                <Tooltip
                                  className="tooltip-arrow"
                                  placement="bottom"
                                  color={"white"}
                                  title={<Space
                                  direction="vertical"
                                  style={{ fontWeight: "400" }}
                                  size={[10, 10]}
                                  >
                                  <div style={{ color: "black" }}>
                                  Choose a custom field from the Salesforce Lead object to use for this mapping within Demand Gen.
                                  </div>

                               </Space>}
                                >
                              <InfoCircleOutlined
                                style={{ color: "#A9A9A9" }}
                              />
                                </Tooltip>
                              </div>
                            </Radio>
                            <Select
                             value={leadValue &&leadValue.includes(salesCustom[integrateData?.StageMapping]) ? salesCustom[integrateData?.StageMapping] : null}
                              size="large"
                              style={{
                                width: "100%",
                                marginTop: "-10px",
                              }}
                              disabled={
                                !(
                                  salesforceConfigValue[integrateData?.StageMapping][0]
                                    .isCustomField &&
                                  salesforceConfigValue[integrateData?.StageMapping][0]
                                    .objectName === "Lead"
                                )||(!leadValue)
                              }
                              onChange={(value) =>
                                {
                                  setSalesCustom({...salesCustom,[integrateData?.StageMapping] : value})
                                  handleSalesForceConfigChange(
                                  integrateData?.StageMapping,
                                  value,
                                  0,
                                  "Lead",
                                  true
                                )}
                              }
                            >
                              {leadValue && leadValue.map((option, indexval) => {
                                return (
                                  <Option key={option} value={option}>
                                    {option}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Space>
                        </div>
                        <Divider style={{ margin: "18px 0" }} />
                        <div>
                          <div className="object-name">Contact</div>
                          <Space
                            direction="vertical"
                            style={{ width: "100%" }}
                            size={[22, 22]}
                          >
                            <Radio
                              value={"Contact_Custom_Field"}
                              className="radio-text"
                              disabled={!contactValue}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                Custom Field
                                <Tooltip
                                  className="tooltip-arrow"
                                  placement="bottom"
                                  color={"white"}
                                  title={<Space
                                  direction="vertical"
                                  style={{ fontWeight: "400" }}
                                  size={[10, 10]}
                                  >
                                  <div style={{ color: "black" }}>
                                  Choose a custom field from the Salesforce Contact object to use for this mapping within Demand Gen.
                                  </div>

                               </Space>}
                                >
                              <InfoCircleOutlined
                                style={{ color: "#A9A9A9" }}
                              />
                                </Tooltip>
                              </div>
                            </Radio>
                           <Select
                             value={contactValue && contactValue.includes(salesCustom[integrateData?.StageMapping]) ? salesCustom[integrateData?.StageMapping] : null}
                              size="large"
                              style={{
                                width: "100%",
                                marginTop: "-10px",
                              }}

                              disabled={
                                !(
                                  salesforceConfigValue[integrateData?.StageMapping][0]
                                    .isCustomField &&
                                  salesforceConfigValue[integrateData?.StageMapping][0]
                                    .objectName === "Contact"
                                ) || (!contactValue)
                              }
                              onChange={(value) =>
                               {
                                 setSalesCustom({...salesCustom,[integrateData?.StageMapping]: value})
                                 handleSalesForceConfigChange(
                                  integrateData?.StageMapping,
                                  value,
                                  0,
                                  "Contact",
                                  true
                                )}
                              }
                            >
                              {contactValue !== undefined && contactValue !== null && contactValue.map((option, indexval) => {
                                return (
                                  <Option key={option} value={option}>
                                    {option}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Space>
                        </div>
                      </>
                    </Radio.Group>
                    <Divider style={{ margin: "18px 0" }} />
                    <Radio.Group
                      style={{ width: "100%" }}
                      value={salesforceConfigValue[integrateData?.StageMapping][1].value}
                      onChange={(event) =>
                        handleSalesForceConfigChange(
                          integrateData?.StageMapping,
                          event.target.value,
                          1,
                          "Opportunity",
                          false
                        )
                      }
                    >
                      <>
                        <div>
                          <div className="object-name">Opportunity</div>
                          <Space
                            direction="vertical"
                            style={{ width: "100%" }}
                            size={[22, 22]}
                          >
                            <Radio
                              value={"Opportunity Stage"}
                              className="radio-text"
                              disabled={true}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                Opportunity Stage
                                <Tooltip
                                  className="tooltip-arrow"
                                  placement="bottom"
                                  color={"white"}
                                  title={<Space
                                  direction="vertical"
                                  style={{ fontWeight: "400" }}
                                  size={[10, 10]}
                                  >
                                  <div style={{ color: "black" }}>
                                  Choose the Opportunity Stage field from the Salesforce Opportunity object to use for this mapping within Demand Gen.
                                  </div>

                               </Space>}
                                >
                              <InfoCircleOutlined
                                style={{ color: "#A9A9A9" }}
                              />
                                </Tooltip>
                              </div>
                            </Radio>
                          </Space>
                        </div>
                      </>
                    </Radio.Group>
                  </div>
                </Panel>
                <Panel
                header={
                  <>
                  <Space style={{ width: "100%", justifyContent: "space-between" }}>{integrateData?.SegmentMapping}
                      <Tooltip
                        className="tooltip-arrow"
                        placement="bottom"
                        color={"white"}
                        title={<Space
                          direction="vertical"
                          style={{ fontWeight: "400" }}
                          size={[10, 10]}
                        >
                          <div style={{ color: "black" }}>
                          Demand Generation Segments are mapped within Revenue Planning (step #1) using CRM fields specified here.
                          </div>

                  </Space>}
                            >
                      <div className="tooltip-wrapper" >

                          { saleForceMessageObj.includes(integrateData?.SegmentMapping) && <div
                          className="mapping-stages-dot show-stage-chnages-text"
                          style={{ marginLeft: 10 }}
                          >

                          </div>   }
                          <InfoCircleOutlined
                          style={{ color: "#A9A9A9" }}
                          />
                          </div>
                      </Tooltip>
                     </Space>
                      </>
                      }
                 key={integrateData?.SegmentMapping}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "18px 0",
                      width: "100%",
                    }}
                  >

                    <Radio.Group
                      style={{ width: "100%" }}
                      value={
                        salesforceConfigValue["Segment Mapping"][0]
                          .isCustomField
                          ? `${salesforceConfigValue["Segment Mapping"][0].objectName}_Custom_Field`
                          : salesforceConfigValue["Segment Mapping"][0].value
                      }
                      onChange={(event) =>
                        handleSalesForceConfigChange(
                          integrateData?.SegmentMapping,
                          event.target.value,
                          0,
                          "",
                          "",
                          salesCustom[integrateData?.SegmentMapping]

                        )
                      }
                    >
                      <>
                        <div>
                          <div className="no-object-name">
                            <Space
                              direction="vertical"
                              style={{ width: "100%" }}
                              size={[22, 22]}
                            >
                              <Radio
                                value={"No Segment Mapping Required"}
                                className="radio-text"
                                disabled={false}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                      width: "100%",
                                    fontWeight: 500
                                  }}
                                >
                                  No Segment Mapping Required
                                  <Tooltip
                                    className="tooltip-arrow"
                                    placement="bottom"
                                    color={"white"}
                                    title={<Space
                                    direction="vertical"
                                    style={{ fontWeight: "400" }}
                                    size={[10, 10]}
                                    >
                                    <div style={{ color: "black" }}>
                                    Recommended for situations where only one Demand Gen segment exists
                                    </div>

                                  </Space>}
                                  >
                                <InfoCircleOutlined
                                  style={{ color: "#A9A9A9" }}
                                />
                                  </Tooltip>
                                </div>
                              </Radio>
                              </Space>
                          </div>
                            <Divider style={{ margin: "10px" }} />
                          <div className="object-name">Lead</div>
                          <Space
                            direction="vertical"
                            style={{ width: "100%" }}
                            size={[22, 22]}
                          >
                            <Radio
                              value={"Lead Source"}
                              className="radio-text"
                              disabled={false}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                Lead Source
                                <Tooltip
                                  className="tooltip-arrow"
                                  placement="bottom"
                                  color={"white"}
                                  title={<Space
                                  direction="vertical"
                                  style={{ fontWeight: "400" }}
                                  size={[10, 10]}
                                  >
                                  <div style={{ color: "black" }}>
                                  Choose the Lead Source field from the Salesforce Lead object to use for this mapping within Demand Gen.
                                  </div>

                               </Space>}
                                >
                              <InfoCircleOutlined
                                style={{ color: "#A9A9A9" }}
                              />
                                </Tooltip>
                              </div>
                            </Radio>
                            <Radio
                              value={"Lead_Custom_Field"}
                              className="radio-text"
                              disabled={!leadValue}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                Custom Field
                                <Tooltip
                                  className="tooltip-arrow"
                                  placement="bottom"
                                  color={"white"}
                                  title={<Space
                                  direction="vertical"
                                  style={{ fontWeight: "400" }}
                                  size={[10, 10]}
                                  >
                                  <div style={{ color: "black" }}>
                                  Choose a custom field from the Salesforce Lead object to use for this mapping within Demand Gen.
                                  </div>

                               </Space>}
                                >
                              <InfoCircleOutlined
                                style={{ color: "#A9A9A9" }}
                              />
                                </Tooltip>
                              </div>
                            </Radio>

                              <Select
                            value={leadValue && leadValue.includes(salesCustom[integrateData?.SegmentMapping]) ? salesCustom[integrateData?.SegmentMapping] : null}
                              size="large"
                              style={{
                                width: "100%",
                                marginTop: "-10px",
                              }}
                              disabled={
                                !(
                                  salesforceConfigValue["Segment Mapping"][0]
                                    .isCustomField &&
                                  salesforceConfigValue["Segment Mapping"][0]
                                    .objectName === "Lead"
                                ) ||(!leadValue)
                              }
                              defaultValue={
                                salesforceConfigValue["Segment Mapping"][0]
                                  .isCustomField &&
                                salesforceConfigValue["Segment Mapping"][0]
                                  .objectName === "Lead"
                                  ? salesforceConfigValue["Segment Mapping"][0]
                                      .value
                                  : "Select"
                              }
                              onChange={(value) =>
                               {
                                setSalesCustom({...salesCustom,[integrateData?.SegmentMapping] : value})
                                 handleSalesForceConfigChange(
                                 integrateData?.SegmentMapping,
                                  value,
                                  0,
                                  "Lead",
                                  true
                                )}
                              }
                            >
                              {leadValue && leadValue.map((option, indexval) => {
                                return (
                                  <Option key={option} value={option}>
                                    {option}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Space>
                          <Divider style={{ margin: "18px 0" }} />
                        </div>
                        <div>
                          <div className="object-name">Contact</div>
                          <Space
                            direction="vertical"
                            style={{ width: "100%" }}
                            size={[22, 22]}
                          >
                            <Radio
                              value={"Contact_Custom_Field"}
                              className="radio-text"
                              disabled={!contactValue}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                Custom Field
                                <Tooltip
                                  className="tooltip-arrow"
                                  placement="bottom"
                                  color={"white"}
                                  title={<Space
                                  direction="vertical"
                                  style={{ fontWeight: "400" }}
                                  size={[10, 10]}
                                  >
                                  <div style={{ color: "black" }}>
                                  Choose a custom field from the Salesforce Contact object to use for this mapping within Demand Gen.
                                  </div>

                               </Space>}
                                >
                              <InfoCircleOutlined
                                style={{ color: "#A9A9A9" }}
                              />
                                </Tooltip>
                              </div>
                            </Radio>
                             <Select
                            value={contactValue && contactValue.includes(salesCustom[integrateData?.SegmentMapping]) ? salesCustom[integrateData?.SegmentMapping] : null}
                              size="large"
                              style={{
                                width: "100%",
                                marginTop: "-10px",
                              }}
                              disabled={
                                !(
                                  salesforceConfigValue["Segment Mapping"][0]
                                    .isCustomField &&
                                  salesforceConfigValue["Segment Mapping"][0]
                                    .objectName === "Contact"
                                ) ||(!contactValue)
                              }
                              defaultValue={
                                salesforceConfigValue["Segment Mapping"][0]
                                  .isCustomField &&
                                salesforceConfigValue["Segment Mapping"][0]
                                  .objectName === "Contact"
                                  ? salesforceConfigValue["Segment Mapping"][0]
                                      .value
                                  : "Select"
                              }
                              onChange={(value) =>
                                {setSalesCustom({...salesCustom,[integrateData?.SegmentMapping] : value})
                                  handleSalesForceConfigChange(
                                 integrateData?.SegmentMapping,
                                  value,
                                  0,
                                  "Contact",
                                  true
                                )}
                              }
                            >
                              {contactValue !== undefined && contactValue !== null && contactValue.map((option, indexval) => {
                                return (
                                  <Option key={option} value={option}>
                                    {option}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Space>
                        </div>
                      </>
                    </Radio.Group>
                  </div>
                </Panel>
                <Panel
                  header={
                  <>
                  <Space style={{ width: "100%", justifyContent: "space-between" }}>{integrateData?.Market}
                      <Tooltip
                        className="tooltip-arrow"
                        placement="bottom"
                        color={"white"}
                        title={<Space
                          direction="vertical"
                          style={{ fontWeight: "400" }}
                          size={[10, 10]}
                        >
                          <div style={{ color: "black" }}>
                          Demand Generation Marketing Sourced is mapped within Revenue Planning (step #2) using CRM fields specified here.
                          </div>

                  </Space>}
                            >
                        <div className="tooltip-wrapper" >

                            { saleForceMessageObj.includes(integrateData?.Market) && <div
                            className="mapping-stages-dot show-stage-chnages-text"
                            style={{ marginLeft: 10 }}
                            >

                            </div>   }
                            <InfoCircleOutlined
                            style={{ color: "#A9A9A9" }}
                            />
                            </div>
                      </Tooltip>
                     </Space>
                      </>
                      }
                  key={integrateData?.Market}
                  className="rrr"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "18px 0",
                      width: "100%",
                    }}
                  >
                    <Radio.Group
                      style={{ width: "100%" }}
                      value={
                        salesforceConfigValue[
                          "Marketing Sourced Mapping"
                        ][0].isCustomField
                          ? `${salesforceConfigValue["Marketing Sourced Mapping"][0].objectName}_Custom_Field`
                          : salesforceConfigValue[
                              "Marketing Sourced Mapping"
                            ][0].value
                      }
                      onChange={(event) =>
                        handleSalesForceConfigChange(
                         integrateData?.Market,
                          event.target.value,
                          0,
                           "",
                          "",
                          salesCustom[integrateData?.Market]
                        )
                      }
                    >
                      <>
                        <div>
                          <div className="object-name">Lead</div>
                          <Space
                            direction="vertical"
                            style={{ width: "100%" }}
                            size={[22, 22]}
                          >
                            <Radio
                              value={"Lead Source"}
                              className="radio-text"
                              disabled={false}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                Lead Source
                                <Tooltip
                                  className="tooltip-arrow"
                                  placement="bottom"
                                  color={"white"}
                                  title={<Space
                                  direction="vertical"
                                  style={{ fontWeight: "400" }}
                                  size={[10, 10]}
                                  >
                                  <div style={{ color: "black" }}>
                                  Choose the Lead Source field from the Salesforce Lead object to use for this mapping within Demand Gen.
                                  </div>

                               </Space>}
                                >
                              <InfoCircleOutlined
                                style={{ color: "#A9A9A9" }}
                              />
                                </Tooltip>
                              </div>
                            </Radio>
                            <Radio
                              value={"Lead_Custom_Field"}
                              className="radio-text"
                              disabled={!leadValue}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                Custom Field
                                <Tooltip
                                  className="tooltip-arrow"
                                  placement="bottom"
                                  color={"white"}
                                  title={<Space
                                  direction="vertical"
                                  style={{ fontWeight: "400" }}
                                  size={[10, 10]}
                                  >
                                  <div style={{ color: "black" }}>
                                  Choose a custom field from the Salesforce Lead object to use for this mapping within Demand Gen.
                                  </div>

                               </Space>}
                                >
                              <InfoCircleOutlined
                                style={{ color: "#A9A9A9" }}
                              />
                                </Tooltip>
                              </div>
                            </Radio>
                         <Select
                            value={leadValue && leadValue.includes(salesCustom[integrateData?.Market]) ? salesCustom[integrateData?.Market] : null}
                              size="large"
                              style={{
                                width: "100%",
                                marginTop: "-10px",
                              }}
                              disabled={
                                !(
                                  salesforceConfigValue[
                                    "Marketing Sourced Mapping"
                                  ][0].isCustomField &&
                                  salesforceConfigValue[
                                    "Marketing Sourced Mapping"
                                  ][0].objectName === "Lead"
                                ) || (!leadValue)

                              }
                              onChange={(value) =>
                                {setSalesCustom({...salesCustom,[integrateData?.Market] : value})
                                  handleSalesForceConfigChange(
                                  integrateData?.Market,
                                  value,
                                  0,
                                  "Lead",
                                  true
                                )}
                              }
                            >
                              {leadValue  && leadValue.map((option, indexval) => {
                                return (
                                  <Option key={option} value={option}>
                                    {option}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Space>
                          <Divider style={{ margin: "18px 0" }} />
                        </div>
                        <div>
                          <div className="object-name">Contact</div>
                          <Space
                            direction="vertical"
                            style={{ width: "100%" }}
                            size={[22, 22]}
                          >
                           <Radio
                              value={"Contact_Custom_Field"}
                              className="radio-text"
                              disabled={!contactValue}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                Custom Field
                                <Tooltip
                                  className="tooltip-arrow"
                                  placement="bottom"
                                  color={"white"}
                                  title={<Space
                                  direction="vertical"
                                  style={{ fontWeight: "400" }}
                                  size={[10, 10]}
                                  >
                                  <div style={{ color: "black" }}>
                                  Choose a custom field from the Salesforce Contact object to use for this mapping within Demand Gen.
                                  </div>

                               </Space>}
                                >
                              <InfoCircleOutlined
                                style={{ color: "#A9A9A9" }}
                              />
                                </Tooltip>
                              </div>
                            </Radio>
                            <Select
                            value={contactValue && contactValue.includes(salesCustom[integrateData?.Market]) ? salesCustom[integrateData?.Market] : null}
                              size="large"
                              style={{
                                width: "100%",
                                marginTop: "-10px",
                              }}
                              disabled={
                                !(
                                  salesforceConfigValue[
                                    "Marketing Sourced Mapping"
                                  ][0].isCustomField &&
                                  salesforceConfigValue[
                                    "Marketing Sourced Mapping"
                                  ][0].objectName === "Contact"
                                ) || (!contactValue)
                              }
                              defaultValue={
                                salesforceConfigValue[
                                  "Marketing Sourced Mapping"
                                ][0].isCustomField &&
                                salesforceConfigValue[
                                  "Marketing Sourced Mapping"
                                ][0].objectName === "Contact"
                                  ? salesforceConfigValue[
                                      "Marketing Sourced Mapping"
                                    ][0].value
                                  : "Select"
                              }
                              onChange={(value) =>
                                {setSalesCustom({...salesCustom,[integrateData?.Market] : value})
                                  handleSalesForceConfigChange(
                                  integrateData?.Market,
                                  value,
                                  0,
                                  "Contact",
                                  true
                                )}
                              }
                            >
                              {contactValue !== undefined && contactValue !== null && contactValue.map((option, indexval) => {
                                return (
                                  <Option key={option} value={option}>
                                    {option}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Space>
                        </div>
                      </>
                    </Radio.Group>
                  </div>
                </Panel>
                <Panel
                header={
                  <>
                  <Space style={{ width: "100%", justifyContent: "space-between" }}>{integrateData?.Campaign}
                      <Tooltip
                        className="tooltip-arrow"
                        placement="bottom"
                        color={"white"}
                        title={<Space
                          direction="vertical"
                          style={{ fontWeight: "400" }}
                          size={[10, 10]}
                        >
                          <div style={{ color: "black" }}>
                          Demand Generation Campaigns are mapped within Budget & Campaigns using CRM fields specified here.
                          </div>

                  </Space>}
                            >
                        <InfoCircleOutlined
                          style={{ color: "#A9A9A9" }}
                        />
                      </Tooltip>
                     </Space>
                      </>
                      }
                key={integrateData?.Campaign}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "18px 0",
                      width: "100%",
                    }}
                  >
                    <Radio.Group
                      style={{ width: "100%" }}
                      value={salesforceConfigValue[integrateData?.Campaign][0].value}
                      onChange={(event) =>
                        handleSalesForceConfigChange(
                          integrateData?.Campaign,
                          event.target.value,
                          0,
                          null,
                          false
                        )
                      }
                    >
                      <Radio
                        value={"Salesforce Campaign"}
                        className="radio-text"
                        disabled={true}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          Salesforce Campaign
                          <Tooltip
                          className="tooltip-arrow"
                                  placement="bottom"
                                  color={"white"}
                                  title={<Space
                                  direction="vertical"
                                  style={{ fontWeight: "400" }}
                                  size={[10, 10]}
                                  >
                                  <div style={{ color: "black" }}>
                                  Choose the Salesforce Campaign object for this mapping within Demand Gen.
                                  </div>

                               </Space>}
                                >
                              <InfoCircleOutlined
                                style={{ color: "#A9A9A9" }}
                              />
                          </Tooltip>
                        </div>
                      </Radio>
                    </Radio.Group>
                  </div>
                </Panel>
                <Panel
                header={
                  <>
                  <Space style={{ width: "100%", justifyContent: "space-between" }}>{integrateData?.PipelineMapping}
                      <Tooltip
                        className="tooltip-arrow"
                        placement="bottom"
                        color={"white"}
                        title={<Space
                          direction="vertical"
                          style={{ fontWeight: "400" }}
                          size={[10, 10]}
                        >
                          <div style={{ color: "black" }}>
                          Demand Generation Pipelines are mapped within Sales & Marketing Alignment using CRM fields specified here.
                          </div>

                  </Space>}
                            >
                        <div className="tooltip-wrapper" >

                          { saleForceMessageObj.includes(integrateData?.PipelineMapping) && <div
                          className="mapping-stages-dot show-stage-chnages-text"
                          style={{ marginLeft: 10 }}
                          ></div>   }
                          <InfoCircleOutlined
                          style={{ color: "#A9A9A9" }}
                          />
                          </div>
                      </Tooltip>
                     </Space>
                      </>
                      }
                 key={integrateData?.PipelineMapping}>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "18px 0",
                      width: "100%",
                    }}
                  >

                    <Radio.Group
                      style={{ width: "100%" }}
                      value={setSalesforceValue(salesforceConfigValue["Pipeline and Revenue Mapping"][0])
                      }


                      onChange={(event) =>
                        {
                          handleSalesForceConfigChange(
                            integrateData?.PipelineMapping,
                            event.target.value,
                            0,
                            "Opportunity",
                            "",
                            salesCustom[integrateData?.PipelineMapping]
                          )
                        }
                      }
                    >
                      <>

                        <div>
                          <div className="object-name">Opportunity</div>
                          <Space
                            direction="vertical"
                            style={{ width: "100%" }}
                            size={[22, 22]}
                          >
                            <Radio
                              value={"Amount"}
                              className="radio-text"
                              disabled={false}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                Amount
                                <Tooltip
                                   className="tooltip-arrow"
                        placement="bottom"
                        color={"white"}
                        title={<Space
                          direction="vertical"
                          style={{ fontWeight: "400" }}
                          size={[10, 10]}
                        >
                          <div style={{ color: "black" }}>
                          Choose the Opportunity Amount field from the Salesforce Lead object to use for this mapping within Demand Gen.
                          </div>

                  </Space>}
                            >
                        <InfoCircleOutlined
                          style={{ color: "#A9A9A9" }}
                        />
                                </Tooltip>
                              </div>
                            </Radio>
                            <Radio
                            value={"Opportunity_Custom_Field"}
                              className="radio-text"
                              disabled={!opportunityAmountValue}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                Custom Field
                                <Tooltip
                                  className="tooltip-arrow"
                                  placement="bottom"
                                  color={"white"}
                                  title={<Space
                                  direction="vertical"
                                  style={{ fontWeight: "400" }}
                                  size={[10, 10]}
                                  >
                                  <div style={{ color: "black" }}>
                                  Choose a custom field from the Salesforce Lead object to use for this mapping within Demand Gen.
                                  </div>

                               </Space>}
                                >
                              <InfoCircleOutlined
                                style={{ color: "#A9A9A9" }}
                              />
                                </Tooltip>
                              </div>
                            </Radio>
                            <Select
                             value={opportunityAmountValue &&opportunityAmountValue.includes(salesCustom[integrateData?.PipelineMapping]) ? salesCustom[integrateData?.PipelineMapping] : null}
                              size="large"
                              style={{
                                width: "100%",
                                marginTop: "-10px",
                              }}
                              disabled={
                                !(
                                  salesforceConfigValue[integrateData?.PipelineMapping][0]
                                    .isCustomField &&
                                  salesforceConfigValue[integrateData?.PipelineMapping][0]
                                    .objectName === "Opportunity"
                                )||(!opportunityAmountValue)
                              }
                              onChange={(value) =>
                                {
                                  setSalesCustom({...salesCustom,[integrateData?.PipelineMapping] : value})
                                  handleSalesForceConfigChange(
                                  integrateData?.PipelineMapping,
                                  value,
                                  0,
                                  "Opportunity",
                                  true
                                )}
                              }
                            >
                              {opportunityAmountValue && opportunityAmountValue.map((option, indexval) => {
                                return (
                                  <Option key={option} value={option}>
                                    {option}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Space>
                        </div>

                      </>
                    </Radio.Group>

                  </div>
                </Panel>
              </Collapse>
            </>
          )}
        </div>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            zIndex: 2,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
          }}
        >
          <Button
            disabled={isLoading}
            onClick={handleCloseConfigDrawer}
            style={{ fontSize: 18, marginRight: 8, width: "48%" }}
            className="secondary-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={showConfirm}
            type="primary"
            style={{ fontSize: 18, width: "48%" }}
            disabled= {
              openConfig === "Hubspot" ? (hubSpotMessageObj.length >0 ? false : true) : (saleForceMessageObj.length >0 ? false : true)
            }
          >
            Save
          </Button>

        </div>
        <Modal
          title="Save Config Mapping Updates?"
          className="confirm-save-model"
          visible={confirmVisible}
          style={{ borderRadius: '10px' ,}}
          onCancel={handleConfirmCancel}
          centered
          footer={[
             < >
              <Button className="cancel-btn" onClick={handleConfirmCancel}>No</Button>
              <Button loading={loader} className = "save-btn" type="primary"  onClick={() => openConfig === "Hubspot" ? MappingConfigs(openConfig) : SalesforceMappingConfig(openConfig)}>
                Yes
              </Button>
              </>
               ]}
            >
            {hubSpotMessageObj.length >0 ? <p>All existing <span style={{fontWeight:"bold"}}>{hubSpotMessageObj.join(" / ")}</span> will be overridden. Are you sure?</p> :
            saleForceMessageObj.length >0 ? <p>All existing <span style={{fontWeight:"bold"}}>{saleForceMessageObj.join(" / ")}</span> will be overridden. Are you sure?</p> : <p>No changes to be Update.</p>}

      </Modal>
      </Drawer>
    </div>
  );
};
export default Integration;
