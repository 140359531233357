import {
  Table,
  Select,
  Space,
  Input,
  Button,
  Form,
  Dropdown,
  Menu,
  Skeleton,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { listPropertyPackageData } from "store/actions/reportAction";
import { MoreOutlined } from "@ant-design/icons";
import CreatePackage from "./CreatePackage";
import EditProperty from "./EditProperty";

const PackageList = (props) => {
  const [SearchParams, setSearchParams] = useState({});
  const [packagePropertyListBackupList, setPackagePropertyListBackupList] =
    useState({});
  const [visible, setVisible] = useState(false);
  const [selectedPackageInfo, setSelectedPackageInfo] = useState();
  // const { Option } = Select;
  const [form] = Form.useForm();

  // const [searchOption, setsearchOption] = useState("packagelist");
  const dispatch = useDispatch();

  // const userData = useSelector(state => state.loginReducer.userData)
  const { packagePropertyList } = useSelector(
    (state) => state.reportReducer
  );

  const showCreatePackageDrawer = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setIsEditPackage(false);
  };

  const [getPropertiesLoader, setgetPropertiesLoader] = useState(false)
  useEffect(() => {
    setgetPropertiesLoader(true)
    dispatch(listPropertyPackageData()).then(res => {
      setgetPropertiesLoader(false)
    }).catch(err => {
      setgetPropertiesLoader(false)

    });
  }, [dispatch]);

  const [selectedPackageId, setSelectedPackageId] = useState();
  const [selectedPackageDetails, setSelectedPackageDetails] = useState();
  useEffect(() => {
    if (selectedPackageId) {
      const packageData = packagePropertyList?.find(
        (val) => val.id === selectedPackageId
      );
      setSelectedPackageInfo(packageData?.properties?.items);
      setPackagePropertyListBackupList(
        packageData?.properties?.items
      );
      setSelectedPackageDetails({
        name: packageData?.name,
        id: packageData?.id,
      });
      // setSelectedPackageId(packagePropertyList?.find((val) => val.id === selectedPackageId)?.id)
    } else {
      setSelectedPackageInfo(packagePropertyList?.[0]?.properties?.items);
      setPackagePropertyListBackupList(
        packagePropertyList?.[0]?.properties?.items
      );
      setSelectedPackageId(packagePropertyList?.[0]?.id);
      setSelectedPackageDetails({
        name: packagePropertyList?.[0]?.name,
        id: packagePropertyList?.[0]?.id,
      });
    }
    form.setFieldsValue({ package_name: packagePropertyList?.[0]?.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packagePropertyList]);

  const handle_search = (value) => {
    setSelectedPackageInfo(packagePropertyListBackupList);
    setSearchParams({
      Name: value["package_name"],
      Value: value["search_value"] ? value["search_value"].toLowerCase() : "",
    });
  };

  useEffect(() => {
    searchPackageProperty(SearchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchParams]);

  const searchPackageProperty = (SearchParams) => {
    setSelectedPackageInfo(
      selectedPackageInfo?.filter((val) =>
        String(val?.key)
          ?.toLowerCase()
          ?.includes(SearchParams?.Value?.toLowerCase()?.trim())
      )
    );
  };

  const onSelectPackage = (selectedPackageId) => {
    const selectedPackage = packagePropertyList.find(
      (val) => val.id === selectedPackageId
    );
    setSelectedPackageInfo(selectedPackage?.properties?.items);
    setPackagePropertyListBackupList(selectedPackage?.properties?.items)
    setSelectedPackageDetails({
      name: selectedPackage?.name,
      id: selectedPackage?.id,
    });
    setSelectedPackageId(selectedPackageId);
  };

  const [isEdit, setIsEdit] = useState(false);
  const [editObjectValue, setEditObjectValue] = useState({});
  const onPackagePropertyEdit = (selectedObj) => {
    setIsEdit(true);
    setEditObjectValue(selectedObj);
  };

  const handleCancelPropertyEdit = () => {
    setIsEdit(false);
  };

  const [isEditPackage, setIsEditPackage] = useState();
  const onEditPackage = () => {
    setVisible(true);
    setIsEditPackage(true);
  };

  const columns = [
    {
      title: "Property Name",
      width:"75%",
      dataIndex: "key",
      key: "key",
      align: "center",
      render: (name) => {
        return (
          <div style={{ display: "flex" }}>
            <div style={{ width: "97%", textAlign: "left" }}>{name}</div>
          </div>
        );
      },
      sorter: (a, b) => {
        return a?.key?.localeCompare(b?.key);
      },
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "Value",
      width:"25%",
      dataIndex: "value",
      key: "value",
      align: "center",
      render: (name, record) => {
        return (
          <div style={{ display: "flex" }}>
            <div style={{ width: "90%", textAlign: "left" }}>{name}</div>
            <div style={{ width: "10%" }}>
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu>
                    <Menu.Item
                      key="edit-pacakge"
                      onClick={() => onPackagePropertyEdit(record)}
                    >
                      <strong>Edit</strong>
                    </Menu.Item>

                    {/* <Menu.Item key="delete" onClick={() => {}}>
                      <strong>Delete</strong>
                    </Menu.Item> */}
                  </Menu>
                }
              >
                <MoreOutlined style={{ fontSize: "25px" }} />
              </Dropdown>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="user-container packageListBox" style={{ paddingTop: 0 }}>
        {/* {isLoading && <div className="loading">Loading;</div>} */}
        <Space direction="vertical" style={{ width: "100%" }} size={1}>
          <Form
            name="search_users"
            onFinish={handle_search}
            // initialValues={{ package_name: selectedPackageId }}
          >
            <div style={{
                marginBottom: "30px",
                fontSize: "18px",
                background: "#f5f7fa",
                padding: "10px 5px",
                textAlign: "center",
                width:"100%",
                display:"block",
                color:"#1D2939"
              }}
              className="titleText fw500 panel-design">Package</div>
            
            <Space
            className="plr-0"
              direction="horizontal"
              style={{
                padding: "0px 5px",
                paddingLeft: "0",
                width: "100%",
                paddingRight: "0",
                justifyContent:'center'
              }}
            >
              <Form.Item
                name="package_name"
                // label={
                //   <div style={{ paddingTop: 0, paddingRight: 10}}>Package</div>
                // }
                colon={false}
              >
                {" "}
                <Select
                  style={{ width: "300px" }}
                  size="large"
                  onChange={(e) => onSelectPackage(e)}
                  showSearch
                  optionFilterProp="children"
                  defaultValue={selectedPackageId}
                  value={selectedPackageId}
                  key="package_name"
                  className="general-input-select"
                >
                  {packagePropertyList?.map((val) => {
                    return (
                      <Select.Option value={val.id} key={val.id}>
                        {val.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {selectedPackageId && <div className="edit-package" onClick={onEditPackage}>
              <Form.Item>
                <Button type="primary" htmlType="button" size="middle" className="primaryButton" style={{width:"150px"}}>
                Edit package
                </Button>
              </Form.Item>
              </div>}
              <Form.Item name="search_value" style={{ width: "300px" }}>
                <Input placeholder="Search" size="large" className="general-input"/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" size="middle" className="primaryButton" style={{width:"150px"}}>
                  Search
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  size="middle"
                  onClick={showCreatePackageDrawer}
                  className="secondaryButton"
                  style={{width:'150px'}}
                >
                  + Add new
                </Button>
              </Form.Item>
            </Space>
          </Form>
        
        {getPropertiesLoader ? <Skeleton active paragraph={{rows:6}} style={{ paddingLeft: "20%", paddingRight: "20%" }} /> :
          <div className="table-wrapper">

            <Table
              bordered
              size="middle"
              dataSource={selectedPackageInfo}
              columns={columns}
              className="plr-0 general-table company-package-table"
              style={{ marginTop:"30px", padding:"0 15px" }}
            ></Table>
          </div>
          }
        </Space>
        <CreatePackage
          visible={visible}
          handleCancel={handleCancel}
          selectedPackageDetails={selectedPackageDetails}
          selectedPackageInfo={packagePropertyListBackupList}
          isEditPackage = {isEditPackage}
        />
        <EditProperty
          visibleEdit={isEdit}
          handleCancelPropertyEdit={handleCancelPropertyEdit}
          valueObj={editObjectValue}
          selectedPackageId={selectedPackageId}
        />
      </div>
    </>
  );
};

export default PackageList;
