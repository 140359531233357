/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      company
      company_loweredcased
      company_description
      product_description
      tagline
      url
      accessible_site_url
      fiscalYearMonth
      disabled
      users
      metadata
      createdAt
      updatedAt
      colors
      profile_url
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      industry
      company_size
      owners
      editors
      viewers
      requests {
        items {
          id
          account
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        id
        name
        description
        bulletPoints
        packageID
        stripePriceID
        priceValues
        listed
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      company
      company_loweredcased
      company_description
      product_description
      tagline
      url
      accessible_site_url
      fiscalYearMonth
      disabled
      users
      metadata
      createdAt
      updatedAt
      colors
      profile_url
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      industry
      company_size
      owners
      editors
      viewers
      requests {
        items {
          id
          account
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        id
        name
        description
        bulletPoints
        packageID
        stripePriceID
        priceValues
        listed
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      company
      company_loweredcased
      company_description
      product_description
      tagline
      url
      accessible_site_url
      fiscalYearMonth
      disabled
      users
      metadata
      createdAt
      updatedAt
      colors
      profile_url
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      industry
      company_size
      owners
      editors
      viewers
      requests {
        items {
          id
          account
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        id
        name
        description
        bulletPoints
        packageID
        stripePriceID
        priceValues
        listed
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const createAccountRequest = /* GraphQL */ `
  mutation CreateAccountRequest(
    $input: CreateAccountRequestInput!
    $condition: ModelAccountRequestConditionInput
  ) {
    createAccountRequest(input: $input, condition: $condition) {
      id
      account
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateAccountRequest = /* GraphQL */ `
  mutation UpdateAccountRequest(
    $input: UpdateAccountRequestInput!
    $condition: ModelAccountRequestConditionInput
  ) {
    updateAccountRequest(input: $input, condition: $condition) {
      id
      account
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccountRequest = /* GraphQL */ `
  mutation DeleteAccountRequest(
    $input: DeleteAccountRequestInput!
    $condition: ModelAccountRequestConditionInput
  ) {
    deleteAccountRequest(input: $input, condition: $condition) {
      id
      account
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createOAuthCreds = /* GraphQL */ `
  mutation CreateOAuthCreds(
    $input: CreateOAuthCredsInput!
    $condition: ModeloAuthCredsConditionInput
  ) {
    createOAuthCreds(input: $input, condition: $condition) {
      id
      account
      platform
      refresh_token
      createdAt
      updatedAt
    }
  }
`;
export const updateOAuthCreds = /* GraphQL */ `
  mutation UpdateOAuthCreds(
    $input: UpdateOAuthCredsInput!
    $condition: ModeloAuthCredsConditionInput
  ) {
    updateOAuthCreds(input: $input, condition: $condition) {
      id
      account
      platform
      refresh_token
      createdAt
      updatedAt
    }
  }
`;
export const deleteOAuthCreds = /* GraphQL */ `
  mutation DeleteOAuthCreds(
    $input: DeleteOAuthCredsInput!
    $condition: ModeloAuthCredsConditionInput
  ) {
    deleteOAuthCreds(input: $input, condition: $condition) {
      id
      account
      platform
      refresh_token
      createdAt
      updatedAt
    }
  }
`;
export const createWebProfiles = /* GraphQL */ `
  mutation CreateWebProfiles(
    $input: CreateWebProfilesInput!
    $condition: ModelWebProfilesConditionInput
  ) {
    createWebProfiles(input: $input, condition: $condition) {
      id
      account
      platform
      profile_id
      metadata
      enabled
      createdAt
      updatedAt
    }
  }
`;
export const updateWebProfiles = /* GraphQL */ `
  mutation UpdateWebProfiles(
    $input: UpdateWebProfilesInput!
    $condition: ModelWebProfilesConditionInput
  ) {
    updateWebProfiles(input: $input, condition: $condition) {
      id
      account
      platform
      profile_id
      metadata
      enabled
      createdAt
      updatedAt
    }
  }
`;
export const deleteWebProfiles = /* GraphQL */ `
  mutation DeleteWebProfiles(
    $input: DeleteWebProfilesInput!
    $condition: ModelWebProfilesConditionInput
  ) {
    deleteWebProfiles(input: $input, condition: $condition) {
      id
      account
      platform
      profile_id
      metadata
      enabled
      createdAt
      updatedAt
    }
  }
`;
export const createFiscalYear = /* GraphQL */ `
  mutation CreateFiscalYear(
    $input: CreateFiscalYearInput!
    $condition: ModelFiscalYearConditionInput
  ) {
    createFiscalYear(input: $input, condition: $condition) {
      id
      account
      year
      budget
      startMonth
      createdAt
      updatedAt
      baselineMetrics {
        items {
          id
          fiscalYearId
          name
          goal
          avgDealSize
          mrktSourcePercent
          updatedBy
          account
          mrkt_year_won_leads
          sales_year_won_leads
          budget_allocation
          mrkt_qtr_won_leads
          sales_qtr_won_leads
          mrkt_qtr_budget_allocation
          sales_qtr_budget_allocation
          segment_type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateFiscalYear = /* GraphQL */ `
  mutation UpdateFiscalYear(
    $input: UpdateFiscalYearInput!
    $condition: ModelFiscalYearConditionInput
  ) {
    updateFiscalYear(input: $input, condition: $condition) {
      id
      account
      year
      budget
      startMonth
      createdAt
      updatedAt
      baselineMetrics {
        items {
          id
          fiscalYearId
          name
          goal
          avgDealSize
          mrktSourcePercent
          updatedBy
          account
          mrkt_year_won_leads
          sales_year_won_leads
          budget_allocation
          mrkt_qtr_won_leads
          sales_qtr_won_leads
          mrkt_qtr_budget_allocation
          sales_qtr_budget_allocation
          segment_type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteFiscalYear = /* GraphQL */ `
  mutation DeleteFiscalYear(
    $input: DeleteFiscalYearInput!
    $condition: ModelFiscalYearConditionInput
  ) {
    deleteFiscalYear(input: $input, condition: $condition) {
      id
      account
      year
      budget
      startMonth
      createdAt
      updatedAt
      baselineMetrics {
        items {
          id
          fiscalYearId
          name
          goal
          avgDealSize
          mrktSourcePercent
          updatedBy
          account
          mrkt_year_won_leads
          sales_year_won_leads
          budget_allocation
          mrkt_qtr_won_leads
          sales_qtr_won_leads
          mrkt_qtr_budget_allocation
          sales_qtr_budget_allocation
          segment_type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createBaselineMetric = /* GraphQL */ `
  mutation CreateBaselineMetric(
    $input: CreateBaselineMetricInput!
    $condition: ModelBaselineMetricConditionInput
  ) {
    createBaselineMetric(input: $input, condition: $condition) {
      id
      fiscalYearId
      name
      goal
      avgDealSize
      mrktSourcePercent
      updatedBy
      account
      mrkt_year_won_leads
      sales_year_won_leads
      budget_allocation
      mrkt_qtr_won_leads
      sales_qtr_won_leads
      mrkt_qtr_budget_allocation
      sales_qtr_budget_allocation
      segment_type
      createdAt
      updatedAt
      funnelConfigs {
        items {
          id
          baselineMetricId
          name
          account
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateBaselineMetric = /* GraphQL */ `
  mutation UpdateBaselineMetric(
    $input: UpdateBaselineMetricInput!
    $condition: ModelBaselineMetricConditionInput
  ) {
    updateBaselineMetric(input: $input, condition: $condition) {
      id
      fiscalYearId
      name
      goal
      avgDealSize
      mrktSourcePercent
      updatedBy
      account
      mrkt_year_won_leads
      sales_year_won_leads
      budget_allocation
      mrkt_qtr_won_leads
      sales_qtr_won_leads
      mrkt_qtr_budget_allocation
      sales_qtr_budget_allocation
      segment_type
      createdAt
      updatedAt
      funnelConfigs {
        items {
          id
          baselineMetricId
          name
          account
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteBaselineMetric = /* GraphQL */ `
  mutation DeleteBaselineMetric(
    $input: DeleteBaselineMetricInput!
    $condition: ModelBaselineMetricConditionInput
  ) {
    deleteBaselineMetric(input: $input, condition: $condition) {
      id
      fiscalYearId
      name
      goal
      avgDealSize
      mrktSourcePercent
      updatedBy
      account
      mrkt_year_won_leads
      sales_year_won_leads
      budget_allocation
      mrkt_qtr_won_leads
      sales_qtr_won_leads
      mrkt_qtr_budget_allocation
      sales_qtr_budget_allocation
      segment_type
      createdAt
      updatedAt
      funnelConfigs {
        items {
          id
          baselineMetricId
          name
          account
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createFunnelConfig = /* GraphQL */ `
  mutation CreateFunnelConfig(
    $input: CreateFunnelConfigInput!
    $condition: ModelFunnelConfigConditionInput
  ) {
    createFunnelConfig(input: $input, condition: $condition) {
      id
      baselineMetricId
      name
      account
      createdAt
      updatedAt
      stages {
        items {
          id
          funnelConfigID
          order
          name
          conversion
          count
          account
          salesAndMarketingAlignmentID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateFunnelConfig = /* GraphQL */ `
  mutation UpdateFunnelConfig(
    $input: UpdateFunnelConfigInput!
    $condition: ModelFunnelConfigConditionInput
  ) {
    updateFunnelConfig(input: $input, condition: $condition) {
      id
      baselineMetricId
      name
      account
      createdAt
      updatedAt
      stages {
        items {
          id
          funnelConfigID
          order
          name
          conversion
          count
          account
          salesAndMarketingAlignmentID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteFunnelConfig = /* GraphQL */ `
  mutation DeleteFunnelConfig(
    $input: DeleteFunnelConfigInput!
    $condition: ModelFunnelConfigConditionInput
  ) {
    deleteFunnelConfig(input: $input, condition: $condition) {
      id
      baselineMetricId
      name
      account
      createdAt
      updatedAt
      stages {
        items {
          id
          funnelConfigID
          order
          name
          conversion
          count
          account
          salesAndMarketingAlignmentID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createStage = /* GraphQL */ `
  mutation CreateStage(
    $input: CreateStageInput!
    $condition: ModelStageConditionInput
  ) {
    createStage(input: $input, condition: $condition) {
      id
      funnelConfigID
      order
      name
      conversion
      count
      account
      salesAndMarketingAlignmentID
      createdAt
      updatedAt
      salesAndMarketingAlignment {
        id
        account
        name
        disabled
        isDefault
        isLast
        showPipelineCoverage
        order
        howitcomes
        thingstokeepinmind
        definition
        stageType
        internalStage
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateStage = /* GraphQL */ `
  mutation UpdateStage(
    $input: UpdateStageInput!
    $condition: ModelStageConditionInput
  ) {
    updateStage(input: $input, condition: $condition) {
      id
      funnelConfigID
      order
      name
      conversion
      count
      account
      salesAndMarketingAlignmentID
      createdAt
      updatedAt
      salesAndMarketingAlignment {
        id
        account
        name
        disabled
        isDefault
        isLast
        showPipelineCoverage
        order
        howitcomes
        thingstokeepinmind
        definition
        stageType
        internalStage
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteStage = /* GraphQL */ `
  mutation DeleteStage(
    $input: DeleteStageInput!
    $condition: ModelStageConditionInput
  ) {
    deleteStage(input: $input, condition: $condition) {
      id
      funnelConfigID
      order
      name
      conversion
      count
      account
      salesAndMarketingAlignmentID
      createdAt
      updatedAt
      salesAndMarketingAlignment {
        id
        account
        name
        disabled
        isDefault
        isLast
        showPipelineCoverage
        order
        howitcomes
        thingstokeepinmind
        definition
        stageType
        internalStage
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSalesAndMarketingAlignment = /* GraphQL */ `
  mutation CreateSalesAndMarketingAlignment(
    $input: CreateSalesAndMarketingAlignmentInput!
    $condition: ModelSalesAndMarketingAlignmentConditionInput
  ) {
    createSalesAndMarketingAlignment(input: $input, condition: $condition) {
      id
      account
      name
      disabled
      isDefault
      isLast
      showPipelineCoverage
      order
      howitcomes
      thingstokeepinmind
      definition
      stageType
      internalStage
      createdAt
      updatedAt
    }
  }
`;
export const updateSalesAndMarketingAlignment = /* GraphQL */ `
  mutation UpdateSalesAndMarketingAlignment(
    $input: UpdateSalesAndMarketingAlignmentInput!
    $condition: ModelSalesAndMarketingAlignmentConditionInput
  ) {
    updateSalesAndMarketingAlignment(input: $input, condition: $condition) {
      id
      account
      name
      disabled
      isDefault
      isLast
      showPipelineCoverage
      order
      howitcomes
      thingstokeepinmind
      definition
      stageType
      internalStage
      createdAt
      updatedAt
    }
  }
`;
export const deleteSalesAndMarketingAlignment = /* GraphQL */ `
  mutation DeleteSalesAndMarketingAlignment(
    $input: DeleteSalesAndMarketingAlignmentInput!
    $condition: ModelSalesAndMarketingAlignmentConditionInput
  ) {
    deleteSalesAndMarketingAlignment(input: $input, condition: $condition) {
      id
      account
      name
      disabled
      isDefault
      isLast
      showPipelineCoverage
      order
      howitcomes
      thingstokeepinmind
      definition
      stageType
      internalStage
      createdAt
      updatedAt
    }
  }
`;
export const createBudget = /* GraphQL */ `
  mutation CreateBudget(
    $input: CreateBudgetInput!
    $condition: ModelBudgetConditionInput
  ) {
    createBudget(input: $input, condition: $condition) {
      id
      account
      name
      active
      year
      budget
      budget_allocation
      BaselineMetricID
      lockedMonths
      createdAt
      updatedAt
      campaigns {
        items {
          id
          account
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateBudget = /* GraphQL */ `
  mutation UpdateBudget(
    $input: UpdateBudgetInput!
    $condition: ModelBudgetConditionInput
  ) {
    updateBudget(input: $input, condition: $condition) {
      id
      account
      name
      active
      year
      budget
      budget_allocation
      BaselineMetricID
      lockedMonths
      createdAt
      updatedAt
      campaigns {
        items {
          id
          account
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteBudget = /* GraphQL */ `
  mutation DeleteBudget(
    $input: DeleteBudgetInput!
    $condition: ModelBudgetConditionInput
  ) {
    deleteBudget(input: $input, condition: $condition) {
      id
      account
      name
      active
      year
      budget
      budget_allocation
      BaselineMetricID
      lockedMonths
      createdAt
      updatedAt
      campaigns {
        items {
          id
          account
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createCampaignChannel = /* GraphQL */ `
  mutation CreateCampaignChannel(
    $input: CreateCampaignChannelInput!
    $condition: ModelCampaignChannelConditionInput
  ) {
    createCampaignChannel(input: $input, condition: $condition) {
      id
      name
      channelType
      account
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaignChannel = /* GraphQL */ `
  mutation UpdateCampaignChannel(
    $input: UpdateCampaignChannelInput!
    $condition: ModelCampaignChannelConditionInput
  ) {
    updateCampaignChannel(input: $input, condition: $condition) {
      id
      name
      channelType
      account
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaignChannel = /* GraphQL */ `
  mutation DeleteCampaignChannel(
    $input: DeleteCampaignChannelInput!
    $condition: ModelCampaignChannelConditionInput
  ) {
    deleteCampaignChannel(input: $input, condition: $condition) {
      id
      name
      channelType
      account
      createdAt
      updatedAt
    }
  }
`;
export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      id
      account
      name
      channel
      vendor
      metadata
      createdAt
      updatedAt
      campaign_details {
        items {
          id
          account
          campaign_id
          date_start
          date_end
          metadata
          createdAt
          updatedAt
        }
        nextToken
      }
      budgets {
        items {
          id
          account
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign(
    $input: UpdateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    updateCampaign(input: $input, condition: $condition) {
      id
      account
      name
      channel
      vendor
      metadata
      createdAt
      updatedAt
      campaign_details {
        items {
          id
          account
          campaign_id
          date_start
          date_end
          metadata
          createdAt
          updatedAt
        }
        nextToken
      }
      budgets {
        items {
          id
          account
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign(
    $input: DeleteCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    deleteCampaign(input: $input, condition: $condition) {
      id
      account
      name
      channel
      vendor
      metadata
      createdAt
      updatedAt
      campaign_details {
        items {
          id
          account
          campaign_id
          date_start
          date_end
          metadata
          createdAt
          updatedAt
        }
        nextToken
      }
      budgets {
        items {
          id
          account
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createCampaignDetail = /* GraphQL */ `
  mutation CreateCampaignDetail(
    $input: CreateCampaignDetailInput!
    $condition: ModelCampaignDetailConditionInput
  ) {
    createCampaignDetail(input: $input, condition: $condition) {
      id
      account
      campaign_id
      date_start
      date_end
      metadata
      createdAt
      updatedAt
      campaign_months {
        items {
          id
          account
          campaign_detail_id
          month
          budget
          leads
          weight
          weight_lead
          verified_budget
          verified_leads
          metadata
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCampaignDetail = /* GraphQL */ `
  mutation UpdateCampaignDetail(
    $input: UpdateCampaignDetailInput!
    $condition: ModelCampaignDetailConditionInput
  ) {
    updateCampaignDetail(input: $input, condition: $condition) {
      id
      account
      campaign_id
      date_start
      date_end
      metadata
      createdAt
      updatedAt
      campaign_months {
        items {
          id
          account
          campaign_detail_id
          month
          budget
          leads
          weight
          weight_lead
          verified_budget
          verified_leads
          metadata
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCampaignDetail = /* GraphQL */ `
  mutation DeleteCampaignDetail(
    $input: DeleteCampaignDetailInput!
    $condition: ModelCampaignDetailConditionInput
  ) {
    deleteCampaignDetail(input: $input, condition: $condition) {
      id
      account
      campaign_id
      date_start
      date_end
      metadata
      createdAt
      updatedAt
      campaign_months {
        items {
          id
          account
          campaign_detail_id
          month
          budget
          leads
          weight
          weight_lead
          verified_budget
          verified_leads
          metadata
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createCampaignMonth = /* GraphQL */ `
  mutation CreateCampaignMonth(
    $input: CreateCampaignMonthInput!
    $condition: ModelCampaignMonthConditionInput
  ) {
    createCampaignMonth(input: $input, condition: $condition) {
      id
      account
      campaign_detail_id
      month
      budget
      leads
      weight
      weight_lead
      verified_budget
      verified_leads
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaignMonth = /* GraphQL */ `
  mutation UpdateCampaignMonth(
    $input: UpdateCampaignMonthInput!
    $condition: ModelCampaignMonthConditionInput
  ) {
    updateCampaignMonth(input: $input, condition: $condition) {
      id
      account
      campaign_detail_id
      month
      budget
      leads
      weight
      weight_lead
      verified_budget
      verified_leads
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaignMonth = /* GraphQL */ `
  mutation DeleteCampaignMonth(
    $input: DeleteCampaignMonthInput!
    $condition: ModelCampaignMonthConditionInput
  ) {
    deleteCampaignMonth(input: $input, condition: $condition) {
      id
      account
      campaign_detail_id
      month
      budget
      leads
      weight
      weight_lead
      verified_budget
      verified_leads
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const createBudgetCampaign = /* GraphQL */ `
  mutation CreateBudgetCampaign(
    $input: CreateBudgetCampaignInput!
    $condition: ModelBudgetCampaignConditionInput
  ) {
    createBudgetCampaign(input: $input, condition: $condition) {
      id
      account
      createdAt
      updatedAt
      budget {
        id
        account
        name
        active
        year
        budget
        budget_allocation
        BaselineMetricID
        lockedMonths
        createdAt
        updatedAt
        campaigns {
          nextToken
        }
      }
      campaign {
        id
        account
        name
        channel
        vendor
        metadata
        createdAt
        updatedAt
        campaign_details {
          nextToken
        }
        budgets {
          nextToken
        }
      }
    }
  }
`;
export const updateBudgetCampaign = /* GraphQL */ `
  mutation UpdateBudgetCampaign(
    $input: UpdateBudgetCampaignInput!
    $condition: ModelBudgetCampaignConditionInput
  ) {
    updateBudgetCampaign(input: $input, condition: $condition) {
      id
      account
      createdAt
      updatedAt
      budget {
        id
        account
        name
        active
        year
        budget
        budget_allocation
        BaselineMetricID
        lockedMonths
        createdAt
        updatedAt
        campaigns {
          nextToken
        }
      }
      campaign {
        id
        account
        name
        channel
        vendor
        metadata
        createdAt
        updatedAt
        campaign_details {
          nextToken
        }
        budgets {
          nextToken
        }
      }
    }
  }
`;
export const deleteBudgetCampaign = /* GraphQL */ `
  mutation DeleteBudgetCampaign(
    $input: DeleteBudgetCampaignInput!
    $condition: ModelBudgetCampaignConditionInput
  ) {
    deleteBudgetCampaign(input: $input, condition: $condition) {
      id
      account
      createdAt
      updatedAt
      budget {
        id
        account
        name
        active
        year
        budget
        budget_allocation
        BaselineMetricID
        lockedMonths
        createdAt
        updatedAt
        campaigns {
          nextToken
        }
      }
      campaign {
        id
        account
        name
        channel
        vendor
        metadata
        createdAt
        updatedAt
        campaign_details {
          nextToken
        }
        budgets {
          nextToken
        }
      }
    }
  }
`;
export const createCourseCategory = /* GraphQL */ `
  mutation CreateCourseCategory(
    $input: CreateCourseCategoryInput!
    $condition: ModelCourseCategoryConditionInput
  ) {
    createCourseCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      courses {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCourseCategory = /* GraphQL */ `
  mutation UpdateCourseCategory(
    $input: UpdateCourseCategoryInput!
    $condition: ModelCourseCategoryConditionInput
  ) {
    updateCourseCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      courses {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCourseCategory = /* GraphQL */ `
  mutation DeleteCourseCategory(
    $input: DeleteCourseCategoryInput!
    $condition: ModelCourseCategoryConditionInput
  ) {
    deleteCourseCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      courses {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      name
      slug
      short_description
      description
      featuredimage
      attachments
      createdAt
      updatedAt
      videos {
        items {
          id
          courseId
          name
          title
          description
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      categories {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      name
      slug
      short_description
      description
      featuredimage
      attachments
      createdAt
      updatedAt
      videos {
        items {
          id
          courseId
          name
          title
          description
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      categories {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      name
      slug
      short_description
      description
      featuredimage
      attachments
      createdAt
      updatedAt
      videos {
        items {
          id
          courseId
          name
          title
          description
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      categories {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createCourseVideo = /* GraphQL */ `
  mutation CreateCourseVideo(
    $input: CreateCourseVideoInput!
    $condition: ModelCourseVideoConditionInput
  ) {
    createCourseVideo(input: $input, condition: $condition) {
      id
      courseId
      name
      title
      description
      duration
      createdAt
      updatedAt
    }
  }
`;
export const updateCourseVideo = /* GraphQL */ `
  mutation UpdateCourseVideo(
    $input: UpdateCourseVideoInput!
    $condition: ModelCourseVideoConditionInput
  ) {
    updateCourseVideo(input: $input, condition: $condition) {
      id
      courseId
      name
      title
      description
      duration
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourseVideo = /* GraphQL */ `
  mutation DeleteCourseVideo(
    $input: DeleteCourseVideoInput!
    $condition: ModelCourseVideoConditionInput
  ) {
    deleteCourseVideo(input: $input, condition: $condition) {
      id
      courseId
      name
      title
      description
      duration
      createdAt
      updatedAt
    }
  }
`;
export const createCourseCategoryConnection = /* GraphQL */ `
  mutation CreateCourseCategoryConnection(
    $input: CreateCourseCategoryConnectionInput!
    $condition: ModelCourseCategoryConnectionConditionInput
  ) {
    createCourseCategoryConnection(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      courseCategory {
        id
        name
        createdAt
        updatedAt
        courses {
          nextToken
        }
      }
      course {
        id
        name
        slug
        short_description
        description
        featuredimage
        attachments
        createdAt
        updatedAt
        videos {
          nextToken
        }
        categories {
          nextToken
        }
      }
    }
  }
`;
export const updateCourseCategoryConnection = /* GraphQL */ `
  mutation UpdateCourseCategoryConnection(
    $input: UpdateCourseCategoryConnectionInput!
    $condition: ModelCourseCategoryConnectionConditionInput
  ) {
    updateCourseCategoryConnection(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      courseCategory {
        id
        name
        createdAt
        updatedAt
        courses {
          nextToken
        }
      }
      course {
        id
        name
        slug
        short_description
        description
        featuredimage
        attachments
        createdAt
        updatedAt
        videos {
          nextToken
        }
        categories {
          nextToken
        }
      }
    }
  }
`;
export const deleteCourseCategoryConnection = /* GraphQL */ `
  mutation DeleteCourseCategoryConnection(
    $input: DeleteCourseCategoryConnectionInput!
    $condition: ModelCourseCategoryConnectionConditionInput
  ) {
    deleteCourseCategoryConnection(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      courseCategory {
        id
        name
        createdAt
        updatedAt
        courses {
          nextToken
        }
      }
      course {
        id
        name
        slug
        short_description
        description
        featuredimage
        attachments
        createdAt
        updatedAt
        videos {
          nextToken
        }
        categories {
          nextToken
        }
      }
    }
  }
`;
export const createChartData = /* GraphQL */ `
  mutation CreateChartData(
    $input: CreateChartDataInput!
    $condition: ModelChartDataConditionInput
  ) {
    createChartData(input: $input, condition: $condition) {
      id
      account
      key
      metadata
      chart_type
      source
      analytics_screen
      dashboard_screen
      createdAt
      updatedAt
    }
  }
`;
export const updateChartData = /* GraphQL */ `
  mutation UpdateChartData(
    $input: UpdateChartDataInput!
    $condition: ModelChartDataConditionInput
  ) {
    updateChartData(input: $input, condition: $condition) {
      id
      account
      key
      metadata
      chart_type
      source
      analytics_screen
      dashboard_screen
      createdAt
      updatedAt
    }
  }
`;
export const deleteChartData = /* GraphQL */ `
  mutation DeleteChartData(
    $input: DeleteChartDataInput!
    $condition: ModelChartDataConditionInput
  ) {
    deleteChartData(input: $input, condition: $condition) {
      id
      account
      key
      metadata
      chart_type
      source
      analytics_screen
      dashboard_screen
      createdAt
      updatedAt
    }
  }
`;
export const createCrmSyncHistory = /* GraphQL */ `
  mutation CreateCrmSyncHistory(
    $input: CreateCrmSyncHistoryInput!
    $condition: ModelCrmSyncHistoryConditionInput
  ) {
    createCrmSyncHistory(input: $input, condition: $condition) {
      id
      account
      crm
      profile_id
      source
      status
      createdAt
      detail
      updatedAt
    }
  }
`;
export const updateCrmSyncHistory = /* GraphQL */ `
  mutation UpdateCrmSyncHistory(
    $input: UpdateCrmSyncHistoryInput!
    $condition: ModelCrmSyncHistoryConditionInput
  ) {
    updateCrmSyncHistory(input: $input, condition: $condition) {
      id
      account
      crm
      profile_id
      source
      status
      createdAt
      detail
      updatedAt
    }
  }
`;
export const deleteCrmSyncHistory = /* GraphQL */ `
  mutation DeleteCrmSyncHistory(
    $input: DeleteCrmSyncHistoryInput!
    $condition: ModelCrmSyncHistoryConditionInput
  ) {
    deleteCrmSyncHistory(input: $input, condition: $condition) {
      id
      account
      crm
      profile_id
      source
      status
      createdAt
      detail
      updatedAt
    }
  }
`;
export const createProperty = /* GraphQL */ `
  mutation CreateProperty(
    $input: CreatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    createProperty(input: $input, condition: $condition) {
      id
      propertiesPackageID
      key
      value
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateProperty = /* GraphQL */ `
  mutation UpdateProperty(
    $input: UpdatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    updateProperty(input: $input, condition: $condition) {
      id
      propertiesPackageID
      key
      value
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteProperty = /* GraphQL */ `
  mutation DeleteProperty(
    $input: DeletePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    deleteProperty(input: $input, condition: $condition) {
      id
      propertiesPackageID
      key
      value
      type
      createdAt
      updatedAt
    }
  }
`;
export const createPropertiesPackage = /* GraphQL */ `
  mutation CreatePropertiesPackage(
    $input: CreatePropertiesPackageInput!
    $condition: ModelPropertiesPackageConditionInput
  ) {
    createPropertiesPackage(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      properties {
        items {
          id
          propertiesPackageID
          key
          value
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePropertiesPackage = /* GraphQL */ `
  mutation UpdatePropertiesPackage(
    $input: UpdatePropertiesPackageInput!
    $condition: ModelPropertiesPackageConditionInput
  ) {
    updatePropertiesPackage(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      properties {
        items {
          id
          propertiesPackageID
          key
          value
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletePropertiesPackage = /* GraphQL */ `
  mutation DeletePropertiesPackage(
    $input: DeletePropertiesPackageInput!
    $condition: ModelPropertiesPackageConditionInput
  ) {
    deletePropertiesPackage(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      properties {
        items {
          id
          propertiesPackageID
          key
          value
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUserPackage = /* GraphQL */ `
  mutation CreateUserPackage(
    $input: CreateUserPackageInput!
    $condition: ModelUserPackageConditionInput
  ) {
    createUserPackage(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      packages {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUserPackage = /* GraphQL */ `
  mutation UpdateUserPackage(
    $input: UpdateUserPackageInput!
    $condition: ModelUserPackageConditionInput
  ) {
    updateUserPackage(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      packages {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUserPackage = /* GraphQL */ `
  mutation DeleteUserPackage(
    $input: DeleteUserPackageInput!
    $condition: ModelUserPackageConditionInput
  ) {
    deleteUserPackage(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      packages {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUserPackagesPropertiesPackageConnection = /* GraphQL */ `
  mutation CreateUserPackagesPropertiesPackageConnection(
    $input: CreateUserPackagesPropertiesPackageConnectionInput!
    $condition: ModelUserPackagesPropertiesPackageConnectionConditionInput
  ) {
    createUserPackagesPropertiesPackageConnection(
      input: $input
      condition: $condition
    ) {
      id
      sortOrder
      createdAt
      updatedAt
      propertiespackages {
        id
        name
        createdAt
        updatedAt
        properties {
          nextToken
        }
        users {
          nextToken
        }
        account {
          nextToken
        }
      }
      userpackages {
        id
        owner
        createdAt
        updatedAt
        packages {
          nextToken
        }
      }
    }
  }
`;
export const updateUserPackagesPropertiesPackageConnection = /* GraphQL */ `
  mutation UpdateUserPackagesPropertiesPackageConnection(
    $input: UpdateUserPackagesPropertiesPackageConnectionInput!
    $condition: ModelUserPackagesPropertiesPackageConnectionConditionInput
  ) {
    updateUserPackagesPropertiesPackageConnection(
      input: $input
      condition: $condition
    ) {
      id
      sortOrder
      createdAt
      updatedAt
      propertiespackages {
        id
        name
        createdAt
        updatedAt
        properties {
          nextToken
        }
        users {
          nextToken
        }
        account {
          nextToken
        }
      }
      userpackages {
        id
        owner
        createdAt
        updatedAt
        packages {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserPackagesPropertiesPackageConnection = /* GraphQL */ `
  mutation DeleteUserPackagesPropertiesPackageConnection(
    $input: DeleteUserPackagesPropertiesPackageConnectionInput!
    $condition: ModelUserPackagesPropertiesPackageConnectionConditionInput
  ) {
    deleteUserPackagesPropertiesPackageConnection(
      input: $input
      condition: $condition
    ) {
      id
      sortOrder
      createdAt
      updatedAt
      propertiespackages {
        id
        name
        createdAt
        updatedAt
        properties {
          nextToken
        }
        users {
          nextToken
        }
        account {
          nextToken
        }
      }
      userpackages {
        id
        owner
        createdAt
        updatedAt
        packages {
          nextToken
        }
      }
    }
  }
`;
export const createAccountPackage = /* GraphQL */ `
  mutation CreateAccountPackage(
    $input: CreateAccountPackageInput!
    $condition: ModelAccountPackageConditionInput
  ) {
    createAccountPackage(input: $input, condition: $condition) {
      id
      account
      createdAt
      updatedAt
      packages {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAccountPackage = /* GraphQL */ `
  mutation UpdateAccountPackage(
    $input: UpdateAccountPackageInput!
    $condition: ModelAccountPackageConditionInput
  ) {
    updateAccountPackage(input: $input, condition: $condition) {
      id
      account
      createdAt
      updatedAt
      packages {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteAccountPackage = /* GraphQL */ `
  mutation DeleteAccountPackage(
    $input: DeleteAccountPackageInput!
    $condition: ModelAccountPackageConditionInput
  ) {
    deleteAccountPackage(input: $input, condition: $condition) {
      id
      account
      createdAt
      updatedAt
      packages {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAccountPackagesPropertiesPackageConnection = /* GraphQL */ `
  mutation CreateAccountPackagesPropertiesPackageConnection(
    $input: CreateAccountPackagesPropertiesPackageConnectionInput!
    $condition: ModelAccountPackagesPropertiesPackageConnectionConditionInput
  ) {
    createAccountPackagesPropertiesPackageConnection(
      input: $input
      condition: $condition
    ) {
      id
      sortOrder
      createdAt
      updatedAt
      propertiespackages {
        id
        name
        createdAt
        updatedAt
        properties {
          nextToken
        }
        users {
          nextToken
        }
        account {
          nextToken
        }
      }
      accountpackages {
        id
        account
        createdAt
        updatedAt
        packages {
          nextToken
        }
      }
    }
  }
`;
export const updateAccountPackagesPropertiesPackageConnection = /* GraphQL */ `
  mutation UpdateAccountPackagesPropertiesPackageConnection(
    $input: UpdateAccountPackagesPropertiesPackageConnectionInput!
    $condition: ModelAccountPackagesPropertiesPackageConnectionConditionInput
  ) {
    updateAccountPackagesPropertiesPackageConnection(
      input: $input
      condition: $condition
    ) {
      id
      sortOrder
      createdAt
      updatedAt
      propertiespackages {
        id
        name
        createdAt
        updatedAt
        properties {
          nextToken
        }
        users {
          nextToken
        }
        account {
          nextToken
        }
      }
      accountpackages {
        id
        account
        createdAt
        updatedAt
        packages {
          nextToken
        }
      }
    }
  }
`;
export const deleteAccountPackagesPropertiesPackageConnection = /* GraphQL */ `
  mutation DeleteAccountPackagesPropertiesPackageConnection(
    $input: DeleteAccountPackagesPropertiesPackageConnectionInput!
    $condition: ModelAccountPackagesPropertiesPackageConnectionConditionInput
  ) {
    deleteAccountPackagesPropertiesPackageConnection(
      input: $input
      condition: $condition
    ) {
      id
      sortOrder
      createdAt
      updatedAt
      propertiespackages {
        id
        name
        createdAt
        updatedAt
        properties {
          nextToken
        }
        users {
          nextToken
        }
        account {
          nextToken
        }
      }
      accountpackages {
        id
        account
        createdAt
        updatedAt
        packages {
          nextToken
        }
      }
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      name
      description
      bulletPoints
      packageID
      stripePriceID
      priceValues
      listed
      order
      createdAt
      updatedAt
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      name
      description
      bulletPoints
      packageID
      stripePriceID
      priceValues
      listed
      order
      createdAt
      updatedAt
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      name
      description
      bulletPoints
      packageID
      stripePriceID
      priceValues
      listed
      order
      createdAt
      updatedAt
    }
  }
`;
export const createPositioning = /* GraphQL */ `
  mutation CreatePositioning(
    $input: CreatePositioningInput!
    $condition: ModelPositioningConditionInput
  ) {
    createPositioning(input: $input, condition: $condition) {
      id
      account
      key_differentiators
      value_proposition
      awards
      competitive {
        competitor_name
        website
      }
      offers
      createdAt
      updatedAt
    }
  }
`;
export const updatePositioning = /* GraphQL */ `
  mutation UpdatePositioning(
    $input: UpdatePositioningInput!
    $condition: ModelPositioningConditionInput
  ) {
    updatePositioning(input: $input, condition: $condition) {
      id
      account
      key_differentiators
      value_proposition
      awards
      competitive {
        competitor_name
        website
      }
      offers
      createdAt
      updatedAt
    }
  }
`;
export const deletePositioning = /* GraphQL */ `
  mutation DeletePositioning(
    $input: DeletePositioningInput!
    $condition: ModelPositioningConditionInput
  ) {
    deletePositioning(input: $input, condition: $condition) {
      id
      account
      key_differentiators
      value_proposition
      awards
      competitive {
        competitor_name
        website
      }
      offers
      createdAt
      updatedAt
    }
  }
`;
export const createTargetIdealCustomProfile = /* GraphQL */ `
  mutation CreateTargetIdealCustomProfile(
    $input: CreateTargetIdealCustomProfileInput!
    $condition: ModelTargetIdealCustomProfileConditionInput
  ) {
    createTargetIdealCustomProfile(input: $input, condition: $condition) {
      id
      account
      market_segments
      industries
      regions
      createdAt
      updatedAt
    }
  }
`;
export const updateTargetIdealCustomProfile = /* GraphQL */ `
  mutation UpdateTargetIdealCustomProfile(
    $input: UpdateTargetIdealCustomProfileInput!
    $condition: ModelTargetIdealCustomProfileConditionInput
  ) {
    updateTargetIdealCustomProfile(input: $input, condition: $condition) {
      id
      account
      market_segments
      industries
      regions
      createdAt
      updatedAt
    }
  }
`;
export const deleteTargetIdealCustomProfile = /* GraphQL */ `
  mutation DeleteTargetIdealCustomProfile(
    $input: DeleteTargetIdealCustomProfileInput!
    $condition: ModelTargetIdealCustomProfileConditionInput
  ) {
    deleteTargetIdealCustomProfile(input: $input, condition: $condition) {
      id
      account
      market_segments
      industries
      regions
      createdAt
      updatedAt
    }
  }
`;
export const createPersonas = /* GraphQL */ `
  mutation CreatePersonas(
    $input: CreatePersonasInput!
    $condition: ModelPersonasConditionInput
  ) {
    createPersonas(input: $input, condition: $condition) {
      id
      account
      personas {
        name
        person_type
        job_title
        job_functions
        job_level
        details_responsibilities
        pain_points
        pain_point_solution
        messaging_theme
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePersonas = /* GraphQL */ `
  mutation UpdatePersonas(
    $input: UpdatePersonasInput!
    $condition: ModelPersonasConditionInput
  ) {
    updatePersonas(input: $input, condition: $condition) {
      id
      account
      personas {
        name
        person_type
        job_title
        job_functions
        job_level
        details_responsibilities
        pain_points
        pain_point_solution
        messaging_theme
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePersonas = /* GraphQL */ `
  mutation DeletePersonas(
    $input: DeletePersonasInput!
    $condition: ModelPersonasConditionInput
  ) {
    deletePersonas(input: $input, condition: $condition) {
      id
      account
      personas {
        name
        person_type
        job_title
        job_functions
        job_level
        details_responsibilities
        pain_points
        pain_point_solution
        messaging_theme
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCurrentMarketActivity = /* GraphQL */ `
  mutation CreateCurrentMarketActivity(
    $input: CreateCurrentMarketActivityInput!
    $condition: ModelCurrentMarketActivityConditionInput
  ) {
    createCurrentMarketActivity(input: $input, condition: $condition) {
      id
      account
      channels
      email_marketing
      branded_keywords
      non_branded_keywords
      createdAt
      updatedAt
    }
  }
`;
export const updateCurrentMarketActivity = /* GraphQL */ `
  mutation UpdateCurrentMarketActivity(
    $input: UpdateCurrentMarketActivityInput!
    $condition: ModelCurrentMarketActivityConditionInput
  ) {
    updateCurrentMarketActivity(input: $input, condition: $condition) {
      id
      account
      channels
      email_marketing
      branded_keywords
      non_branded_keywords
      createdAt
      updatedAt
    }
  }
`;
export const deleteCurrentMarketActivity = /* GraphQL */ `
  mutation DeleteCurrentMarketActivity(
    $input: DeleteCurrentMarketActivityInput!
    $condition: ModelCurrentMarketActivityConditionInput
  ) {
    deleteCurrentMarketActivity(input: $input, condition: $condition) {
      id
      account
      channels
      email_marketing
      branded_keywords
      non_branded_keywords
      createdAt
      updatedAt
    }
  }
`;
export const createCurrentSalesMarketingTools = /* GraphQL */ `
  mutation CreateCurrentSalesMarketingTools(
    $input: CreateCurrentSalesMarketingToolsInput!
    $condition: ModelCurrentSalesMarketingToolsConditionInput
  ) {
    createCurrentSalesMarketingTools(input: $input, condition: $condition) {
      id
      account
      marketing_tools
      other
      createdAt
      updatedAt
    }
  }
`;
export const updateCurrentSalesMarketingTools = /* GraphQL */ `
  mutation UpdateCurrentSalesMarketingTools(
    $input: UpdateCurrentSalesMarketingToolsInput!
    $condition: ModelCurrentSalesMarketingToolsConditionInput
  ) {
    updateCurrentSalesMarketingTools(input: $input, condition: $condition) {
      id
      account
      marketing_tools
      other
      createdAt
      updatedAt
    }
  }
`;
export const deleteCurrentSalesMarketingTools = /* GraphQL */ `
  mutation DeleteCurrentSalesMarketingTools(
    $input: DeleteCurrentSalesMarketingToolsInput!
    $condition: ModelCurrentSalesMarketingToolsConditionInput
  ) {
    deleteCurrentSalesMarketingTools(input: $input, condition: $condition) {
      id
      account
      marketing_tools
      other
      createdAt
      updatedAt
    }
  }
`;
export const createCrmMarketingAutomationSystem = /* GraphQL */ `
  mutation CreateCrmMarketingAutomationSystem(
    $input: CreateCrmMarketingAutomationSystemInput!
    $condition: ModelCrmMarketingAutomationSystemConditionInput
  ) {
    createCrmMarketingAutomationSystem(input: $input, condition: $condition) {
      id
      account
      lead_contact_de_duplication
      lead_contact_de_duplication_process
      lead_contact_assignment
      lead_contact_assignment_process
      lead_contact_status
      lead_contact_status_process
      lead_contact_source
      lead_contact_source_process
      lead_contact_creation
      lead_contact_creation_process
      lead_contact_conversion
      lead_contact_conversion_process
      account_company_creation
      account_company_creation_process
      opportunity_deal_creation
      opportunity_deal_creation_process
      marketing_sales_sla
      marketing_sales_sla_process
      createdAt
      updatedAt
    }
  }
`;
export const updateCrmMarketingAutomationSystem = /* GraphQL */ `
  mutation UpdateCrmMarketingAutomationSystem(
    $input: UpdateCrmMarketingAutomationSystemInput!
    $condition: ModelCrmMarketingAutomationSystemConditionInput
  ) {
    updateCrmMarketingAutomationSystem(input: $input, condition: $condition) {
      id
      account
      lead_contact_de_duplication
      lead_contact_de_duplication_process
      lead_contact_assignment
      lead_contact_assignment_process
      lead_contact_status
      lead_contact_status_process
      lead_contact_source
      lead_contact_source_process
      lead_contact_creation
      lead_contact_creation_process
      lead_contact_conversion
      lead_contact_conversion_process
      account_company_creation
      account_company_creation_process
      opportunity_deal_creation
      opportunity_deal_creation_process
      marketing_sales_sla
      marketing_sales_sla_process
      createdAt
      updatedAt
    }
  }
`;
export const deleteCrmMarketingAutomationSystem = /* GraphQL */ `
  mutation DeleteCrmMarketingAutomationSystem(
    $input: DeleteCrmMarketingAutomationSystemInput!
    $condition: ModelCrmMarketingAutomationSystemConditionInput
  ) {
    deleteCrmMarketingAutomationSystem(input: $input, condition: $condition) {
      id
      account
      lead_contact_de_duplication
      lead_contact_de_duplication_process
      lead_contact_assignment
      lead_contact_assignment_process
      lead_contact_status
      lead_contact_status_process
      lead_contact_source
      lead_contact_source_process
      lead_contact_creation
      lead_contact_creation_process
      lead_contact_conversion
      lead_contact_conversion_process
      account_company_creation
      account_company_creation_process
      opportunity_deal_creation
      opportunity_deal_creation_process
      marketing_sales_sla
      marketing_sales_sla_process
      createdAt
      updatedAt
    }
  }
`;
export const createSocialChannels = /* GraphQL */ `
  mutation CreateSocialChannels(
    $input: CreateSocialChannelsInput!
    $condition: ModelSocialChannelsConditionInput
  ) {
    createSocialChannels(input: $input, condition: $condition) {
      id
      account
      facebook
      facebook_link
      instagram
      instagram_link
      linkedin
      linkedin_link
      youtube
      youtube_link
      twitter
      twitter_link
      createdAt
      updatedAt
    }
  }
`;
export const updateSocialChannels = /* GraphQL */ `
  mutation UpdateSocialChannels(
    $input: UpdateSocialChannelsInput!
    $condition: ModelSocialChannelsConditionInput
  ) {
    updateSocialChannels(input: $input, condition: $condition) {
      id
      account
      facebook
      facebook_link
      instagram
      instagram_link
      linkedin
      linkedin_link
      youtube
      youtube_link
      twitter
      twitter_link
      createdAt
      updatedAt
    }
  }
`;
export const deleteSocialChannels = /* GraphQL */ `
  mutation DeleteSocialChannels(
    $input: DeleteSocialChannelsInput!
    $condition: ModelSocialChannelsConditionInput
  ) {
    deleteSocialChannels(input: $input, condition: $condition) {
      id
      account
      facebook
      facebook_link
      instagram
      instagram_link
      linkedin
      linkedin_link
      youtube
      youtube_link
      twitter
      twitter_link
      createdAt
      updatedAt
    }
  }
`;
export const createAssetLibrary = /* GraphQL */ `
  mutation CreateAssetLibrary(
    $input: CreateAssetLibraryInput!
    $condition: ModelAssetLibraryConditionInput
  ) {
    createAssetLibrary(input: $input, condition: $condition) {
      id
      account
      name
      hosted_url
      creative_files_url
      abstract
      thumbnail
      type
      metadata
      created_date
      market_segments
      industries
      regions
      personas
      createdAt
      updatedAt
    }
  }
`;
export const updateAssetLibrary = /* GraphQL */ `
  mutation UpdateAssetLibrary(
    $input: UpdateAssetLibraryInput!
    $condition: ModelAssetLibraryConditionInput
  ) {
    updateAssetLibrary(input: $input, condition: $condition) {
      id
      account
      name
      hosted_url
      creative_files_url
      abstract
      thumbnail
      type
      metadata
      created_date
      market_segments
      industries
      regions
      personas
      createdAt
      updatedAt
    }
  }
`;
export const deleteAssetLibrary = /* GraphQL */ `
  mutation DeleteAssetLibrary(
    $input: DeleteAssetLibraryInput!
    $condition: ModelAssetLibraryConditionInput
  ) {
    deleteAssetLibrary(input: $input, condition: $condition) {
      id
      account
      name
      hosted_url
      creative_files_url
      abstract
      thumbnail
      type
      metadata
      created_date
      market_segments
      industries
      regions
      personas
      createdAt
      updatedAt
    }
  }
`;
export const createMaterializerSyncHistory = /* GraphQL */ `
  mutation CreateMaterializerSyncHistory(
    $input: CreateMaterializerSyncHistoryInput!
    $condition: ModelMaterializerSyncHistoryConditionInput
  ) {
    createMaterializerSyncHistory(input: $input, condition: $condition) {
      id
      account
      source
      status
      createdAt
      detail
      updatedAt
    }
  }
`;
export const updateMaterializerSyncHistory = /* GraphQL */ `
  mutation UpdateMaterializerSyncHistory(
    $input: UpdateMaterializerSyncHistoryInput!
    $condition: ModelMaterializerSyncHistoryConditionInput
  ) {
    updateMaterializerSyncHistory(input: $input, condition: $condition) {
      id
      account
      source
      status
      createdAt
      detail
      updatedAt
    }
  }
`;
export const deleteMaterializerSyncHistory = /* GraphQL */ `
  mutation DeleteMaterializerSyncHistory(
    $input: DeleteMaterializerSyncHistoryInput!
    $condition: ModelMaterializerSyncHistoryConditionInput
  ) {
    deleteMaterializerSyncHistory(input: $input, condition: $condition) {
      id
      account
      source
      status
      createdAt
      detail
      updatedAt
    }
  }
`;
export const createSDRCapacityCalculator = /* GraphQL */ `
  mutation CreateSDRCapacityCalculator(
    $input: CreateSDRCapacityCalculatorInput!
    $condition: ModelSDRCapacityCalculatorConditionInput
  ) {
    createSDRCapacityCalculator(input: $input, condition: $condition) {
      id
      account
      totalWeeksInPeriod
      targetNoOfCalls
      dailyCallCApacity
      estimatedConnectRate
      connectToMarketingQualifiedLeadRate
      marketingQualifiedLeadToShowRate
      fiscalYearID
      baselineMetricID
      stageID
      metadata
      createdAt
      updatedAt
      fiscalYear {
        id
        account
        year
        budget
        startMonth
        createdAt
        updatedAt
        baselineMetrics {
          nextToken
        }
      }
      baselineMetric {
        id
        fiscalYearId
        name
        goal
        avgDealSize
        mrktSourcePercent
        updatedBy
        account
        mrkt_year_won_leads
        sales_year_won_leads
        budget_allocation
        mrkt_qtr_won_leads
        sales_qtr_won_leads
        mrkt_qtr_budget_allocation
        sales_qtr_budget_allocation
        segment_type
        createdAt
        updatedAt
        funnelConfigs {
          nextToken
        }
      }
      stage {
        id
        funnelConfigID
        order
        name
        conversion
        count
        account
        salesAndMarketingAlignmentID
        createdAt
        updatedAt
        salesAndMarketingAlignment {
          id
          account
          name
          disabled
          isDefault
          isLast
          showPipelineCoverage
          order
          howitcomes
          thingstokeepinmind
          definition
          stageType
          internalStage
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateSDRCapacityCalculator = /* GraphQL */ `
  mutation UpdateSDRCapacityCalculator(
    $input: UpdateSDRCapacityCalculatorInput!
    $condition: ModelSDRCapacityCalculatorConditionInput
  ) {
    updateSDRCapacityCalculator(input: $input, condition: $condition) {
      id
      account
      totalWeeksInPeriod
      targetNoOfCalls
      dailyCallCApacity
      estimatedConnectRate
      connectToMarketingQualifiedLeadRate
      marketingQualifiedLeadToShowRate
      fiscalYearID
      baselineMetricID
      stageID
      metadata
      createdAt
      updatedAt
      fiscalYear {
        id
        account
        year
        budget
        startMonth
        createdAt
        updatedAt
        baselineMetrics {
          nextToken
        }
      }
      baselineMetric {
        id
        fiscalYearId
        name
        goal
        avgDealSize
        mrktSourcePercent
        updatedBy
        account
        mrkt_year_won_leads
        sales_year_won_leads
        budget_allocation
        mrkt_qtr_won_leads
        sales_qtr_won_leads
        mrkt_qtr_budget_allocation
        sales_qtr_budget_allocation
        segment_type
        createdAt
        updatedAt
        funnelConfigs {
          nextToken
        }
      }
      stage {
        id
        funnelConfigID
        order
        name
        conversion
        count
        account
        salesAndMarketingAlignmentID
        createdAt
        updatedAt
        salesAndMarketingAlignment {
          id
          account
          name
          disabled
          isDefault
          isLast
          showPipelineCoverage
          order
          howitcomes
          thingstokeepinmind
          definition
          stageType
          internalStage
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteSDRCapacityCalculator = /* GraphQL */ `
  mutation DeleteSDRCapacityCalculator(
    $input: DeleteSDRCapacityCalculatorInput!
    $condition: ModelSDRCapacityCalculatorConditionInput
  ) {
    deleteSDRCapacityCalculator(input: $input, condition: $condition) {
      id
      account
      totalWeeksInPeriod
      targetNoOfCalls
      dailyCallCApacity
      estimatedConnectRate
      connectToMarketingQualifiedLeadRate
      marketingQualifiedLeadToShowRate
      fiscalYearID
      baselineMetricID
      stageID
      metadata
      createdAt
      updatedAt
      fiscalYear {
        id
        account
        year
        budget
        startMonth
        createdAt
        updatedAt
        baselineMetrics {
          nextToken
        }
      }
      baselineMetric {
        id
        fiscalYearId
        name
        goal
        avgDealSize
        mrktSourcePercent
        updatedBy
        account
        mrkt_year_won_leads
        sales_year_won_leads
        budget_allocation
        mrkt_qtr_won_leads
        sales_qtr_won_leads
        mrkt_qtr_budget_allocation
        sales_qtr_budget_allocation
        segment_type
        createdAt
        updatedAt
        funnelConfigs {
          nextToken
        }
      }
      stage {
        id
        funnelConfigID
        order
        name
        conversion
        count
        account
        salesAndMarketingAlignmentID
        createdAt
        updatedAt
        salesAndMarketingAlignment {
          id
          account
          name
          disabled
          isDefault
          isLast
          showPipelineCoverage
          order
          howitcomes
          thingstokeepinmind
          definition
          stageType
          internalStage
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createSDRCompensationModel = /* GraphQL */ `
  mutation CreateSDRCompensationModel(
    $input: CreateSDRCompensationModelInput!
    $condition: ModelSDRCompensationModelConditionInput
  ) {
    createSDRCompensationModel(input: $input, condition: $condition) {
      id
      account
      name
      OTE
      base_salary
      model_type
      variableCompensation
      SDRCompensationModelStageMetric
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const updateSDRCompensationModel = /* GraphQL */ `
  mutation UpdateSDRCompensationModel(
    $input: UpdateSDRCompensationModelInput!
    $condition: ModelSDRCompensationModelConditionInput
  ) {
    updateSDRCompensationModel(input: $input, condition: $condition) {
      id
      account
      name
      OTE
      base_salary
      model_type
      variableCompensation
      SDRCompensationModelStageMetric
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const deleteSDRCompensationModel = /* GraphQL */ `
  mutation DeleteSDRCompensationModel(
    $input: DeleteSDRCompensationModelInput!
    $condition: ModelSDRCompensationModelConditionInput
  ) {
    deleteSDRCompensationModel(input: $input, condition: $condition) {
      id
      account
      name
      OTE
      base_salary
      model_type
      variableCompensation
      SDRCompensationModelStageMetric
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const createSDRMeetingAndOpportunityModel = /* GraphQL */ `
  mutation CreateSDRMeetingAndOpportunityModel(
    $input: CreateSDRMeetingAndOpportunityModelInput!
    $condition: ModelSDRMeetingAndOpportunityModelConditionInput
  ) {
    createSDRMeetingAndOpportunityModel(input: $input, condition: $condition) {
      id
      account
      hubOwnerId
      compensationModelID
      metadata
      createdAt
      updatedAt
      compensationModel {
        id
        account
        name
        OTE
        base_salary
        model_type
        variableCompensation
        SDRCompensationModelStageMetric
        metadata
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSDRMeetingAndOpportunityModel = /* GraphQL */ `
  mutation UpdateSDRMeetingAndOpportunityModel(
    $input: UpdateSDRMeetingAndOpportunityModelInput!
    $condition: ModelSDRMeetingAndOpportunityModelConditionInput
  ) {
    updateSDRMeetingAndOpportunityModel(input: $input, condition: $condition) {
      id
      account
      hubOwnerId
      compensationModelID
      metadata
      createdAt
      updatedAt
      compensationModel {
        id
        account
        name
        OTE
        base_salary
        model_type
        variableCompensation
        SDRCompensationModelStageMetric
        metadata
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSDRMeetingAndOpportunityModel = /* GraphQL */ `
  mutation DeleteSDRMeetingAndOpportunityModel(
    $input: DeleteSDRMeetingAndOpportunityModelInput!
    $condition: ModelSDRMeetingAndOpportunityModelConditionInput
  ) {
    deleteSDRMeetingAndOpportunityModel(input: $input, condition: $condition) {
      id
      account
      hubOwnerId
      compensationModelID
      metadata
      createdAt
      updatedAt
      compensationModel {
        id
        account
        name
        OTE
        base_salary
        model_type
        variableCompensation
        SDRCompensationModelStageMetric
        metadata
        createdAt
        updatedAt
      }
    }
  }
`;
export const createDashboardData = /* GraphQL */ `
  mutation CreateDashboardData(
    $input: CreateDashboardDataInput!
    $condition: ModeldashboardDataConditionInput
  ) {
    createDashboardData(input: $input, condition: $condition) {
      id
      dashboardName
      dashboardID
      createdAt
      updatedAt
    }
  }
`;
export const updateDashboardData = /* GraphQL */ `
  mutation UpdateDashboardData(
    $input: UpdateDashboardDataInput!
    $condition: ModeldashboardDataConditionInput
  ) {
    updateDashboardData(input: $input, condition: $condition) {
      id
      dashboardName
      dashboardID
      createdAt
      updatedAt
    }
  }
`;
export const deleteDashboardData = /* GraphQL */ `
  mutation DeleteDashboardData(
    $input: DeleteDashboardDataInput!
    $condition: ModeldashboardDataConditionInput
  ) {
    deleteDashboardData(input: $input, condition: $condition) {
      id
      dashboardName
      dashboardID
      createdAt
      updatedAt
    }
  }
`;
