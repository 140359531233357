import { Button, Col, Divider, Row, Skeleton, Typography } from 'antd';
import React from 'react';
import Free from "../../assets/Images/freePlan.svg";
import Planning from "../../assets/Images/planning.svg";
import Growth from "../../assets/Images/growth.svg";
import { useHistory } from 'react-router-dom';
import { TrackClickFunction } from 'commons/HeapTrack/TrackClickFunction';

function AccountInfo({accountDetails,isLoading, isContent}) {
    
    let pricePlan = {}
    if(accountDetails?.plan?.priceValues){
      let prices = JSON.parse(accountDetails?.plan?.priceValues)
      pricePlan = prices?.[Object.keys(prices)?.[0]]
    }else{
        pricePlan.value = 0
        pricePlan.billing_period = 'month'
    }
    const history = useHistory()
  return(
    <div
    className="float-container panel-design"
    style={{ height: "100%", padding: 20, background: "#fff",borderRadius:7}}
  > 
  {isLoading ? <Skeleton active /> : 
   
  <> 
        <div style={{display: 'flex' , justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
          <Typography.Title level={5} style={{marginBottom: 0, color:'#1D2939'}}>{accountDetails?.company}</Typography.Title>
          <Typography.Text style={{color:'#7F56D9', fontWeight:500}}>{accountDetails?.url}</Typography.Text>
        </div>
        <Divider style={{margin: '15px 0'}} />
        
        <Row align='space-between'>
        <Col span={14}
          xs={24}
          sm={24}
          md={24}
          lg={14} >
            <Row justify='space-around'>
                {/* <Col span={10}>
                  <img alt="" src={accountDetails?.plan?.name === "Growth" ? Growth : accountDetails?.plan?.name === "Planning" ? Planning :  Free} style={{marginRight: 20, width: '100%', height: '80px'}} />
                </Col> */}
                <Col span={24} style={{display:"flex", paddingLeft: 10 }}>
                    <div style={{display:"flex", justifyContent:"center", flexDirection:"column", width:"fit-content" }}>
                    <Typography.Text  type="secondary" style={{color:'#98A2B3', fontWeight:'500' ,marginBottom:'5px'}}> My Plan</Typography.Text>
                    <Typography.Text style={{fontSize:20, color:'#7F56D9', padding:0, fontWeight:'700', marginBottom:'5px'}} >{accountDetails?.plan?.name ? accountDetails?.plan?.name : "Free"}</Typography.Text>
                    <Typography.Text style={{color:'rgb(236, 74, 10)', fontWeight:'500'}}>${pricePlan?.value ? pricePlan?.value : 0 }/{pricePlan?.billing_period ? pricePlan?.billing_period : "month"}</Typography.Text>
                    </div>

                </Col>

            </Row>
        </Col>
        <Col span={8} 
        sm={24}
        xs={24}
        md={24}
        lg={8}
        style={{display:"flex", alignContent:"center", justifyContent:"flex-end" }}>
            <Row style={{display:"flex", alignContent:"center", justifyContent:"center" }}>
            <Button type='primary' onClick={() => {
              TrackClickFunction('demand-gen-manage-plan-button');
              if(isContent){
                history.push("/settings/content")
              }else{
              history.push("/settings")
              }
            }} size='small' className="primaryButton" style={{width:'130px', fontSize:"14px"}}><strong>Manage Plan</strong></Button>
            </Row>
        </Col>

        </Row>
</>
}
  </div>
  )

  ;
}

export default AccountInfo;
