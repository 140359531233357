/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row, Tabs, Tooltip,List, Typography, Input, Layout, Grid, Skeleton, Tag, Menu, Dropdown, message } from 'antd'
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import invite from "assets/Images/invite.svg";
import ResetIcon from "assets/Images/reset_icon.svg";
import verticalTripleDotImg from "../../assets/Images/vertical_triple_dot.svg";
import { API } from 'aws-amplify';
import sdk from 'sdk/Accounts';
import { useSelector } from 'react-redux';
import {TrackClickFunction} from '../../commons/HeapTrack/TrackClickFunction'



function CompanyUsers({ onInvite, acct, approveRequest, deleteRequest, fetchAccountDetails, loading }) {
  const [Users, setUsers] = useState([])
  const [userRequests, setuserRequests] = useState([]);
  const [searchText, setsearchText] = useState("")
  const [searchTextReq, setsearchTextReq] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const { useBreakpoint } = Grid;
  const { groups ,viewType } = useSelector(
    (state) => state.loginReducer
  );
  const screens = useBreakpoint();
  const get_attribute = (user, key) => {
    let result = user.filter((attribute) => attribute.Name === key);
    if (result.length > 0) {
      return result[0]["Value"];
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (searchTextReq?.length === 0)
      setUserRequestData()
    else {
      let requestFiltered = []
      requestFiltered = userRequests.filter(item => String(item.owner).includes(searchTextReq))
      setuserRequests(requestFiltered)
    }
  }, [searchTextReq])
  useEffect(() => {
    if (searchText?.length === 0)
      setUsersData()
    else {
      let userFilterd = Users?.filter(item => String(item?.UserFullName).toLowerCase().includes(searchText) || String(item?.Useremail).toLowerCase().includes(searchText))
      setUsers(userFilterd)
    }
  }, [searchText])


  const setUsersData = () => {
    let userdata = acct?.users?.map(item => JSON.parse(item)).map(item => ({ ...item, Useremail: `${get_attribute(item?.Attributes, "email")}`, UserFullName: `${get_attribute(item?.Attributes, "given_name")} ${get_attribute(item?.Attributes, "family_name")}`, UserPosition: `${get_attribute(item?.Attributes, "custom:position")}` }))
    setUsers(userdata)
  }
  const setUserRequestData = () => {
    let requests = acct?.requests?.items
    requests ? setuserRequests(requests) : setuserRequests([])
  }

  useEffect(() => {
    setUsersData()
    setUserRequestData();
  }, [acct])
  
  // User position change Make Admin or Remove Admin 
  const onPositionChange = async (user) => {
    setIsLoading(true)
    let inputData = {
      email: user.Useremail,
      accountId : acct.id
    }
    if (user?.UserPosition !== "admin") {
      let path = `/administration/makeadmin`
      await API.post("exploricsREST", path, { body: inputData }).then((res) => {
        if (res?.error) {
          message.error(res?.error)
        }
        else {
          fetchAccountDetails()
          message.success(res?.message)
          setIsLoading(false)
        }
        })
          .catch((err) => {
            console.log("Eror => ", err)
            setIsLoading(false)
          message.error("Something went wrong!")

          })
    }
    else {
      setIsLoading(true)
      let path = `/administration/removeadmin`
      await API.post("exploricsREST", path, { body: inputData }).then((res) => {
        setIsLoading(false)
          if (res?.error) {
          message.error(res?.error)
          }
          else {
          fetchAccountDetails()
          message.success(res?.message)
          }
          })
        .catch((err) => {
            setIsLoading(false)
          message.error("Something went wrong!")
          })
    }
  }


  //Delete User 
  const handleDeleteUser = async (user) => {
    setIsLoading(true)
    let inputData = {
        id : user.Useremail
        }
    let result = await sdk.DeleteUser(inputData)
    if (result.error) {
      message.error(result.error, 5);
      setIsLoading(false)
    } else {
      await fetchAccountDetails()
      message.success("User Deleted Successfully");
      setIsLoading(false)

    }
    }

    return (
        <>
             <Row style={{paddingTop:20}} >
             <Col span={16} lg={16} md={16} sm={24} xs={24}>
                  <Tabs tabPosition={screens.xs === true ? 'top' : 'left'} style={{width:"100%"}} className='general-tab'>
                <Tabs.TabPane tab="Team"  key="1" style={{height:"50vh", overflowY:"scroll", padding : "0px 24px"}}> 
                <Layout.Header style={{position:"sticky", zIndex:1, background:"#fff",top:0,paddingLeft:0, paddingRight:0}}>
                <Row>
                  <Col span={22}>
                <Input value={searchText} onChange={(event)=>setsearchText(event.target.value)} size='large' placeholder='Search member' className="general-input"/>
                </Col>
                <Col span={2} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <img src={ResetIcon} alt="" style={{cursor:"pointer", paddingTop:5, height:30}} title='Refresh' onClick={() => fetchAccountDetails()}/>
                </Col>
                </Row>
                </Layout.Header>

                {loading || isLoading ? <Skeleton style={{paddingRight:20}} active paragraph={{rows:4}}/> : 
                <List
                        className="reqList"
                        itemLayout="horizontal"
                        dataSource={Users}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              title={<Typography.Text  style={{fontSize:16, color:"#1D2939", fontWeight:500}}>{item.Useremail}</Typography.Text>}
                              description={
                                <>
                                  {item.UserFullName?.length > 1 ? item.UserFullName : "-"}

                                  {item?.UserPosition === "admin" && <Tag color='#E9F2FF' className='admin-tag'>
                                    <span style={{color : "#0F61DB"}}>Admin</span></Tag>}
                              </>}
                            />
                          { groups?.length > 0 &&
                               (groups?.[1] === "companyadmin" || groups?.[2] === "admin") && (viewType !=="companycontributor") && <div className='admin-operation'>
                              <Dropdown
                                trigger={["click"]}
                                placement="bottomRight"
                                overlay={
                                  <Menu style={{ borderRadius: "7px" }}>
                                    <Menu.Item
                                      key="permision"
                                      onClick={() =>onPositionChange(item)}
                                    >
                                    {item?.UserPosition === "admin"  ?  "Remove Admin" : "Make Admin"}
                                     
                                    </Menu.Item>
                                      <Menu.Item
                                      key="delete-stage"
                                      onClick={() => handleDeleteUser(item)}
                                      >
                                        <Typography.Text  style={{fontSize:15}}>Delete</Typography.Text>
                                      </Menu.Item>
                                  </Menu>
                                }
                              >
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    src={verticalTripleDotImg}
                                    alt="dropdown"
                                    title="Click to open dropdown"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </Dropdown>
                            </div>}

                          </List.Item>
                        )}
                      />

                        }

                </Tabs.TabPane>
                  <Tabs.TabPane tab="Requests"  key="2"  style={{height:"50vh", overflowY:"scroll"}}>
                  <Layout.Header style={{position:"sticky", zIndex:1, background:"#fff",top:0,paddingLeft:0, paddingRight:0}}>
                <Row>
                  <Col span={22}>
                <Input value={searchTextReq} onChange={(event)=>setsearchTextReq(event.target.value)} size='large' placeholder='Search email'/>
                </Col>
                <Col span={2} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <img src={ResetIcon} alt="" style={{cursor:"pointer", paddingTop:5, height:30}} title='Refresh' onClick={() => fetchAccountDetails()}/>
                </Col>
                </Row>
                </Layout.Header>
                {loading ? <Skeleton style={{paddingRight:20}} active paragraph={{rows:4}}/> : 

                  <List
                        className="reqList"
                        itemLayout="horizontal"
                        dataSource={userRequests}
                        renderItem={(item) => (
                          <List.Item
                            actions={[
                              <Tooltip title={`Approve ${item.owner}`}>
                               { groups?.length > 0 &&
                               (groups?.[1] === "companyadmin" || groups?.[2] === "admin") ?  (<Button
                                  size="large"
                                  type="primary"
                                  onClick={() => approveRequest(item.id)}
                                >
                                  Approve
                                </Button>) :( <Button
                                  size="large"
                                  type="primary"
                                  disabled
                                >
                                  Approve
                                </Button>)}
                              </Tooltip>,
                              <Tooltip title={`Deny ${item.owner}`}>
                               { groups?.length > 0 &&
                               (groups?.[1] === "companyadmin" || groups?.[2] === "admin") ? ( <Button
                                  type="secondary"
                                  size="large"
                                  //type="link"
                                  onClick={() => deleteRequest(item.id)}
                                >
                                  Deny
                                </Button>) : ( <Button
                                  type="secondary"
                                    size="large"
                                    disabled
                                  //type="link"
                                >
                                  Deny
                                </Button>)}
                              </Tooltip>,
                            ]}
                          >
                            <List.Item.Meta
                              title={<a style={{fontSize:16}} href={`mailto:${item.owner}`}>{item.owner}</a>}
                              description={<Moment fromNow>{item.createdAt}</Moment>}
                            />
                          </List.Item>
                        )}
                      />
                  }
                  </Tabs.TabPane>


                  </Tabs>
                  </Col>
                      
                  <Col span={8} lg={8} md={8} sm={24} xs={24}>
                    <div className="float-chil" style={{ width: "100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:'center'}}>
                      <div className="dashboard-step-header" style={{ paddingTop: 20, textAlign:"center"}}>
                        <img
                          className="inviteIMG"
                          src={invite}
                          alt=""
                          style={{ height: 200, paddingLeft: 14}}
                        />
                      </div>
                      <div
                        className="dashboard-card-header"
                        style={{
                          paddingTop: 25,
                          paddingBottom: 30,
                          paddingLeft: 10,
                          fontSize: 20,
                          textAlign:"center"
                        }}
                      >
                        Invite your team members
                      </div>
                      <Button
                        
                        className="invite-btn-empty-width secondaryButton"
                        type="primary"
                        onClick={() => {
                          TrackClickFunction('demand-gen-send-invitation-button');
                          onInvite()
                        }}
                      >
                        Invite Now
                      </Button>
                    </div>
                </Col>
               
                </Row>

        </>
    )
}

export default CompanyUsers
