import { Button, Col, Row, Spin, Typography, Tooltip, Skeleton } from "antd";
import FunnelCharts from "commons/FunnelChart";
import RevenueTextField from "commons/RevenueTextField";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  getFunnelStagesList,
  updatePlotDataList,
  updateFunnelStages,
  createFunnelStages,
  setPipelineCoverage,
} from "../../store/actions/revenueAction";
import { convertReverseArray } from "utility/context/CommonMethods";
import { getFunnelCountsValue } from "utility/context/CommonMethods";
import { LoadingOutlined } from "@ant-design/icons";
import saveConversationIcon from "./../../assets/Images/save_conversation.svg";
import resetConversationIcon from "./../../assets/Images/reset_conversation.svg";
import pdfIcon from "./../../assets/Images/pdf_icon.svg";
import { TourFunnelData } from "utility/Constants";
import _ from "lodash";

const { Title } = Typography;

class FunnelConversionRates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rate: "",
      index: "",
      chartData: [],
      conversationDiffrence: false,
      funnelStagesChanged: [],
    };
  }

  componentDidMount() {
    const { funnelStages } = this.props;
    if (funnelStages.length > 0) {
      let sortingFunnel = funnelStages.sort(this.sortingOrder);
      this.setState({ funnelStagesChanged: sortingFunnel });
      let chartData = this.getChartData(sortingFunnel);
      this.getTotalPipelineCoverage(chartData);
      this.setState({
        chartData,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.funnelStages) !==
        JSON.stringify(this.props.funnelStages) ||
      prevProps.marketingSourcedCount !== this.props.marketingSourcedCount ||
      prevProps.planningPeriod !== this.props.planningPeriod
    ) {
      const { funnelStages } = this.props;
      let sortingFunnel = funnelStages.sort(this.sortingOrder);
      let newChartData = this.getChartData(sortingFunnel);
      this.getTotalPipelineCoverage(newChartData);
      this.setState({
        chartData: newChartData,
        funnelStagesChanged: sortingFunnel
      });
    }
  }

  sortingOrder = (a, b) => {
    if (a.salesAndMarketingAlignment?.order < b.salesAndMarketingAlignment?.order) {
      return -1;
    }
    if (a.salesAndMarketingAlignment?.order >b.salesAndMarketingAlignment?.order) {
      return 1;
    }
    return 0;
  };

  // handleOpenConfigPopup = () => {
  //   this.setState({
  //     configPopUp: !this.state.configPopUp,
  //   });
  // };

  handleOnChange = (name, value, index) => {
    const { funnelStages } = this.props;
    let sortingFunnel = funnelStages.sort(this.sortingOrder);
    const oldChartData = this.getChartData(sortingFunnel)
    this.props.isFunnelChanges(true);

    const updateFunnelStages = _.cloneDeep(this.state.funnelStagesChanged);
    const updateFunnel = updateFunnelStages.find((f) => f.name === name);
    updateFunnel.conversion = value;
    const updatedChartData = this.getChartData(updateFunnelStages);
    this.setState({ chartData: updatedChartData,funnelStagesChanged: updateFunnelStages });

    let resultDifferenceConversation = updatedChartData.filter(
      (o1) =>
        !oldChartData.some(
          (o2) => o1.percentageValue === o2.percentageValue
        )
    );
    if (resultDifferenceConversation.length >= 0) {
      this.setState({
        conversationDiffrence: true,
      });
    } else {
      this.setState({
        conversationDiffrence: false,
      });
    }
    let totalPipeLineCoverage = 1;
    updatedChartData.forEach((item, i) => {
      totalPipeLineCoverage *= item?.showPipelineCoverage
        ? Number(Number(item?.percentageValue / 100).toFixed(2))
        : 1;
    });
    totalPipeLineCoverage = Number(
      parseFloat(1 / totalPipeLineCoverage).toFixed(2)
    );
    this.props.setPipelineCoverage(totalPipeLineCoverage);

    if (!updatedChartData[index].id) {
      const { funnelStages } = this.props;
      let newStages = [...funnelStages];
      newStages[index].conversion = value;
      this.props.updatePlotDataList(newStages);
    }
  };

  handleRestFunnelStage = () => {
    const { funnelStages } = this.props;
    let sortingFunnel = funnelStages.sort(this.sortingOrder);
    let oldChartData = [...this.getChartData(sortingFunnel)];
    this.getTotalPipelineCoverage(oldChartData);
    this.setState({
      chartData: oldChartData,
      funnelStagesChanged: sortingFunnel
    });
    this.setState({
      conversationDiffrence: false,
    });
  };

  getTotalPipelineCoverage = (data) => {
    let totalPipeLineCoverage = 1;
    if (data && data?.length > 0) {
      data?.forEach((item) => {
        totalPipeLineCoverage *= item?.showPipelineCoverage
          ? Number(Number(item?.percentageValue / 100).toFixed(2))
          : 1;
      });
      totalPipeLineCoverage = Number(
        parseFloat(1 / totalPipeLineCoverage).toFixed(2)
      );
      this.props.setPipelineCoverage(totalPipeLineCoverage);
    }
  };

  handleSaveFunnelStage = (index) => {
    if (this.state.conversationDiffrence) {
      this.props.isFunnelChanges(false);
      const { funnelStages, baseLineData } = this.props;
      const { chartData } = this.state;
      let newPlotData = [...funnelStages];

      // eslint-disable-next-line
      newPlotData.map((newPlotDataVal, plotIndex) => {
        if (newPlotDataVal && newPlotDataVal.id) {
          let requestData = {
            id: newPlotDataVal.id,
            conversion: chartData[plotIndex].percentageValue,
            order: newPlotDataVal.order,
            funnelConfigID: baseLineData?.funnelConfigs?.items[0].id,
          };

          this.props.updateFunnelStage(requestData).then((response) => {
            let index = newPlotData.findIndex(
              (item) => item.id === response.id
            );
            newPlotData.splice(index, 1, response);
            let newChartData = [...chartData];
            newChartData[index].percentageValue = response.conversion;
            this.props.updatePlotDataList(newPlotData);
            this.setState({ chartData: newChartData });
          });
        }
      });
      this.setState({
        conversationDiffrence: false,
      });
    }
  };

  getChartData = (funnelStages) => {
    const { marketingSourcedCount } = this.props;
    let newChartData = [];
    let newConvertData = _.cloneDeep(convertReverseArray(funnelStages));
    newConvertData.map((item, index) => {
      let { name, conversion, id } = item;
      let newValue =
      index === 0
      ? !isFinite(marketingSourcedCount)
            ? 0
            : Number.parseInt(marketingSourcedCount)
          :index === 1 ?  Number.parseInt(
              getFunnelCountsValue(newChartData[index - 1]?.value,item?.conversion,true)
          ) : Number.parseInt(
              getFunnelCountsValue(newChartData[index - 1]?.value,item?.conversion));
      let setWidth =
        index === newConvertData.length - 1 ||
        index === newConvertData.length - 2
          ? 100
          : 70;
      let obj = {
        id: id,
        text: item.stageMapping?.name ?? name,
        percentageValue: conversion,
        value: newValue,
        width: setWidth,
        disabled: item.disabled,
        showPipelineCoverage: item?.showPipelineCoverage,
      };
      newChartData.push({ ...obj });
      return 0;
    });
    return [...convertReverseArray(newChartData)];
  };

  render() {
    const { loading, chartData, conversationDiffrence } =
      this.state;
    const {
      funnelStages,
      marketingSourcedCount,
      funnelConfiguration,
      baseLineData,
      userPermission,
      isTourOpen,
      totalPipeLineCoverage,
    } = this.props;
    let sortingFunnel =
      funnelStages && funnelStages.length > 0
        ? funnelStages.sort(this.sortingOrder)
        : [];
    let overallConversionRate = 0;
    if (chartData && chartData?.length > 0) {
      overallConversionRate = Number(
        (chartData[chartData?.length - 1].value * 100) / chartData[0].value
      ).toFixed(2);
    }

    const filteredChartData = chartData?.filter((item) => !item.disabled) ?? [];
    return (
      <Fragment>
        <div
          style={{
            background: "#fff",
            padding:'20px 15px',
            borderTopLeftRadius: 7,
            borderTopRightRadius: 7,
          }}
          data-tour="tour_funnelConfig"
          className="panel-design"
        >
          <Row
            gutter={[20, 20]}
            style={{
              padding:'0 0 10px 0',
              margin: 0,
              borderBottom: "2px solid #E9F2FF",
            }}
          >
            {/* <Col xs={24} md={18} lg={16} style={{ paddingTop: 20 }}>
              <span className="annual-goal-title">
                <strong>Establish Your Annual Goals</strong>
              </span>
            </Col> */}
            <Col
              xs={24}
              md={12}
              style={{
                
                fontSize: "19px",
                fontStyle: "normal",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
              className="revenue-sub-title step3"
            >
              <strong style={{ color: "#F04438" }}>Step 3</strong>
              <strong style={{color:'#1D2939', fontWeight:700}}>
                {" "}
                - Set Your Segment Funnel Configuration{" "}
                <span style={{color:'#7F56D9', fontWeight:700}}>{this.props.baseLineData?.name &&
                  `(${this.props.baseLineData?.name})`}</span>
              </strong>{" "}
            </Col>
            <Col
              xs={24}
              md={12}
              style={{
                textAlign: "right",
                paddingBottom: "0px",
                display:'flex',
                justifyContent:'flex-end'
              }}
              className="non-print buttonGrp"
              
            >
              {/* <Tooltip
                title={
                  !baseLineData?.goal
                    ? "Please save Goal Configuration in Step 2"
                    : ""
                }
              >
                {(userPermission?.revenuePlanning &&
                  userPermission?.revenuePlanning !== "viewonly" &&
                  userPermission?.["revenuePlanning.configureStages"] &&
                  userPermission?.["revenuePlanning.configureStages"] !==
                    "viewonly" &&
                  userPermission?.["revenuePlanning.configureStages"] !==
                    "hidden") ||
                (account?.[2] === "admin" && viewType !== "user") ? (
                  <Button
                    style={{
                      marginRight: "12px",
                      height: "38px",
                    }}
                    disabled={
                      marketingSourcedCount === 0 ||
                      !(
                        chartData &&
                        chartData.length > 0 &&
                        chartData[chartData.length - 1].value !== 0
                      ) ||
                      !baseLineData?.goal
                    }
                    type="secondary"
                    onClick={(e) => this.handleOpenConfigPopup()}
                    data-tour="tour_stageConfig"
                  >
                    {" "}
                    <span> Configure Stages</span>
                  </Button>
                ) : (
                  <Button
                    style={{
                      marginRight: "12px",
                      height: "38px",
                    }}
                    disabled
                    type="secondary"
                    title="View only permission"
                    data-tour="tour_stageConfig"
                  >
                    {" "}
                    <span> Configure Stages</span>
                  </Button>
                )}
              </Tooltip> */}
              {/* </Col>
            <Col xs={24} md={18} lg={4}> */}
              {userPermission?.revenuePlanning !== "viewonly" ? (
                <Button
                  style={{
                    marginRight: "12px",
                    height: "38px",
                  }}
                  type="primary"
                  onClick={(e) => {
                    window.print();
                  }}
                  disabled={
                    marketingSourcedCount === 0 || !funnelConfiguration.id || this.props.multiSelected
                  }
                  className="primaryButton"
                >
                  <img
                    src={pdfIcon}
                    style={{ paddingRight: 5, fontSize: "16px" }}
                    alt="pdf"
                  ></img>
                  Export To PDF
                </Button>
              ) : (
                <Button
                  style={{
                    marginRight: "12px",
                    height: "38px",
                  }}
                  type="primary"
                  title="View only permission"
                  disabled
                >
                  <img
                    src={pdfIcon}
                    style={{ paddingRight: 5, fontSize: "16px" }}
                    alt="pdf"
                  ></img>
                  Export To PDF
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={17} lg={17}>
              {this.props.getFiscalYearListLoader ? (
                <Skeleton active style={{ marginLeft: 25 }} />
              ) : (
                <>
                  <div className="annual-goal-header-text" style={{color:'#475467'}}>
                    Adjust the sliders below to specify the conversion rates
                    between each stage of the funnel
                  </div>
                  <div className="annual-goal-sub-header-text" style={{color:'#98A2B3'}}>
                    If you have funnel conversion rates, you can apply here. If
                    not, you can adjust these sliders to see the difference in
                    conversion rates between each stages{" "}
                  </div>
                </>
              )}
            </Col>
            {chartData &&
              chartData.length > 0 &&
              chartData[chartData.length - 1].value !== 0 && (
                <Col
                  xs={24}
                  md={7}
                  lg={7}
                  className="pt-0"
                  style={{ paddingTop: 50, paddingRight: 12 }}
                >
                  <Row
                    className="right_side_label"
                    style={{
                      display: "inline-flex",
                      justifyContent: "flex-end",
                      marginBottom: 8,
                    }}
                  >
                    <Col className="non-print">
                      <span style={{ width: "20%" }}>
                        {conversationDiffrence && (
                          <span className="conversation-difference-dot"></span>
                        )}
                      </span>
                      <div style={{display:'flex'}}>
                      <span style={{ width: "50%" }}>
                        <Tooltip
                          title={
                            !baseLineData?.goal
                              ? "Please save Goal Configuration in Step 2"
                              : ""
                          }
                        >
                          {userPermission?.revenuePlanning !== "viewonly" ? (
                            <Button
                              type="secondary"
                              onClick={this.handleSaveFunnelStage}
                              style={{
                                height: '40px',
                                width:'120px',
                                marginRight: "10px",
                                fontSize: "16px",
                                paddingLeft: 20,
                                paddingright: 20,
                              }}
                              disabled={!baseLineData?.goal || this.props.multiSelected}
                              className="primaryButton"
                            >
                              <img
                                src={saveConversationIcon}
                                style={{
                                  paddingRight: 5,
                                  opacity: `${baseLineData?.goal ? 1 : 0.5}`,
                                }}
                                alt="save"
                              ></img>{" "}
                              Save
                            </Button>
                          ) : (
                            <Button
                              type="secondary"
                              title="View only permission"
                              style={{
                                height: '40px',
                                width:'120px',
                                marginRight: '10px',
                                fontSize: "16px",
                                paddingLeft: 20,
                                paddingright: 20,
                              }}
                              disabled
                              className="primaryButton"
                            >
                              <img
                                src={saveConversationIcon}
                                style={{
                                  paddingRight: 5,
                                  opacity: `${baseLineData?.goal ? 1 : 0.5}`,
                                }}
                                alt="save"
                              ></img>{" "}
                              Save
                            </Button>
                          )}
                        </Tooltip>
                      </span>
                      {/* </Col>
                    <Col xs={24} md={24} lg={12}> */}
                      <Tooltip
                        title={
                          !baseLineData?.goal
                            ? "Please save Goal Configuration in Step 2"
                            : ""
                        }
                      >
                        {userPermission?.revenuePlanning !== "viewonly" ? (
                          <Button
                            type="secondary"
                            onClick={this.handleRestFunnelStage}
                            style={{
                              height: '40px',
                              width: '120px',
                              marginRight: 12,
                              color:"#fff",
                              fontSize: "16px",
                            }}
                            disabled={!baseLineData?.goal || this.props.multiSelected}
                            className="secondaryButton"
                          >
                            <img
                              src={resetConversationIcon}
                              style={{
                                paddingRight: 5,
                                opacity: `${baseLineData?.goal ? 1 : 0.5}`,
                              }}
                              alt="reset"
                            ></img>{" "}
                            Reset
                          </Button>
                        ) : (
                          <Button
                            type="secondary"
                            title="View only permission"
                            style={{
                              height: '40px',
                              width: '120px',
                              marginRight: 12,
                             
                              color:"#fff",
                              fontSize: "16px",
                            }}
                            disabled
                            className="secondaryButton"
                          >
                            <img
                              src={resetConversationIcon}
                              style={{
                                paddingRight: 5,
                                opacity: `${baseLineData?.goal ? 1 : 0.5}`,
                              }}
                              alt="reset"
                            ></img>{" "}
                            Reset
                          </Button>
                        )}
                      </Tooltip>
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
          </Row>
          <Row
            style={{
              background: "#fff",
              paddingBottom: 20,
              borderBottomLeftRadius: 7,
              borderBottomRightRadius: 7,
            }}
          >
            {(filteredChartData &&
              filteredChartData.length > 0 &&
              filteredChartData[filteredChartData.length - 1].value !== 0) ||
            isTourOpen ? (
              <Fragment>
                <Col lg={12} md={24} xs={24} className="funnelchart">
                  <FunnelCharts
                    data={isTourOpen ? TourFunnelData : filteredChartData}
                    color={[
                      // "#8becf9",
                      // "#75ECF9",
                      // "#4FE8FD",
                      // "#4DE1FD",
                      // "#0EE6FF",
                      // "#28DFF9",
                      // "#12D5EF",
                      // "#00C3DD",
                      // "#00B9D1",

                      // "#D6BBFB",
                      "#B692F6",
                      "#9E77ED",
                      "#7F56D9",
                      "#6941C6",
                      "#53389E",

                    ]}
                    // conversionTag= {true}
                    labelColor="#fff"
                    transform={false}
                    
                  />
                </Col>
                <Col
                  lg={12}
                  md={24}
                  xs={24}
                  className="nextPage only-print spacing-row"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  {/* <Title
                  level={3}
                  style={{ marginBottom: 0, width: "100%", marginLeft: 20 }}
                > */}
                  {/* Adjust Conversion Rates{" "} */}
                  {loading && (
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 22 }} spin />
                      }
                    />
                  )}
                  {/* </Title> */}
                  {/* <Divider style={{ margin: "15px 0 0 0" }} /> */}
                  {funnelStages.length > 0 &&
                    filteredChartData &&
                    filteredChartData.length > 0 &&
                    filteredChartData.map((item, index) => {
                      if (index !== filteredChartData.length - 1) {
                        return (
                          <Row
                            xs={24}
                            md={24}
                            lg={24}
                            className="row"
                            key={index}
                          >
                            <Col
                              xs={24}
                              md={20}
                              lg={20}
                              className="revenue-slider"
                              style={{
                                marginTop: "0px",
                                height: "auto",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <RevenueTextField
                                uperText={`${item?.text}
                               to ${filteredChartData[index + 1]?.text}`}
                                value={item.percentageValue}
                                isSlider={true}
                                name={item?.text}
                                // style={{ margin: "20px 0 0 0px" }}
                                minVal={1}
                                maxVal={100}
                                disabled={
                                  loading ||
                                  userPermission?.revenuePlanning ===
                                    "viewonly" ||
                                  userPermission?.[
                                    "revenuePlanning.adjustStageConversionRateSliders"
                                  ] === "viewonly" ||
                                  userPermission?.[
                                    "revenuePlanning.adjustStageConversionRateSliders"
                                  ] === "hidden" ||
                                  this.props.multiSelected
                                }
                                onChange={(name, value) =>
                                  this.handleOnChange(name, value, index)
                                }
                                onButtonClick={() =>
                                  this.handleSaveFunnelStage(index)
                                }
                                isSaveButton={
                                  sortingFunnel[index] &&
                                  sortingFunnel[index].conversion !==
                                    item.percentageValue &&
                                  item.id
                                }
                                buttonText={"Save"}
                                buttonType={"primary"}
                              />
                            </Col>
                          </Row>
                        );
                      }
                      return <Fragment key={index}></Fragment>;
                    })}
                  <div
                    style={{
                      fontSize: 16,
                      paddingLeft: 0,
                      paddingTop: 0,
                      paddingBottom: 30,
                      height: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography.Text style={{color:'#7F56D9', marginBottom:'5px', fontWeight:500}}>
                      Pipeline Coverage :{" "}
                      <strong style={{color:'#F04438'}}>{totalPipeLineCoverage}x </strong>
                    </Typography.Text>
                    <Typography.Text style={{color:'#7F56D9', marginBottom:'5px', fontWeight:500}}>
                      Overall Funnel Conversion Rate :{" "}
                      <strong style={{color:'#F04438'}}>{overallConversionRate}% </strong>
                    </Typography.Text>
                  </div>
                </Col>
              </Fragment>
            ) : (
              <Col lg={24} md={24}>
                <Title
                  level={2}
                  style={{
                    color: "gray",
                    padding: 100,
                    textAlign: "center",
                    margin: 0,
                  }}
                  className="empty-funnel-section"
                >
                  Please set Segment inputs for your annual targets.
                </Title>
              </Col>
            )}
            {/* {configPopUp && (
              <ModelConfigGraph
                configPopUp={configPopUp}
                plotData={chartData}
                handleOpenConfigPopup={this.handleOpenConfigPopup}
              />
            )} */}
          </Row>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    baseLineData,
    funnelConfiguration,
    planningPeriod,
    funnelStages,
    marketingSourcedCount,
    totalPipeLineCoverage,
  } = state.revenueReducer;

  funnelStages.forEach((stage) => {
    const { salesAndMarketingAlignment } = stage;
    stage.name = salesAndMarketingAlignment?.name || "" ;
    stage.isLast = salesAndMarketingAlignment?.isLast || false;
    stage.disabled = salesAndMarketingAlignment?.disabled || false;
    stage.showPipelineCoverage = salesAndMarketingAlignment?.showPipelineCoverage || false;
  });

  return {
    baseLineData,
    funnelConfiguration,
    funnelStages,
    planningPeriod,
    marketingSourcedCount,
    totalPipeLineCoverage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updatePlotDataList: (array) => dispatch(updatePlotDataList(array)),
    getFunnelStagesList: (id) => dispatch(getFunnelStagesList(id)),
    updateFunnelStage: (inputData) => dispatch(updateFunnelStages(inputData)),
    createFunnelStage: (inputData) => dispatch(createFunnelStages(inputData)),
    setPipelineCoverage: (inputData) =>
      dispatch(setPipelineCoverage(inputData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FunnelConversionRates);
