import { Tabs } from "antd";
import React from "react";
import PackageList from "./PackageList";
import AssignPackage from "./AssignPackage";
import PropertySettings from "./PropertySettings";

const PackageSettings = (props) => {
  const { TabPane } = Tabs;

  return (
    <>
      <div className="user-container">
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Package List" key="1">
            <PackageList></PackageList>
          </TabPane>
          <TabPane tab="Assign Package" key="2">
            <AssignPackage></AssignPackage>
          </TabPane>
          <TabPane tab="Default Properties" key="3">
          <PropertySettings  />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default PackageSettings;
