/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Typography, Tabs, Button, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyPositioning,
  getPersonasList,
  getTargetIdealCustomProfileList,
  getCompanySocialChannelsList,
  getCurrentMarketActivitiesList,
  getMarektingAutomationSystemList,
  getCurrentMarketToolsList,
} from "store/actions/goToMarketAction";
import { getaccountDetails } from "store/actions/loginAction";

import gotomarketImg from "../../assets/Images/gotomarketImg.svg";
import go_to_market_module_preview from "../../assets/Images/go_to_market_module_preview.png";
import AssetsLibrary from "./AssetsLibrary";
import CompanyInfo from "./CompanyInfo";
import "./gotomarket.less";
import SystemsProcess from "./SystemsProcess";
import TargetAudience from "./TargetAudience";
import { Page, Document, pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import {
  TargetAudienceCover,
  TargetAudienceCustomreProfile,
  TargetAudienceUserPersona,
  SystemProcessCover,
  SyestemProcess,
  CompanyInformation,
  CompanyInfoCover,
  HeaderPage,
} from "./PdfPages";
import { target_market_segments } from "utility/Constants";
import PreviewInfo from "commons/PreviewInfo";
import { Prompt, useHistory } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";
import pptxgen from "pptxgenjs";
import {
  SystemProcessPPT,
  CompanyInfoCoverPPT,
  CompanyInfoPPT,
  TargetAudiencePPT,
  MasterCoverPPT,
  SystemProcessCoverPPT,
  TargetAudienceCoverPPT,
} from "./PPTXPages";
import { Storage } from "aws-amplify";
import config from "aws-exports";
import { LightenDarkenColor } from "utility/context/CommonMethods";

import Tour from "reactour";
import sdk from "sdk/Accounts";
import { uploadFile } from "../../ApiService/REST_Api";
import { TrackClickFunction } from "../../commons/HeapTrack/TrackClickFunction";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

export default function GoToMarket() {
  const [isTourOpen, setisTourOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [c, setc] = useState(1);

  const dispatch = useDispatch();
  const {
    companyPositioning,
    TargetIdealCustomProfile,
    Persona,
    companySocialChannels,
    CurrentMarketActivity,
    MarketingAutomationSystem,
    CurrentMarketTools,
  } = useSelector((state) => state.goToMarketReducer);

  const {
    accountDetails,
    groups: account,
    userPermission,
    viewType,
  } = useSelector((state) => state.loginReducer);
  const [showSkeletonRoot, setShowSkeletonRoot] = useState(true);
  useEffect(() => {
    Promise.all([
      dispatch(getaccountDetails(account[0])),
      dispatch(getCompanyPositioning()),
      dispatch(getTargetIdealCustomProfileList()),
      dispatch(getPersonasList()),
      dispatch(getCompanySocialChannelsList()),
      dispatch(getCurrentMarketActivitiesList()),
      dispatch(getMarektingAutomationSystemList()),
      dispatch(getCurrentMarketToolsList()),
    ]).then(() => {
      setShowSkeletonRoot(false);
    });
  }, []);

  const [systemProcessData, SetSystemProcessData] = useState([]);
  const [companyInfoData, setCompanyInfoData] = useState([]);
  const [targetAudienceData, setTargetAudienceData] = useState([]);

  useEffect(() => {
    let companyData = {};
    if (companyPositioning?.length > 0) {
      companyData = { ...companyPositioning?.[0] };
    }
    companyData.name = accountDetails.company;
    companyData.url = accountDetails.url;
    companyData.company_description = accountDetails?.company_description
      ? accountDetails?.company_description
      : "-";
    companyData.product_description = accountDetails?.product_description
      ? accountDetails?.product_description
      : "-";
    companyData.tagline = accountDetails?.tagline
      ? accountDetails?.tagline
      : "-";
    setCompanyInfoData(companyData);

    let targetAudiencedata = {};
    if (TargetIdealCustomProfile?.length > 0) {
      targetAudiencedata = { ...TargetIdealCustomProfile?.[0] };
      let swaped = swapKeyValue(target_market_segments);
      targetAudiencedata.market_segments =
        targetAudiencedata?.market_segments?.map((item) => swaped[item]);

      // swaped = swapKeyValue(target_regions)
      // targetAudiencedata.regions = targetAudiencedata?.regions?.map(item => swaped[item])
      setTargetAudienceData(targetAudiencedata);
    }

    let systemProcessObj = {
      ...companySocialChannels?.[0],
      ...MarketingAutomationSystem?.[0],
      ...CurrentMarketActivity?.[0],
      ...CurrentMarketTools?.[0],
    };
    SetSystemProcessData(systemProcessObj);
  }, []);

  const downloadPPTX = async () => {
    let url = await getSignedUrlImg(accountDetails?.profile_url);

    let StylingObject = {};

    StylingObject.primaryColor = accountDetails?.colors
      ? JSON.parse(accountDetails?.colors)?.[0]
      : "#0f61db";
    StylingObject.backgroundColor = LightenDarkenColor(
      accountDetails?.colors
        ? JSON.parse(accountDetails?.colors)?.[0]
        : "#0f61db",
      200
    );
    StylingObject.logoUrl = url;

    let pptx = new pptxgen();

    pptx = MasterCoverPPT(pptx, StylingObject);
    if (
      (userPermission?.["goToMarketIntelligence.CompanyInformation"] &&
        userPermission?.["goToMarketIntelligence.CompanyInformation"] !==
          "hidden") ||
      (account?.[2] === "admin" &&
        viewType !== "companyadmin" &&
        viewType !== "companycontributor")
    ) {
      pptx = CompanyInfoCoverPPT(pptx, StylingObject);
      pptx = await CompanyInfoPPT(pptx, companyInfoData, StylingObject);
    }

    if (
      (userPermission?.["goToMarketIntelligence.TargetAudience"] &&
        userPermission?.["goToMarketIntelligence.TargetAudience"] !==
          "hidden") ||
      (account?.[2] === "admin" &&
        viewType !== "companyadmin" &&
        viewType !== "companycontributor")
    ) {
      pptx = TargetAudienceCoverPPT(pptx, StylingObject);
      pptx = TargetAudiencePPT(
        pptx,
        targetAudienceData,
        Persona,
        StylingObject
      );
    }

    if (
      (userPermission?.["goToMarketIntelligence.Systems"] &&
        userPermission?.["goToMarketIntelligence.Systems"] !== "hidden") ||
      (account?.[2] === "admin" &&
        viewType !== "companyadmin" &&
        viewType !== "companycontributor")
    ) {
      pptx = SystemProcessCoverPPT(pptx, StylingObject);
      pptx = SystemProcessPPT(pptx, systemProcessData, StylingObject);
    }

    pptx.writeFile({ fileName: "Go to Market Intelligence.pptx" });
  };

  const swapKeyValue = (obj) => {
    let ret = {};
    for (const key in obj) {
      ret[obj[key]] = key;
    }
    return ret;
  };

  const GTMMasterDoc = useMemo(() => {
    return ({ logoUrl }) => {
      let companyData = {};
      if (companyPositioning?.length > 0) {
        companyData = { ...companyPositioning?.[0] };
      }

      companyData.name = accountDetails.company;
      companyData.url = accountDetails.url;
      companyData.company_description = accountDetails?.company_description
        ? accountDetails?.company_description
        : "-";
      companyData.product_description = accountDetails?.product_description
        ? accountDetails?.product_description
        : "-";
      companyData.tagline = accountDetails?.tagline
        ? accountDetails?.tagline
        : "-";
      // setCompanyInfoData(companyData)

      let targetAudiencedata = {};
      if (TargetIdealCustomProfile?.length > 0) {
        targetAudiencedata = { ...TargetIdealCustomProfile?.[0] };
        let swaped = swapKeyValue(target_market_segments);
        targetAudiencedata.market_segments =
          targetAudiencedata?.market_segments?.map((item) => swaped[item]);

        // swaped = swapKeyValue(target_regions)
        // targetAudiencedata.regions = targetAudiencedata?.regions?.map(item => swaped[item])
        // setTargetAudienceData(targetAudiencedata)
      }
      let personaData = [];
      if (Persona?.length > 0) {
        personaData = Persona?.[0]?.personas;
      }

      let StylingObject = {};

      StylingObject.primaryColor = accountDetails?.colors
        ? JSON.parse(accountDetails?.colors)?.[0]
        : "#0f61db";
      StylingObject.backgroundColor = LightenDarkenColor(
        accountDetails?.colors
          ? JSON.parse(accountDetails?.colors)?.[0]
          : "#0f61db",
        200
      );
      StylingObject.logoUrl = logoUrl;

      let systemProcessObj = {
        ...companySocialChannels?.[0],
        ...MarketingAutomationSystem?.[0],
        ...CurrentMarketActivity?.[0],
        ...CurrentMarketTools?.[0],
      };
      // SetSystemProcessData(systemProcessObj)
      return (
        <Document>
          <Page
            orientation="landscape"
            style={{ paddingTop: 20, paddingBottom: 50, paddingHorzontal: 30 }}
            wrap
          >
            <HeaderPage
              logoUrl={{ logourl: logoUrl, url: accountDetails.url }}
            />

            {((userPermission?.["goToMarketIntelligence.CompanyInformation"] &&
              userPermission?.["goToMarketIntelligence.CompanyInformation"] !==
                "hidden") ||
              (account?.[2] === "admin" &&
                viewType !== "companyadmin" &&
                viewType !== "companycontributor")) && (
              <CompanyInfoCover
                {...companyData}
                {...StylingObject}
                logoUrl={{ logourl: logoUrl, url: accountDetails.url }}
              />
            )}
            {((userPermission?.["goToMarketIntelligence.CompanyInformation"] &&
              userPermission?.["goToMarketIntelligence.CompanyInformation"] !==
                "hidden") ||
              (account?.[2] === "admin" &&
                viewType !== "companyadmin" &&
                viewType !== "companycontributor")) && (
              <CompanyInformation {...companyData} {...StylingObject} />
            )}
            {((userPermission?.["goToMarketIntelligence.TargetAudience"] &&
              userPermission?.["goToMarketIntelligence.TargetAudience"] !==
                "hidden") ||
              (account?.[2] === "admin" &&
                viewType !== "companyadmin" &&
                viewType !== "companycontributor")) && (
              <TargetAudienceCover
                {...StylingObject}
                logoUrl={{ logourl: logoUrl, url: accountDetails.url }}
              />
            )}
            {((userPermission?.["goToMarketIntelligence.TargetAudience"] &&
              userPermission?.["goToMarketIntelligence.TargetAudience"] !==
                "hidden") ||
              (account?.[2] === "admin" &&
                viewType !== "companyadmin" &&
                viewType !== "companycontributor")) && (
              <TargetAudienceCustomreProfile
                {...targetAudiencedata}
                {...StylingObject}
              />
            )}
            {((userPermission?.["goToMarketIntelligence.TargetAudience"] &&
              userPermission?.["goToMarketIntelligence.TargetAudience"] !==
                "hidden") ||
              (account?.[2] === "admin" &&
                viewType !== "companyadmin" &&
                viewType !== "companycontributor")) && (
              <TargetAudienceUserPersona
                persona={personaData}
                {...StylingObject}
              />
            )}
            {((userPermission?.["goToMarketIntelligence.Systems"] &&
              userPermission?.["goToMarketIntelligence.Systems"] !==
                "hidden") ||
              (account?.[2] === "admin" &&
                viewType !== "companyadmin" &&
                viewType !== "companycontributor")) && (
              <SystemProcessCover
                {...StylingObject}
                logoUrl={{ logourl: logoUrl, url: accountDetails.url }}
              />
            )}
            {((userPermission?.["goToMarketIntelligence.Systems"] &&
              userPermission?.["goToMarketIntelligence.Systems"] !==
                "hidden") ||
              (account?.[2] === "admin" &&
                viewType !== "companyadmin" &&
                viewType !== "companycontributor")) && (
              <SyestemProcess {...systemProcessObj} {...StylingObject} />
            )}
          </Page>
        </Document>
      );
    };
  }, [
    companyPositioning,
    accountDetails,
    TargetIdealCustomProfile,
    Persona,
    companySocialChannels,
    MarketingAutomationSystem,
    CurrentMarketActivity,
    CurrentMarketTools,
  ]);

  // tour steps
  const steps = [
    {
      selector: '[data-tour = "tour__companyinfo"]',
      content: () => (
        <div>
          <Title className="fuel-title" level={4}>
            Company Info
          </Title>
          <Paragraph>
            Outline how your company differentiate with your competitors.
          </Paragraph>
        </div>
      ),
      action: (node) => {
        // let node  = document.getElementById("rc-tabs-0-tab-1")
        node?.focus();
        // node.scrollIntoView({ block: "start", behavior: "smooth"});
      },
    },
    {
      selector: '[data-tour = "tour__target_audience"]',
      content: () => (
        <div>
          <Title className="fuel-title" level={4}>
            Target Audience
          </Title>
          <Paragraph>
            Define your Ideal Customer Profile and configure any relevant
            Personas.
          </Paragraph>
        </div>
      ),
      action: (node) => {
        // let node  = document.getElementById("rc-tabs-0-tab-2")
        node?.focus();
        // node.scrollIntoView({ block: "start", behavior: "smooth"});
      },
    },
    {
      selector: '[data-tour = "tour__system_processes"]',
      content: () => (
        <div>
          <Title className="fuel-title" level={4}>
            Systems & Processes
          </Title>
          <Paragraph>
            Specify any tools, tactics, and rules that you're currently using to
            tackle your marketing objectives.
          </Paragraph>
        </div>
      ),
      action: (node) => {
        // let node  = document.getElementById("rc-tabs-0-tab-3")
        node?.focus();
        // node.scrollIntoView({ block: "start", behavior: "smooth"});
      },
    },
    {
      selector: '[data-tour = "tour__asset_library"]',
      content: () => (
        <div>
          <Title className="fuel-title" level={4}>
            Asset Library
          </Title>
          <Paragraph>
            Organize your marketing assets for convenient access.
          </Paragraph>
        </div>
      ),
      action: (node) => {
        // let node  = document.getElementById("rc-tabs-0-tab-4")
        node?.focus();
        // node.scrollIntoView({ block: "start", behavior: "smooth"});
      },
    },
    {
      selector: '[data-tour = "tour__Export"]',
      content: () => (
        <div>
          <Title className="fuel-title" level={4}>
            Export
          </Title>
          <Paragraph>
            {" "}
            Download all of your Go to Market details with ease.
          </Paragraph>
        </div>
      ),
      action: (node) => {
        node?.focus();
        node?.scrollIntoView({ block: "nearest", inline: "nearest" });
      },
    },
  ];

  // Navigation Prevention

  function callback(key) {}

  const [hasUnsavedChanges, sethasUnsavedChanges] = useState({});
  const [isShowModal, setisShowModal] = useState(false);
  const [nextLocation, setnextLocation] = useState(null);
  const [confirmedNavigation, setconfirmedNavigation] = useState(false);

  const history = useHistory();

  const showModal = (nextLocation) => {
    setisShowModal(true);
    setnextLocation(nextLocation);
    setconfirmedNavigation(true);
  };

  const onLeave = () => {
    setisShowModal(false);
    history.push(nextLocation);
  };

  const onStay = () => {
    setisShowModal(false);
    setconfirmedNavigation(false);
    document.getElementById("goToMarket").click();
  };

  const preventNavigation = (nextLocation) => {
    let location = history.location;

    if (location.pathname === nextLocation.pathname) {
      return false;
    }

    if (confirmedNavigation) {
      return true;
    }

    showModal(nextLocation.pathname);
    return false;
  };

  const [loadingPDF, setLoadingPDF] = useState(false);
  const generatePdfDocument = async (fileName, pdfDocumentComponent) => {
    setLoadingPDF(true);
    const blob = await pdf(pdfDocumentComponent).toBlob();
    setLoadingPDF(false);
    saveAs(blob, fileName);
  };

  async function getSignedUrlImg(fileKey) {
    let bucketName = config.aws_cloud_logic_custom?.[0].endpoint;
    bucketName = bucketName?.split("/");
    Storage.configure({
      AWSS3: {
        bucket: `4amdemand-company-profile-image-${bucketName?.[3]}`,
        region: config.aws_user_files_s3_bucket_region,
      },
    });

    const fileInfo = fileKey?.split("/");
    const signedURL = await Storage.get(fileInfo?.[1], {
      level: "protected",
      identityId: fileInfo[0],
    });
    return signedURL;
  }

  const emailPdfDocument = async (subjectfileName, pdfDocumentComponent) => {
    setLoadingPDF(true);
    const blob = await pdf(pdfDocumentComponent).toBlob();
    uploadDocStorage(blob, subjectfileName, "pdf");
  };

  const emailPPTXDocument = async () => {
    let url = await getSignedUrlImg(accountDetails?.profile_url);

    let StylingObject = {};

    StylingObject.primaryColor = accountDetails?.colors
      ? JSON.parse(accountDetails?.colors)?.[0]
      : "#0f61db";
    StylingObject.backgroundColor = LightenDarkenColor(
      accountDetails?.colors
        ? JSON.parse(accountDetails?.colors)?.[0]
        : "#0f61db",
      200
    );
    StylingObject.logoUrl = url;

    let pptx = new pptxgen();
    setLoadingPDF(true);
    pptx = MasterCoverPPT(pptx, StylingObject);
    if (
      (userPermission?.["goToMarketIntelligence.CompanyInformation"] &&
        userPermission?.["goToMarketIntelligence.CompanyInformation"] !==
          "hidden") ||
      (account?.[2] === "admin" &&
        viewType !== "companyadmin" &&
        viewType !== "companycontributor")
    ) {
      pptx = await CompanyInfoCoverPPT(pptx, StylingObject);
      pptx = await CompanyInfoPPT(pptx, companyInfoData, StylingObject);
    }

    if (
      (userPermission?.["goToMarketIntelligence.TargetAudience"] &&
        userPermission?.["goToMarketIntelligence.TargetAudience"] !==
          "hidden") ||
      (account?.[2] === "admin" &&
        viewType !== "companyadmin" &&
        viewType !== "companycontributor")
    ) {
      pptx = await TargetAudienceCoverPPT(pptx, StylingObject);
      pptx = await TargetAudiencePPT(
        pptx,
        targetAudienceData,
        Persona,
        StylingObject
      );
    }

    if (
      (userPermission?.["goToMarketIntelligence.Systems"] &&
        userPermission?.["goToMarketIntelligence.Systems"] !== "hidden") ||
      (account?.[2] === "admin" &&
        viewType !== "companyadmin" &&
        viewType !== "companycontributor")
    ) {
      pptx = await SystemProcessCoverPPT(pptx, StylingObject);
      pptx = await SystemProcessPPT(pptx, systemProcessData, StylingObject);
    }

    pptx
      .stream()
      .then((data) => {
        uploadDocStorage(
          data,
          "Demand Generation - Go to Market Intelligence Complete Presentation",
          "pptx"
        );
      })
      .catch((err) => {
        console.error(err);
        setLoadingPDF(false);
      });
  };

  const uploadDocStorage = async (file, subjectfileName, type) => {
    let fileMimeType =
      type === "pdf"
        ? "application/pdf"
        : "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    let resultURLs = await sdk.getSignedPutURL(`${subjectfileName}.${type}`);
    await uploadFile(file, resultURLs?.url, fileMimeType);
    setLoadingPDF(false);
    const mailTo = "";
    const subject = subjectfileName;
    const body = `Check this out from Demand Generation :  ${resultURLs?.end_url} `;
    window.location.href = `mailto:${mailTo}?subject=${subject}&body=${body}`;
  };

  return (userPermission?.goToMarketIntelligence === "preview" &&
    account?.[2] === "admin" &&
    (viewType !== "companyadmin" || viewType !== "companycontributor")) ||
    (userPermission?.goToMarketIntelligence === "preview" &&
      account?.[2] !== "admin") ? (
    <div>
      <Row
        className="aboutPage"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 8,
          background: "#fff",
          margin: "0 0 15px 0px",
          width: "100%",
          borderRadius: 7,
          alignContent: "center",
        }}
      >
        <Col
          className="details"
          style={{
            padding: "0px 0px 0px 30px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
          span={12}
          lg={12}
          md={12}
          xs={24}
          sm={24}
        >
          <Typography.Text className="title" style={{ fontSize: 29 }}>
            <strong>Go To Market Intelligence</strong>
          </Typography.Text>
          <Typography.Text style={{ fontSize: 15 }}>
            Organize information about your company and customers to help
            highlight your demand generation marketing strategy
          </Typography.Text>
        </Col>

        <Col span={12} lg={12} md={12} xs={24} sm={24}>
          <Row justify="end">
            <img src={gotomarketImg} alt="" className="w-100" />
          </Row>
        </Col>
      </Row>
      <PreviewInfo img={go_to_market_module_preview} text="Upgrade to View" />
    </div>
  ) : (
    <div>
      {/* <CompanyInfoSlide companyInfoData = {companyInfoData} />
      <TargetAudienceSlide  targetAudienceData = {targetAudienceData} />
      <SystemProcessSlide systemProcessData = {systemProcessData}  /> */}
      {/* <SystemProcess style = {{display:"none"}}/> */}
      <Row
        className="gtm panel-design"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 8,
          background: "#fff",
          marginBottom: "20px",
          width: "100%",
          borderRadius: 7,
          alignContent: "center",
          padding:'30px 20px'
        }}
      >
        <Col
          className="details"
          style={{
            
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
          span={24}
          lg={24}
          md={24}
          xs={24}
          sm={24}
        >
          {/* Navigation prevention */}

          <Prompt
            message={preventNavigation}
            when={Boolean(
              Object.keys(hasUnsavedChanges).find(
                (key) => hasUnsavedChanges[key]
              )
            )}
          />
          <Modal
            className="preventNavModal"
            visible={isShowModal}
            closable={false}
            footer={[
              <Button
                key="back"
                onClick={onLeave}
                style={{
                  borderRadius: 7,
                  height: 40,
                }}
              >
                Leave Anyway
              </Button>,
              <Button key="stay" type="primary" onClick={onStay}>
                Stay
              </Button>,
            ]}
          >
            <Row>
              <Col span={2}>
                <Typography.Title level={4}>
                  <QuestionCircleOutlined style={{ color: "#faad14" }} />
                </Typography.Title>
              </Col>
              <Col span={22}>
                <Typography.Title level={5}>
                  Are you sure you want to leave?
                </Typography.Title>
                <Typography.Text type="secondary">
                  You have unsaved changes on this page. Leaving this page
                  without saving may result in your changes being lost.
                </Typography.Text>
              </Col>
            </Row>
          </Modal>
          <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <div>  
              <Typography.Text className="title fw700" style={{ fontSize: 32, color:'#1D2939', marginBottom:'15px', display:'block' }}>
                Go To Market Intelligence
              </Typography.Text>
              <Typography.Text style={{ fontSize: 16, color:'#475467', lineHeight:'26px' }} >
                Organize information about your company and customers to help <br />
                highlight your demand generation marketing strategy
              </Typography.Text>
            </div>  
          {/* <PDFDownloadLink
                document={<GTMMasterDoc />}
                fileName='Go to Marketing Master.pdf'
            >
                {({ loading }) => <div style={{paddingTop:17}}><Button style={{ marginTop:15, marginBottom:15}} type="primary"><img src={pdf_icon} alt="" style={{paddingRight:10}} /> Download Master PDF </Button></div>}

            </PDFDownloadLink> */}

            <Button
              className="take-a-tour-btn non-print primaryButton" 
              style={{height:'45px'}}
              onClick={() => {
                TrackClickFunction(
                  "demand-gen-gtm-intelligence-take-a-tour-button"
                );
                setisTourOpen(true);
              }}
            >
              Take a tour
          </Button>
          </div>

          {/* <Button style={{ marginTop:15, marginBottom:15, width:180}} type="primary" onClick={downloadPPTX}>
              Download Master PPTX
            </Button> */}
        </Col>

        {/* <Col span={12} lg={12} md={12} xs={24} sm={24}>
          <Row justify="end">
            <img src={gotomarketImg} alt="" className="w-100" />
          </Row>
        </Col> */}
      </Row>

      <Row
        className="gtm panel-design"
        style={{
          marginBottom: 8,
          background: "#fff",
          margin: "0 0 15px 0px",
          width: "100%",
          borderRadius: 7,
        }}
      >
        <Tabs
          defaultActiveKey="1"
          centered
          style={{ width: "100%" }}
          animated={false}
          onChange={callback}
          className="general-tab first-tab"
        >
          {((userPermission?.["goToMarketIntelligence.CompanyInformation"] &&
            userPermission?.["goToMarketIntelligence.CompanyInformation"] !==
              "hidden") ||
            (account?.[2] === "admin" &&
              viewType !== "companyadmin" &&
              viewType !== "companycontributor")) && (
            <TabPane
              tab={
                <span data-tour="tour__companyinfo" className="fw700">
                  Company Info
                </span>
              }
              key="1"
            >
              <CompanyInfo
                emailPdfDocument={emailPdfDocument}
                emailPPTXDocument={emailPPTXDocument}
                generatePdfDocument={generatePdfDocument}
                loadingPDF={loadingPDF}
                showSkeletonRoot={showSkeletonRoot}
                GTMMasterDoc={GTMMasterDoc}
                downloadPPTX={downloadPPTX}
                companyPositioning={companyPositioning}
                sethasUnsavedChanges={sethasUnsavedChanges}
                hasUnsavedChanges={hasUnsavedChanges}
                getSignedUrlImg={getSignedUrlImg}
              />
            </TabPane>
          )}

          {((userPermission?.["goToMarketIntelligence.TargetAudience"] &&
            userPermission?.["goToMarketIntelligence.TargetAudience"] !==
              "hidden") ||
            (account?.[2] === "admin" &&
              viewType !== "companyadmin" &&
              viewType !== "companycontributor")) && (
            <TabPane
              tab={
                <span data-tour="tour__target_audience" className="fw700">
                  Target Audience
                </span>
              }
              key="2"
            >
              <TargetAudience
                downloadPPTX={downloadPPTX}
                TargetIdealCustomProfile={TargetIdealCustomProfile}
                Persona={Persona}
                sethasUnsavedChanges={sethasUnsavedChanges}
                hasUnsavedChanges={hasUnsavedChanges}
                GTMMasterDoc={GTMMasterDoc}
                generatePdfDocument={generatePdfDocument}
                loadingPDF={loadingPDF}
                getSignedUrlImg={getSignedUrlImg}
                emailPdfDocument={emailPdfDocument}
                emailPPTXDocument={emailPPTXDocument}
              />
            </TabPane>
          )}

          {((userPermission?.["goToMarketIntelligence.Systems"] &&
            userPermission?.["goToMarketIntelligence.Systems"] !== "hidden") ||
            (account?.[2] === "admin" &&
              viewType !== "companyadmin" &&
              viewType !== "companycontributor")) && (
            <TabPane
              tab={
                <span data-tour="tour__system_processes">
                  <strong>Systems & Processes</strong>
                </span>
              }
              key="3"
            >
              <SystemsProcess
                downloadPPTX={downloadPPTX}
                companySocialChannels={companySocialChannels}
                CurrentMarketActivity={CurrentMarketActivity}
                MarketingAutomationSystem={MarketingAutomationSystem}
                CurrentMarketTools={CurrentMarketTools}
                sethasUnsavedChanges={sethasUnsavedChanges}
                hasUnsavedChanges={hasUnsavedChanges}
                GTMMasterDoc={GTMMasterDoc}
                showSkeletonRoot={showSkeletonRoot}
                generatePdfDocument={generatePdfDocument}
                loadingPDF={loadingPDF}
                getSignedUrlImg={getSignedUrlImg}
                emailPdfDocument={emailPdfDocument}
                emailPPTXDocument={emailPPTXDocument}
              />
            </TabPane>
          )}

          {((userPermission?.["goToMarketIntelligence.AssetLibrary"] &&
            userPermission?.["goToMarketIntelligence.AssetLibrary"] !==
              "hidden") ||
            (account?.[2] === "admin" &&
              (viewType !== "companyadmin" ||
                viewType !== "companycontributor"))) && (
            <TabPane
              tab={
                <span data-tour="tour__asset_library">
                  <strong>Asset Library</strong>
                </span>
              }
              key="4"
            >
              <AssetsLibrary
                emailPdfDocument={emailPdfDocument}
                emailPPTXDocument={emailPPTXDocument}
                generatePdfDocument={generatePdfDocument}
                loadingPDF={loadingPDF}
                showSkeletonRoot={showSkeletonRoot}
                downloadPPTX={downloadPPTX}
                sethasUnsavedChanges={sethasUnsavedChanges}
                hasUnsavedChanges={hasUnsavedChanges}
                GTMMasterDoc={GTMMasterDoc}
                getSignedUrlImg={getSignedUrlImg}
              />
            </TabPane>
          )}
        </Tabs>
      </Row>

      <Tour
        steps={steps}
        accentColor={"#0F61DB"}
        isOpen={isTourOpen}
        onRequestClose={() => {
          setisTourOpen(false);
        }}
        rounded={5}
        scrollDuration={100}
        disableInteraction={true}
        onAfterOpen={(_) => (document.body.style.overflowY = "hidden")}
        onBeforeClose={(_) => (document.body.style.overflowY = "auto")}
        lastStepNextButton={
          <Button type="primary" onClick={() => setisTourOpen(false)}>
            Get Started
          </Button>
        }
        disableFocusLock={true}
        closeWithMask={false}
        getCurrentStep={(curr) => setc(curr)}
        startAt={0}
      />
    </div>
  );
}
