/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {Button, Col, Modal, Row, Tabs, Typography} from "antd";
import salesAndMarketingImg from "../../assets/Images/undraw_sales_and_marketing.svg";
import DefaultStages from "./DefaultStages";
// eslint-disable-next-line
import CustomStages from "./CustomStages";
import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import sales_marketing_module_preview from "assets/Images/sales_marketing_module_preview.png"
import { setLoader } from "../../store/actions/reportAction";

import { QuestionCircleOutlined } from "@ant-design/icons";
import "jspdf-autotable";
import PreviewInfo from "commons/PreviewInfo";
import {getFiscalYearsList, updateFunnelStages } from "store/actions/revenueAction";
import Tour from "reactour";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
import { UpdateTourData } from "store/actions/loginAction";
import { getDefaultMappingStages, getSalesAndMarketingStages, updateSalesAndMarketingStage, getCustomStageMappings} from "store/actions/SalesAndMarketingAction";
import SdrCapacityCalculator from "pages/SDR/SdrCapacityCalculator";
import SdrCompensationModal from "pages/SDR/SdrCompensationModal";
import SdrMeetingAndOpportunityTracker from "pages/SDR/SdrMeetingAndOpportunityTracker";
import { Prompt, useHistory } from "react-router-dom";
import { TrackClickFunction } from "commons/HeapTrack/TrackClickFunction";

const { TabPane } = Tabs;

const SalesAndMarketing = () => {
  const dispatch = useDispatch();
  // account id array
  const {
    userPermission,
    viewType,
    groups: account,
    accountDetails,
    userData,
  } = useSelector((state) => state.loginReducer);
  const { fiscalYearList } = useSelector((state) => state.revenueReducer);
  const { ConnectedCRMs } = useSelector((state) => state.reportReducer);
  const { isLoading } = useSelector((state) => state.reportReducer);
  const { salesAndMarketingStages } = useSelector(
    (state) => state.salesAndMarketingReducer
  );
  // eslint-disable-next-line
  const [tableData, settableData] = useState([]);
  const [hubspotList] = useState(ConnectedCRMs?.["Hubspot"]);
  const [salesforceList] = useState(ConnectedCRMs?.["Salesforce"]);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [otherStageData, setOtherStageData] = useState([]);
  const [allStageData, setAllStageData] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [fullPermission, setfullPermission] = useState(
    !(userPermission?.["salesMarketing"] === "preview")
  );
  const [defaultStageMappings, setdefaultStageMappings] = useState({});
  const [getStagesLoader, setgetStagesLoader] = useState(true);
  const [defaultAvailableStages, setdefaultAvailableStages] = useState([]);
  useEffect(async () => {
    let hubspot = ConnectedCRMs["Hubspot"];
    fullPermission && dispatch(getFiscalYearsList());
    let dataObj = {};
    if (true) {
      // FOR DEFAULT STAGES
      await dispatch(getDefaultMappingStages(hubspot?.[0]?.profile_id || 0))
        .then((response) => {
          response?.defaultStages?.map((item) => {
            let data = {
              label: item?.platform,
              value: item?.available_mapping,
            };
            dataObj[item?.order] = dataObj[item?.order]
              ? [...dataObj[item?.order], data]
              : [data];
          });
          setdefaultAvailableStages(
            response?.defaultStages ? response?.defaultStages : []
          );
          setdefaultStageMappings(dataObj);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    let salesforce = ConnectedCRMs["Salesforce"];
    fullPermission &&
      dispatch(getSalesAndMarketingStages(account))
        .then((res) => {
          setgetStagesLoader(false);
        })
        .catch((err) => {
          setgetStagesLoader(false);
        });

    let availableMappingsInputData = {
      account: account[0],
      data: [
        {
          virtualStageId: "",
          Salesforce: [""],
          Hubspot: [""],
        },
      ],
    };
    await dispatch(getCustomStageMappings(availableMappingsInputData))
      .then((res) => {
        let available = {};
        if (res) {
          Object.keys(res?.[0]).map((key) => {
            if (key !== "virtualStageId") {
              if (
                (key === "Hubspot" && hubspot?.length > 0) ||
                (key === "Salesforce" && salesforce?.length > 0)
              ) {
                available[key + " Contacts"] =
                  res?.[0]?.[key]?.[0]?.availableContactMappings;
                available[key + " Deals"] =
                  res?.[0]?.[key]?.[0]?.availableDealMappings;
                available[key + " Lead"] =
                  res?.[0]?.[key]?.[0]?.availableLeadMappings;
                available[key + "LeadStatus"] =
                  res?.[0]?.[key]?.[0]?.availableLeadStatusMappings;
              }
            }
          });
        }
        setcustomAvailableMappings(available);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, [dispatch]);

  const [customSavedMappings, setcustomSavedMappings] = useState({});
  const [customAvailableMappings, setcustomAvailableMappings] = useState({});

  const [crmConnections, setcrmConnections] = useState({});
  const [customStages, setcustomStages] = useState([]);
  const [defaultStages, setdefaultStages] = useState([]);

  const [hasAnyCRMConnected, sethasAnyCRMConnected] = useState(false);

  const [currentLastStage, setcurrentLastStage] = useState({});
  const [isLastChanged, setisLastChanged] = useState(false);
  useEffect(() => {
    sethasAnyCRMConnected(Object.keys(customAvailableMappings)?.length > 0);
  }, [customAvailableMappings]);
  useEffect(() => {
    setTableCustomData();
    setdefaultStages(salesAndMarketingStages.filter((item) => item.isDefault));
    setcustomStages(salesAndMarketingStages.filter((item) => !item.isDefault));
    let last = salesAndMarketingStages?.find((item) => item.isLast);
    if (last?.id !== currentLastStage?.id) {
      setcurrentLastStage(last);
      // setisLastChanged(true);
    }
  }, [salesAndMarketingStages]);

  useEffect(() => {
    let inputData = {};
    if (customStages?.length > 0) {
      inputData = {
        account: account[0],
        data: [
          ...customStages?.map((item) => ({
            virtualStageId: item.id,
            Salesforce: [""],
            Hubspot: [""],
          })),
          ...defaultStages?.map((item) => ({
            virtualStageId: item.id,
            Salesforce: [""],
            Hubspot: [""],
          })),
        ],
      };
    } else {
      inputData = {
        account: account[0],
        data: [
          ...defaultStages?.map((item) => ({
            virtualStageId: item.id,
            Salesforce: [""],
            Hubspot: [""],
          })),
        ],
      };
    }
    dispatch(getCustomStageMappings(inputData)).then((res) => {
      let dataObj = {};
      res.map((item) => {
        Object.keys(item).map((key) => {
          if (key !== "virtualStageId") {
            let data = item[key]?.[0]?.savedStageMappings?.map((value) => ({
              label: key,
              value: value[Object.keys(value)[0]],
            }));
            if (data)
              dataObj[item?.["virtualStageId"]] = dataObj?.[
                item?.["virtualStageId"]
              ]
                ? [...dataObj?.[item?.["virtualStageId"]], ...data]
                : data;
          }
        });
      });
      setcustomSavedMappings(dataObj);
    });
  }, [customStages]);

  useEffect(() => {
    if (isLastChanged) {
      fiscalYearList?.map((fiscalYear) => {
        fiscalYear?.baselineMetrics?.items?.map((baselinemetric) => {
          baselinemetric?.funnelConfigs?.items?.map((funnelConfig) => {
            let stages = funnelConfig?.stages?.items;
            if (
              stages.find(
                (item) =>
                  item?.salesAndMarketingAlignmentID === currentLastStage?.id
              )
            ) {
              let maxorder = 0;
              let currentorder = 0;
              stages.map((item) => {
                if (item?.order > maxorder) {
                  maxorder = item.order;
                }
                if (
                  item?.salesAndMarketingAlignmentID === currentLastStage?.id
                ) {
                  currentorder = item.order;
                }
              });
              if (currentorder !== maxorder) {
                let currentLast = stages.find(
                  (item) => item.order === maxorder
                );
                let current = stages.find(
                  (item) => item.order === currentorder
                );
                let currentrequestData = {
                  id: current.id,
                  order: maxorder,
                };
                let lastreq = {
                  id: currentLast.id,
                  order: currentorder,
                };
                dispatch(updateFunnelStages(currentrequestData));
                dispatch(updateFunnelStages(lastreq));
              }
            } else {
              let stages = funnelConfig?.stages?.items;
              if (stages.length > 0) {
                let order = 0;
                stages.forEach((item) => {
                  order = item?.order > order ? item.order : order;
                });
                let stageToUpdate = stages.find(
                  (stage) => stage?.order === order
                );

                let requestData = {
                  id: stageToUpdate?.id,
                  name: currentLastStage?.name,
                  account: account[0],
                  salesAndMarketingAlignmentID: currentLastStage?.id,
                };
                dispatch(updateFunnelStages(requestData));
              }
            }
          });
        });
      });
    }
    setisLastChanged(false);
  }, [isLastChanged]);

  useEffect(() => {
    let data = {
      Hubspot: hubspotList ? hubspotList?.[0]?.profile_id : "",
      Salesforce: salesforceList ? salesforceList?.[0]?.profile_id : "",
    };
    setcrmConnections(data);
  }, [hubspotList, salesforceList]);

  const setTableCustomData = () => {
    let table_data = [];
    let funnelTableData = [];
    let data = {};
    // eslint-disable-next-line array-callback-return
    salesAndMarketingStages?.map((item) => {
      data["id"] = item?.id;
      data.Stages = item?.name;
      data.disabled = item?.disabled;
      data.definition = item?.definition;
      data.howitcomes = item?.howitcomes;
      data.thingstokeepinmind = item?.thingstokeepinmind;
      data.isLast = item?.isLast ?? false;
      data.isDefault = item?.isDefault ?? false;
      data.order = item?.order;
      data.showPipelineCoverage = item?.showPipelineCoverage;
      data.stageType = item?.stageType;
      data.internalStage = item?.internalStage
      data["selectedChannelStages"] = defaultStageMappings?.[data.order]
        ? defaultStageMappings?.[data.order]
        : [];
      if (data.disabled || data.internalStage === "Closed Lost") {
        table_data.push(data);
      } else {
        funnelTableData.push(data);
      }
      data = {};
    });
    funnelTableData.sort(sortingOrder);
    setOtherStageData(table_data);
    setAllStageData(funnelTableData);
  };

  const sortingOrder = (a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  };

  // on custom stage disable click
  const onDisableStage = useCallback(
    async (record) => {
      dispatch(setLoader(true));
      let inputData = {
        id: record.id,
        disabled: !record?.disabled,
      };
      return dispatch(updateSalesAndMarketingStage(inputData, false));
    },
    [dispatch]
  );

  const steps = [
    {
      selector: '[data-tour = "tour__funnelVisual"]',
      content: () => (
        <div>
          <Title className="fuel-title" level={4}>
            Funnel Visual
          </Title>
          <Paragraph>
            {" "}
            The funnel visualization configured here will be used throughout the Demand Generation product, but primarily within Revenue Planning {" "}
          </Paragraph>
        </div>
      ),
      //   action: (node) => {
      //     node?.scrollIntoView(false);
      //     node?.focus()
      //   },
    },
    {
      selector: '[data-tour = "tour__funnelStages"]',
      content: () => (
        <div>
          <Title level={4}>Funnel Stages</Title>
          <Paragraph>
            {" "}
            Your Funnel’s Stage names and their definitions are outlined here
          </Paragraph>
        </div>
      ),
      //   action: (node) => {
      //     node?.focus()
      //     node?.scrollIntoView({ block: "center", inline: "nearest" });
      //   },
    },
    {
      selector: '[data-tour = "tour__crmMappings"]',
      content: () => (
        <div>
          <Title level={4}>CRM Mappings (if applicable)</Title>
          <Paragraph>
            If you have connected a CRM, you will see how CRM stages map with
            Demand Generation stages here{" "}
          </Paragraph>
        </div>
      ),
      action: (node) => {
        node?.focus();
        node?.scrollIntoView({ block: "nearest", inline: "nearest" });
      },
    },
    {
      selector: '[data-tour = "tour_editStages"]',
      content: () => (
        <div>
          <Title level={4}>Edit Stages (if applicable)</Title>
          <Paragraph>
            You may choose to edit any stage names and their definitions, or disable them from use
          </Paragraph>
        </div>
      ),
      action: (node) => {
        node?.focus();
        node?.scrollIntoView({ block: "center", inline: "nearest" });
      },
    },
        {
      selector: '[data-tour = "tour__addStage"]',
      content: () => (
        <div>
          <Title level={4}>Add Stage</Title>
          <Paragraph>
            You may create additional custom stages beyond the Demand Generation defaults{" "}
          </Paragraph>
        </div>
      ),
      action: (node) => {
        node?.focus();
        node?.scrollIntoView({ block: "nearest", inline: "nearest" });
      },
    },
    {
      selector: '[data-tour = "tour_otherStage"]',
      content: () => (
        <div>
          <Title level={4}>Other Stages</Title>
          <Paragraph>
            Any disabled stages are reflected here
          </Paragraph>
        </div>
      ),
      action: (node) => {
        node?.focus();
        node?.scrollIntoView({ block: "center", inline: "nearest" });
      },
    },
    {
      selector: '[data-tour = "tour__capacityCalculator"]',
      content: () => (
        <div>
          <Title className="fuel-title" level={4}>
            SDR Capacity Calculator
          </Title>
          <Paragraph>
            {" "}
            Calculate your SDR resource needs based on Revenue Planning inputs
          </Paragraph>
        </div>
      ),
      action: (node) => {
        node?.focus();
        node?.scrollIntoView({ block: "center", inline: "nearest" });
      },
    },
    {
      selector: '[data-tour = "tour__compensationModel"]',
      content: () => (
        <div>
          <Title level={4}>SDR Compensation Model</Title>
          <Paragraph>
            {" "}
            Develop compensation plans to incentivize your team against desired outcomes
          </Paragraph>
        </div>
      ),
      action: (node) => {
        node?.focus();
        node?.scrollIntoView({ block: "center", inline: "nearest" });
      },
    },
    //   {
    //     selector: '[data-tour = "tour__meetingTracker"]',
    //     content: () => (<div>
    //       <Title level={4}>
    //       SDR Tracker
    //       </Title>
    //       <Paragraph> Track activity from your CRM to monitor each SDR’s progress towards their on-target earnings</Paragraph>
    //     </div>),
    //     action: (node) => {
    //       node?.focus()
    //       node?.scrollIntoView({ block: "nearest", inline: "nearest" });
    //     },
    //   },
  ];

  // eslint-disable-next-line no-unused-vars
  const [c, setc] = useState(1);

  const [accountMetadata, setaccountMetadata] = useState({});

  useEffect(() => {
    let metadata = JSON.parse(accountDetails?.metadata)?.[userData.sub];
    accountDetails?.metadata && setaccountMetadata(metadata);
  }, [accountDetails, userData]);

  useEffect(() => {
    if (account?.[2] !== "admin")
      setIsTourOpen(!accountMetadata?.SalesAndMarketingTourFinish);
  }, [accountMetadata]);

  const updateTour = () => {
    dispatch(
      UpdateTourData(
        account,
        accountMetadata,
        "SalesAndMarketingTourFinish",
        userData?.sub,
        JSON.parse(accountDetails?.metadata)
      )
    );
    let node = window.document.querySelector(
      "[data-tour = 'tour__funnelVisual']"
    );
    node?.scrollIntoView({ block: "center", inline: "nearest" });
    setc(1);
  };
  // const [ActiveKey, setActiveKey] = useState('stage')

  // useEffect(() => {
  //     isTourOpen && setActiveKey(c >=6 ? "SDR" : "stage")
  // },[c,isTourOpen])

  // SDR
  const [HasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isShowModal, setisShowModal] = useState(false);
  const [nextLocation, setnextLocation] = useState(null);
  const [confirmedNavigation, setconfirmedNavigation] = useState(false);

  const [NavigationMessages, setNavigationMessages] = useState("");

  const [nextTab, setnextTab] = useState("");

  const history = useHistory();

  const showModal = (nextLocation) => {
    setisShowModal(true);
    setnextLocation(nextLocation);
    setconfirmedNavigation(true);
  };

  const onLeave = () => {
    setisShowModal(false);
    if (currentTab !== nextTab) {
      setcurrentTab(nextTab);
      setHasUnsavedChanges(false);
    } else {
      history.push(nextLocation);
    }
  };

  const onStay = () => {
    setisShowModal(false);
    setconfirmedNavigation(false);
    document.getElementById("salesAndMarking").click();
  };

  const preventNavigation = (nextLocation) => {
    let location = history.location;

    if (location.pathname === nextLocation.pathname) {
      return false;
    } else {
      setnextTab(currentTab);
    }

    if (confirmedNavigation) {
      return true;
    }

    showModal(nextLocation.pathname);
    return false;
  };

  const changeTab = (e) => {
    setnextTab(e);
    if (HasUnsavedChanges) {
      setisShowModal(true);
    } else {
      setcurrentTab(e);
    }
  };

  const [currentTab, setcurrentTab] = useState("stage");

  const [showSkeleton, setShowSkeleton] = useState(true);

  return (userPermission?.["salesMarketing"] === "preview" &&
    account?.[2] === "admin" &&
    (viewType === "companyadmin" || viewType === "companycontributor") ) ||
    (userPermission?.["salesMarketing"] === "preview" &&
      account?.[2] !== "admin") ? (
    <div>
      <Row
        className="aboutPage"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 8,
          background: "#fff",
          margin: "0 0 15px 0px",
          width: "100%",
          borderRadius: 7,
          alignContent: "center",
        }}
      >
        <Col
          className="details"
          style={{
            padding: "14px 0px 14px 30px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
          span={12}
          lg={12}
          md={12}
          xs={24}
          sm={24}
        >
          <Typography.Text className="title" style={{ fontSize: 29 }}>
            <strong>Sales & Marketing Alignment</strong>
          </Typography.Text>
          <Typography.Text style={{ fontSize: 15 }}>
            It is important that your team speaks a common language when working
            together and with Demand Gen. Please take a moment to review some key
            definitions, customize your funnel stages, and make sure you have
            enough resources to help generate qualified opportunities.
          </Typography.Text>
          <Button
            style={{ borderRadius: 7 }}
            className="take-a-tour-btn non-print"
            onClick={() => setIsTourOpen(true)}
          >
            Take a tour
          </Button>
        </Col>

        <Col span={12} lg={12} md={12} xs={24} sm={24}>
          <Row justify="end">
            <img src={salesAndMarketingImg} alt="" />
          </Row>
        </Col>
      </Row>
      <PreviewInfo
        img={sales_marketing_module_preview}
        text="Upgrade to View"
        centerStyle={{ top: "15%" }}
      />
    </div>
  ) : (
    <div>
      <Row
        className="aboutPage panel-design"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom:'20px',
          background: "#fff",
          margin: "0 0 15px 0px",
          width: "100%",
          borderRadius: 7,
          alignContent: "center",
          padding:'30px 20px'
        }}
      >
        <Col
          className="details"
          style={{
            
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
          span={24}
          lg={24}
          md={24}
          xs={24}
          sm={24}
        >
          <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <div>
          <Typography.Text className="title" style={{ fontSize: 32, color:'#1D2939', marginBottom:'15px', display:'block' }}>
            <strong>Sales & Marketing Alignment</strong>
          </Typography.Text>
          <Typography.Text style={{ fontSize: 16, color:'#475467', lineHeight:'26px' }}>
            It is important that your team speaks a common language when working 
            together and<br/> with Demand Gen. Please take a moment to review some key 
            definitions, customize your funnel stages,<br/> and make sure you have
            enough resources to help generate qualified opportunities.
          </Typography.Text>
          </div>
          <Button
            style={{height:'45px'}}
            onClick={() => {
              TrackClickFunction('demand-gen-sales-and-marketing-take-a-tour-button');
              setIsTourOpen(true)
            }}
            className="take-a-tour-btn non-print primaryButton"
          >
            Take a tour
          </Button>
          </div>
        </Col>

        {/* <Col span={12} lg={12} md={12} xs={24} sm={24}>
          <Row justify="end">
            <img src={salesAndMarketingImg} alt="" />
          </Row>
        </Col> */}
      </Row>

      <Prompt message={preventNavigation} when={HasUnsavedChanges} />
      <Modal
        className="preventNavModal"
        visible={isShowModal}
        closable={false}
        footer={[
          <Button
            key="back"
            onClick={onLeave}
            style={{
              borderRadius: 7,
              height: 40,
            }}
          >
            Leave Anyway
          </Button>,
          <Button key="stay" type="primary" onClick={onStay}>
            Stay
          </Button>,
        ]}
      >
        <Row>
          <Col span={2}>
            <Typography.Title level={4}>
              <QuestionCircleOutlined style={{ color: "#faad14" }} />
            </Typography.Title>
          </Col>
          <Col span={22}>
            <Typography.Title level={5}>
              Are you sure you want to leave?
            </Typography.Title>
            <Typography.Text type="secondary">
              {NavigationMessages?.length > 0
                ? NavigationMessages
                : "You have unsaved changes on this page. Leaving this page without saving may result in your changes being lost."}
            </Typography.Text>
          </Col>
        </Row>
      </Modal>

      <div style={{ background: "#fff", borderRadius: 7 }} className="panel-design">
        <Tabs centered activeKey={currentTab} onChange={changeTab} className="general-tab first-tab">
          <Tabs.TabPane
            key="stage"
            tab={
              <span>
                <strong>Stage Configuration</strong>
              </span>
            }
          >
              <>
                {currentTab === "stage" &&
                  <DefaultStages
                isLoading={isLoading}
                getStagesLoader={getStagesLoader}
                tableData={allStageData}
                otherTableData={otherStageData}
                settableData={settableData}
                salesAndMarketingStages={salesAndMarketingStages}
                userPermission={userPermission}
                onDisableStage={onDisableStage}
                isTourOpen={isTourOpen}
                currentTourStep={c}
                dispatch={dispatch}
                customAvailableMappings={customAvailableMappings}
                hasAnyCRMConnected={hasAnyCRMConnected}
                defaultAvailableStages={defaultAvailableStages}
                crmConnections={crmConnections}
                customSavedMappings={customSavedMappings}
                viewType={viewType}
                HasUnsavedChanges={HasUnsavedChanges}
                setHasUnsavedChanges={setHasUnsavedChanges}
              />}

              {/* <CustomStages
                                getStagesLoader={getStagesLoader}
                                isLoading={isLoading}
                                form={form}
                                onDisableStage={onDisableStage}
                                dispatch={dispatch}
                                tableCustomData={tableCustomData}
                                setaddNewStageModal={setaddNewStageModal}
                                addNewStageModal={addNewStageModal}
                                userPermission={userPermission}
                                fiscalYearList = {fiscalYearList}
                                salesAndMarketingStages={salesAndMarketingStages}
                                viewType = {viewType}
                                account = {account}
                                customAvailableMappings={customAvailableMappings}
                                crmConnections = {crmConnections}
                                customSavedMappings={customSavedMappings}
                                hasAnyCRMConnected={hasAnyCRMConnected}
                                MappingsDataLoader={MappingsDataLoader}
                            /> */}
            </>
          </Tabs.TabPane>
          {((userPermission?.["SDRMethodology.CapacityCalculator"] &&
            userPermission?.["SDRMethodology.CapacityCalculator"] !==
              "hidden") ||
            (account?.[2] === "admin" && (viewType !== "companyadmin" || viewType !== "companycontributor") )) && (
            <TabPane
              tab={
                <span data-tour="tour__capacityCalculator">
                  <strong>SDR Capacity Calculator</strong>
                </span>
              }
              key="1"
            >
              <SdrCapacityCalculator
                showSkeleton={showSkeleton}
                skeletonFlag={setShowSkeleton}
                HasUnsavedChanges={HasUnsavedChanges}
                setHasUnsavedChanges={setHasUnsavedChanges}
                setNavigationMessages={setNavigationMessages}
                currentTab={currentTab}
              />
            </TabPane>
          )}
          {((userPermission?.["SDRMethodology.Compensation"] &&
            userPermission?.["SDRMethodology.Compensation"] !== "hidden") ||
            (account?.[2] === "admin" && (viewType !== "companyadmin" || viewType !== "companycontributor"))) && (
            <TabPane
              tab={
                <span data-tour="tour__compensationModel">
                  <strong>SDR Compensation Model</strong>
                </span>
              }
              key="2"
              currentTab={currentTab}
            >
              <SdrCompensationModal
                isLoading={isLoading}
                HasUnsavedChanges={HasUnsavedChanges}
                setHasUnsavedChanges={setHasUnsavedChanges}
                setNavigationMessages={setNavigationMessages}
                currentTab={currentTab}
              />
            </TabPane>
          )}

          {((userPermission?.["SDRMethodology.Tracker"] &&
            userPermission?.["SDRMethodology.Tracker"] !== "hidden") ||
            (account?.[2] === "admin" && (viewType !== "companyadmin" || viewType !== "companycontributor") )) && (
            <TabPane
              tab={
                <span data-tour="tour__meetingTracker">
                  <strong>SDR Meeting & Opportunity Tracker</strong>
                </span>
              }
              key="3"
              currentTab={currentTab}
            >
              <SdrMeetingAndOpportunityTracker
                showSkeleton={showSkeleton}
                skeletonFlag={setShowSkeleton}
              />
            </TabPane>
          )}
        </Tabs>
      </div>

      <Tour
        steps={steps}
        accentColor={"#0F61DB"}
        isOpen={isTourOpen}
        onRequestClose={() => {
          setIsTourOpen(false);
          updateTour();
        }}
        rounded={5}
        scrollDuration={100}
        disableInteraction={true}
        onAfterOpen={(_) => (document.body.style.overflowY = "hidden")}
        onBeforeClose={(_) => (document.body.style.overflowY = "auto")}
        lastStepNextButton={
          <Button
            type="primary"
            onClick={() => {
              updateTour();
            }}
          >
            Get Started
          </Button>
        }
        disableFocusLock={true}
        closeWithMask={false}
        getCurrentStep={(curr) => setc(curr + 1)}
        startAt={0}
      />
    </div>
  );
};

export default SalesAndMarketing;
