import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, Input, Row } from 'antd';
import { connect } from 'react-redux';
import { login } from 'store/actions/loginAction';
import backgroundImg from 'assets/Images/login_signup_background.svg';
import Logo from 'commons/Logo';
import { emailPattern } from 'utility/Constants';
import { Auth } from 'aws-amplify';
import { jwtDecode } from 'jwt-decode';

class Login extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      sso: false,
      userName: '',
      password: '',
      loading: false,
      feedback: props.history.location.state
        ? props.history.location.state.feedback
        : '',
      error: '',
    };
  }
  
  async doAuthChallenge(email, challenge) {
    this.setState({ sso: true });
    var decoded = jwtDecode(challenge);
    if (decoded.origin || decoded.user_metadata.origin) {
      localStorage.setItem('origin', decoded.origin || decoded.user_metadata.origin);
    }
    const user = await Auth.signIn(email);
    localStorage.setItem(
      'cognitoUser',
      JSON.stringify({
        username: email,
        session: user.Session,
      })
    );
    localStorage.setItem('XPJWT', challenge);
    const session = await Auth.sendCustomChallengeAnswer(user, challenge);
    for (let [key, value] in session.storage) {
      localStorage.setItem(key, value);
    }
    await user.setSignInUserSession(await Auth.currentSession());
    Auth.currentAuthenticatedUser()
      .then((user) => console.log(user))
      .catch((err) => console.log(err));

    try {
      this.props.updateLogin(
        session.signInUserSession.accessToken,
        session.signInUserSession,
        {
          accessToken: session.signInUserSession.accessToken,
          username:
            session.signInUserSession.idToken.payload['cognito:username'],
          email: session.signInUserSession.idToken.payload.email,
        }
      );
    } catch (error) {
      console.log('Error: ', error);
      this.setState({ error: error.message });
    }
  }
  componentDidMount() {
    console.log(`Referrer: ${document.referrer}`);
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const challenge = urlParams.get('challenge');
    if (email && challenge) {
      try {
        this.doAuthChallenge(email, challenge);
      } catch (error) {
        console.log('Challenge error: ', error);
        this.setState({ error: error.message });
      }
    }
  }
  componentDidUpdate() {
    window.localStorage.setItem('version', process.env.REACT_APP_VERSION);
  }

  handleState = (name, value) => {
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    this.setState(
      {
        loading: true,
        error: '',
      },
      () => {
        // Parse the query string
        const queryParams = new URLSearchParams(window.location.search);

        // Read the value of the 'redirect_url' query parameter
        const redirectUrl = queryParams.get('redirect_url');

        console.log("queryParams",redirectUrl);
        
        this.props
          .loginData(e.userName, e.password)
          .then((response) => {
            Auth.currentAuthenticatedUser().catch((err) => console.log(err));
            if (response?.challengeName === 'NEW_PASSWORD_REQUIRED') {
              this.props.history.push({
                pathname: `/create-password`,
                state: {
                  userAttributes: response?.challengeParam?.userAttributes,
                  oldPassword: e.password,
                },
              });
            }
            this.setState(
              {
                loading: false,
              },
              () => this.props.history.push(redirectUrl)
            );

            
          })
          .catch((error) => {
            if (error?.message === 'User is not confirmed.') {
              this.props.history.push({
                pathname: `/confirm_sign_up`,
                state: { feedback: '', userName: e.userName },
              });
            }
            console.log('error signing in:', error);
            this.setState({
              error: error.message,
              loading: false,
              userName: '',
              password: '',
            });
          });
      }
    );
  };

  render() {
    const { email, password, loading, error, feedback } = this.state;
    const { history } = this.props;

    
    return (
      <Row justify="center" align="middle">
        <Col xs={0} md={0} lg={6} style={{ height: '100%' }}>
          <img
            src={backgroundImg}
            alt="background"
            style={{ height: '100vh', minHeight: 580 }}
          />
        </Col>
        <Col xs={24} md={24} lg={18} style={{ transform: 'scale(0.88)' }}>
          <Row
            justify="center"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              height: '100vh',
              minHeight: 580,
            }}
          >
            {!this.state.sso && (
              <Card
                className="card-container"
                style={{ border: 'none', width: '437px' }}
              >
                <div style={{ marginBottom: 35 }}>
                  <Logo />
                </div>
                {feedback && (
                  <Alert
                    style={{ marginBottom: '10px' }}
                    type="success"
                    showIcon={false}
                    message={feedback}
                    banner
                  />
                )}
                {error !== '' && (
                  <Alert
                    style={{ marginBottom: '10px' }}
                    type="error"
                    showIcon={false}
                    message={error}
                    banner
                  />
                )}
                <Form
                  labelCol={{ xs: 24 }}
                  requiredMark={false}
                  layout="vertical"
                  name="normal_login"
                  className="login-form"
                  onFinish={(e) => this.handleSubmit(e)}
                >
                  <Form.Item
                    label={
                      <small>
                        {' '}
                        <strong
                          style={{
                            fontSize: '18px',
                          }}
                        >
                          Email
                        </strong>
                      </small>
                    }
                    labelCol={{ span: 24 }}
                    initialValue={email}
                    name="userName"
                    rules={[
                      { required: true, message: 'Please input your Email!' },
                      {
                        pattern: emailPattern,
                        message: 'Please provide valid Email!',
                      },
                    ]}
                  >
                    <Input
                      autoFocus
                      value={email}
                      onChange={(e) =>
                        this.handleState(e.target.name, e.target.value)
                      }
                      id="userName"
                      key="userName"
                      name="userName"
                      placeholder="Your email id"
                      disabled={loading}
                      tabIndex={1}
                    />
                  </Form.Item>

                  <Form.Item
                    labelCol={{ span: 24 }}
                    label={
                      <Row
                        className="remember_forget_pass"
                        style={{ margin: 0 }}
                      >
                        <Col xs={12}>
                          <small>
                            <strong
                              style={{
                                fontSize: '18px',
                              }}
                            >
                              Password
                            </strong>
                          </small>
                        </Col>
                        <Col xs={12} style={{ textAlign: 'end' }}>
                          <Button
                            type="link"
                            size="small"
                            onClick={() => history.push('/forgot-password')}
                            disabled={loading}
                            tabIndex={3}
                            style={{
                              color: '#0F61DB',
                              fontSize: '16px',
                              fontWeight: 700,
                            }}
                          >
                            Forgot your password?
                          </Button>
                        </Col>
                      </Row>
                    }
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Password!',
                      },
                    ]}
                  >
                    <Input.Password
                      className=""
                      value={password}
                      id="password"
                      key="password"
                      name="password"
                      type="password"
                      placeholder="Your password"
                      disabled={loading}
                      tabIndex={2}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Row justify="space-between" align="middle">
                      <Col span={12}>
                        <Button
                          type="link"
                          size="small"
                          block
                          onClick={() => history.push('/signup')}
                          disabled={loading}
                          style={{ textAlign: 'left' }}
                          tabIndex={4}
                        >
                          <span
                            style={{
                              color: '#0F61DB',
                              fontWeight: '600',
                              fontSize: '18px',
                            }}
                          >
                            Create Account
                          </span>
                        </Button>
                      </Col>
                      <Col span={10}>
                        <Button
                          type="primary"
                          size="small"
                          htmlType="submit"
                          block
                          loading={loading}
                          tabIndex={5}
                        >
                          <span style={{ fontSize: '18px' }}>Login</span>
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>

                {/* <Divider plain="true" style={{
                  margin: "0px !important", color: "#A9A9A9"
                }}>or</Divider>

                <Button type="primary" block style={{ color: "white", backgroundColor: "#303030" }} > <img src={googleImg} alt="Google" style={{ height: "20px", paddingRight: "10px" }} /> Continue with Google</Button> */}
              </Card>
            )}
            {this.state.sso && (
              <Card className="card-container user-login-card">
                <div style={{ marginBottom: 35 }}>
                  <Logo />
                </div>
                <h3>Logging in...</h3>
              </Card>
            )}
            {this.state.error && (
              <Card className="card-container user-login-card">
                <h3>We had a glitch...</h3>
                <p>
                  Please contact <a href="mailto:support@xfactor.io">support</a>{' '}
                  or try again.
                </p>
                <p>Error: {this.state.error}</p>
              </Card>
            )}
            <div>
              <p
                style={{
                  color: '#646C79',
                  fontSize: '16px',
                  textAlign: 'center',
                  fontWeight: '500',
                  userSelect: 'none',
                  marginBottom: '0px',
                }}
              >
                Version{' '}
                {process.env.REACT_APP_VERSION
                  ? process.env.REACT_APP_VERSION
                  : '0.1.0'}
              </p>
              <p
                style={{
                  fontSize: '16px',
                  color: '#646C79',
                  textAlign: 'center',
                }}
              >
                &copy; {new Date().getFullYear()} XFactor.io copyright all
                rights reserved.
              </p>
            </div>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    prop: state.prop,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginData: (userName, password) => dispatch(login(userName, password)),
    updateLogin: (token, userData) =>
      dispatch({
        type: 'UPDATE_LOGIN',
        payload: {
          accessToken: userData.accessToken,
        },
        data: userData,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
