export const listbaselinetric = /* GraphQL */ `
  query ListBaselineMetrics(
    $filter: ModelBaselineMetricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBaselineMetrics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        avgDealSize
        goal
        account
        mrktSourcePercent
        updatedAt
        updatedBy
        createdAt
        fiscalYearId
      name
      goal
      avgDealSize
      mrktSourcePercent
      updatedBy
      account
      mrkt_year_won_leads
      sales_year_won_leads
      budget_allocation
      mrkt_qtr_won_leads
      sales_qtr_won_leads
      mrkt_qtr_budget_allocation
      sales_qtr_budget_allocation
      segment_type
      createdAt
      updatedAt
        segment_type
        funnelConfigs {
          items {
            baselineMetricId
            createdAt
            account
            id
            name
            stages {
              items {
                conversion
                count
                createdAt
                funnelConfigID
                account
                id
                name
                order
                updatedAt
              }
            }
          }
        }
      }
    }
  }
`;

export const listBudgetCustom = /* GraphQL */ `
  query ListBudgets(
    $filter: ModelBudgetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBudgets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        account
        active
        budget
        budget_allocation
        campaigns {
          items {
            campaign {
              channel
              createdAt
              id
              name
              updatedAt
              vendor
              metadata
              campaign_details {
                items {
                  campaign_months {
                    items {
                      budget
                      campaign_detail_id
                      createdAt
                      id
                      leads
                      metadata
                      month
                      updatedAt
                      weight
                      weight_lead
                    }
                  }
                  campaign_id
                  createdAt
                  date_end
                  date_start
                  id
                  metadata
                  updatedAt
                }
              }
            }

            account

            id
          }
        }
        id
        name
        year
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCourseCustom = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        slug
        description
        featuredimage
        attachments
        short_description
        id
        categories {
          items {
            courseCategory {
              id
              name
            }
            id
          }
        }
        videos {
          items {
            id
            courseId
            name
            title
            description
            duration
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;

export const listCategoryCoursesCustom = /* GraphQL */ `
  query MyQuery {
    listCourseCategorys {
      items {
        courses {
          items {
            course {
              attachments
              description
              createdAt
              featuredimage
              short_description
              id
              name
              slug
              updatedAt
              videos {
                items {
                  id
                  courseId
                  name
                  title
                  description
                  duration
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
        }
        name
        updatedAt
        id
        createdAt
      }
    }
  }
`;

export const listBudgetCampaign = /* GraphQL */ `
  query ListBudgetCampaigns(
    $filter: ModelBudgetCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBudgetCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        budget {
          id
        }
        campaign {
          id
        }
      }
      nextToken
    }
  }
`;

export const listFiscalYearsListCustom = /* GraphQL */ `
  query ListFiscalYears($filter: ModelFiscalYearFilterInput) {
    listFiscalYears(filter: $filter) {
      items {
        account
        budget
        createdAt
        id
        updatedAt
        year
        baselineMetrics {
          items {
            account
            avgDealSize
            budget_allocation
            createdAt
            fiscalYearId
            goal
            id
            mrktSourcePercent
            name
            updatedAt
            updatedBy
            segment_type
            funnelConfigs {
              items {
                account
                baselineMetricId
                createdAt
                id
                name
                updatedAt
                stages {
                  items {
                    account
                    conversion
                    count
                    createdAt
                    funnelConfigID
                    id
                    name
                    order
                    updatedAt
                    salesAndMarketingAlignmentID
                    salesAndMarketingAlignment {
                      id
                      name
                      disabled
                      isDefault
                      isLast
                      order
                      showPipelineCoverage
                    }
                  }
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;


export const getFiscalYearCustom = /* GraphQL */ `
query GetFiscalYear($id: ID!) {
  getFiscalYear(id: $id) {
    id
    account
    year
    budget
    baselineMetrics {
      items {
        id
        fiscalYearId
        name
        goal
        avgDealSize
        mrktSourcePercent
        updatedBy
        account
        budget_allocation
        createdAt
        updatedAt
        segment_type
        funnelConfigs {
          items {
            account
            baselineMetricId
            createdAt
            id
            name
            updatedAt
            stages {
              items {
                account
                conversion
                count
                createdAt
                funnelConfigID
                id
                name
                order
                updatedAt
              }
            }
          }
        }
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}`;

export const listPropertyPackages = /* GraphQL */ `
  query ListPropertiesPackages(
    $filter: ModelPropertiesPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertiesPackages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        properties {
          items {
            value
            key
            type
            id
          }
          nextToken
        }
        users {
          nextToken
        }
        account {
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listAccountPackages = /* GraphQL */ `
  query ListAccountPackages(
    $filter: ModelAccountPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountPackages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        createdAt
        updatedAt
        packages {
          items {
            propertiespackages {
              id
              name
              properties {
                items {
                  value
                  key
                  type
                }
              }
            }
            sortOrder
            id
          }
        }
      }
      nextToken
    }
  }
`;

export const listUserPackages = /* GraphQL */ `
  query ListUserPackages(
    $filter: ModelUserPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPackages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        packages {
          items {
            id
            propertiespackages {
              id
              name
              properties {
                items {
                  value
                  key
                  type
                }
              }
            }
            sortOrder
          }
        }
      }
      nextToken
    }
  }
`;

export const getBudgetCustom = /* GraphQL */ `
  query GetBudget($id: ID!) {
    getBudget(id: $id) {
      id
      account
      name
      active
      year
      budget
      budget_allocation
      BaselineMetricID
      lockedMonths
      campaigns {
        items {
          campaign {
            channel
            campaign_details {
              items {
                campaign_months {
                  items {
                    budget
                    campaign_detail_id
                    createdAt
                    id
                    verified_budget
                    verified_leads
                    leads
                    metadata
                    month
                    updatedAt
                    weight
                    weight_lead
                  }
                }
                campaign_id
                createdAt
                date_end
                date_start
                id
                metadata
                updatedAt
              }
            }
            createdAt
            id
            name
            updatedAt
            vendor
            metadata
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const getFunnelConfigCustom = /* GraphQL */ `
  query GetFunnelConfig($id: ID!) {
    getFunnelConfig(id: $id) {
      id
      baselineMetricId
      name
      account
      createdAt
      updatedAt
      stages {
        items {
          id
          funnelConfigID
          order
          name
          conversion
          count
          account
          salesAndMarketingAlignmentID
          salesAndMarketingAlignment {
            id
            account
            name
            disabled
            isDefault
            isLast
            showPipelineCoverage
            order
            howitcomes
            thingstokeepinmind
            definition
            stageType
            internalStage
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;