import React, { Component } from "react";
import { DollarCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Col, InputNumber, message, Row, Slider, Tooltip, Typography } from "antd";

class RevenueTextField extends Component {
  handleSlider = (value) => {
    if (value < 101) {
      this.setState(
        {
          [this.props.name]: value,
        },
        () => this.props.onChange(this.props.name, value)
      );
    } else {
      this.setState(
        {
          [this.props.name]: 1,
        },
        () => {
          this.props.onChange(this.props.name, 1);
          message.warning("Please enter value between 0 to 100");
        }
      );
    }
  };

  render() {
    const {
      uperText,
      lowerText,
      tooltipText,
      isSlider,
      isItalic,
      style,
      notPrefix,
      name,
      value,
      onChange,
      placeholder,
      minVal,
      maxVal,
      type,
      readOnly,
      disabled,
      onAfterChange,
      marketingSourcedText,
      dollercirclestyle,
      multiSelectedKey
    } = this.props;

    
    return (
      <div style={style}>
        <Typography className="text_label" style={{color:'#1D2939', marginBottom:'5px', fontWeight:500}}>
          {uperText}{" "}
          {tooltipText && (
            <Tooltip placement="top" title={tooltipText}>
              <InfoCircleOutlined
                className={`optional_icon ${
                  marketingSourcedText ? "marketing-sourced-info-icon" : ""
                } non-print` }
                style={{ float: "right" }}
              />
            </Tooltip>
          )}{" "}
        </Typography>
        {!isSlider ? (
          <div>
            {!notPrefix && (
              <DollarCircleOutlined
                className="optional_icon input-num-icon"
                style={
                  dollercirclestyle
                    ? dollercirclestyle
                    : { position: "absolute", zIndex: 12, top: 43, left: 20,fontSize:18,marginTop:"6px"  }

                   
                }
              />
            )}
            <InputNumber
              className="goal_textfield general-input"
              size="large"
              placeholder={placeholder}
              name={name}
              readOnly={readOnly || false}
              value={value}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              type={type || "text"}
              disabled={disabled || multiSelectedKey || false}
              onChange={(e) => onChange(Math.floor(e))}
              style={{
                padding: notPrefix || "0 0 0 17px ",
                width: "100%",
                color: disabled && "black",
              }}
            />
          </div>
        ) : (
          <Row className="marketing-slider" style={{padding: 0}}>
            <Col
              span={18}
              md={18}
              lg={18}
              sm={18}
              xs={18}
              style={{ border: "1px solid #DEDEDE", borderRadius: 7 }}
            >
              <Slider
                min={minVal}
                max={maxVal}
                value={value}
                tooltipVisible={false}
                style={{ border: "1px solid #00000", paddingTop: 8, marginLeft: 16, marginRight: 16 }}
                name={name}
                onChange={onChange && this.handleSlider}
                disabled={disabled || multiSelectedKey || false}
                onAfterChange={onAfterChange}
              />
            </Col>
            <Col className="pl-0" span={6} lg={6} md={6} sm={6} xs={6} style={{ paddingLeft: 5 }}>
              <InputNumber
                className="goal_textfield"
                onChange={this.handleSlider}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
                min={minVal}
                max={maxVal}
                value={value}
                disabled={disabled || false}
                style={{
                  width: "100%",
                  paddingTop: 5,
                  color: "#7F56D9",
                  fontWeight: 500,
                }}
              />
            </Col>
          </Row>
        )}

        {lowerText && !isItalic && (
          <Typography className="text_label text_bottom">
            {lowerText}
          </Typography>
        )}
        {lowerText && isItalic && (
          <Typography className="text_label text_bottom">
            <i>{lowerText}</i>
          </Typography>
        )}
      </div>
    );
  }
}

export default RevenueTextField;
