import React, { useEffect, useState } from "react";
import blog_image1 from "assets/Images/blog_image1.png";
import { API } from "aws-amplify";
import { Col, Row, Skeleton } from "antd";
import Parser from "rss-parser";
import _ from "lodash";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import {TrackClickFunction} from '../../commons/HeapTrack/TrackClickFunction'

const Blogs = (props) => {
  const [amBlogFeed, set4amBlogFeed] = useState(); // eslint-disable
  const [loader, setLoader] = useState(false); // eslint-disable
  const feedArray = [];

  useEffect(() => {
    (async () => {
      try {
        setLoader(true);
        const path = `/blog`;
        try {
          var result = await API.get("exploricsREST", path);
          let parser = new Parser();
          const feed = await parser.parseString(result);
          feed.items.forEach((item) => {
            var div = document.createElement("div");
            div.innerHTML = item?.["content:encoded"];
            var firstImage = div.getElementsByTagName("img")[0];
            var imgSrc = firstImage ? firstImage.src : "";
            feedArray.push({
              title: item.title,
              url: imgSrc ? imgSrc : blog_image1,
              description: item.contentSnippet,
              link: item.link,
            });
          });
          set4amBlogFeed(_.chunk(feedArray, 3));
          setLoader(false);
        } catch (e) {
          console.log(e);
          return { error: "Failed getting blogs" };
        }
      } catch (e) {
        setLoader(false);
        console.log("error", e);
      }
    })();
  }, []); // eslint-disable-line

  return (
    <div
      className="site-card-wrapper"
      style={{ background: "#fff", padding:'20px 15px', height:"100%"}}
    >

      <div
        className="coaching-title titleText"
        style={{ padding:0, marginBottom:'20px'}}
      >
        Demand Generation Blogs
      </div>

    { loader ? <div>
      <Skeleton active />
    </div> :
      <Carousel
        showArrows={true}
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
      >
        {amBlogFeed && amBlogFeed.length > 0
          ? amBlogFeed?.map((amBlogFeedVal, index) => {
            
              return (
                <Row
                  gutter={[16, 16]}
                  style={{ background: "#fff" }}
                  key={index}
                >
                  <div style={{ display: "flex" , overflow: 'auto'}}>
                    {amBlogFeedVal?.[0] && (
                      <Col
                        span={8}
                        style={{ padding: '0 18px 0 10px' }}
                        xs={24}
                        md={12}
                        lg={12}
                      >
                        <div style={{ display: "inline-flex", border:'1px solid #D0D5DD' }}>
                          <div style={{ display: "inline-block", flex:1 }}>
                            <img src={amBlogFeedVal?.[0].url} style={{height: '200px',objectFit: 'contain'}} alt="" />
                          </div>
                          <div
                            style={{
                              display: "inline-block",
                              paddingLeft: 15,
                              paddingRight: 10,
                              flex:1,
                              background: '#f9fafc',
                              padding: '12px 12px 12px 10px'
                            }}
                          >
                            <div
                              className="blog-title-link"
                              onClick={() => {
                                TrackClickFunction('demand-gen-blog-title',{blogTitle: amBlogFeedVal?.[0].title});
                                window.open(amBlogFeedVal?.[0].link, "_blank");
                              }}
                              style={{paddingRight:'5px', lineHeight:'24px', paddingTop:0, color:"#1D2939"}}
                            >
                              {amBlogFeedVal?.[0].title}
                            </div>
                            <div className="card-description-dashboard bodyText" style={{paddingRight:'5px'}}>
                              {amBlogFeedVal?.[0].description}
                            </div>
                          </div>
                        </div>
                      </Col>
                    )}

                    {amBlogFeedVal?.[1] && (
                      <Col
                        span={8}
                        style={{ padding: '0 18px 0 10px' }}
                        xs={24}
                        md={12}
                        lg={12}
                      >
                        <div style={{ display: "inline-flex", border:'1px solid #D0D5DD' }}>
                          <div style={{ display: "inline-block", flex:1 }}>
                            <img src={amBlogFeedVal?.[1].url} alt="" style={{height: '200px',objectFit: 'contain'}}/>
                          </div>
                          <div
                            style={{
                              display: "inline-block",
                              paddingLeft: 15,
                              paddingRight: 10,
                              flex:1,
                              background: '#f9fafc',
                              padding: '12px 12px 12px 10px'
                            }}
                          >
                            <div
                              className="blog-title-link"
                              onClick={() => {
                                TrackClickFunction('demand-gen-blog-title',{blogTitle: amBlogFeedVal?.[1].title});
                                window.open(amBlogFeedVal?.[1].link, "_blank");
                              }}
                              style={{paddingRight:'5px', lineHeight:'24px', paddingTop:0, color:"#1D2939"}}
                            >
                              {" "}
                              {amBlogFeedVal?.[1].title}
                            </div>
                            <div className="card-description-dashboard" style={{paddingRight:'5px'}}>
                              {amBlogFeedVal?.[1].description}
                            </div>
                          </div>
                        </div>
                      </Col>
                    )}

                    {amBlogFeedVal?.[2] && (
                      <Col
                        span={8}
                        style={{ padding: '0 18px 0 10px' }}
                        xs={24}
                        md={12}
                        lg={12}
                      >
                        <div style={{ display: "inline-flex",border:'1px solid #D0D5DD' }}>
                          <div style={{ display: "inline-block", flex:1 }}>
                            <img src={amBlogFeedVal?.[2].url} alt="" style={{height: '200px',objectFit: 'contain'}}/>
                          </div>
                          <div
                            style={{
                              display: "inline-block",
                              paddingLeft: 15,
                              paddingRight: 10,
                             flex:1,
                             background: '#f9fafc',
                              padding: '12px 12px 12px 10px'
                            }}
                          >
                            <div
                              className="blog-title-link"
                              onClick={() => {
                                TrackClickFunction('demand-gen-blog-title',{blogTitle: amBlogFeedVal?.[2].title});
                                window.open(amBlogFeedVal?.[2].link, "_blank");
                              }}
                              style={{paddingRight:'5px', lineHeight:'24px', paddingTop:0, color:"#1D2939"}}
                            >
                              {" "}
                              {amBlogFeedVal?.[2].title}
                            </div>
                            <div className="card-description-dashboard" style={{paddingRight:'5px'}}>
                              {amBlogFeedVal?.[2].description}
                            </div>
                          </div>
                        </div>
                      </Col>
                    )}
                  </div>
                </Row>
              );
            })
          : !loader && "Blogs not available"}
      </Carousel>
    }
    </div>
  );
};

export default Blogs;
