import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Form,
  Input,
  Drawer,
  Select,
  Upload,
  message,
} from "antd";
import {
  LoadingOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import {
  createNewCourse,
  getCourseCategoryList,
  setServerError
} from "store/actions/coachingAction";
import { Storage, Auth } from "aws-amplify";
import { coachingImageTypes } from "../../utility/Constants";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import { downloadBlob } from "./../../utility/context/CommonMethods";
import addMoreIcon from "assets/Images/icn_add_more.png";
import deleteIcon from "assets/Images/ic_delete.svg";
import icn_video from "assets/Images/icn_video.svg";
import icn_image from "assets/Images/icn_image.svg";
import icn_attachment from "assets/Images/icn_attachment.svg";
import Player from "pages/Coaching/Player";
import slugify from 'react-slugify';

const CreateCourse = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.loginReducer.groups);
  const { CourseCategoryList } = useSelector((state) => state.coachingReducer);
  const { Option } = Select;
  const { TextArea } = Input;
  const { Dragger } = Upload;
  const serverError = useSelector((state) => state.coachingReducer.serverError);

  const mdParser = new MarkdownIt({
    html: false,
    linkify: true,
    typographer: true,
  });

  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [imageFileKey, setImageFileKey] = useState();
  const [attachmentFileKey, setAttachmentFileKey] = useState();
  const [currentUserInfo, setCurrentUserInfo] = useState();

  const handleOk = (value) => {
    dispatch(createNewCourse(value)).then(res => {
    setLoading(false)
    }).catch(err => {
    setLoading(false)
    });
  //  props.handleCancel();
  };

  const getCurrentUserInfo = async () => {
    const currentUserVal = await Auth.currentUserInfo();
    setCurrentUserInfo(currentUserVal?.id);
  };

  useEffect(() => {
    getCurrentUserInfo();
  }, []);

  useEffect(() => {
    if (serverError === "success") {
      form.resetFields();
      props.handleCancel();
      dispatch(setServerError(""))
    }
  }, [serverError]); // eslint-disable-line

  const onSubmit = () => {
    setLoading(true)
    let catagories = form.getFieldsValue(["course_categories"])['course_categories']
    // eslint-disable-next-line array-callback-return
    catagories = catagories.filter((item) => {
      item = item.trim();
      if (item.length > 0) {
        return true
      }
    })
    form.setFieldsValue({ course_categories: catagories })
    
    let name = form.getFieldsValue(["course_name"])['course_name']? form.getFieldsValue(["course_name"])['course_name'].trim() : ""
    if (name.length === 0) {
      form.setFieldsValue({ course_name: name })
    }
    let desc = form.getFieldsValue(["short_description"])['short_description']? form.getFieldsValue(["short_description"])['short_description'].trim() : ""
    if (name.length === 0) {
      form.setFieldsValue({ short_description: desc })
    }
    form
      .validateFields()
      .then((values) => {
        // form.resetFields();
        setImageUrl("");
        setAttachmentArray([]);
        const formatedValues = {
          ...values,
          account: account[0],
          slug: slugify(values.course_name),
          imageFileKey,
          attachmentFileKey,
        };
        if (isUpdateMode) {
          formatedValues.id = props.selectedCourse.id;
          formatedValues.previousCategories = props.selectedCourse.categories;
          formatedValues.previousCourseVideos =
            props.selectedCourse?.videos?.items;
        }
        handleOk(formatedValues);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
    setLoading(false)

      });
  };

  const beforeUploadImage = (file) => {
    if (!coachingImageTypes.includes(file.type)) {
      message.error(`Allowed file types: ${coachingImageTypes.toString()}`);
    }
    if (coachingImageTypes.includes(file.type)) setimgFile(file);
    return false;
  };
  var currentVideo;
  const beforeUploadAttachment = (videoIndex) => {
    currentVideo = videoIndex;
    return false;
  };

  useEffect(() => {
    dispatch(getCourseCategoryList());
  }, [dispatch]);

  const uploadButtonImg = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <img src = {icn_image} alt = "Feature" />}
      <div style={{ marginTop: 8 }}>Upload a feature image for course</div>
    </div>
  );

  const handleChangeCreateImage = async (info) => {
    const file = imgfile;
    const newFilename = new Date().valueOf();
    try {
      setLoadingImg(true);
      const uploadedFileKeyImg = await Storage.put(
        `${file.name.replace(
          /\.[^/.]+$/,
          ""
        )}_${newFilename}.${file.name.replace(/(.*)\./g, "")}`,
        file,
        {
          level: "protected",
          contentType: file.type, // contentType is optional
        }
      );
      setLoadingImg(false);
      setImageFileKey(`${currentUserInfo}/${uploadedFileKeyImg.key}`);
      const signedURL = await Storage.get(uploadedFileKeyImg.key, {
        level: "protected",
      });
      setImageUrl(signedURL);
    } catch (error) {
      message.error(`Uploading file: ${error}`);
    }
  };

  const [draggerProperty, setDraggerProperty] = useState();
  const [availableFileList, setAvailableFileList] = useState([]);
  const [attachmentArray, setAttachmentArray] = useState([]);
  const [videoArray, setVideoArray] = useState([]);
  const [availableVideoList, setAvailableVideoList] = useState([]);

  useEffect(() => {
    if (props.selectedCourse) {
      const ExistingFileList = [];
      const ExistingVideoList = [];
      const oldFilesArray = [];
      props.selectedCourse?.attachments.map((existingAttchment, index) => { // eslint-disable-line

        const fileInfo = existingAttchment.split("/");
        ExistingFileList.push({
          uniqueId: index,
          name: fileInfo?.[1],
          status: "done",
          fileInfo: existingAttchment,
        });
        oldFilesArray.push(existingAttchment);
      });

      setAttachmentArray(oldFilesArray);

      const dragProps = {
        showUploadList: {
          showDownloadIcon: true,
          downloadIcon: "download ",
          showRemoveIcon: true,
        },
        progress: {
          strokeColor: {
            "0%": "#108ee9",
            "100%": "#87d068",
          },
          strokeWidth: 3,
          format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
        },
      };
      setAvailableFileList(ExistingFileList);
      setAvailableVideoList(ExistingVideoList);
      setDraggerProperty(dragProps);
    }
  }, [props.selectedCourse]);

  const downloadAttachment = async (fileKey) => {
    const fileInfo = fileKey.fileInfo.split("/");
    const result = await Storage.get(fileInfo[1], {
      download: true,
      level: "protected",
      identityId: fileInfo[0],
    });
    downloadBlob(result.Body, fileInfo[1]);
  };

  const onFileChange = async (file1) => {
    const file = file1.file;

    try {
      setLoading(true);

      if (file.status === "removed") {
        await Storage.remove(`${file.name}`, { level: "protected" });

        const valueToRemove = file.fileInfo;
        const filteredItems = attachmentArray.filter(
          (item) => item !== valueToRemove
        );
        setAttachmentArray(filteredItems);
        setAttachmentFileKey(filteredItems);
      } else {
        const uploadedFileKeyAttachment = await Storage.put(
          `${file.name}`,
          file,
          {
            level: "protected",
            contentType: file.type, // contentType is optional
          }
        );
        attachmentArray.push(
          `${currentUserInfo}/${uploadedFileKeyAttachment.key}`
        );

        setAttachmentArray(attachmentArray);
        setAttachmentFileKey(attachmentArray);

        availableFileList.push({
          uniqueId: new Date().getTime(),
          name: file.name,
          status: "done",
          fileInfo: `${currentUserInfo}/${uploadedFileKeyAttachment.key}`,
        });
      }

      setAvailableFileList(availableFileList);
      setLoading(false);
    } catch (error) {
      message.error(`Uploading file: ${error}`);
      setLoading(false);
    }
  };

  const onVideoChange = async (file1) => {
    const file = file1.file;

    let newVideoSet = {
      video_name: "",
      video_duration: "",
    };

    var reader = new FileReader();
    reader.onload = function () {
      var aud = new Audio(reader.result);
      aud.onloadedmetadata = function () {
        const values = [...videoFormList];

        if(values.length > currentVideo) {
          values[currentVideo].video_duration = parseInt(aud.duration);
          setVideoFormList(values);
        } else {
          newVideoSet.video_duration = parseInt(aud.duration);
        }
      };
      reader.readAsArrayBuffer(file);
    };
    reader.readAsDataURL(file);

    try {
      setLoading(true);

      if (file.status === "removed") {
        await Storage.remove(`${file.name}`, { level: "protected" });

        const valueToRemove = file.fileInfo;
        const filteredItems = videoArray.filter(
          (item) => item !== valueToRemove
        );
        setVideoArray(filteredItems);
      } else {
        const uploadedFileKeyAttachment = await Storage.put(
          `${file.name}`,
          file,
          {
            level: "protected",
            contentType: file.type, // contentType is optional
          }
        );
        const values = [...videoFormList];
        if(values.length > currentVideo) {
           values[
          currentVideo
        ].video_name = `${currentUserInfo}/${uploadedFileKeyAttachment.key}`;
          setVideoFormList(values);
          form.setFieldsValue({
            items: values,
           });
        } else {
          newVideoSet.video_name = `${currentUserInfo}/${uploadedFileKeyAttachment.key}`;
          values.push(newVideoSet);
          setVideoFormList(values);
          form.setFieldsValue({
            items: values,
           });
        }
        availableVideoList.push({
          uniqueId: new Date().getTime(),
          name: file.name,
          status: "done",
          fileInfo: `${currentUserInfo}/${uploadedFileKeyAttachment.key}`,
        });
      }
      setLoading(false);
      setAvailableFileList(availableVideoList);
    } catch (error) {
      message.error(`Uploading file: ${error}`);
      setLoading(false);
    }
  };

  const [imgfile, setimgFile] = useState();
  useEffect(() => {
    if (imgfile) handleChangeCreateImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgfile]);

  const [isUpdateMode, setIsUpdateMode] = useState(false);

  const onLoadVideo = [
    {
      video_name: "",
      video_title: "",
      video_description: "",
      video_duration: "",
    },
  ];
  const [videoFormList, setVideoFormList] = useState([]);
  useEffect(() => {
    if (props.selectedCourse) {
      setIsUpdateMode(true);
      const categroyTags = [];
      props.selectedCourse.categories.items.map((categoryVal) => {
        categroyTags.push(categoryVal.courseCategory.id);
        return true;
      });

      if (props.selectedCourse.videos.items.length > 0) {
        const multipleVideos = [];
        props.selectedCourse.videos.items.map((videoVal, index) => {
          multipleVideos.push({
            video_name: videoVal.name,
            video_title: videoVal.title,
            video_description: videoVal.description,
            video_duration: videoVal.duration,
          });
          return true;
        });
        setVideoFormList(multipleVideos);
        form.setFieldsValue({ items: multipleVideos });
      } else {
        setVideoFormList(onLoadVideo);
        form.setFieldsValue({ items: onLoadVideo });
      }

      setAttachmentFileKey(props.selectedCourse.attachments);
      form.setFieldsValue({
        course_name: props.selectedCourse.name,
        short_description: props.selectedCourse.short_description,
        course_categories: categroyTags,
        description: props.selectedCourse.description,
        feature_image: props.selectedCourse.featuredimage,
        attachment: props.selectedCourse.attachments,
      });
      getSignedUrlImg(props.selectedCourse.featuredimage);
    } else {
      setVideoFormList(onLoadVideo);
      form.setFieldsValue({ items: onLoadVideo });
      setIsUpdateMode(false);
      form.setFieldsValue({
        course_name: "",
        short_description: "",
        course_categories: [],
        description: "",
        feature_image: "",
        attachment: "",
        video_duration_0: "",
      });
      setImageUrl("");
      setImageFileKey("");
      setAttachmentFileKey([]);
      setAvailableFileList([]);
      setAvailableVideoList([]);
    } //eslint-disable-next-line

  }, [props.selectedCourse]); // eslint-disable-line

  async function getSignedUrlImg(fileKey) {
    const fileInfo = fileKey.split("/");
    const signedURL = await Storage.get(fileInfo?.[1], {
      level: "protected",
      identityId: fileInfo[0],
    });
    setImageUrl(signedURL);
    setImageFileKey(fileKey);
  }

  const [videoUrl, setVideoUrl] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const playVideo = async (fileKey) => {
    const fileInfo = fileKey.split("/");
    const videoResult = await Storage.get(fileInfo[1], {
      level: "protected",
      identityId: fileInfo[0],
    });
    showModal();
    setVideoUrl(videoResult);
  };

  return (
    <>
      <Player
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        videoUrl={videoUrl}
      />
      <Drawer
        title={isUpdateMode ? <strong>Update Course</strong> : <strong>Create New Course</strong>}
        width={550}
        onClose={props.handleCancel}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80}}
        className='create-new-course'
        maskClosable={false}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
          requiredMark={"optional"}
        >
          <Form.Item
            name="course_name"
            label={<span>Course Name</span>}
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <Input placeholder="Please enter course name" />
          </Form.Item>

          <Form.Item
            name="course_categories"
            label="Course Categories"
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Course Categories"
              optionFilterProp="children"
              mode="tags"
              key="course_categories"
            >
              {" "}
              {CourseCategoryList.listCourseCategorys?.items.map(
                (categroyVal) => {
                  return (
                    <Option key={categroyVal.id}>{categroyVal.name}</Option>
                  );
                }
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name="short_description" 
            label={<span>Short Description</span>}
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <TextArea placeholder="Please enter short description" />
          </Form.Item>

          <Form.Item
            name="description"
            label={<span>Course Description</span>}
            getValueFromEvent={(data) => data.text}
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <MdEditor
              style={{ height: "500px" }}
              renderHTML={(text) => mdParser.render(text)}
            />
          </Form.Item>

          <Form.Item
            name="feature_image"
            label={<span>Feature Image</span>}
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUploadImage}
              accept="image/*"
            >
              {imageUrl && !loadingImg ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{ height: "100%", width: "100%" }}
                />
              ) : (
                uploadButtonImg
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            name="attachment"
            label={<span>Attachments</span>}
            rules={[
              {
                required: false,
                message: "This field can not be empty!",
              },
            ]}
          >
            {isUpdateMode && (
              <Dragger
                multiple={true}
                accept=".doc, .docx, .pdf, .zip, .xls, .xlsx, .ppt"
                beforeUpload={beforeUploadAttachment}
                onDownload={downloadAttachment}
                defaultFileList={availableFileList}
                onChange={onFileChange}
                {...draggerProperty}
              >
                <p className="ant-upload-drag-icon">
                  <img src = {icn_attachment} alt = "Attachment" />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Valid file type: pdf,
                  doc, zip, xls, ppt
                </p>
              </Dragger>
            )}
            {!isUpdateMode && (
              <Dragger
                multiple={true}
                accept=".doc, .docx, .pdf, .zip, .xls, .xlsx, .ppt"
                beforeUpload={beforeUploadAttachment}
                onDownload={downloadAttachment}
                defaultFileList={availableFileList}
                onChange={onFileChange}
                {...draggerProperty}
              >
                <p className="ant-upload-drag-icon">
                <img src = {icn_attachment} alt = "Attachment" />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Valid file type: pdf,
                  doc, zip, xls, ppt
                </p>
              </Dragger>
            )}
          </Form.Item>

          <Form.List name="items" initialValue={videoFormList}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div
                    key={field.key}
                    align="baseline"
                  >
                    <Form.Item
                      name={[field.name, "video_name"]}
                      fieldKey={[field.fieldKey, "video_name"]}
                      label={
                        <span>
                          <span>Video {index + 1}</span>{" "}
                          <span
                            style={{ float: "right", cursor: "pointer" }}
                            onClick={() => remove(field.name)}
                          >
                            <img src={deleteIcon} alt="remove" />
                          </span>
                          {videoFormList[index]?.video_name && (
                            <span
                              title="Play Video"
                              style={{
                                float: "right",
                                cursor: "pointer",
                                paddingRight: 20,
                              }}
                              onClick={() =>
                                playVideo(videoFormList[index]?.video_name)
                              }
                            >
                              <PlayCircleOutlined />
                            </span>
                          )}
                        </span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "This field can not be empty!",
                        },
                      ]}
                    > {" "}
                      <Dragger
                        accept="video/*"
                        beforeUpload={() => beforeUploadAttachment(index)}
                        onDownload={downloadAttachment}
                        onChange={onVideoChange}
                        {...draggerProperty}
                        showUploadList={false}
                      >
                          <span>
                            <p className="ant-upload-drag-icon">
                              <img src = {icn_video} alt = "Video" />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag video to this area to upload
                            </p>
                            {
                              videoFormList[index]?.video_name ? (
                                <span style = {{fontWeight:"bold"}}>
                                  {videoFormList[index]?.video_name.split("/")[1]}
                                </span>):(<p className="ant-upload-hint">
                              Support for a single upload.
                            </p>)
                            }
                          </span>
                        <div
                          className={`video-thumb_${index} thumbnail-section`}
                        ></div>
                      </Dragger>
                    </Form.Item>

                    <Form.Item
                      {...field}
                      name={[field.name, "video_title"]}
                      fieldKey={[field.fieldKey, "video_title"]}
                    >
                      <Input placeholder="Video Title" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "video_description"]}
                      fieldKey={[field.fieldKey, "video_description"]}
                    >
                      <Input placeholder="Video Description" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "video_duration"]}
                      fieldKey={[field.fieldKey, "video_duration"]}
                      hidden
                    >
                      <Input placeholder="Duration" />
                    </Form.Item>
                  </div>
                ))}
                <Form.Item>
                <Button
                    className="secondary-btn"
                    onClick={() => add()}
                    style={{ fontSize: 18, marginRight: 8, width: "100%" }}
                  >
                    <img src={addMoreIcon} alt="add more" />
                    <span style={{ paddingLeft: 5, verticalAlign: "middle" }}>
                      Add More Videos
                    </span>
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
          }}
        >
          <Button
            className="secondary-btn"
            onClick={props.handleCancel}
            style={{ fontSize: 18, marginRight: 8, width: "48%" }}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            type="primary"
            style={{ fontSize: 18, width: "48%" }}
            loading={loading}
          >
            {isUpdateMode ? "Update" : "Save"}
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default CreateCourse;
