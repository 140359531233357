/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Tooltip, Typography, Skeleton } from "antd";
import BudgetPieChart from "../../commons/PieChart";
import BudgetLiquidChart from "../../commons/LiquidChart";
import { nFormatter, numberWithCommas } from "utility/context/CommonMethods";
import { setProspects } from "store/actions/budgetAction";
import { convertReverseArray } from "utility/context/CommonMethods";
import leadAnalysis from "assets/Images/lead_analysis.svg";
import revenueAnalysis from "assets/Images/revenue_analisys.svg";

const BudgetSummary = (props) => {
  const { getBudgetLoader ,nameOfLeadObj} = props
  const [totalRevenuegoal, settotalRevenuegoal] = useState();
  const [totalLeadsGoal, settotalLeadsGoal] = useState();
  const [checkedItems, setCheckedItems] = useState({
    Q1: true,
    Q2: true,
    Q3: true,
    Q4: true,
  });
  const [prospectCount, setProspectCount] = useState(0);
  const dispatch = useDispatch();
  const { BudgetProfile, ChannelCampaignPlannedTotal, CampaignList } =
    useSelector((state) => state.budgetReducer);
  const [plannedLeadImpact, setPlannedLeadImpact] = useState();
  const [plannedBudgetImpact, setPlannedBudgetImpact] = useState();
  const [filteredBudgetAmount, setFilteredBudgetAmount] = useState();
  const [filteredBudgetAmountRemaining, setFilteredBudgetAmountRemaining] =
    useState();
  const { marketingSourcedCount, funnelStages } =
    useSelector((state) => state.revenueReducer);
  const handleChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };
  const [actualRevenuQ, setActaulRevenueQ] = useState();
  const [actualLeadsQ, setActaulLeadsQ] = useState();
  useEffect(() => {
    // filters allocated budget and camapaign total by selected quarters
    const countQuarter = Object.values(checkedItems).filter(function (value) {
      return value === true;
    }).length;

    countQuarter === 4 ? setCheckedAll(true) : setCheckedAll(false);
    const leadImpactQuarter =
      (checkedItems.Q1 ? ChannelCampaignPlannedTotal.lead?.q1['projectedLeads'] : 0) +
      (checkedItems.Q2 ? ChannelCampaignPlannedTotal.lead?.q2['projectedLeads'] : 0) +
      (checkedItems.Q3 ? ChannelCampaignPlannedTotal.lead?.q3['projectedLeads'] : 0) +
      (checkedItems.Q4 ? ChannelCampaignPlannedTotal.lead?.q4['projectedLeads'] : 0);

    setPlannedLeadImpact(leadImpactQuarter);
    const budgetAllocation = BudgetProfile?.budget_allocation
      ? JSON.parse(BudgetProfile.budget_allocation)
      : "";
    const budgetImpactQuarter =
      (checkedItems.Q1 && budgetAllocation?.budget_q1 ? ChannelCampaignPlannedTotal.budget?.q1['projected'] : 0) +
      (checkedItems.Q2 && budgetAllocation?.budget_q2 ? ChannelCampaignPlannedTotal.budget?.q2['projected'] : 0) +
      (checkedItems.Q3 && budgetAllocation?.budget_q3 ? ChannelCampaignPlannedTotal.budget?.q3['projected'] : 0) +
      (checkedItems.Q4 && budgetAllocation?.budget_q4 ? ChannelCampaignPlannedTotal.budget?.q4['projected'] : 0);
    setPlannedBudgetImpact(parseFloat(parseFloat(budgetImpactQuarter).toFixed(2)));


    const budgetProfileTotalQuarter =
      (checkedItems.Q1 ? parseInt(budgetAllocation?.budget_q1) : 0) +
      (checkedItems.Q2 ? parseInt(budgetAllocation?.budget_q2) : 0) +
      (checkedItems.Q3 ? parseInt(budgetAllocation?.budget_q3) : 0) +
      (checkedItems.Q4 ? parseInt(budgetAllocation?.budget_q4) : 0);

    const revenueQuarterActual =
      (checkedItems.Q1
        ? props.actaulRevenueLeadsQuarter?.Q1?.closed_deal_amount
        : 0) +
      (checkedItems.Q2
        ? props.actaulRevenueLeadsQuarter?.Q2?.closed_deal_amount
        : 0) +
      (checkedItems.Q3
        ? props.actaulRevenueLeadsQuarter?.Q3?.closed_deal_amount
        : 0) +
      (checkedItems.Q4
        ? props.actaulRevenueLeadsQuarter?.Q4?.closed_deal_amount
        : 0);
    setActaulRevenueQ(revenueQuarterActual);

    const leadsQuarterActual =
      (checkedItems.Q1 ? props.actaulRevenueLeadsQuarter?.Q1?.deals_count : 0) +
      (checkedItems.Q2 ? props.actaulRevenueLeadsQuarter?.Q2?.deals_count : 0) +
      (checkedItems.Q3 ? props.actaulRevenueLeadsQuarter?.Q3?.deals_count : 0) +
      (checkedItems.Q4 ? props.actaulRevenueLeadsQuarter?.Q4?.deals_count : 0);
    setActaulLeadsQ(leadsQuarterActual);

    setFilteredBudgetAmount(
      isFinite(budgetProfileTotalQuarter) ? budgetProfileTotalQuarter : 0
    );
    setFilteredBudgetAmountRemaining(
      isFinite(budgetProfileTotalQuarter - budgetImpactQuarter)
        ? parseFloat(parseFloat(budgetProfileTotalQuarter - budgetImpactQuarter).toFixed(2))
        : 0
    );

    const totalrevenue = Number((checkedItems.Q1 ? props.quarterlyRevenueGoal?.budget_q1 : 0) + (checkedItems.Q2 ? props.quarterlyRevenueGoal?.budget_q2 : 0) + (checkedItems.Q3 ? props.quarterlyRevenueGoal?.budget_q3 : 0) + (checkedItems.Q4 ? props.quarterlyRevenueGoal?.budget_q4 : 0)).toFixed(0)
    settotalRevenuegoal(totalrevenue)

    const totalleads = Number((checkedItems.Q1 ? props.TotalLeadsGoal?.budget_q1 : 0) + (checkedItems.Q2 ? props.TotalLeadsGoal?.budget_q2 : 0) + (checkedItems.Q3 ? props.TotalLeadsGoal?.budget_q3 : 0) + (checkedItems.Q4 ? props.TotalLeadsGoal?.budget_q4 : 0)).toFixed(0)
    settotalLeadsGoal(totalleads)

    props.setCheckedQuarters(checkedItems)

  }, [checkedItems, ChannelCampaignPlannedTotal, BudgetProfile, CampaignList]); // eslint-disable-line

  // useEffect(() => {
  //   dispatch(getBudget(props.selectedProfile));
  // }, [props.selectedProfile]); // eslint-disable-line
  
  
  useEffect(() => {
    settotalRevenuegoal(Number(props.quarterlyRevenueGoal?.budget_q1 + props.quarterlyRevenueGoal?.budget_q2 + props.quarterlyRevenueGoal?.budget_q3 + props.quarterlyRevenueGoal?.budget_q4).toFixed(0))
    
    settotalLeadsGoal(parseInt(props.TotalLeadsGoal?.budget_q1 + props.TotalLeadsGoal?.budget_q2 + props.TotalLeadsGoal?.budget_q3 + props.TotalLeadsGoal?.budget_q4))
  },[props.quarterlyRevenueGoal, props.TotalLeadsGoal])
  const checkboxes = [
    {
      name: "Q1",
      key: "1stQuarter",
      label: "Q1"
    },
    {
      name: "Q2",
      key: "2ndQuarter",
      label: "Q2",
    },
    {
      name: "Q3",
      key: "3rdQuarter",
      label: "Q3",
    },
    {
      name: "Q4",
      key: "4thQuarter",
      label: "Q4",
    },
  ];

  const getLeadsData = (funnelStages) => {
    let newChartData = [];
    let newConvertData = [...convertReverseArray(funnelStages)];
    newConvertData.map((item, index) => {
      let { name, conversion, id } = item;
      let newValue =
        index === 0
          ? !isFinite(marketingSourcedCount)
            ? 0
            : Number.parseInt(marketingSourcedCount)
          : Number.parseInt(
              (newChartData[index - 1].value * 100) /
                Number.parseInt(item.conversion)
            );
      let setWidth = index === 0 ? 250 : conversion * 10 + 250;
      let obj = {
        id: id,
        text: name,
        percentageValue: conversion,
        value: newValue,
        width: setWidth,
      };
      newChartData.push({ ...obj });
      return 0;
    });
    return [...convertReverseArray(newChartData)];
  };

  useEffect(() => {
    // set prospect count for selected budget profile
    const leadsData = getLeadsData(funnelStages);
    setProspectCount(leadsData[0]?.value);
    dispatch(setProspects(leadsData[0]?.value));
  }, [prospectCount, props.selectedProfile]); // eslint-disable-line

  useEffect(() => {
    const leadsData = getLeadsData(funnelStages);
    setProspectCount(leadsData[0]?.value);
    dispatch(setProspects(leadsData[0]?.value));
  }, [funnelStages]); // eslint-disable-line

  const quarterCheckboxDefault = checkboxes.map((item, index) => (
    <span key={index}>
      <input
        type="checkbox"
        name={item.name}
        checked={checkedItems[item.name]}
        onChange={handleChange}
        style={{ verticalAlign: "middle" }}
      />
      <span style={{ paddingRight: 15, paddingLeft: 2 }}>{item.label}</span>
    </span>
  ));

  const [checkedAll, setCheckedAll] = useState(true);
  const handleAll = (val) => {
    setCheckedAll(!checkedAll);
    setCheckedItems({
      Q1: !checkedAll,
      Q2: !checkedAll,
      Q3: !checkedAll,
      Q4: !checkedAll,
    });
  };

  const {campaignActualsData} = useSelector(
    (state) => state.budgetReducer
);
const [leadsActual, setleadsActual] = useState(0)
  useEffect(() =>{
    let totalLeads = 0
    let Campaigns = BudgetProfile?.campaigns?.items?.map(item => item?.campaign?.id)
    Object.keys(campaignActualsData)?.map(key => {
      if(Campaigns?.includes(key))
      Object.keys(campaignActualsData[key]).map(month => {
        totalLeads += !isNaN(campaignActualsData[key][month]?.lead_actual) ? campaignActualsData[key][month]?.lead_actual : 0
      })
    })
    setleadsActual(totalLeads)
  },[campaignActualsData, BudgetProfile])

  const budgetTotal = filteredBudgetAmountRemaining + plannedBudgetImpact;
  const transformedBudgetTotal = numberWithCommas(budgetTotal.toFixed(0));

  return (
    <>
      <Row xs={24} md={24} lg={24} gutter={[20, 0]} style={{ marginTop: '30px', paddingBottom: 15}}>
        <Col xs={24} md={24} lg={12} style={{ margin: 0, marginRight: 10 }}>
          {getBudgetLoader ? <Skeleton active paragraph={{rows:7}}/> : 
          
          <Row xs={24} md={12} lg={12} gutter={[20, 20]} style={{ margin: 0 }} >
            <div className="quarter-filter">
              <div>
              <input
                type="checkbox"
                name="all"
                checked={checkedAll}
                style={{ verticalAlign: "middle" }}
                // onClick={handleAll}
                onChange={handleAll}
              />
              <span style={{ paddingRight: 15, paddingLeft: 2 }}>All</span>
              {quarterCheckboxDefault}
              </div>
              <div>
                <Typography.Text style={{color:"#7F56D9", fontWeight:700, fontSize:'18px'}}>Budget total: <span className="secondaryColor fw700" style={{marginLeft:'5px'}}>${transformedBudgetTotal}</span></Typography.Text>
              </div>
            </div>
            <Col xs={24} md={12} lg={12} style={{ margin: 0, paddingTop: 10 }}>
              <Row xs={24} md={12} lg={24} gutter={[8, 8]}>
                <div className="budget-card-text" style={{ width: "100%" }}>
                  <strong style={{color: '#1D2939', fontWeight:700}}>
                  Budget remaining
                  </strong>
                </div>
                <div
                  className="budget-card-number-remaining"
                  style={{ width: "100%", paddingTop: 10,color:"#7F56D9" }}
                >
                  {BudgetProfile?.budget
                    ? `$${numberWithCommas(filteredBudgetAmountRemaining?.toFixed())}`
                    : "$0"}
                </div>
              </Row>

              <Row xs={24} md={12} lg={24} gutter={[8, 8]} justify="center">
                <Col xs={18} md={18} lg={24} style={{ margin: 0, height: 160 }}>
                  <Tooltip
                    title={`$${numberWithCommas(
                      filteredBudgetAmountRemaining
                    )} Remaining`}
                  >
                    <span>
                      <BudgetLiquidChart
                        totalBudget={filteredBudgetAmount}
                        plannedBudget={plannedBudgetImpact}
                      />
                    </span>
                  </Tooltip>
                </Col>
              </Row>
            </Col>

            <Col xs={24} md={12} lg={12} style={{ margin: 0, paddingTop: 10 }}>
              <Row xs={24} md={12} lg={24} gutter={[8, 8]} >
                <div className="budget-card-text" style={{ width: "100%" }}>
                  <strong style={{color: '#1D2939', fontWeight:700}}>
                  Budget planned
                  </strong>
                </div>
                <div
                  className="budget-card-number-planned"
                  style={{ width: "100%", paddingTop: 10, color:"#F04438" }}
                >
                  ${numberWithCommas(plannedBudgetImpact?.toFixed())}
                </div>
              </Row>

              <Row xs={24} md={12} lg={24} gutter={[8, 8]} justify="center">
                <Col xs={18} md={18} lg={24} style={{ margin: 0, height: 160 }}>
                  <BudgetPieChart
                    plannedBudget={ChannelCampaignPlannedTotal}
                    checkedItems={checkedItems}
                    totalspend = {plannedBudgetImpact}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
}
        </Col>

        <Col xs={24} md={24} lg={11}>
        {getBudgetLoader ? <Skeleton active /> : 
          <Row
            xs={24}
            md={12}
            lg={24}
            gutter={[20, 20]}
            style={{ textAlign: "center", marginBottom:'30px' }}
            className="revenue_calculation"
          >
            <div className="summary-right-section" style={{borderBottom:'1px solid #D0D5DD', paddingBottom:'15px', marginBottom:'5px'}}>
              <span style={{ paddingLeft: 12 }}>
                <img src={leadAnalysis} alt="" />
              </span>
                <span className="analisys-text" style={{color:"#7F56D9", fontWeight:700}}>{nameOfLeadObj} Analysis</span>
            </div>

            <Col
              xs={24}
              md={8}
              lg={8}
              className="budget-column"
              
            >
              <div className="budget-card-number">
                  <Tooltip title={numberWithCommas(totalLeadsGoal)}>
                <span style={{color:"#1D2939", fontWeight:700}}>{props.TotalLeadsGoal ? nFormatter(totalLeadsGoal) : "0"}</span>
                </Tooltip>
              </div>
              <div className="budget-card-text-analysis" style={{color:'#98A2B3'}}>Goal</div>
            </Col>
            <Col
              xs={12}
              md={8}
              lg={8}
              className="budget-column"
              style={{ padding: 10 }}
            >
              <div className="budget-card-number">  
                <Tooltip title={numberWithCommas(plannedLeadImpact)}> 
                <span style={{color:"#1D2939", fontWeight:700}}>{nFormatter(isNaN(plannedLeadImpact) ? 0 : plannedLeadImpact)}</span>
                </Tooltip>
              </div>
              <div className="budget-card-text-analysis" style={{color:'#98A2B3'}}>Planned</div>
            </Col>
            <Col
              xs={12}
              md={8}
              lg={8}
              className="budget-column"
              style={{ padding: 10 }}
            >
             <div className="budget-card-number" style={{color:"#1D2939", fontWeight:700}}>
                {!isFinite(leadsActual)
                  ? 0
                  : `${numberWithCommas(leadsActual)}`}
              </div>
              <div className="budget-card-text-analysis" style={{color:'#98A2B3'}}>{nameOfLeadObj} Actual</div>
            </Col>
          </Row>
          }
        {getBudgetLoader ? <Skeleton active /> : 

          <Row
            xs={24}
            md={12}
            lg={24}
            gutter={[20, 20]}
            style={{ textAlign: "center"}}
            className="revenue_calculation"
          >
            <div className="summary-right-section" style={{borderBottom:'1px solid #D0D5DD', paddingBottom:'15px', marginBottom:'5px'}}>
              <span style={{ paddingLeft: 12 }}>
                <img src={revenueAnalysis} alt="" />
              </span>
              <span className="revenue-text" style={{color:"#F04438", fontWeight:700}}>Revenue Analysis</span>
            </div>
            <Col
              xs={24}
              md={8}
              lg={8}
              className="budget-column"
              style={{ paddingLeft: 20, paddingRight: 10, paddingBottom: 30 }}
            >
              <div className="budget-card-number" >
                <Tooltip title={"$" + numberWithCommas(Math.floor(totalRevenuegoal))}>
                <span style={{color:"#1D2939", fontWeight:700}}>{!isFinite(totalRevenuegoal)
                  ? 0
                  : `$${nFormatter(Math.floor(totalRevenuegoal))}`}</span>
                  </Tooltip>
              </div>
              <div className="budget-card-text-analysis" style={{color:'#98A2B3'}}>Goal</div>
            </Col>
            {/* <Col
              xs={12}
              md={6}
              lg={6}
              className="budget-column"
              style={{ padding: 10 }}
            >
              <div className="budget-card-number">
              <Tooltip title={"$" + numberWithCommas(Math.floor(plannedBudgetImpact))}>
                {!isFinite(plannedBudgetImpact)
                  ? 0
                  : `$${nFormatter(Math.floor(plannedBudgetImpact))}`}
                  </Tooltip>
              </div>
              <div className="budget-card-text-analysis">Spend</div>
            </Col> */}
            <Col
              xs={12}
              md={8}
              lg={8}
              className="budget-column"
              style={{ padding: 10 }}
            >
              <div className="budget-card-number-achieved">
              <Tooltip title={"$" + numberWithCommas(Math.floor(actualRevenuQ))}>
                <span style={{color:"#1D2939", fontWeight:700}}>{!isFinite(actualRevenuQ)
                  ? 0
                  : `$${nFormatter(Math.floor(actualRevenuQ))}`}</span>
                  </Tooltip>
              </div>
              <div className="budget-card-text-analysis" style={{color:'#98A2B3'}}>Achieved</div>
            </Col>
            <Col
              xs={12}
              md={8}
              lg={8}
              className="budget-column"
              style={{ padding: 10 }}
            >
               <div
                className="budget-card-number-planned"
                style={{ textAlign: "left" }}
              >
                <Tooltip title={"$" + numberWithCommas(actualLeadsQ)}>
                <span style={{color:"#1D2939", fontWeight:700}}>{numberWithCommas(actualLeadsQ)}</span>
                </Tooltip>
              </div>
              <div className="budget-card-text-analysis" style={{color:'#98A2B3'}}>{props.lastStageName || "Closed Won" }</div>
            </Col>
          </Row>
          }
        </Col>
      </Row>
    </>
  );
};

export default BudgetSummary;
