/* eslint-disable array-callback-return */
import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Switch,
  Tag,
  Table,
  Tooltip,
  Typography,
  Space,
  Collapse,
} from "antd";
import {
  CloseCircleFilled,
  MenuFoldOutlined,
  VerticalAlignMiddleOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  LinkOutlined,
  ApiOutlined,
} from "@ant-design/icons";
import ReactDragListView from "react-drag-listview/lib/ReactDragListView";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FunnelCharts from "commons/FunnelChart";
import { ExpandTextButton, TableParagraph } from "./CustomStages";
import logoshort from "assets/Images/xio-logo-short.svg";
import vector from "../../assets/Images/vector.svg";
import verticalTripleDotImg from "../../assets/Images/vertical_triple_dot.svg";
import saveConversationIcon from "./../../assets/Images/save_conversation.svg";
import resetConversationIcon from "./../../assets/Images/reset_conversation.svg";
import { getConnectedCRMs } from "store/actions/reportAction";
import SalesforceImg from "assets/Images/Social/Salesforce.png";
import HubspotImg from "assets/Images/Social/hubspot.jpg";

import "./DefaultStages.css";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import {
  saveCustomStageMappings,
  updateSalesAndMarketingStage,
  deleteSalesAndMarketingStage,
  createSalesAndMarketingStage,
  getSalesAndMarketingStages,
} from "store/actions/SalesAndMarketingAction";
import { setLoader } from "store/actions/reportAction";
import moment from "moment";
import jsPDF from "jspdf";
import pdfIcon from "./../../assets/Images/pdf_icon.svg";
import { TrackClickFunction } from "../../commons/HeapTrack/TrackClickFunction";

const FormTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #303030;
  margin-bottom: 10px;
`;
const IsLastSwitch = styled(Switch)`
  width: 40px !important;
  margin-left: 0 !important;
`;

const DefaultStages = ({
  isLoading,
  tableData,
  otherTableData,
  settableData,
  salesAndMarketingStages,
  removeIslastForCustomeStage,
  isTourOpen,
  currentTourStep,
  dispatch,
  customAvailableMappings,
  hasAnyCRMConnected,
  defaultAvailableStages,
  crmConnections,
  customSavedMappings,
  viewType,
  HasUnsavedChanges,
  setHasUnsavedChanges,
  ...props
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoadingChart, setIsLoadingChart] = useState(false);
  const [isFunnelDrawerVisible, setIsFunnelDrawerVisible] = useState(false);
  const [isOtherDrawerVisible, setIsOtherDrawerVisible] = useState(false);
  const [stageToEdit, setStageToEdit] = useState(null);
  const [isDeleteStageModelOpen, setisDeleteStageModelOpen] = useState(false);
  const [stageToDelete, setstageToDelete] = useState({});
  const [MaturedLeads, setMaturedLeads] = useState(false);
  const { ConnectedCRMs } = useSelector((state) => state.reportReducer);
  const [
    customSavedMappingswithoutPlatform,
    setcustomSavedMappingswithoutPlatform,
  ] = useState({});
  const [noCRMConnected, setnoCRMConnected] = useState(false);
  const [selectedCRM, setselectedCRM] = useState();
  //only showing connected CRMS into default Stage drawer
  const connected_crms_names = Object.keys(ConnectedCRMs);
  const { Panel } = Collapse;

  const [form] = Form.useForm();
  const account = useSelector((state) => state.loginReducer.groups);
  const StageHeight = 66;

  const editPermission =
    (props.userPermission?.revenuePlanning &&
      props.userPermission?.revenuePlanning !== "viewonly" &&
      props.userPermission?.["revenuePlanning.configureStages"] &&
      props.userPermission?.["revenuePlanning.configureStages"] !==
        "viewonly" &&
      props.userPermission?.["revenuePlanning.configureStages"] !== "hidden") ||
    (account?.[2] === "admin" &&
      viewType !== "companyadmin" &&
      viewType !== "companycontributor");

  //Generate PDF based on data
  const generatePDF = (doc, tabledata) => {
    return tabledata.map((data, i) => {
      let rows = [],
        columns = [];
      columns.push([data.Stages]);
      rows.push([`Definition:- ${data.definition}`]);
      rows.push([`How It Comes In:- ${data.howitcomes}`]);
      rows.push([`Things to keep in mind:- ${data.thingstokeepinmind}`]);
      return doc.autoTable({
        head: columns,
        body: rows,
        startY: doc.lastAutoTable.finalY
          ? i === 0
            ? doc.lastAutoTable.finalY + 15
            : doc.lastAutoTable.finalY + 10
          : 12,
        headStyles: {
          fontSize: 10,
          fillColor: "#ffffff",
          background: "#ffffff",
        },
        theme: "plain",
      });
    });
  };

  // Download and save pdf
  const exportPDF = () => {
    // noinspection JSPotentiallyInvalidConstructorUsage
    const doc = new jsPDF();
    doc.setFontSize(16);
    if (tableData.length > 0) {
      doc.text("Funnel Stages", 10, 10);
      generatePDF(doc, tableData);
    }
    if (otherTableData.length > 0) {
      const startY = doc.lastAutoTable.finalY + 10;
      doc.text("Other Stages", 10, startY);
      generatePDF(doc, otherTableData);
    }
    var currentDate = moment().format("DD_MM_YYYY_HH_mm");
    doc.save(`stages_${currentDate}.pdf`);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const init = async () => {
    let ConnectedCRMs = await dispatch(getConnectedCRMs());
    let CRMS = [];
    if (Object.keys(ConnectedCRMs)?.length) {
      Object.keys(ConnectedCRMs)?.forEach((key) => {
        if (["Hubspot", "Salesforce"]?.includes(key)) {
          CRMS.push(key);
        }
      });
    }
    setnoCRMConnected(CRMS?.length === 0);

    // Check if "Salesforce" is available in the CRMS array
    CRMS.includes("Salesforce")
      ? setselectedCRM("Salesforce")
      : setselectedCRM(CRMS[0]);
  };

  // updates form and opens modal
  const onEditStageClick = (record) => {
    seteditMode(true);
    setStageToEdit(record);
    const {
      Stages: name,
      definition,
      howitcomes,
      thingstokeepinmind,
      isLast,
      showPipelineCoverage,
    } = record;
    form.setFieldsValue({
      name,
      definition,
      howitcomes,
      thingstokeepinmind,
      stages: hasAnyCRMConnected
        ? customSavedMappingswithoutPlatform[record?.id]
        : [],
      isLast,
      showPipelineCoverage,
    });
    customSavedMappingswithoutPlatform[record?.id] &&
      saveAvailableMappingCRM(record);
    setIsModalVisible(true);
  };

  // resets form and closes modal
  const cancelModal = () => {
    form.resetFields();
    setStageToEdit(null);
    setIsModalVisible(false);
    setIntCrmMap([]);
  };

  const showFunnleDrawer = () => {
    setIsFunnelDrawerVisible(true);
  };
  const onFunnelDrawerClose = () => {
    setIsFunnelDrawerVisible(false);
  };
  const showOtherDrawer = () => {
    setIsOtherDrawerVisible(true);
  };
  const onOtherDrawerClose = () => {
    setIsOtherDrawerVisible(false);
  };

  function TagRender(props, isEditable = false) {
    const { closable, onClose, value, label } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={
          isEditable ? "#0F61DB" : label === "Hubspot" ? "#ff7a59" : "#179cd7"
        }
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          fontSize: 14,
          marginRight: 10,
          marginTop: 3,
          marginBottom: 3,
          padding: "2px 10px",
          borderRadius: "4px",
          backgroundColor:"#7F56D9"
        }}
        closeIcon={<CloseCircleFilled style={{ color: "#fff" }} />}
      >
        {isEditable ? label : customAvailableKeyValuePair[value]}
      </Tag>
    );
  }
  const [intCrmMap, setIntCrmMap] = useState([]);
  const [editMode, seteditMode] = useState(false);
  const [savedData, setSavedData] = useState([]);
  const [unsavedData, setUnsavedData] = useState(false);
  const [orderChangeLoader, setOrderChangeLoader] = useState(false);
  const [leadStatusData, setLeadStatusData] = useState();
  const [salesLeadValues, setSalesLeadValues] = useState();
  const [hubLeadValues, setHubLeadValues] = useState();
  const [saveSalesLeadValues, setSaveSalesLeadValues] = useState([]);
  const [leadStatusLoader, setLeadStatusLoader] = useState(false);
  const [savedSalesValue, setSavedSalesValue] = useState([]);
  const [savedHubsValue, setSavedHubsValue] = useState([]);
  const [saveHubLeadValues, setSaveHubLeadValues] = useState([]);
  const [loader, setLoader] = useState(false);

  // Save default data of available mapping to the IntCrmMap
  const saveAvailableMappingCRM = (data) => {
    let textData = {};

    Object.entries(getData).map(([item, value]) => {
      let splitData = item.split(" ")[0];
      textData[splitData] = [
        { platform_id: crmConnections[splitData], values: [] },
      ];
    });
    Object.entries(getData).map(([item, value]) => {
      value.value !== undefined &&
        value.value.map((el) => {
          customSavedMappingswithoutPlatform[data?.id]?.forEach((i) => {
            if (Object.values(el)[0] === i) {
              let dataObj = item.split(" ")[0];

              textData[dataObj][0]["values"].push({
                type: value.type,
                id: Object.values(el)[0],
              });
            }
          });
        });
    });
    setIntCrmMap(textData);
  };
  const handleHubSpoteData = (data) => {
    let textData = {};
    Object.entries(getData).map(([item, value]) => {
      let splitData = item.split(" ")[0];
      textData[splitData] = [
        { platform_id: crmConnections[splitData], values: [] },
      ];
    });
    Object.entries(getData).map(([item, value]) => {
      value.value !== undefined &&
        value.value.map((el) => {
          data.map((i) => {
            if (Object.values(el)[0] === i) {
              let dataObj = item.split(" ")[0];

              textData[dataObj][0]["values"].push({
                type: value.type,
                id: Object.values(el)[0],
              });
            }
          });
        });
    });
    setIntCrmMap(textData);
  };

  useEffect(() => {
    let dataObj = {};
    Object.keys(customSavedMappings).map((key) => {
      dataObj[key] = customSavedMappings?.[key]?.map((item) => item.value);
    });
    setcustomSavedMappingswithoutPlatform(dataObj);
  }, [customSavedMappings]);

  // on Update btn click, updates the stage according to form
  const onSubmit = async (value) => {
    let name = form.getFieldValue("name");
    name = name ? name.trim() : "";
    if (name.length === 0) {
      form.setFieldsValue({ name });
    }
    try {
      await form.validateFields();
    } catch {
      return;
    }
    if (editMode) {
      const inputdata = form.getFieldsValue();
      inputdata.account = account[0];

      inputdata.id = stageToEdit?.id;
      delete inputdata?.stages;

      dispatch(updateSalesAndMarketingStage(inputdata))
        .then(() => {
          addCustomMappings(inputdata?.id, stageToEdit);

          message.success("Stage updated successfully");
        })
        .catch((err) => {
          message.error("Something went wrong");
        })
        .then(() => {
          cancelModal();
        });
    } else {
      let orderLength = 10;
      if (tableData.length || otherTableData.length) {
        orderLength = tableData.length + otherTableData.length;
      }
      let inputdata = {
        account: account[0],
        disabled: false,
        isDefault: false,
        isLast: false,
        order: orderLength,
        stageType: "CUSTOM",
        internalStage: "Custom",
        ...value,
      };
      let stagesToUpdate = inputdata?.stages;
      delete inputdata.stages;

      dispatch(createSalesAndMarketingStage(inputdata, false))
        .then(async (res) => {
          await addCustomMappings(
            res?.data?.createSalesAndMarketingAlignment?.id,
            stagesToUpdate
          );
          let inputSyncData = {
            account: account[0],
            actionType: "CREATE",
            salesAndMarketingAlignmentIds: [
              res?.data?.createSalesAndMarketingAlignment?.id,
            ],
            name: res?.data?.createSalesAndMarketingAlignment?.name,
            order: res?.data?.createSalesAndMarketingAlignment?.order,
          };
          await syncUpAllStage(inputSyncData);
          message.success("Stage created successfully");
        })
        .catch((err) => {
          console.log(err);
          message.error("Something went wrong");
        })
        .then(() => {
          cancelModal();
        });
    }
  };
  const addCustomMappings = async (id, stagesToUpdate) => {
    if (hasAnyCRMConnected) {
      let dataObj = {};
      Object.keys(customAvailableMappings).map((key) => {
        let b = key.split(" ");
        b[1] = String(b[1]).toLowerCase();
        if (Array.isArray(customAvailableMappings?.[key])) {
          customAvailableMappings[key]?.map((e) => {
            if (stagesToUpdate?.includes(e[Object.keys(e)?.[0]])) {
              let id = e[Object.keys(e)?.[0]];
              dataObj[b[0]] = dataObj[b[0]] ? dataObj[b[0]] : {};
              dataObj[b[0]].platform_id = crmConnections[b[0]];
              dataObj[b[0]].values = dataObj[b[0]]?.values
                ? [...dataObj[b[0]]?.values, { id, type: b[1] }]
                : [{ id, type: b[1] }];
            }
          });
        }
      });
      let crm = {};
      Object.entries(getData).map(([key, value]) => {
        crm[key] = [];
        value.value.map((i) => {
          if (savedData.includes(Object.values(i)[0])) {
          }
        });
      });

      Object.keys(dataObj).map((key) => {
        dataObj[key] = [dataObj[key]];
      });
      let inputData = {
        account: account[0],
        virtualStageId: id,
        ...intCrmMap,
        ...dataObj,
      };

      dispatch(saveCustomStageMappings(inputData)).then((res) => {
        dispatch(getSalesAndMarketingStages(account));
      });
    } else {
      dispatch(setLoader(false));
    }
  };

  const [testData, setTestData] = useState([]);

  const resetStageToDefault = () => {
    if (editMode) {
      let name = stageToEdit?.Stages;
      let definition = stageToEdit.definition;
      let howitcomes = stageToEdit?.howitcomes;
      let thingstokeepinmind = stageToEdit?.thingstokeepinmind;
      let showPipelineCoverage = stageToEdit?.showPipelineCoverage;
      form.setFieldsValue({
        name,
        definition,
        howitcomes,
        thingstokeepinmind,
        showPipelineCoverage,
        stages: hasAnyCRMConnected
          ? customSavedMappingswithoutPlatform[stageToEdit?.id] ?? []
          : [],
      });
    } else {
      form.resetFields();
    }
  };

  const [stageToDisable, setstageToDisable] = useState({});

  const onDisableStage = async (record) => {
    dispatch(setLoader(true));
    let inputData = {
      id: record.id,
      disabled: !record?.disabled,
    };
    dispatch(updateSalesAndMarketingStage(inputData, false))
      .then(async (res) => {
        let inputSyncData = {};
        if (res?.data?.updateSalesAndMarketingAlignment?.disabled) {
          inputSyncData = {
            account: account[0],
            actionType: "DELETE",
            salesAndMarketingAlignmentIds: [
              res?.data?.updateSalesAndMarketingAlignment?.id,
            ],
          };
        } else {
          inputSyncData = {
            account: account[0],
            actionType: "CREATE",
            salesAndMarketingAlignmentIds: [
              res?.data?.updateSalesAndMarketingAlignment?.id,
            ],
            name: res?.data?.updateSalesAndMarketingAlignment?.name,
            order: res?.data?.updateSalesAndMarketingAlignment?.order,
          };
        }
        await syncUpAllStage(inputSyncData);
        message.success(
          `Stage ${
            res?.data?.updateSalesAndMarketingAlignment?.disabled
              ? "disabled"
              : "enabled"
          } successfully`
        );
      })
      .catch((err) => {
        message.error("Something went wrong");
      })
      .then(() => {
        setstageToDisable({});
      });
  };

  // TODO :Need to check all delete scenarios
  const handleDeleteStage = async (record) => {
    let inputData = {
      id: record?.id,
    };
    await dispatch(deleteSalesAndMarketingStage(inputData))
      .then(async (res) => {
        let inputSyncData = {
          account: account[0],
          actionType: "DELETE",
          salesAndMarketingAlignmentIds: [
            res?.data?.deleteSalesAndMarketingAlignment?.id,
          ],
        };
        await syncUpAllStage(inputSyncData);
        message.success("Stage deleted successfully");
      })
      .catch((err) => {
        console.log("Error", err);
        message.error("Something went wrong");
      })
      .then(() => {
        setisDeleteStageModelOpen(false);
        setstageToDelete({});
      });
  };

  const [customAvailableKeyValuePair, setcustomAvailableKeyValuePair] =
    useState({});
  const [getData, setData] = useState({});
  useEffect(() => {
    let dataObj = {};
    Object.entries(customAvailableMappings).map(([key, value]) => {
      if (value && value.values.length > 0) {
        dataObj[value?.title] = { type: value?.type, value: value?.values };
      }
    });
    delete dataObj[""];
    delete dataObj["undefined"];
    setData(dataObj);
    let tempCustomObj = {};
    Object.entries(dataObj).map(([key, value]) => {
      value.value.map((el) => {
        let key = Object.values(el)[0];
        let value = Object.keys(el)[0];
        tempCustomObj[key] = value;
      });
    });
    setcustomAvailableKeyValuePair(tempCustomObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customAvailableMappings]);

  // Set table Data in to another object for track changes
  useEffect(() => {
    setIsLoadingChart(false);
    setTestData(tableData);
  }, [tableData]);

  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    // Update the chart data whenever testData changes
    if (testData.length > 0) {
      const newChartData = testData.map((obj) => {
        return {
          id: obj.id,
          text: obj.Stages,
          width: 100,
        };
      });
      setIsLoadingChart(true);
      setChartData([...newChartData]);
    }
  }, [testData]);

  //Sync up the all the changes
  const syncUpAllStage = async (inputData) => {
    let path = `/mapping/syncup_stages`;
    setOrderChangeLoader(true);
    await API.post("backendRest", path, { body: inputData })
      .then((res) => {
        setOrderChangeLoader(false);
      })
      .catch((err) => {
        setOrderChangeLoader(false);
        console.log("Eror => ", err);
      });
  };
  //Handle Save table Changes
  const handleSaveStageChanges = () => {
    TrackClickFunction(
      "demand-gen-sales-and-marketing-stage-configuration-save-button"
    );
    let apiData = [];
    dispatch(setLoader(true));
    testData.forEach((item, index) => {
      let orderValue =
        item.internalStage === "Closed Won" ||
        item.internalStage === "Closed Lost" ||
        item.internalStage === "Leads"
          ? item.order
          : index + 1;
      let inputData = {};
      inputData = {
        account: account[0],
        id: item.id,
        order: orderValue,
        name: item.name,
      };
      apiData.push(inputData);
    });
    return Promise.all(
      apiData.map((item) => dispatch(updateSalesAndMarketingStage(item, false)))
    ).then(async (response) => {
      let salesIdArray = [];
      response.forEach((item) => {
        salesIdArray.push(item?.data?.updateSalesAndMarketingAlignment?.id);
      });
      let inputSyncData = {
        account: account[0],
        actionType: "UPDATE",
        salesAndMarketingAlignmentIds: salesIdArray,
      };
      await syncUpAllStage(inputSyncData);
      message.success(`Funnel stages configured successfully`);
      setUnsavedData(false);
      setHasUnsavedChanges(false);

      return response;
    });
  };
  //Handle reset table Changes
  const handleResetStageChanges = () => {
    setTestData(tableData);
    setUnsavedData(false);
    setHasUnsavedChanges(false);
  };

  const onDragEnd = (fromIndex, toIndex) => {
    if (toIndex === 0 || toIndex === testData.length - 1) {
      return;
    }
    setUnsavedData(true);
    setHasUnsavedChanges(true);
    const newData = [...testData];
    const item = newData.splice(fromIndex, 1)[0];
    newData.splice(toIndex, 0, item);
    setTestData(newData);
  };

  const dragProps = {
    onDragEnd,
    handleSelector: "a",
  };

  const columns = [
    {
      title: "",
      key: "operate",
      className: "arrow-column",
      width: 48,
      render: (text, record, index) => {
        return record.isDefault || record.disabled ? (
          <VerticalAlignMiddleOutlined
            style={{ fontSize: 26, color: "#E2E2E2" }}
          />
        ) : (
          // eslint-disable-next-line
          <a href="#">
            <VerticalAlignMiddleOutlined
              style={{ fontSize: 26, color: "gray" }}
            />
          </a>
        );
      },
    },
    {
      title: "",
      dataIndex: "Stages",
      className: "stage-column",
      width: 198,
      render: (name, record) => {
        return (
          <div className="stage-wrapper">
            <Typography.Text
              className="stage-column-value"
              style={{
                color:
                  !record.isDefault && record.disabled ? "#A9A9A9" : "#1D2939",
              }}
            >
              {name}
            </Typography.Text>
            <div className="stage-specify-class">
              <div>
                {record.isDefault ? (
                  <img src={logoshort} className="stage-image-viewer" alt="" />
                ) : (
                  <img src={vector} className="stage-image-viewer" alt="" />
                )}
              </div>
              {record.showPipelineCoverage ? (
                <Tooltip title="Pipeline coverage on">
                  <div className="mapping-dynamic-stages-dot show-stage-chnages-text"></div>
                </Tooltip>
              ) : (
                <div style={{ marginRight: 5 }}></div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: "",
      className: "stage-column-defiantion",
      dataIndex: "definition",
      width: 346,
      ellipsis: true,
      render: (name, record) => {
        return (
          <TableParagraph
            className="defiantion-data"
            disabled={!record.isDefault && record.disabled}
            ellipsis={
              name?.length >= "100" && {
                suffix: (
                  <ExpandTextButton style={{color:'#7F56D9'}}>
                    <Tooltip title={name} style={{color:'#7F56D9'}}>Learn More</Tooltip>
                  </ExpandTextButton>
                ),
                rows: 2,
              }
            }
            style={{color:"#475467"}}
          >
            {name}{" "}
          </TableParagraph>
        );
      },
    },
    {
      title: "",
      key: "operate",
      className: "arrow-column",
      width: 40,
      render: (text, record, index) => {
        return (
          props.userPermission?.["salesMarketing"] === "edit" && (
            <div className="data" key={index} data-tour="tour_editStages">
              <div>
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="disable-stage"
                        onClick={() => {
                          setstageToDisable(record);
                        }}
                        disabled={
                          record.internalStage === "Closed Won" ||
                          record.internalStage === "Leads" ||
                          record.internalStage === "Closed Lost"
                        }
                      >
                        <Tooltip
                          title={
                            record.internalStage === "Closed Won" &&
                            "Can not disable last stage"
                          }
                        >
                          {record.disabled ? "Enable Stage" : "Disable Stage"}
                        </Tooltip>
                      </Menu.Item>
                      <Menu.Item
                        disabled={
                          record.disabled &&
                          record.internalStage !== "Closed Lost"
                        }
                        key="edit-stage"
                        onClick={() => onEditStageClick(record)}
                      >
                        Edit Stage
                      </Menu.Item>
                      {!record.isDefault && (
                        <Menu.Item
                          key="delete-stage"
                          disabled={
                            record.internalStage === "Closed Won" ||
                            record.isDefault
                          }
                          onClick={() => {
                            setstageToDelete(record);
                            setisDeleteStageModelOpen(true);
                          }}
                        >
                          Delete Stage
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={verticalTripleDotImg}
                      alt="dropdown"
                      title="Click to open dropdown"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </Dropdown>
              </div>
            </div>
          )
        );
      },
    },
  ];

  const filteredColumns = columns.filter((col) => {
    if (col.key === "operate") {
      return editPermission === true;
    } else {
      return true;
    }
  });

  const handleEditMaturedLeadMapping = async () => {
    setMaturedLeads(true);
    const requestData = {
      account: account[0],
      data: [
        {
          Salesforce: [""],
          Hubspot: [""],
        },
      ],
    };
    await getLeadStatusMappings(requestData);
  };

  const getLeadStatusMappings = async (requestData) => {
    setLeadStatusLoader(true);
    let path = `/mapping/get_matured_lead_status_mappings`;

    await API.post("backendRest", path, { body: requestData })
      .then((res) => {
        setLeadStatusData(res[0]);
        Object.keys(res[0]["Salesforce"][0]).forEach((key) => {
          if (key === "matured_lead_status_mappings") {
            let tempSalesLeadObj = [];
            let tempLeadSales = {
              platform_id: res[0]["Salesforce"][0].plateformId,
              values: [],
            };
            res[0]["Salesforce"][0][key].forEach((value) => {
              tempSalesLeadObj.push(Object.keys(value)[0]);
              tempLeadSales["values"].push({
                type: Object.keys(value)[0],
                id: Object.values(value)[0],
              });
            });

            setSavedSalesValue(tempSalesLeadObj);
            setSaveSalesLeadValues(tempLeadSales);
          }

          if (res[0]["Salesforce"][0][key].title) {
            setSalesLeadValues(res[0]["Salesforce"][0][key]);
          }
        });
        Object.keys(res[0]["Hubspot"][0]).forEach((key) => {
          if (key === "matured_lead_status_mappings") {
            let tempHubsLeadObj = [];
            let tempLeadHub = {
              platform_id: res[0]["Hubspot"][0].plateformId,
              values: [],
            };
            res[0]["Hubspot"][0][key].forEach((value) => {
              tempHubsLeadObj.push(Object.values(value)[0]);
              tempLeadHub["values"].push({
                type: Object.keys(value)[0],
                id: Object.values(value)[0],
              });
            });
            setSavedHubsValue(tempHubsLeadObj);
            setSaveHubLeadValues(tempLeadHub);
          }
          if (res[0]["Hubspot"][0][key].title) {
            setHubLeadValues(res[0]["Hubspot"][0][key]);
          }
        });
        setLeadStatusLoader(false);
        return res;
      })
      .catch((err) => {
        setLeadStatusLoader(false);
        console.log(err);
      });
  };

  const handleHubLeadingData = (data) => {
    let temphubLeadData = {};
    temphubLeadData = {
      platform_id: leadStatusData["Hubspot"][0].plateformId,
      values: [],
    };
    if (hubLeadValues.title) {
      data.forEach((i) => {
        temphubLeadData["values"].push({
          type: hubLeadValues.type,
          id: i,
        });
      });
    }
    setSaveHubLeadValues(temphubLeadData);
  };

  const handleSalesLeadingData = (data) => {
    let tempsalesLeadData = {};
    tempsalesLeadData = {
      platform_id: leadStatusData["Salesforce"][0]?.plateformId,
      values: [],
    };

    if (salesLeadValues.title) {
      data.forEach((i) => {
        tempsalesLeadData["values"].push({
          type: salesLeadValues.type,
          id: i,
        });
      });
    }
    setSaveSalesLeadValues(tempsalesLeadData);
  };

  const SaveLeadStatusMappingConfig = async () => {
    setLoader(true);
    let inputData = {
      account: account[0],
      Hubspot: [saveHubLeadValues],
      Salesforce: [saveSalesLeadValues],
    };
    let init = {
      body: inputData,
    };

    API.post("backendRest", "/mapping/save_matured_lead_status_mappings", init)
      .then((res) => {
        message.success("Matured Lead status updated successfully");
        setLoader(false);
        setLeadStatusData(false);
        return res;
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  const handleCloseLeadStatusMappingDrawer = () => {
    setSavedHubsValue([]);
    setSavedSalesValue([]);
    setMaturedLeads(false);
  };

  return (
    <>
      <div
        className="salesMarketing"
        key="default-stages"
        style={{
          background: "#fff",
          width: "100%",
          borderRadius: 7,
          padding: 20,
          marginTop:'20px'
        }}
      >
        <Row>
          <Row
            className="right_side_label"
            style={{
              display: "inline-flex",
              justifyContent: "flex-end",
              marginBottom: '15px',
            }}
          >
            <Col className="non-print">
              <span style={{ width: "20%" }}>
                {unsavedData && (
                  <span className="conversation-difference-dot"></span>
                )}
              </span>
              <div style={{display:'flex', alignItems:'center'}}>
              <span>
                <Tooltip title={"Please Save Stage Configuration"}>
                  {editPermission &&
                  props.userPermission?.revenuePlanning !== "viewonly" ? (
                    <Button
                      type="secondary"
                      onClick={handleSaveStageChanges}
                      className="saveStageButton primaryButton"
                      loading={orderChangeLoader}
                      style={{width:'130px'}}
                    >
                      <img
                        src={saveConversationIcon}
                        style={{
                          paddingRight: 5,
                        }}
                        alt="save"
                      ></img>{" "}
                      Save
                    </Button>
                  ) : (
                    <Button
                      type="secondary"
                      title="View only permission"
                      className="saveStageButton primaryButton"
                      disabled
                      style={{width:'130px'}}
                    >
                      <img
                        src={saveConversationIcon}
                        style={{
                          paddingRight: 5,
                        }}
                        alt="save"
                      ></img>{" "}
                      Save
                    </Button>
                  )}
                </Tooltip>
              </span>
              {/* </Col>
                    <Col xs={24} md={24} lg={12}> */}
              <Tooltip title={"Please Reset Stage Configuration"}>
                {editPermission &&
                props.userPermission?.revenuePlanning !== "viewonly" ? (
                  <Button
                    type="secondary"
                    onClick={handleResetStageChanges}
                    className="resetStageButton secondaryButton"
                    style={{width:'130px'}}
                  >
                    <img
                      src={resetConversationIcon}
                      style={{
                        paddingRight: 5,
                        opacity: 1,
                      }}
                      alt="reset"
                    ></img>{" "}
                    Reset
                  </Button>
                ) : (
                  <Button
                    type="secondary"
                    title="View only permission"
                    className="resetStageButton secondaryButton"
                    style={{width:'130px'}}
                    disabled
                  >
                    <img
                      src={resetConversationIcon}
                      style={{
                        paddingRight: 5,
                        opacity: `${1}`,
                      }}
                      alt="reset"
                    ></img>{" "}
                    Reset
                  </Button>
                )}
              </Tooltip>
              {props.userPermission?.revenuePlanning !== "viewonly" ? (
                <Button
                  style={{
                    width:'150px'
                  }}
                  type="primary"
                  onClick={exportPDF}
                  className="tertiaryButton"
                >
                  {/* <img
                    src={pdfIcon}
                    style={{ paddingRight: 5, fontSize: "16px" }}
                    alt="pdf"
                  ></img> */}
                  Export To PDF
                </Button>
              ) : (
                <Button
                  style={{
                    height: "38px",
                  }}
                  type="primary"
                >
                  <img
                    src={pdfIcon}
                    style={{ paddingRight: 5, fontSize: "16px" }}
                    alt="pdf"
                  ></img>
                  Export To PDF
                </Button>
              )}
              </div>
            </Col>
          </Row>
          <Col lg={8} md={24} sm={24} >
            <div style={{ textAlign: "center", width: "100%", lineHeight:'35px' }}>
              <Typography.Text className="title-class-header" style={{color:"#1D2939", fontWeight:500}}>
                Manage <span style={{ color: "#7F56D9" }}>Funnel Stages</span><br />
                with Demand Gen
              </Typography.Text>
            </div>
            <div className="funnel-wrapper" data-tour="tour__funnelVisual">
              {isLoadingChart && chartData && chartData?.length > 0 ? (
                <FunnelCharts
                  data={chartData}
                  color={[
                    // "#D6BBFB",
                    "#B692F6",
                    "#9E77ED",
                    "#7F56D9",
                    "#6941C6",
                    "#53389E"
                  ]}
                  height={chartData.length * StageHeight}
                  labelColor="#fff"
                  transform={true}
                />
              ) : (
                <Skeleton active paragraph={{ rows: 10 }} />
              )}
              <div className="border-line" />
            </div>
          </Col>
          <Col lg={16} md={24} sm={24}>
            <div className="table-header-div" style={{background:'#ebebeb'}}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography.Text className="table-stages-title">
                  Stages
                </Typography.Text>
                <Tooltip placement="top" key="#fff" title="Funnel Stages">
                  <InfoCircleOutlined className="tooltip-icon" />
                </Tooltip>
                {/* <LinkOutlined
                  style={{ color: "#4B4B4B", marginLeft: 5 }}
                  onClick={() => handleEditMaturedLeadMapping()}
                /> */}
                <div
                  onClick={() => handleEditMaturedLeadMapping()}
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                >
                  {selectedCRM === "Salesforce" && (
                    <Tooltip placement="top" key="#fff" title="Funnel Maturity Mapping">
                      <img
                        src={SalesforceImg}
                        alt={"Salesforce"}
                        style={{ width: "28px" }}
                      />
                    </Tooltip>
                  )}
                  {selectedCRM === "Hubspot" && (
                    <Tooltip placement="top" key="#fff" title="Funnel Maturity Mapping">
                    <img
                      src={HubspotImg}
                      alt={"Hubspot"}
                      style={{ width: "24px" }}
                    />
                    </Tooltip>
                  )}
                  {selectedCRM !== "Salesforce" &&
                    selectedCRM !== "Hubspot" && (
                      <div
                        className="social_default_image"
                        style={{ height: "22px", width: "22px" }}
                      >
                        <ApiOutlined
                          style={{ fontSize: "16px", color: "#fff" }}
                        />
                      </div>
                    )}
                </div>
              </div>
              <div>
                <Typography.Text className="table-stages-title">
                  Definition
                </Typography.Text>
              </div>
              <div style={{ marginRight: 12 }} data-tour="tour__addStage">
                {(props.userPermission?.["salesMarketing.customStages"] ===
                  "edit" ||
                  (account?.[2] === "admin" &&
                    viewType !== "companyadmin" &&
                    viewType !== "companycontributor")) &&
                props.userPermission?.["salesMarketing"] === "edit" ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsModalVisible(true);
                      seteditMode(false);
                    }}
                    className="primaryButton"
                    style={{height:'35px', width:'130px', fontSize:'14px'}}
                  >
                    Add Stage
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    type="primary"
                    title="View only permission"
                  >
                    Add Stage
                  </Button>
                )}
              </div>
            </div>
             <div className="funnel-stages" style={{background:"#f5f7fa"}}>
              <Typography.Text className="funnel-stages-header" style={{color:"#7F56D9"}}>
                Funnel Stages
              </Typography.Text>
              {(props.userPermission?.["salesMarketing.customStages"] ===
                "edit" ||
                (account?.[2] === "admin" &&
                  viewType !== "companyadmin" &&
                  viewType !== "companycontributor")) &&
                props.userPermission?.["salesMarketing"] === "edit" && (
                  <div className="drawer-data">
                    <Tooltip title="CRM Mapping">
                      <Button
                        style={{
                          border: "none",
                          boxShadow: "none",
                          background: "#F5F5F5",
                        }}
                        onClick={
                          isFunnelDrawerVisible
                            ? onFunnelDrawerClose
                            : showFunnleDrawer
                        }
                        icon={
                          <MenuFoldOutlined
                            style={{
                              transition: ".5s",
                              transform: isFunnelDrawerVisible
                                ? "rotate(180deg)"
                                : "none",
                            }}
                          />
                        }
                      />
                    </Tooltip>
                  </div>
                )}
            </div>
            <div className="table-wrapper-class" data-tour="tour__funnelStages">
              <div
                className={`${
                  isFunnelDrawerVisible || (isTourOpen && currentTourStep === 3)
                    ? "crm-dynamic-mapping-drawer"
                    : "CRMMappingClosed"
                }  ${
                  !(isTourOpen && currentTourStep === 3) && "crm-mapping-drawer"
                }`}
                data-tour="tour__crmMappings"
              >
                <div className="crm-drawer-header">
                  <strong style={{ fontSize: 18, fontWeight: 600 }}>
                    CRM Mapping
                  </strong>
                  <Tooltip
                    className="tooltip"
                    placement="leftTop"
                    color="#fff"
                    key="#fff"
                    title={
                      <div style={{ width: "100%", padding: "0px 20px" }}>
                        {connected_crms_names.includes("Hubspot") && (
                          <div className="hint-title hint-title-hubspot">
                            {" "}
                            Hubspot
                          </div>
                        )}
                        {connected_crms_names.includes("Salesforce") && (
                          <div className="hint-title hint-title-salesforce">
                            {" "}
                            SalesForce
                          </div>
                        )}
                      </div>
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>

                {testData?.map((record, index) => (
                  <Row className="data-test" key={index}>
                    <Tooltip
                      title={
                        hasAnyCRMConnected
                          ? ""
                          : "Please connect CRM to get actual data"
                      }
                    >
                      {hasAnyCRMConnected ? (
                        <Select
                          mode="multiple"
                          showArrow={false}
                          tagRender={TagRender}
                          style={{ width: "100%" }}
                          size="large"
                          placeholder="Please connect CRM to get actual data"
                          allowClear
                          optionFilterProp="children"
                          value={
                            hasAnyCRMConnected
                              ? customSavedMappings[record?.id]?.filter((obj) =>
                                  connected_crms_names.includes(obj.label)
                                )
                              : []
                          }
                          disabled
                        ></Select>
                      ) : (
                        <Skeleton
                          paragraph={{ rows: 1 }}
                          active
                          style={{ width: "100%" }}
                        />
                      )}
                    </Tooltip>
                  </Row>
                ))}
              </div>
              {testData.length > 0 ? (
                <ReactDragListView {...dragProps}>
                  <Table
                    className="table_col_hide"
                    columns={filteredColumns}
                    pagination={false}
                    dataSource={testData}
                  />
                </ReactDragListView>
              ) : (
                <Skeleton active paragraph={{ rows: 10 }} />
              )}
            </div>
            <div className="funnel-stages" style={{background:"#f5f7fa"}}>
              <Typography.Text className="funnel-stages-header" style={{color:"#7F56D9"}}>
                Other Stages
              </Typography.Text>
              {(props.userPermission?.["salesMarketing.customStages"] ===
                "edit" ||
                (account?.[2] === "admin" &&
                  viewType !== "companyadmin" &&
                  viewType !== "companycontributor")) &&
                props.userPermission?.["salesMarketing"] === "edit" && (
                  <div className="drawer-data">
                    <Tooltip title="CRM Mapping">
                      <Button
                        style={{
                          border: "none",
                          boxShadow: "none",
                          background: "#F5F5F5",
                        }}
                        onClick={
                          isOtherDrawerVisible
                            ? onOtherDrawerClose
                            : showOtherDrawer
                        }
                        icon={
                          <MenuFoldOutlined
                            style={{
                              transition: ".5s",
                              transform: isOtherDrawerVisible
                                ? "rotate(180deg)"
                                : "none",
                            }}
                          />
                        }
                      />
                    </Tooltip>
                  </div>
                )}
            </div>
            <div className="table-wrapper-class" data-tour="tour_otherStage">
              <div
                className={`${
                  isOtherDrawerVisible
                    ? "crm-dynamic-mapping-drawer "
                    : "CRMMappingClosed"
                } crm-mapping-drawer`}
              >
                <div className="crm-drawer-header">
                  <strong style={{ fontSize: 18, fontWeight: 600 }}>
                    CRM Mapping
                  </strong>
                  <Tooltip
                    className="tooltip"
                    placement="leftTop"
                    color="#fff"
                    key="#fff"
                    title={
                      <div style={{ width: "100%", padding: "0px 20px" }}>
                        {connected_crms_names.includes("Hubspot") && (
                          <div className="hint-title hint-title-hubspot">
                            {" "}
                            Hubspot
                          </div>
                        )}
                        {connected_crms_names.includes("Salesforce") && (
                          <div className="hint-title hint-title-salesforce">
                            {" "}
                            SalesForce
                          </div>
                        )}
                      </div>
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>

                {otherTableData?.map((record, index) => (
                  <Row className="data-test" key={index}>
                    <Tooltip
                      title={
                        hasAnyCRMConnected
                          ? ""
                          : "Please connect CRM to get actual data"
                      }
                    >
                      {hasAnyCRMConnected ? (
                        <Select
                          mode="multiple"
                          showArrow={false}
                          tagRender={TagRender}
                          style={{ width: "100%" }}
                          size="large"
                          placeholder="Please connect CRM to get actual data"
                          allowClear
                          optionFilterProp="children"
                          value={
                            hasAnyCRMConnected
                              ? customSavedMappings[record?.id]?.filter((obj) =>
                                  connected_crms_names.includes(obj.label)
                                )
                              : []
                          }
                          disabled
                        ></Select>
                      ) : (
                        <Skeleton
                          paragraph={{ rows: 1 }}
                          active
                          style={{ width: "100%" }}
                        />
                      )}
                    </Tooltip>
                  </Row>
                ))}
              </div>
              {otherTableData ? (
                <ReactDragListView {...dragProps}>
                  <Table
                    className="table_col_hide"
                    columns={filteredColumns}
                    pagination={false}
                    dataSource={otherTableData}
                  />
                </ReactDragListView>
              ) : (
                <Skeleton active paragraph={{ rows: 2 }} />
              )}
            </div>
          </Col>
        </Row>
      </div>

      <Drawer
        maskClosable={false}
        className="edit-mapping-stage"
        key="drawer"
        title={
          <strong>
            {editMode ? "Update Stage" : "Create Stage"}
            {isLoading && <LoadingOutlined style={{ marginLeft: 10 }} />}
          </strong>
        }
        visible={isModalVisible}
        width={416}
        onClose={cancelModal}
        footer={[]}
        style={{ borderRadius: "20px" }}
      >
        {" "}
        <div className="delete-modal-body">
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            autoComplete="off"
            requiredMark={false}
            style={{ width: "100%" }}
            onFinish={onSubmit}
          >
            <Form.Item
              disabled={isLoading}
              name="name"
              label={
                <span>
                  <strong>Stage name&nbsp;</strong>
                  <Tooltip
                    title="Stage names set here are referenced throughout the platform"
                    placement="left"
                  >
                    <InfoCircleOutlined style={{ float: "right" }} />
                  </Tooltip>
                </span>
              }
              extra={
                <span className="stage-clarification-tooltip-wrapper">
                  <Tooltip title="Stage Type" placement="topLeft">
                    <Typography.Text className="stage-clarification-text">
                      {editMode ? stageToEdit?.stageType : "CUSTOM"}
                    </Typography.Text>
                  </Tooltip>
                  <span
                    style={{ background: "#a9a9a9" }}
                    className="mapping-dynamic-stages-dot show-stage-chnages-text"
                  ></span>
                  <Tooltip title="XDG Default Stage" placement="topLeft">
                    <Typography.Text className="stage-clarification-text">
                      {stageToEdit?.isDefault === true
                        ? stageToEdit?.internalStage
                        : "Custom"}
                    </Typography.Text>
                  </Tooltip>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "This field can not be empty!",
                },
              ]}
            >
              <Input placeholder="Enter stage name" size="large" className="general-input"/>
            </Form.Item>

            <FormTitle>Definition</FormTitle>
            <Form.Item disabled={isLoading} name="definition">
              <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 7 }}
                placeholder="Enter stage definition"
                style={{ fontSize: 14 }}
                className="general-input-textarea"
              />
            </Form.Item>

            <FormTitle>How It Comes In</FormTitle>
            <Form.Item disabled={isLoading} name="howitcomes">
              <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 7 }}
                style={{ fontSize: 14 }}
                placeholder="Enter stage details how it comes in"
                className="general-input-textarea"
              />
            </Form.Item>
            <FormTitle>Things To Keep In Mind</FormTitle>
            <Form.Item name="thingstokeepinmind">
              <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 7 }}
                style={{ fontSize: 14 }}
                placeholder="Enter stage details things to keep in mind"
                className="general-input-textarea"
              />
            </Form.Item>

            <Form.Item
              label={
                <span>
                  <strong>Show Pipeline Coverage</strong>
                  <Tooltip
                    title="Show pipeline coverage metrics for this stage. This is recommended for opportunity/deal-specific stages only."
                    placement="left"
                  >
                    <InfoCircleOutlined style={{ float: "right" }} />
                  </Tooltip>
                </span>
              }
              name="showPipelineCoverage"
              valuePropName="checked"
            >
              <IsLastSwitch />
            </Form.Item>
            <Form.Item
              name="stages"
              label={
                <span>
                  <strong>CRM Mapping&nbsp;</strong>
                  <Tooltip
                    title="If you have connected your CRM, you may map stages here"
                    placement="left"
                  >
                    <InfoCircleOutlined style={{ float: "right" }} />
                  </Tooltip>
                </span>
              }
            >
              <Select
                mode="multiple"
                showArrow
                tagRender={(e) => TagRender(e, true)}
                style={{ width: "100%" }}
                size="large"
                placeholder={
                  !hasAnyCRMConnected
                    ? "Please connect CRM to get actual data"
                    : "Select CRM stage to map to this stage"
                }
                allowClear
                optionFilterProp="children"
                disabled={!hasAnyCRMConnected}
                getPopupContainer={(trigger) => trigger.parentElement}
                onChange={(e) => {
                  setSavedData(e);
                  handleHubSpoteData(e);
                }}
                className="general-input-select"
              >
                {Object.keys(getData)?.map(
                  (key, index) =>
                    key !== "undefined" &&
                    key !== "" && (
                      <Select.OptGroup label={key}>
                        {getData?.[key]?.value?.map((value, index) => {
                          let obj = Object.keys(value);
                          return (
                            <Select.Option
                              className="default-option"
                              value={value[obj[0]]}
                            >
                              {Object.keys(value)?.[0]}
                            </Select.Option>
                          );
                        })}
                      </Select.OptGroup>
                    )
                )}
              </Select>
            </Form.Item>

            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                zIndex: 2,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
                display: "flex",
              }}
            >
              <Button
                type="primary"
                size="small"
                ghost
                onClick={() => resetStageToDefault()}
                style={{
                  borderColor: "#EC4A0A",
                  color: "#fff",
                  backgroundColor:"#EC4A0A",
                  
                  marginRight: 8,
                  width: "48%",
                }}
                disabled={isLoading}
                className="secondaryButton"
              >
                Reset to default
              </Button>
              <Button
                disabled={isLoading}
                size="large"
                type="primary"
                style={{ fontSize: 18, width: "48%" }}
                htmlType="submit"
                className="primaryButton"
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </Drawer>

      {/* Need to check the data for delete custom stage */}
      <Modal
        visible={isDeleteStageModelOpen}
        closable={false}
        centered
        footer={null}
        style={{ borderRadius: "20px" }}
      >
        <div className="delete-modal-body">
          {stageToDelete?.usage && stageToDelete?.usage?.length > 0 ? (
            <>
              <Typography.Title level={5}>
                In order to delete{" "}
                {
                  <span style={{ color: "#0F61DB" }}>
                    {stageToDelete?.Stages}
                  </span>
                }{" "}
                stage it must first be removed from These segments funnels (via
                Configure Stages)
              </Typography.Title>

              <div style={{ width: "100%" }}>
                {stageToDelete?.usage?.map((data, index) => (
                  <div key={index}>
                    <Typography.Text style={{ fontSize: 16 }}>
                      {data?.name} (FY {data?.year})
                    </Typography.Text>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <Typography.Title level={5}>
                Are you sure you want to delete{" "}
                {
                  <span style={{ color: "#0F61DB" }}>
                    {stageToDelete?.Stages}
                  </span>
                }{" "}
                Stage?
              </Typography.Title>
            </>
          )}

          <div className="delete-modal-footer">
            <Button
              size="large"
              type="primary"
              onClick={() => {
                handleDeleteStage(stageToDelete);
              }}
              loading={isLoading}
              disabled={
                stageToDelete?.usage && stageToDelete?.usage?.length > 0
              }
            >
              Yes
            </Button>
            <Button
              type="primary"
              size="small"
              ghost
              disabled={isLoading}
              onClick={() => {
                setisDeleteStageModelOpen(false);
                setstageToDelete({});
              }}
              style={{ borderColor: "#0F61DB", color: "#0F61DB" }}
            >
              No
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={Object.keys(stageToDisable)?.length > 0}
        closable={false}
        centered
        footer={null}
        style={{ borderRadius: "20px" }}
      >
        <div className="delete-modal-body">
          {
            <>
              <Typography.Title level={5}>
                Are you sure you want to{" "}
                {stageToDisable?.disabled ? "enable" : "disable"}{" "}
                {
                  <span style={{ color: "#0F61DB" }}>
                    {stageToDisable?.Stages}
                  </span>
                }{" "}
                stage?
              </Typography.Title>
            </>
          }

          <div className="delete-modal-footer">
            <Button
              size="large"
              type="primary"
              onClick={async () => {
                await onDisableStage(stageToDisable);
                // setstageToDisable({});
              }}
              loading={isLoading || orderChangeLoader}
            >
              Yes
            </Button>
            <Button
              type="primary"
              size="small"
              ghost
              disabled={isLoading || orderChangeLoader}
              onClick={() => {
                setstageToDisable({});
              }}
              style={{ borderColor: "#0F61DB", color: "#0F61DB" }}
            >
              No
            </Button>
          </div>
        </div>
      </Modal>
      <Drawer
        title={
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <Space>
              <div style={{ fontWeight: "700" }}>Funnel Maturity Mapping</div>
              {leadStatusLoader && (
                <LoadingOutlined style={{ marginLeft: 10 }} />
              )}
            </Space>
            <Tooltip
              placement="bottom"
              color={"white"}
              trigger={"click"}
              overlayStyle={{ minWidth: "300px" }}
              overlayInnerStyle={{
                padding: "10px",
                fontSize: "16px",
                marginRight: 54,
              }}
              title={
                <Space direction="vertical" style={{ fontWeight: "400" }}>
                  <div style={{ color: "black" }}>Funnel Maturity Mapping</div>
                </Space>
              }
            >
              <InfoCircleOutlined style={{ color: "#A9A9A9" }} />
            </Tooltip>
          </Space>
        }
        visible={MaturedLeads}
        className="createAssetModal market-drawer"
        onClose={handleCloseLeadStatusMappingDrawer}
      >
        <Collapse
          accordion
          expandIconPosition="right"
          className="pannel-arrow collapse-style"
          ghost={true}
        >
          {hubLeadValues && (
            <Panel header="Hubspot" key="1">
              <div>
                <div>
                  {hubLeadValues && (
                    <span className="header-style">{hubLeadValues.title}</span>
                  )}
                  <InfoCircleOutlined
                    style={{ float: "right", marginTop: 12, color: "#A9A9A9" }}
                  />
                </div>
                <Select
                  mode="multiple"
                  allowClear
                  size="large"
                  value={savedHubsValue}
                  style={{ width: "100%" }}
                  placeholder={`Select ${hubLeadValues.title} Mappings`}
                  onChange={(e) => {
                    setSavedHubsValue(e);
                    handleHubLeadingData(e);
                  }}
                  className="market-select"
                >
                  {hubLeadValues &&
                    hubLeadValues.values.map((item) => {
                      let obj = Object.keys(item);
                      return (
                        <Select.Option value={item[obj[0]]}>
                          {obj}
                        </Select.Option>
                      );
                    })}
                </Select>
              </div>
            </Panel>
          )}
          {salesLeadValues && (
            <Panel header="SalesForce" key="2">
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "12px 0",
                  }}
                >
                  <span className="header-style" style={{ fontSize: "15px" }}>
                    {salesLeadValues.title}
                  </span>
                  <InfoCircleOutlined
                    style={{ float: "right", marginTop: 0, color: "#A9A9A9" }}
                  />
                </div>
                <Select
                  mode="multiple"
                  allowClear
                  size="large"
                  value={savedSalesValue}
                  style={{ width: "100%" }}
                  placeholder={`Select ${salesLeadValues.title} Mappings`}
                  onChange={(e) => {
                    setSavedSalesValue(e);
                    handleSalesLeadingData(e);
                  }}
                >
                  {salesLeadValues.values.map((item) => {
                    let obj = Object.keys(item);
                    let obj1 = Object.values(item);

                    return (
                      <Select.Option value={item[obj[0]]}>{obj1}</Select.Option>
                    );
                  })}
                </Select>
              </div>
            </Panel>
          )}
        </Collapse>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            zIndex: 2,
            width: "100%",
            borderTop: "1px solid #E9E9E9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
            display: "flex",
          }}
        >
          <Button
            type="primary"
            size="small"
            ghost
            style={{
              borderColor: "#0F61DB",
              color: "#0F61DB",
              fontSize: 18,
              marginRight: 8,
              width: "48%",
            }}
            onClick={handleCloseLeadStatusMappingDrawer}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            loading={loader}
            size="large"
            type="primary"
            style={{ fontSize: 18, width: "48%" }}
            onClick={() => SaveLeadStatusMappingConfig()}
            disabled={isLoading}
          >
            Save
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default DefaultStages;
