/* eslint-disable react-hooks/exhaustive-deps */
import {
  Checkbox,
  Col,
  Row,
  Select,
  Skeleton,
  Typography,
  message,
  Button,
  Tooltip,
  Drawer,
  Descriptions,
  Space
} from "antd";
import React, { useEffect, useState } from "react";
import FunnelCharts from "commons/FunnelChart";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import EmptyChart from "commons/EmptyChart";
import moment from "moment";
import { Line } from "@ant-design/charts";
import FunnelChartsExecutive from "commons/FunnelChartExecutive";
import { Link } from "react-router-dom";

const defaultCheckedList = ["1", "2", "3", "4"];
let checkedList = defaultCheckedList;
const plainOptions = [
  { value: "1", label: "Q1" },
  { value: "2", label: "Q2" },
  { value: "3", label: "Q3" },
  { value: "4", label: "Q4" },
];
let selectedYear = moment().get("year");
let selectedSegment = "";

const MaturedFunnelcolor = [
  "#8becf9",
  "#75ECF9",
  "#4FE8FD",
  "#4DE1FD",
  "#0EE6FF",
  "#28DFF9",
  "#12D5EF",
  "#00C3DD",
  "#00B9D1",
];
const ImMaturedFunnelcolor = [
  "#fbd1c4",
  "#fac1b0",
  "#f9b29c",
  "#f7a388",
  "#f69374",
  "#f58461",
  "#f3744d0",
  "#f26539",
];

const Executive1 = (props) => {
  const account = useSelector((state) => state.loginReducer.groups);
  const { fiscalYearList } = useSelector((state) => state.revenueReducer);
  console.log("fiscalYearList",fiscalYearList);

  const [chartData, setChartData] = useState([]);
  const [plannedFunnelData, SetPlannedFunnelData] = useState([]);
  const [actualFunnelData, SetActualFunnelData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [checkAll, setCheckAll] = useState(true);
  const [baselineMetricsFortheYear, setbaselineMetricsFortheYear] = useState(
    []
  );
  const [selectedChartFilter, setSelectedChartFilter] = useState("");
  const [isMaturedFunnel, setIsMaturedFunnel] = useState(false);
  const[leadsStatusDrawer, setLeadsStatusDrawer] = useState(false);
  const[activeLeads,setActiveLeads] = useState([]);
  const[activeOpportunities,setActiveOpportunities] = useState([]);

  useEffect(() => {
    let baselineMetrics = [];
    let fiscalyear = fiscalYearList?.filter(
      (item) => item?.year === selectedYear
    );
    console.log("fiscalyear",fiscalyear);
    if (fiscalyear?.length > 0) {
      fiscalyear[0]?.baselineMetrics?.items?.map((item) =>
        baselineMetrics.push(item)
      );
      console.log("baselineMetrics",baselineMetrics);
      setbaselineMetricsFortheYear(baselineMetrics);
      selectedSegment = baselineMetrics[0]?.name;
    } else {
      setbaselineMetricsFortheYear([]);
    }
  }, []);

  const yearOptions = [
    {
      label: moment().year() - 2,
      value: moment().year() - 2,
    },
    {
      label: moment().year() - 1,
      value: moment().year() - 1,
    },
    {
      label: moment().year(),
      value: moment().year(),
    },
  ];

  // Handle Filters
  // 1. Quarter Filter
  const onQuarterChange = async (list) => {
    checkedList = list;
    setCheckAll(list.length === plainOptions.length);
    await getFunnelDashBoardView();
  };
  const onCheckAllQuarterChange = (e) => {
    let list = [];
    if (e.target.checked) {
      plainOptions.forEach((data) => {
        list.push(data.value);
      });
    }
    checkedList = e.target.checked ? list : [];
    setCheckAll(e.target.checked);
  };

  // // 2. Metrics changes
  // const onMetricsChange = (value) => {
  //   if (value === "actual_value") {
  //     setMatrixValue(value);
  //     setChartData(
  //       allData?.trend_funnel.filter((item) => item.type === "actual_value")
  //     );
  //   } else if (value === "planned_value") {
  //     setMatrixValue(value);
  //     setChartData(
  //       allData?.trend_funnel.filter((item) => item.type === "planned_value")
  //     );
  //   } else {
  //     setMatrixValue(value);
  //     setChartData(allData?.trend_funnel);
  //   }
  // };

  // 3. Year changes

  const OnYearChange = (year) => {
    let baselineMetrics = [];
    let fiscalyear = fiscalYearList?.filter((item) => item?.year === year);
    if (fiscalyear?.length > 0) {
      fiscalyear[0]?.baselineMetrics?.items?.map((item) =>
        baselineMetrics.push(item)
      );
      setbaselineMetricsFortheYear(baselineMetrics);
    } else {
      setbaselineMetricsFortheYear([]);
    }
    selectedYear = year;
    selectedSegment = baselineMetrics[0]?.name;
    getFunnelDashBoardView();
  };

  const onSegmentChange = (segment) => {
    selectedSegment = segment;
    getFunnelDashBoardView();
  };

  // API Integration for the funnel Analysis values
  const getFunnelDashBoardView = async () => {
    setIsLoading(true);

    const quarter = checkedList.join(",");

    let path = `/mapping/get_funnel_analysis?account_id=${account[0]}&years=${selectedYear}&segments=${selectedSegment}&quarters=${quarter}&platform=${props.CRM}`;
    await API.get("backendRest", path)
      .then((res) => {
        setChartData(
          res?.data?.trend_funnel?.map((item) => ({
            ...item,
            quarter: `Q${item?.quarter}`,
          }))
        );
        // set funnel maturity
        setIsMaturedFunnel(() => res?.data?.matured_funnel);  
        
        //set Active Leads
        setActiveLeads(()=> res?.data?.active_leads)

        //set Active Opportunities
        setActiveOpportunities(()=> res?.data?.active_opportunities)

        // Setting Planned value for Funnel chart
        let updatedPlannedFunnel = [];
        res?.data?.planned_funnel.reverse().forEach((stage, index, stages) => {
          const nextConversion = stages[index + 1]?.conversion;
          if (stage.stage !== "Closed Lost") {
            updatedPlannedFunnel.push({
              text: stage.stage,
              value: stage.value,
              width: 100,
              percentageValue: nextConversion,
            });
          }
        });
        SetPlannedFunnelData(updatedPlannedFunnel.reverse());
        setSelectedChartFilter(updatedPlannedFunnel[0]?.text);

        // Setting Actual value for Funnel chart
        let updatedActualFunnel = [];
        res?.data?.actual_funnel?.reverse().forEach((stage, index, stages) => {
          let nextConversion = 0;
          if (index < stages?.length - 1) {
            let nextStageValue = (stage.value * 100) / stages[index + 1]?.value;
            nextConversion = !isNaN(nextStageValue)
              ? Math.round(nextStageValue)
              : 0;
          }
          if (stage.stage !== "Closed Lost") {
            updatedActualFunnel.push({
              text: stage.stage,
              value: stage.value,
              width: 100,
              percentageValue: nextConversion,
              opportunities: stage.opportunities,
            });
          }
        });
        SetActualFunnelData(updatedActualFunnel.reverse());

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        message.error("Something went wrong");
      });
  };

  useEffect(() => {
    if (props?.CRM) {
      getFunnelDashBoardView();
    }
  }, [props?.CRM]);

  //Stage Changes
  let actualData =
    chartData
      ?.filter((item) => item?.type === "actual_value")
      ?.map((item) => ({ ...item, type: "Actual Value" }))
      ?.filter((item) => item?.stage === selectedChartFilter) ?? [];
  let plannedData =
    chartData
      ?.filter((item) => item?.type === "planned_value")
      ?.map((item) => ({ ...item, type: "Planned Value" }))
      ?.filter((item) => item?.stage === selectedChartFilter) ?? [];
  let finalGraphData = [...actualData, ...plannedData];
  const onStageChange = (value) => {
    setSelectedChartFilter(value);
    actualData = chartData
      ?.filter((item) => item?.type === "actual_value")
      ?.map((item) => ({ ...item, type: "Actual Value" }))
      ?.filter((item) => item?.stage === value);
    plannedData = chartData
      ?.filter((item) => item?.type === "planned_value")
      ?.map((item) => ({ ...item, type: "Planned Value" }))
      ?.filter((item) => item?.stage === value);
    finalGraphData = [...actualData, ...plannedData];
  };

  const dualAxisLineChartConfig = {
    data: finalGraphData,
    xField: "quarter",
    yField: "value",
    seriesField: "type",

    xAxis: {
      title: {
        text: "Quarter",
        position: "bottom",
        style: {
          fontSize: 14,
          fontWeight: 600,
        },
      },
    },
    yAxis: {
      title: {
        text: "Value",
        position: "bottom",
        style: {
          fontSize: 14,
          fontWeight: 600,
        },
      },
    },
  };

  const colorArray = isMaturedFunnel ? MaturedFunnelcolor : ImMaturedFunnelcolor;


  return (
    <>
      <div className="site-card-wrapper aboutPage analysis-tab-wrapper" style={{marginTop:'15px', marginBottom:'20px'}}>
        <div style={{ padding: "20px 15px" }}>
          <Row gutter={[20]} style={{marginBottom:'20px'}}>
            <Col
              style={{
                margin: "10px 0px",
              }}
              span={8}
              lg={8}
              md={8}
              xs={24}
              sm={24}
            >
              <div className="year-selector panel-design" style={{border:'1px solid #D0D5DD', padding:'15px'}}>
                <Typography.Text className="selection-header-title" style={{color:"#1D2939", marginBottom:'10px', fontSize:'18px'}}>
                  Year
                </Typography.Text>
                {isLoading ? (
                  <Skeleton.Input style={{ width: "12vw" }} active />
                ) : (
                  <>
                    <Select
                      // defaultValue="All"
                      value={selectedYear}
                      disabled={isLoading}
                      placeholder="Year"
                      style={{ width: '100%' }}
                      options={yearOptions}
                      onChange={OnYearChange}
                      className="general-input-select"
                    />
                  </>
                )}
              </div>
            </Col>
            <Col
              style={{
                padding: "0px 14px",
                margin: "10px 0px",
              }}
              span={8}
              lg={8}
              md={8}
              xs={24}
              sm={24}
            >
              <div className="year-selector panel-design" style={{border:'1px solid #D0D5DD', padding:'15px'}}>
                <Typography.Text className="selection-header-title" style={{color:"#1D2939", marginBottom:'10px', fontSize:'18px'}}>
                  Segment
                </Typography.Text>
                {isLoading ? (
                  <Skeleton.Input style={{ width: "12vw" }} active />
                ) : (
                  <Select
                    value={selectedSegment}
                    disabled={isLoading}
                    className="general-input-select"
                    placeholder={
                      <Typography.Text
                        style={{
                          fontSize: "16px",
                          fontWeight: 200,
                          marginBottom: "5px",
                        }}
                      >
                        Segment
                      </Typography.Text>
                    }
                    style={{ width: '100%' }}
                    onChange={onSegmentChange}
                  >
                    {baselineMetricsFortheYear.map((item) => (
                      <Select.Option value={item?.name}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </div>
            </Col>
            <Col
              style={{
                margin: "10px 0px",
              }}
              span={8}
              lg={8}
              md={8}
              xs={24}
              sm={24}
            >
              <div className="year-selector panel-design" style={{border:'1px solid #D0D5DD', padding:'15px'}}>
                <Typography.Text className="selection-header-title" style={{color:"#1D2939", marginBottom:'10px', fontSize:'18px'}}>
                  Quarter
                </Typography.Text>
                {isLoading ? (
                  <Skeleton.Input style={{ width: "12vw" }} active />
                ) : (
                  <Select
                    // defaultValue="All"
                    disabled={isLoading}
                    className="general-input-select"
                    placeholder={
                      <Typography.Text
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          marginBottom: "5px",
                        }}
                      >
                        Quarter
                      </Typography.Text>
                    }
                    style={{ width: '100%'}}
                    dropdownRender={(menu) => (
                      <div style={{ padding: "10px" }}>
                        <Checkbox
                          onChange={onCheckAllQuarterChange}
                          checked={checkAll}
                        >
                          Select All
                        </Checkbox>
                        <Checkbox.Group
                          options={plainOptions}
                          onChange={onQuarterChange}
                          value={checkedList}
                        />
                      </div>
                    )}
                  ></Select>
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
              <div
                className="analysis-chart-wrapper panel-design"
                style={{ height: "100%" }}
              >
                <div
                  style={{
                    display: "flex",
                    aignItems: "center",
                    justifyContent: "space-between",
                    height: "32px",
                  }}
                >
                  <Typography.Text
                    style={{ fontSize: "20px", fontWeight: 600 }}
                  >
                    Planned Value
                  </Typography.Text>
                </div>
                {isLoading ? (
                  <Skeleton />
                ) : plannedFunnelData?.length > 0 ? (
                  <FunnelCharts
                    data={plannedFunnelData}
                    color={[
                      // "#8becf9",
                      // "#75ECF9",
                      // "#4FE8FD",
                      // "#4DE1FD",
                      // "#0EE6FF",
                      // "#28DFF9",
                      // "#12D5EF",
                      // "#00C3DD",
                      // "#00B9D1",
                      
                      
                      // "#D6BBFB",
                      "#B692F6",
                      "#9E77ED",
                      "#7F56D9",
                      "#6941C6",
                      "#53389E", 
                      
                    ]}
                    height={plannedFunnelData.length * 75}
                    conversionTag={true}
                    labelColor="#fff"
                  />
                ) : (
                  <EmptyChart />
                )}
              </div>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="analysis-chart-wrapper panel-design">
                <div
                  style={{
                    display: "flex",
                    aignItems: "center",
                    justifyContent: "space-between",
                    height: "32px",
                  }}
                >
                  <Typography.Text
                    style={{
                      fontSize: "20px",
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Actual Value
                  </Typography.Text>
                  {isMaturedFunnel ? (
                    <Tooltip
                      title="There are no more active leads, and all opportunities are marked closed won or closed lost."
                      placement="top"
                    >
                      <Button
                        type="primary"
                        size="small"
                        style={{
                          height: "34px",
                          fontWeight: 600,
                          backgroundColor: "#FB6514",
                          borderColor: "#FB6514",
                        }}
                      >
                        Cohort is Complete
                      </Button>
                    </Tooltip>
                  ) : (
                    <>
                    {!isLoading && 
                    <Tooltip
                      title="This cohort still has active leads, or opportunities that are still open or are not yet closed won or closed lost."
                      placement="top"
                      
                    >
                      <Button
                        type="primary"
                        size="small"
                        style={{
                          height: "34px",
                          fontWeight: 600,
                          backgroundColor: "#FB6514",
                          borderColor: "#FB6514",
                        }}
                        onClick={() => setLeadsStatusDrawer(true)}
                        disabled={isLoading}
                        
                      >
                        Cohort is Still Active
                      </Button>
                    </Tooltip>
                  }
</>
                  )}
                </div>
                {isLoading ? (
                  <Skeleton />
                ) : (actualFunnelData || [])?.length > 0 ? (
                  <FunnelChartsExecutive
                    data={actualFunnelData}
                    color={[
                      // "#8becf9",
                      // "#75ECF9",
                      // "#4FE8FD",
                      // "#4DE1FD",
                      // "#0EE6FF",
                      // "#28DFF9",
                      // "#12D5EF",
                      // "#00C3DD",
                      // "#00B9D1",

                      '#FD853A',
                      '#FB6514',
                      '#EC4A0A',
                      '#C4320A',
                      '#9C2A10'
                    ]}
                    height={actualFunnelData.length * 75}
                    conversionTag={true}
                    labelColor="#fff"
                    transform={false}
                    isActual={true}
                  />
                ) : (
                  <EmptyChart />
                )}
                {/* <ColumnLineChart />
                 */}
                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography.Text
                    style={{
                      fontSize: "13px",
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                      marginRight: "25px",
                    }}
                  >
                    <span
                      style={{
                        height: "16px",
                        width: "16px",
                        display: "inline-block",
                        background: "#00B9D1",
                        marginRight: 6,
                      }}
                    ></span>
                    Matured Funnel
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      fontSize: "13px",
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        height: "16px",
                        width: "16px",
                        display: "inline-block",
                        background: "#f26539",
                        marginRight: 6,
                      }}
                    ></span>
                    ImMatured Funnel
                  </Typography.Text>
                </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="site-card-wrapper aboutPage analysis-tab-wrapper">
        <div style={{ padding: "20px" }}>
          <Row>
            <Col span={24}>
              {/* <div className="year-selector">
                <Typography.Text
                  style={{
                    fontSize: "16px",
                    marginLeft: "5px",
                    fontWeight: 500,
                  }}
                >
                  Metrics Selector
                </Typography.Text>
                <Select
                  value={matrixValue}
                  placeholder={
                    <Typography.Text
                      style={{
                        fontSize: "16px",
                        fontWeight: 200,
                        marginBottom: "5px",
                      }}
                    >
                      Metrics Selector
                    </Typography.Text>
                  }
                  style={{ width: 172, padding: "10px 0px" }}
                  options={[
                    { value: "all", label: "Select All" },
                    { value: "planned_value", label: "Planned Value" },
                    { value: "actual_value", label: "Actual Value" },
                  ]}
                  onChange={onMetricsChange}
                ></Select>
              </div> */}
              <div className="year-selector panel-design" style={{border:'1px solid #D0D5DD', padding:'15px', marginBottom:'20px'}}>
                <Typography.Text
                  style={{
                    fontSize: "18px",
                    marginLeft: "10px",
                    fontWeight: 700,
                    color:"#1D2939"
                  }}
                >
                  Stage Selector
                </Typography.Text>
                {isLoading ? (
                  <Skeleton.Input style={{ width: "12vw" }} active />
                ) : (
                  <Select
                    value={selectedChartFilter}
                    disabled={isLoading}
                    placeholder={
                      <Typography.Text
                        style={{
                          fontSize: "16px",
                          fontWeight: 200,
                          marginBottom: "5px",
                        }}
                      >
                        Stage Selector
                      </Typography.Text>
                    }
                    style={{ width: 300, padding: "10px 0px" }}
                    onChange={onStageChange}
                    className="general-input-select"
                  >
                    {plannedFunnelData?.map((item) => (
                      <Select.Option value={item?.text}>
                        {item?.text}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </div>
              <div
                style={{
                  
                  borderRadius: "7px",
                  padding: "10px",
                }}
                className="panel-design"
              >
                <Typography.Text
                  style={{
                    fontSize: "15px",
                    fontWeight: 600,
                    marginBottom: "5px",
                  }}
                >
                  Funnel Trends
                </Typography.Text>
                {isLoading ? (
                  <Skeleton />
                ) : chartData?.length > 0 ? (
                  <Line {...dualAxisLineChartConfig} />
                ) : (
                  <EmptyChart />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Drawer
      title={
        <Space style={{ width: "100%", justifyContent: "space-between" }}>
          <Space>
            <div style={{ fontWeight: "700" }}>Leads / Opportunities</div>
            {/* {marketLoader && <LoadingOutlined style={{ marginLeft: 10 }} />} */}
          </Space>
        </Space>}
        visible={leadsStatusDrawer}
        className="createAssetModal market-drawer status-drawer"
        onClose={() => setLeadsStatusDrawer(false)}
      >
        {activeLeads?.length > 0 && 
        <Descriptions size={"small"} bordered className="lead_status_area">
          <Descriptions.Item span={3} label={'Lead'} className="list_head">Lead Status</Descriptions.Item>
          {activeLeads?.map((item, index) => (
            
              <Descriptions.Item span={3} label={<a href={item?.url} target="_blank">{item?.title}</a>} key={`${index}`}>
                <span>{item?.value}</span>
              </Descriptions.Item>
            )
          )}
        </Descriptions>
        }
        {activeOpportunities?.length > 0 && 
        <Descriptions size={"small"} bordered className="opportunity_status_area" >
          <Descriptions.Item span={3} label={'Opportunity'} className="list_head">Opportunity Stage</Descriptions.Item>
          {activeOpportunities?.map((item, index) => (
            
              <Descriptions.Item span={3} label={item?.title} key={`${index}`}>
                <span>{item?.value}</span>
              </Descriptions.Item>
            ))}
        </Descriptions>
        }
        
      </Drawer>
    </>
  );
};

export default Executive1;
