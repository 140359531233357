import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Input, Drawer, Tooltip, Select, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { setServerError } from "store/actions/budgetAction";
import { createPackageData, updatePackageData } from "store/actions/reportAction";
import sdk from "sdk/Accounts";

const CreatePackage = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const serverError = useSelector((state) => state.budgetReducer.serverError);
  const { isLoading } = useSelector((state) => state.reportReducer);
  const [properties, setProperties] = useState([]);
  const { Option } = Select;

  const getProperties = async () => {
    let listProperty = await sdk.listDefaultProperties();
    setProperties(listProperty);
  };
  useEffect(() => {
    getProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedPropertiesEdit = []
    if(props.isEditPackage) {
      form.setFieldsValue({
        name: props.selectedPackageDetails?.name,
      });

      props.selectedPackageInfo?.map((val) => { // eslint-disable-line
        selectedPropertiesEdit.push(val?.key)
      })

      const propertiesUpdate = [];

      properties && properties?.length > 0 && properties?.map((val) => { // eslint-disable-line
        let selectedObj = selectedPropertiesEdit?.find((item) => item?.[0] === val?.name)
        if(selectedObj) {
          propertiesUpdate.push({name:selectedObj?.[0], value:selectedObj?.[1]})
        } else {
          propertiesUpdate.push(val)
        }
      })
      setProperties(propertiesUpdate)

      form.setFieldsValue({
        properties: selectedPropertiesEdit,
      });
    } else {
      form.setFieldsValue({
        name: "",
      });
      properties?.map((val) => { // eslint-disable-line
        selectedPropertiesEdit.push(val?.name)
      })

      form.setFieldsValue({
        properties: selectedPropertiesEdit,
      });
    }
    
  
  }, [form, props.selectedPackageDetails, props.selectedPackageInfo, props.isEditPackage, props.visible]); // eslint-disable-line


  const handleOk = (value) => {

      if(props.isEditPackage) {
        value.id = props.selectedPackageDetails?.id;
        value.existingProperties = props.selectedPackageInfo;
        const setPropertiesVal = []
        value?.properties?.map((item) => { // eslint-disable-line
          const selectedVal = props.selectedPackageInfo?.find((val) => val?.key === item)
          if(selectedVal) {
            setPropertiesVal.push({name:selectedVal?.key, value:selectedVal?.value})
          } else {
            const newSelVal = properties?.find((itemnew) => itemnew.name === item)
            setPropertiesVal.push({name:newSelVal?.name, value:newSelVal?.value})
          }
        })
        value.properties = setPropertiesVal
        dispatch(updatePackageData(value));
      } else {
        const setPropertiesVal = []
        value?.properties?.map((item) => { // eslint-disable-line
          const newSelVal = properties?.find((itemnew) => itemnew.name === item)
          setPropertiesVal.push({name:newSelVal?.name, value:newSelVal?.value})
        })
        value.properties = setPropertiesVal
        dispatch(createPackageData(value));
      }
  };

  useEffect(() => {
    if (serverError === "successPackageEdit") {
      message.success("Success")
      form.resetFields();
      props.handleCancel();
      dispatch(setServerError(""));
    }
  }, [serverError]); // eslint-disable-line

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        handleOk(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <Drawer
        title={
          props.isEditPackage ? (
            <strong>Edit Package</strong>
          ) : (
            <strong>Create New Package</strong>
          )
        }
        className='create-package-drawer'
        width={416}
        onClose={props.handleCancel}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        maskClosable={false}
      >
        {/* {isLoading && <div className="loading">Loading;</div>} */}
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
          requiredMark={"optional"}
          className = "create-package"
        >
          <Form.Item
            name="name"
            label={
              <span>
                <strong>Package Name&nbsp;</strong>
                <Tooltip title="Package  info" placement="left">
                  <InfoCircleOutlined style={{ float: "right" }} />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
              {
                pattern: /^[a-zA-Z0-9_ ]+$/,
                message: "Special charactors are not allowed.",
              },
            ]}
          >
            <Input style={{ marginBottom: 1 }} placeholder="Package Name" className="general-input"/>
          </Form.Item>

          <Form.Item
            name="properties"
            label={
              <span>
                <strong>Selected Properties</strong>
                <Tooltip title="Property select info" placement="left">
                  <InfoCircleOutlined
                    style={{ float: "right" }}
                    className="font-color-budget"
                  />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <Select
              size="large"
              showSearch
              style={{ width: "100%" }}
              placeholder="Select properties"
              optionFilterProp="children"
              key="properties"
              mode="multiple"
              // defaultValue={props.channelIdForCampaign}
            >
              {properties &&
                properties.length > 0 &&
                properties.map((item, indexval) => {
                  return (
                    <Option key={indexval} value={item.name}>
                      {item.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}
        >
          <Button
            className="secondaryButton"
            onClick={props.handleCancel}
            disabled={isLoading}
            style={{ fontSize: 18, marginRight: 8, width: "48%" }}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            type="primary"
            style={{ fontSize: 18, width: "48%" }}
            loading={isLoading}
            className="primaryButton"
          >
            Save
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default CreatePackage;
