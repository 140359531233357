import { Button, Modal, Row, Select, Space, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import "./ExecutiveV2.less";
import Executive1 from "./Executive1";
import DealAnalysis from "./DealAnalysis";
import ExecutiveExplore from "./ExecutiveExplore";
import { useDispatch, useSelector } from "react-redux";
import { getConnectedCRMs } from "store/actions/reportAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ReportingHeader from "../../ReportingHeader";
import PreviewInfo from "commons/PreviewInfo";
import reporting_analytics_module_preview from "assets/Images/reporting_analytics_module_preview.png";
import GoogleAnalytics from "./GoogleAnalytics";
import Analytics from "pages/reports/Analytics/V2/Analytics";
import SalesforceImg from "assets/Images/Social/Salesforce.png";
import HubspotImg from "assets/Images/Social/hubspot.jpg";
import { ApiOutlined } from "@ant-design/icons";
import { TrackClickFunction } from "commons/HeapTrack/TrackClickFunction";

const DemoAnalysis = () => {
  const [currentTab, setCurrentTab] = useState("analysis");
  const dispatch = useDispatch();
  const history = useHistory();
  const account = useSelector((state) => state.loginReducer.groups);
  const { userPermission, viewType } = useSelector(
    (state) => state.loginReducer
  );

  const permissions = {
    executive: userPermission?.["reporting.executive"],
    "google-analytics": userPermission?.["reporting.analytics"],
    "sales-and-marketing":
      userPermission?.["reporting.salesandmarketingleadership"],
  };
  const showPreviewOnly =
    (permissions["executive"] === "preview" &&
      account?.[2] === "admin" &&
      !(viewType === "companyadmin" || viewType === "companycontributor")) ||
    (permissions["executive"] === "preview" && account?.[2] !== "admin");

  const [availableCRMS, setavailableCRMS] = useState([]);
  const [noCRMConnected, setnoCRMConnected] = useState(false);
  const [selectedCRM, setselectedCRM] = useState();

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const init = async () => {
    let ConnectedCRMs = await dispatch(getConnectedCRMs());
    let CRMS = [];
    if (Object.keys(ConnectedCRMs)?.length) {
      Object.keys(ConnectedCRMs)?.forEach((key) => {
        if (["Hubspot", "Salesforce"]?.includes(key)) {
          CRMS.push(key);
        }
      });
    }
    setnoCRMConnected(CRMS?.length === 0);
    setavailableCRMS(CRMS);
    console.log("CRMS", CRMS);
    // Check if "Salesforce" is available in the CRMS array
    CRMS.includes("Salesforce")
      ? setselectedCRM("Salesforce")
      : setselectedCRM(CRMS[0]);
  };

  const goToIntegrations = () => {
    history.push("/settings");
  };

  const changeTab = (e) => {
    setCurrentTab(e);
  };

  return (
    <>
      {!showPreviewOnly && noCRMConnected && (
        <Modal
          className="delete-revenue-model"
          visible
          centered
          footer={null}
          width="350px"
          closable={false}
          style={{ borderRadius: "20px" }}
        >
          <Space direction="vertical" align="center">
            <div>
              <Typography.Title level={5}>
                {" "}
                No CRM Connection Found{" "}
              </Typography.Title>
              <Typography.Text>
                Please connect any of CRM options available at{" "}
                <strong>Company Settings &gt; Integrations &gt; CRM</strong>
                <br />
                to view Dashboard
              </Typography.Text>
            </div>
            <Button type="primary" onClick={goToIntegrations}>
              Connect{" "}
            </Button>
          </Space>
        </Modal>
      )}
      <ReportingHeader type="executive" />
      {showPreviewOnly ? (
        <PreviewInfo
          img={reporting_analytics_module_preview}
          text="Upgrade to View"
          centerStyle={{ top: "30%" }}
        />
      ) : (
        <div >
          <Tabs
            activeKey={currentTab}
            onChange={changeTab}
            className="executive-tabs"
            tabBarExtraContent={
              <Row gutter={[10, 10]} justify="end" align="center">
                {availableCRMS?.length > 1 ? (
                  <>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 10 }}
                    >
                      <Typography.Text> Data Source : </Typography.Text>

                      <Select
                        placeholder="Please Select Data Source"
                        style={{ width: 120 }}
                        value={selectedCRM}
                        onChange={setselectedCRM}
                      >
                        {availableCRMS?.map((item) => {
                          return (
                            <Select.Option value={item}>{item}</Select.Option>
                          );
                        })}
                      </Select>
                    </div>
                  </>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {selectedCRM === "Salesforce" && (
                      <div className="social_images">
                        <img
                          src={SalesforceImg}
                          alt={"Salesforce"}
                          style={{ width: "30px" }}
                        />
                      </div>
                    )}
                    {selectedCRM === "Hubspot" && (
                      <div className="social_images">
                        <img
                          src={HubspotImg}
                          alt={"Hubspot"}
                          style={{ width: "30px" }}
                        />
                      </div>
                    )}
                    {selectedCRM !== "Salesforce" &&
                      selectedCRM !== "Hubspot" && (
                        <div className="social_default_image">
                          <ApiOutlined
                            style={{ fontSize: "22px", color: "#fff" }}
                          />
                        </div>
                      )}

                    <Typography.Text style={{ marginLeft: "5px" }}>
                      {" "}
                      Data Source{" "}
                      <div>
                        <strong>{selectedCRM}</strong>
                      </div>{" "}
                    </Typography.Text>
                  </div>
                )}
              </Row>
            }
          >
            <Tabs.TabPane
              key="analysis"
              tab={
                <span
                  style={{ fontSize: "16px" }}
                  onClick={() =>
                    TrackClickFunction(
                      "executive-dashboard-funnel-analysis-menu"
                    )
                  }
                >
                  Funnel Analysis
                </span>
              }
            >
              {currentTab === "analysis" && <Executive1 CRM={selectedCRM} />}
            </Tabs.TabPane>
            <Tabs.TabPane
              key="dealAnalysis"
              tab={
                <span
                  style={{ fontSize: "16px" }}
                  onClick={() =>
                    TrackClickFunction("executive-dashboard-deal-analysis-menu")
                  }
                >
                  Deal Analysis
                </span>
              }
            >
              {currentTab === "dealAnalysis" && (
                <DealAnalysis CRM={selectedCRM} />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane
              key="explore"
              tab={
                <span
                  style={{ fontSize: "16px" }}
                  onClick={() =>
                    TrackClickFunction("executive-dashboard-explore-menu")
                  }
                >
                  Explore
                </span>
              }
            >
              {currentTab === "explore" && (
                <ExecutiveExplore CRM={selectedCRM} />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane
              key="googleAnalytics"
              tab={
                <span
                  style={{ fontSize: "16px" }}
                  onClick={() =>
                    TrackClickFunction(
                      "executive-dashboard-google-analytics-menu"
                    )
                  }
                >
                  Google Analytics
                </span>
              }
            >
              {currentTab === "googleAnalytics" &&
                ((userPermission?.["reporting.analytics"] &&
                  userPermission?.["reporting"] &&
                  userPermission?.["reporting.analytics"] !== "hidden" &&
                  userPermission?.["reporting"] !== "hidden") ||
                  (account?.[2] === "admin" &&
                    !(
                      viewType === "companyadmin" ||
                      viewType === "companycontributor"
                    ))) && <Analytics />}
            </Tabs.TabPane>
          </Tabs>
        </div>
      )}
    </>
  );
};

export default DemoAnalysis;
