import {
  Col,
  Row,
  Select,
  Skeleton,
  Switch,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import GroupedColumnChart from "./GroupedColumnChart";
import EmptyChart from "commons/EmptyChart";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { Line } from "@ant-design/charts";

let closedValue = 0;
let breakByValue = "CONTACT_CREATED";
let axisValue = "CONTACT_CREATED";
let DealOptions = [
  {
    value: "CONTACT_CREATED",
    label: "Quarter (Contact Created)",
  },
  { value: "DEAL_CLOSED", label: "Quarter (Deal Closed)" },
  { value: "DEAL_CREATED", label: "Quarter (Deal Created)" },
];

const ExecutiveExplore = (props) => {
  const account = useSelector((state) => state.loginReducer.groups);

  //sum of deal age table column define here
  const columns = [
    {
      title: <b>Quarter (Deal Created)</b>,
      dataIndex: "deal_created_quarter",
      width: 170,
      render: (text) => {
        return (
          <Typography.Text className="table-row-data">{text}</Typography.Text>
        );
      },
    },
    {
      title: <b>Quarter (Deal Closed)</b>,
      dataIndex: "deal_closed_quarter",
      width: 170,
      render: (text) => {
        return (
          <Typography.Text className="table-row-data">{text}</Typography.Text>
        );
      },
    },
    {
      title: <b>Has Closed Amount</b>,
      dataIndex: "hasclosedamount",
      width: 170,
      render: (text) => {
        return (
          <Typography.Text className="table-row-data">
            {text ? "Yes" : "No"}
          </Typography.Text>
        );
      },
    },
    {
      title: <b>is Last Stage</b>,
      dataIndex: "islaststage",
      width: 130,
      render: (text) => {
        return (
          <Typography.Text className="table-row-data">
            {text ? "Yes" : "No"}
          </Typography.Text>
        );
      },
    },
    {
      title: <b>Stage</b>,
      dataIndex: "stage_name",
      width: 200,
      render: (text) => {
        return (
          <Typography.Text className="table-row-data">{text}</Typography.Text>
        );
      },
    },
    {
      title: <b>Deal</b>,
      dataIndex: "cnt_deals",
      width: 170,
      render: (text) => {
        return (
          <Typography.Text className="table-row-data">{text}</Typography.Text>
        );
      },
    },
    {
      title: <b>Deal Age (Quarters)</b>,
      dataIndex: "deal_age_quarter",
      width: 170,
      render: (text) => {
        return (
          <Typography.Text className="table-row-data">{text}</Typography.Text>
        );
      },
    },
  ];

  //Integration for explore tab
  const [isLoading, setIsLoading] = useState(false);
  const [isDealLoading, setIsDealLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [dealAgeData, setDealAgeData] = useState([]);
  const [dealAnalysisData, setDealAnalysisData] = useState([]);
  // const [breakByValue, setBreakByValue] = useState("CONTACT_CREATED");

  //Handling all the filter
  // 1. Closed won filter
  const OnClosedWonChange = async (value) => {
    closedValue = Number(value);

    await getDealAnalysisData();
  };

  const onBreakValueChange = async (breakValue) => {
    breakByValue = breakValue;
    await getDealAnalysisData();
  };
  const onAxisValueChange = async (value) => {
    axisValue = value;
    await getDealAnalysisData();
  };

  //Get the deal age data and the table data
  const getDealAndSumData = async () => {
    setIsLoading(true);

    let path = `/mapping/get_explore_table_data?account_id=${account[0]}&platform=${props.CRM}`;
    await API.get("backendRest", path)
      .then((res) => {
        //Setting the table data
        setTableData(res?.data?.table_data);
        //Setting the chart data for deal age
        setDealAgeData(res?.data?.deal_age_data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        message.error("Something went wrong");
      });
  };
  const getDealAnalysisData = async () => {
    setIsDealLoading(true);

    let path = `/mapping/get_explore_deal_analysis?account_id=${account[0]}&is_closed=${closedValue}&x_axis=${axisValue}&break_by=${breakByValue}&platform=${props.CRM}`;
    await API.get("backendRest", path)
      .then((res) => {
        setDealAnalysisData(res?.data?.deal_analysis);
        setIsDealLoading(false);
      })
      .catch((err) => {
        setIsDealLoading(false);
        console.log(err);
        message.error("Something went wrong");
      });
  };

  useEffect(() => {
    getDealAndSumData();
    getDealAnalysisData();
    // eslint-disable-next-line
  }, [props?.CRM]);

  const dealAgeChartConfig = {
    data: dealAgeData,
    padding: "auto",
    xField: "contact_created_quarter",
    yField: "deal_age",
    smooth: false,
  };

  return (
    <>
      <div className="site-card-wrapper aboutPage analysis-tab-wrapper" style={{marginTop:'20px'}}>
        <div style={{ padding: "20px 15px" }}>
          <Row gutter={[20, 20]} style={{marginBottom:'20px'}}>
            <Col span={8}
              lg={8}
              md={8}
              xs={24}
              sm={24}>
              <div className="year-selector panel-design" style={{border:'1px solid #D0D5DD', padding:'15px'}}>
                <Typography.Text className="selection-header-title" style={{color:"#1D2939", marginBottom:'10px', fontSize:'18px'}}>
                  Quarter Selector (x-axis)
                </Typography.Text>
                {isDealLoading ? (
                  <Skeleton.Input style={{ width: "17vw" }} active />
                ) : (
                  <Select
                    value={axisValue}
                    disabled={isDealLoading}
                    placeholder={
                      <Typography.Text
                        style={{
                          fontSize: "16px",
                          fontWeight: 200,
                          marginBottom: "5px",
                        }}
                      >
                        Quarter Selector (x-axis)
                      </Typography.Text>
                    }
                    style={{ width: '100%' }}
                    onChange={onAxisValueChange}
                  >
                    {DealOptions.map((item) => {
                      return (
                        <Select.Option value={item.value} key={item?.label}>
                          {item?.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </div>
            </Col>
            <Col
              style={{
                
                padding: "0px 14px",
                flex: 1,
              }}
              span={8}
              lg={8}
              md={8}
              xs={24}
              sm={24}
            >
              <div className="year-selector panel-design" style={{border:'1px solid #D0D5DD', padding:'15px'}}>
                <Typography.Text className="selection-header-title" style={{color:"#1D2939", marginBottom:'10px', fontSize:'18px'}}>
                  Quarter Selector (break by)
                </Typography.Text>
                {isDealLoading ? (
                  <Skeleton.Input style={{ width: "17vw" }} active />
                ) : (
                  <Select
                    value={breakByValue}
                    disabled={isDealLoading}
                    placeholder={
                      <Typography.Text
                        style={{
                          fontSize: "16px",
                          fontWeight: 200,
                          marginBottom: "5px",
                        }}
                      >
                        Quarter Selector (break by)
                      </Typography.Text>
                    }
                    style={{ width: '100%' }}
                    onChange={onBreakValueChange}
                  >
                    {DealOptions.map((item) => {
                      return (
                        <Select.Option value={item.value} key={item.label}>
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </div>
            </Col>
            
              <Col
                
                span={8}
              lg={8}
              md={8}
              xs={24}
              sm={24}
              
              >
                <div className="year-selector panel-design" style={{border:'1px solid #D0D5DD', padding:'15px', height:'100%'}}>
                <div style={{display:'flex', alignItems:'center', justifyContent:"center", height:'100%'}}>
                <Typography.Text className="selection-header-title" >
                  Is Closed?{" "}
                  <Switch
                    checked={closedValue}
                    disabled={isDealLoading}
                    onChange={OnClosedWonChange}
                  />
                </Typography.Text>
                </div>
                </div>
              </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
              <div
                className="analysis-chart-wrapper panel-design"
                style={{ height: "100%" }}
              >
                <Typography.Text className="label-title-text" style={{ fontSize: "18px", fontWeight: 500, color:"#1D2939", marginBottom:'15px' }}>
                  Deal Analysis
                </Typography.Text>
                {isDealLoading ? (
                  <Skeleton.Button
                    style={{
                      width: "35vw",
                      height: 400,
                      marginLeft: "10px",
                    }}
                    active
                  />
                ) : dealAnalysisData?.length > 0 ? (
                  <div style={{ height: "80%", marginTop: "20px" }}>
                    <GroupedColumnChart data={dealAnalysisData} />
                  </div>
                ) : (
                  <div className="emptychart-wrapper">
                    <EmptyChart />
                  </div>
                )}
              </div>
            </Col>
            <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
              <div
                className="analysis-chart-wrapper panel-design"
                style={{ height: "100%" }}
              >
                <Typography.Text className="label-title-text" style={{ fontSize: "18px", fontWeight: 500, color:"#1D2939", marginBottom:'15px' }}>
                  Sum of Deal Age (Quarters) by Quarter (Contact Created)
                </Typography.Text>
                {isLoading ? (
                  <Skeleton.Button
                    style={{ width: "32vw", height: 400 }}
                    active
                  />
                ) : dealAgeData?.length > 0 ? (
                  <div style={{ height: "80%", marginTop: "20px" }}>
                    <Line {...dealAgeChartConfig} />
                  </div>
                ) : (
                  <div className="emptychart-wrapper">
                    <EmptyChart />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="site-card-wrapper aboutPage analysis-tab-wrapper">
        <div style={{ padding: "20px" }}>
          <Row>
            <Col span={24}>
              <div>
                <Typography.Text className="label-title-text">
                  Sum of Multiple Values
                </Typography.Text>
                <Tooltip
                  title="Sum of Deals and Sum of Deal Age (Quarters) by Quarter (Deal Created), Quarter (Deal Closed), Hasclosedamount, Islaststage, Advertiser_group_id, and Stage
"
                  placement="right"
                >
                  <InfoCircleOutlined
                    style={{
                      color: "#A9A9A9",
                      fontSize: "18px",
                      marginLeft: "5px",
                    }}
                  />
                </Tooltip>
              </div>
              {isLoading ? (
                <Skeleton paragraph={{ rows: 5 }} />
              ) : (
                <Table
                  columns={columns}
                  bordered
                  dataSource={tableData}
                  pagination={false}
                  scroll={{
                    y: 500,
                    x: 1000,
                  }}
                  className="nowrap custom-table general-table"
                />
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ExecutiveExplore;
