import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Checkbox, Drawer, Form, Input, message, Modal, Select, Tooltip, Typography } from "antd";
import RevenueTextField from "commons/RevenueTextField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBudget } from "store/actions/budgetAction";
import {
  createBaseLineMetric,
  setFunnelStagesList,
  createFunnelConfiguration,
  createFunnelStages,
  updateBaseLineMetric,
  setLoader
} from "store/actions/revenueAction";

function CreateBaseLineMetrics(props) {

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.loginReducer.groups);
  const revenueData = useSelector((state) => state.revenueReducer);
  const { funnelStages, fiscalYear, fiscalYearList } = revenueData;
  const { salesAndMarketingStages } = useSelector((state) => state.salesAndMarketingReducer);
  
  const [funnelStageCustom, setfunnelStageCustom] = useState([])
  const predefineCampaignTypes = ['PRODUCT', 'REGION', 'PERSONA', 'OTHER']
  const [createBudgetDefault, setcreateBudgetDefault] = useState(false)
  const [isConfirmModelOpen, setIsConfirmModalOpen] = useState(false)
  const [storeNewSegmentData, setStoreNewSegmentData] = useState({})
  

  useEffect(() => {
    if (props.isEdit) { 
      form.setFieldsValue({ name: props?.baselineMetrics?.name, SegmentType:props?.baselineMetrics?.segment_type})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])
  
  const [isLoading, setisLoading] = useState(false)
  const handleOk = async (requestData) => {
    setisLoading(true)
    if (props.isEdit) {
      requestData.id = props?.baselineMetrics?.id
      await dispatch(updateBaseLineMetric(requestData));
      message.success("Segment updated successfully");
      props.handleCancel()
      setisLoading(false)
      await props.reloadTableData(fiscalYear.id)

    }
    else {
      let metricData = dispatch(createBaseLineMetric(requestData));
      metricData.then(async (response) => {
        if (createBudgetDefault) {
          let budgetAllocation = {}

          // let perQuarterBudget = fiscalYear?.budget/4
          let perQuarterBudget = budgetAmount/4
          let quarterlydiffrence = parseInt(perQuarterBudget) * 4
          if (quarterlydiffrence !== perQuarterBudget) {
            let Budget = {
              budget_q1 : parseInt(perQuarterBudget),
              budget_q2 : parseInt(perQuarterBudget),
              budget_q3 : parseInt(perQuarterBudget),
              budget_q4 : parseInt(perQuarterBudget)
            }
            if (budgetAmount - parseInt(quarterlydiffrence) === 1) {
                Budget.budget_q4 += 1 
            }
            if (budgetAmount - parseInt(quarterlydiffrence) === 2) {
                Budget.budget_q4 += 1
                Budget.budget_q3 += 1
            }
            if (budgetAmount - parseInt(quarterlydiffrence) === 3) {
                Budget.budget_q4 += 1
                Budget.budget_q3 += 1
                Budget.budget_q2 += 1
            }
            budgetAllocation = JSON.stringify(Budget)
          }
          let budgetReqData = {
            BaselineMetricID: [response?.id],
            account: account[0],
            active: false,
            budget: budgetAmount,
            budget_allocation:budgetAllocation,
            name: requestData?.name,
            year: props?.year,
          }
          createBudgetForSegment(budgetReqData)
        }
        let funnelConfigData = await createFunnelConfig(response?.id);
        let funnelStage = await createFunnel(funnelConfigData?.id);
        if (funnelStage?.length > 0) {
          await dispatch(setFunnelStagesList(funnelStage));
        }
        await props.handleCancel()
        await props.reloadTableData(fiscalYear.id)
        setisLoading(false)
        form.setFieldsValue({ name: "" })
       }).catch(e=>{console.log("error: ", e);
       setisLoading(false)
      
      })
      dispatch(setLoader(true))
    } 

  };

  const createFunnelConfig = (metricId) => {
    const requestData = {
      baselineMetricId: metricId,
        name: "FunnelConfig",
      account: account[0]
      };
      
    return dispatch(createFunnelConfiguration(requestData))
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e;
    });
    
  };

  const createFunnel = async (id) => {
    let apiCall = [];
    funnelStageCustom.map((item, index) => {
       let requestData = {
          funnelConfigID: id,
          order: item?.order,
          name: item?.name,
          conversion: 50,
          account: account[0],
          salesAndMarketingAlignmentID:item?.salesAndMarketingAlignmentID
        };
      apiCall.push(requestData);
      return 0;
    });
    return Promise.all(
      apiCall.map((item) =>
          dispatch(createFunnelStages(item))
      )
    ).then((response) => {
      return response;
    });
    };
    
  const createBudgetForSegment = (data) => {
    dispatch(createBudget(data))
  }
  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (fiscalYearList.length > 0) {
          let name = values['name'].trim()
          if (name.length === 0) {
            form.setFieldsValue({name : name})
          }
          else {
            const requestData = {
              name: name,
              fiscalYearId: fiscalYear.id,
              account: account[0],
              mrktSourcePercent: props.isEdit ? props.baselineMetrics.marketing_percent : 50,
              segment_type:values?.SegmentType,
              ...(fiscalYear?.baselineMetrics?.items?.length === 0 && { goal: fiscalYear.budget }),
            };
            if (!props.isEdit && props.isConnectedAndSelected && fiscalYear?.baselineMetrics?.items?.length >= 1) {
              setIsConfirmModalOpen(true)
              setStoreNewSegmentData(requestData)
            }
            else {
              handleOk(requestData);    
            }
          }
          } 
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const onCancelClick = () => {
    setIsConfirmModalOpen(false)
    props.handleCancel()
  }
  const onSaveClick = () => {
    setIsConfirmModalOpen(false)
    handleOk(storeNewSegmentData)
  
  }

  useEffect(() => {
    let customStages = salesAndMarketingStages?.map(stage => {
      if (!stage.disabled && stage.internalStage !== "Closed Lost") {
          return ({name:stage?.name,conversion:50,salesAndMarketingAlignmentID:stage?.id, order:stage?.order})
      }
      return undefined
    }).filter(item => item !== undefined)

    let last = salesAndMarketingStages?.find(stage => stage?.isLast)
    let incustom = customStages.find(stage => stage.salesAndMarketingAlignmentID === last?.id)
    let maxorder = 0
    let max = {} 
    customStages.forEach(stage => {
      if (maxorder < stage.order) {
        maxorder = stage.order;
        max = stage
      }
    })

    if (incustom) {
      let currentorder = customStages.indexOf(incustom)
      let maximum = customStages.indexOf(max)
      customStages[maximum].order = customStages[currentorder].order 
      customStages[currentorder].order = maxorder

    }
    else {
      customStages.push({name:last?.name,conversion:50,salesAndMarketingAlignmentID:last?.id})
    }
    
    customStages.sort(sortingOrder)
    setfunnelStageCustom(customStages)
  }, [funnelStages, salesAndMarketingStages])
  

  const sortingOrder = (a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  };

  const [budgetAmount, setbudgetAmount] = useState(0)


  return (
    <>
      <Drawer
        title={
          <>{props.isDelete ? (
            <strong>Delete Segment</strong>) : 
      props.isEdit ? (
              <strong>Edit Segment</strong>) :
        (<strong>Create Segment</strong>)} {isLoading && <LoadingOutlined style={{marginLeft:10}}/>}  </>
          }
        width={416}
        onClose={props.handleCancel}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
          requiredMark={"optional"}
        >
          <Form.Item
            name="name"
            label={
              <span>
                <strong>Add Segment Name&nbsp;</strong>
                <Tooltip title="Enter Segment Name" placement="left">
                  <InfoCircleOutlined style={{ float: "right" }} />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <Input placeholder="Segment Name" className="general-input"/>
          </Form.Item>
          <Form.Item
            name="SegmentType"
            label={
              <span>
                <strong>Select Segment Type</strong>
                <Tooltip title="Segmentt type info" placement="left">
                  <InfoCircleOutlined
                    style={{ float: "right" }}
                    className="font-color-budget"
                  />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
          <Select
            
              size="large"
              // showSearch
              style={{ width: "100%" }}
              placeholder="Select Segment Type"
              optionFilterProp="children"
              key="SegmentType"
              className="general-input-select"
              
            >
              {predefineCampaignTypes &&
                predefineCampaignTypes.length > 0 &&
                predefineCampaignTypes.map((item, indexval) => {
                  return (
                    <Select.Option key={indexval} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
            </Select>
            
          </Form.Item>

{
  (!((props.userPermission?.budgetCampaign === 'preview' && account?.[2] === "admin" && (props.viewType === "companyadmin" || props.viewType === "companycontributor") ) || (props.userPermission?.budgetCampaign === 'preview' && account?.[2] !== "admin"))) && 

(
  <>
    {!props.isEdit && <><Form.Item
            name="createBudget"
          >
            <Checkbox name="createBudget" checked={createBudgetDefault} onChange={(e) => setcreateBudgetDefault(e.target.checked)} ><span style={{ fontSize: 18 }}>Would you also like to create distinct budget for this segment?</span></Checkbox>
          </Form.Item>
         {
           createBudgetDefault && <Form.Item
           name="budget_revenue"
           label={
             <span>
               <strong>Total Marketing Programs Budget</strong>
               <Tooltip title="This may include budget for content creation and
software. It should not include marketing
headcount" placement="left">
                 <InfoCircleOutlined style={{ float: "right" }} />
               </Tooltip>
             </span>
           }
           rules={[
             {
               required: true,
               message: "This field can not be empty!",
             },
           ]}
           extra={"This may include budget for content creation and software. It should not include marketing headcount"}
         >
           <RevenueTextField
                        dollercirclestyle={{
                          position: "absolute",
                          zIndex: 12,
                          top: 21,
                          left: 7,
                          backgroundSize: "3rem",
                          fontSize:"18px"
                        }}
                        name={"budget_revenue"}
                        placeholder="Total Budget"
                        onChange={(value) => {
                          setbudgetAmount(value)
                        }}
                        value={budgetAmount}
                      />
         </Form.Item>
         } 
          
          </>
          }
</>
)
}
        

        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            fontSize: "18px",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}
        >
          <Button
            className="secondaryButton"
            onClick={props.handleCancel}
            style={{ fontSize: 18, marginRight: 8, width: "48%" }}
            disabled={isLoading}

          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            type="primary"
            style={{ fontSize: 18, width: "48%" }}
            htmlType="submit"
            disabled={isLoading}
            className="primaryButton"
          >
            Save
          </Button>
          </div>
        </Form>
          
      </Drawer>
      <Modal
          className="deleteCampaignModal"
          visible={isConfirmModelOpen}
          closable={false}
          centered
          footer={[
              <Button
            style={{
              height: "40px",
            }}
            type="secondary"
            key="cancel"
            onClick={onCancelClick}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            size="large"
            onClick={onSaveClick}
          >
            Save
          </Button>,
          ]}
        >
          <Typography.Title level={4}>
            No Segment Mapping Detected
        </Typography.Title>
        <Typography.Text className="single-segment-message">
           {"If you would like to see reporting broken out by your segments, be sure to configure your Segment Mapping field under Company Settings > Integrations > CRM"}
          </Typography.Text>
        </Modal>
    </>
  );
}

export default CreateBaseLineMetrics;
