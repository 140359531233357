/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Space,
  Descriptions,
  Tabs,
  Button,
  message,
  Modal,
  Form,
  Select,
  Row,
  Col,
  Divider,
  Input,
  Drawer,
  Skeleton,
  Upload
} from "antd";
import sdk from "sdk/Accounts";
import Integration from "./Integration";
import AccountPackages from "./AccountPackages";
import { useHistory } from "react-router";
import settings_integration_preview from "assets/Images/settings_integration_preview.png";
import suspendCompanyImg from "../../assets/Images/suspend_company.svg";
import Free from "../../assets/Images/freePlan.svg";
import Planning from "../../assets/Images/planning.svg";
import Growth from "../../assets/Images/growth.svg";
import { companySize, emailPattern, industries } from "utility/Constants";
import PreviewInfo from "commons/PreviewInfo";
import "./details.css";
import "./Setting.less";
import { getPlanList } from "store/actions/revenueAction";
import { loadStripe } from "@stripe/stripe-js";
import PlanSelected from "./PlanSelected";
import { PenIcon } from "utility/CustomIcons";
import { API } from "aws-amplify";
import CompanyUsers from "./CompanyUsers";
import { LoadingOutlined } from '@ant-design/icons';
import icn_image from "assets/Images/icn_image.svg";
import { ColorExtractor } from 'react-color-extractor';
import { SketchPicker } from 'react-color';
import { Storage, Auth } from "aws-amplify";
// import defaultImage from "assets/Images/default4AM.png"
import starterPlan from "assets/Images/starterPlan.svg"
import config from "../../../src/aws-exports";
import { setPermission, updateAccount } from 'store/actions/loginAction';


const { TabPane } = Tabs;
// const { Dragger } = Upload;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
const AccountDetails = () => {
  const { groups, viewType, selectedCompanyName } = useSelector(
    (state) => state.loginReducer
  );
  
  const userPermission = useSelector(
    (state) => state.loginReducer.userPermission
  );
  const [loading, setLoading] = useState(true);
  const [acct, setAcct] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  const [isCompanySuspendModelOpen, setIsCompanysuspendModelOpen] =
    useState(false);
  const [companySuspendLoading, setCompanySuspendLoading] = useState(false);
  // const [companyToSusupend, setCompanyToSusupend] = useState({});
  const [inviteModelShow, setinviteModelShow] = useState(false);
  const [inviteData, setinviteData] = useState();
  const [inviteform] = Form.useForm();
  const [brandForm] = Form.useForm();


  const [plans, setplans] = useState([]);
  const [stripe, setStripe] = useState(null);
  const [UpdateCompanyModelOpen, setUpdateCompanyModelOpen] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [isAdminScoped, setisAdminScoped] = useState(false);

  const dispatch = useDispatch();

  const [companyAccountForm] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    if (groups && groups[0]) {
      if (selectedCompanyName) {
        setisAdminScoped(true);
      }
    }
  }, [groups, selectedCompanyName]);

  const changePlanDirect = async (plan) => {
    setLoading(true);
    let init = {
      body: {
        account_id: groups[0],
        plan_id: plan?.id,
      },
    };

    API.post("exploricsREST", `/admin/updateplan`, init)
      .then((_) => {
        message.success("Plan updated successfully");
        setLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong");
        setLoading(false);
      });
  };

  const fetchAccountDetails = async () => {
    setLoading(true);
    const accountID = groups.length > 0 ? groups[0] : null;
    if (accountID) {
      let account = await sdk.fetchAccount(accountID);
      
      setCompanyData(account);
      setLoading(false);
    }
  };

  const setCompanyData = (account) => {
    if (!account?.url?.includes("http")) {
      account.url = "https://" + account?.url;
    }
    setAcct(account);
  };

  const approveRequest = async (id) => {
    if (id) {
      setLoading(true);
      let response = await sdk.approveRequest(id);
      if (response.errors || response.error) {
        setLoading(false);
        message.error("Failed to approve request.");
        return;
      } else {
        setLoading(false);
        deleteRequest(id);
      }
    }
  };

  const fetchPlans = async (force = false) => {
    if (plans?.length === 0 || force) {
      let response = dispatch(getPlanList());
      response
        .then((data) => {
          data.sort(sortingOrder);
          setplans(data);
        })
        .catch((err) => {
          console.log("Error : ", err);
        });
    }
  };

  const deleteRequest = async (id) => {
    if (id) {
      setLoading(true);
      await sdk.deleteRequest(id);
      await fetchAccountDetails();
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccountDetails();
    fetchPlans(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let data = plans;
    if (data.length > 0) {
      data.map((item) => {
        if (acct?.planID) {
          item.isSelected = item.id === acct?.planID;
        } else {
          item.isSelected = item.name === "Free";
        }
      });
    }
  }, [acct, plans]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.has("error")) {
      history.replace({
        search: "",
      });
    }
    if (params.has("code")) {
      setActiveTab("3");
    }
    if (params.has("stripe_session")) {
      setActiveTab("1");
      setplanselectedSuccess(true);
      fetchAccountDetails();

      history.replace({
        search: params.toString(),
      });
      if (params.has("error")) {
        history.replace({
          search: "",
        });
      }
      for (var key of params.keys()) {
        params.delete(key);
      }
    }

    // eslint-disable-next-line
  }, [window.location.search]);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleCompanySuspend = async () => {
    if (acct) {
      setCompanySuspendLoading(true);
      let companyId = acct?.id;
      let suspendFlag = acct?.disabled === true ? false : true;
      // let suspend = companyToSusupend?.suspend
      let result = await sdk.companySuspension(companyId, suspendFlag);
      if (result.error) {
        message.error(result.error, 5);
      } else {
        message.success(
          suspendFlag
            ? "Company suspended successfully"
            : "Company unsuspended successfully",
          5
        );
      }
    }
    setIsCompanysuspendModelOpen(false);
    setCompanySuspendLoading(false);
    fetchAccountDetails();
    // getCompanyData()
  };

  const onInvite = () => {
    setinviteModelShow(true);
    let data = { account_id: acct?.id, name: acct?.company };
    setinviteData(data);
  };

  const sendInvitation = async (value) => {
    value.account_id = inviteData.account_id;
    value.name = inviteData.name;
    setLoading(true);
    let result = await sdk.sendEmailInvitation(value);

    if (result.error) {
      message.error(result.error, 5);
      setLoading(false);
    } else {
      fetchAccountDetails();
      setLoading(false);
      inviteform.resetFields();
      message.success(`Invitation sent successfully.`, 5);
    }
    setinviteModelShow(false);
  };
  const onInviteSend = () => {
    inviteform
      .validateFields()
      .then((values) => {
        const formatedValues = {
          ...values,
        };
        sendInvitation(formatedValues);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const validateEmail = (e) => {
    const email_pattern = emailPattern;
    if (e.length > 0) {
      const found = e[e.length - 1].match(email_pattern);
      if (!found) {
        e.pop();
        message.error("Please enter valid email");
      } else {
        e[e.length - 1] = e[e.length - 1].toLowerCase();
      }
    }
  };

  const sortingOrder = (a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  };

   const SETPermission = async (session) =>{
    await dispatch(setPermission(session.idToken.payload))
  }
  const refreshTokenManualy = async () => {
    setLoading(true)
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();

    cognitoUser.refreshSession(currentSession.refreshToken, async (err, session) => {
      if (session) {
        if (session?.idToken?.payload) {
          await SETPermission(session)
          window.location.reload();
          setLoading(false)
        }
      }
    });
  }
  // PRICING
  const selectPlan = async (plan) => {
    setLoading(true);
    const accountID = groups.length > 0 ? groups[0] : null;

    if (plan && plan.id && accountID) {
      try {
        let sessionResult = await sdk.getOrgCheckoutSession(
          plan.stripePriceID[0],
          plan.id,
          accountID
        );
        if (sessionResult.type === "checkout" && sessionResult.sessionId) {
          setLoading(false);

          stripe
            .redirectToCheckout({
              sessionId: sessionResult.sessionId,
            })
            .catch((e) => {
              console.log(e);
              message.error("Failed to update plan information.");
              setLoading(false);
            });
        } else if (sessionResult?.type === "update") {
          message.success("Plan Updated successfully");
          fetchAccountDetails()?.then(async () => {
            await refreshTokenManualy()
          });
        } else {
          message.error("Failed to update plan information.");
          setLoading(false);
        }
      } catch (error) {
        console.log("error creating stripe session", error);
        message.error("Failed to update plan information.");
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const resolveStripe = async () => {
      let s = await stripePromise;
      setStripe(s);
    };
    resolveStripe();
  }, []);

  const [planselectedSuccess, setplanselectedSuccess] = useState(false);

  const updateCompany = (e) => {
    setLoading(true);
    delete e.url;
    let reqData = {
      id: acct?.id,
      company_loweredcased: String(e?.company).toLowerCase(),
      ...e,
    };

    sdk
      .companyUpdate(reqData)
      .then((data) => {
        if (data?.errors) {
          message.error("Something went wrong");
          setLoading(false);
        } else {
          data.url = acct?.url;
          setCompanyData(data);
          setUpdateCompanyModelOpen(false);
          setLoading(false);
          message.success("Company updated succesfully");
          companyAccountForm.resetFields();
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong");
      });
  };


  // Company Brand details
  const updateCompanyBrand = (value, callback) => {
    setLoading(true);

    let inputData = {
      id: acct?.id,
      ...value,
    }
   dispatch(updateAccount(inputData)).then((res) => {
    if(callback !== 'colors')
          message.success("Company brand details updated succesfully");
          setLoading(false);
   }).catch((e) =>{
        message.error("something went wrong!");
        setLoading(false);
   })

  }

  const [imageUrl, setImageUrl] = useState();
  const [imageLoadFail, setImageLoadFail] = useState(false);

  const [loadingImg, setLoadingImg] = useState(false);

  const uploadButtonImg = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <img src = {icn_image} alt = "Feature" />}
      <div style={{ marginTop: 8 }}><span style={{fontWeight:"bold"}}>Drop your logo or</span> <span style={{fontWeight:"bold", color:"#0f61db"}}>Browse</span> <div>Support JPG, PNG</div></div>
    </div>
  );

  const [extractColor, setExtractColor] = useState(false)
  useEffect(() => {

      if(acct) {
        if(acct?.profile_url) {
          getSignedUrlImg(acct?.profile_url);

          const colorArray = acct?.colors?JSON.parse(acct?.colors):[]
          brandForm.setFieldsValue({color_1:colorArray?.[0]})
          brandForm.setFieldsValue({color_2:colorArray?.[1]})
          brandForm.setFieldsValue({color_3:colorArray?.[2]})
          brandForm.setFieldsValue({color_4:colorArray?.[3]})
          brandForm.setFieldsValue({color_5:colorArray?.[4]})
          setColor1(colorArray?.[0])
          setColor2(colorArray?.[1])
          setColor3(colorArray?.[2])
          setColor4(colorArray?.[3])
          setColor5(colorArray?.[4])

        } else {
        setImageUrl(`https://logo.clearbit.com/${acct?.url}`);
        brandForm.setFieldsValue({profile_url:`https://logo.clearbit.com/${acct?.url}`})

        if(acct?.url){
        fetch(`https://logo.clearbit.com/${acct?.url?.replace(/^https?:\/\//, '')}`)
        .then((response ) => {
          if (response.ok) {
            return response.blob();
          }
          throw new Error('Something went wrong');
        })
        .then((responseJson) => {
          beforeUploadImage(responseJson)
        })
        .catch((error) => {
          setImageLoadFail(true)
          console.log(error)
        });
        }
     }
      }

  }, [acct]) // eslint-disable-line

  async function getSignedUrlImg(fileKey) {

    let bucketName = config.aws_cloud_logic_custom?.[0].endpoint;
      bucketName = bucketName?.split('/')
      Storage.configure({ AWSS3: {
        bucket: `4amdemand-company-profile-image-${bucketName?.[3]}`,
        region: config.aws_user_files_s3_bucket_region
      }});

    const fileInfo = fileKey.split("/");
    const signedURL = await Storage.get(fileInfo?.[1], {
      level: "protected",
      identityId: fileInfo[0],
    });

    setImageUrl(signedURL);
  }

  const [currentUserInfo, setCurrentUserInfo] = useState();
  const getCurrentUserInfo = async () => {
    const currentUserVal = await Auth.currentUserInfo();
    setCurrentUserInfo(currentUserVal?.id);
  };

  useEffect(() => {
    getCurrentUserInfo();
  }, []);

  const beforeUploadImage = async (file) => {
    try {
      setLoadingImg(true);
      let bucketName = config.aws_cloud_logic_custom?.[0].endpoint;
      bucketName = bucketName?.split('/')
      Storage.configure({ AWSS3: {
        bucket: `4amdemand-company-profile-image-${bucketName?.[3]}`,
        region: config.aws_user_files_s3_bucket_region
      }});

      const uploadedFileKeyImg = await Storage.put(
        groups[0],
        file,
        {
          level: "protected",
          contentType: "image/jpeg",
        }
      );
      setLoadingImg(false);
      setImageLoadFail(false)
      updateCompanyBrand({profile_url:`${currentUserInfo}/${uploadedFileKeyImg.key}`})

      // setImageUrl(signedURL);
      getSignedUrlImg(`${currentUserInfo}/${uploadedFileKeyImg.key}`)
      setExtractColor(true)
    } catch (error) {
      message.error(`Uploading file: ${error}`);
      setLoadingImg(false);
      setImageLoadFail(false);
    }
    return false;
  };

  const [color1, setColor1] = useState()
  const [color2, setColor2] = useState()
  const [color3, setColor3] = useState()
  const [color4, setColor4] = useState()
  const [color5, setColor5] = useState()

  const getColors = (colorsExtracted) => {
    brandForm.setFieldsValue({color_1:colorsExtracted?.[0]})
    brandForm.setFieldsValue({color_2:colorsExtracted?.[1]})
    brandForm.setFieldsValue({color_3:colorsExtracted?.[2]})
    brandForm.setFieldsValue({color_4:colorsExtracted?.[3]})
    brandForm.setFieldsValue({color_5:colorsExtracted?.[4]})
    setColor1(colorsExtracted?.[0])
    setColor2(colorsExtracted?.[1])
    setColor3(colorsExtracted?.[2])
    setColor4(colorsExtracted?.[3])
    setColor5(colorsExtracted?.[4])

    let defaultColors = [colorsExtracted?.[0], colorsExtracted?.[1], colorsExtracted?.[2], colorsExtracted?.[3], colorsExtracted?.[4]]
    updateCompanyBrand({"colors":JSON.stringify(defaultColors)}, 'colors')
  }

  const [colorPicker1, setColorPicker1] = useState(false)
  const handlePicker1 = () => {
    setColorPicker1(!colorPicker1)
   };
   const handleChangeCompletePicker1 = (color, event) => {
     setColor1(color?.hex)
     brandForm.setFieldsValue({color_1:color?.hex})

     let accountColors = acct?.colors?JSON.parse(acct?.colors):[]
     accountColors[0] = color?.hex;
    updateCompanyBrand({"colors":JSON.stringify(accountColors)})

   };
   const handleClosePicker1 = () => {
     setColorPicker1(false)
   };

 const [colorPicker2, setColorPicker2] = useState(false)
 const handlePicker2 = () => {
   setColorPicker2(!colorPicker2)
  };
  const handleChangeCompletePicker2 = (color, event) => {
    setColor2(color?.hex)
    brandForm.setFieldsValue({color_2:color?.hex})

    let accountColors = acct?.colors?JSON.parse(acct?.colors):[]
    accountColors[1] = color?.hex;
   updateCompanyBrand({"colors":JSON.stringify(accountColors)})
  };
  const handleClosePicker2 = () => {
    setColorPicker2(false)
  };

 const [colorPicker3, setColorPicker3] = useState(false)
 const handlePicker3 = () => {
   setColorPicker3(!colorPicker3)
  };
  const handleChangeCompletePicker3 = (color, event) => {
    setColor3(color?.hex)
    brandForm.setFieldsValue({color_3:color?.hex})

    let accountColors = acct?.colors?JSON.parse(acct?.colors):[]
    accountColors[2] = color?.hex;
   updateCompanyBrand({"colors":JSON.stringify(accountColors)})
  };
  const handleClosePicker3 = () => {
    setColorPicker3(false)
  };

 const [colorPicker4, setColorPicker4] = useState(false)
 const handlePicker4 = () => {
   setColorPicker4(!colorPicker4)
  };
  const handleChangeCompletePicker4 = (color, event) => {
    setColor4(color?.hex)
    brandForm.setFieldsValue({color_4:color?.hex})

    let accountColors = acct?.colors?JSON.parse(acct?.colors):[]
    accountColors[3] = color?.hex;
   updateCompanyBrand({"colors":JSON.stringify(accountColors)})
  };
  const handleClosePicker4 = () => {
    setColorPicker4(false)
  };

 const [colorPicker5, setColorPicker5] = useState(false)
 const handlePicker5 = () => {
   setColorPicker5(!colorPicker4)
  };
  const handleChangeCompletePicker5 = (color, event) => {
    setColor5(color?.hex)
    brandForm.setFieldsValue({color_5:color?.hex})

    let accountColors = acct?.colors?JSON.parse(acct?.colors):[]
    accountColors[4] = color?.hex;
   updateCompanyBrand({"colors":JSON.stringify(accountColors)})
  };
  const handleClosePicker5 = () => {
    setColorPicker5(false)
  };

  const popover = {
    position: 'absolute',
    zIndex: '2',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  return (
    <>
      {planselectedSuccess && (
        <PlanSelected
          planreset={() => {
            setplanselectedSuccess(false);
          }}
        />
      )}
      {/* {loading && <div className="loading">Loading;</div>} */}

      <Space
        direction="vertical"
        className="sub-container panel-design"
        style={{ width: "100%" }}
      >
        <Typography.Text type={"secondary"} style={{
                marginBottom: "30px",
                fontSize: "18px",
                background: "#f5f7fa",
                padding: "10px 5px",
                textAlign: "center",
                width:"100%",
                display:"block",
                color:"#1D2939"
              }}
              className="titleText fw500 panel-design">Account Settings <span style={{display:'inline-block', marginLeft:'15px', color:"#7F56D9", fontWeight:700}}>
              {acct ? (
                <>
                  {acct.company}{" "}
                 { (groups?.length > 0 &&
                      (groups?.[1] === "companyadmin" || groups?.[2] === "admin") && viewType !== "companycontributor" ) && (<span
                    onClick={() => {
                      setUpdateCompanyModelOpen(true);
                      companyAccountForm.setFieldsValue(acct);
                    }}
                  >
                    <PenIcon  style={{cursor: "pointer !important"}}/>{" "}
                  </span>)}
                </>
              ) : (
                <Skeleton.Input active style={{ width: "100px" }} size="small" />
              )}
            </span></Typography.Text>
        
        <Tabs
          className="setting-table general-tab"
          activeKey={activeTab}
          centered
          onChange={handleTabChange}
        >
          <TabPane tab="Details" key="1" style={{ paddingTop: 20 }}>
            {acct ? (
              <>
                <Descriptions bordered column={1} className="account_description_table" id="adt">
                  <Descriptions.Item
                    labelStyle={{ fontWeight: "700" }}
                    label="Company Name"
                  >
                    {acct && acct.company ? acct.company : "-"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: "700" }}
                    label="Company URL"
                  >
                    {acct ? (
                      <a
                        href={acct.url}
                        rel="noreferrer noopener"
                        target="_blank"
                        style={{color:"#7F56D9"}}
                      >
                        {acct.url}
                      </a>
                    ) : (
                      "-"
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: "700" }}
                    label="Company Industry"
                  >
                    {acct && acct.industry ? acct.industry : "-"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: "700" }}
                    label="Company Size"
                  >
                    {acct && acct.company_size ? acct.company_size : "-"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: "700" }}
                    label="Account ID"
                  >
                    {acct ? (
                      <Typography.Text copyable={{ text: acct.id }}>
                        {acct.id}
                      </Typography.Text>
                    ) : (
                      "-"
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: "700" }}
                    label="XPlatform Domain URL"
                  >
                    {acct ? (
                      <a
                        href={acct.accessible_site_url}
                        rel="noreferrer noopener"
                        target="_blank"
                        style={{color:"#7F56D9"}}
                      >
                        {acct.accessible_site_url}
                      </a>
                    ) : (
                      "-"
                    )}
                  </Descriptions.Item>
                </Descriptions>
                {groups?.length > 0 &&
                  (groups?.[2] === "admin" &&
                  viewType === "admin" ) && (
                    <div style={{ paddingTop: 30, paddingBottom: 10 }}>
                      <Button
                        className="secondaryButton"
                        type="primary"
                        onClick={() => setIsCompanysuspendModelOpen(true)}
                        
                      >
                        {acct?.disabled === true ? (
                          <span>Un-Suspend Company</span>
                        ) : (
                          <span>Suspend Company</span>
                        )}
                      </Button>
                    </div>
                  )}
              </>
            ) : (
              <>
                <Space style={{ width: "100%" }} direction="vertical">
                  <Skeleton active paragraph={{ rows: 4 }} />
                  <Skeleton.Button
                    active
                    shape="round"
                    style={{ borderRadius: 7 }}
                  />
                </Space>
              </>
            )}



            <div
              className="CurrentPlan"
              style={{
                background: "white",
                borderRadius: "7px",
                marginTop: 20,
                padding: 18,
              }}
            >
              {extractColor && <ColorExtractor
                  src={imageUrl}
                  getColors={getColors}
                />}
              <div>
                <Typography.Title level={4} style={{
                marginBottom: "30px",
                fontSize: "18px",
                background: "#f5f7fa",
                padding: "10px 5px",
                textAlign: "center",
                width:"100%",
                display:"block",
                color:"#1D2939",
                fontWeight:500
              }}
              className="titleText fw500 panel-design">
                  Company Brand Details { loading && acct && <LoadingOutlined />}
                </Typography.Title>
               {acct ? <div>
                  <div>
                    <Form
                            name="basic"
                            layout="vertical"
                            autoComplete="off"
                            style={{width:"100%"}}
                            form={brandForm}
                            className="brand-detail-form"
                            // onFinish={(value)=>{handleUpdateBrandDetails(value)}}

                            >
                    <Row>
                      <Col
                        span={7}
                        lg={6}
                        md={24}
                        sm={24}
                        xs={24}
                      >
                        <div
                          className="plan-header"
                          style={{
                            flexWrap: "wrap",
                            alignItems: "center",
                          }}
                        >
                          <Form.Item
                                name= "profile_url"
                                //label={<small><strong style={{color:"#1D2939", fontSize:"18px"}}>{`Logo`}</strong></small>}
                            >
                        { groups?.length > 0 &&
                          (groups?.[1] === "companyadmin" || groups?.[2] === "admin") && viewType !== "companycontributor"   ? ( <Upload
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUploadImage}
                            accept=".JPG, .PNG"
                          >
                            {imageUrl && !loadingImg  && !imageLoadFail? (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                style={{ height: "auto", width: "100%" }}
                                // onerror = {defaultImage}
                              />
                            ) : (
                              uploadButtonImg
                            )}
                              </Upload>)
                                :
                                (<Upload
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            disabled
                            accept=".JPG, .PNG"
                          >
                            {imageUrl && !loadingImg  && !imageLoadFail? (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                style={{ height: "100%", width: "100%" }}
                                // onerror = {defaultImage}
                              />
                            ) : (
                              uploadButtonImg
                            )}
                          </Upload> )  }
                          </Form.Item>
                        </div>
                      </Col>
                      <Col
                        span={7}
                        lg={18}
                        md={24}
                        sm={24}
                        xs={24}
                      >
                        <Row className="table colors">
                          <Col
                            span={12}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            // style={{ display: "flex", flexWrap: "wrap" }}
                          >
                            {/* {websiteColors?.map((item, index) =>  */}
                            { groups?.length > 0 &&
                              (groups?.[1] === "companyadmin" || groups?.[2] === "admin") && viewType !== "companycontributor"   && (<Row>
                            <Col
                              span={12}
                              lg={4}
                              md={12}
                              sm={24}
                              xs={24}
                              style={{ paddingTop:10 }}
                            >
                              <div style={{paddingLeft:40}}>
                             {colorPicker1 && <div style={ popover }>
                             <div style={ cover } onClick={ handleClosePicker1 }/>
                               <SketchPicker
                                  color="#fff"
                                  onChangeComplete={ handleChangeCompletePicker1 }
                                /> </div>}
                              <div>
                              <Form.Item
                                name={`color_1`}
                                label={<small><strong>{`Color 1`}</strong></small>}
                            >
                                <Input placeholder={!color1?"Specify Color 1":""} onChange={(e) => setColor1(e.target.value)} prefix = {<div onClick={()=>handlePicker1()} className={!color1?"empty-color":""}  style={{background:color1, width:20, height:15, cursor:"pointer"}}></div>} />
                            </Form.Item>
                              </div>
                                </div>

                            </Col>

                            <Col
                              span={12}
                              lg={4}
                              md={12}
                              sm={24}
                              xs={24}
                              style={{paddingTop:10 }}
                            >
                              <div style={{paddingLeft:40}}>
                             {colorPicker2 && <div style={ popover }>
                             <div style={ cover } onClick={ handleClosePicker2 }/>
                               <SketchPicker
                                  color="#fff"
                                  onChangeComplete={ handleChangeCompletePicker2 }
                                /> </div>}
                              <div>
                              <Form.Item
                                name={`color_2`}
                                label={<small><strong>{`Color 2`}</strong></small>}
                            >
                                <Input  placeholder={!color1?"Specify Color 2":""} onChange={(e) => setColor2(e.target.value)} prefix = {<div onClick={()=>handlePicker2()} className={!color2?"empty-color":""} style={{background:color2, width:20, height:15, cursor:"pointer"}}></div>} />
                            </Form.Item>
                              </div>
                                </div>
                            </Col>

                            <Col
                              span={12}
                              lg={4}
                              md={12}
                              sm={24}
                              xs={24}
                              style={{  paddingTop:10 }}
                            >
                              <div style={{paddingLeft:40}}>
                             {colorPicker3 && <div style={ popover }>
                             <div style={ cover } onClick={ handleClosePicker3 }/>
                               <SketchPicker
                                  color="#fff"
                                  onChangeComplete={ handleChangeCompletePicker3 }
                                /> </div>}
                              <div>
                              <Form.Item
                                name={`color_3`}
                                label={<small><strong>{`Color 3`}</strong></small>}
                            >
                                <Input placeholder={!color1?"Specify Color 3":""} onChange={(e) => setColor3(e.target.value)} prefix = {<div onClick={()=>handlePicker3()} className={!color3?"empty-color":""} style={{background:color3, width:20, height:15, cursor:"pointer"}}></div>} />
                            </Form.Item>
                              </div>
                                </div>
                            </Col>

                            <Col
                              span={12}
                              lg={4}
                              md={12}
                              sm={24}
                              xs={24}
                              style={{  paddingTop:10 }}
                            >
                              <div style={{paddingLeft:40}}>
                             {colorPicker4 && <div style={ popover }>
                             <div style={ cover } onClick={ handleClosePicker4 }/>
                               <SketchPicker
                                  color="#fff"
                                  onChangeComplete={ handleChangeCompletePicker4 }
                                /> </div>}
                              <div>
                              <Form.Item
                                name={`color_4`}
                                label={<small><strong>{`Color 4`}</strong></small>}
                            >
                                <Input placeholder={!color1?"Specify Color 4":""} onChange={(e) => setColor4(e.target.value)} prefix = {<div onClick={()=>handlePicker4()} className={!color4?"empty-color":""} style={{background:color4, width:20, height:15, cursor:"pointer"}}></div>} />
                            </Form.Item>
                              </div>
                                </div>
                            </Col>

                            <Col
                              span={12}
                              lg={4}
                              md={12}
                              sm={24}
                              xs={24}
                              style={{ paddingTop:10 }}
                            >
                              <div style={{paddingLeft:40}}>
                             {colorPicker5 && <div style={ popover }>
                             <div style={ cover } onClick={ handleClosePicker5 }/>
                               <SketchPicker
                                  color="#fff"
                                  onChangeComplete={ handleChangeCompletePicker5 }
                                /> </div>}
                              <div>
                              <Form.Item
                                name={`color_5`}
                                label={<small><strong>{`Color 5`}</strong></small>}
                            >
                                <Input placeholder={!color1?"Specify Color 5":""} onChange={(e) => setColor5(e.target.value)} prefix = {<div onClick={()=>handlePicker5()} className={!color5?"empty-color":""} style={{background:color5, width:20, height:15, cursor:"pointer"}}></div>} />
                            </Form.Item>
                              </div>
                                </div>
                            </Col>
                            </Row>)}
                          </Col>
                      </Row>

                      </Col>
                    </Row>
                      </Form>
                  </div>
                </div> : <div>

                    <Row>
                      <Col
                        span={7}
                        lg={6}
                        md={24}
                        sm={24}
                        xs={24}
                      >
                        <div
                          className="plan-header"
                          style={{
                            flexWrap: "wrap",
                            alignItems: "center",
                          }}
                        >
                          <Skeleton active />
                        </div>
                      </Col>
                      <Col
                        span={7}
                        lg={18}
                        md={24}
                        sm={24}
                        xs={24}
                      >
                        <Row className="table colors">
                          <Col
                            span={12}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            // style={{ display: "flex", flexWrap: "wrap" }}
                          >
                            <Row>
                            <Col
                              span={12}
                              lg={4}
                              md={12}
                              sm={24}
                              xs={24}
                              style={{ paddingTop:10 }}
                            >
                              <div style={{paddingLeft:40}}>
                              <Skeleton.Input active style={{ width: "140px" }} size="large" />
                                </div>

                            </Col>

                            <Col
                              span={12}
                              lg={4}
                              md={12}
                              sm={24}
                              xs={24}
                              style={{paddingTop:10 }}
                            >
                              <div style={{paddingLeft:40}}>
                              <Skeleton.Input active style={{ width: "140px" }} size="large" />
                                </div>
                            </Col>

                            <Col
                              span={12}
                              lg={4}
                              md={12}
                              sm={24}
                              xs={24}
                              style={{  paddingTop:10 }}
                            >
                              <div style={{paddingLeft:40}}>
                              <Skeleton.Input active style={{ width: "140px" }} size="large" />
                                </div>
                            </Col>

                            <Col
                              span={12}
                              lg={4}
                              md={12}
                              sm={24}
                              xs={24}
                              style={{  paddingTop:10 }}
                            >
                              <div style={{paddingLeft:40}}>
                              <Skeleton.Input active style={{ width: "140px" }} size="large" />
                                </div>
                            </Col>

                            <Col
                              span={12}
                              lg={4}
                              md={12}
                              sm={24}
                              xs={24}
                              style={{ paddingTop:10 }}
                            >
                              <div style={{paddingLeft:40}}>
                              <Skeleton.Input active style={{ width: "140px" }} size="large" />
                                </div>
                            </Col>
                            </Row>
                          </Col>
                      </Row>

                      </Col>
                    </Row>
                  </div>}
              </div>
            </div>



            <div
              className="CurrentPlan"
              style={{
                background: "white",
                borderRadius: "7px",
                marginTop: 20,
                padding: 18,
              }}
            >
              <Typography.Title level={4} style={{
                marginBottom: "30px",
                fontSize: "18px",
                background: "#f5f7fa",
                padding: "10px 5px",
                textAlign: "center",
                width:"100%",
                display:"block",
                color:"#1D2939",
                fontWeight:500
              }}
              className="titleText fw500 panel-design">My Current plan</Typography.Title>

              {loading ? (
                <Skeleton active />
              ) : (
                <>
                  {plans.map((plan, index) => {
                    let bulletPoints = {};
                    let halfBulletPoints = 0;
                    let pricePlan = {};
                    if (plan.listed && plan.isSelected) {
                      if (plan.priceValues) {
                        let prices = JSON.parse(plan.priceValues);
                        pricePlan = prices[Object.keys(prices)[0]];
                      }

                      if (plan.bulletPoints) {
                        bulletPoints = JSON.parse(plan.bulletPoints);

                        halfBulletPoints = parseInt(bulletPoints.length / 2);
                        if (bulletPoints.length % 2 !== 0) {
                          halfBulletPoints++;
                        }
                      }
                    }

                    return (
                      plan.listed &&
                      plan.isSelected && (
                        <div key={index}>
                          <div
                            style={{
                              background: "#EFFBFD",
                              padding: 15,
                              borderRadius: "7px",
                              marginTop: 20,
                              marginBottom: 20,
                            }}
                          >
                            <Row>
                              <Col
                                span={7}
                                lg={7}
                                md={7}
                                sm={24}
                                xs={24}
                                style={{ borderRight: "1px solid #DDE8F9", alignItems:'center', justifyContent:'center', display:'flex', textAlign:'center' }}
                              >
                                <div
                                  className="plan-header"
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <img
                                    alt=""
                                    src={
                                      plan.name === "Free"
                                        ? Free
                                        : plan.name === "Core"
                                        ? Planning
                                        : plan.name === "Starter" ? starterPlan : Growth
                                    }
                                    style={{ marginRight: 20 }}
                                  /> */}

                                  <div>
                                    <h3
                                      style={{
                                        color: "#7F56D9",
                                        fontWeight: 700,
                                        fontSize:"28px"
                                      }}
                                    >
                                      {String(plan?.name).toUpperCase()}
                                    </h3>
                                    <h2
                                      style={{
                                        color: "#F04438",
                                        fontWeight: 500,
                                        fontSize:"18px"
                                      }}
                                    >
                                      ${pricePlan?.value}/
                                      {pricePlan?.billing_period}
                                    </h2>
                                    {plan.name !== "Free" && <i>*billed annually</i>}
                                  </div>
                                </div>
                              </Col>

                              <Col span={17} lg={17} md={17} sm={17} xs={24}>
                                <Row>
                                  <Col span={9} lg={9} md={12} sm={24} xs={24}>
                                    {bulletPoints
                                      .splice(0, halfBulletPoints)
                                      ?.map((item, index) => {
                                        return (
                                          <ul
                                            key={index}
                                            className="Checked"
                                            style={{
                                              listStyle: "none",
                                              listStylePosition: "outside",
                                            }}
                                          >
                                            {
                                              <li
                                                key={Object.keys(item)[0]}
                                              >
                                                {Object.keys(item)[0]}
                                              </li>
                                            }
                                            <ul
                                              className="Checked"
                                              style={{
                                                listStyle: "none",
                                                listStylePosition: "outside",
                                              }}
                                            >
                                              {item?.[
                                                Object.keys(item)?.[0]
                                              ].map((value) => {
                                                return (
                                                  <li key={value}>{value}</li>
                                                );
                                              })}
                                            </ul>
                                          </ul>
                                        );
                                      })}
                                  </Col>
                                  <Col span={9} lg={9} md={12} sm={24} xs={24}>
                                    {bulletPoints
                                      .splice(0, halfBulletPoints)
                                      ?.map((item, index) => {
                                        return (
                                          <ul
                                            key={index}
                                            className="Checked"
                                            style={{
                                              listStyle: "none",
                                              listStylePosition: "outside",
                                              width: "80%",
                                            }}
                                          >
                                            {
                                              <li
                                                key={Object.keys(item)[0]}
                                              >
                                                {Object.keys(item)[0]}
                                              </li>
                                            }
                                            <ul
                                              className="Checked"
                                              style={{
                                                listStyle: "none",
                                                listStylePosition: "outside",
                                                width: "80%",
                                              }}
                                            >
                                              {item?.[
                                                Object.keys(item)?.[0]
                                              ].map((value) =>
                                                <li key={value}>{value}</li>
                                              )}
                                            </ul>
                                          </ul>
                                        );
                                      })}
                                  </Col>

                                  <Col
                                    span={6}
                                    lg={6}
                                    md={24}
                                    sm={24}
                                    xs={24}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                    className="connect-for-help"
                                      style={{
                                        fontSize: "17px",
                                        width: "100%",
                                        fontWeight: 600,
                                      }}
                                      type="primary"
                                      ghost
                                      onClick={() => {
                                        window.location.href =
                                          "mailto:support@4amdemand.com";
                                      }}
                                    >
                                      Contact for help
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )
                    );
                  })}
                </>
              )}
              <Typography.Title level={4} style={{
                marginBottom: "30px",
                fontSize: "18px",
                background: "#f5f7fa",
                padding: "10px 5px",
                textAlign: "center",
                width:"100%",
                display:"block",
                color:"#1D2939",
                fontWeight:500
              }}
              className="titleText fw500 panel-design">Other plans</Typography.Title>
              {loading ? (
                <>
                  <Skeleton active /> <Skeleton active />
                </>
              ) : (
                <>
                  {plans.map((plan, index) => {
                    let bulletPoints = {};
                    let halfBulletPoints = 0;
                    let pricePlan = {};
                    if (plan.listed && !plan.isSelected) {
                      if (plan.priceValues) {
                        let prices = JSON.parse(plan.priceValues);
                        pricePlan = prices[Object.keys(prices)[0]];
                      }

                      if (plan.bulletPoints) {
                        bulletPoints = JSON.parse(plan.bulletPoints);
                        halfBulletPoints = parseInt(bulletPoints.length / 2);
                        if (bulletPoints.length % 2 !== 0) {
                          halfBulletPoints++;
                        }
                      }
                    }

                    return (
                      plan.listed &&
                      !plan.isSelected && (
                        <div key={index}>
                          <div
                            style={{
                              background: plan.isSelected ? "#EFFBFD" : "#fff",
                              padding: 15,
                              borderRadius: "7px",
                              marginTop: 20,
                            }}
                          >
                            <Row>
                              <Col
                                span={7}
                                lg={7}
                                md={7}
                                sm={24}
                                xs={24}
                                style={{ borderRight: "1px solid #DDE8F9", alignItems:'center', justifyContent:'center', display:'flex', textAlign:'center'  }}
                              >
                                <div
                                  className="plan-header"
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <img
                                    alt=""
                                    src={
                                      plan.name === "Free"
                                        ? Free
                                        : plan.name === "Core"
                                        ? Planning
                                        : plan.name === "Starter" ? starterPlan : Growth
                                    }
                                    style={{ marginRight: 20 }}
                                  /> */}

                                  <div>
                                    <h3
                                      style={{
                                        color: "#7F56D9",
                                        fontWeight: 700,
                                        fontSize:"28px"
                                      }}
                                    >
                                      {String(plan?.name).toUpperCase()}
                                    </h3>
                                    <h2
                                      style={{
                                        color: "#F04438",
                                        fontWeight: 500,
                                        fontSize:"18px"
                                      }}
                                    >
                                      ${pricePlan?.value}/
                                      {pricePlan?.billing_period}
                                    </h2>
                  {plan.name !== "Free" && <i>*billed annually</i>}
                                  </div>
                                </div>
                              </Col>

                              <Col span={17} lg={17} md={17} sm={17} xs={24}>
                                <Row>
                                  <Col span={9} lg={9} md={12} sm={24} xs={24}>
                                    {bulletPoints
                                      .splice(0, halfBulletPoints)
                                      ?.map((item, index) => {
                                        return (
                                          <ul
                                            key={index}
                                            className="Checked"
                                            style={{
                                              listStyle: "none",
                                              listStylePosition: "outside",
                                            }}
                                          >
                                            {
                                              <li
                                                key={Object.keys(item)[0]}
                                                style={{color:"#1D2939", marginBottom:'10px'}}
                                              >
                                                {Object.keys(item)[0]}
                                              </li>
                                            }
                                            <ul
                                              className="Checked"
                                              style={{
                                                listStyle: "none",
                                                listStylePosition: "outside",
                                              }}
                                            >
                                              {item?.[
                                                Object.keys(item)?.[0]
                                              ].map((value) => {
                                                return (
                                                  <li key={value} style={{color:"#1D2939", marginBottom:'10px'}}>{value}</li>
                                                );
                                              })}
                                            </ul>
                                          </ul>
                                        );
                                      })}
                                  </Col>
                                  <Col span={9} lg={9} md={12} sm={24} xs={24}>
                                    {bulletPoints
                                      .splice(0, halfBulletPoints)
                                      ?.map((item, index) => {
                                        return (
                                          <ul
                                            key={index}
                                            className="Checked"
                                            style={{
                                              listStyle: "none",
                                              listStylePosition: "outside",
                                              width: "80%",
                                            }}
                                          >
                                            {
                                              <li
                                                key={Object.keys(item)[0]}
                                              >
                                                {Object.keys(item)[0]}
                                              </li>
                                            }
                                            <ul
                                              className="Checked"
                                              style={{
                                                listStyle: "none",
                                                listStylePosition: "outside",
                                                width: "80%",
                                              }}
                                            >
                                              {item?.[
                                                Object.keys(item)?.[0]
                                              ].map((value) => {
                                                return (
                                                  <li key={value}>{value}</li>
                                                );
                                              })}
                                            </ul>
                                          </ul>
                                        );
                                      })}
                                  </Col>

                                  <Col
                                    span={6}
                                    lg={6}
                                    md={24}
                                    sm={24}
                                    xs={24}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    {groups?.length > 0 &&
                                      (groups?.[2] === "admin" &&
                                      viewType === "admin" ) && (
                                        <Button
                                          className="primaryButton"
                                          type="primary"
                                          onClick={() => changePlanDirect(plan)}
                                          style={{marginBottom:"15px"}}
                                        >
                                          Assign Plan
                                        </Button>
                                      )}
                                    <>
                                      <Button
                                        className="tertiaryButton"
                                        type="primary"
                                        ghost
                                        onClick={() => {
                                          window.location.href =
                                            "mailto:support@xfactor.io";
                                        }}
                                        style={{marginBottom:"15px"}}
                                      >
                                        Talk to our team
                                      </Button>
                                      {groups?.length > 0 &&
                                      (groups?.[2] === "admin" || groups?.[1] === "companyadmin") && (viewType !=="companycontributor") &&
                                        (
                                      <Button
                                        className="secondaryButton"
                                        type="primary"
                                        onClick={() => selectPlan(plan)}
                                      >
                                        Select Plan
                                      </Button>)}
                                    </>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                          {plans.indexOf(plan) !== plans?.length - 1 && (
                            <Divider
                              style={{
                                width: "100%",
                                borderTopColor: "#E9F2FF !important",
                              }}
                            />
                          )}
                        </div>
                      )
                    );
                  })}
                </>
              )}
            </div>
          </TabPane>
          <TabPane tab="Team" key="2">
            <CompanyUsers
              onInvite={onInvite}
              fetchAccountDetails={fetchAccountDetails}
              acct={acct}
              approveRequest={approveRequest}
              deleteRequest={deleteRequest}
              loading={loading}
            />
          </TabPane>

          {((userPermission?.["settings.integrations"] &&
            userPermission?.["settings.integrations"] !== "hidden" &&
            userPermission?.["settings.integrations"] !== "preview") ||
            (groups?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor"))) && (
            <TabPane tab="Integrations" key="3">
              <Integration userPermission={userPermission} />
            </TabPane>
          )}
          {((userPermission?.["settings.integrations"] === "preview" &&
            groups?.[2] === "admin" &&
            (viewType === "companyadmin" && viewType === "companycontributor") ) ||
            (userPermission?.["settings.integrations"] === "preview" &&
              groups?.[2] !== "admin")) && (
            <TabPane tab="Integrations" key="3">
              <div>
                <PreviewInfo
                  img={settings_integration_preview}
                  text="Upgrade to View"
                  centerStyle={{ top: "30%" }}
                />
              </div>
            </TabPane>
          )}
          {groups?.length > 0 &&
            groups?.[2] === "admin" &&
            (viewType !== "companyadmin" && viewType !== "companycontributor")  && (
              <TabPane tab="Packages" key="5">
                <AccountPackages />
              </TabPane>
            )}
        </Tabs>

        <Modal
          visible={isCompanySuspendModelOpen}
          closable={false}
          centered
          footer={[]}
          style={{ borderRadius: "20px" }}
          className="suspend-company-footer"
        >
          <div className="delete-modal-body">
            <img src={suspendCompanyImg} alt="suspend company" />

            <Typography.Text
              style={{
                marginTop: "15px",
                fontSize: "20px",
                marginBottom: "15px",
              }}
            >
              {acct?.disabled === true
                ? "Are you sure you want to unsuspend the company?"
                : "Are you sure you want to suspend the company?"}
            </Typography.Text>
            <div className="delete-modal-footer">
              <Button
                type="primary"
                size="small"
                ghost
                onClick={() => {
                  handleCompanySuspend();
                }}
                style={{ borderColor: "#0F61DB", color: "#0F61DB" }}
                loading={companySuspendLoading}
              >
                Yes
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={() => setIsCompanysuspendModelOpen(false)}
              >
                No
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          title="Add email address to send invitation"
          visible={inviteModelShow}
          className="invite-dialogue"
          footer={null}
          closable={false}
        >
          {loading && <div className="loading">Loading;</div>}
          <Form form={inviteform} className="invite-modal">
            <Form.Item
              name="emails"
              rules={[
                {
                  required: true,
                  message: "This field can not be empty!",
                },
              ]}
            >
              <Select
                mode="tags"
                style={{ width: "100%" }}
                tokenSeparators={[","]}
                notFoundContent=""
                onChange={validateEmail}
                placeholder={
                  <span style={{ color: "grey" }}>Enter email id</span>
                }
              >
                {/* {children} */}
              </Select>
            </Form.Item>
          </Form>
          <div style={{ paddingTop: "35px" }}>
            <Button
              className="secondary-btn"
              onClick={() => {
                setinviteModelShow(false);
              }}
              style={{ fontSize: 18, marginRight: 8, width: "48%" }}
            >
              Cancel
            </Button>
            <Button
              onClick={onInviteSend}
              type="primary"
              style={{ fontSize: 18, width: "50%" }}
            >
              Send
            </Button>
          </div>
        </Modal>
      </Space>

      <Drawer
        visible={UpdateCompanyModelOpen}
        title={<strong>Edit Company</strong>}
        bodyStyle={{ paddingBottom: 80 }}
        width={416}
        onClose={() => {
          setUpdateCompanyModelOpen(false);
          companyAccountForm.resetFields();
        }}
        className="createAssetModal"
      >
        <Form
          labelCol={{ xs: 24 }}
          requiredMark={false}
          layout="vertical"
          name="new_account"
          className="login-form"
          onFinish={(e) => updateCompany(e)}
          form={companyAccountForm}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <Form.Item
              name="company"
              style={{
                marginTop: "0px",
                marginBottom: "12px",
              }}
              label={<strong>Company name</strong>}
              rules={[
                {
                  required: true,
                  message: "Please enter the name of your company",
                },
                {
                  pattern: /(^[a-zA-Z0-9]{1})([a-zA-Z0-9& .]+)$/,
                  message: "Please enter valid company name",
                },
              ]}
            >
              <Input
                size="middle"
                id="company"
                key="company"
                name="company"
                placeholder="Company Name"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="url"
              label={<strong>Website</strong>}
              rules={[
                {
                  required: true,
                  message: "Please enter the URL of your company",
                },
                {
                  pattern:
                    /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                  message: "Please enter valid URL",
                },
              ]}
            >
              <Input
                size="middle"
                id="url"
                key="url"
                name="url"
                placeholder="Company Website"
                disabled
              />
            </Form.Item>

            <Form.Item
              name="industry"
              label={<strong>Industry</strong>}
              rules={[
                {
                  required: true,
                  message: "Please Select Industry",
                },
              ]}
            >
              <Select
                placeholder="Select company industry"
                showSearch
                size="large"
                style={{ height: 43 }}
                disabled={loading}
              >
                {industries.map((item) => {
                  return (
                    <Select.Option value={item} key={item}>
                      {item}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="company_size"
              label={<strong>Company size</strong>}
              rules={[
                {
                  required: true,
                  message: "Please Select company size",
                },
              ]}
            >
              <Select
                placeholder="Select Company Size"
                showSearch
                size="large"
                style={{ height: 43 }}
                disabled={loading}
              >
                {companySize.map((item) => {
                  return (
                    <Select.Option value={item} key={item}>
                      {item}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="accessible_site_url"
              label={<strong>XPlatform Domain URL</strong>}
              rules={[
                {
                  pattern:
                    /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                  message: "Please enter valid URL",
                },
              ]}
            >
              <Input
                size="middle"
                id="xplatform_url"
                key="xplatform_url"
                name="xplatform_url"
                placeholder="XPlatform Domain URL"
                
              />
            </Form.Item>
          </Space>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <Button
              className="secondary-btn"
              onClick={() => setUpdateCompanyModelOpen(false)}
              style={{ fontSize: 18, marginRight: 8, width: "48%" }}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{ fontSize: 18, width: "48%" }}
              loading={loading}
            >
              Save
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default AccountDetails;
