/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  MinusCircleTwoTone,
  MoreOutlined,
  PlusOutlined,
  Loading3QuartersOutlined,
  UploadOutlined,
  MailOutlined,
} from "@ant-design/icons";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Select,
  Divider,
  Collapse,
  Dropdown,
  Menu,
  Tooltip,
} from "antd";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createPersona,
  createTargetIdealCustomProfile,
  setLoader,
  updatePersona,
  updateTargetIdealCustomProfile,
} from "store/actions/goToMarketAction";
import {
  industries,
  persona_values,
  target_market_segments,
  target_regions,
} from "utility/Constants";
import "./gotomarket.less";
import pdfImg from "assets/Images/pdfImg.png";
import go_to_market_target_audience_preview from "assets/Images/go_to_market_target_audience_preview.png";
import {
  TargetAudienceCover,
  TargetAudienceCustomreProfile,
  TargetAudienceUserPersona,
} from "./PdfPages";
import { Page, Document } from "@react-pdf/renderer";
import PreviewInfo from "commons/PreviewInfo";
import ppt_icon from "../../assets/Images/ppt_icon.svg";
import { LightenDarkenColor } from "utility/context/CommonMethods";
import { TrackClickFunction } from "../../commons/HeapTrack/TrackClickFunction";

const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;

export default function TargetAudience(props) {
  const {
    TargetIdealCustomProfile,
    Persona,
    hasUnsavedChanges,
    GTMMasterDoc,
    downloadPPTX,
    getSignedUrlImg,
  } = props;

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.goToMarketReducer);
  const {
    groups: account,
    userPermission,
    viewType,
    accountDetails,
  } = useSelector((state) => state.loginReducer);
  const [target_ideal_custom_profile] = Form.useForm();

  const saveTargetIdealCustomProfile = async (values) => {
    TrackClickFunction("demand-gen-gtm-intelligence-target-icp-save");
    dispatch(setLoader(true));
    let inputData = { account: account[0], ...values };
    if (TargetIdealCustomProfile?.length > 0) {
      inputData = { ...inputData, id: TargetIdealCustomProfile?.[0]?.id };
      await dispatch(updateTargetIdealCustomProfile(inputData));
      dispatch(setLoader(false));
    } else {
      dispatch(createTargetIdealCustomProfile(inputData));
      dispatch(setLoader(false));
    }
    props.sethasUnsavedChanges((old) => ({
      ...old,
      TargetIdealCustomerProfile: false,
    }));
  };

  const HandlesavePersona = async (value) => {
    dispatch(setLoader(true));
    value?.personas?.map((item) => {
      Object.keys(item).forEach((key) => {
        !item[key] && delete item[key];
      });
    });
    let inputdata = {
      account: account[0],
      personas: value?.personas,
    };
    if (Persona?.length > 0) {
      inputdata.id = Persona?.[0]?.id;
      inputdata.id && (await dispatch(updatePersona(inputdata)));
      dispatch(setLoader(false));
    } else {
      await dispatch(createPersona(inputdata));
      dispatch(setLoader(false));
    }
    props.sethasUnsavedChanges((old) => ({ ...old, personas: false }));
  };

  const TargetAudienceCustomerProfileDoc = useMemo(() => {
    return ({ logoUrl }) => {
      let targetAudiencedata = {};
      if (TargetIdealCustomProfile?.length > 0) {
        targetAudiencedata = { ...TargetIdealCustomProfile?.[0] };
        let swaped = swapKeyValue(target_market_segments);
        targetAudiencedata.market_segments =
          targetAudiencedata?.market_segments?.map((item) => swaped[item]);
        targetAudiencedata.logoUrl = logoUrl;
        targetAudiencedata.primaryColor = accountDetails?.colors
          ? JSON.parse(accountDetails?.colors)?.[0]
          : "#0f61db";
        targetAudiencedata.backgroundColor = LightenDarkenColor(
          accountDetails?.colors
            ? JSON.parse(accountDetails?.colors)?.[0]
            : "#0f61db",
          200
        );
      }
      let personaData = [];
      if (Persona?.length > 0) {
        personaData = Persona?.[0]?.personas?.filter((item) => item?.name);
      }

      return (
        <Document>
          <Page
            orientation="landscape"
            style={{ paddingTop: 20, paddingBottom: 50, paddingHorzontal: 30 }}
          >
            <TargetAudienceCover
              logoUrl={{ logourl: logoUrl, url: accountDetails.url }}
            />
            <TargetAudienceCustomreProfile {...targetAudiencedata} />
            <TargetAudienceUserPersona
              persona={personaData}
              {...targetAudiencedata}
            />
          </Page>
        </Document>
      );
    };
  }, [TargetIdealCustomProfile, Persona]);

  const swapKeyValue = (obj) => {
    let ret = {};
    for (const key in obj) {
      ret[obj[key]] = key;
    }
    return ret;
  };

  const personaEdited = () => {
    props.sethasUnsavedChanges((old) => ({ ...old, personas: true }));
  };
  return (userPermission?.["goToMarketIntelligence.TargetAudience"] ===
    "preview" &&
    account?.[2] === "admin" &&
    (viewType === "companyadmin" || viewType === "companycontributor")) ||
    (userPermission?.["goToMarketIntelligence.TargetAudience"] === "preview" &&
      account?.[2] !== "admin") ? (
    <div>
      <PreviewInfo
        img={go_to_market_target_audience_preview}
        text="Upgrade to View"
      />
    </div>
  ) : (
    <div>
      {/* {isLoading && <div className="loading">Loading;</div>} */}

      <Row
        justify="center"
        style={{ padding: "20px 10px" }}
        className="targetAudience"
      >
        <Col span={15} lg={15} md={20} sm={24} xs={24}>
          <h2
            style={{
              marginBottom: "30px",
              fontSize: "18px",
              background: "#f5f7fa",
              padding: "10px 5px",
              textAlign: "center",
            }}
            className="titleText fw500 panel-design"
          >
            Target Ideal Customer Profile
          </h2>

          <Form
            name="basic"
            form={target_ideal_custom_profile}
            layout="vertical"
            initialValues={TargetIdealCustomProfile?.[0]}
            onFinish={saveTargetIdealCustomProfile}
            onChange={() => {
              props.sethasUnsavedChanges((old) => ({
                ...old,
                TargetIdealCustomerProfile: true,
              }));
            }}
            autoComplete="off"
            className="general-form"
          >
            <Form.Item
              name="market_segments"
              label={
                <small
                  className="titleText fw700"
                  style={{ marginBottom: "5px", display: "block" }}
                >
                  Select Target Market Segments
                </small>
              }
            >
              <Select
                mode="multiple"
                className="general-input-select"
                allowClear
                style={{ width: "100%", color: "#1D2939" }}
                placeholder="Select Target Market Segments"
                onChange={() => {
                  props.sethasUnsavedChanges((old) => ({
                    ...old,
                    TargetIdealCustomerProfile: true,
                  }));
                }}
              >
                {Object.keys(target_market_segments).map((key) => (
                  <Option value={target_market_segments[key]}>{key}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="industries"
              label={
                <small
                  className="titleText fw700"
                  style={{ marginBottom: "5px", display: "block" }}
                >
                  Select Target Industries
                </small>
              }
            >
              <Select
                mode="multiple"
                className="general-input-select"
                allowClear
                style={{ width: "100%" }}
                placeholder="Select Target Industries"
                onChange={() => {
                  props.sethasUnsavedChanges((old) => ({
                    ...old,
                    TargetIdealCustomerProfile: true,
                  }));
                }}
              >
                {industries.map((key) => (
                  <Option value={key} key={key}>
                    {key}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="regions"
              label={
                <small
                  className="titleText fw700"
                  style={{ marginBottom: "5px", display: "block" }}
                >
                  Select Target Regions
                </small>
              }
            >
              <Select
                mode="multiple"
                className="general-input-select"
                allowClear
                style={{ width: "100%" }}
                placeholder="Select Target Regions"
                onChange={() => {
                  props.sethasUnsavedChanges((old) => ({
                    ...old,
                    TargetIdealCustomerProfile: true,
                  }));
                }}
              >
                {target_regions.map((key) => (
                  <Option value={key}>{key}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                className="primaryButton"
                loading={isLoading}
              >
                <strong>Save</strong>
              </Button>
              {hasUnsavedChanges.TargetIdealCustomerProfile && (
                <span
                  className="conversation-difference-dot"
                  style={{ opacity: 1, marginLeft: 12 }}
                ></span>
              )}
            </Form.Item>
          </Form>

          <Divider style={{ borderTopColor: "#D9D9D9 !important" }} />

          <h2
            style={{
              marginBottom: "30px",
              fontSize: "18px",
              background: "#f5f7fa",
              padding: "10px 5px",
              textAlign: "center",
            }}
            className="titleText fw500 panel-design"
          >
            Define your persona(s)
          </h2>

          <Form
            onFinish={HandlesavePersona}
            layout="vertical"
            autoComplete="off"
            initialValues={{ personas: Persona[0]?.personas }}
            onChange={personaEdited}
            className="general-form"
          >
            <Form.List name="personas">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <>
                      <Collapse
                        defaultActiveKey={[String(fields?.length - 1)]}
                        expandIconPosition="right"
                      >
                        <Panel
                          key={String(index)}
                          header={
                            Persona[0]?.personas?.[field.name]?.name ||
                            `Persona ${index}`
                          }
                          extra={
                            <Dropdown
                              trigger={["click"]}
                              overlay={
                                <Menu>
                                  <Menu.Item
                                    key="remove-persona"
                                    onClick={() => {
                                      if (fields.length === 1) {
                                        HandlesavePersona({ personas: [] });
                                      }
                                      personaEdited();
                                      remove(fields.indexOf(field));
                                    }}
                                  >
                                    <strong>Remove Persona</strong>
                                  </Menu.Item>
                                </Menu>
                              }
                            >
                              <MoreOutlined />
                            </Dropdown>
                          }
                        >
                          <Row justify="space-between">
                            <Col span={11} lg={11} md={11} sm={24} xs={24}>
                              <Form.Item
                                {...field}
                                name={[field.name, "name"]}
                                label={
                                  <small>
                                    <b>Persona Name</b>
                                  </small>
                                }
                              >
                                <Input
                                  style={{ color: "black" }}
                                  size="large"
                                  placeholder="Persona Name"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={11} lg={11} md={11} sm={24} xs={24}>
                              <Form.Item
                                {...field}
                                name={[field.name, "person_type"]}
                                label={
                                  <small>
                                    <b>Select a Persona Type</b>
                                  </small>
                                }
                              >
                                <Select
                                  placeholder="Select Persona type"
                                  size="large"
                                  onChange={personaEdited}
                                >
                                  {Object.keys(
                                    persona_values["persona_type"]
                                  ).map((key) => {
                                    return (
                                      <Option
                                        key={key}
                                        value={
                                          persona_values["persona_type"][key]
                                        }
                                      >
                                        {key}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={24} lg={24} md={24} sm={24} xs={24}>
                              <Form.Item
                                {...field}
                                name={[field.name, "job_title"]}
                                label={
                                  <small>
                                    <b>Specify Job Title</b>
                                  </small>
                                }
                              >
                                <Select
                                  mode="tags"
                                  allowClear
                                  style={{ width: "100%" }}
                                  placeholder="Select Job Title"
                                ></Select>
                              </Form.Item>
                            </Col>
                            <Col span={24} lg={24} md={24} sm={24} xs={24}>
                              <Form.Item
                                {...field}
                                name={[field.name, "job_functions"]}
                                label={
                                  <small>
                                    <b>
                                      Specify the Job Functions for this persona
                                    </b>
                                  </small>
                                }
                              >
                                <Select
                                  mode="multiple"
                                  allowClear
                                  style={{ width: "100%" }}
                                  placeholder="Select Job Functions"
                                >
                                  {persona_values["job_functions"].map(
                                    (job) => {
                                      return (
                                        <Select.Option
                                          value={job.id}
                                          key={job.id}
                                        >
                                          {job.name}
                                        </Select.Option>
                                      );
                                    }
                                  )}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={24} lg={24} md={24} sm={24} xs={24}>
                              <Form.Item
                                {...field}
                                name={[field.name, "job_level"]}
                                label={
                                  <small>
                                    <b>
                                      Specify the Job Levels for this persona
                                    </b>
                                  </small>
                                }
                              >
                                <Select
                                  mode="multiple"
                                  allowClear
                                  style={{ width: "100%" }}
                                  placeholder="Select Job Level"
                                >
                                  {[
                                    "Individual Contributor",
                                    "Manager",
                                    "Director",
                                    "VP",
                                    "C-Level",
                                  ].map((item) => {
                                    return (
                                      <Select.Option value={item} key={item}>
                                        {item}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={24} lg={24} md={24} sm={24} xs={24}>
                              <Form.Item
                                {...field}
                                name={[field.name, "details_responsibilities"]}
                                label={
                                  <small>
                                    <b>Persona Details/Responsibilities</b>
                                  </small>
                                }
                              >
                                <TextArea
                                  autoSize={{ minRows: 4, maxRows: 5 }}
                                  placeholder="Enter as much detail as you have about this persona. The more, the better."
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <strong style={{ lineHeight: "35px" }}>
                                {Persona[0]?.personas?.[field.name]?.name &&
                                  `${
                                    Persona[0]?.personas?.[field.name]?.name
                                  }'s`}{" "}
                                Pain Points
                              </strong>
                            </Col>
                            <Form.List
                              {...field}
                              name={[field.name, "pain_points"]}
                            >
                              {(PainPointsfields, { add, remove }) => (
                                <>
                                  {PainPointsfields.map(
                                    (PainPointField, index) => (
                                      <>
                                        <Col
                                          span={22}
                                          lg={22}
                                          md={22}
                                          sm={22}
                                          xs={22}
                                        >
                                          <Form.Item
                                            {...PainPointField}
                                            label={
                                              <small>
                                                <span
                                                  style={{
                                                    color: "#727272",
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  <strong>
                                                    Pain point {index + 1}
                                                  </strong>
                                                </span>
                                              </small>
                                            }
                                          >
                                            <TextArea
                                              autoSize={{
                                                minRows: 4,
                                                maxRows: 5,
                                              }}
                                              placeholder="Enter Pain point"
                                            />
                                          </Form.Item>
                                        </Col>

                                        <Col
                                          span={2}
                                          lg={2}
                                          md={2}
                                          sm={2}
                                          xs={2}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <MinusCircleTwoTone
                                            onClick={() =>
                                              remove(
                                                PainPointsfields.indexOf(
                                                  PainPointField
                                                )
                                              )
                                            }
                                            style={{ fontSize: 18 }}
                                          />
                                        </Col>
                                      </>
                                    )
                                  )}
                                  <Tooltip
                                    title={
                                      PainPointsfields?.length >= 3
                                        ? "You can only add upto 3 pain points"
                                        : ""
                                    }
                                  >
                                    <Button
                                      type="text"
                                      onClick={() => {
                                        if (PainPointsfields?.length < 3) {
                                          personaEdited();
                                          add();
                                        }
                                      }}
                                      disabled={PainPointsfields?.length >= 3}
                                      style={{
                                        color:
                                          PainPointsfields?.length >= 3
                                            ? "grey"
                                            : "#0F61DB",
                                        borderBottom: `1px solid ${
                                          PainPointsfields?.length >= 3
                                            ? "grey"
                                            : "#0F61DB"
                                        }`,
                                        padding: 0,
                                        fontWeight: 600,
                                        marginBottom: 30,
                                      }}
                                    >
                                      <PlusOutlined />
                                      Add More
                                    </Button>
                                  </Tooltip>
                                </>
                              )}
                            </Form.List>

                            <Col span={24} lg={24} md={24} sm={24} xs={24}>
                              <Form.Item
                                {...field}
                                name={[field.name, "pain_point_solution"]}
                                label={
                                  <small>
                                    <b>
                                      How does your product/solution solve these
                                      pain points?
                                      <br />
                                    </b>
                                  </small>
                                }
                              >
                                <TextArea
                                  autoSize={{ minRows: 4, maxRows: 5 }}
                                  placeholder="Summary of how you help make your persona's life easier "
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <strong style={{ lineHeight: "35px" }}>
                                Enter any messaging themes used for this persona
                              </strong>
                            </Col>
                            <Form.List
                              {...field}
                              name={[field.name, "messaging_theme"]}
                            >
                              {(Themefields, { add, remove }) => (
                                <>
                                  {Themefields.map((ThemeField, index) => (
                                    <>
                                      <Col
                                        span={22}
                                        lg={22}
                                        md={22}
                                        sm={22}
                                        xs={22}
                                      >
                                        <Form.Item
                                          {...ThemeField}
                                          label={
                                            <small>
                                              <span
                                                style={{
                                                  color: "#727272",
                                                  fontSize: 14,
                                                }}
                                              >
                                                Theme {index + 1}
                                              </span>
                                            </small>
                                          }
                                        >
                                          <TextArea
                                            autoSize={{
                                              minRows: 4,
                                              maxRows: 5,
                                            }}
                                            placeholder="Enter messaging theme"
                                          />
                                        </Form.Item>
                                      </Col>

                                      <Col
                                        span={2}
                                        lg={2}
                                        md={2}
                                        sm={2}
                                        xs={2}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <MinusCircleTwoTone
                                          onClick={() =>
                                            remove(
                                              Themefields.indexOf(ThemeField)
                                            )
                                          }
                                          style={{ fontSize: 18 }}
                                        />
                                      </Col>
                                    </>
                                  ))}
                                  <Tooltip
                                    title={
                                      Themefields?.length >= 3
                                        ? "You can only add upto 3 Themes"
                                        : ""
                                    }
                                  >
                                    <Button
                                      type="text"
                                      onClick={() => {
                                        if (Themefields?.length < 3) {
                                          personaEdited();
                                          add();
                                        }
                                      }}
                                      disabled={Themefields?.length >= 3}
                                      style={{
                                        color:
                                          Themefields?.length >= 3
                                            ? "grey"
                                            : "#0F61DB",
                                        borderBottom: `1px solid ${
                                          Themefields?.length >= 3
                                            ? "grey"
                                            : "#0F61DB"
                                        }`,
                                        padding: 0,
                                        fontWeight: 600,
                                        marginBottom: 30,
                                      }}
                                    >
                                      <PlusOutlined />
                                      Add More
                                    </Button>
                                  </Tooltip>
                                </>
                              )}
                            </Form.List>
                            <Col span={24} lg={24} md={24} sm={24} xs={24}>
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}
                              >
                                <strong>Save</strong>
                              </Button>
                              {hasUnsavedChanges.personas && (
                                <span
                                  className="conversation-difference-dot"
                                  style={{ opacity: 1, marginLeft: 12 }}
                                ></span>
                              )}
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </>
                  ))}

                  <Button
                    onClick={() => {
                      TrackClickFunction(
                        "demand-gen-gtm-intelligence-define-personas-create-button"
                      );
                      add();
                    }}
                    className="tertiaryButton"
                  >
                    Create New Persona
                  </Button>
                </>
              )}
            </Form.List>
          </Form>

          <Divider />

          <Row
            gutter={[10, 10]}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
              borderRadius: 7,
              marginTop: 30,
              marginBottom: 15,
            }}
            data-tour="tour__Export"
          >
            <Col span={24} style={{ display: "flex" }}>
              <div style={{width:"100%"}}>
                <h3 style={{ marginBottom: "30px", fontSize:'18px', background:"#f5f7fa", padding:"10px 5px", textAlign:'center', display:"block", fontWeight:500 }}
            className="panel-design">
                  Download PDFs & PPTx
                </h3>{" "}
              </div>
              <div style={{ paddingLeft: 10 }}>
                {props.loadingPDF && (
                  <Loading3QuartersOutlined
                    spin
                    style={{
                      fontSize: 18,
                      float: "right",
                      marginTop: 5,
                      marginRight: 8,
                    }}
                  />
                )}
              </div>
            </Col>

            <Col span={8} md={8} lg={8} sm={24} xs={24}>
              <div
                style={{
                  background: "#f5f7fa",
                  padding: 15,
                  borderRadius: 10,
                }}
                className="panel-design"
              >
                <img src={pdfImg} style={{ marginBottom: 10 }} alt="" />
                <p>
                  <strong>Target Audience PDF</strong>
                  <br /> with all the details
                  <br /> included above
                </p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    className="download-btn tertiaryButton"
                    onClick={async () => {
                      let url = await getSignedUrlImg(
                        accountDetails?.profile_url
                      );
                      props.generatePdfDocument(
                        "Target Audience.pdf",
                        <TargetAudienceCustomerProfileDoc logoUrl={url} />
                      );
                    }}
                    type="primary"
                    icon={<UploadOutlined style={{ fontSize: 13 }} />}
                    style={{ width:'49%', fontSize:'14px', height:'34px'}}
                  >
                    Export
                  </Button>
                  <Button
                    className="download-btn tertiaryButton"
                    onClick={() => {
                      props.emailPdfDocument(
                        "Demand Generation - Go to Market Intelligence - Target Audience",
                        <TargetAudienceCustomerProfileDoc />
                      );
                    }}
                    
                    type="primary"
                    icon={
                      <MailOutlined
                        style={{ fontSize: 13, verticalAlign: "middle" }}
                      />
                    }
                    style={{ width:'49%', fontSize:'14px', height:'34px', marginLeft:'2%'}}
                  >
                    Email
                  </Button>
                </div>
              </div>
            </Col>

            <Col span={8} md={8} lg={8} sm={24} xs={24}>
              <div
                style={{
                    background: "#f5f7fa",
                    padding: 15,
                    borderRadius: 10,
                  }}
                  className="panel-design"
              >
                <img src={pdfImg} style={{ marginBottom: 10 }} alt="" />
                <p>
                  <strong>Master PDF</strong> with all the <br />
                  GTM modules details
                  <br /> included in one PDF
                </p>
                <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  onClick={async () => {
                    let url = await getSignedUrlImg(
                      accountDetails?.profile_url
                    );
                    props.generatePdfDocument(
                      "Go to Marketing Master.pdf",
                      <GTMMasterDoc logoUrl={url} />
                    );
                  }}
                  className="download-btn tertiaryButton"
                  type="primary"
                  icon={<UploadOutlined style={{ fontSize: 13 }} />}
                  style={{ width:'49%', fontSize:'14px', height:'34px'}}
                >
                  Export
                </Button>

                <Button
                  className="download-btn tertiaryButton"
                  onClick={() => {
                    props.emailPdfDocument(
                      "Demand Generation - Go to Marketing Intelligence Master",
                      <GTMMasterDoc />
                    );
                  }}
                  type="primary"
                  icon={
                    <MailOutlined
                      style={{ fontSize: 13, verticalAlign: "middle" }}
                    />
                  }
                  style={{ width:'49%', fontSize:'14px', height:'34px', marginLeft:'2%'}}
                >
                  Email
                </Button>
                </div>
              </div>
            </Col>

            <Col span={8} md={8} lg={8} sm={24} xs={24}>
              <div
                style={{
                    background: "#f5f7fa",
                    padding: 15,
                    borderRadius: 10,
                  }}
                  className="panel-design"
              >
                <img src={ppt_icon} style={{ marginBottom: 10 }} alt="" />
                <p>
                  <strong>Complete presentation</strong>
                  <br /> of all the GTM modules
                  <br /> details entered
                </p>
                <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  className="download-btn tertiaryButton"
                  icon={<UploadOutlined style={{ fontSize: 13 }} />}
                  type="primary"
                  onClick={downloadPPTX}
                  style={{ width:'49%', fontSize:'14px', height:'34px'}}
                >
                  Export
                </Button>

                <Button
                  className="download-btn tertiaryButton"
                  style={{ width:'49%', fontSize:'14px', height:'34px', marginLeft:'2%'}}
                  icon={
                    <MailOutlined
                      style={{ fontSize: 13, verticalAlign: "middle" }}
                    />
                  }
                  type="primary"
                  onClick={props.emailPPTXDocument}
                >
                  Email
                </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
