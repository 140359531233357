import React from "react";
import { Card, Col, Row, Skeleton } from "antd";
import { AmplifyS3Image } from "@aws-amplify/ui-react";
import { useHistory } from "react-router-dom";
import { TrackClickFunction } from "commons/HeapTrack/TrackClickFunction";
import { state } from "@antv/g2plot/lib/adaptor/common";

const CoachingSection = (props) => {
  const history = useHistory(); 
  const handleCourseClick = (CourseDetails) => {
   if(props.isContent){
    history.push({
      pathname: `/coaching/content/course/${CourseDetails.course.slug}`,
      state: {isContent: true}
    });
   }else{ 
    history.push({
      pathname: `/coaching/course/${CourseDetails.course.slug}`,
    });
  }
  };

  console.log("issContent", props.hasContent);

  return (
    <>
      <div className="site-card-wrapper" style={{padding:'0 15px 30px 15px'}}>
        <Row gutter={[16, 16]}>
          {props.data.map((coursesVal, index) => {
            return (
              <Col span={6} xs={24} md={12} lg={6} key={index}>
                <Card
                  
                  className="coaching-card panel-design"
                  style={{padding: '8px',
                  border: '1px solid rgb(208, 213, 221)', height:'100%'}}
                >
                  <div className="course-image-placeholder" >
                  
                    <AmplifyS3Image
                    className="img"
                      level="protected"
                      identityId={coursesVal.course.featuredimage.split("/")[0]}
                      style={{
                        "--height": "190px",
                        "--width": "100%",
                        "--alt": "image",
                        objectFit:'cover'
                      }}
                      path={coursesVal.course.featuredimage.split("/")[1]}
                      onClick={() => {
                        handleCourseClick(coursesVal)
                      }}
                    />
                  </div>
                  <div style={{padding:'10px 0'}}>
                  <div
                    onClick={() => {
                      TrackClickFunction('demand-gen-coaching-title-page-coaching',{coachingTitle: coursesVal.course.name});
                      handleCourseClick(coursesVal)
                    }}
                    className = "coaching-title-link titleText"
                    title = "Click here for details"
                  >
                    {coursesVal.course.name}
                  </div>

                  <div style={{ paddingTop: 8,height: 52, fontSize:'16px' }} className="course-text" title={coursesVal.course.description}>
                      {coursesVal.course.short_description}
                  </div>
                  </div>
                </Card>
              </Col>
            );
          })}

          
          
        </Row>
      </div>
    </>
  );
};

export default CoachingSection;
