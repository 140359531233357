import ReportingHeader from 'pages/reports/ReportingHeader'
import React from 'react'
import AnalyticsStatistics from './AnalyticsStatistics'
import { useSelector } from 'react-redux';
import PreviewInfo from 'commons/PreviewInfo';
import reporting_analytics_module_preview from "assets/Images/reporting_analytics_module_preview.png";

function Analytics() {
  const account = useSelector((state) => state.loginReducer.groups);
  const {userPermission, viewType} = useSelector((state) => state.loginReducer);

  const permissions = {
    executive: userPermission?.['reporting.executive'],
    "google-analytics": userPermission?.['reporting.analytics'],
    "sales-and-marketing": userPermission?.['reporting.salesandmarketingleadership']
  }
  const showPreviewOnly = (permissions["google-analytics"] === "preview"  && account?.[2] === "admin" && !(viewType === "companyadmin" || viewType === "companycontributor")) || (permissions["google-analytics"] === "preview" && account?.[2] !== "admin");
  
  return (
    <>
      <ReportingHeader type="google-analytics" /> 
   { showPreviewOnly ?  <PreviewInfo img = {reporting_analytics_module_preview} text = "Upgrade to View"  centerStyle = {{top:"30%"}} /> :   
    <AnalyticsStatistics />}
    </>
  )
}

export default Analytics