/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      company
      company_loweredcased
      company_description
      product_description
      tagline
      url
      accessible_site_url
      fiscalYearMonth
      disabled
      users
      metadata
      createdAt
      updatedAt
      colors
      profile_url
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      industry
      company_size
      owners
      editors
      viewers
      requests {
        items {
          id
          account
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      plan {
        id
        name
        description
        bulletPoints
        packageID
        stripePriceID
        priceValues
        listed
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company
        company_loweredcased
        company_description
        product_description
        tagline
        url
        accessible_site_url
        fiscalYearMonth
        disabled
        users
        metadata
        createdAt
        updatedAt
        colors
        profile_url
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        industry
        company_size
        owners
        editors
        viewers
        requests {
          nextToken
        }
        plan {
          id
          name
          description
          bulletPoints
          packageID
          stripePriceID
          priceValues
          listed
          order
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAccountRequest = /* GraphQL */ `
  query GetAccountRequest($id: ID!) {
    getAccountRequest(id: $id) {
      id
      account
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listAccountRequests = /* GraphQL */ `
  query ListAccountRequests(
    $filter: ModelAccountRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOAuthCreds = /* GraphQL */ `
  query GetOAuthCreds($id: ID!) {
    getOAuthCreds(id: $id) {
      id
      account
      platform
      refresh_token
      createdAt
      updatedAt
    }
  }
`;
export const listOAuthCredss = /* GraphQL */ `
  query ListOAuthCredss(
    $filter: ModeloAuthCredsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOAuthCredss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        platform
        refresh_token
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWebProfiles = /* GraphQL */ `
  query GetWebProfiles($id: ID!) {
    getWebProfiles(id: $id) {
      id
      account
      platform
      profile_id
      metadata
      enabled
      createdAt
      updatedAt
    }
  }
`;
export const listWebProfiless = /* GraphQL */ `
  query ListWebProfiless(
    $filter: ModelWebProfilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebProfiless(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        platform
        profile_id
        metadata
        enabled
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFiscalYear = /* GraphQL */ `
  query GetFiscalYear($id: ID!) {
    getFiscalYear(id: $id) {
      id
      account
      year
      budget
      startMonth
      createdAt
      updatedAt
      baselineMetrics {
        items {
          id
          fiscalYearId
          name
          goal
          avgDealSize
          mrktSourcePercent
          updatedBy
          account
          mrkt_year_won_leads
          sales_year_won_leads
          budget_allocation
          mrkt_qtr_won_leads
          sales_qtr_won_leads
          mrkt_qtr_budget_allocation
          sales_qtr_budget_allocation
          segment_type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listFiscalYears = /* GraphQL */ `
  query ListFiscalYears(
    $filter: ModelFiscalYearFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiscalYears(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        year
        budget
        startMonth
        createdAt
        updatedAt
        baselineMetrics {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBaselineMetric = /* GraphQL */ `
  query GetBaselineMetric($id: ID!) {
    getBaselineMetric(id: $id) {
      id
      fiscalYearId
      name
      goal
      avgDealSize
      mrktSourcePercent
      updatedBy
      account
      mrkt_year_won_leads
      sales_year_won_leads
      budget_allocation
      mrkt_qtr_won_leads
      sales_qtr_won_leads
      mrkt_qtr_budget_allocation
      sales_qtr_budget_allocation
      segment_type
      createdAt
      updatedAt
      funnelConfigs {
        items {
          id
          baselineMetricId
          name
          account
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listBaselineMetrics = /* GraphQL */ `
  query ListBaselineMetrics(
    $filter: ModelBaselineMetricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBaselineMetrics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fiscalYearId
        name
        goal
        avgDealSize
        mrktSourcePercent
        updatedBy
        account
        mrkt_year_won_leads
        sales_year_won_leads
        budget_allocation
        mrkt_qtr_won_leads
        sales_qtr_won_leads
        mrkt_qtr_budget_allocation
        sales_qtr_budget_allocation
        segment_type
        createdAt
        updatedAt
        funnelConfigs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getFunnelConfig = /* GraphQL */ `
  query GetFunnelConfig($id: ID!) {
    getFunnelConfig(id: $id) {
      id
      baselineMetricId
      name
      account
      createdAt
      updatedAt
      stages {
        items {
          id
          funnelConfigID
          order
          name
          conversion
          count
          account
          salesAndMarketingAlignmentID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listFunnelConfigs = /* GraphQL */ `
  query ListFunnelConfigs(
    $filter: ModelFunnelConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFunnelConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        baselineMetricId
        name
        account
        createdAt
        updatedAt
        stages {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getStage = /* GraphQL */ `
  query GetStage($id: ID!) {
    getStage(id: $id) {
      id
      funnelConfigID
      order
      name
      conversion
      count
      account
      salesAndMarketingAlignmentID
      createdAt
      updatedAt
      salesAndMarketingAlignment {
        id
        account
        name
        disabled
        isDefault
        isLast
        showPipelineCoverage
        order
        howitcomes
        thingstokeepinmind
        definition
        stageType
        internalStage
        createdAt
        updatedAt
      }
    }
  }
`;
export const listStages = /* GraphQL */ `
  query ListStages(
    $filter: ModelStageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        funnelConfigID
        order
        name
        conversion
        count
        account
        salesAndMarketingAlignmentID
        createdAt
        updatedAt
        salesAndMarketingAlignment {
          id
          account
          name
          disabled
          isDefault
          isLast
          showPipelineCoverage
          order
          howitcomes
          thingstokeepinmind
          definition
          stageType
          internalStage
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSalesAndMarketingAlignment = /* GraphQL */ `
  query GetSalesAndMarketingAlignment($id: ID!) {
    getSalesAndMarketingAlignment(id: $id) {
      id
      account
      name
      disabled
      isDefault
      isLast
      showPipelineCoverage
      order
      howitcomes
      thingstokeepinmind
      definition
      stageType
      internalStage
      createdAt
      updatedAt
    }
  }
`;
export const listSalesAndMarketingAlignments = /* GraphQL */ `
  query ListSalesAndMarketingAlignments(
    $filter: ModelSalesAndMarketingAlignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesAndMarketingAlignments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        name
        disabled
        isDefault
        isLast
        showPipelineCoverage
        order
        howitcomes
        thingstokeepinmind
        definition
        stageType
        internalStage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBudget = /* GraphQL */ `
  query GetBudget($id: ID!) {
    getBudget(id: $id) {
      id
      account
      name
      active
      year
      budget
      budget_allocation
      BaselineMetricID
      lockedMonths
      createdAt
      updatedAt
      campaigns {
        items {
          id
          account
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listBudgets = /* GraphQL */ `
  query ListBudgets(
    $filter: ModelBudgetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBudgets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        name
        active
        year
        budget
        budget_allocation
        BaselineMetricID
        lockedMonths
        createdAt
        updatedAt
        campaigns {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCampaignChannel = /* GraphQL */ `
  query GetCampaignChannel($id: ID!) {
    getCampaignChannel(id: $id) {
      id
      name
      channelType
      account
      createdAt
      updatedAt
    }
  }
`;
export const listCampaignChannels = /* GraphQL */ `
  query ListCampaignChannels(
    $filter: ModelCampaignChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaignChannels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        channelType
        account
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      account
      name
      channel
      vendor
      metadata
      createdAt
      updatedAt
      campaign_details {
        items {
          id
          account
          campaign_id
          date_start
          date_end
          metadata
          createdAt
          updatedAt
        }
        nextToken
      }
      budgets {
        items {
          id
          account
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        name
        channel
        vendor
        metadata
        createdAt
        updatedAt
        campaign_details {
          nextToken
        }
        budgets {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCampaignDetail = /* GraphQL */ `
  query GetCampaignDetail($id: ID!) {
    getCampaignDetail(id: $id) {
      id
      account
      campaign_id
      date_start
      date_end
      metadata
      createdAt
      updatedAt
      campaign_months {
        items {
          id
          account
          campaign_detail_id
          month
          budget
          leads
          weight
          weight_lead
          verified_budget
          verified_leads
          metadata
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCampaignDetails = /* GraphQL */ `
  query ListCampaignDetails(
    $filter: ModelCampaignDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaignDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        campaign_id
        date_start
        date_end
        metadata
        createdAt
        updatedAt
        campaign_months {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCampaignMonth = /* GraphQL */ `
  query GetCampaignMonth($id: ID!) {
    getCampaignMonth(id: $id) {
      id
      account
      campaign_detail_id
      month
      budget
      leads
      weight
      weight_lead
      verified_budget
      verified_leads
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const listCampaignMonths = /* GraphQL */ `
  query ListCampaignMonths(
    $filter: ModelCampaignMonthFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaignMonths(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        campaign_detail_id
        month
        budget
        leads
        weight
        weight_lead
        verified_budget
        verified_leads
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBudgetCampaign = /* GraphQL */ `
  query GetBudgetCampaign($id: ID!) {
    getBudgetCampaign(id: $id) {
      id
      account
      createdAt
      updatedAt
      budget {
        id
        account
        name
        active
        year
        budget
        budget_allocation
        BaselineMetricID
        lockedMonths
        createdAt
        updatedAt
        campaigns {
          nextToken
        }
      }
      campaign {
        id
        account
        name
        channel
        vendor
        metadata
        createdAt
        updatedAt
        campaign_details {
          nextToken
        }
        budgets {
          nextToken
        }
      }
    }
  }
`;
export const listBudgetCampaigns = /* GraphQL */ `
  query ListBudgetCampaigns(
    $filter: ModelBudgetCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBudgetCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        createdAt
        updatedAt
        budget {
          id
          account
          name
          active
          year
          budget
          budget_allocation
          BaselineMetricID
          lockedMonths
          createdAt
          updatedAt
        }
        campaign {
          id
          account
          name
          channel
          vendor
          metadata
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCourseCategory = /* GraphQL */ `
  query GetCourseCategory($id: ID!) {
    getCourseCategory(id: $id) {
      id
      name
      createdAt
      updatedAt
      courses {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCourseCategorys = /* GraphQL */ `
  query ListCourseCategorys(
    $filter: ModelCourseCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        courses {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      name
      slug
      short_description
      description
      featuredimage
      attachments
      createdAt
      updatedAt
      videos {
        items {
          id
          courseId
          name
          title
          description
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      categories {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        slug
        short_description
        description
        featuredimage
        attachments
        createdAt
        updatedAt
        videos {
          nextToken
        }
        categories {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCourseVideo = /* GraphQL */ `
  query GetCourseVideo($id: ID!) {
    getCourseVideo(id: $id) {
      id
      courseId
      name
      title
      description
      duration
      createdAt
      updatedAt
    }
  }
`;
export const listCourseVideos = /* GraphQL */ `
  query ListCourseVideos(
    $filter: ModelCourseVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        courseId
        name
        title
        description
        duration
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourseCategoryConnection = /* GraphQL */ `
  query GetCourseCategoryConnection($id: ID!) {
    getCourseCategoryConnection(id: $id) {
      id
      createdAt
      updatedAt
      courseCategory {
        id
        name
        createdAt
        updatedAt
        courses {
          nextToken
        }
      }
      course {
        id
        name
        slug
        short_description
        description
        featuredimage
        attachments
        createdAt
        updatedAt
        videos {
          nextToken
        }
        categories {
          nextToken
        }
      }
    }
  }
`;
export const listCourseCategoryConnections = /* GraphQL */ `
  query ListCourseCategoryConnections(
    $filter: ModelCourseCategoryConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseCategoryConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        courseCategory {
          id
          name
          createdAt
          updatedAt
        }
        course {
          id
          name
          slug
          short_description
          description
          featuredimage
          attachments
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getChartData = /* GraphQL */ `
  query GetChartData($id: ID!) {
    getChartData(id: $id) {
      id
      account
      key
      metadata
      chart_type
      source
      analytics_screen
      dashboard_screen
      createdAt
      updatedAt
    }
  }
`;
export const listChartDatas = /* GraphQL */ `
  query ListChartDatas(
    $filter: ModelChartDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChartDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        key
        metadata
        chart_type
        source
        analytics_screen
        dashboard_screen
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCrmSyncHistory = /* GraphQL */ `
  query GetCrmSyncHistory($id: ID!) {
    getCrmSyncHistory(id: $id) {
      id
      account
      crm
      profile_id
      source
      status
      createdAt
      detail
      updatedAt
    }
  }
`;
export const listCrmSyncHistorys = /* GraphQL */ `
  query ListCrmSyncHistorys(
    $filter: ModelCrmSyncHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrmSyncHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        crm
        profile_id
        source
        status
        createdAt
        detail
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCrmSyncHistoryByDate = /* GraphQL */ `
  query GetCrmSyncHistoryByDate(
    $crm: CRM
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCrmSyncHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCrmSyncHistoryByDate(
      crm: $crm
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        crm
        profile_id
        source
        status
        createdAt
        detail
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProperty = /* GraphQL */ `
  query GetProperty($id: ID!) {
    getProperty(id: $id) {
      id
      propertiesPackageID
      key
      value
      type
      createdAt
      updatedAt
    }
  }
`;
export const listPropertys = /* GraphQL */ `
  query ListPropertys(
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        propertiesPackageID
        key
        value
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPropertiesPackage = /* GraphQL */ `
  query GetPropertiesPackage($id: ID!) {
    getPropertiesPackage(id: $id) {
      id
      name
      createdAt
      updatedAt
      properties {
        items {
          id
          propertiesPackageID
          key
          value
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPropertiesPackages = /* GraphQL */ `
  query ListPropertiesPackages(
    $filter: ModelPropertiesPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertiesPackages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        properties {
          nextToken
        }
        users {
          nextToken
        }
        account {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUserPackage = /* GraphQL */ `
  query GetUserPackage($id: ID!) {
    getUserPackage(id: $id) {
      id
      owner
      createdAt
      updatedAt
      packages {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUserPackages = /* GraphQL */ `
  query ListUserPackages(
    $filter: ModelUserPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        createdAt
        updatedAt
        packages {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUserPackagesPropertiesPackageConnection = /* GraphQL */ `
  query GetUserPackagesPropertiesPackageConnection($id: ID!) {
    getUserPackagesPropertiesPackageConnection(id: $id) {
      id
      sortOrder
      createdAt
      updatedAt
      propertiespackages {
        id
        name
        createdAt
        updatedAt
        properties {
          nextToken
        }
        users {
          nextToken
        }
        account {
          nextToken
        }
      }
      userpackages {
        id
        owner
        createdAt
        updatedAt
        packages {
          nextToken
        }
      }
    }
  }
`;
export const listUserPackagesPropertiesPackageConnections = /* GraphQL */ `
  query ListUserPackagesPropertiesPackageConnections(
    $filter: ModelUserPackagesPropertiesPackageConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPackagesPropertiesPackageConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sortOrder
        createdAt
        updatedAt
        propertiespackages {
          id
          name
          createdAt
          updatedAt
        }
        userpackages {
          id
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAccountPackage = /* GraphQL */ `
  query GetAccountPackage($id: ID!) {
    getAccountPackage(id: $id) {
      id
      account
      createdAt
      updatedAt
      packages {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listAccountPackages = /* GraphQL */ `
  query ListAccountPackages(
    $filter: ModelAccountPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        createdAt
        updatedAt
        packages {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAccountPackagesPropertiesPackageConnection = /* GraphQL */ `
  query GetAccountPackagesPropertiesPackageConnection($id: ID!) {
    getAccountPackagesPropertiesPackageConnection(id: $id) {
      id
      sortOrder
      createdAt
      updatedAt
      propertiespackages {
        id
        name
        createdAt
        updatedAt
        properties {
          nextToken
        }
        users {
          nextToken
        }
        account {
          nextToken
        }
      }
      accountpackages {
        id
        account
        createdAt
        updatedAt
        packages {
          nextToken
        }
      }
    }
  }
`;
export const listAccountPackagesPropertiesPackageConnections = /* GraphQL */ `
  query ListAccountPackagesPropertiesPackageConnections(
    $filter: ModelAccountPackagesPropertiesPackageConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountPackagesPropertiesPackageConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sortOrder
        createdAt
        updatedAt
        propertiespackages {
          id
          name
          createdAt
          updatedAt
        }
        accountpackages {
          id
          account
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      name
      description
      bulletPoints
      packageID
      stripePriceID
      priceValues
      listed
      order
      createdAt
      updatedAt
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        bulletPoints
        packageID
        stripePriceID
        priceValues
        listed
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPositioning = /* GraphQL */ `
  query GetPositioning($id: ID!) {
    getPositioning(id: $id) {
      id
      account
      key_differentiators
      value_proposition
      awards
      competitive {
        competitor_name
        website
      }
      offers
      createdAt
      updatedAt
    }
  }
`;
export const listPositionings = /* GraphQL */ `
  query ListPositionings(
    $filter: ModelPositioningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPositionings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        key_differentiators
        value_proposition
        awards
        competitive {
          competitor_name
          website
        }
        offers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTargetIdealCustomProfile = /* GraphQL */ `
  query GetTargetIdealCustomProfile($id: ID!) {
    getTargetIdealCustomProfile(id: $id) {
      id
      account
      market_segments
      industries
      regions
      createdAt
      updatedAt
    }
  }
`;
export const listTargetIdealCustomProfiles = /* GraphQL */ `
  query ListTargetIdealCustomProfiles(
    $filter: ModelTargetIdealCustomProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTargetIdealCustomProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        market_segments
        industries
        regions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPersonas = /* GraphQL */ `
  query GetPersonas($id: ID!) {
    getPersonas(id: $id) {
      id
      account
      personas {
        name
        person_type
        job_title
        job_functions
        job_level
        details_responsibilities
        pain_points
        pain_point_solution
        messaging_theme
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPersonass = /* GraphQL */ `
  query ListPersonass(
    $filter: ModelPersonasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonass(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        personas {
          name
          person_type
          job_title
          job_functions
          job_level
          details_responsibilities
          pain_points
          pain_point_solution
          messaging_theme
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCurrentMarketActivity = /* GraphQL */ `
  query GetCurrentMarketActivity($id: ID!) {
    getCurrentMarketActivity(id: $id) {
      id
      account
      channels
      email_marketing
      branded_keywords
      non_branded_keywords
      createdAt
      updatedAt
    }
  }
`;
export const listCurrentMarketActivitys = /* GraphQL */ `
  query ListCurrentMarketActivitys(
    $filter: ModelCurrentMarketActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurrentMarketActivitys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        channels
        email_marketing
        branded_keywords
        non_branded_keywords
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCurrentSalesMarketingTools = /* GraphQL */ `
  query GetCurrentSalesMarketingTools($id: ID!) {
    getCurrentSalesMarketingTools(id: $id) {
      id
      account
      marketing_tools
      other
      createdAt
      updatedAt
    }
  }
`;
export const listCurrentSalesMarketingToolss = /* GraphQL */ `
  query ListCurrentSalesMarketingToolss(
    $filter: ModelCurrentSalesMarketingToolsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurrentSalesMarketingToolss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        marketing_tools
        other
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCrmMarketingAutomationSystem = /* GraphQL */ `
  query GetCrmMarketingAutomationSystem($id: ID!) {
    getCrmMarketingAutomationSystem(id: $id) {
      id
      account
      lead_contact_de_duplication
      lead_contact_de_duplication_process
      lead_contact_assignment
      lead_contact_assignment_process
      lead_contact_status
      lead_contact_status_process
      lead_contact_source
      lead_contact_source_process
      lead_contact_creation
      lead_contact_creation_process
      lead_contact_conversion
      lead_contact_conversion_process
      account_company_creation
      account_company_creation_process
      opportunity_deal_creation
      opportunity_deal_creation_process
      marketing_sales_sla
      marketing_sales_sla_process
      createdAt
      updatedAt
    }
  }
`;
export const listCrmMarketingAutomationSystems = /* GraphQL */ `
  query ListCrmMarketingAutomationSystems(
    $filter: ModelCrmMarketingAutomationSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrmMarketingAutomationSystems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        lead_contact_de_duplication
        lead_contact_de_duplication_process
        lead_contact_assignment
        lead_contact_assignment_process
        lead_contact_status
        lead_contact_status_process
        lead_contact_source
        lead_contact_source_process
        lead_contact_creation
        lead_contact_creation_process
        lead_contact_conversion
        lead_contact_conversion_process
        account_company_creation
        account_company_creation_process
        opportunity_deal_creation
        opportunity_deal_creation_process
        marketing_sales_sla
        marketing_sales_sla_process
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSocialChannels = /* GraphQL */ `
  query GetSocialChannels($id: ID!) {
    getSocialChannels(id: $id) {
      id
      account
      facebook
      facebook_link
      instagram
      instagram_link
      linkedin
      linkedin_link
      youtube
      youtube_link
      twitter
      twitter_link
      createdAt
      updatedAt
    }
  }
`;
export const listSocialChannelss = /* GraphQL */ `
  query ListSocialChannelss(
    $filter: ModelSocialChannelsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSocialChannelss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        facebook
        facebook_link
        instagram
        instagram_link
        linkedin
        linkedin_link
        youtube
        youtube_link
        twitter
        twitter_link
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAssetLibrary = /* GraphQL */ `
  query GetAssetLibrary($id: ID!) {
    getAssetLibrary(id: $id) {
      id
      account
      name
      hosted_url
      creative_files_url
      abstract
      thumbnail
      type
      metadata
      created_date
      market_segments
      industries
      regions
      personas
      createdAt
      updatedAt
    }
  }
`;
export const listAssetLibrarys = /* GraphQL */ `
  query ListAssetLibrarys(
    $filter: ModelAssetLibraryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetLibrarys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        name
        hosted_url
        creative_files_url
        abstract
        thumbnail
        type
        metadata
        created_date
        market_segments
        industries
        regions
        personas
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMaterializerSyncHistory = /* GraphQL */ `
  query GetMaterializerSyncHistory($id: ID!) {
    getMaterializerSyncHistory(id: $id) {
      id
      account
      source
      status
      createdAt
      detail
      updatedAt
    }
  }
`;
export const listMaterializerSyncHistorys = /* GraphQL */ `
  query ListMaterializerSyncHistorys(
    $filter: ModelMaterializerSyncHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaterializerSyncHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        source
        status
        createdAt
        detail
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMaterializerSyncHistoryByAccount = /* GraphQL */ `
  query GetMaterializerSyncHistoryByAccount(
    $account: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMaterializerSyncHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMaterializerSyncHistoryByAccount(
      account: $account
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        source
        status
        createdAt
        detail
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSDRCapacityCalculator = /* GraphQL */ `
  query GetSDRCapacityCalculator($id: ID!) {
    getSDRCapacityCalculator(id: $id) {
      id
      account
      totalWeeksInPeriod
      targetNoOfCalls
      dailyCallCApacity
      estimatedConnectRate
      connectToMarketingQualifiedLeadRate
      marketingQualifiedLeadToShowRate
      fiscalYearID
      baselineMetricID
      stageID
      metadata
      createdAt
      updatedAt
      fiscalYear {
        id
        account
        year
        budget
        startMonth
        createdAt
        updatedAt
        baselineMetrics {
          nextToken
        }
      }
      baselineMetric {
        id
        fiscalYearId
        name
        goal
        avgDealSize
        mrktSourcePercent
        updatedBy
        account
        mrkt_year_won_leads
        sales_year_won_leads
        budget_allocation
        mrkt_qtr_won_leads
        sales_qtr_won_leads
        mrkt_qtr_budget_allocation
        sales_qtr_budget_allocation
        segment_type
        createdAt
        updatedAt
        funnelConfigs {
          nextToken
        }
      }
      stage {
        id
        funnelConfigID
        order
        name
        conversion
        count
        account
        salesAndMarketingAlignmentID
        createdAt
        updatedAt
        salesAndMarketingAlignment {
          id
          account
          name
          disabled
          isDefault
          isLast
          showPipelineCoverage
          order
          howitcomes
          thingstokeepinmind
          definition
          stageType
          internalStage
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listSDRCapacityCalculators = /* GraphQL */ `
  query ListSDRCapacityCalculators(
    $filter: ModelSDRCapacityCalculatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSDRCapacityCalculators(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        totalWeeksInPeriod
        targetNoOfCalls
        dailyCallCApacity
        estimatedConnectRate
        connectToMarketingQualifiedLeadRate
        marketingQualifiedLeadToShowRate
        fiscalYearID
        baselineMetricID
        stageID
        metadata
        createdAt
        updatedAt
        fiscalYear {
          id
          account
          year
          budget
          startMonth
          createdAt
          updatedAt
        }
        baselineMetric {
          id
          fiscalYearId
          name
          goal
          avgDealSize
          mrktSourcePercent
          updatedBy
          account
          mrkt_year_won_leads
          sales_year_won_leads
          budget_allocation
          mrkt_qtr_won_leads
          sales_qtr_won_leads
          mrkt_qtr_budget_allocation
          sales_qtr_budget_allocation
          segment_type
          createdAt
          updatedAt
        }
        stage {
          id
          funnelConfigID
          order
          name
          conversion
          count
          account
          salesAndMarketingAlignmentID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSDRCompensationModel = /* GraphQL */ `
  query GetSDRCompensationModel($id: ID!) {
    getSDRCompensationModel(id: $id) {
      id
      account
      name
      OTE
      base_salary
      model_type
      variableCompensation
      SDRCompensationModelStageMetric
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const listSDRCompensationModels = /* GraphQL */ `
  query ListSDRCompensationModels(
    $filter: ModelSDRCompensationModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSDRCompensationModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        name
        OTE
        base_salary
        model_type
        variableCompensation
        SDRCompensationModelStageMetric
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSDRMeetingAndOpportunityModel = /* GraphQL */ `
  query GetSDRMeetingAndOpportunityModel($id: ID!) {
    getSDRMeetingAndOpportunityModel(id: $id) {
      id
      account
      hubOwnerId
      compensationModelID
      metadata
      createdAt
      updatedAt
      compensationModel {
        id
        account
        name
        OTE
        base_salary
        model_type
        variableCompensation
        SDRCompensationModelStageMetric
        metadata
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSDRMeetingAndOpportunityModels = /* GraphQL */ `
  query ListSDRMeetingAndOpportunityModels(
    $filter: ModelSDRMeetingAndOpportunityModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSDRMeetingAndOpportunityModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        hubOwnerId
        compensationModelID
        metadata
        createdAt
        updatedAt
        compensationModel {
          id
          account
          name
          OTE
          base_salary
          model_type
          variableCompensation
          SDRCompensationModelStageMetric
          metadata
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDashboardData = /* GraphQL */ `
  query GetDashboardData($id: ID!) {
    getDashboardData(id: $id) {
      id
      dashboardName
      dashboardID
      createdAt
      updatedAt
    }
  }
`;
export const listDashboardDatas = /* GraphQL */ `
  query ListDashboardDatas(
    $filter: ModeldashboardDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboardDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dashboardName
        dashboardID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
