import { Loading3QuartersOutlined, MoreOutlined } from "@ant-design/icons";
import { Table, Typography, Select, Space, Input, Button, Form, message, Modal, Menu, Dropdown, Skeleton } from "antd";
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import sdk from "sdk/Accounts";

const Users = (props) => {
  const [Users, setUsers] = useState([]);
  const [SearchParams, setSearchParams] = useState({});
  const [userbackup, setuserbackup] = useState({});
  const [TableLoading, setTableLoading] = useState(false);
  const [suspendedUsers, setsuspendedUsers] = useState([])
  const userData = useSelector(state => state.loginReducer.userData)
  const [isUsersuspendModelOpen, setisUsersuspendModelOpen] = useState(false);
  const [userSuspendLoading, setuserSuspendLoading] = useState(false);
  const [userToSusupend, setuserToSusupend] = useState({})
  const [searchOption, setsearchOption] = useState("Email")

  const [search_form] = Form.useForm()

  const {UsersFilter} = props

  const get_attribute = (user, key) => {
    let result = user.filter((attribute) => attribute.Name === key);
    if (result.length > 0) {
      return result[0]["Value"];
    } else {
      return "";
    }
  };

  const [getUsersDataLoader, setgetUsersDataLoader] = useState(true)

  const getUserData = async () => {
    const user_path = "/admin/groupswithusers";
    Users?.length > 0 && setTableLoading(true);
    
    try {
      API.get("exploricsREST", user_path)
        .then((result) => {
          setsuspendedUsers([])
          let usersdata = []
          // eslint-disable-next-line array-callback-return
          result.map((item) => {
            // eslint-disable-next-line array-callback-return
            item?.Users?.map(user => {
              let data = user
              delete item?.Users; 
              data['Company_Data'] = item
              item.GroupName === "suspend" && suspendedUsers.push(get_attribute(user?.Attributes, "email"))
              if (item.Description && get_attribute(data.Attributes, "email") !== userData?.email)
                usersdata.push(data)
            })
          })
          // eslint-disable-next-line array-callback-return
          usersdata.map((data) => {
            suspendedUsers.includes(get_attribute(data?.Attributes, "email")) ? data['suspend'] = true : data['suspend'] = false
          })
          setUsers(usersdata);
          setuserbackup(usersdata)
          if(SearchParams)
            searchUser(SearchParams)
          // if(Object.keys(UsersFilter)?.length > 0){
          //   search_form.setFieldsValue(UsersFilter)
          //   setSearchParams(UsersFilter)
          //   searchUser(UsersFilter)
          // }
          setTableLoading(false);
          setgetUsersDataLoader(false);
        })
        .catch((e) => {
          console.log("Error :", e);
          setgetUsersDataLoader(false);
        });
    } catch (e) {
      console.log("Error :", e);
      setgetUsersDataLoader(false);
    }
  }
  useEffect(() => {
    getUserData()
    // setUsersFilter({})
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(userbackup?.length > 0 && Object.keys(UsersFilter)?.length > 0){

      handle_search({search_type : UsersFilter?.Name, search_value:UsersFilter?.Value})
      search_form.setFieldsValue({search_type : UsersFilter?.Name, search_value:UsersFilter?.Value})

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[UsersFilter,userbackup])

  const handleuserSuspend = async () => {
    if (userToSusupend) {
      setuserSuspendLoading(true)
      let userid = userToSusupend?.id
      let suspend = userToSusupend?.suspend       
      let result = await sdk.userSuspention(userid, suspend);
      if (result.error) {
        message.error(result.error, 5);
      } else {
        message.success(
          suspend? "User suspended successfully" : "User unsuspended successfully",
          5
        );
      }
    }
    setisUsersuspendModelOpen(false)
    setuserSuspendLoading(false)
    getUserData()
    }

    const [userToDelete, setuserToDelete] = useState({})

    const handleDeleteUser = async () =>{
      if(userToDelete){
      setuserSuspendLoading(true)
          let result = await sdk.DeleteUser(userToDelete)
          if (result.error) {
            message.error(result.error, 5);
            setuserToDelete({})
          } else {
            message.success("User Deleted Successfully");
            setuserToDelete({})
          }
      setuserSuspendLoading(false)
      getUserData()

      }
    }

  const columns = [
    {
      title: "Name",
      width: "18%",
      dataIndex: "Attributes",
      key: "Name",
      align: "center",
      render: (name) => (
        <small>
          {get_attribute(name, "given_name") +
            " " +
            get_attribute(name, "family_name")}
        </small>
      ),
      sorter: (a, b) => {
        var namea = a.Attributes[2].Value + " " + a.Attributes[3].Value;
        var nameb = b.Attributes[2].Value + " " + b.Attributes[3].Value;
        return namea.localeCompare(nameb);
      },
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "Email",
      width:"25%",
      dataIndex: "Attributes",
      key: "Email",
      render: (attr) => <small>{get_attribute(attr, "email")}</small>,
      sorter: (a, b) => {
        var emaila = a.Attributes[4].Value;
        var emailb = b.Attributes[4].Value;
        return String(emaila).localeCompare(emailb);
      },
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "Status",
      width:"14%",
      dataIndex: "UserStatus",
      key: "user_status",
      render: (status) => <small>{status}</small>,
    },
    {
      title: "Last modified",
      width:"18%",
      dataIndex: "UserLastModifiedDate",
      key: "last_modified",
      render: (modifyDate) => (
        <small>
          <Moment date={modifyDate} format="lll" />
        </small>
      ),
      sorter: (a, b) => {
        return String(a.UserLastModifiedDate).localeCompare(
          b.UserLastModifiedDate
        );
      },
      defaultSortOrder: "descend",
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Company",
      width:"14%",
      dataIndex: "Company_Data",
      key: "Company_Name",
      align: "center",
      render: (name, record) => (
        <small title = "Click here to select company" className = "company-name" onClick={() => {
          setSearchParams({
            Name: 'Company',
            Value: String(name?.Description) ? String(name?.Description).toLowerCase() : "",
          });
          }}>
          {name?.Description}
        </small>
      ),
      sorter: (a, b) => {
        return a?.Company_Data?.Description.localeCompare(b?.Company_Data?.Description);
      },
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "",
      width:"3%",
      dataIndex: "suspend",
      key: "actions",
      render: (suspend, record) => <div style={{display:'flex', justifyContent:"center"}}><Dropdown
      trigger={["click"]}
      overlay={
        <Menu>
          <Menu.Item
            key="suspend"
            onClick={() => {
              let temp = {id : record?.Username, suspend : !suspend }
              setuserToSusupend(temp)
              setisUsersuspendModelOpen(true)               
            }}
            >
            <strong>{suspend ? "Unsuspend" : "Suspend" }</strong>
          </Menu.Item>

          <Menu.Item
            key="delete User"
            onClick={() => {
              let temp = {id: get_attribute(record?.Attributes,"email")}
              setuserToDelete(temp)

            }}
          >

              <strong>Delete User</strong>

          </Menu.Item>
        </Menu>
      }
    >
      <MoreOutlined style={{ fontSize: "25px" }} />
    </Dropdown></div>,
    },
  ];

  const handle_search = (value) => {
    setUsers(userbackup)
    setSearchParams({
      Name: value["search_type"],
      Value: value["search_value"] ? value["search_value"].toLowerCase() : "",
    });
  };

  useEffect(() => {
    searchUser(SearchParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchParams]);

  const searchUser = (SearchParams) => {
    if (SearchParams.Name === "First name") {
      setUsers(Users.filter((user) =>
        String(get_attribute(user.Attributes, "given_name"))
          .toLowerCase()
          .includes(SearchParams.Value.toLowerCase().trim())
      ))
    }
    else if (SearchParams.Name === "Last name") {
      setUsers(Users.filter((user) =>
        String(get_attribute(user.Attributes, "family_name"))
          .toLowerCase()
          .includes(SearchParams.Value.toLowerCase().trim())
      ))
    }
    else if (SearchParams.Name === "Email") {
      setUsers(Users.filter((user) =>
        String(get_attribute(user.Attributes, "email"))
          .toLowerCase()
          .includes(SearchParams.Value.toLowerCase().trim())
      ))
    }
    else if (SearchParams.Name === "Company") {
      setUsers(Users.filter((user) =>
        String(user['Company_Data']?.Description)
          .toLowerCase()
          .includes(SearchParams.Value.toLowerCase().trim())
      ))
    }
  }


  return (
    <>
      <div className="user-container" style={{marginTop:'20px'}}>
      {/* { TableLoading && <div className="loading">Loading;</div>} */}
        <Space direction="vertical" style={{ width: "100%" }} size={1}>
          <Typography.Title level={5} style={{
                marginBottom: "30px",
                fontSize: "18px",
                background: "#f5f7fa",
                padding: "10px 5px",
                textAlign: "center",
                width:"100%",
                display:"block",
                color:"#1D2939",
                fontWeight:500
              }}
              className="titleText fw500 panel-design">User Management</Typography.Title>


          <Form
            className="componiesForm"
            name="search_users"
            onFinish={handle_search}
            initialValues={{ search_type: "Email" }}
            form={search_form}
          >
            <Space direction="horizontal" style={{ padding: "0px 5px" }}>
              <Form.Item name="search_type">
                <Select
                  style={{ width: "125px" }}
                  size="large"
                  onChange={(e) => setsearchOption(e)}
                >
                  <Select.Option
                    key="Email"
                  >
                    Email
                  </Select.Option>
                  <Select.Option
                    key="First name"
                  >
                    First Name
                  </Select.Option>
                  <Select.Option
                    key="Last name"
                  >
                    Last Name
                  </Select.Option>
                  <Select.Option
                    key="Company"
                  >
                    Company
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="search_value">
                <Input placeholder={`Enter ${searchOption}`} size="large" className="general-input"/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="secondaryButton" style={{width:"120px"}} size="middle" disabled={TableLoading}>
                  Search
                </Button>

              </Form.Item>
        {TableLoading && <Loading3QuartersOutlined spin style={{fontSize:22,marginLeft:10, marginBottom:22}}/>}
            </Space>
          </Form>

          <div className="table-wrapper">
            {getUsersDataLoader ? <Skeleton paragraph={{rows:7}} active/> :
            <Table
              bordered
              size="middle"
              dataSource={Users}
              columns={columns}
              // loading={TableLoading}
              className="user-company-table general-table"
              ></Table>
            }
          </div>
        </Space>
      </div>

      <Modal
          visible={isUsersuspendModelOpen}
          closable={false}
          centered
          footer={[]}
          style={{ borderRadius: "20px" }}
        >
          <div className="delete-modal-body">
            <Typography.Text style={{ marginTop: "15px", fontSize: "20px", marginBottom:"15px" }}>
              {userToSusupend.suspend ? "Are you sure you want to suspend the user?" : "Are you sure you want to unsuspend the user?"}
            </Typography.Text>
            <div className="delete-modal-footer">
              <Button
                type="primary"
                size="small"
                ghost
                onClick={() => {
                  handleuserSuspend();
                }}
                style={{ borderColor: "#0F61DB", color: "#0F61DB" }}
                loading={userSuspendLoading}
              >
                Yes
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={() => setisUsersuspendModelOpen(false)}
              >
                No
              </Button>
            </div>
          </div>
        </Modal>

      <Modal
          visible={Object.keys(userToDelete)?.length > 0}
          closable={false}
          centered
          footer={[]}
          style={{ borderRadius: "20px" }}
        >
          <div className="delete-modal-body">
            <Typography.Text style={{ marginTop: "15px", fontSize: "20px", marginBottom:"15px" }}>
              Are you sure you want to delete the user?
            </Typography.Text>
            <div className="delete-modal-footer">
              <Button
                type="primary"
                size="small"
                ghost
                onClick={() => {
                  handleDeleteUser();
                }}
                style={{ borderColor: "#0F61DB", color: "#0F61DB" }}
                loading={userSuspendLoading}
              >
                Yes
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={() => setuserToDelete({})}
              >
                No
              </Button>
            </div>
          </div>
        </Modal>
    </>
  );
};

export default Users;
