import React, { useEffect, useState } from "react";
import { Col, Row, Skeleton } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getCategoryCourseList } from "store/actions/coachingAction";
import { AmplifyS3Image } from "@aws-amplify/ui-react";
import { useHistory } from "react-router-dom";
import {TrackClickFunction} from '../../commons/HeapTrack/TrackClickFunction'

const PopularCoaching = (props) => {
  const {isLoading} = props
  const dispatch = useDispatch();
  const { CategoryCouseList } = useSelector((state) => state.coachingReducer);
  const history = useHistory();

  useEffect(() => {
    dispatch(getCategoryCourseList());
  }, [dispatch]);

  const handleCourseClick = (CourseDetails) => {
    if(props.isContent){
      history.push({
        pathname: `/coaching/content/course/${CourseDetails.course.slug}`,
        state: { isContent: true }
      });
    }else{
    history.push({
      pathname: `/coaching/course/${CourseDetails.course.slug}`,
    });
  }
  };

  const [popularCoachingData, setPopularCoachingData] = useState();
  useEffect(() => {
    const coachingArray = [];
    CategoryCouseList.listCourseCategorys?.items.map((CategoryCouseListVal, index) => { // eslint-disable-line
      CategoryCouseListVal.courses?.items.map((coachingCatValue) => {  // eslint-disable-line
        coachingArray.push({details:coachingCatValue, category: CategoryCouseListVal.name});
      });
    });

    if (coachingArray.length > 3) {
      setPopularCoachingData(coachingArray.slice(-4));
    } else {
      setPopularCoachingData(coachingArray);
    }
  }, [CategoryCouseList]);

  return (
    <>

    <div
      className="site-card-wrapper coaching-wrapper panel-design"
      style={{ padding: "20px 15px", marginTop:'20px'}}
    >
      <div className="coaching-title titleText" style={{padding:0, marginBottom:'20px'}}>Most popular from Demand Generation coaching</div>
      {isLoading ? <Skeleton active /> :
      <Row
        gutter={16}
        style={{ marginLeft:0, marginRight:0 }}
      >
        {
        popularCoachingData?.length > 0 ? (
          
          popularCoachingData?.map((coursesVal, index) => {
            
            return (
              <Col
                span={6}
                
                xs={24}
                md={12}
                lg={6}
                key={index}
                
              >
                <div style={{padding: '8px',
                  border: '1px solid rgb(208, 213, 221)', height:'100%'}} className="coaching-design panel-design">
                  <div className="course-image-placeholder">
                    
                      <AmplifyS3Image
                      className="img"
                        level="protected"
                        identityId={coursesVal.details.course.featuredimage.split("/")[0]}
                        style={{
                          "--height": "190px",
                          "--width": "100%",
                          "--alt": "image",
                          objectFit:'cover'
                        }}
                        path={coursesVal.details.course.featuredimage.split("/")[1]}
                        onClick={() => handleCourseClick(coursesVal.details)}
                        
                      />
                    </div>
                    <div style={{padding:'10px 0'}}>
                      <div
                        className="coaching-title-link titleText"
                        onClick={() => {
                          TrackClickFunction('demand-gen-coaching-title-page-home',{coachingTitle: coursesVal?.details?.course?.name});
                          handleCourseClick(coursesVal.details)
                        }}
                        style={{height:'46px'}}

                      >
                        {coursesVal.details.course.name}
                      </div>
                      <div
                        title={coursesVal.details.course.short_description}
                        style={{ paddingTop: 8,height: 52, fontSize:'16px' }}
                        className="coaching-desc card-description-dashboard-blog bodyText"
                      >
                        {coursesVal.details.course.short_description}
                      </div>
                  </div>
                </div>        
              </Col>
            );
          })
        ) : (
          <div className="center">No Data Found </div>
        )}
      </Row>
}
    </div>

    </>

  );
};

export default PopularCoaching;
