/* eslint-disable react-hooks/exhaustive-deps */
import { Line } from "@ant-design/charts";
import {
  Col,
  Divider,
  Row,
  Select,
  Skeleton,
  Switch,
  Table,
  Tooltip,
  Typography,
  message,
  Dropdown,
  Button,
  Menu
} from "antd";
import { API } from "aws-amplify";
import FunnelCharts from "commons/FunnelChart";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import verticalExpandImg from "assets/Images/vertical_expand.svg";
import verticalCollapseImg from "assets/Images/vertical_collapse.svg";
import { isNumber } from "lodash";
import {
  numberWithCommas,
  numberWithCommasAndRemovedNoDecimals,
} from "utility/context/CommonMethods";
import { CaretDownOutlined, FilterOutlined } from "@ant-design/icons";
import settings from '../../../../assets/Images/Settings.svg'


function CampaignFunnelAnalysis(props) {
  const [isLoading, setisLoading] = useState(true);
  const [stageView, setStageView] = useState(true);
  const [opportunityTypeOptions, setOpportunityTypeOptions] = useState([]);
  const [opportunityTypes, setOpportunityTypes] = useState([]);
  const [opportunityTypesFetched, setOpportunityTypesFetched] = useState(false);
  console.log("opportunityTypes",opportunityTypes);
  const isStageView = (viewFlag) => {
    setStageView(!stageView);
  };
  const [selectedYear, setselectedYear] = useState(moment().year());
  const account = useSelector((state) => state.loginReducer.groups);

  const sortingOrder = (a, b) => {
    if (a?.order < b?.order) {
      return -1;
    }
    if (a?.order > b?.order) {
      return 1;
    }
    return 0;
  };

  const yearOptions = [
    {
      label: moment().year() - 2,
      value: moment().year() - 2,
    },
    {
      label: moment().year() - 1,
      value: moment().year() - 1,
    },
    {
      label: moment().year(),
      value: moment().year(),
    },
  ];

  const opportunityOptions = [];
  opportunityTypes?.map((item)=> {
    return opportunityOptions.push({
      label: item,
      value: item
    })
  })

  useEffect(() => {
    if (props?.CRM) {
      fetchGraphData();
    }
  }, [selectedYear, props?.CRM, opportunityTypeOptions]);

  const [graphData, setgraphData] = useState({});
  const fetchGraphData = async () => {
    setisLoading(true);
    try {
      let response = await API.get(
        "backendRest",
        `/mapping/get_campaign_funnel_analysis?year=${selectedYear}&account_id=${account[0]}&platform=${props?.CRM}&opp_types=${opportunityTypeOptions}`
      );

      let FunnelActualData = response?.data?.campaign_funnel_actual_data?.map(
        (item) => ({
          ...item,
          text: item?.stage,
          width: 70,
          percentageValue: 50,
        })
      );

      if (response?.data) {
        setgraphData({ ...response?.data, FunnelActualData });
        if (!opportunityTypesFetched) {
          setOpportunityTypes(response?.data?.opportunity_types);
          setOpportunityTypesFetched(true);
        }

      }
    } catch (error) {
      console.log(error);
      message.error("Something Went Wrong");
    } finally {
      setisLoading(false);
    }
  };

  const [campaignTableData, setcampaignTableData] = useState([]);
  const [roiTableData, setRoiTableData] = useState([]);
  const [campaignTableDatabackup, setcampaignTableDatabackup] = useState([]);
  const [campaignColumnData, setcampaignColumnData] = useState([]);
  const [roiColumnData, setroiColumnData] = useState([]);
  const [funnelChartData, setFunnelChartData] = useState([]);
  const [selectedChartFilter, setSelectedChartFilter] = useState("select_all");
  const [actualData, setActualData] = useState([]);
  const [recordData, setRecordDataData] = useState("");
  const [revenueActual, setRevenueActual] = useState(0);
  const [revenueActualRoi, setRevenueActualRoi] = useState(0);
  const [revenueActualRoiChild, setRevenueActualRoiChild] = useState(0);
  const [totalStagePipeLineValue, setTotalStagePipeLineValue] = useState(0);
  const [totalStagePipeLineRoiValue, setTotalStagePipeLineRoiValue] = useState(0);
  const [totalStagePipeLineRoiChildValue, setTotalStagePipeLineRoiChildValue] = useState(0);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const onStageChange = (value) => {
    setSelectedChartFilter(value);
    value === "select_all"
      ? setActualData(graphData?.campaign_funnel_funnel_trends)
      : setActualData(
          graphData?.campaign_funnel_funnel_trends
            ?.map((item) => ({ ...item, stage: item?.stage + " Actual" }))
            ?.filter((item) => item?.stage === `${value} Actual`)
        );
  };

  const LineChartConfig = {
    data: actualData,
    xField: "month",
    yField: "value",
    seriesField: "stage",
    xAxis: {
      title: {
        text: "Month",
        position: "bottom",
        style: {
          fontSize: 14,
          fontWeight: 600,
        },
      },
    },
    yAxis: {
      title: {
        text: "Actual Value",
        position: "bottom",
        style: {
          fontSize: 14,
          fontWeight: 600,
        },
      },
    },
  };

  let selectedTest = "";
  const handleClick = (record) => {
    setFunnelChartData(
      graphData?.FunnelActualData.map((item) => ({
        text: item.stage,
        value: record[item.stage],
        width: item?.width,
      }))
    );
    setActualData(graphData?.campaign_funnel_funnel_trends);
  };

  const onTableRowClick = (record) => {
    selectedTest = record?.name;
    handleClick(record);
    setRecordDataData(record);
    console.log("rrr",recordData);
  };

  const [isDeltaVisible, setisDeltaVisible] = useState(false);

  const generateColumnData = () => {
    let columnData = [
      {
        title: <span style={{ textAlign: "left", marginBottom: 0, color:"##1D2939", fontWeight:500 }}>Name</span>,
        dataIndex: "name",
        key: "name",
        width: "15%",
        fixed: "left",
        render: (name, record) => {
          return (
            <div>
              {record?.children || record.name === "Total" ? (
                <span className="channel-text" style={{color:"#7F56D9", fontWeight:500, fontSize:'15px'}}>{name}</span>
              ) : (
                <Typography.Text
                  className={`table-campaign-text ${
                    selectedTest && name === selectedTest
                      ? "campaign-selected"
                      : ""
                  }`}
                  style={{color:"#12B76A", fontWeight:500, fontSize:'14px'}}
                >
                  {name}
                </Typography.Text>
              )}
            </div>
          );
        },
      },
    ];

    let PotentialColumns = graphData?.stage_order_data?.sort(sortingOrder);
    PotentialColumns?.forEach((item, index) => {
      let key = item?.name;
      const lastKey = PotentialColumns[index - 1]?.name;
      columnData.push({
        title: (
          <Tooltip title={key}>
            <div className="header-cell">
              <span style={{ textAlign: "left", marginBottom: 0, color:"##1D2939", fontWeight:500 }}>{key}</span>
            </div>
          </Tooltip>
        ),
        dataIndex: key,
        key: key,
        width: "10%",
        render: (value, record, i) => {
          let deltaValue =
            lastKey && !isNaN(record[lastKey]) ? value - record[lastKey] : null;

          return (
            <div
              style={{ fontWeight: 500, display: "flex", alignItems: "center" }}
            >
              <Typography.Text style={{ marginRight: 5 }}>
                {isDeltaVisible && deltaValue ? (
                  <small style={{ color: "red" }}>
                    (
                    <CaretDownOutlined />
                    {deltaValue})
                  </small>
                ) : null}
              </Typography.Text>{" "}
              {value}
              &nbsp;
              <Typography.Text
                style={{ fontWeight: 500, color:'#12B76A' }}
                type={
                  showPipelineValue &&
                  (record?.["pipeline"]?.[key] >= 0 ||
                    record?.["actual_revenue"]?.[key] >= 0)
                    ? "secondary"
                    : ""
                }
              >
                {showPipelineValue && record?.["pipeline"]?.[key] >= 0
                  ? `($${numberWithCommas(record?.["pipeline"]?.[key])})`
                  : ""}
                {showPipelineValue && record?.["actual_revenue"]?.[key] >= 0
                  ? `($${numberWithCommas(record?.["actual_revenue"]?.[key])})`
                  : ""}
              </Typography.Text>{" "}
            </div>
          );
        },
      });
    });
    setcampaignColumnData(columnData);
  };

  const generateColumnDataRoi = () => {
    let columnDataRoi = [
      {
        title: <span style={{ textAlign: "left", marginBottom: 0, color:"##1D2939", fontWeight:500 }}>Name</span>,
        dataIndex: "name",
        key: "name",
        width: "15%",
        fixed: "left",
        render: (name, record) => {
          return (
            <div>
              {record?.children || record.name === "Total" ? (
                <span className="channel-text" style={{color:"#7F56D9", fontWeight:500, fontSize:'15px'}}>{name}</span>
              ) : (
                <Typography.Text
                  className={`table-campaign-text ${
                    selectedTest && name === selectedTest
                      ? "campaign-selected"
                      : ""
                  }`}
                  style={{color:"#12B76A", fontWeight:500, fontSize:'14px'}}
                >
                  {name}
                </Typography.Text>
              )}
            </div>
          );
        },

      },
      {
        title: <span style={{ textAlign: "left", marginBottom: 0, color:"##1D2939", fontWeight:500 }}>Budget Verified</span>,
        dataIndex: "budget_verified",
        key: "budgetVerified",
        width: "10%",
      },
      {
        title: <span style={{ textAlign: "left", marginBottom: 0, color:"##1D2939", fontWeight:500 }}>Revenue Actual</span>,
        dataIndex: "revenue_actual",
        key: "revenueActual",
        width: "10%",
      },
      {
        title: <span style={{ textAlign: "left", marginBottom: 0, color:"##1D2939", fontWeight:500 }}>ROI</span>,
        dataIndex: "roi",
        key: "roi",
        width: "10%",
      },
      {
        title: <span style={{ textAlign: "left", marginBottom: 0, color:"##1D2939", fontWeight:500 }}>Investment to Pipeline</span>,
        dataIndex: "investment_to_pipeline",
        key: "investmentToPipeline",
        width: "10%",
      },
    ];

    setroiColumnData(columnDataRoi);
  };

  const getTotalValues = (data) => {
    let tableDataTotal = {
      name: "Total",
      key: "Total",
      pipeline: {},
      actual_revenue: {},
    };

    data?.forEach((item) => {
      Object.keys(item)?.forEach((key) => {
        if (isNumber(item[key])) {
          tableDataTotal[key] = tableDataTotal[key] + item[key] || item[key];
        }
        if (isNumber(item?.["pipeline"]?.[key])) {
          tableDataTotal["pipeline"][key] =
            tableDataTotal["pipeline"][key] + item["pipeline"][key] ||
            item["pipeline"][key];
        }
        if (isNumber(item?.["actual_revenue"]?.[key])) {
          tableDataTotal["actual_revenue"][key] =
            tableDataTotal["actual_revenue"][key] +
              item["actual_revenue"][key] || item["actual_revenue"][key];
        }
      });
    });
    return tableDataTotal;
  };

  const [showZeroValue, setshowZeroValue] = useState(true);
  const [showPipelineValue, setshowPipelineValue] = useState(false);

  useEffect(() => {
    if (graphData?.campaign_funnel_analysis?.length > 0) {
      generateColumnData(graphData);
    }
  }, [showPipelineValue]);

  useEffect(() => {
    generateColumnData();
    generateColumnDataRoi();
  }, [isDeltaVisible]);

  useEffect(() => {
    // SetUp table columns Data
    if (graphData?.stage_order_data?.length > 0) {
      generateColumnData(graphData);
    }

    let tableData = graphData?.campaign_funnel_analysis;

    let tableDataTotal = getTotalValues(tableData);
    tableData?.push(tableDataTotal);
    console.log("tttttableData",tableData);
    setcampaignTableData(tableData);
    setcampaignTableDatabackup(tableData);

  }, [graphData]);

  useEffect(() => {
    if (graphData && graphData.campaign_funnel_analysis && graphData.campaign_funnel_analysis.length > 0) {
      let tableData = graphData.campaign_funnel_analysis;

      let modifiedTableData = tableData.map((item) => {
        let TotalStagePipelineRoi = 0;
        Object.keys(item).forEach((key, index) => {
          const actualRevenueValueRoi = item?.actual_revenue?.[key];
          TotalStagePipelineRoi = +recordData?.actual_revenue?.[key];
          if(actualRevenueValueRoi !== undefined){
            setRevenueActualRoi(() => actualRevenueValueRoi);

          }
          setTotalStagePipeLineRoiValue(() => TotalStagePipelineRoi);
        });

        const roiCal = item?.verified_budget ? numberWithCommasAndRemovedNoDecimals(revenueActualRoi / item?.verified_budget) || 0: 0;

        const investmentToPipelineCal = (totalStagePipeLineRoiValue && item?.verified_budget
          ? totalStagePipeLineRoiValue / item?.verified_budget
          : 0) + " X";

        let budgetVerifiedCal = item.verified_budget !== 0 ? `$${numberWithCommasAndRemovedNoDecimals(item.verified_budget)}` : `${numberWithCommasAndRemovedNoDecimals(item.verified_budget)}`;
        let revenueActualCal = (revenueActualRoi !== 0) ? `$${numberWithCommasAndRemovedNoDecimals(revenueActualRoi)}` : numberWithCommasAndRemovedNoDecimals(revenueActualRoi);


        return {
        ...item,
        // Assuming these values need to be calculated from existing data

        budget_verified: budgetVerifiedCal,
        revenue_actual: revenueActualCal,
        roi: roiCal,
        investment_to_pipeline: investmentToPipelineCal,
        children: item.children?.map((childItem) => {
          let TotalStagePipelineRoiChild = 0;
          Object.keys(childItem).forEach((key, index) => {
            const actualRevenueValueRoiChild = childItem?.actual_revenue?.[key];
            TotalStagePipelineRoiChild = +childItem?.actual_revenue?.[key];
            if(actualRevenueValueRoiChild !== undefined){
              setRevenueActualRoiChild(() => actualRevenueValueRoiChild);
            }
            setTotalStagePipeLineRoiChildValue(() => TotalStagePipelineRoiChild);
          });
          const roiCalChild = childItem?.verified_budget ? numberWithCommasAndRemovedNoDecimals(revenueActualRoiChild / childItem?.verified_budget) || 0: 0;
          const investmentToPipelineCalChild = (totalStagePipeLineRoiChildValue && childItem?.verified_budget
            ? totalStagePipeLineRoiChildValue / childItem?.verified_budget
            : 0) + " X";
            let budgetVerifiedCalChild = childItem.verified_budget !== 0 ? `$${numberWithCommasAndRemovedNoDecimals(childItem.verified_budget)}` : `${numberWithCommasAndRemovedNoDecimals(childItem.verified_budget)}`;
            let revenueActualCalChild = (revenueActualRoiChild !== 0) ? `$${numberWithCommasAndRemovedNoDecimals(revenueActualRoiChild)}` : numberWithCommasAndRemovedNoDecimals(revenueActualRoiChild);

          return {
          ...childItem,
          budget_verified: budgetVerifiedCalChild,
          revenue_actual: revenueActualCalChild,
          roi: roiCalChild,
          investment_to_pipeline:investmentToPipelineCalChild,
        }}),
      }
      });

      setRoiTableData(modifiedTableData);
    }
  }, [graphData]);


  useEffect(() => {
    if(stageView){
    if (!showZeroValue) {
      let updatedValue = campaignTableDatabackup
        .filter((item) => item?.Leads !== 0)
        .map((item) => {
          let updatedChildren = [];
          if (item.children && Array.isArray(item.children)) {
            updatedChildren = item.children.filter((child) => child?.Leads !== 0);
          }
          return {
            ...item,
            children: updatedChildren,
          };
        });

      setcampaignTableData(updatedValue);
    } else {

      setcampaignTableData(campaignTableDatabackup);

    }
  }
  }, [showZeroValue, campaignTableDatabackup]);

  useEffect(() => {
    let TotalStagePipeline = 0;
    Object.keys(recordData).forEach((key, index) => {
      const actualRevenueValue = recordData?.actual_revenue?.[key];
      TotalStagePipeline = +recordData?.actual_revenue?.[key];
      if (actualRevenueValue !== undefined) {
        setRevenueActual(actualRevenueValue);
      }
    });
    setTotalStagePipeLineValue(TotalStagePipeline);
  }, [recordData]);

  const handleDropdownVisibleChange = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleMenuClick = (e) => {
    if (e.key === 'close') {
      setDropdownVisible(false);
    }
  };
  const onOpportunityTypeOptionsChange = (value) => {
    setOpportunityTypeOptions(() => value);
  }


  const filterItems = (
    <Menu onClick={handleMenuClick} className="filter__menu">
      <Menu.Item key="settings">

          <div className="filter__dropdown">
            <div style={{display:"flex",alignItems:"center", justifyContent:"space-between"}}>
              <span style={{marginRight:20}}>Show 0 Values </span><Switch checked={showZeroValue} onChange={setshowZeroValue} size="small" disabled={!stageView} />
            </div>
            <Divider style={{margin:'10px 0'}}/>
            <div style={{display:"flex",alignItems:"center", justifyContent:"space-between"}}>
              <Tooltip
                title="On enable this toggle the columns will show the selected pipeline values (Under Sales and Marketing) and Actual Revenue for the Demand Gen Default Stage Closed Won"
                placement="left"
              >
                <span style={{marginRight:20}}>Show Pipeline & Revenue Values</span> <Switch checked={showPipelineValue} onChange={setshowPipelineValue} size="small" disabled={!stageView}/>
              </Tooltip>
            </div>
            <Divider style={{margin:'10px 0'}}/>
            <div style={{display:"flex",alignItems:"center", justifyContent:"space-between"}}>
              <span style={{marginRight:20}}>Show Delta</span> <Switch checked={isDeltaVisible} onChange={setisDeltaVisible} size="small" disabled={!stageView}/>
            </div>
          </div>
      </Menu.Item>

    </Menu>
  )


  return (
    <div className="reporting-card-body panel-design" style={{marginTop:'20px'}}>
      <Row gutter={[10, 10]} justify="space-between">
        <Col>
          <div style={{display:'flex', alignItems:"center"}}>
                <div className="font-color-budget  custom-tab">
                  <span
                    onClick={() => isStageView(true)}
                    className={`budget ${
                      stageView
                        ? "custom-selected-tab-selected"
                        : "custom-selected-tab"
                    }`}
                  >
                    Stages
                  </span>
                  <span
                    onClick={() => isStageView(false)}
                    style={{ marginLeft: 12 }}
                    className={`budget ${
                      stageView
                        ? "custom-selected-tab"
                        : "custom-selected-tab-selected"
                    }`}
                  >
                    ROI
                  </span>
                </div>
            </div>
        </Col>
        <Col display="flex" alignItems="center">
          <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center", gap: 10, marginRight:"15px" }}>
              <Typography.Text className="selection-header-title" style={{margin:0, color:"#1D2939", fontWeight:'500'}}>
                  Opportunity Types
              </Typography.Text>
              <Select
                mode="multiple"

                style={{ width: 220 }}
                onChange={(value) =>onOpportunityTypeOptionsChange(value)}
                options={opportunityOptions}
                disabled={isLoading}
                className="general-input-select"
              ></Select>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <Typography.Text className="selection-header-title" style={{margin:0, color:"#1D2939", fontWeight:'500'}}>
                  Year
              </Typography.Text>
              <Select
                placeholder="Please Select Data Source"
                style={{ width: 172 }}
                value={selectedYear}
                onChange={setselectedYear}
                options={yearOptions}
                disabled={isLoading}
                className="general-input-select"
              ></Select>
            </div>
            <Dropdown overlay={filterItems} trigger={['click']}
              placement="bottomLeft"
              visible={dropdownVisible}

              >
              <Button onClick={handleDropdownVisibleChange} style={{border:'none'}}>
                <img src={settings} alt="Filters" />
              </Button>
            </Dropdown>
          </div>
        </Col>


      </Row>

      {isLoading ? (
        <Skeleton paragraph={{ rows: 6 }} active />
      ) : (
        <Row style={{ marginTop: 20, width: "100%" }}>

          <Col span={24}>
          {stageView ?
            <Table
              dataSource={campaignTableData}
              columns={campaignColumnData}
              className="campaign-table non-print override-fixed-column-style custom-table general-table sales-table"
              style={{ width: "100%" }}
              size="small"
              pagination={false}
              scroll={{
                x: 1000,
              }}
              rowClassName={(record, index) =>
                record.name === "Total" ? "total-spend-text" : ""
              }
              onRow={(record) => ({
                onClick: () => onTableRowClick(record),
                style: recordData?.name &&
                  record.name === recordData?.name && {
                    backgroundColor: "#f0f0f0",
                    fontWeight: 600,
                  }, // add custom row color based on record.isSelected property
              })}
              bordered
              expandable={{
                expandIcon: (props) => {
                  const style = {
                    marginTop: "2.5005px",
                    marginRight: "8px",
                    cursor: "pointer",
                    float: "right",
                    paddingTop: "6px",
                    display: "block",
                  };
                  if (!props?.record?.children) {
                    style.display = "none";
                  }
                  return props.expanded ? (
                    <span>
                      <img
                        style={style}
                        src={verticalCollapseImg}
                        onClick={(e) => props.onExpand(props.record, e)}
                        alt=""
                      />
                    </span>
                  ) : (
                    <span>
                      <img
                        style={style}
                        src={verticalExpandImg}
                        onClick={(e) => props.onExpand(props.record, e)}
                        alt=""
                      />
                    </span>
                  );
                },
              }}
            /> : <Table
            dataSource={roiTableData}
            columns={roiColumnData}
            className="campaign-table non-print override-fixed-column-style custom-table general-table sales-table"
            style={{ width: "100%" }}
            size="small"
            bordered
            pagination={false}
            scroll={{
              x: 1000,
            }}
            rowClassName={(record, index) =>
              record.name === "Total" ? "total-spend-text" : ""
            }
            onRow={(record) => ({
              onClick: () => onTableRowClick(record),
              style: recordData?.name &&
                record.name === recordData?.name && {
                  backgroundColor: "#f0f0f0",
                  fontWeight: 600,
                }, // add custom row color based on record.isSelected property
            })}
            expandable={{
              expandIcon: (props) => {
                const style = {
                  marginTop: "2.5005px",
                  marginRight: "8px",
                  cursor: "pointer",
                  float: "right",
                  paddingTop: "6px",
                  display: "block",
                };
                if (!props?.record?.children) {
                  style.display = "none";
                }
                return props.expanded ? (
                  <span>
                    <img
                      style={style}
                      src={verticalCollapseImg}
                      onClick={(e) => props.onExpand(props.record, e)}
                      alt=""
                    />
                  </span>
                ) : (
                  <span>
                    <img
                      style={style}
                      src={verticalExpandImg}
                      onClick={(e) => props.onExpand(props.record, e)}
                      alt=""
                    />
                  </span>
                );
              },
            }}
          />
          }
          </Col>


        </Row>
      )}

      <Divider />

      {funnelChartData && funnelChartData.length > 0  && (

        <div>
          {stageView &&
          <>
            <Row className="analysis-chart-wrapper" style={{ border: "none", paddingLeft:0 }}>
              {" "}
              <Typography.Text className="label-title-text" style={{color:'#1D2939', fontWeight:500}}>
                {!recordData?.children
                  ? recordData?.name === "Total"
                    ? ""
                    : "Campaign : "
                  : "Channel : "}
                <span style={{color:"#7F56D9"}}>{recordData?.name}</span>
              </Typography.Text>
            </Row>

            <Row justify="space-between" gutter={[20, 20]} style={{marginBottom:'20px'}}>
              <Col span={6} xs={24} sm={12} md={6} lg={6} xl={6}>
                <div
                  className="executive-analysis panel-design"
                  style={{ marginBottom: "20px", alignItems:'center', justifyContent:'center' }}
                >
                  <Typography.Text className="analysis-title" style={{color:"#1D2939", fontWeight:500, fontSize:'18px', textAlign:'center', marginBottom:'5px'}}>
                    Budget Verified
                  </Typography.Text>
                  <Typography.Text className="roi-Indicator" style={{color:"#7F56D9", fontWeight:500, textAlign:'center'}}>
                    $
                    {recordData?.verified_budget
                      ? numberWithCommasAndRemovedNoDecimals(
                          recordData?.verified_budget || 0
                        )
                      : 0}
                  </Typography.Text>
                </div>
              </Col>
              <Col span={6} xs={24} sm={12} md={6} lg={6} xl={6}>
                <div
                  className="executive-analysis panel-design"
                  style={{ marginBottom: "20px", alignItems:'center', justifyContent:'center' }}
                >
                  <Typography.Text className="analysis-title" style={{color:"#1D2939", fontWeight:500, fontSize:'18px', textAlign:'center', marginBottom:'5px'}}>
                    Revenue Actual
                  </Typography.Text>
                  <Typography.Text className="roi-Indicator" style={{color:"#7F56D9", fontWeight:500, textAlign:'center'}}>
                    $
                    {revenueActual
                      ? numberWithCommasAndRemovedNoDecimals(revenueActual)
                      : 0}
                  </Typography.Text>
                </div>
              </Col>
              <Col span={6} xs={24} sm={12} md={6} lg={6} xl={6}>
                <div
                  className="executive-analysis panel-design"
                  style={{ marginBottom: "20px", alignItems:'center', justifyContent:'center' }}
                >
                  <Typography.Text className="analysis-title" style={{color:"#1D2939", fontWeight:500, fontSize:'18px', textAlign:'center', marginBottom:'5px'}}>
                    ROI
                  </Typography.Text>

                  <Typography.Text
                    className={
                      recordData?.verified_budget
                        ? revenueActual / recordData.verified_budget > 1
                          ? "roi-positive-Indicator"
                          : "roi-Indicator"
                        : "roi-Indicator"
                    }
                    style={{color:"#7F56D9", fontWeight:500, textAlign:'center'}}
                  >
                    {recordData?.verified_budget
                      ? revenueActual / recordData?.verified_budget || 0
                      : 0}
                  </Typography.Text>
                </div>
              </Col>
              <Col span={6} xs={24} sm={12} md={6} lg={6} xl={6}>
                <div
                  className="executive-analysis panel-design"
                  style={{ marginBottom: "20px", alignItems:'center', justifyContent:'center' }}
                >
                  <Typography.Text className="analysis-title" style={{color:"#1D2939", fontWeight:500, fontSize:'18px', textAlign:'center', marginBottom:'5px'}}>
                    Investment to Pipeline
                  </Typography.Text>

                  <Typography.Text className="roi-Indicator" style={{color:"#7F56D9", fontWeight:500, textAlign:'center'}}>
                    {totalStagePipeLineValue && recordData?.verified_budget
                      ? totalStagePipeLineValue / recordData?.verified_budget
                      : 0}
                    X
                  </Typography.Text>
                </div>
              </Col>
            </Row>
          </>
          }
          <Row gutter={[20, 20]}>
            {isLoading ? (
              <>
                <Col span={12}>
                  <Skeleton.Button
                    active
                    style={{ width: "36vw", height: 300 }}
                  />
                </Col>
                <Col span={12}>
                  <Skeleton.Button
                    active
                    style={{ width: "36vw", height: 300 }}
                  />
                </Col>
              </>
            ) : (
              <>
                <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="analysis-chart-wrapper panel-design">
                    <Typography.Text className="label-title-text" style={{color:"#1D2939", fontWeight:'500'}}>
                      Sum of Actual Value by stage
                    </Typography.Text>
                    {funnelChartData.length > 0 && (
                      <FunnelCharts
                        data={funnelChartData}
                        color={[
                          // "#8becf9",
                          // "#75ECF9",
                          // "#4FE8FD",
                          // "#4DE1FD",
                          // "#0EE6FF",
                          // "#28DFF9",
                          // "#12D5EF",
                          // "#00C3DD",
                          // "#00B9D1",

                          // "#D6BBFB",
                          "#B692F6",
                      "#9E77ED",
                      "#7F56D9",
                      "#6941C6",
                      "#53389E",
                        ]}
                        // conversionTag= {true}
                        labelColor="#fff"
                        height={funnelChartData.length * 100}
                        transform={false}
                        isActual={true}
                      />
                    )}
                  </div>
                </Col>
                <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="analysis-chart-wrapper panel-design" style={{height:'100%'}}>
                    <div className="year-selector">
                      <div style={{borderBottom:'1px solid #D0D5DD', marginBottom:'20px'}}>
                        <Typography.Text
                          style={{
                            fontSize: "16px",
                            marginLeft: "5px",
                            fontWeight: 600,
                            color:"#1D2939",
                            marginRight:'15px'
                          }}
                        >
                          Stage Selector
                        </Typography.Text>
                      
                      <Select
                        value={selectedChartFilter}
                        disabled={isLoading}
                        placeholder={
                          <Typography.Text
                            style={{
                              fontSize: "16px",
                              fontWeight: 200,
                              marginBottom: "5px",
                            }}
                          >
                            Stage Selector
                          </Typography.Text>
                        }
                        style={{ width: 172, padding: "10px 0px" }}
                        onChange={onStageChange}
                      >
                        <Select.Option value="select_all">
                          Select All
                        </Select.Option>
                        {funnelChartData?.map((item) => (
                          <Select.Option value={item?.text}>
                            {item?.text}
                          </Select.Option>
                        ))}
                      </Select>
                      </div>
                    </div>
                    <Typography.Text className="label-title-text" style={{color:"#1D2939", fontWeight:500}}>
                      Sum of Actual Value by Month and Stage
                    </Typography.Text>
                    <div style={{ marginTop: "20px" }}>
                      <Line {...LineChartConfig} />
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </div>

      )}
    </div>
  );
}

export default CampaignFunnelAnalysis;
