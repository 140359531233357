import { Col, Row, Typography } from 'antd'
import React from 'react'
import { reportingHeadings } from 'utility/Constants'
import ReportingImage from '../../assets/Images/dashboardImage.svg'

function ReportingHeader(props) {
  return (
      
      <Row
        className="aboutPage panel-design"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 8,
          background: "#fff",
          marginBottom: "20px",
          width: "100%",
          borderRadius: 7,
          alignContent: "center",
          padding:'30px 20px',
          marginTop:'20px'
        }}
      >
        <Col
          className="details"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
          span={24}
          lg={24}
          md={24}
          xs={24}
          sm={24}
        >
          <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <div>
              <Typography.Text className="title fw700" style={{ fontSize: 32, color:'#1D2939', marginBottom:'15px', display:'block' }}>
                {reportingHeadings?.[props?.type]?.heading}
              </Typography.Text>
              <Typography.Text style={{ fontSize: 16, color:'#475467', lineHeight:'26px' }}>
              {reportingHeadings?.[props?.type]?.subHeading}
              </Typography.Text> 
          </div>
          </div>
        </Col>

        {/* <Col span={9} lg={9} md={9} xs={24} sm={24}>
          <Row justify="end" style={{margin: "15px 0px 10px"}}>
            <img src={ReportingImage} alt="" className="w-100" />
          </Row>
        </Col> */}
      </Row>
  )
}

export default ReportingHeader


