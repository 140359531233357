import { Table, Select, Space, Input, Button, Form, Skeleton } from "antd";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { listPropertyPackageData, setLoader } from "store/actions/reportAction";
import { API } from "aws-amplify";
import AssignPackageAccountUser from "./AssignPackageAccountUser";
import sdk from "sdk/Accounts";
import { Loading3QuartersOutlined } from "@ant-design/icons";


const AssignPackage = (props) => {
  const [SearchParams, setSearchParams] = useState({
    Name: "organization",
    Value: "",
  });
  const [Companies, setCompanies] = useState([]);
  const [visible, setVisible] = useState(false);
  const [compniesBackup, setcompniesBackup] = useState([]);
  const { Option } = Select;
  const [Users, setUsers] = useState([]);
  const [userbackup, setuserbackup] = useState({});

  const dispatch = useDispatch();

  const { packagePropertyList } = useSelector(
    (state) => state.reportReducer
  );
  const userData = useSelector((state) => state.loginReducer.userData);

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    dispatch(listPropertyPackageData());
  }, [dispatch]);

  const [selectedAssignType, setSelectedAssignType] = useState("organization");
  const onSelectAssignType = (selected) => {
    setSelectedAssignType(selected);
  };

  const handle_search = (value) => {
    if (value["assign_type"] === "user") setUsers(userbackup);
    if (value["assign_type"] === "organization") setCompanies(compniesBackup);

    setSearchParams({
      Name: value["assign_type"],
      Value: value["search_value"] ? value["search_value"].toLowerCase() : "",
    });
  };

  useEffect(() => {
    if (SearchParams?.Name === "user") {
      searchUsers(SearchParams);
    } else if (SearchParams?.Name === "organization") {
      searchOrganization(SearchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchParams]);

  const searchOrganization = (SearchParams) => {
    // setCompanies(
    //   Companies?.filter((val) =>
    //     String(val?.company)
    //       ?.toLowerCase()
    //       ?.includes(SearchParams?.Value?.toLowerCase()?.trim())
    //   )
    // );
    searchCompany(SearchParams)
  };

  const searchCompany = async (SearchParams) => {
    await setTableLoading(true)
    let tempNextToken = null
  if (Object.keys(SearchParams).length > 0) {
    if (String(SearchParams.Value).length === 0) {
      setCompanies(compniesBackup)
      setTableLoading(false)
    }
    else {
      let filter = {}
    
        filter['company_loweredcased'] = { contains: String(SearchParams.Value).toLowerCase() }
     

      setCompanies([])
      do {
        let companyList = await sdk.fetchCompanies(tempNextToken, filter)
          setCompanies(olddata => olddata.concat(companyList?.data?.listAccounts?.items))
      tempNextToken = companyList?.data?.listAccounts?.nextToken;
        if (!tempNextToken) {
          await setTableLoading(false)
      }
        
      }while(tempNextToken)
    }
    
  }
  
}


  const [TableLoading, setTableLoading] = useState(false);
  const [nextToken, setnextToken] = useState(null)
  const [getCompanyDataLoader, setgetCompanyDataLoader] = useState(true)


  const getCompanyData = async () => { 
    Companies?.length > 0 && setTableLoading(true)

    try {
      if (!(Companies?.length > 0 && !nextToken)) {
        let response = sdk.fetchCompanies(nextToken)
        response.then((companyList) => {
          setCompanies(olddata => olddata.concat(companyList?.data?.listAccounts?.items))
          setcompniesBackup(olddata => olddata.concat(companyList?.data?.listAccounts?.items))
          setnextToken(companyList?.data?.listAccounts?.nextToken)
          setTableLoading(false)
          setgetCompanyDataLoader(false)
          return companyList.data;


        }).catch((e) => {
          console.log(e);
          setTableLoading(false)
          setgetCompanyDataLoader(false)
          return { errors: ["Failed retrieving account"] };
        })
      }
      else {
        setTableLoading(false)
        setgetCompanyDataLoader(false)
      }
    } catch (e) {
      console.log(e);
      setgetCompanyDataLoader(false)
      return { errors: ["Failed retrieving account"] };
    }
  }

  const [getUsersDataLoader, setgetUsersDataLoader] = useState(false)
  // const getCompanyData = async () => {
  //   dispatch(setLoader(true));
  //   setgetUsersDataLoader(true);
  //   try {
      
      
  //     let companyList = await API.graphql(graphqlOperation(listAccounts));



  //     setCompanies(companyList.data.listAccounts.items);
  //     setcompniesBackup(companyList.data.listAccounts.items);
  //     if (SearchParams?.Value) searchOrganization(SearchParams);
  //     dispatch(setLoader(false));
  //     setgetUsersDataLoader(false);
  //     return companyList.data;
  //   } catch (e) {
  //     console.log(e);
  //     dispatch(setLoader(false));
  //     setgetUsersDataLoader(false);
  //     return { errors: ["Failed retrieving account"] };
  //   }
  // };

  function itemRender(current, type, originalElement) {
    if (type === 'prev') {
      return  <Button className="nextbtn">{"<"}</Button>;
    }
    if (type === 'next') {
      return  <Button disabled={Companies?.length > 0 && !nextToken} className="nextbtn">{">"}</Button>;
    }
    return originalElement;
  }
  
  const paginationChanged = (currentPage) => {
    if (parseInt((Companies.length / 10).toFixed(0)) === currentPage) {
      if (nextToken) {
        getCompanyData()
      }
    }
  }

  const [suspendedUsers, setsuspendedUsers] = useState([]);
  const getUserData = () => {
    const user_path = "/admin/groupswithusers";
    dispatch(setLoader(true));
    setgetUsersDataLoader(true);

    try {
      API.get("exploricsREST", user_path)
        .then((result) => {
          setsuspendedUsers([]);
          let usersdata = [];
          // eslint-disable-next-line array-callback-return
          result.map((item) => {
            // eslint-disable-next-line array-callback-return
            item?.Users?.map((user) => {
              let data = user;
              delete item?.Users;
              data["Company_Data"] = item;
              item.GroupName === "suspend" &&
                suspendedUsers.push(get_attribute(user?.Attributes, "email"));
              if (
                item.Description &&
                get_attribute(data.Attributes, "email") !== userData?.email
              )
                usersdata.push(data);
            });
          });
          // eslint-disable-next-line array-callback-return
          usersdata.map((data) => {
            suspendedUsers.includes(get_attribute(data?.Attributes, "email"))
              ? (data["suspend"] = true)
              : (data["suspend"] = false);
          });

          setUsers(usersdata);
          setuserbackup(usersdata);
      setgetUsersDataLoader(false);
          if (SearchParams?.Value) searchUsers(SearchParams);
          dispatch(setLoader(false));
        })
        .catch((e) => {
          console.log("Error :", e);
      setgetUsersDataLoader(false);
      dispatch(setLoader(false));
        });
    } catch (e) {
      setgetUsersDataLoader(false);
      console.log("Error :", e);
      dispatch(setLoader(false));
    }
  };

  const get_attribute = (user, key) => {
    let result = user?.filter((attribute) => attribute.Name === key);
    if (result?.length > 0) {
      return result?.[0]["Value"];
    } else {
      return "";
    }
  };

  const searchUsers = (SearchParams) => {
    setUsers(
      Users?.filter((val) => {
        return (
          String(get_attribute(val.Attributes, "given_name"))
            ?.toLowerCase()
            ?.includes(SearchParams?.Value?.toLowerCase()?.trim()) ||
          String(get_attribute(val.Attributes, "family_name"))
            ?.toLowerCase()
            ?.includes(SearchParams?.Value?.toLowerCase()?.trim()) ||
          String(get_attribute(val.Attributes, "email"))
            ?.toLowerCase()
            ?.includes(SearchParams?.Value?.toLowerCase()?.trim())
        );
      })
    );
  };

  useEffect(() => {
    if (selectedAssignType === "user") getUserData();
    if (selectedAssignType === "organization") getCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssignType]);

  const [selectedAssignee, setSelectedAssignee] = useState();
  const [selectedAssigneeType, setSelectedAssigneeType] = useState();
  const onAssign = (assignee, type) => {
    setVisible(true);
    setSelectedAssignee(assignee);
    setSelectedAssigneeType(type);
  };

  const columnsCompany = [
    {
      title: "Organization Id",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (id, record) => {
        return (
          <div style={{ display: "flex" }}>
            <div style={{ width: "80%", textAlign: "left" }}>{record?.id}</div>
          </div>
        );
      },
      width:"50%"
    },
    {
      title: "Organization Name",
      dataIndex: "company",
      key: "company",
      align: "center",
      render: (name, record) => {
        return (
          <div style={{ display: "flex" }}>
            <div style={{ width: "80%", textAlign: "left" }}>{record.company}</div>
            <div
              style={{ width: "20%" }}
              className="assign-text"
              onClick={() => onAssign(record, "company")}
            >
              Assign
            </div>
          </div>
        );
      },
      sorter: (a, b) => String(a.company).localeCompare(b.company),
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
  ];

  const columnsUser = [
    {
      title: "Name",
      width:"30%",
      dataIndex: "Attributes",
      key: "name",
      align: "center",
      render: (name, record) => {
        return (
          <div style={{ display: "flex" }}>
            <small>
              {get_attribute(name, "given_name") +
                " " +
                get_attribute(name, "family_name")}
            </small>
          </div>
        );
      },
      sorter: (a, b) => {
        var namea = a.Attributes[2].Value + " " + a.Attributes[3].Value;
        var nameb = b.Attributes[2].Value + " " + b.Attributes[3].Value;
        return namea?.localeCompare(nameb);
      },
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "Email",
      width:"70%",
      dataIndex: "Attributes",
      key: "email",
      align: "center",
      render: (attr, record) => {
        return (
          <div style={{ display: "flex" }}>
            <div style={{ width: "80%", textAlign: "left" }}>
              {get_attribute(attr, "email")}
            </div>
            <div
              style={{ width: "20%" }}
              className="assign-text"
              onClick={() => onAssign(record, "user")}
            >
              Assign
            </div>
          </div>
        );
      },
      sorter: (a, b) => {
        var emaila = a.Attributes[4].Value;
        var emailb = b.Attributes[4].Value;
        return String(emaila)?.localeCompare(emailb);
      },
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
  ];

  return (
    <>
      <div className="user-container packageListBox" style={{marginTop:'20px'}}>
        {/* {isLoading && <div className="loading">Loading;</div>} */}
        <Space direction="vertical" style={{ width: "100%" }} size={1}>
          {/* <Typography.Title level={5}>Package Management</Typography.Title> */}

          <Form
            name="search_users"
            onFinish={handle_search}
            initialValues={{ assign_type: "organization" }}
          >
            <Space
            className="plr-0"
              direction="horizontal"
              style={{
                padding: "0px 5px",
                paddingLeft: "20%",
                width: "100%",
                paddingRight: "20%",
                justifyContent:'center'
              }}
            >
              <Form.Item name="assign_type">
                <Select
                  style={{ width: "250px" }}
                  size="large"
                  onChange={(e) => onSelectAssignType(e)}
                  defaultValue="organization"
                  value="organization"
                  className="general-input-select"
                >
                  <Option key="organization">Organization</Option>
                  <Option key="user">User</Option>
                </Select>
              </Form.Item>
              <Form.Item name="search_value" style={{ width: "100%" }}>
                <Input placeholder="Search" size="large" className="general-input"/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" size="middle" className="primaryButton" style={{width:'150px'}}>
                  Search
                </Button>
              </Form.Item>
              {TableLoading && <Loading3QuartersOutlined spin style={{fontSize:22,marginRight:20, marginBottom:20, marginLeft:10}}/>}
            </Space>
          </Form>

         

          {selectedAssignType === "organization" && (
            <div className="table-wrapper">
              {getCompanyDataLoader ? <Skeleton active style={{ paddingLeft: "20%", paddingRight: "20%" }} paragraph={{rows:5}} /> : <>
              <Table
                bordered
                size="middle"
                dataSource={Companies}
                columns={columnsCompany}
                className="plr-0 general-table assign-package-table"
                style={{ paddingLeft: "10%", paddingRight: "10%" }}
                 // eslint-disable-next-line no-func-assign
              pagination={{ itemRender, onChange: paginationChanged }}
              ></Table>
              </>}
            </div>
          )}

          {selectedAssignType === "user" && (
            <div className="table-wrapper">
              {getUsersDataLoader ? <Skeleton active style={{ paddingLeft: "20%", paddingRight: "20%" }} paragraph={{rows:5}} /> : 
              <Table
                bordered
                size="middle"
                dataSource={Users}
                columns={columnsUser}
                className="plr-0"
                style={{ paddingLeft: "20%", paddingRight: "20%" }}
              ></Table>
          }
            </div>
          )}
        </Space>
        <AssignPackageAccountUser
          visible={visible}
          handleCancel={handleCancel}
          selectedAssignee={selectedAssignee}
          selectedAssigneeType={selectedAssigneeType}
          packagePropertyList={packagePropertyList}
        />
      </div>
    </>
  );
};

export default AssignPackage;
