/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Descriptions,
  Empty,
  Popover,
  Row,
  Select,
  Skeleton,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import ColumnLineChart from "../../../../commons/Charts/ColumnLineChart";
import { API } from "aws-amplify";
import moment from "moment";
import { useSelector } from "react-redux";
import { numberWithCommasAndRemovedNoDecimals } from "utility/context/CommonMethods";

const yearOptions = [
  {
    label: moment().year() - 2,
    value: moment().year() - 2,
  },
  {
    label: moment().year() - 1,
    value: moment().year() - 1,
  },
  {
    label: moment().year(),
    value: moment().year(),
  },
];

const quarterOptions = [
  {
    label: "Q1",
    value: "Q1"
  },
  {
    label: "Q2",
    value: "Q2"
  },
  {
    label: "Q3",
    value: "Q3"
  },
  {
    label: "Q4",
    value: "Q4"
  }
];

let pipelineChartYAxis = ["Pipeline Amount", "Deals"];
let pipelineChartXAxis = "Deal Closed";

let closedWonChartYAxis = ["Closed Amount", "Deals"];
let closedWonChartXAxis = "Contact Created";

const DealAnalysis = (props) => {
  const account = useSelector((state) => state.loginReducer.groups);

  const [skeletonLoader, setskeletonLoader] = useState(false);

  const [ChartData, setChartData] = useState({});

  const[contactQuarters, setcontactQuarters] = useState(["Q1","Q2","Q3","Q4"]);
  const[closedQuarters, setclosedQuarters] = useState(["Q1","Q2","Q3","Q4"]);

  const getDashboardData = async (deal_closed_year, contact_created_year) => {
    try {
      setskeletonLoader(true);
      let dashboardData = await API.get(
        "backendRest",
        `/mapping/get_deal_analysis?account_id=${account[0]}&deal_closed_year=${deal_closed_year}&contact_created_year=${contact_created_year}&platform=${props?.CRM}&deal_closed_quarters=${closedQuarters}&contact_created_quarters=${contactQuarters}`
      );
      
      // const filteredChartData = dashboardData?.data?.pipeline_data?.filter((item)=> {
      //   return contactQuarters.some((contactQuarter) => item['Deal Closed'].includes(contactQuarter));
      // })

      // const filteredClosedChartData = dashboardData?.data?.deal_data?.filter((item)=> {
      //   return closedQuarters.some((closedQuarter) => item['Contact Created'].includes(closedQuarter));
      // })
      console.log("dashData1",dashboardData?.data)
      // let filterData = {
      //   ...dashboardData?.data,
      //   deal_data: [
      //     {
      //       "Closed Amount": 235000,
      //       "Contact Created": "Q1",
      //       "Deals": 2
      //     }
      //   ],
      //   pipeline_data: [
      //     {
      //       "Closed Amount": 235000,
      //       "Deal Closed": "Q1",
      //       "Deals": 22
      //     }
      //   ]
      // }
      // console.log("filterData",filterData);
      setChartData(dashboardData?.data);
   } catch (error) {
      console.log(error);
    } finally {
      setskeletonLoader(false);
    }
  };

  const [yearFilter, setyearFilter] = useState({
    pipeline: moment().get("year"),
    closed_won: moment().get("year"),
  });

  useEffect(() => {
    getDashboardData(yearFilter["closed_won"], yearFilter["pipeline"]);
  }, [yearFilter, props?.CRM, contactQuarters, closedQuarters]);

  const OnYearChange = (key, value) => {
    setyearFilter((old) => ({ ...old, [key]: value }));
  };

  const onContactQuaterChange = (value) => {
    
    setcontactQuarters(() => value);
  }
  const onClosedQuaterChange = (value) => {
    
    setclosedQuarters(() => value);
  }

  

  return (
    <>
      {/* Pipeline Chart */}
      <div className="reporting-card-body" style={{marginTop:'20px'}}>
        <Row justify="space-between" gutter={[18, 18]}>
          <Col span={5} xs={24} sm={12} md={12} lg={8} xl={4}>
            <div className="executive-analysis panel-design">
              <Typography.Text className="analysis-title" style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}>
                Total Pipeline
              </Typography.Text>
              {skeletonLoader ? (
                <Skeleton.Button active style={{ margin: "8px 0px", textAlign:"center" }} />
              ) : (
                <Typography.Text className="analysis-value" style={{color:"#7F56D9", fontSize:"18px", fontWeight:700, textAlign:'center', marginTop:'5px'}}>
                  $
                  {ChartData
                    ? numberWithCommasAndRemovedNoDecimals(
                        ChartData["total_pipeline"] || 0
                      )
                    : 0}
                </Typography.Text>
              )}
            </div>
          </Col>
          <Col span={5} xs={24} sm={12} md={12} lg={8} xl={4}>
            <div className="executive-analysis panel-design">
              <Typography.Text className="analysis-title" style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}>
                Deals (Pipeline)
              </Typography.Text>
              {ChartData["tooltip"]?.[0]?.pipeline?.length ? (
                <Popover
                  placement="topRight"
                  arrowPointAtCenter
                  title={<strong>Deals</strong>}
                  content={
                    <div style={{ maxHeight: 300, overflow: "scroll" }}>
                      <Descriptions size={"small"} bordered>
                        {ChartData["tooltip"]?.[0]?.pipeline?.map(
                          (item, index) => (
                            <Descriptions.Item
                              span={3}
                              label={item?.id}
                              key={index}
                            >
                              <span>{item?.name}</span>
                            </Descriptions.Item>
                          )
                        )}
                      </Descriptions>
                    </div>
                  }
                >
                  <Button
                    type="text"
                    style={{
                      fontFamily: "cursive",
                      fontWeight: 600,
                      fontSize: "18px",
                      color: "#bdb6b6",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      right: 10,
                      top: 3,
                    }}
                  >
                    i
                  </Button>
                </Popover>
              ) : null}

              {skeletonLoader ? (
                <Skeleton.Button active style={{ margin: "8px 0px" }} />
              ) : (
                <Typography.Text className="analysis-value" style={{color:"#7F56D9", fontSize:"18px", fontWeight:700, textAlign:'center', marginTop:'5px'}}>
                  {ChartData
                    ? numberWithCommasAndRemovedNoDecimals(
                        ChartData["total_pipeline_deals"] || 0
                      )
                    : 0}
                </Typography.Text>
              )}
            </div>
          </Col>
          <Col span={5} xs={24} sm={12} md={12} lg={8} xl={5}>
            <div className="executive-analysis panel-design">
              <Typography.Text className="analysis-title" style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}>
                Avg Deal Size (Pipeline)
              </Typography.Text>
              {skeletonLoader ? (
                <Skeleton.Button active style={{ margin: "8px 0px" }} />
              ) : (
                <Typography.Text className="analysis-value" style={{color:"#7F56D9", fontSize:"18px", fontWeight:700, textAlign:'center', marginTop:'5px'}}>
                  $
                  {ChartData
                    ? numberWithCommasAndRemovedNoDecimals(
                        ChartData["avg_pipeline_deal_size"] || 0
                      )
                    : 0}
                </Typography.Text>
              )}
            </div>
          </Col>
          <Col span={5} xs={24} sm={12} md={12} lg={12} xl={5}>
            <div className="executive-analysis panel-design" style={{    alignItems: 'center'}}>
              <Typography.Text
                className="analysis-title"
                style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}
              >
                Year (Contact Created)
              </Typography.Text>
              <Select
                value={yearFilter["pipeline"]}
                placeholder="Year"
                style={{ width: 172, padding: "6px 0px",marginTop:'5px' }}
                options={yearOptions}
                onChange={(value) => OnYearChange("pipeline", value)}
                disabled={skeletonLoader}
              />
            </div>
          </Col>
          <Col span={4} xs={24} sm={12} md={24} lg={12} xl={6}>
            <div className="executive-analysis panel-design" style={{    alignItems: 'center'}}>
              <Typography.Text
                className="analysis-title"
                style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}
              >
                Quarter (Contact Created)
              </Typography.Text>
              
              <Select
                mode="multiple"
                size={'middle'}
                
                defaultValue={['Q1', 'Q2', 'Q3', 'Q4']}
                onChange={(value) => onContactQuaterChange(value)}
                style={{ width: 220, padding: "6px 0px", marginTop:'5px' }}
                options={quarterOptions}
            />
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 20 }}>
          <Col span={24} className="analysis-chart-wrapper panel-design">
            <div style={{ width: "100%" }}>
              <Typography.Text style={{ fontSize: "18px", fontWeight: 500, color:"#1D2939" }}>
                Pipeline In Play
              </Typography.Text>
              <br />
              {skeletonLoader ? (
                <Skeleton.Button
                  style={{ width: "75vw", height: 400 }}
                  active
                />
              ) : ChartData &&
                (ChartData["pipeline_data"] || [])?.length > 0 ? (
                <ColumnLineChart
                  data={ChartData["pipeline_data"] || []}
                  xField={pipelineChartXAxis}
                  yFields={pipelineChartYAxis}
                  
                />
              ) : (
                <RenderEmptyChart />
              )}
            </div>
          </Col>
        </Row>
      </div>

      {/* Closed Won Chart */}
      <div className="reporting-card-body">
        <Row justify="space-between" gutter={[18, 18]}>
          <Col span={6} xs={24} sm={12} md={12} lg={8} xl={4}>
            <div className="executive-analysis panel-design">
              <Typography.Text className="analysis-title" style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}>
                Closed Won Revenue
              </Typography.Text>

              {skeletonLoader ? (
                <Skeleton.Button active style={{ margin: "8px 0px" }} />
              ) : (
                <Typography.Text className="analysis-value" style={{color:"#7F56D9", fontSize:"18px", fontWeight:700, textAlign:'center', marginTop:'5px'}}>
                  $
                  {ChartData
                    ? numberWithCommasAndRemovedNoDecimals(
                        ChartData["total_closed_won_revenue"] || 0
                      )
                    : 0}
                </Typography.Text>
              )}
            </div>
          </Col>
          <Col span={6} xs={24} sm={12} md={12} lg={8} xl={4}>
            <div className="executive-analysis panel-design">
              <Typography.Text className="analysis-title" style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}>
                Deal (Closed Won)
              </Typography.Text>
              {ChartData["tooltip"]?.[0]?.close_won?.length ? (
                <Popover
                  placement="topRight"
                  arrowPointAtCenter
                  title={<strong>Deals</strong>}
                  content={
                    <div style={{ maxHeight: 300, overflow: "scroll" }}>
                      <Descriptions size={"small"} bordered>
                        {ChartData["tooltip"]?.[0]?.close_won?.map(
                          (item, index) => (
                            <Descriptions.Item
                              span={3}
                              label={item?.id}
                              key={index}
                            >
                              <span>{item?.name}</span>
                            </Descriptions.Item>
                          )
                        )}
                      </Descriptions>
                    </div>
                  }
                >
                  <Button
                    type="text"
                    style={{
                      fontFamily: "cursive",
                      fontWeight: 600,
                      fontSize: "18px",
                      color: "#bdb6b6",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      right: 10,
                      top: 3,
                    }}
                  >
                    i
                  </Button>
                </Popover>
              ) : null}

              {skeletonLoader ? (
                <Skeleton.Button active style={{ margin: "8px 0px" }} />
              ) : (
                <Typography.Text className="analysis-value" style={{color:"#7F56D9", fontSize:"18px", fontWeight:700, textAlign:'center', marginTop:'5px'}}>
                  {ChartData
                    ? numberWithCommasAndRemovedNoDecimals(
                        ChartData["total_closed_won_deals"] || 0
                      )
                    : 0}
                </Typography.Text>
              )}
            </div>
          </Col>
          <Col span={6} xs={24} sm={12} md={12} lg={8} xl={5}>
            <div className="executive-analysis panel-design">
              <Typography.Text className="analysis-title" style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}>
                Avg Deal Size (Closed Won)
              </Typography.Text>
              {skeletonLoader ? (
                <Skeleton.Button active style={{ margin: "8px 0px" }} />
              ) : (
                <Typography.Text className="analysis-value" style={{color:"#7F56D9", fontSize:"18px", fontWeight:700, textAlign:'center', marginTop:'5px'}}>
                  $
                  {ChartData
                    ? numberWithCommasAndRemovedNoDecimals(
                        ChartData["avg_closed_won_deal_size"] || 0
                      )
                    : 0}
                </Typography.Text>
              )}
            </div>
          </Col>
          <Col span={6} xs={24} sm={12} md={12} lg={12} xl={5}>
            <div className="executive-analysis panel-design" style={{    alignItems: 'center'}}>
              <Typography.Text
                className="analysis-title"
                style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}
              >
                Year (Deal Closed)
              </Typography.Text>
              <Select
                value={yearFilter["closed_won"]}
                disabled={skeletonLoader}
                placeholder="Year"
                style={{ width: 172, padding: "6px 0px", marginTop:'5px' }}
                options={yearOptions}
                onChange={(value) => OnYearChange("closed_won", value)}
              />
            </div>
          </Col>
          <Col span={4} xs={24} sm={12} md={24} lg={12} xl={6}>
            <div className="executive-analysis panel-design" style={{    alignItems: 'center'}}>
              <Typography.Text
                className="analysis-title"
                style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}
              >
                Quarter (Deal Closed)
              </Typography.Text>
              <Select
                mode="multiple"
                size={'middle'}
                defaultValue={['Q1', 'Q2', 'Q3', 'Q4']}
                onChange={(value) => onClosedQuaterChange(value)}
                style={{ width: 220, padding: "6px 0px", marginTop:'5px' }}
                options={quarterOptions}
            />
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 20 }}>
          <Col span={24} className="analysis-chart-wrapper panel-design">
            <div style={{ width: "100%" }}>
              <Typography.Text style={{ fontSize: "18px", fontWeight: 500, color:"#1D2939" }}>
                Closed Won Lookback
              </Typography.Text>
              <br />
              {skeletonLoader ? (
                <Skeleton.Button
                  style={{ width: "75vw", height: 400 }}
                  active
                />
              ) : ChartData && (ChartData["deal_data"] || [])?.length > 0 ? (
                <ColumnLineChart
                  data={ChartData["deal_data"] || []}
                  xField={closedWonChartXAxis}
                  yFields={closedWonChartYAxis}
                  xTitle='Group By : Quarter (Contact Created)'
                />
              ) : (
                <RenderEmptyChart />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DealAnalysis;

const RenderEmptyChart = () => (
  <Empty
    style={{
      height: 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    }}
  />
);
