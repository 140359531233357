import React, { useState } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Input, Switch, Dropdown, Menu } from "antd";
import CreateChannel from "./CreateChannel";
import CreateCampaign from "./CreateCampaign";
import campaignPlanning from "assets/Images/campaign_planning.svg";
import campaignSearchIcon from "assets/Images/campaign_search_icon.svg";
import budgetCampaign_preview from "assets/Images/budgetCampaign_preview.png";
import PreviewInfo from "commons/PreviewInfo";
import BudgetLeadsViewNew from "./BudgetLeadsViewNew";
import { Loading3QuartersOutlined, MoreOutlined } from "@ant-design/icons";
import { TrackClickFunction } from "commons/HeapTrack/TrackClickFunction";

const Campaigns = ({
  CampaignList,
  BudgetProfileListDetails,
  ChannelList,
  BudgetProfile,
  ProspectData,
  selectedQuarters,
  TotalLeadsGoal,
  selectedYear,
  sethasUnsavedChanges,
  setaskForSampleBudget,
  nameOfLeadObj,
  listOfYear,
  ...props
}) => {
  const [visible, setVisible] = useState(false);
  const [visibleCreateCampaignDrawer, setVisibleCreateCampaignDrawer] =
    useState(false);
  const [showCampaignWithZero, setshowCampaignWithZero] = useState(false)
  const showModal = () => {
    setVisible(true);
  };
  const showCreateCampaignDrawer = () => {
    setVisibleCreateCampaignDrawer(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const handleCancelCampign = () => {
    setVisibleCreateCampaignDrawer(false);
  };

  // useEffect(() => {
  //   dispatch(getCapmaignList());
  // }, [dispatch]);

  const [searchText, setSearchText] = useState("");
  const handleRowSearch = (e) => {
    setSearchText(e.target.value);
  };

  const [campaignViewBudget, setCampaignViewBudget] = useState(true);
  const isBudgetView = (viewFlag) => {
    setCampaignViewBudget(!campaignViewBudget);
  };


  let actulsState = true;
  if(localStorage.getItem("show_actuals") === 'false' || props.userPermission?.['budgetCampaign.campaigns.campaign.mapActual'] === 'viewonly' || props.userPermission?.['budgetCampaign.campaigns.campaign.mapActual'] === 'hidden') {
    actulsState = false
  }

  // eslint-disable-next-line no-unused-vars
  const [showActuals, setShowActuals] = useState(actulsState);
  // const onToggleActualSwitch = (checked) => {
  //   localStorage.setItem("show_actuals",checked)
  //   setShowActuals(checked)
  // }


  const [tableLoading, settableLoading] = useState(false)
const [uploadCsvModalOpen, setuploadCsvModalOpen] = useState(false)


  return (
    <div className="campaign-planning-section panel-design" style={{border:'1px solid var(--Gray-300, #D0D5DD)'}}>
      {props.userPermission?.['budgetCampaign.campaigns.campaign'] !== 'preview' && props.userPermission?.['budgetCampaign.campaigns.campaign'] !== 'hidden' && <div>
        <Row
          xs={24}
          md={24}
          lg={24}
          gutter={[8, 8]}
          style={{ padding: "5px 0px 5px 0px", alignItems: 'center' }}
          className="print-grid responsive-box"
        >
          <h2 style={{textAlign:"left", marginBottom: 20, paddingLeft:1 , width: '100%'}} className="only-print"><b>Campaign planning</b></h2>
          {!props.campaignPlanningEmpty && (
            <Col xs={24} md={12} lg={5} span={3}>
              <div className="font-color-budget non-print ">
                {/* <span>
                  <img src={campaignPlanning} alt="" />
                </span> */}
                <span className="campaign-planning-title" style={{color:"#1D2939", fontWeight:700}}>
                  Campaign Planning
                </span>
              </div>
            </Col>
          )}
       
          
        </Row>
        <Row
          xs={24}
          md={16}
          lg={24}
          gutter={[8, 8]}
          style={{ padding: "10px 0px 10px 0px", alignItems: 'center' }}
          className="non-print"
        >
          <Col xs={24} md={24} lg={5} span={3}>
            {!props.campaignPlanningEmpty ? (
              <div style={{display:'flex', alignItems:"center"}}>
              <div className="font-color-budget  custom-tab">
                <span
                  onClick={() => isBudgetView(true)}
                  className={`budget ${
                    campaignViewBudget
                      ? "custom-selected-tab-selected"
                      : "custom-selected-tab"
                  }`}
                  style={{color:"#7F56D9"}}
                >
                  Budget
                </span>
                <span
                  onClick={() => isBudgetView(false)}
                  
                  className={`budget ${
                    campaignViewBudget
                      ? "custom-selected-tab"
                      : "custom-selected-tab-selected"
                  }`}
                >
                  {nameOfLeadObj}
                </span>
              </div>
                    <div style={{marginLeft:10}} >
                    { tableLoading && <Loading3QuartersOutlined spin   style={{fontSize:22}}/>}
                    </div>
                    </div>
                    
            ) : (
              <div className="font-color-budget ">
                <span>
                  <img src={campaignPlanning} alt="" />
                </span>
                <span className="campaign-planning-title">
                  Campaign Planning
                </span>
              </div>
            )}
          </Col>
          <Col xs={24} md={12} lg={6} span={4} className="print-box">
          {!props.campaignPlanningEmpty && (
            <>
            
              {(props.userPermission?.['budgetCampaign.campaigns.campaign.mapActual'] !== 'viewonly' && props.userPermission?.['budgetCampaign.campaigns.campaign.mapActual'] !== 'hidden') && <div className="font-color-budget " style={{display:"flex", justifyContent:"space-evenly"}}>
                  <div>
                  <span>

                <Switch defaultChecked onChange={(e) => setshowCampaignWithZero(!e)} checked = {!showCampaignWithZero} />
                </span>
                <span className="campaign-planning-title-spend" style = {{color:"#727272", fontSize:16}}>
                  <strong style={{color:'#1D2939', fontWeight:'500', fontSize:16}}>Show $0 Campaigns</strong>{" "}
                </span>
                </div>
                
              </div>}
            
            </>
            
          )}
          </Col>
          <Col xs={24} md={24} lg={6} span={4}>
            {!props.campaignPlanningEmpty && (
              <div className="font-color-budget non-print" style={{display:"flex",alignItems:"center"}}>
          
                <Input
                style={{
                  fontSize:"20px",
                }}
                  placeholder="Search row name"
                  prefix={
                    <img
                      src={campaignSearchIcon}
                      style={{ paddingRight: 3, paddingTop: 2 }}
                      alt=""
                    />
                  }
                  onChange={handleRowSearch}
                  className={`campaign-search general-input ${searchText && 'campaign-search-heighlight'}`}
                />
              </div>
            )}
          </Col>
          <Col xs={24} md={24} lg={7} span={12} data-tour = "tour__createCampaign" className="createBTN" style={{display:"flex", justifyContent:"end", alignItems:"center"}}>
          {((props.userPermission?.['budgetCampaign.campaigns.channel'] !== 'viewonly' && props.userPermission?.['budgetCampaign.campaigns.channel'] !== 'hidden') && (props.userPermission?.['budgetCampaign']  !== 'preview' && props.userPermission?.['budgetCampaign']  !== 'viewonly')) && <Col span={11} lg={11} md={11} sm={24} xs={24}>
            <div className="font-color-budget non-print">
              <Button
                block
                type="secondary"
                onClick={() => {
                  TrackClickFunction('demand-gen-budget-and-campaigns-create-channel-button')
                  showModal()
                }}
                className="create-btn-type-2 primaryButton"
                style={{ fontSize: '14px', height:'38px', width:'100%' }}
              >
                Create Channel
              </Button>
            </div>
          </Col>}
          {((props.userPermission?.['budgetCampaign.campaigns.channel']  === 'viewonly')) && <Col span={11} lg={11} md={11} sm={24} xs={24} >
            <div className="font-color-budget non-print">
              <Button
                block
                type="secondary"
                // onClick={showModal}
                className="create-btn-type-2 primaryButton"
                style={{ fontSize: '14px', height:'38px', width:'100%' }}
                title = "View only permission"
                disabled
              >
                Create Channel
              </Button>
            </div>
          </Col>}
          {((props.userPermission?.['budgetCampaign.campaigns.campaign'] !== 'viewonly' && props.userPermission?.['budgetCampaign.campaigns.campaign'] !== 'preview' && props.userPermission?.['budgetCampaign.campaigns.campaign'] !== 'hidden') && (props.userPermission?.['budgetCampaign']  !== 'preview' && props.userPermission?.['budgetCampaign']  !== 'viewonly')) && <Col
            span={11} lg={11} md={11} sm={24} xs={24}
            style={{  paddingLeft: 4 }}
          >
            <div className="font-color-budget non-print">
              <Button
                type="primary"
                block
                onClick={() => {
                  TrackClickFunction('demand-gen-budget-and-campaigns-create-campaign-button')
                  showCreateCampaignDrawer()
                }}
                className="create-budget-btn secondaryButton"
                style={{ fontSize: '14px', height:'38px', width:'100%' }}
              >
                Create Campaign
              </Button>
            </div>
          </Col>}
          {((props.userPermission?.['budgetCampaign.campaigns.campaign']  === 'preview' || props.userPermission?.['budgetCampaign.campaigns.campaign']  === 'viewonly')) && <Col

            span={11} lg={11} md={11} sm={24} xs={24}
            style={{ paddingTop: 4, paddingLeft: 4 }}
          >
            <div className="font-color-budget non-print">
              <Button
                type="primary"
                block
                // onClick={showCreateCampaignDrawer}
                className="create-budget-btn secondaryButton"
                title = "View only permission"
                disabled
                style={{ fontSize: '14px', height:'38px', width:'100%' }}
              >
                Create Campaign
              </Button>
            </div>
            </Col>}
          {((props.userPermission?.['budgetCampaign.campaigns.campaign'] !== 'viewonly' && props.userPermission?.['budgetCampaign.campaigns.campaign'] !== 'preview' && props.userPermission?.['budgetCampaign.campaigns.campaign'] !== 'hidden') && (props.userPermission?.['budgetCampaign']  !== 'preview' && props.userPermission?.['budgetCampaign']  !== 'viewonly')) && <Col
            span={2} lg={2} md={2} sm={2} xs={24}
            style={{  paddingLeft: 4 }}
          >
            <div className="font-color-budget non-print">
              <Dropdown className="edit" overlay={<Menu>
                  
                  { !props?.campaignPlanningEmpty ? <> <Menu.Item style={{fontSize:16, margin:5}}  onClick={(e) => { window.print(); }}>
                      <span > Export PDF </span>
                  </Menu.Item>
                  <Menu.Item style={{fontSize:16, margin:5}}  onClick={() => { document.getElementById('camaign-table-print-btn').click();}}>
                     <span >Export CSV</span>
                  </Menu.Item></>
                  :
                  <>
                  <Menu.Item style={{fontSize:16, margin:5}}  onClick={(e) => { setuploadCsvModalOpen(true) }}>
                      <span > Import CSV </span>
                  </Menu.Item>
                  <Menu.Item style={{fontSize:16, margin:5}}  onClick={(e) => { setaskForSampleBudget(true) }}>
                      <span > Generate Example </span>
                  </Menu.Item>
                  </>
                  }

              </Menu>} 
              placement="bottomRight">
                  <Button icon={<MoreOutlined />} style={{backgroundColor:"#E9F2FF", border:"none", borderRadius:4}} />
                  </Dropdown>
            </div>
          </Col>}
          {((props.userPermission?.['budgetCampaign.campaigns.campaign']  === 'preview' || props.userPermission?.['budgetCampaign.campaigns.campaign']  === 'viewonly')) && <Col

            span={8} lg={8} md={8} sm={24} xs={24}
            style={{ paddingTop: 4, paddingLeft: 4 }}
          >
            <div className="font-color-budget non-print">
              <Dropdown className="edit" overlay={<Menu>
                  
                  { !props?.campaignPlanningEmpty ? <> <Menu.Item style={{fontSize:16, margin:5}}  onClick={(e) => { window.print(); }}>
                      <span > Export PDF </span>
                  </Menu.Item>
                  <Menu.Item style={{fontSize:16, margin:5}}  onClick={() => { document.getElementById('camaign-table-print-btn').click();}}>
                     <span >Export CSV</span>
                  </Menu.Item></>
                  :
                  <>
                  <Menu.Item style={{fontSize:16, margin:5}}  onClick={(e) => { setuploadCsvModalOpen(true) }}>
                      <span > Import CSV </span>
                  </Menu.Item>
                  <Menu.Item style={{fontSize:16, margin:5}}  onClick={(e) => { setaskForSampleBudget(true) }}>
                      <span > Generate Example </span>
                  </Menu.Item>
                  </>
                  }

              </Menu>} 
              placement="bottomRight" disabled>
                  <Button icon={<MoreOutlined />} style={{backgroundColor:"#E9F2FF", border:"none", borderRadius:4}} />
                  </Dropdown>
            </div>
            </Col>}
            </Col>
        </Row>
      </div>}
      <div style={{width:"100%"}}>
{props.userPermission?.['budgetCampaign.campaigns.campaign'] !== 'preview' && props.userPermission?.['budgetCampaign.campaigns.campaign'] !== 'hidden' &&  (
        <BudgetLeadsViewNew
        sethasUnsavedChanges={sethasUnsavedChanges}
        selectedQuarters = {selectedQuarters}
          CampaignList={CampaignList}
          BudgetProfileListDetails={BudgetProfileListDetails}
          ChannelList={ChannelList}
          selectedValue={props.selectedValue}
          budgetView={campaignViewBudget}
          searchText={searchText}
          BudgetProfile={BudgetProfile}
          ProspectData={TotalLeadsGoal }
          campaignPlanningEmpty={props.campaignPlanningEmpty}
          selectedBudgetDetails={props.selectedBudgetDetails}
          selectedYear={selectedYear}
          showActuals = {showActuals}
          userPermission={props.userPermission}
          showCampaignWithZero={showCampaignWithZero}
          showModal={showModal}
          settableLoadingParent={settableLoading}
          setuploadCsvModalOpen={setuploadCsvModalOpen}
          uploadCsvModalOpen={uploadCsvModalOpen}
          nameOfLeadObj={nameOfLeadObj}  
          listOfYear = {listOfYear}
            
        />
      )} 
      {/* {!campaignViewBudget && props.userPermission?.['budgetCampaign.campaigns.campaign'] !== 'preview' && props.userPermission?.['budgetCampaign.campaigns.campaign'] !== 'hidden' && (
        <BudgetLeadsViewNew
        selectedQuarters = {selectedQuarters}
          CampaignList={CampaignList}
          BudgetProfileListDetails={BudgetProfileListDetails}
          ChannelList={ChannelList}
          selectedValue={props.selectedValue}
          budgetView={false}
          searchText={searchText}
          BudgetProfile={BudgetProfile}
          ProspectData={TotalLeadsGoal}
          campaignPlanningEmpty={props.campaignPlanningEmpty}
            selectedBudgetDetails={props.selectedBudgetDetails}
            selectedYear = {selectedYear}
            showActuals = {showActuals}
            userPermission={props.userPermission}
            showCampaignWithZero = {showCampaignWithZero}
        />
      )} */}
        {(props.userPermission?.['budgetCampaign.campaigns.campaign'] === 'preview') && <div>
          <PreviewInfo img = {budgetCampaign_preview} text = "Upgrade to View"  centerStyle = {{top:"30%"}} />
          </div>}

      </div>
      <CreateChannel visible={visible} handleCancel={handleCancel} listOfYear = {listOfYear} />
      {visibleCreateCampaignDrawer && (
        <CreateCampaign
          visibleCreateCampaignDrawer={visibleCreateCampaignDrawer}
          handleCancelCampign={handleCancelCampign}
          selectedValue={props.selectedValue}
          showModal={showModal}
          year = {BudgetProfile?.year}
          userPermission={props.userPermission}
          nameOfLeadObj = {nameOfLeadObj}
        />
      )}
    </div>
  );
};

export default connect(
  ({
    budgetReducer: {
      CampaignList,
      BudgetProfileListDetails,
      ChannelList,
      BudgetProfile,
      ProspectData,
    },
  }) => ({
    CampaignList,
    BudgetProfileListDetails,
    ChannelList,
    BudgetProfile,
    ProspectData,
  })
)(Campaigns);
