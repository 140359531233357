import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Drawer, Select, message } from "antd";
import { setServerError } from "store/actions/budgetAction";
import {
  assignPackagesAccount,
  assignPackagesUser,
  listUserPackagesData,
  editAssignPackagesUser,
  listAccountPackagesData,
  editAssignPackagesAccount,
} from "store/actions/reportAction";

const AssignPackageAccountUser = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const serverError = useSelector((state) => state.budgetReducer.serverError);
  const { isLoading, userPackages } = useSelector(
    (state) => state.reportReducer
  );
  const { Option } = Select;

  useEffect(() => {
    if (props.visible) {
      if (props.selectedAssigneeType === "user")
        dispatch(listUserPackagesData(props.selectedAssignee?.Username));
      else dispatch(listAccountPackagesData(props.selectedAssignee?.id));
    }
  }, [form, props.selectedAssigneeType, props.selectedAssignee, props.visible]); // eslint-disable-line

  useEffect(() => {
    const assignedPackages = [];
    if (userPackages?.length > 0) {
      userPackages?.[0]?.packages?.items?.map((val) => { // eslint-disable-line
        // eslint-disable-line
        assignedPackages.push(val?.propertiespackages?.id);
      });
    }
    form.setFieldsValue({
      packages: assignedPackages,
    });
  }, [userPackages, props.visible]); // eslint-disable-line

  const handleOk = (value) => {
    if (props.selectedAssigneeType === "company") {
      value.account = props.selectedAssignee.id;
      if (userPackages?.length > 0) {
        value.existing = userPackages?.[0];
        dispatch(editAssignPackagesAccount(value));
      } else {
        dispatch(assignPackagesAccount(value));
      }
    } else {
      if (props.selectedAssignee?.Username) {
        value.owner = props.selectedAssignee?.Username;
        if (userPackages?.length > 0) {
          value.existing = userPackages?.[0];
          dispatch(editAssignPackagesUser(value));
        } else {
          dispatch(assignPackagesUser(value));
        }
      } else {
        message.error(
          "Something went wrong, Username not availale for selected user!"
        );
      }
    }
  };

  useEffect(() => {
    if (serverError === "successAssign") {
      form.resetFields();
      props.handleCancel();
      dispatch(setServerError(""));
    }
  }, [serverError]); // eslint-disable-line

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        handleOk(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const get_attribute = (user, key) => {
    let result = user?.filter((attribute) => attribute.Name === key);
    if (result?.length > 0) {
      return result?.[0]["Value"];
    } else {
      return "";
    }
  };

  return (
    <>
      <Drawer
        title={
          <strong>
            Assign Package to{" "}
            {props.selectedAssigneeType === "company"
              ? props.selectedAssignee?.company
              : `${get_attribute(
                  props.selectedAssignee?.Attributes,
                  "given_name"
                )} ${get_attribute(
                  props.selectedAssignee?.Attributes,
                  "family_name"
                )}`}
          </strong>
        }
        width={416}
        onClose={props.handleCancel}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        maskClosable={false}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
          requiredMark={"optional"}
          className = "create-package"
        >
          <Form.Item name="packages">
            <Select
              size="large"
              showSearch
              style={{ width: "100%" }}
              placeholder="Select packages"
              optionFilterProp="children"
              key="packages"
              mode="multiple"
            >
              {props.packagePropertyList &&
                props.packagePropertyList.length > 0 &&
                props.packagePropertyList.map((item, indexval) => {
                  return (
                    <Option key={indexval} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
          }}
        >
          <Button
            className="secondary-btn"
            onClick={props.handleCancel}
            style={{ fontSize: 18, marginRight: 8, width: "48%" }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            type="primary"
            style={{ fontSize: 18, width: "48%" }}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default AssignPackageAccountUser;
