/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Col,
  Row,
  Form,
  Tag,
  Select,
  Button,
  Divider,
  Checkbox,
  Input,
  Skeleton,
  Space,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCurrentMarketActivity,
  createMarketingAutomationSystem,
  createSocialChannel,
  getCompanySocialChannelsList,
  getCurrentMarketActivitiesList,
  getMarektingAutomationSystemList,
  setLoader,
  updateCurrentMarketActivity,
  updateMarketingAutomationSystem,
  UpdateSocialChannel,
  updateCurrentMarketTools,
  createCurrentMarketTools,
  getCurrentMarketToolsList,
} from "store/actions/goToMarketAction";
import {
  current_marketing_activity_channels,
  email_marketing_programs,
  current_sales_marketing_tools,
} from "utility/Constants";
import "./gotomarket.less";
import { LightenDarkenColor } from "utility/context/CommonMethods";

import pdfImg from "assets/Images/pdfImg.png";
import go_to_market_system_process_preview from "assets/Images/go_to_market_system_process_preview.png";
import PreviewInfo from "commons/PreviewInfo";
import { Page, Document } from "@react-pdf/renderer";
import { SyestemProcess, SystemProcessCover } from "./PdfPages";
import ppt_icon from "../../assets/Images/ppt_icon.svg";
import {
  Loading3QuartersOutlined,
  UploadOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { TrackClickFunction } from "../../commons/HeapTrack/TrackClickFunction";

function SystemsProcess(props) {
  const { CheckableTag } = Tag;
  const { Option } = Select;
  const { TextArea } = Input;

  const [social_channels_form] = Form.useForm();
  const [current_marketing_activity] = Form.useForm();
  const [current_marketing_tools] = Form.useForm();
  const [crm_marketing_automation_system] = Form.useForm();

  const [selectedChannels, setselectedChannels] = useState([]);
  const [selectedTools, setselectedTools] = useState([]);
  const [selectedPrograms, setselectedPrograms] = useState([]);

  const {
    companySocialChannels,
    CurrentMarketActivity,
    MarketingAutomationSystem,
    CurrentMarketTools,
    hasUnsavedChanges,
    GTMMasterDoc,
    downloadPPTX,
    showSkeletonRoot,
    getSignedUrlImg,
  } = props;

  const dispatch = useDispatch();

  const {
    groups: account,
    userPermission,
    viewType,
    accountDetails,
  } = useSelector((state) => state.loginReducer);
  const {
    // companySocialChannels,
    // CurrentMarketActivity,
    // MarketingAutomationSystem,
    // CurrentMarketTools,
    isLoading,
  } = useSelector((state) => state.goToMarketReducer);

  const branded_keyword = useMemo(
    () => ({
      // "Hubspot Marketing Channels": "HUBSPOT_MARKETING_CHANNEL",
      // "Salesforce Marketing Channels": "SALESFORCE_MARKETING_CHANNEL",
    }),
    []
  );
  const non_branded_keyword = useMemo(
    () => ({
      // "B2B SaaS growth": "B2B_SAAS_GROWTH",
    }),
    []
  );

  const [showSkeleton, setShowSkeleton] = useState(true);

  useEffect(() => {
    // dispatch(setLoader(true));
    Promise.all([
      dispatch(getCompanySocialChannelsList()),
      dispatch(getCurrentMarketActivitiesList()),
      dispatch(getMarektingAutomationSystemList()),
      dispatch(getCurrentMarketToolsList()),
    ]).then(() => {
      setShowSkeleton(false);
    });

    // dispatch(setLoader(false));
  }, []);

  const handleChangeChannels = (tag, checked) => {
    setselectedChannels((old) => {
      if (checked) {
        return [...old, tag];
      } else {
        return old.filter((value) => value !== tag);
      }
    });
  };

  const [otherTools, setOtherTool] = useState(false);
  const handleChangeTools = (tag, checked) => {
    if (tag === "OTHER") {
      setOtherTool(checked);
    }

    setselectedTools((old) => {
      if (checked) {
        return [...old, tag];
      } else {
        return old.filter((value) => value !== tag);
      }
    });
  };

  const handleChangePrograms = (tag, checked) => {
    setselectedPrograms((old) => {
      if (checked) {
        return [...old, tag];
      } else {
        return old.filter((value) => value !== tag);
      }
    });
  };

  const saveSocialChannels = async (values) => {
    TrackClickFunction(
      "demand-gen-gtm-intelligence-social-channels-save-button"
    );
    dispatch(setLoader(true));
    let inputData = { account: account[0], ...values };
    if (companySocialChannels?.length > 0) {
      inputData = { ...inputData, id: companySocialChannels?.[0]?.id };
      await dispatch(UpdateSocialChannel(inputData));
      dispatch(setLoader(false));
    } else {
      await dispatch(createSocialChannel(inputData));
      dispatch(setLoader(false));
    }
    props.sethasUnsavedChanges((old) => ({ ...old, socialChannels: false }));
  };

  const saveCurrentMarketingActivity = async (values) => {
    TrackClickFunction(
      "demand-gen-gtm-intelligence-current-marketing-activity-save-button"
    );
    dispatch(setLoader(true));
    let inputData = {
      account: account[0],
      ...values,
      channels: selectedChannels,
      email_marketing: selectedPrograms,
    };
    if (CurrentMarketActivity?.length > 0) {
      inputData = { ...inputData, id: CurrentMarketActivity?.[0]?.id };
      await dispatch(updateCurrentMarketActivity(inputData));
      dispatch(setLoader(false));
    } else {
      dispatch(createCurrentMarketActivity(inputData));
      dispatch(setLoader(false));
    }
    props.sethasUnsavedChanges((old) => ({
      ...old,
      currentMarketingActivity: false,
    }));
  };
  const saveCurrentMarketingTools = async (values) => {
    TrackClickFunction(
      "demand-gen-gtm-intelligence-current-sales-and-marketing-tools-save-button"
    );
    dispatch(setLoader(true));
    let inputData = {
      account: account[0],
      ...values,
      marketing_tools: selectedTools,
    };
    if (CurrentMarketTools?.length > 0) {
      inputData = { ...inputData, id: CurrentMarketTools?.[0]?.id };
      await dispatch(updateCurrentMarketTools(inputData));
      dispatch(setLoader(false));
    } else {
      dispatch(createCurrentMarketTools(inputData));
      dispatch(setLoader(false));
    }
    props.sethasUnsavedChanges((old) => ({
      ...old,
      salesAndMarketingTools: false,
    }));
  };

  const saveCRMMarketingAutomationSystem = async (values) => {
    TrackClickFunction(
      "demand-gen-gtm-intelligence-crm-and-marketing-automation-save-button"
    );
    dispatch(setLoader(true));
    let inputData = { account: account[0], ...values };
    if (MarketingAutomationSystem?.length > 0) {
      inputData = { ...inputData, id: MarketingAutomationSystem?.[0]?.id };
      await dispatch(updateMarketingAutomationSystem(inputData));
      dispatch(setLoader(false));
    } else {
      dispatch(createMarketingAutomationSystem(inputData));
      dispatch(setLoader(false));
    }
    props.sethasUnsavedChanges((old) => ({
      ...old,
      MarketingAutomationSystem: false,
    }));
  };

  useEffect(() => {
    if (CurrentMarketActivity?.[0])
      CurrentMarketActivity[0]?.channels &&
        setselectedChannels(CurrentMarketActivity[0]?.channels);
    CurrentMarketActivity[0]?.email_marketing &&
      setselectedPrograms(CurrentMarketActivity[0]?.email_marketing);

    if (CurrentMarketTools?.[0])
      CurrentMarketTools[0]?.marketing_tools &&
        setselectedTools(CurrentMarketTools[0]?.marketing_tools);
  }, [CurrentMarketActivity, CurrentMarketTools]);

  const [socialChannelsSelect, setSocialChannelsSelect] = useState([]);
  const handleSocialChannelsChange = async (e) => {
    let socialCheckedObj = [...socialChannelsSelect];
    if (e.target.checked) {
      socialCheckedObj.push(e.target.value);
    } else {
      socialCheckedObj = socialCheckedObj.filter(
        (item) => item !== e.target.value
      );
    }
    setSocialChannelsSelect(socialCheckedObj);
  };
  useEffect(() => {
    let onLoadSocialSelected = [...socialChannelsSelect];
    if (companySocialChannels?.length > 0) {
      Object.keys(companySocialChannels?.[0]).map((key) => {
        if (companySocialChannels?.[0]?.[key] === true)
          onLoadSocialSelected.push(key);
        setSocialChannelsSelect(onLoadSocialSelected);
      });
    }
  }, [companySocialChannels]);

  const [automationSelect, setAutomationSelect] = useState([]);
  useEffect(() => {
    let onLoadAutomationSelected = [...automationSelect];
    if (MarketingAutomationSystem?.length > 0) {
      Object.keys(MarketingAutomationSystem?.[0]).map((key) => {
        if (MarketingAutomationSystem?.[0]?.[key] === true)
          onLoadAutomationSelected.push(key);
        setAutomationSelect(onLoadAutomationSelected);
      });
    }
  }, [MarketingAutomationSystem]);

  const handleAutomationChange = async (e) => {
    let automationCheckedObj = [...automationSelect];
    if (e.target.checked) {
      automationCheckedObj.push(e.target.value);
    } else {
      automationCheckedObj = automationCheckedObj.filter(
        (item) => item !== e.target.value
      );
    }
    setAutomationSelect(automationCheckedObj);
  };

  const SystemProcessPage = useMemo(() => {
    return ({ logoUrl }) => {
      let systemProcessObj = {
        ...companySocialChannels?.[0],
        ...MarketingAutomationSystem?.[0],
        ...CurrentMarketActivity?.[0],
        ...CurrentMarketTools?.[0],
      };
      systemProcessObj.primaryColor = accountDetails?.colors
        ? JSON.parse(accountDetails?.colors)?.[0]
        : "#0f61db";
      systemProcessObj.backgroundColor = LightenDarkenColor(
        accountDetails?.colors
          ? JSON.parse(accountDetails?.colors)?.[0]
          : "#0f61db",
        200
      );
      systemProcessObj.logoUrl = logoUrl;
      return (
        <Document>
          <Page
            orientation="landscape"
            style={{ paddingTop: 20, paddingBottom: 50, paddingHorzontal: 30 }}
          >
            <SystemProcessCover
              logoUrl={{ logourl: logoUrl, url: accountDetails.url }}
            />
            <SyestemProcess {...systemProcessObj} />
          </Page>
        </Document>
      );
    };
  }, [
    companySocialChannels,
    MarketingAutomationSystem,
    CurrentMarketActivity,
    CurrentMarketTools,
  ]);

  return (userPermission?.["goToMarketIntelligence.Systems"] === "preview" &&
    account?.[2] === "admin" &&
    (viewType === "companyadmin" || viewType === "companycontributor")) ||
    (userPermission?.["goToMarketIntelligence.Systems"] === "preview" &&
      account?.[2] !== "admin") ? (
    <div>
      <PreviewInfo
        img={go_to_market_system_process_preview}
        text="Upgrade to View"
      />
    </div>
  ) : (
    // showSkeleton || showSkeletonRoot ? <>
    // <Skeleton  paragraph={{ rows: 20 }} style={{padding:20}}  size = "large"/>
    // </>:
    <div style={{ marginTop: "20px" }}>
      {/* {isLoading && <div className="loading">Loading;</div>} */}
      <Row
        justify="center"
        style={{ padding: "10px" }}
        className="systemsProcess targetAudience"
      >
        <Col span={15} lg={15} md={20} sm={24} xs={24}>
          <h2
            style={{
              marginBottom: "30px",
              fontSize: "18px",
              background: "#f5f7fa",
              padding: "10px 5px",
              textAlign: "center",
            }}
            className="titleText fw500 panel-design"
          >
            Current Marketing Activity
          </h2>
          {showSkeleton || showSkeletonRoot ? (
            <Space style={{ width: "100%" }} direction="vertical">
              <Row gutter={16}>
                <Col span={24}>
                  <Skeleton.Input
                    active
                    style={{ width: "30vw", marginBottom: 10 }}
                    size="large"
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 150, borderRadius: 7 }}
                  />
                </Col>
              </Row>
              <Col span={24} lg={24} md={24} sm={24} xs={24}>
                <Skeleton.Input active style={{ width: "60vw" }} size="large" />
              </Col>
              <Col span={24} lg={24} md={24} sm={24} xs={24}>
                <Skeleton.Input active style={{ width: "60vw" }} size="large" />
              </Col>
              <Col span={24} lg={24} md={24} sm={24} xs={24}>
                <Skeleton.Button
                  active
                  size="large"
                  shape="round"
                  style={{ width: 200, borderRadius: 7, marginTop: 10 }}
                />
              </Col>
            </Space>
          ) : (
            <Form
              name="basic"
              form={current_marketing_activity}
              onFinish={saveCurrentMarketingActivity}
              layout="vertical"
              initialValues={CurrentMarketActivity?.[0]}
              autoComplete="off"
              onChange={() => {}}
              className="general-form"
            >
              <Form.Item
                label={
                  <small>
                    <span
                      style={{
                        color: "#1D2939",
                        fontWeight: 500,
                        fontSize: "18px",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                      Channels
                    </span>
                    <span style={{ color: "#475467", fontSize: 14 }}>
                      What Channels do you use today to drive awareness and
                      leads? Please check all that apply.
                    </span>
                  </small>
                }
              >
                {Object.keys(current_marketing_activity_channels).map((key) => (
                  <CheckableTag
                    key={current_marketing_activity_channels[key]}
                    checked={
                      selectedChannels.indexOf(
                        current_marketing_activity_channels[key]
                      ) > -1
                    }
                    onChange={(checked) => {
                      handleChangeChannels(
                        current_marketing_activity_channels[key],
                        checked
                      );
                      props.sethasUnsavedChanges((old) => ({
                        ...old,
                        currentMarketingActivity: true,
                      }));
                    }}
                  >
                    {key}
                  </CheckableTag>
                ))}
              </Form.Item>

              <Form.Item
                label={
                  <small>
                    <span
                      style={{
                        color: "#1D2939",
                        fontWeight: 500,
                        fontSize: "18px",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                      Email Marketing Programs
                    </span>
                    <span style={{ color: "#475467", fontSize: 14 }}>
                      What kind email marketing programs?
                    </span>
                  </small>
                }
              >
                {Object.keys(email_marketing_programs).map((key) => (
                  <CheckableTag
                    key={email_marketing_programs[key]}
                    checked={
                      selectedPrograms.indexOf(email_marketing_programs[key]) >
                      -1
                    }
                    onChange={(checked) => {
                      handleChangePrograms(
                        email_marketing_programs[key],
                        checked
                      );
                      props.sethasUnsavedChanges((old) => ({
                        ...old,
                        currentMarketingActivity: true,
                      }));
                    }}
                  >
                    {key}
                  </CheckableTag>
                ))}
              </Form.Item>
              <Form.Item
                name="branded_keywords"
                label={
                  <small>
                    <span
                      style={{
                        color: "#1D2939",
                        fontWeight: 500,
                        fontSize: "18px",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                      Branded Keywords
                    </span>

                    <span style={{ color: "#475467", fontSize: 14 }}>
                      Type comma separated list of branded keywords you want to
                      target.
                    </span>
                  </small>
                }
              >
                <Select
                  mode="tags"
                  allowClear
                  style={{ width: "100%" }}
                  onChange={() =>
                    props.sethasUnsavedChanges((old) => ({
                      ...old,
                      currentMarketingActivity: true,
                    }))
                  }
                  // defaultValue={['Hubspot Marketing Channels','Salesforce Leads Channels']}
                  placeholder="Select branded keywords"
                  className="general-input-select"
                >
                  {Object.keys(branded_keyword)?.map((key) => (
                    <Option value={branded_keyword[key]}>{key}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="non_branded_keywords"
                label={
                  <small>
                    <span
                      style={{
                        color: "#1D2939",
                        fontWeight: 500,
                        fontSize: "18px",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                      Non branded Keywords
                    </span>

                    <span style={{ color: "#475467", fontSize: 14 }}>
                      Try comma separated list of other relevant keywords.
                    </span>
                  </small>
                }
              >
                <Select
                  mode="tags"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Select non branded keywords"
                  onChange={() =>
                    props.sethasUnsavedChanges((old) => ({
                      ...old,
                      currentMarketingActivity: true,
                    }))
                  }
                  className="general-input-select"
                >
                  {Object.keys(non_branded_keyword)?.map((key) => (
                    <Option value={non_branded_keyword[key]}>{key}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  className="primaryButton"
                  htmlType="submit"
                  type="primary"
                  loading={isLoading}
                >
                  <strong>Save</strong>
                </Button>
                {hasUnsavedChanges.currentMarketingActivity && (
                  <span
                    className="conversation-difference-dot"
                    style={{ opacity: 1, marginLeft: 12 }}
                  ></span>
                )}
              </Form.Item>
            </Form>
          )}
          <Divider style={{ borderTopColor: "#D9D9D9 !important" }} />

          <h2
            style={{
              marginBottom: "30px",
              marginTop: "15px",
              fontSize: "18px",
              background: "#f5f7fa",
              padding: "10px 5px",
              textAlign: "center",
            }}
            className="titleText fw500 panel-design"
          >
            Current Sales and Marketing Tools
          </h2>
          {showSkeleton || showSkeletonRoot ? (
            <Space style={{ width: "100%" }} direction="vertical">
              <Row gutter={16}>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 100, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 100, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 100, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 100, borderRadius: 7 }}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 100, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 100, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 100, borderRadius: 7 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 100, borderRadius: 7 }}
                  />
                </Col>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 200, borderRadius: 7 }}
                  />
                </Col>
              </Row>
            </Space>
          ) : (
            <Form
              name="basic"
              form={current_marketing_tools}
              onFinish={saveCurrentMarketingTools}
              layout="vertical"
              initialValues={CurrentMarketTools?.[0]}
              autoComplete="off"
            >
              <Form.Item
                label={
                  <small>
                    <span
                      style={{
                        color: "#1D2939",
                        fontWeight: 500,
                        fontSize: "18px",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                      What tools you use for Sales & Marketing?
                    </span>

                    <span style={{ color: "#475467", fontSize: 14 }}>
                      Please select all that apply
                    </span>
                  </small>
                }
              >
                {Object.keys(current_sales_marketing_tools).map((key) => (
                  <CheckableTag
                    key={current_sales_marketing_tools[key]}
                    checked={
                      selectedTools.indexOf(
                        current_sales_marketing_tools[key]
                      ) > -1
                    }
                    onChange={(checked) => {
                      handleChangeTools(
                        current_sales_marketing_tools[key],
                        checked
                      );
                      props.sethasUnsavedChanges((old) => ({
                        ...old,
                        salesAndMarketingTools: true,
                      }));
                    }}
                  >
                    {key}
                  </CheckableTag>
                ))}
              </Form.Item>

              {otherTools && (
                <Form.Item name="other">
                  <Input
                    placeholder="Please specify tool name"
                    style={{ marginTop: 10 }}
                    onChange={() =>
                      props.sethasUnsavedChanges((old) => ({
                        ...old,
                        salesAndMarketingTools: true,
                      }))
                    }
                  />
                </Form.Item>
              )}
              <Form.Item>
                <Button
                  className="primaryButton"
                  htmlType="submit"
                  type="primary"
                  loading={isLoading}
                >
                  <strong>Save</strong>
                </Button>
                {hasUnsavedChanges.salesAndMarketingTools && (
                  <span
                    className="conversation-difference-dot"
                    style={{ opacity: 1, marginLeft: 12 }}
                  ></span>
                )}
              </Form.Item>
            </Form>
          )}

          <Divider style={{ borderTopColor: "#D9D9D9 !important" }} />

          <h2
            style={{
              marginBottom: "30px",
              marginTop: "15px",
              marginTop: "15px",
              fontSize: "18px",
              background: "#f5f7fa",
              padding: "10px 5px",
              textAlign: "center",
            }}
            className="titleText fw500 panel-design"
          >
            CRM or Marketing Automation System
          </h2>
          {showSkeleton || showSkeletonRoot ? (
            <Space style={{ width: "100%" }} direction="vertical">
              <Skeleton active avatar></Skeleton>
            </Space>
          ) : (
            <Form
              className="crmSystem"
              name="basic"
              form={crm_marketing_automation_system}
              layout="vertical"
              initialValues={MarketingAutomationSystem?.[0]}
              onFinish={saveCRMMarketingAutomationSystem}
              autoComplete="off"
              onChange={() =>
                props.sethasUnsavedChanges((old) => ({
                  ...old,
                  MarketingAutomationSystem: true,
                }))
              }
            >
              <>
                <span
                  style={{
                    color: "#1D2939",
                    fontWeight: 500,
                    fontSize: "18px",
                    marginBottom: "5px",
                    display: "block",
                  }}
                >
                  Do you currently have Processes/Rules defined for these
                  following categories?
                </span>

                <span style={{ color: "#475467", fontSize: 14 }}>
                  Outline any processes or rules you have defined, where
                  applicable
                </span>
              </>

              <Form.Item
                name="lead_contact_de_duplication"
                valuePropName="checked"
              >
                <Checkbox
                  value="lead_contact_de_duplication"
                  onChange={(e) => handleAutomationChange(e)}
                >
                  Lead/Contact De-duplication
                </Checkbox>
              </Form.Item>
              {automationSelect?.includes("lead_contact_de_duplication") && (
                <Form.Item name="lead_contact_de_duplication_process">
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 5 }}
                    placeholder="Please define process"
                    className="general-input-textarea"
                  />
                </Form.Item>
              )}

              <Form.Item name="lead_contact_assignment" valuePropName="checked">
                <Checkbox
                  value="lead_contact_assignment"
                  onChange={(e) => handleAutomationChange(e)}
                >
                  Lead/Contact Assignment{" "}
                </Checkbox>
              </Form.Item>
              {automationSelect?.includes("lead_contact_assignment") && (
                <Form.Item name="lead_contact_assignment_process">
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 5 }}
                    placeholder="Please define process"
                    className="general-input-textarea"
                  />
                </Form.Item>
              )}

              <Form.Item name="lead_contact_status" valuePropName="checked">
                <Checkbox
                  value="lead_contact_status"
                  onChange={(e) => handleAutomationChange(e)}
                >
                  Lead/Contact Status
                </Checkbox>
              </Form.Item>
              {automationSelect?.includes("lead_contact_status") && (
                <Form.Item name="lead_contact_status_process">
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 5 }}
                    placeholder="Please define process"
                    className="general-input-textarea"
                  />
                </Form.Item>
              )}

              <Form.Item name="lead_contact_source" valuePropName="checked">
                <Checkbox
                  value="lead_contact_source"
                  onChange={(e) => handleAutomationChange(e)}
                >
                  Lead/Contact Source
                </Checkbox>
              </Form.Item>
              {automationSelect?.includes("lead_contact_source") && (
                <Form.Item name="lead_contact_source_process">
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 5 }}
                    placeholder="Please define process"
                    className="general-input-textarea"
                  />
                </Form.Item>
              )}

              <Form.Item name="lead_contact_creation" valuePropName="checked">
                <Checkbox
                  value="lead_contact_creation"
                  onChange={(e) => handleAutomationChange(e)}
                >
                  Lead/Contact Creation{" "}
                  <span style={{ color: "#727272" }}>
                    (When to Create a Contact vs. Lead)
                  </span>{" "}
                </Checkbox>
              </Form.Item>
              {automationSelect?.includes("lead_contact_creation") && (
                <Form.Item name="lead_contact_creation_process">
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 5 }}
                    placeholder="Please define process"
                    className="general-input-textarea"
                  />
                </Form.Item>
              )}

              <Form.Item name="lead_contact_conversion" valuePropName="checked">
                <Checkbox
                  value="lead_contact_conversion"
                  onChange={(e) => handleAutomationChange(e)}
                >
                  Lead/Contact Conversion{" "}
                  <span style={{ color: "#727272" }}>(Lead to Contact)</span>
                </Checkbox>
              </Form.Item>
              {automationSelect?.includes("lead_contact_conversion") && (
                <Form.Item name="lead_contact_conversion_process">
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 5 }}
                    placeholder="Please define process"
                    className="general-input-textarea"
                  />
                </Form.Item>
              )}

              <Form.Item
                name="account_company_creation"
                valuePropName="checked"
              >
                <Checkbox
                  value="account_company_creation"
                  onChange={(e) => handleAutomationChange(e)}
                >
                  Account/Company Creation{" "}
                  <span style={{ color: "#727272" }}>
                    (When to Create an Account)
                  </span>
                </Checkbox>
              </Form.Item>
              {automationSelect?.includes("account_company_creation") && (
                <Form.Item name="account_company_creation_process">
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 5 }}
                    placeholder="Please define process"
                    className="general-input-textarea"
                  />
                </Form.Item>
              )}

              <Form.Item
                name="opportunity_deal_creation"
                valuePropName="checked"
              >
                <Checkbox
                  value="opportunity_deal_creation"
                  onChange={(e) => handleAutomationChange(e)}
                >
                  Opportunity/Deal Creation{" "}
                </Checkbox>
              </Form.Item>
              {automationSelect?.includes("opportunity_deal_creation") && (
                <Form.Item name="opportunity_deal_creation_process">
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 5 }}
                    placeholder="Please define process"
                    className="general-input-textarea"
                  />
                </Form.Item>
              )}

              <Form.Item name="marketing_sales_sla" valuePropName="checked">
                <Checkbox
                  value="marketing_sales_sla"
                  onChange={(e) => handleAutomationChange(e)}
                >
                  Marketing & Sales SLA’s
                </Checkbox>
              </Form.Item>
              {automationSelect?.includes("marketing_sales_sla") && (
                <Form.Item name="marketing_sales_sla_process">
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 5 }}
                    placeholder="Please define process"
                    style={{ marginBottom: 10 }}
                    className="general-input-textarea"
                  />
                </Form.Item>
              )}

              <Form.Item>
                <Button
                  className="primaryButton"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  <strong>Save</strong>
                </Button>
                {hasUnsavedChanges.MarketingAutomationSystem && (
                  <span
                    className="conversation-difference-dot"
                    style={{ opacity: 1, marginLeft: 12 }}
                  ></span>
                )}
              </Form.Item>
            </Form>
          )}
          <Divider style={{ borderTopColor: "#D9D9D9 !important" }} />

          <h2
            style={{
              marginBottom: "30px",
              marginTop: "15px",
              marginTop: "15px",
              fontSize: "18px",
              background: "#f5f7fa",
              padding: "10px 5px",
              textAlign: "center",
            }}
            className="titleText fw500 panel-design"
          >
            Social Channels
          </h2>
          {showSkeleton || showSkeletonRoot ? (
            <Space style={{ width: "100%" }} direction="vertical">
              <Skeleton active avatar></Skeleton>
              <Row>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 200, borderRadius: 7 }}
                  />
                </Col>
              </Row>
            </Space>
          ) : (
            <Form
              className="crmSystem"
              form={social_channels_form}
              initialValues={companySocialChannels?.[0]}
              name="basic"
              layout="vertical"
              autoComplete="off"
              onFinish={saveSocialChannels}
              onChange={() =>
                props.sethasUnsavedChanges((old) => ({
                  ...old,
                  socialChannels: true,
                }))
              }
            >
              <>
                <span
                  style={{
                    color: "#1D2939",
                    fontWeight: 500,
                    fontSize: "18px",
                    marginBottom: "5px",
                    display: "block",
                  }}
                >
                  Is your organization active on social media accounts?
                </span>

                <span style={{ color: "#475467", fontSize: 14 }}>
                  Please add account urls you active on.
                </span>
              </>
              <Form.Item name="facebook" valuePropName="checked">
                <Checkbox
                  value="facebook"
                  onChange={(e) => handleSocialChannelsChange(e)}
                >
                  Facebook
                </Checkbox>
              </Form.Item>

              {socialChannelsSelect?.includes("facebook") && (
                <Form.Item
                  name="facebook_link"
                  rules={[
                    {
                      type: "url",
                      message: "Please enter valid url (including https://)",
                    },
                  ]}
                >
                  <Input
                    placeholder="Add Facebook account link here"
                    className="general-input"
                  />
                </Form.Item>
              )}

              <Form.Item name="instagram" valuePropName="checked">
                <Checkbox
                  value="instagram"
                  onChange={(e) => handleSocialChannelsChange(e)}
                >
                  Instagram{" "}
                </Checkbox>
              </Form.Item>

              {socialChannelsSelect?.includes("instagram") && (
                <Form.Item
                  name="instagram_link"
                  rules={[
                    {
                      type: "url",
                      message: "Please enter valid url (including https://)",
                    },
                  ]}
                >
                  <Input
                    placeholder="Add Instagram account link here"
                    className="general-input"
                  />
                </Form.Item>
              )}

              <Form.Item name="linkedin" valuePropName="checked">
                <Checkbox
                  value="linkedin"
                  onChange={(e) => handleSocialChannelsChange(e)}
                >
                  Linkedin{" "}
                </Checkbox>
              </Form.Item>

              {socialChannelsSelect?.includes("linkedin") && (
                <Form.Item
                  name="linkedin_link"
                  rules={[
                    {
                      type: "url",
                      message: "Please enter valid url (including https://)",
                    },
                  ]}
                >
                  <Input
                    placeholder="Add Linkedin account link here"
                    className="general-input"
                  />
                </Form.Item>
              )}

              <Form.Item name="youtube" valuePropName="checked">
                <Checkbox
                  value="youtube"
                  onChange={(e) => handleSocialChannelsChange(e)}
                >
                  Youtube
                </Checkbox>
              </Form.Item>

              {socialChannelsSelect?.includes("youtube") && (
                <Form.Item
                  name="youtube_link"
                  rules={[
                    {
                      type: "url",
                      message: "Please enter valid url (including https://)",
                    },
                  ]}
                >
                  <Input
                    placeholder="Add Youtube account link here"
                    style={{ marginBottom: 10 }}
                    className="general-input"
                  />
                </Form.Item>
              )}

              <Form.Item name="twitter" valuePropName="checked">
                <Checkbox
                  value="twitter"
                  onChange={(e) => handleSocialChannelsChange(e)}
                >
                  Twitter
                </Checkbox>
              </Form.Item>

              {socialChannelsSelect?.includes("twitter") && (
                <Form.Item
                  name="twitter_link"
                  rules={[
                    {
                      type: "url",
                      message: "Please enter valid url (including https://)",
                    },
                  ]}
                >
                  <Input
                    placeholder="Add twitter account link here"
                    style={{ marginBottom: 10 }}
                    className="general-input"
                  />
                </Form.Item>
              )}

              <Form.Item>
                <Button
                  className="primaryButton"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  <strong>Save</strong>
                </Button>
                {hasUnsavedChanges.socialChannels && (
                  <span
                    className="conversation-difference-dot"
                    style={{ opacity: 1, marginLeft: 12 }}
                  ></span>
                )}
              </Form.Item>
            </Form>
          )}
          <Divider />

          <Row
            gutter={[10, 10]}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
              borderRadius: 7,
              marginTop: 30,
              marginBottom: 15,
            }}
            data-tour="tour__Export"
          >
            <Col span={24} style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <h3
                  className="panel-design"
                  style={{
                    marginBottom: "30px",
                    fontSize: "18px",
                    background: "#f5f7fa",
                    padding: "10px 5px",
                    textAlign: "center",
                    display: "block",
                    fontWeight: 500,
                  }}
                >
                  Download PDFs & PPTx
                </h3>{" "}
              </div>
              <div style={{ paddingLeft: 10 }}>
                {props.loadingPDF && (
                  <Loading3QuartersOutlined
                    spin
                    style={{
                      fontSize: 18,
                      float: "right",
                      marginTop: 5,
                      marginRight: 8,
                    }}
                  />
                )}
              </div>
            </Col>
            <Col span={8} md={8} lg={8} sm={24} xs={24}>
              {showSkeleton || showSkeletonRoot ? (
                <Space style={{ width: "100%" }} direction="vertical">
                  <Skeleton active size="large" />
                </Space>
              ) : (
                <div
                style={{
                  background: "#f5f7fa",
                  padding: 15,
                  borderRadius: 10,
                }}
                className="panel-design"
                >
                  <img src={pdfImg} style={{ marginBottom: 10 }} alt="" />
                  <p>
                    <strong>System & Processes PDF</strong>
                    <br /> with all the details
                    <br /> included above
                  </p>
                  <div style={{display:'flex', alignItems:"center"}}>
                  <Button
                    onClick={async () => {
                      let url = await getSignedUrlImg(
                        accountDetails?.profile_url
                      );
                      props.generatePdfDocument(
                        "System & Processes.pdf",
                        <SystemProcessPage logoUrl={url} />
                      );
                    }}
                    className="download-btn tertiaryButton"
                    style={{ width:'49%', fontSize:'14px', height:'34px'}}
                    type="primary"
                    icon={<UploadOutlined style={{ fontSize: 13 }} />}
                  >
                    Export
                  </Button>
                  <Button
                    className="download-btn tertiaryButton"
                    onClick={() => {
                      props.emailPdfDocument(
                        "Demand Generation - Go to Marketing Intelligence System & Processes",
                        <SystemProcessPage />
                      );
                    }}
                    style={{ width:'49%', fontSize:'14px', height:'34px', marginLeft:'2%' }}
                    type="primary"
                    icon={
                      <MailOutlined
                        style={{ fontSize: 13, verticalAlign: "middle" }}
                      />
                    }
                  >
                    Email
                  </Button>
                  </div>
                </div>
              )}
            </Col>
            <Col span={8} md={8} lg={8} sm={24} xs={24}>
              {showSkeleton || showSkeletonRoot ? (
                <Space style={{ width: "100%" }} direction="vertical">
                  <Skeleton active size="large" />
                </Space>
              ) : (
                <div
                style={{
                  background: "#f5f7fa",
                  padding: 15,
                  borderRadius: 10,
                }}
                className="panel-design"
                >
                  <img src={pdfImg} style={{ marginBottom: 10 }} alt="" />
                  <p>
                    <strong>Master PDF</strong> with all the <br />
                    GTM modules details
                    <br /> included in one PDF
                  </p>
                  <div style={{display:'flex', alignItems:"center"}}>
                  <Button
                    onClick={async () => {
                      let url = await getSignedUrlImg(
                        accountDetails?.profile_url
                      );
                      props.generatePdfDocument(
                        "Go to Marketing Master.pdf",
                        <GTMMasterDoc logoUrl={url} />
                      );
                    }}
                    className="download-btn tertiaryButton"
                    style={{ width:'49%', fontSize:'14px', height:'34px'}}
                    type="primary"
                    icon={<UploadOutlined style={{ fontSize: 13 }} />}
                  >
                    Export
                  </Button>
                  <Button
                    className="download-btn tertiaryButton"
                    onClick={() => {
                      props.emailPdfDocument(
                        "Demand Generation - Go to Marketing Intelligence Master",
                        <GTMMasterDoc />
                      );
                    }}
                    style={{ width:'49%', fontSize:'14px', height:'34px', marginLeft:'2%' }}
                    type="primary"
                    icon={
                      <MailOutlined
                        style={{ fontSize: 13, verticalAlign: "middle" }}
                      />
                    }
                  >
                    Email
                  </Button>
                  </div>
                </div>
              )}
            </Col>
            <Col span={8} md={8} lg={8} sm={24} xs={24}>
              {showSkeleton || showSkeletonRoot ? (
                <Space style={{ width: "100%" }} direction="vertical">
                  <Skeleton active size="large" />
                </Space>
              ) : (
                <div
                style={{
                  background: "#f5f7fa",
                  padding: 15,
                  borderRadius: 10,
                }}
                className="panel-design"
                >
                  <img src={ppt_icon} style={{ marginBottom: 10 }} alt="" />
                  <p>
                    <strong>Complete presentation</strong>
                    <br /> of all the GTM modules
                    <br /> details entered
                  </p>
                  <div style={{display:'flex', alignItems:"center"}}>
                  <Button
                    className="download-btn tertiaryButton"
                    icon={<UploadOutlined style={{ fontSize: 13 }} />}
                    type="primary"
                    onClick={downloadPPTX}
                    style={{ width:'49%', fontSize:'14px', height:'34px'}}
                  >
                    Export
                  </Button>
                  <Button
                    className="download-btn tertiaryButton"
                    style={{ width:'49%', fontSize:'14px', height:'34px', marginLeft:'2%' }}
                    icon={
                      <MailOutlined
                        style={{ fontSize: 13, verticalAlign: "middle" }}
                      />
                    }
                    type="primary"
                    onClick={props.emailPPTXDocument}
                  >
                    Email
                  </Button>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default SystemsProcess;
