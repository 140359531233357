import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Skeleton, message, Row, Col } from "antd";
import reporting_analytics_module_preview from "assets/Images/reporting_analytics_module_preview.png";
import PreviewInfo from "commons/PreviewInfo";
import { getQuicksightUrl } from "store/actions/reportAction";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";
import config from "aws-exports";
import { ExploricApi} from "utility/Api"
import { reportingHeadings } from "utility/Constants";

function QuicksightDashboard(props) {
    const {type} = props

  const account = useSelector((state) => state.loginReducer.groups);
  const {userPermission, viewType,userData,admin_groups_self} = useSelector((state) => state.loginReducer);

  const permissions = {
    executive: userPermission?.['reporting.executive'],
    "google-analytics": userPermission?.['reporting.analytics'],
    "sales-and-marketing": userPermission?.['reporting.salesandmarketingleadership']
   }

  const showPreviewOnly = (permissions[type] === "preview"  && account?.[2] === "admin" && viewType === "companyadmin") || (permissions[type] === "preview" && account?.[2] !== "admin");

  useEffect(() => {
  if(showPreviewOnly) return;
    if(account?.[0] !== admin_groups_self?.[0]){

        changeQuickSightPermissions(account?.[0]).then(res => {
        console.log(res);
        getURL()
      }).catch(err => {
        console.log(err);
      })

    }else{
      getURL()
    }
    return () => {

      if(account?.[0] !== admin_groups_self?.[0]){
        changeQuickSightPermissions(admin_groups_self?.[0])
      }
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const changeQuickSightPermissions = (customerAccount) => {
    let bucketName = config.aws_cloud_logic_custom?.[0].endpoint?.split('/');
        let envName = bucketName[bucketName?.length - 1]
    
          let params  = {
          "event-bus":`4am-demand-${envName}`,
          "source":"4AMDemand",
          "detail-type":"QuicksightOnTheFly",
          "detail":{
              "uuid":customerAccount,
              "email":userData?.email
            }
          }
      return ExploricApi.ChangeQuicksightPermissionPutEvent(params)
  }

  const [quicksightLoader, setquicksightLoader] = useState(false)

  const getURL = () => {
    setquicksightLoader(true)
      getQuicksightUrl(type,account).then(res => {
        embed(res?.EmbedUrl)
        
      }).catch(err =>{
        console.log(err);
        message.error("Something went wrong")

      })
  }

  const embed = async (url) => {
      document.getElementById("quick-sight").innerHTML=""
        const options = {
            url: url,
            container: document.getElementById("quick-sight"),
            scrolling: "yes",
            height: "100%",
            width: "100%",
            locale: "en-US",
            footerPaddingEnabled: true,
            loadCallback: dashboardloadCallback,
            errorCallback: dashboardErrorCallback,

        };

        QuickSightEmbedding.embedDashboard(options)
        setquicksightLoader(false)

    };

    function dashboardloadCallback(e)
    {
      setquicksightLoader(false)
    }

    function dashboardErrorCallback(e)
    {
      setquicksightLoader(false)

    }

    let executive = useMemo(() => (
      <><Row gutter={[20,20]} style={{width:'100%'}}>
      <Col><Skeleton.Button active/></Col>
      <Col><Skeleton.Button active/></Col>
      <Col><Skeleton.Button active/></Col>
    </Row>
    <Row gutter={[20,20]} style={{width:'100%',paddingTop:20}}>
    
    <Col span={4}>

      <Skeleton.Button style={{width:'11.1vw', height:80}}  active />
      <Skeleton.Button style={{width:'11.1vw', height:80, marginTop:20}}  active />
      <Skeleton.Button style={{width:'11.1vw', height:140, marginTop:20}}  active />
      
    </Col>
    <Col span={10}><Skeleton.Button style={{width:'30vw', height:340}}  active /></Col>
    <Col span={10}><Skeleton.Button active style={{width:'30vw', height:340}} /></Col>
    </Row>
    <Row style={{paddingTop:20}}>
    <Col span={4}><Skeleton.Button active style={{width:'11.1vw', height:160}} /></Col>
    <Col span={10}><Skeleton.Button active style={{width:'61.8vw', height:160}} /></Col>

    </Row>
</>
    ), [])

    

    let googleSkeleton = useMemo(() => (
      <>
    <Row gutter={[20,20]} style={{width:'100%'}}>
    <Col span={20}>
      <Skeleton.Button style={{width:'14.5vw', height:100}}  active />
      <Skeleton.Button style={{width:'14.5vw', height:100,  marginLeft:20}}  active />
      <Skeleton.Button style={{width:'14.5vw', height:100,  marginLeft:20}}  active />
      <Skeleton.Button style={{width:'14.5vw', height:100,  marginLeft:20}}  active />
      <br/>
      <Skeleton.Button active style={{width:'62.1vw', height:160, marginTop:20}} />
      <br/>
      <Skeleton.Button active style={{width:'62.1vw', height:200, marginTop:20}} />
    </Col>
    <Col span={4}>
      <Skeleton.Button active style={{width:'12.5vw', height:100,}} />
      <br/>
      <Skeleton.Button active style={{width:'12.5vw', height:160, marginTop:20}} />
      <br/>
      <Skeleton.Button active style={{width:'12.5vw', height:200, marginTop:20}} />

    </Col>

    </Row>
</>
    ), [])

    let salesAndMarketing = useMemo(() => (
      <>
    <Row gutter={[20,20]} style={{width:'100%'}}>
    <Col span={20}>
      <Skeleton.Button active style={{width:'61.8vw', height:180}} />
      <Skeleton.Button style={{width:'30vw', height:340,marginTop:20}}  active />
      <Skeleton.Button style={{width:'30vw', height:340, marginTop:20, marginLeft:"1.8vw"}}  active />

    </Col>
    <Col span={4}><Skeleton.Button active style={{width:'11.1vw', height:80,}} /></Col>

    </Row>
</>
    ), [])



    let skeletons = {
      "google-analytics" :googleSkeleton,
      executive,
      "sales-and-marketing" : salesAndMarketing
    }

  return (
    showPreviewOnly ? <div>
       <div
        className="site-card-wrapper aboutPage"
        style={{
          background: "#fff",
          paddingLeft: 30,
          marginBottom: 15,
          fontSize: 32,
          fontWeight: "bold",
          borderRadius: 7,
        }}
      >
        <div className="coaching-sub-header" style={{ color: "#303030", paddingTop:15 }}>{reportingHeadings[type]?.heading}</div>
        <div
          className="coaching-header-description" style={{paddingBottom:15}}
        >
          {reportingHeadings?.[type]?.subHeading}
        </div>
      </div>
      <PreviewInfo img = {reporting_analytics_module_preview} text = "Upgrade to View"  centerStyle = {{top:"30%"}} />
    </div>:
    <div>
      <div
        className="site-card-wrapper aboutPage"
        style={{
          background: "#fff",
          paddingLeft: 30,
          marginBottom: 15,
          fontSize: 32,
          fontWeight: "bold",
          borderRadius: 7,
        }}
      >
        <div className="coaching-sub-header" style={{ color: "#303030", paddingTop:15 }}>{reportingHeadings[type]?.heading}</div>
        <div
          className="coaching-header-description" style={{paddingBottom:15}}
        >
          {reportingHeadings?.[type]?.subHeading}
        </div>
      </div>
      <div style={{ background: "#fff", borderRadius:7, height:'100vh', minHeight:650,marginTop:18, position:"relative" }} >
      {quicksightLoader && <div style={{padding:20, position:"absolute", top:0, width:"100%"}}>
      <div className="dashboard-loader">
                  {skeletons[type]}
                  </div>
             </div>}
           <div className='wrapperBox'>
             <div className='iframeBox'  id="quick-sight"/>
         </div>
      </div>
    </div>
  )
}

export default QuicksightDashboard