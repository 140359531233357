import { Button, Modal, Row, Select, Space, Tabs, Typography } from 'antd'
import ReportingHeader from 'pages/reports/ReportingHeader'
import React, { useEffect, useState } from 'react'
import CampaignFunnelAnalysis from './CampaignFunnelAnalysis'
import CampaignDealAnalysis from './CampaignDealAnalysis'
import "pages/reports/SalesMarketingLeadership/SalesMarketingLeadership.css"
import { useDispatch, useSelector } from "react-redux";
import {
  getConnectedCRMs,
} from "store/actions/reportAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PreviewInfo from 'commons/PreviewInfo'
import reporting_analytics_module_preview from "assets/Images/reporting_analytics_module_preview.png";
import { API } from "aws-amplify";
import { TrackClickFunction } from 'commons/HeapTrack/TrackClickFunction'
import { useParams } from "react-router-dom";

function SalesMarkeingLeadership() {
  const {content} = useParams();
  const [currentTab, setCurrentTab] = useState("campaign-funnel");
  const account = useSelector((state) => state.loginReducer.groups);
  const {userPermission, viewType} = useSelector((state) => state.loginReducer);
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");

  const permissions = {
    executive: userPermission?.['reporting.executive'],
    "google-analytics": userPermission?.['reporting.analytics'],
    "sales-and-marketing": userPermission?.['reporting.salesandmarketingleadership']
  }
  const showPreviewOnly = (permissions["sales-and-marketing"] === "preview"  && account?.[2] === "admin" && !(viewType === "companyadmin" || viewType === "companycontributor")) || (permissions["sales-and-marketing"] === "preview" && account?.[2] !== "admin");
  

    const dispatch = useDispatch()
  const history = useHistory()
  
  const [availableCRMS, setavailableCRMS] = useState([])
  const [noCRMConnected, setnoCRMConnected] = useState(false)
  const [selectedCRM, setselectedCRM] = useState()

  useEffect(() => {
    init()
    // eslint-disable-next-line 
  },[])

  const init = async () => {
    let ConnectedCRMs = await dispatch(getConnectedCRMs())
       let CRMS = []
    if(Object.keys(ConnectedCRMs)?.length){
      Object.keys(ConnectedCRMs)?.forEach(key => {
        if(["Hubspot","Salesforce"]?.includes(key)){
          CRMS.push(key)
        }
      })

    }
    setnoCRMConnected(CRMS?.length === 0)
    setavailableCRMS(CRMS)
    // Check if "Salesforce" is available in the CRMS array
    CRMS.includes("Salesforce") ?  setselectedCRM("Salesforce") : setselectedCRM(CRMS[0]);
  }

  const goToIntegrations = () => {
    if(content === 'content'){
      history.push("/settings/content")
    }else{
      history.push("/settings")
    }
  }

  const changeTab = (e) => {
    setCurrentTab(e);
  };

  const getChannelsAndCampaign = async () => {
    try {
      setskeletonLoader(true);
      let channelCampaignData = await API.get(
        "backendRest",
        `/mapping/get_channel_campaigns?account_id=${account[0]}`
      );
      
      setSelectedChannel(() => channelCampaignData?.data[0]?.channel_id);
      setSelectedCampaign(
        () => channelCampaignData?.data[0]?.campaigns[0]?.campaign_id
      );
    } catch (error) {
      console.log(error);
    } finally {
      setskeletonLoader(false);
    }
  };

  useEffect(async () => {
    await getChannelsAndCampaign();
    
  }, []);
  return (
    <>
      { !showPreviewOnly && noCRMConnected && <Modal
          className="delete-revenue-model"
          visible
          centered
          footer={null}
          width='350px'
          closable={false}
          style={{ borderRadius: "20px" }}
        >
          <Space direction="vertical" align="center">
          <div>
          <Typography.Title level={5} > No CRM Connection Found </Typography.Title>
            <Typography.Text>
            Please connect any of CRM options available at <strong>Company Settings &gt; Integrations &gt; CRM</strong> 
          <br/>
            to view Dashboard

            </Typography.Text>
            </div>
            <Button type="primary" onClick={goToIntegrations}>Connect </Button>
          </Space>
        </Modal>}
    <ReportingHeader type="sales-and-marketing" /> 
      {showPreviewOnly ?
      <PreviewInfo img = {reporting_analytics_module_preview} text = "Upgrade to View"  centerStyle = {{top:"30%"}} /> : 
        <div>
        <Tabs
          className="executive-tabs"
          activeKey={currentTab}
          onChange={changeTab}
          tabBarExtraContent={<Row gutter={[10,10]}  justify="end" align="center">

          {availableCRMS?.length > 1 ? 
          <>
          <div style={{display:"flex", alignItems:"center",gap:10}} >
          <Typography.Text> Data Source : </Typography.Text>
  
          <Select placeholder="Please Select Data Source" style={{width:120}} value={selectedCRM} onChange={setselectedCRM}>
            {availableCRMS?.map(item => {
              return <Select.Option value={item} >{item}</Select.Option>
            })}
          </Select>
          </div>
          </>
          :
          <Typography.Text> Data Source : <strong>{selectedCRM}</strong> </Typography.Text>
          }
          </Row>}
        >
          <Tabs.TabPane key="campaign-funnel" tab={<span style={{fontSize: "16px"}} onClick={() => {
            TrackClickFunction('sales-marketing-dashboard-campaign-funnel-analysis-menu')
          }}>Campaign Funnel Analysis</span>}>
            {currentTab === "campaign-funnel" && <CampaignFunnelAnalysis CRM={selectedCRM}/>}
          </Tabs.TabPane>
          <Tabs.TabPane key="campaign-deal" tab={<span style={{fontSize: "16px"}} onClick={() => {
            TrackClickFunction('sales-marketing-dashboard-campaign-deal-analysis-menu')
          }}>Campaign Deal Analysis</span>}>
          {currentTab === "campaign-deal" && selectedChannel && selectedCampaign && <CampaignDealAnalysis CRM={selectedCRM} selectedChannel={selectedChannel} selectedCampaign={selectedCampaign}/>}

          </Tabs.TabPane>
        </Tabs>
      </div>}
    </>
  )
}

export default SalesMarkeingLeadership