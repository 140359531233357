//LoginActions
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_LOGIN = 'UPDATE_LOGIN';
export const UPDATE_COMPANY_ACCOUNT = 'UPDATE_COMPANY_ACCOUNT';
export const SET_ACCOUNT_DETAILS = 'SET_ACCOUNT_DETAILS';
export const SET_PERMISSION = 'SET_PERMISSION';
export const SET_VIEW_TYPE = 'SET_VIEW_TYPE';
export const SET_ACCOUNT_REQUESTS = "SET_ACCOUNT_REQUESTS"
export const SET_DASHBOARD_TOUR_OPEN = "SET_DASHBOARD_TOUR_OPEN"

//RegisterActions

export const SIGNUP = "SIGNUP";
export const ROLES = "ROLES";


// REVENUE ACTIONS

export const UPDATE_PLOT_DATA_LIST = "UPDATE_PLOT_DATA_LIST";
export const ADD_PLOT_DATA_ITEM = "ADD_PLOT_DATA_ITEM";
export const REMOVE_PLOT_DATA_ITEM = "REMOVE_PLOT_DATA_ITEM";
export const UPDATE_METRICS_CALCULATION = "UPDATE_METRICS_CALCULATION";
export const SET_BASELINE_DATA = "SET_BASELINE_DATA";
export const SET_BASELINE_LIST_DATA = "SET_BASELINE_LIST_DATA";
export const SET_FUNNEL_CONFIG = "SET_FUNNEL_CONFIG";
export const SET_FUNNEL_STAGES = "SET_FUNNEL_STAGES";
export const SET_FUNNEL_STAGES_LIST = 'SET_FUNNEL_STAGES_LIST';
export const DELETE_STAGE_DATA = 'DELETE_STAGE_DATA';
export const SET_PLANNING_PERIOD = 'SET_PLANNING_PERIOD';
export const SET_YEAR_LIST = 'SET_YEAR_LIST';
export const SET_FISCAL_YEAR_LIST = "SET_FISCAL_YEAR_LIST";
export const SET_FISCAL_YEAR = "SET_FISCAL_YEAR"
export const SET_TOTAL_MARKETING_SOURCE_REVENUE = "SET_TOTAL_MARKETING_SOURCE_REVENUE"
export const SET_ACCOUNT_MASTER_STAGES = "SET_ACCOUNT_MASTER_STAGES"
export const TOTAL_PIPELINE_COVERAGE = "TOTAL_PIPELINE_COVERAGE"

//BUDGET ACTIONS

export const SET_CREATED_BUDGET_DATA = "SET_CREATED_BUDGET_DATA";
export const SET_BUDGET_LIST_DATA = "SET_BUDGET_LIST_DATA";
export const SET_CHANNEL_LIST_DATA = "SET_CHANNEL_LIST_DATA";
export const SET_BUDGET_DATA = "SET_BUDGET_DATA";
export const SET_PROSPECT_DATA = "SET_PROSPECT_DATA";
export const SET_CAMPAIGN_LIST_DATA = "SET_CAMPAIGN_LIST_DATA";
export const SET_CAMPAIGN_CHANNEL_PLANNED = "SET_CAMPAIGN_CHANNEL_PLANNED";
export const SET_TOTAL_SPEND = "SET_TOTAL_SPEND";
export const SHOW_LOADER = "SHOW_LOADER";
export const SHOW_CHECK_LOADER = "SHOW_CHECK_LOADER";
export const SET_CREATED_CAMPAIGN_DATA = "SET_CREATED_CAMPAIGN_DATA";
export const SET_VIEW_TOTAL = "SET_VIEW_TOTAL";
export const SET_CREATED_CHANNEL_DATA = "SET_CREATED_CHANNEL_DATA";
export const SERVER_ERROR = "SERVER_ERROR";
export const SET_BUDGET_INFO_DATA = "SET_BUDGET_INFO_DATA";
export const SET_ORIGINAL_CRM_SOURCE = "SET_ORIGINAL_CRM_SOURCE";
export const SET_ORIGINAL_CRM_SOURCE_DD1 = "SET_ORIGINAL_CRM_SOURCE_DD1";
export const SET_ORIGINAL_CRM_SOURCE_DD2 = "SET_ORIGINAL_CRM_SOURCE_DD2";
export const SET_CAMPAIGN_ACTUALS = "SET_CAMPAIGN_ACTUALS";
export const SET_CAMPAIGN_ACTUALS_AMOUNT = "SET_CAMPAIGN_ACTUALS_AMOUNT";

//COACHING ACTIONS
export const SET_CREATED_COURSE_CATEGORY_DATA = "SET_CREATED_COURSE_CATEGORY_DATA"
export const SET_CREATED_COURSE_DATA = "SET_CREATED_COURSE_DATA"
export const SET_CREATED_COURSE_CATEGORY_CONNECTION_DATA = "SET_CREATED_COURSE_CATEGORY_CONNECTION_DATA"
export const SET_COURSE_LIST = "SET_COURSE_LIST"
export const SET_COURSE_CATEGORY_LIST = "SET_COURSE_CATEGORY_LIST"
export const SET_CATEGORY_COURSE_LIST = "SET_CATEGORY_COURSE_LIST"

//REPORT ACTIONS
export const SET_ACTUAL_REPORT_DATA = "SET_ACTUAL_REPORT_DATA"
export const SET_ACTUAL_REPORT_WEB_DATA = "SET_ACTUAL_REPORT_WEB_DATA"
export const SET_ACTUAL_REPORT_FUNNEL_DATA = "SET_ACTUAL_REPORT_FUNNEL_DATA"
export const SET_ACTUAL_REPORT_DATA_YEARLY = "SET_ACTUAL_REPORT_DATA_YEARLY"
export const SET_INVITATION_SENT = "SET_INVITATION_SENT"
export const SET_CONFIGURED_CHART_INFO = "SET_CONFIGURED_CHART_INFO"
export const SET_MAPPING_STAGES = "SET_MAPPING_STAGES"
export const SET_ACCOUNT_STAGE_MAPPING_DATA = "SET_ACCOUNT_STAGE_MAPPING_DATA"
export const SET_ACTUAL_REPORT_DATA_OTHERS = "SET_ACTUAL_REPORT_DATA_OTHERS"
export const SET_FUNNEL_ACTUAL_DATA_LOADER = "SET_FUNNEL_ACTUAL_DATA_LOADER"
export const SET_LEADS_DRAWER = "SET_LEADS_DRAWER"
export const SET_LEADS_DATA = "SET_LEADS_DATA"
export const SET_ACTUAL_REPORT_FUNNEL_TRENDS_DATA = "SET_ACTUAL_REPORT_FUNNEL_TRENDS_DATA"
export const SET_PACKAGE_PROPERTY = "SET_PACKAGE_PROPERTY"
export const SET_USER_PACKAGES = "SET_USER_PACKAGES"
export const SET_SALES_AND_MARKETING_ACTUALS = "SET_SALES_AND_MARKETING_ACTUALS"
export const SET_SALES_MAREKETING_LEADERSHIP_DATA = "SET_SALES_MAREKETING_LEADERSHIP_DATA"
export const SET_SALES_MARKETING_STAGES = "SET_SALES_MARKETING_STAGES"
export const SET_EXECUTIVE_REPORTS_DATA = "SET_EXECUTIVE_REPORTS_DATA"
export const SET_CONNECTED_CRMS = "SET_CONNECTED_CRMS"

// GO TO MARKET ACTIONS
export const SET_COMPANY_POSITIONING = "SET_COMPANY_POSITIONING"
export const SET_SOCIAL_CHANNELS = "SET_SOCIAL_CHANNELS"
export const SET_CURRENT_MARKET_ACTIVITY  = "SET_CURRENT_MARKET_ACTIVITY"
export const SET_MARKETING_AUTOMATION_SYSTEM = "SET_MARKETING_AUTOMATION_SYSTEM"
export const SET_CURRENT_MARKET_TOOLS = "SET_CURRENT_MARKET_TOOLS"
export const SET_TARGET_IDEAL_CUSTOM_PROFILE = "SET_TARGET_IDEAL_CUSTOM_PROFILE"
export const SET_PERSONAS = "SET_PERSONAS"
export const SET_ASSETS_LIBRARY = "SET_ASSETS_LIBRARY"

// SALES AND MARKETING ACTIONS
export const SET_SALES_AND_MARKETING_STAGES = "SET_SALES_AND_MARKETING_STAGES"

// SDR
export const SET_SDR_COMPENSATION_MODELS  = "SET_SDR_COMPENSATION_MODELS"
export const SET_CALCULATOR_INFO = "SET_CALCULATOR_INFO"
export const SET_SDR_CALCULATOR_SUMMARY = "SET_SDR_CALCULATOR_SUMMARY"
export const SET_SDR_ACTIVITY_TRACKER_DATA = "SET_SDR_ACTIVITY_TRACKER_DATA"
export const SET_SDR_ACTUAL_DATA = "SET_SDR_ACTUAL_DATA"
