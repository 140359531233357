import React from "react";
import { Button } from "antd";
import paid_feature from "assets/Images/paid_feature.svg";
import { useHistory } from "react-router-dom";

const PreviewInfo = (props) => {
  const history = useHistory();
  return (
    <div class="container">
      <img style={{ width: "100%" }} src={props.img} alt="" />
      <div class="centered" style = {props?.centerStyle}>
        <img src={paid_feature} alt="" />
        <div className="preview-info">{props?.text}</div>
        <div className="preview-info-buttons">
          <div style={{ paddingRight: 5 }}>
            <Button
              type="primary"
              onClick={() =>
                history.push("/settings")
              }
              style = {{width:150, fontSize:16}}
            >
              Upgrade Plan
            </Button>
          </div>
          <div style={{ paddingLeft: 5 }}>
            <Button
              type="secondary"
              onClick={() =>
                window.open("https://4amdemand.com/platform/", "_blank")
              }
              className="learn-more-btn"
              style = {{width:150, fontSize:16}}
            >
              Learn More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewInfo;
