/**
 * This file handles the lambda response for the custom auth flow.
 */
import React, { Component } from 'react';
import { Card, Col, Row } from 'antd';
import backgroundImg from 'assets/Images/login_signup_background.svg';
import { Auth } from 'aws-amplify';
import Logo from 'commons/Logo';
import { connect } from 'react-redux';
import { LoadingOutlined, ToolOutlined } from '@ant-design/icons';

class XPLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sso: false,
      msg: '',
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const challenge = urlParams.get('challenge');
    if (email && challenge) {
      try {
        this.doAuthChallenge(email, challenge);
      } catch (error) {
        console.log('Challenge error: ', error);
        this.setState({ sso: false, error: error.message });
      }
    }
  }

  async doAuthChallenge(email, challenge) {
    this.setState({ sso: true });
    const user = await Auth.signIn(email);
    localStorage.setItem(
      'cognitoUser',
      JSON.stringify({
        username: email,
        session: user.Session,
      })
    );
    console.log('user: ', user);

    const session = await Auth.sendCustomChallengeAnswer(user, challenge);
    for (let [key, value] in session.storage) {
      localStorage.setItem(key, value);
    }
    console.log('session: ', session);

    user.setSignInUserSession(await Auth.currentSession());
    Auth.currentAuthenticatedUser()
      .then((user) => console.log('Authenticated user:', user))
      .catch((err) => console.log(err));

    try {
      this.props.updateLogin(
        session.signInUserSession.accessToken,
        session.signInUserSession,
        {
          accessToken: session.signInUserSession.accessToken,
          username:
            session.signInUserSession.idToken.payload['cognito:username'],
          email: session.signInUserSession.idToken.payload.email,
        }
      );
    } catch (error) {
      this.setState({ msg: error.message });
      console.log('Error: ', error);
    }
  }

  render() {
    return (
      <Row>
        <Col xs={1} md={1} lg={1} style={{ height: '100%' }}>
          <img
            src={backgroundImg}
            alt="background"
            style={{ height: '100vh', minHeight: 580, position: 'absolute' }}
          />
          <Card
            className="card-container"
            style={{ border: 'none', width: '437px', padding: '0px' }}
          >
            <div style={{ marginBottom: 35 }}>
              <Logo />
            </div>
            {this.state.sso && !this.state.msg && (
              <Card className="card-container user-login-card">
                <div style={{ marginBottom: 35 }}>
                  <Logo />
                </div>
                <h3>Logging you in to Demand Gen.</h3>
                <LoadingOutlined spin />
              </Card>
            )}
            {this.state.msg && (
              <Card className="card-container user-login-card">
                <h3>We had a glitch...</h3>
                <p>
                  <ToolOutlined />
                  Please contact{' '}
                  <a href="mailto:support@xfactor.io">support@xfactor.io</a> or
                  try again.
                </p>
              </Card>
            )}
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    prop: state.prop,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateLogin: (token, userData) =>
      dispatch({
        type: 'UPDATE_LOGIN',
        payload: {
          accessToken: userData.accessToken,
        },
        data: userData,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(XPLogin);
