import { Table, Space, Input, Button, Form, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import sdk from "sdk/Accounts";

const PropertySettings = () => {
  const [properties, setProperties] = useState([]);
  const [SearchParams, setSearchParams] = useState({});
  const [propertyBackup, setPropertiesBackup] = useState({});
  const [loading, setLoading] = useState(false);

  const getProperties = async () => {
    setLoading(true);
    let listProperty = await sdk.listDefaultProperties();
    setLoading(false);
    setProperties(listProperty);
    setPropertiesBackup(listProperty);
    if (SearchParams) searchProperties(SearchParams);
  };
  useEffect(() => {
    getProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handle_search = (value) => {
    setProperties(propertyBackup);
    setSearchParams({
      Value: value["search_value"] ? value["search_value"].toLowerCase() : "",
    });
  };

  useEffect(() => {
    searchProperties(SearchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchParams]);

  const searchProperties = (SearchParams) => {
    if (SearchParams.Value) {
      setProperties(
        properties.filter((property) =>
          property.name
            .toLowerCase()
            .includes(SearchParams.Value.toLowerCase().trim())
        )
      );
    }
  };

  const columns = [
    {
      title: "Name",
      width:"80%",
      dataIndex: "name",
      key: "name",
      render: (val) => <small>{val}</small>,
      sorter: (a, b) => {
        return a?.name?.localeCompare(b?.name);
      },
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "Value",
      width:"20%",
      dataIndex: "value",
      key: "value",
      render: (val) => {
        if (val === true) return "true";
        else if (val === false) return "false";
        else return val;
      },
    },
  ];

  return (
    <>
      <div className="user-container packageListBox" style={{marginTop:'20px'}}>
        {/* {loading && <div className="loading">Loading;</div>} */}
        <Space direction="vertical" style={{ width: "100%", justifyContent:'center' }} size={1}>
          {/* <Typography.Title level={5}>Property Management</Typography.Title> */}

          <Form
            name="search_users"
            onFinish={handle_search}
            style={{paddingLeft:'10%', paddingRight:"10%", display:'flex', alignContent:'center', justifyContent:'center'}}
            // initialValues={{ search_type: "Email" }}
          >
            <Space direction="horizontal" style={{ padding: "0px 5px" }}>
              <Form.Item name="search_value">
                <Input placeholder={`Enter Name`} size="large" className="general-input" style={{width:'300px'}}/>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  className="primaryButton"
                  style={{width:'150px'}}
                >
                  Search
                </Button>
              </Form.Item>
            </Space>
          </Form>

          <div className="table-wrapper">
            {loading ? <Skeleton active style={{paddingLeft:'10%', paddingRight:"10%"}} paragraph={{rows:5}} /> : 
            <Table
              bordered
              rowKey={(record) => record?.name}
              size="middle"
              dataSource={properties}
              columns={columns}
              style={{paddingLeft:'20%', paddingRight:"20%"}}
              className="general-table default-property-table"
            ></Table>
          }
          </div>
        </Space>
      </div>
    </>
  );
};

export default PropertySettings;
