import Amplify, { Auth } from "aws-amplify";
import "./App.less";
import config from "./aws-exports";
import Routes from "Routers/Routes";
import './responsive.css'

const updatedAwsConfig = {
  ...config,
  // Set API to use id token. This will ensure email is passed within the token
  API: {
    graphql_headers: async () => ({
      Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    }),
  },
};

Amplify.configure(updatedAwsConfig);



function App() {
  
  return <Routes />;
}

export default App;
