/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  message,
  Select,
  Skeleton,
  Radio,
} from "antd";
import { API, Auth } from "aws-amplify";
import { CountryList } from "utility/Constants";
import { useDispatch, useSelector } from "react-redux";
import { getaccountDetails, updateAccount } from "store/actions/loginAction";
import { Loading3QuartersOutlined } from "@ant-design/icons";

export default function UserSettings() {
  const [form] = Form.useForm();
  const [profileForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();

  const dispatch = useDispatch();

  const {
    accountDetails,
    userData,
    groups: account,
  } = useSelector((state) => state.loginReducer);

  const getCurrentUserInfo = async () => {
    const currentUserVal = await Auth.currentUserInfo();
    setEmail(currentUserVal?.attributes?.email);
    profileForm.setFieldsValue(currentUserVal?.attributes);
  };

  useEffect(() => {
    getCurrentUserInfo();
  }, []);

  const handleUpdateProfile = (value) => {
    let init = {
      body: {
        ...value,
      },
    };
    API.post("exploricsREST", `/user/update`, init)
      .then((data) => {
        message.success("Profile Updated");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePassword = async () => {
    if (newPassword?.length < 8) {
      message.error("New password should be more than 8 characters");
    } else {
      try {
        form
          .validateFields()
          .then((data) => {
            setLoading(true);
            Auth.currentAuthenticatedUser()
              .then((user) => {
                return Auth.changePassword(user, oldPassword, newPassword);
              })
              .then((data) => {
                message.success("Password changed successfully");
              })
              .catch((err) => {
                console.log("Error : ", err);
                message.error(err?.message);
              })
              .then((data) => {
                form.resetFields();
                setLoading(false);
              });
          })
          .catch((err) => console.log("Validation Error", err));
      } catch {
        return;
      }
    }
  };

  // LEADS PREFERENECE SETTINGS
  const [autoUpdateLeads, setautoUpdateLeads] = useState(false);
  const [accountMetadata, setaccountMetadata] = useState({});
  const [preferenceLoader, setpreferenceLoader] = useState(false);

  useEffect(() => {
    let metadata = accountDetails?.metadata
      ? JSON.parse(accountDetails?.metadata)?.[userData.sub]
      : {};
    if (!metadata) metadata = {};
    if (!metadata?.["preferences"]) {
      metadata["preferences"] = {};
      metadata["preferences"][userData.sub] = {};
    }
    accountDetails?.metadata && setaccountMetadata(metadata);
    setautoUpdateLeads(
      metadata?.["preferences"]?.autoUpdateLeads
        ? metadata?.["preferences"]?.autoUpdateLeads
        : false
    );
  }, [accountDetails, userData]);

  const handleChangeLeadsPreference = async (e) => {
    setpreferenceLoader(true);
    setautoUpdateLeads(e.target.value);
    let data = { ...accountMetadata };
    data["preferences"] = { autoUpdateLeads: e.target.value };
    setaccountMetadata(data);
    let metadata = { ...JSON.parse(accountDetails?.metadata) };
    metadata[userData.sub] = data;
    let inputData = {
      id: account[0],
      metadata: JSON.stringify(metadata),
    };
    await dispatch(updateAccount(inputData));
    await dispatch(getaccountDetails(account[0])).then((res) => {
      setpreferenceLoader(false);
    });
  };

  return (
    <>
      <div
        className="userSettings panel-design"
        style={{
          background: "white",
          borderRadius: "7px",
          marginBottom: 30,

          padding: "20px 15px",
        }}
      >
        <Row>
          <Col span={24} lg={24} md={24} sm={24} xs={24}>
            <Typography.Title
              level={4}
              style={{
                marginBottom: "30px",
                fontSize: "18px",
                background: "#f5f7fa",
                padding: "10px 5px",
                textAlign: "center",
                fontWeight:500
              }}
              className="titleText fw500 panel-design"
            >
              Your Profile
            </Typography.Title>
            {!email?.length > 0 ? (
              <>
                <Skeleton active paragraph={{ rows: 5 }} />{" "}
                <Skeleton.Button active style={{ marginTop: 10 }} />{" "}
              </>
            ) : (
              <Form
                name="basic"
                layout="vertical"
                autoComplete="off"
                form={profileForm}
                onFinish={(value) => {
                  handleUpdateProfile(value);
                }}
                className="general-form"
              >
                <Form.Item
                  name="email"
                  label={
                    <small>
                      <strong>Email ID</strong>
                    </small>
                  }
                >
                  <Input disabled className="general-input"/>
                </Form.Item>
                <Row justify="space-between" gutter={16}>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      name="given_name"
                      label={
                        <small>
                          <strong>First Name</strong>
                        </small>
                      }
                    >
                      <Input
                        
                        size="large"
                        placeholder="First Name"
                        className="general-input"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      name="family_name"
                      label={
                        <small>
                          <strong>Last Name</strong>
                        </small>
                      }
                    >
                      <Input
                        
                        size="large"
                        placeholder="Last Name"
                        className="general-input"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  label={
                    <small>
                      <strong>Country</strong>
                    </small>
                  }
                  name="custom:country"
                >
                  <Select
                    placeholder="Select country"
                    showSearch
                    size="large"
                    
                    className="general-input-select"
                  >
                    {CountryList &&
                      CountryList.map((country) => {
                        return (
                          <Select.Option
                            value={country.name}
                            key={country.code}
                          >
                            {country.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={
                    <small>
                      <strong>Job Role</strong>
                    </small>
                  }
                  name="custom:job_function"
                >
                  <Select
                    placeholder="Select your job role"
                    showSearch
                    size="large"
                    
                    className="general-input-select"
                  >
                    {["Marketing", "Sales", "IT", "Finance", "Other"].map(
                      (item) => {
                        return (
                          <Select.Option value={item} key={item}>
                            {item}
                          </Select.Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={
                    <small>
                      <strong>Job Level</strong>
                    </small>
                  }
                  name="custom:job_level"
                >
                  <Select
                    placeholder="Select your job Level"
                    showSearch
                    size="large"
                    className="general-input-select"
                  >
                    {[
                      "Executive/VP",
                      "Director",
                      "Manager",
                      "Individual Contributor",
                    ].map((item) => {
                      return (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Col span={8} lg={8} md={8} sm={24} xs={24}>
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={loading}
                    block
                    className="primaryButton"
                  >
                    Update Profile
                  </Button>
                </Col>
              </Form>
            )}
          </Col>
        </Row>
      </div>

      <div
        className="userSettings panel-design"
        style={{
          background: "white",
          borderRadius: "7px",
          marginTop: 20,
          padding: 18,
        }}
      >
        <Row>
          <Col span={24} lg={24} md={24} sm={24} xs={24}>
            <Typography.Title style={{
                marginBottom: "30px",
                fontSize: "18px",
                background: "#f5f7fa",
                padding: "10px 5px",
                textAlign: "center",
                fontWeight:500
              }} level={4} className="titleText fw500 panel-design">Change Password</Typography.Title>
            {!email?.length > 0 ? (
              <>
                <Skeleton active paragraph={{ rows: 3 }} />{" "}
                <Skeleton.Button active style={{ marginTop: 10 }} />{" "}
              </>
            ) : (
              <Form
                name="basic"
                layout="vertical"
                onSubmitCapture={handleChangePassword}
                autoComplete="off"
                form={form}
                requiredMark={false}
                className="general-form"
              >
                <Form.Item
                  name="OldPassword"
                  label={
                    <small>
                      <strong>Old password</strong>
                    </small>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Old password",
                    },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => setOldPassword(e.target.value)}
                    placeholder="Old password"
                    className="general-input"
                  />
                </Form.Item>

                <Form.Item
                  name="NewPassword"
                  label={
                    <small>
                      <strong>New password</strong>
                    </small>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please Enter New password!",
                    },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="New password"
                    className="general-input"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={loading}
                    className="primaryButton"
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Col>
        </Row>
      </div>

      <div
        className="userSettings panel-design"
        style={{
          background: "white",
          borderRadius: "7px",
          marginBottom: 30,
          marginTop: 20,
          padding: 18,
        }}
      >
        <Row style={{width:'100%'}}>
          <Typography.Title style={{
                marginBottom: "30px",
                fontSize: "18px",
                background: "#f5f7fa",
                padding: "10px 5px",
                textAlign: "center",
                width:'100%',
                fontWeight:500
              }} level={4} className="titleText fw500 panel-design">
            Leads Preference{" "}
            {preferenceLoader && (
              <Loading3QuartersOutlined spin style={{ marginLeft: 10 }} />
            )}
          </Typography.Title>
          <Typography.Text style={{color:"#1D2939", fontSize:'16px', lineHeight:'25px'}}>
            When modifying Budgets directly on the Campaign Planning table,
            would you like to also automatically modify Leads, based on your
            projected CPL for the campaign?
          </Typography.Text>

          <Radio.Group
            onChange={handleChangeLeadsPreference}
            value={autoUpdateLeads}
            style={{ marginTop: 10 }}
            disabled={preferenceLoader}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Row>
      </div>
    </>
  );
}
