import React from "react";
import { Card, } from "antd";
import { useHistory } from "react-router-dom";
import TotalBudgetImg from "assets/Images/total_budget.svg";
import TotalLeadsImg from "assets/Images/total_leads.svg";
import TotalRevenueImg from "assets/Images/total_revenue.svg";
import {TrackClickFunction} from '../../commons/HeapTrack/TrackClickFunction'


const Steps = (props) => {
  const history = useHistory();
  const handleFirstRevenuGoal = () => {
    
    if(props.isContent){
      history.push("/revenue-planning/content");
    }else{
      history.push("revenue-planning");
    }
  };
  const handleFirstCampaign = () => {
    
    if(props.isContent){
      history.push("/budget-campaigns/content");
    }else{
      history.push("budget-campaigns");
    }
  };

  const defineStratergyHandle = (customemEvent) => {
    TrackClickFunction(customemEvent);
    if(props.isContent){
      history.push("/go-to-market/content")
    }else{
    history.push("go-to-market")
    }
  }

  const marketingAlignmentHandle = (customemEvent) => {
    TrackClickFunction(customemEvent);
    if(props.isContent){
      history.push("/sales-and-marketing/content")
    }else{
      history.push("sales-and-marketing")
    }
    
  }

  return (
    <div className="site-card-wrapper" style={{marginBottom:'20px'}}>
      <div className="responsive-grid" style={{display: 'flex', gap:'10px'}}>

      <div style={{ flex:1}}>
          <Card bordered={false} style={{ borderRadius:7, height:'100%' }} className="panel-design">
              <div className="float-container">
                {/* <div className="float-child " style={{ marginTop: 6 }}>
                  <div className="circle"><strong>1</strong></div>
                </div> */}
                <div className="float-child">
                  <div className="dashboard-step-header titleText">
                    Define your  <br/>Go to Market strategy
                  </div>


                  <div className="dashboard-link" style={{marginTop:"10px"}} onClick={() => defineStratergyHandle('demand-gen-define-gtm-hyperlink')}>
                    Click Here
                  </div>
                </div>
              </div>
          </Card>
        </div>

      {/* {((props.userPermission?.['salesMarketing'] && props.userPermission?.['salesMarketing'] !== "hidden") || (props.accoun?.[1] === "admin" && props.viewType !== "user"))  &&  */}
        <div style={{ flex:1}}>
          <Card bordered={false} style={{borderRadius:7, height:'100%' }} className="panel-design">


              <div className="float-container">
                {/* <div className="float-child " style={{ marginTop: 6 }}>
                  <div className="circle"><strong>2</strong></div>
                </div> */}
                <div className="float-child">
                  <div className="dashboard-step-header titleText">
                    Review Sales & <br/>Marketing Alignment
                  </div>


                  <div className="dashboard-link" style={{marginTop:"10px"}} onClick={() =>marketingAlignmentHandle('demand-gen-review-alignment-hyperlink') }>
                    Click Here
                  </div>
                </div>
              </div>

              {/* <div className="float-container" style={{display: 'flex' , alignItems: 'center'}}>
                <div className="float-child " style={{ marginTop: 0 }}>
                  <div>
                    <img src={salesMarketingImg} alt="" height="51px" />
                  </div>
                </div>
                <div className="float-child" style={{ marginLeft: 15 }}>
                  <div className="total-planned-title">
                    Setup Sales & Marketing<br/> Alignments
                  </div>
                  <div className="dashboard-link" style={{marginTop:"5px"}} onClick={() =>{history.push("sales-and-marketing");}}>
                    Click Here
                  </div>
                </div>
              </div> */}
          </Card>
        </div>
        {/* } */}
        
        {/* {((props.userPermission?.["revenuePlanning"] && props.userPermission?.["revenuePlanning"] !== "hidden" && props.userPermission?.["revenuePlanning"] !== "preview") || (props.account?.[2] === "admin" && props.viewType !== "user")) &&  */}
        <div style={{ flex:1}}>
          <Card bordered={false} style={{ borderRadius:7, height:'100%' }} className="panel-design">
            {props.marketingSourcedRevenue === 0 ? 
            <div className="float-container">
              {/* <div className="float-child " style={{ marginTop: 0}}>
                <div className="circle"><strong>3</strong></div>
              </div> */}
              <div className="float-child">
                <div className="dashboard-step-header titleText">Set Revenue goal</div>
                <div className="dashboard-link" onClick={handleFirstRevenuGoal} style={{marginTop:"10px"}}>
                  Click Here
                </div>
              </div>
            </div>
            :
            <div className="float-container">
              {/* <div className="float-child " style={{marginTop: 5 }}>
                <div ><img src = {TotalRevenueImg} alt = "" /></div>
              </div> */}
              <div className="float-child">
                <div className="total-planned-title titleText">Total net revenue goal</div>
                <div className="total-planned">
                  ${parseInt(props.marketingSourcedRevenue).toLocaleString()}
                </div>
              </div>
            </div>}
          </Card>
        </div>
        {/* } */}
        
        {/* {((props.userPermission?.["budgetCampaign"] && props.userPermission?.["budgetCampaign"] !== "hidden" && props.userPermission?.["budgetCampaign"] !== "preview") || (props.account?.[2] === "admin" && props.viewType !== "user")) &&  */}
        <div  style = {{ flex:1}}>
          <Card bordered={false} style={{ borderRadius:7, height:'100%' }} className="panel-design">
            {!props.budgetPlanned || props.isEmpty ? (
              <div className="float-container">
                {/* <div className="float-child " style={{ marginTop: 0}}>
                  <div className="circle"><strong>4</strong></div>
                </div> */}
                <div className="float-child" >
                  <div className="dashboard-step-header">
                    <strong>
                    Plan your first budget
                    </strong>
                  </div>
                  <div
                    className="dashboard-link"
                    onClick={handleFirstCampaign}
                    style={{marginTop:"5px"}}
                  >
                    Click Here
                  </div>
                </div>
              </div>
            ) : (
              <div className="float-container">
                {/* <div className="float-child " style={{ marginTop: 5 }}>
                  <div>
                    <img src={TotalBudgetImg} alt="" />
                  </div>
                </div> */}
                <div className="float-child" >
                  <div className="total-planned-title">
                    Total budget planned
                  </div>
                  <div className="total-planned">
                    ${props.budgetPlanned.toLocaleString()}
                  </div>
                </div>
              </div>
            )}
          </Card>
        </div>
        {/* } */}

        {/* {((props.userPermission?.["budgetCampaign"] && props.userPermission?.["budgetCampaign"] !== "hidden" && props.userPermission?.["budgetCampaign"] !== "preview") || (props.account?.[2] === "admin" && props.viewType !== "user")) &&  */}
        <div style={{ flex:1}}>
          <Card bordered={false} style={{ borderRadius:7, height:'100%' }} className="panel-design">
            {!props?.leadsPlanned ? (
              <div className="float-container" style={{padding: 2}}>
                {/* <div className="float-child " style={{ marginTop: 0}}>
                  <div className="circle"><strong>5</strong></div>
                </div> */}
                <div className="float-child" >
                  <div className="dashboard-step-header">
                    <strong>
                    Setup your first <br/>campaign</strong>
                  </div>
                  <div className="dashboard-link" onClick={handleFirstCampaign} style={{marginTop:"5px"}}>
                    Click Here
                  </div>
                </div>
              </div>
            ) : (
              <div className="float-container">
                {/* <div className="float-child " style={{ marginTop: 5 }}>
                  <div>
                    <img src={TotalLeadsImg} alt="" />
                  </div>
                </div> */}
                <div className="float-child" >
                  <div className="total-planned-title">
                    Total leads planned
                  </div>
                  <div className="total-planned">
                    {props.leadsPlanned.toLocaleString()}
                  </div>
                </div>
              </div>
            )}
          </Card>
        </div>
        {/* } */}
      </div>
    </div>
  );
};

export default Steps;
