import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, Select, Form, message, Skeleton } from "antd";
import invite from "assets/Images/invite.svg";
import sdk from "sdk/Accounts";
import Auth from "@aws-amplify/auth";
import { Col, Row } from "antd";
import { emailPattern } from "utility/Constants";

const Invite = (props) => {
  const {isLoading} = props
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [currentUserEmail, setcurrentUserEmail] = useState("");
  const { groups:account, selectedCompanyName} = useSelector((state) => state.loginReducer);

  
  useEffect(() => {
    Auth.currentUserInfo()
      .then((result) => setcurrentUserEmail(result?.attributes?.email))
  }, [])

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    form.resetFields()
    setIsModalVisible(false);
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const formatedValues = {
          ...values,
        };
        handleValidForm(formatedValues);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleValidForm = async (value) => {
    setLoading(true);
    value.account_id = account?.[0]
    if(selectedCompanyName)
      value.name = selectedCompanyName
    let result = await sdk.sendEmailInvitation(value);

    if (result.error) {
      message.error(result.error, 5);
      setLoading(false);
    } else {
      setLoading(false);
      form.resetFields();
      handleCancel();
      message.success(`Invitation sent successfully.`, 5);
      return;
    }
  };
  const validateEmail = (e) => {
    if (e.length > 0) {
      const found = e[e.length - 1].match(emailPattern)
      if (!found) {
        e.pop()
        message.error("Please enter valid email")
      }
      else {
        e[e.length - 1] = e[e.length - 1].toLowerCase()
      }
    }
    if (currentUserEmail && currentUserEmail === e[e.length - 1]) {
      message.error("Please enter different email.")
      e.pop()
    }
  }
  return (
    <div
      className="float-container invite panel-design"
      style={{ height: "100%", padding: '20px 15px', background: "#fff",borderRadius:7, display:'flex', alignItems:"center" }}
    >
     { isLoading ? 
     <Skeleton active />
     : 
      <>
      <div
        className={`${
          props.isEmpty ? "invite-width-empty" : "invite-width-filled"
        } float-child`}
      >
        
          <Row>
          {/* <Col span={8} md={8} lg={8} xs={24} sm={24}>
              <div
              style={{marginTop: 10, padding:10}}
              className={`${
              props.isEmpty ? "invite-btn-empty" : "invite-btn-filled"
              }`}
              >
                {!props.isEmpty && (
                  <div className="float-child" style={{width: "100%"}}>
                    <div className="dashboard-step-header">
                      <img
                        src={invite}
                        alt=""
                        style={{ height: 100, width: "100%" }}
                      />
                    </div>
                  </div>
                )}
             </div>
            </Col> */}
            <Col span={24} md={24} lg={24} xs={24} sm={24} >
              <div className="textCenter" style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column"}}>
                <div className="dashboard-card-header titleText textCenter" style={{marginBottom:'10px'}}>Invite your team members</div>
                 <div className="dashboard-card-sub-header bodyText textCenter" >
                Track the progress of your campaigns together with your colleagues
                </div>
                  <Button
                  className={`primaryButton ${
                    props.isEmpty
                      ? "invite-btn-empty-width"
                      : "invite-btn-filled-width"
                  } invitatin-btn`}
                  type="primary"
                  onClick={showModal}
                  style={{marginTop:'15px'}}
                  >
                  Send Invitation
                  </Button>
              </div>
            </Col>

          </Row>
        
      </div>
      {props.isEmpty && (
        <div className="float-child" style={{ width: "30%" }}>
          <div className="dashboard-step-header">
            <img src={invite} alt="" style={{ height: 160, width: "100%" }} />
          </div>
        </div>
      )}
      </>
}

      <Modal
        title="Add email address to send invitation"
        visible={isModalVisible}
        className="invite-dialogue"
        footer={null}
        closable={false}
      >
        {loading && <div className="loading">Loading;</div>}
        <Form form={form}>
          <Form.Item
            name="emails"
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <Select
              placeholder=" Enter Email "
              mode="tags"
              style={{ width: "100%", paddingBottom: "2px" }}
              onChange={validateEmail}
              tokenSeparators={[","]}
              notFoundContent=""
              size="large"
              className="general-input-select"
            >
              {/* {children} */}
            </Select>
          </Form.Item>
        </Form>
        <div style={{paddingTop: "35px" , display: 'flex'}} >
        <Button
          className="secondaryButton"
          onClick={handleCancel}
          style={{ fontSize: 18, marginRight: 8, width: "48%" }}
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          type="primary"
          style={{ fontSize: 18, width: "50%" }}
          className="primaryButton"
        >
          Send
        </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Invite;
