import { Pie } from "@ant-design/charts";

const PieChart = (props) => {
  const {totalspend} = props
  let data = [];
  props.plannedBudget?.campaignDataBudget?.map((campaignData, index) => {
    if (index !== (props.plannedBudget?.campaignDataBudget.length-1) && index !== (props.plannedBudget?.campaignDataBudget.length -2) && campaignData["fy-totals"]) {
      let budgetPlannedQuarter = campaignData['fy-totals'].projected;
      if(budgetPlannedQuarter > 0) {
        data.push({
          type: campaignData["channel-campaign"],
          value: budgetPlannedQuarter,
        });
      }
    }
    return true;
  });
  
  data = data.length === 0 ? [{type: 'No data found', value:0}]:data

  var config = {
    appendPadding: 10,
    animation: false,
    data: data,
    meta: {
      value: {
        formatter: (v) => {
          return `${isNaN(((v*100)/totalspend).toFixed(2)) ? 0 : (((v*100)/totalspend).toFixed(2)) } %`;
        },
      },
    },

    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    legend: false,
    label: {
      type: "inner",
      offset: "-50%",
      content: "",
      style: {
        textAlign: "center",
        fontSize: 9,
      },
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        formatter: function formatter() {
          return "";
        },
      },
    },
  };
  return <Pie {...config} />;
};

export default PieChart;
