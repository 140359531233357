// /* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import moment from "moment";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import {
  compareArrays,
  getNumberDifference,
  getNumberDifferenceClass,
  getNumberDifferenceClassForLeads,
  getNumberDifferenceIfUnderBudget,
  gettimeDiff,
  getTotalsFormatted,
  numberWithCommas,
} from "utility/context/CommonMethods";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Row,
  Space,
  Statistic,
  Table,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import Title from "antd/lib/typography/Title";
import verticalExpandImg from "assets/Images/vertical_expand.svg";
import verticalCollapseImg from "assets/Images/vertical_collapse.svg";
import settingsImg from "assets/Images/table_settings.svg";
import expandImg from "assets/Images/expand.png";
import collpaseImg from "assets/Images/collapse.png";

import {
  CheckOutlined,
  FileTextOutlined,
  LinkOutlined,
  SyncOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  deleteCampaign,
  deleteCampaignChannel,
  getChannelList,
  setChannelCampaignPlannedTotals,
  setViewTotal,
  updateCampaign,
  deleteCampaignMonth,
  deleteCampaignDetails,
  updateCampaignMonth,
  updateCampaignDetails,
  getBudget,
  createCampaignMonth,
  createCampaign,
  createCampaignDetails,
  getLastActualSync,
  getCampaignActualData,
  setCampaignActuals,
  updateBudget,
  setLoader,
  createChannel,
} from "store/actions/budgetAction";
import { ExploricApi } from "utility/Api";
import blueTickImg from "assets/Images/blue-tick.svg";
import lockBlackImg from "assets/Images/lock-black.svg";

import ColumnEmptyState from "./ColumnEmptyState";
import MapCampaignDrawer from "./MapCampaignDrawer";
import CreateCampaign from "./CreateCampaign";
import CreateChannel from "./CreateChannel";
import { CSVLink } from "react-csv";
import ResetIcon from "assets/Images/reset_icon.svg";
import SaveIconGrey from "assets/Images/save_icon_grey.svg";
import LeadsPreferenceImg from "assets/Images/lead_preference.svg";
import _ from "lodash";

import { API, graphqlOperation } from "aws-amplify";
import { onCreateMaterializerSyncHistory } from "graphql/subscriptions";
import { getaccountDetails, updateAccount } from "store/actions/loginAction";
import {
  BudgetTemplateS3url,
  CSVImportTemplateHeaders,
} from "utility/Constants";
import {
  getConnectedCRMs,
} from "store/actions/reportAction";
import SalesforceImg from 'assets/Images/Social/Salesforce.png'
import HubspotImg from 'assets/Images/Social/hubspot.jpg';
import { ApiOutlined } from "@ant-design/icons";
import { TrackClickFunction } from "commons/HeapTrack/TrackClickFunction";

function BudgetLeadsViewNew(props) {
  const {
    selectedQuarters,
    CampaignList,
    BudgetProfileListDetails,
    ChannelList,
    budgetView,
    searchText,
    BudgetProfile,
    ProspectData,
    selectedBudgetDetails,
    selectedYear,
    showCampaignWithZero,
    sethasUnsavedChanges,
    settableLoadingParent,
    uploadCsvModalOpen,
    setuploadCsvModalOpen,
    nameOfLeadObj,
    listOfYear
  } = props;
  const TotalLeadValueBudget = `Total ${nameOfLeadObj}`
  const [CampaignTableData, setCampaignTableData] = useState([]);
  const [CampaignTableDataBackup, setCampaignTableDataBackup] = useState([]);

  const [viewTotals, setviewTotals] = useState([]);
  const [viewTotalsBackup, setviewTotalsBackup] = useState([]);

  const [budgetAllocationDetails, setBudgetAllocationDetails] = useState();
  const [columnData, setcolumnData] = useState([]);

  const [isMapCampaignDrawerOpen, setIsMapCampaignDrawerOpen] = useState(false);
  const [linkRecordId, setLinkRecordId] = useState(null);
  const [linkRecordInfo, setLinkRecordInfo] = useState(null);
  // eslint-disable-next-line
  const BasicQuarter = useMemo(() => [
    "monthq1",
    "monthq2",
    "monthq3",
    "monthq4",
  ]);
  const [quarters, setQuarters] = useState(BasicQuarter);
  const [budgetData, setBudgetData] = useState({});
  const [isCampaignDeleteModalOpen, setIsCampaignDeleteModalOpen] =
    useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const [channelToDelete, setChannelToDelete] = useState(null);
  const [isDeletCampaignLoading, setIsDeletCampaignLoading] = useState(false);
  const [isDeleteChannelModalOpen, setIsDeleteChannelModalOpen] =
    useState(false);
  const [isDeleteChannelLoading, setIsDeleteChannelLoading] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [campaignToEdit, setCampaignToEdit] = useState(null);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [channelToRename, setChannelToRename] = useState(null);
  const [channelToCopy, setChannelToCopy] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState(undefined);
  const dispatch = useDispatch();
  const [channelIdForCampaign, setChannelIdForCampaign] = useState();
  const [selectedQuarter, setselectedQuarter] = useState(BasicQuarter);
  const [tableLoading, settableLoading] = useState(false);
  const [resetTable, setresetTable] = useState(false);
  const [CSVdata, setCSVdata] = useState([]);
  const [PrintTableColumnData, setPrintTableColumnData] = useState();
  const [printTableData, setprintTableData] = useState([]);

  const [dollarSign, setDollarSign] = useState();

  useEffect(() => {
    setDollarSign(props.budgetView ? "$" : "");
  }, [props.budgetView]);

  const {
    groups: account,
    accountDetails,
    userData,
  } = useSelector((state) => state.loginReducer);

  const { campaignActualsData, campaignActualsAmountData, isLoading } =
    useSelector((state) => state.budgetReducer);
  const { ConnectedCRMs } = useSelector((state) => state.reportReducer);

  const [isCRMConnectedAndEnabled, setisCRMConnectedAndEnabled] =
    useState(false);
  useEffect(() => {
    setisCRMConnectedAndEnabled(
      ConnectedCRMs["Hubspot"]?.filter((item) => item?.enabled)?.length > 0 ||
        ConnectedCRMs["LinkedIn"]?.filter((val) => val?.enabled)?.length > 0 ||
        ConnectedCRMs["Salesforce"]?.filter((val) => val?.enabled)?.length >
          0 ||
        ConnectedCRMs["GoogleAds"]?.filter((val) => val?.enabled)?.length > 0
    );
  }, [ConnectedCRMs]);

  // INIT
  useEffect(() => {
    if (
      ChannelList &&
      ChannelList?.length > 0 &&
      budgetData &&
      Object.keys(budgetData)?.length > 0
    )
      getTableData();
  },
  // eslint-disable-next-line
   [
    ChannelList,
    budgetData,
    budgetView,
    ProspectData,
    campaignActualsAmountData,
    selectedQuarters,
    resetTable,
    campaignActualsData,
  ]);

  const [LeadsAutoUpdate, setLeadsAutoUpdate] = useState();
  const [accountMetadata, setaccountMetadata] = useState({});
  const [noCRMConnected, setnoCRMConnected] = useState(false)
  const [selectedCRM, setselectedCRM] = useState()

  console.log("selectedCRM",selectedCRM);

  useEffect(() => {
    dispatch(getChannelList());
  }, [dispatch]);

  useEffect(() => {
    let metadata = accountDetails?.metadata
      ? JSON.parse(accountDetails?.metadata)?.[userData.sub]
      : {};
    setLeadsAutoUpdate(metadata?.["preferences"]?.autoUpdateLeads);
    if (!metadata?.["preferences"] && metadata !== undefined) {
      metadata["preferences"] = {};
    }
    accountDetails?.metadata && setaccountMetadata(metadata);
    // eslint-disable-next-line
  }, [accountDetails]);

  const getActualSpendData = () => {
    settableLoading(true);
    let inputData = {
      account: account[0],
      year: selectedYear,
    };

    let dataObj = {};
    dispatch(getCampaignActualData(inputData))
      .then((res) => {
        res?.campaignReport?.map((item) => {
          dataObj[item?.campaign_id] = dataObj[item?.campaign_id]
            ? dataObj[item?.campaign_id]
            : {};
          dataObj[item?.campaign_id][item?.month] = item;
        });
        dispatch(setCampaignActuals(dataObj));
        settableLoading(false);
      })
      .catch((err) => {
        settableLoading(false);
        console.log("Error", err);
      });
  };

  // get actual spent
  useEffect(() => {
    getActualSpendData();
    // eslint-disable-next-line
  }, [selectedYear]);

  useEffect(() => {
    init();
    // eslint-disable-next-line
  },[])

  const init = async () => {
    let ConnectedCRMs = await dispatch(getConnectedCRMs())
       let CRMS = []
    if(Object.keys(ConnectedCRMs)?.length){
      Object.keys(ConnectedCRMs)?.forEach(key => {
        if(["Hubspot","Salesforce"]?.includes(key)){
          CRMS.push(key)
        }
      })

    }
    setnoCRMConnected(CRMS?.length === 0)
    
    console.log("CRMS",CRMS)
    // Check if "Salesforce" is available in the CRMS array
    CRMS.includes("Salesforce") ?  setselectedCRM("Salesforce") : setselectedCRM(CRMS[0]);

  }

  useEffect(() => {
    // sets budget and lead total counts to show in campaign view table
    dispatch(
      setChannelCampaignPlannedTotals({
        budget: viewTotals?.[viewTotals?.length - 1],
        lead: viewTotals?.[viewTotals?.length - 1],
        campaignDataBudget: viewTotals,
        campaignDataLead: viewTotals,
      })
    );
    // eslint-disable-next-line
  }, [viewTotals]);

  // SET BUDGET DETAILED DATA
  useEffect(() => {
    setBudgetData(BudgetProfile);
  }, [BudgetProfile]);

  // IF BUGDET IS NOT SET FOR A QUARTER THEN ITS VALUES SHOULD 0
  useEffect(() => {
    let budgetAllocation = selectedBudgetDetails?.budget_allocation
      ? JSON.parse(selectedBudgetDetails?.budget_allocation)
      : "";
    setBudgetAllocationDetails(budgetAllocation);
  }, [selectedBudgetDetails]);

  const months = useMemo(
    () => [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    []
  );
  const quartersAndMonths = useMemo(
    () => ({
      q1: ["Jan", "Feb", "Mar"],
      q2: ["Apr", "May", "Jun"],
      q3: ["Jul", "Aug", "Sep"],
      q4: ["Oct", "Nov", "Dec"],
    }),
    []
  );
  const [PrintDataKeys, setPrintDataKeys] = useState([]);

  // TO GET THE TABLE DATA
  const getTableData = () => {
    let printDataKeys = [];
    const channelCampaignData = [];
    let viewTotal = [];
    ChannelList.map((channel) => {
      var matchCampaignData = budgetData?.campaigns?.items?.filter((item) => {
        return item.campaign.channel === channel.id;
      });
      if (matchCampaignData?.length > 0) {
        matchCampaignData.map((budgetinfo, index) => {
          let timeValues = {};
          budgetinfo?.campaign?.campaign_details?.items?.map((campaign) => {
            campaign?.campaign_months?.items?.map((month) => {
              let budget = 0;
              let leads = 0;

              if (month["verified_budget"]) {
                budget = month["verified_budget"];
              } else if (month["verified_budget"] === 0) {
                budget = 0;
              } else {
                budget = month["budget"];
              }

              if (month["verified_leads"]) {
                leads = month["verified_leads"];
              } else if (month["verified_leads"] === 0) {
                leads = 0;
              } else {
                leads = month["leads"];
              }

              timeValues[months[month.month - 1]] = {
                lead: month["leads"],
                budget: month["budget"],
                weight: month["weight"],
                weight_lead: month["weight_lead"],
                verified_budget: budget,
                verified_leads: leads,
              };
            });
          });
          let headers = {};
          headers["key"] = budgetinfo?.campaign?.["id"];
          headers["channel-campaign"] = budgetinfo?.campaign?.name;
          headers["name"] = channel.name;
          headers["channelType"] = channel?.channelType;
          headers["id"] = budgetinfo?.campaign?.["id"];
          headers["channelId"] = channel.id;
          let headersData = { ...headers, ...timeValues };
          channelCampaignData.push(headersData);
          return channelCampaignData;
        });
      } else {
        channelCampaignData.push({
          key: channel.id,
          name: channel.name,
          channelType: channel?.channelType,
          id: channel.id,
          channelId: channel.id,
          isChannel: true,
        });
      }
    });
    const viewData = [];
    channelCampaignData.map((channelCampaign, dataIndex) => {
      //Lead Actual
      var actual = {};

      // revenue actual
      var actualAmount = {};

      // budget Actual
      let spend = {};

      if (!channelCampaign.isChannel) {
        let dataObj = {
          actual: {},
          spend: {},
          actualAmount: {},
        };
        if (campaignActualsData[channelCampaign?.id]) {
          Object.keys(campaignActualsData[channelCampaign?.id]).map((key) => {
            dataObj.actual[moment(key).format("MMM")] =
              campaignActualsData[channelCampaign?.id]?.[key]?.lead_actual;
            dataObj.spend[moment(key).format("MMM")] =
              campaignActualsData[channelCampaign?.id]?.[key]?.budget_actual;
            dataObj.actualAmount[moment(key).format("MMM")] =
              campaignActualsData[channelCampaign?.id]?.[key]?.revenue_actual;
          });
          actual = dataObj.actual;
          actualAmount = dataObj.actualAmount;
          spend = dataObj.spend;
        }
      }
      let totalspend = 0;
      Object.keys(spend).forEach((key) => {
        totalspend += spend[key];
      });
      let totalActualLeads = 0;
      Object.keys(actual).forEach((key) => {
        totalActualLeads += actual[key];
      });
      let totalActualRevenue = 0;
      Object.keys(actualAmount).forEach((key) => {
        totalActualRevenue += actualAmount[key];
      });

      let viewObject = {
        channelId: channelCampaign.channelId,
        id: channelCampaign.id,
        key: channelCampaign?.id,
        name: channelCampaign.name,
        channelType: channelCampaign?.channelType,
        "channel-campaign": channelCampaign["channel-campaign"],
        actualLead: totalActualLeads,
        totalspend: totalspend,
        actualrevenue: totalActualRevenue,
      };

      // Added Months in loop
      months.forEach((month) => {
        viewObject[month] = {
          projected: isFinite(channelCampaign?.[month]?.budget)
            ? channelCampaign?.[month]?.budget
            : 0,
          projectedLeads: isFinite(channelCampaign?.[month]?.lead)
            ? channelCampaign?.[month]?.lead
            : 0,
          actual: actual[month] ? actual[month] : 0, //Lead Actual
          actualRevenue: actualAmount[month] ? actualAmount[month] : 0, // revenue actual
          spend: spend[month] ? spend[month] : 0, // budget Actual
          inTimeFrame: channelCampaign?.[month]?.budget ? true : false,
          weight: channelCampaign?.[month]?.weight
            ? channelCampaign?.[month]?.weight
            : 0,
          weightLead: channelCampaign?.[month]?.weight_lead
            ? channelCampaign?.[month]?.weight_lead
            : 0,
          verified_budget: channelCampaign?.[month]?.verified_budget
            ? channelCampaign?.[month]?.verified_budget
            : 0,
          verified_leads: channelCampaign?.[month]?.verified_leads
            ? channelCampaign?.[month]?.verified_leads
            : 0,
        };
      });

      viewData.push(viewObject);
      let q1 = {
        projected: Number(
          parseFloat(
            viewObject.Jan.projected +
              viewObject.Feb.projected +
              viewObject.Mar.projected
          ).toFixed(2)
        ),
        projectedLeads: Number(
          parseFloat(
            viewObject.Jan.projectedLeads +
              viewObject.Feb.projectedLeads +
              viewObject.Mar.projectedLeads
          ).toFixed(2)
        ),
        actual:
          viewObject.Jan.actual + viewObject.Feb.actual + viewObject.Mar.actual,
        actualRevenue:
          viewObject.Jan.actualRevenue +
          viewObject.Feb.actualRevenue +
          viewObject.Mar.actualRevenue,
        spend:
          viewObject.Jan.spend + viewObject.Feb.spend + viewObject.Mar.spend,
        inTimeFrame:
          viewObject.Jan?.inTimeFrame ||
          viewObject.Feb.inTimeFrame ||
          viewObject.Mar.inTimeFrame,
        verified_budget:
          viewObject.Jan?.verified_budget ||
          viewObject.Feb.verified_budget ||
          viewObject.Mar.verified_budget,
        verified_leads:
          viewObject.Jan?.verified_leads ||
          viewObject.Feb.verified_leads ||
          viewObject.Mar.verified_leads,
      };

      let q2 = {
        projected: Number(
          parseFloat(
            viewObject.Apr.projected +
              viewObject.May.projected +
              viewObject.Jun.projected
          ).toFixed(2)
        ),
        projectedLeads: Number(
          parseFloat(
            viewObject.Apr.projectedLeads +
              viewObject.May.projectedLeads +
              viewObject.Jun.projectedLeads
          ).toFixed(2)
        ),
        actual:
          viewObject.Apr.actual + viewObject.May.actual + viewObject.Jun.actual,
        actualRevenue:
          viewObject.Apr.actualRevenue +
          viewObject.May.actualRevenue +
          viewObject.Jun.actualRevenue,
        spend:
          viewObject.Apr.spend + viewObject.May.spend + viewObject.Jun.spend,
        inTimeFrame:
          viewObject.Apr?.inTimeFrame ||
          viewObject.May.inTimeFrame ||
          viewObject.Jun.inTimeFrame,
        verified_budget:
          viewObject.Apr?.verified_budget ||
          viewObject.May.verified_budget ||
          viewObject.Jun.verified_budget,
        verified_leads:
          viewObject.Apr?.verified_leads ||
          viewObject.May.verified_leads ||
          viewObject.Jun.verified_leads,
      };
      let q3 = {
        projected: Number(
          parseFloat(
            viewObject.Jul.projected +
              viewObject.Aug.projected +
              viewObject.Sep.projected
          ).toFixed(2)
        ),
        projectedLeads: Number(
          parseFloat(
            viewObject.Jul.projectedLeads +
              viewObject.Aug.projectedLeads +
              viewObject.Sep.projectedLeads
          ).toFixed(2)
        ),
        actual:
          viewObject.Jul.actual + viewObject.Aug.actual + viewObject.Sep.actual,
        actualRevenue:
          viewObject.Jul.actualRevenue +
          viewObject.Aug.actualRevenue +
          viewObject.Sep.actualRevenue,
        spend:
          viewObject.Jul.spend + viewObject.Aug.spend + viewObject.Sep.spend,
        inTimeFrame:
          viewObject.Jul?.inTimeFrame ||
          viewObject.Aug.inTimeFrame ||
          viewObject.Sep.inTimeFrame,
        verified_budget:
          viewObject.Jul?.verified_budget ||
          viewObject.Aug.verified_budget ||
          viewObject.Sep.verified_budget,
        verified_leads:
          viewObject.Jul?.verified_leads ||
          viewObject.Aug.verified_leads ||
          viewObject.Sep.verified_leads,
      };
      let q4 = {
        projected: Number(
          parseFloat(
            viewObject.Oct.projected +
              viewObject.Nov.projected +
              viewObject.Dec.projected
          ).toFixed(2)
        ),
        projectedLeads: Number(
          parseFloat(
            viewObject.Oct.projectedLeads +
              viewObject.Nov.projectedLeads +
              viewObject.Dec.projectedLeads
          ).toFixed(2)
        ),
        actual:
          viewObject.Oct.actual + viewObject.Nov.actual + viewObject.Dec.actual,
        actualRevenue:
          viewObject.Oct.actualRevenue +
          viewObject.Nov.actualRevenue +
          viewObject.Dec.actualRevenue,
        spend:
          viewObject.Oct.spend + viewObject.Nov.spend + viewObject.Dec.spend,
        inTimeFrame:
          viewObject.Oct?.inTimeFrame ||
          viewObject.Nov.inTimeFrame ||
          viewObject.Dec.inTimeFrame,
        verified_budget:
          viewObject.Oct?.verified_budget ||
          viewObject.Nov.verified_budget ||
          viewObject.Dec.verified_budget,
        verified_leads:
          viewObject.Oct?.verified_leads ||
          viewObject.Nov.verified_leads ||
          viewObject.Dec.verified_leads,
      };

      viewData[dataIndex].q1 = q1;
      viewData[dataIndex].q2 = q2;
      viewData[dataIndex].q3 = q3;
      viewData[dataIndex].q4 = q4;

      viewData[dataIndex]["fy-totals"] = {
        projected: Number(
          parseFloat(
            (selectedQuarters &&
            selectedQuarters["Q1"] &&
            budgetAllocationDetails?.budget_q1 > 0
              ? viewData[dataIndex].q1.projected
              : 0) +
              (selectedQuarters &&
              selectedQuarters["Q2"] &&
              budgetAllocationDetails?.budget_q2 > 0
                ? viewData[dataIndex].q2.projected
                : 0) +
              (selectedQuarters &&
              selectedQuarters["Q3"] &&
              budgetAllocationDetails?.budget_q3 > 0
                ? viewData[dataIndex].q3.projected
                : 0) +
              (selectedQuarters &&
              selectedQuarters["Q4"] &&
              budgetAllocationDetails?.budget_q4 > 0
                ? viewData[dataIndex].q4.projected
                : 0)
          ).toFixed(2)
        ),

        projectedLeads: Number(
          parseFloat(
            (selectedQuarters &&
            selectedQuarters["Q1"] &&
            budgetAllocationDetails?.budget_q1 > 0
              ? viewData[dataIndex].q1.projectedLeads
              : 0) +
              (selectedQuarters &&
              selectedQuarters["Q2"] &&
              budgetAllocationDetails?.budget_q2 > 0
                ? viewData[dataIndex].q2.projectedLeads
                : 0) +
              (selectedQuarters &&
              selectedQuarters["Q3"] &&
              budgetAllocationDetails?.budget_q3 > 0
                ? viewData[dataIndex].q3.projectedLeads
                : 0) +
              (selectedQuarters &&
              selectedQuarters["Q4"] &&
              budgetAllocationDetails?.budget_q4 > 0
                ? viewData[dataIndex].q4.projectedLeads
                : 0)
          ).toFixed(2)
        ),

        verified_budget: Number(
          parseFloat(
            (selectedQuarters &&
            selectedQuarters["Q1"] &&
            budgetAllocationDetails?.budget_q1 > 0
              ? viewData[dataIndex].q1.verified_budget
              : 0) +
              (selectedQuarters &&
              selectedQuarters["Q2"] &&
              budgetAllocationDetails?.budget_q2 > 0
                ? viewData[dataIndex].q2.verified_budget
                : 0) +
              (selectedQuarters &&
              selectedQuarters["Q3"] &&
              budgetAllocationDetails?.budget_q3 > 0
                ? viewData[dataIndex].q3.verified_budget
                : 0) +
              (selectedQuarters &&
              selectedQuarters["Q4"] &&
              budgetAllocationDetails?.budget_q4 > 0
                ? viewData[dataIndex].q4.verified_budget
                : 0)
          ).toFixed(2)
        ),

        verified_leads: Number(
          parseFloat(
            (selectedQuarters &&
            selectedQuarters["Q1"] &&
            budgetAllocationDetails?.budget_q1 > 0
              ? viewData[dataIndex].q1.verified_leads
              : 0) +
              (selectedQuarters &&
              selectedQuarters["Q2"] &&
              budgetAllocationDetails?.budget_q2 > 0
                ? viewData[dataIndex].q2.verified_leads
                : 0) +
              (selectedQuarters &&
              selectedQuarters["Q3"] &&
              budgetAllocationDetails?.budget_q3 > 0
                ? viewData[dataIndex].q3.verified_leads
                : 0) +
              (selectedQuarters &&
              selectedQuarters["Q4"] &&
              budgetAllocationDetails?.budget_q4 > 0
                ? viewData[dataIndex].q4.verified_leads
                : 0)
          ).toFixed(2)
        ),

        actualRevenue:
          (selectedQuarters &&
          selectedQuarters["Q1"] &&
          budgetAllocationDetails?.budget_q1 > 0
            ? viewData[dataIndex].q1.actualRevenue
            : 0) +
          (selectedQuarters &&
          selectedQuarters["Q2"] &&
          budgetAllocationDetails?.budget_q2 > 0
            ? viewData[dataIndex].q2.actualRevenue
            : 0) +
          (selectedQuarters &&
          selectedQuarters["Q3"] &&
          budgetAllocationDetails?.budget_q3 > 0
            ? viewData[dataIndex].q3.actualRevenue
            : 0) +
          (selectedQuarters &&
          selectedQuarters["Q4"] &&
          budgetAllocationDetails?.budget_q4 > 0
            ? viewData[dataIndex].q4.actualRevenue
            : 0),
        actual:
          (selectedQuarters &&
          selectedQuarters["Q1"] &&
          budgetAllocationDetails?.budget_q1 > 0
            ? viewData[dataIndex].q1.actual
            : 0) +
          (selectedQuarters &&
          selectedQuarters["Q2"] &&
          budgetAllocationDetails?.budget_q2 > 0
            ? viewData[dataIndex].q2.actual
            : 0) +
          (selectedQuarters &&
          selectedQuarters["Q3"] &&
          budgetAllocationDetails?.budget_q3 > 0
            ? viewData[dataIndex].q3.actual
            : 0) +
          (selectedQuarters &&
          selectedQuarters["Q4"] &&
          budgetAllocationDetails?.budget_q4 > 0
            ? viewData[dataIndex].q4.actual
            : 0),
        spend:
          (selectedQuarters &&
          selectedQuarters["Q1"] &&
          budgetAllocationDetails?.budget_q1 > 0
            ? viewData[dataIndex].q1.spend
            : 0) +
          (selectedQuarters &&
          selectedQuarters["Q2"] &&
          budgetAllocationDetails?.budget_q2 > 0
            ? viewData[dataIndex].q2.spend
            : 0) +
          (selectedQuarters &&
          selectedQuarters["Q3"] &&
          budgetAllocationDetails?.budget_q3 > 0
            ? viewData[dataIndex].q3.spend
            : 0) +
          (selectedQuarters &&
          selectedQuarters["Q4"] &&
          budgetAllocationDetails?.budget_q4 > 0
            ? viewData[dataIndex].q4.spend
            : 0),
      };

      viewData[dataIndex]["cpl"] = isFinite(
        viewData[dataIndex]["fy-totals"]?.projected /
          viewData[dataIndex]["fy-totals"]?.projectedLeads
      )
        ? Number(
            (
              viewData[dataIndex]["fy-totals"]?.projected /
              viewData[dataIndex]["fy-totals"]?.projectedLeads
            ).toFixed(2)
          )
        : 0;
    });
    const getGroupCampaign = (groups, item) => {
      let group = groups.find((g) => {
        return g["channel-campaign"] === item["name"];
      });
      if (!group) {
        group = {
          "channel-campaign": item["name"],
          key: item["id"],
          children: [],
          id: item["id"],
          channelId: item["channelId"],
          channelType: item["channelType"],
        };
        groups.push(group);
      }
      return group;
    };

    let groupedCampaign = [];
    viewData.forEach((item) =>
      getGroupCampaign(groupedCampaign, item).children.push(item)
    );
    groupedCampaign.map((groupedCampaignValue, groupedCampaignIndex) => {
      let actualwins = 0;
      let totalspend = 0;
      let actualrevenue = 0;

      let dataObj = {};
      groupedCampaignValue.children.forEach((value, valueIndex) => {
        months.map((month) => {
          dataObj[`${month}Channel`] = {
            projected: Number(
              parseFloat(
                (dataObj[`${month}Channel`]?.projected
                  ? dataObj[`${month}Channel`]?.projected
                  : 0) + value?.[month].projected
              ).toFixed(2)
            ),
            projectedLeads: Number(
              parseFloat(
                (dataObj[`${month}Channel`]?.projectedLeads
                  ? dataObj[`${month}Channel`]?.projectedLeads
                  : 0) + value?.[month].projectedLeads
              ).toFixed(2)
            ),
            actual:
              (dataObj[`${month}Channel`]?.["actual"]
                ? dataObj[`${month}Channel`]?.["actual"]
                : 0) + value?.[month].actual,
            actualRevenue:
              (dataObj[`${month}Channel`]?.["actualRevenue"]
                ? dataObj[`${month}Channel`]?.["actualRevenue"]
                : 0) + value?.[month].actualRevenue,
            spend:
              (dataObj[`${month}Channel`]?.["spend"]
                ? dataObj[`${month}Channel`]?.["spend"]
                : 0) + value?.[month].spend,
            verified_budget:
              (dataObj[`${month}Channel`]?.["verified_budget"]
                ? dataObj[`${month}Channel`]?.["verified_budget"]
                : 0) + value?.[month].verified_budget,
            verified_leads:
              (dataObj[`${month}Channel`]?.["verified_leads"]
                ? dataObj[`${month}Channel`]?.["verified_leads"]
                : 0) + value?.[month].verified_leads,
          };
        });

        Object.keys(quartersAndMonths).forEach((quarter) => {
          dataObj[`${quarter}Channel`] = {
            projected: Number(
              parseFloat(
                (dataObj[`${quarter}Channel`]?.projected
                  ? dataObj[`${quarter}Channel`]?.projected
                  : 0) + value?.[quarter].projected
              ).toFixed(2)
            ),
            projectedLeads: Number(
              parseFloat(
                (dataObj[`${quarter}Channel`]?.projectedLeads
                  ? dataObj[`${quarter}Channel`]?.projectedLeads
                  : 0) + value?.[quarter].projectedLeads
              ).toFixed(2)
            ),
            actual:
              (dataObj[`${quarter}Channel`]?.["actual"]
                ? dataObj[`${quarter}Channel`]?.["actual"]
                : 0) + value?.[quarter].actual,
            actualRevenue:
              (dataObj[`${quarter}Channel`]?.["actualRevenue"]
                ? dataObj[`${quarter}Channel`]?.["actualRevenue"]
                : 0) + value?.[quarter].actualRevenue,
            spend:
              (dataObj[`${quarter}Channel`]?.["spend"]
                ? dataObj[`${quarter}Channel`]?.["spend"]
                : 0) + value?.[quarter].spend,
            verified_budget:
              (dataObj[`${quarter}Channel`]?.["verified_budget"]
                ? dataObj[`${quarter}Channel`]?.["verified_budget"]
                : 0) + value?.[quarter].verified_budget,
            verified_leads:
              (dataObj[`${quarter}Channel`]?.["verified_leads"]
                ? dataObj[`${quarter}Channel`]?.["verified_leads"]
                : 0) + value?.[quarter].verified_leads,
          };
        });

        dataObj["fy-totalsChannel"] = {
          projected: Number(
            parseFloat(
              (dataObj[`fy-totalsChannel`]?.projected
                ? dataObj[`fy-totalsChannel`]?.projected
                : 0) + +value["fy-totals"]["projected"]
            ).toFixed(2)
          ),
          projectedLeads: Number(
            parseFloat(
              (dataObj[`fy-totalsChannel`]?.projectedLeads
                ? dataObj[`fy-totalsChannel`]?.projectedLeads
                : 0) + value["fy-totals"]["projectedLeads"]
            ).toFixed(2)
          ),
          actual:
            (dataObj[`fy-totalsChannel`]?.["actual"]
              ? dataObj[`fy-totalsChannel`]?.["actual"]
              : 0) + value["fy-totals"]["actual"],
          actualRevenue:
            (dataObj[`fy-totalsChannel`]?.["actualRevenue"]
              ? dataObj[`fy-totalsChannel`]?.["actualRevenue"]
              : 0) + value?.["fy-totals"].actualRevenue,
          spend:
            (dataObj[`fy-totalsChannel`]?.["spend"]
              ? dataObj[`fy-totalsChannel`]?.["spend"]
              : 0) + value?.["fy-totals"].spend,
          verified_budget:
            (dataObj[`fy-totalsChannel`]?.["verified_budget"]
              ? dataObj[`fy-totalsChannel`]?.["verified_budget"]
              : 0) + value?.["fy-totals"].verified_budget,
          verified_leads:
            (dataObj[`fy-totalsChannel`]?.["verified_leads"]
              ? dataObj[`fy-totalsChannel`]?.["verified_leads"]
              : 0) + value?.["fy-totals"].verified_leads,
        };

        actualwins += value.actualLead;
        totalspend += value.totalspend;
        actualrevenue += value.actualrevenue;
      });
      groupedCampaign[groupedCampaignIndex]["key"] = groupedCampaignIndex * 100;

      printDataKeys.push(groupedCampaignIndex * 100);
      Object.keys(dataObj).forEach((key) => {
        groupedCampaign[groupedCampaignIndex][key.split("Channel")?.[0]] =
          dataObj[key];
      });

      groupedCampaign[groupedCampaignIndex]["actualLead"] = actualwins;
      groupedCampaign[groupedCampaignIndex]["totalspend"] = totalspend;
      groupedCampaign[groupedCampaignIndex]["actualRevenue"] = actualrevenue;
      groupedCampaign[groupedCampaignIndex]["cpl"] = isFinite(
        dataObj["fy-totalsChannel"]?.projected /
          dataObj["fy-totalsChannel"]?.projectedLeads
      )
        ? Number(
            parseFloat(
              (
                dataObj["fy-totalsChannel"]?.projected /
                dataObj["fy-totalsChannel"]?.projectedLeads
              ).toFixed(2)
            )
          )
        : 0;

      return groupedCampaign;
    });
    let data = getTotalsFormatted(groupedCampaign,nameOfLeadObj);
    viewTotal = data;
    setviewTotals(viewTotal);
    setPrintDataKeys(printDataKeys);
    setviewTotalsBackup(viewTotal);
  };

  const [fieldToBeEdited, setfieldToBeEdited] = useState("projected");
  useEffect(() => {
    setfieldToBeEdited(budgetView ? "projected" : "projectedLeads");
  }, [budgetView]);

  // DIRECT EDIT FROM THE TABLE
  const EditTableCell = (record, name, value) => {
    sethasUnsavedChanges(true);
    if (
      (months.includes(name) || ["q1", "q2", "q3", "q4"].includes(name)) &&
      value === ""
    ) {
      value = 0;
    }
    record.isEdited = true;
    let dataToEdit = _.cloneDeep(viewTotals);

    dataToEdit.map((channel) => {
      if (record?.channelId === channel?.channelId) {
        if (true) {
          if (
            channel["channel-campaign"] !== "Total Spend" ||
            channel["channel-campaign"] !== TotalLeadValueBudget
          ) {
            channel?.children?.map((campaign) => {
              if (campaign?.id === record.id) {
                // check if month is editded
                if (months.includes(name)) {
                  if (!isNaN(value) && value >= 0) {
                    value = Number(parseFloat(value).toFixed(2));
                    campaign[name][fieldToBeEdited] = value;

                    let quarter = `q${moment(name, "MMM").quarter()}`;
                    campaign[quarter][fieldToBeEdited] = parseFloat(
                      parseFloat(
                        campaign[quartersAndMonths[quarter][0]]?.[
                          fieldToBeEdited
                        ] +
                          campaign[quartersAndMonths[quarter][1]]?.[
                            fieldToBeEdited
                          ] +
                          campaign[quartersAndMonths[quarter][2]]?.[
                            fieldToBeEdited
                          ]
                      ).toFixed(2)
                    );

                    campaign[quartersAndMonths[quarter][0]].weight = parseFloat(
                      Number(
                        (campaign[quartersAndMonths[quarter][0]]?.[
                          fieldToBeEdited
                        ] *
                          100) /
                          campaign[quarter]?.[fieldToBeEdited]
                      ).toFixed(2)
                    );
                    campaign[quartersAndMonths[quarter][1]].weight = parseFloat(
                      Number(
                        (campaign[quartersAndMonths[quarter][1]]?.[
                          fieldToBeEdited
                        ] *
                          100) /
                          campaign[quarter]?.[fieldToBeEdited]
                      ).toFixed(2)
                    );
                    campaign[quartersAndMonths[quarter][2]].weight = parseFloat(
                      Number(
                        (campaign[quartersAndMonths[quarter][2]]?.[
                          fieldToBeEdited
                        ] *
                          100) /
                          campaign[quarter]?.[fieldToBeEdited]
                      ).toFixed(2)
                    );

                    campaign["fy-totals"][fieldToBeEdited] = Number(
                      parseFloat(
                        parseFloat(campaign["q1"]?.[fieldToBeEdited]) +
                          parseFloat(campaign["q2"]?.[fieldToBeEdited]) +
                          parseFloat(campaign["q3"]?.[fieldToBeEdited]) +
                          parseFloat(campaign["q4"]?.[fieldToBeEdited])
                      ).toFixed(2)
                    );
                  }
                }
                // check if quarter if edited
                else if (["q1", "q2", "q3", "q4"].includes(name)) {
                  if (!isNaN(value) && value >= 0) {
                    value = Number(parseFloat(value).toFixed(2));
                    campaign[name][fieldToBeEdited] = value;
                    let mon = quartersAndMonths[name];
                    let totalweight = 0;
                    mon.forEach(
                      (month) => (totalweight += campaign[month].weight)
                    );
                    mon.map((month) => {
                      campaign[month][fieldToBeEdited] =
                        totalweight !== 0
                          ? parseFloat(
                              Number(
                                (campaign[month].weight * value) / 100
                              ).toFixed(2)
                            )
                          : parseFloat(Number(value / 3).toFixed(2));
                    });

                    campaign[name][fieldToBeEdited] = parseFloat(
                      campaign[quartersAndMonths[name][0]]?.[fieldToBeEdited] +
                        campaign[quartersAndMonths[name][1]]?.[
                          fieldToBeEdited
                        ] +
                        campaign[quartersAndMonths[name][2]][fieldToBeEdited]
                    ).toFixed(2);

                    campaign["fy-totals"][fieldToBeEdited] = Number(
                      parseFloat(
                        parseFloat(campaign["q1"]?.[fieldToBeEdited]) +
                          parseFloat(campaign["q2"]?.[fieldToBeEdited]) +
                          parseFloat(campaign["q3"]?.[fieldToBeEdited]) +
                          parseFloat(campaign["q4"]?.[fieldToBeEdited])
                      ).toFixed(2)
                    );
                  }
                }
                // other wise name is edited
                else if (name === "channel-campaign") {
                  campaign[name] = value;
                }
              }
            });
            // Calculations for channel
            if (
              months.includes(name) ||
              ["q1", "q2", "q3", "q4"].includes(name)
            ) {
              Object.keys(quartersAndMonths).forEach((key) => {
                let quarterSum = 0;
                let month = quartersAndMonths[key];
                let m0 = 0;
                let m1 = 0;
                let m2 = 0;
                channel?.children?.map((campaign) => {
                  quarterSum += Number(campaign[key][fieldToBeEdited]);
                  m0 += Number(campaign[month[0]][fieldToBeEdited]);
                  m1 += Number(campaign[month[1]][fieldToBeEdited]);
                  m2 += Number(campaign[month[2]][fieldToBeEdited]);
                });

                channel[key][fieldToBeEdited] = parseFloat(
                  parseFloat(quarterSum).toFixed(2)
                );
                channel[month[0]][fieldToBeEdited] = parseFloat(
                  parseFloat(m0).toFixed(2)
                );
                channel[month[1]][fieldToBeEdited] = parseFloat(
                  parseFloat(m1).toFixed(2)
                );
                channel[month[2]][fieldToBeEdited] = parseFloat(
                  parseFloat(m2).toFixed(2)
                );
              });

              channel["fy-totals"][fieldToBeEdited] = Number(
                parseFloat(
                  parseFloat(channel["q1"][fieldToBeEdited]) +
                    parseFloat(channel["q2"][fieldToBeEdited]) +
                    parseFloat(channel["q3"][fieldToBeEdited]) +
                    parseFloat(channel["q4"][fieldToBeEdited])
                ).toFixed(2)
              );
            }
          }
        }
      }
    });
    setviewTotals(dataToEdit);
  };

  const sortingbyNameSync = async (budget) => {
    await budget.sort((a, b) =>
      String(a?.["channel-campaign"]).localeCompare(b?.["channel-campaign"])
    );
    budget.forEach(async (campaign) => {
      await campaign?.children?.sort((a, b) =>
        String(a?.["channel-campaign"]).localeCompare(b?.["channel-campaign"])
      );
    });
  };

  // SET VIEW DATA TO TABLE DATA
  const setTableData = async (rowData) => {
    let data = rowData
      ?.map((item) => {
        if (item?.id === item?.channelId) {
          item["isChannel"] = true;
          delete item.children;
        }
        return item;
      })
      .filter((item) => item);
    let TotalLeads = data.pop();
    let TotalSpend = data.pop();

    data && (await sortingbyNameSync(data));

    if (TotalLeads && TotalSpend) {
      data.push(TotalSpend);
      data.push(TotalLeads);
    }
    if (showCampaignWithZero) {
      var deepCopy = _.cloneDeep(data);
      console.log("deepCopy",deepCopy);
      let BudgetData = deepCopy.filter(
        (item) => item?.["fy-totals"]?.["projected"] !== 0
      );
      console.log("filtereddeepCopy",BudgetData);
      BudgetData.forEach(function (el) {
        if (el?.children) {
          el.children = el.children.filter(function (x) {
            return x?.["fy-totals"]?.["projected"] !== 0;
          });
          return el;
        }
      });
      
      setCampaignTableData(BudgetData);
    } else {
      setCampaignTableData(data);
      setCampaignTableDataBackup(data);
    }
  };
  console.log("CampaignTableData",CampaignTableData);

  // ON VIEW TOTALS CHANGE SET TABLE ROWS
  useEffect(() => {
    setTableData(viewTotals);
    // eslint-disable-next-line
  }, [budgetView, viewTotals]);

  // EXPORT TO CSV HANDLE
  useEffect(() => {
    let data = [];
    var budgetdata = _.cloneDeep(viewTotalsBackup);

    let budget = budgetdata
      ?.map((item) => {
        item?.children?.map((data) => {
          if (!data["channel-campaign"]) {
            item["isChannel"] = true;
            delete item.children;
          }
        });
        return item;
      })
      .filter((item) => item?.["fy-totals"].projected);

    budgetdata
      ?.filter((row) => {
        if (row?.children && row?.children?.length > 0) {
          return row?.children.map((campaign) => {
            return campaign;
          });
        }
      })
      .map((row) =>
        row.children.map((item) => {
          data.push(item);
        })
      );

    budgetdata?.[budgetdata?.length - 1]?.["channel-campaign"] ===
      TotalLeadValueBudget && data.push(budgetdata?.[budgetdata.length - 1]);

    if (data[data.length - 1]) {
      data[data.length - 1]["channel-campaign"] = "Totals";
      data[data.length - 1]["name"] = "-";
    }
    setCSVdata(data);

    let printData = budget?.filter(
      (item) => item?.q1 + item?.q2 + item?.q3 + item?.q4 !== 0
    );
    setprintTableData(printData);
    // eslint-disable-next-line
  }, [viewTotalsBackup]);

  const updateLeadsPreference = useCallback(async (updateLeads) => {
    let data = { ...accountMetadata };
    data["preferences"] = { autoUpdateLeads: updateLeads };
    let metadata = { ...JSON.parse(accountDetails?.metadata) };
    metadata[userData.sub] = data;
    let inputData = {
      id: account[0],
      metadata: JSON.stringify(metadata),
    };
    await dispatch(updateAccount(inputData));
    await dispatch(getaccountDetails(account[0]));
    // eslint-disable-next-line
  }, []);

  // UPDATE THE CAMPAIGN
  const HandleUpdateCampaign = async (
    record,
    updateLeads,
    needToUpdateAccountPreferenace
  ) => {
    if (needToUpdateAccountPreferenace) {
      updateLeadsPreference(updateLeads);
    }
    settableLoading(true);
    let Campaign = budgetData?.campaigns?.items?.find(
      (camp) => camp?.campaign?.id === record["id"]
    );
    let monthsInDataBaseList = [];

    let monthsinDatabseDetailed =
      Campaign?.campaign?.campaign_details?.items?.map((campaign_details) =>
        campaign_details?.campaign_months?.items?.map((campaign_month) => {
          monthsInDataBaseList.push(campaign_month?.month);
          return campaign_month;
        })
      )[0];
    let campaign_Details = Campaign?.campaign?.campaign_details?.items?.[0];
    let editedmonths = [];
    months.forEach((month) => {
      if (record[month].projected !== 0 || record[month].inTimeFrame) {
        editedmonths.push(month);
      }
    });
    let costPerLead = record?.cpl ? Number(record?.cpl) : 0;
    // Create/Update Campaign Months
    editedmonths.forEach((month) => {
      let inputDataforCampaignMonth = {
        account: account[0],
      };
      if (monthsInDataBaseList.includes(months.indexOf(month) + 1)) {
        // Months Is Already in DataBase So Update it
        let campaign_month = monthsinDatabseDetailed.find(
          (item) => item.month === months.indexOf(month) + 1
        );
        inputDataforCampaignMonth = {
          ...inputDataforCampaignMonth,
          id: campaign_month?.id,
        };
        if (budgetView) {
          inputDataforCampaignMonth["weight"] = record[month]["weight"];
          inputDataforCampaignMonth["budget"] = record[month]["projected"];
        } else {
          inputDataforCampaignMonth["weight_lead"] = record[month]["weight"];
          inputDataforCampaignMonth["leads"] = Number(record[month]["projectedLeads"]).toFixed();
        }
      } else {
        // Month is not in database so Create new One
        inputDataforCampaignMonth = {
          ...inputDataforCampaignMonth,
          campaign_detail_id: campaign_Details?.id,
          month: months.indexOf(month) + 1,
        };
        if (budgetView) {
          inputDataforCampaignMonth["weight"] = record[month]["weight"];
          inputDataforCampaignMonth["budget"] = record[month]["projected"];
          inputDataforCampaignMonth["weight_lead"] = 0;
          inputDataforCampaignMonth["leads"] = 0;
        } else {
          inputDataforCampaignMonth["weight_lead"] =
            record[month]["weight_lead"];
          inputDataforCampaignMonth["leads"] = Number(record[month]["projectedLeads"]).toFixed();
          inputDataforCampaignMonth["weight"] = 0;
          inputDataforCampaignMonth["budget"] = 0;
        }
      }
      if (budgetView && updateLeads) {
        inputDataforCampaignMonth["leads"] = parseInt(
          inputDataforCampaignMonth["budget"] / costPerLead
        );
        inputDataforCampaignMonth["weight_lead"] = record[month]["weight"];
      }

      if (inputDataforCampaignMonth.id) {
        HandleUpdateCampaignMonth(inputDataforCampaignMonth);
      } else {
        HandleCreateCampaignMonth(inputDataforCampaignMonth);
      }
    });

    // Edit Campaign Details
    let InputDataForCampaignDetails = {
      id: campaign_Details?.id,
      account: account[0],
    };

    if (editedmonths && editedmonths?.length > 0)
      InputDataForCampaignDetails.date_start = moment(editedmonths[0], "MMM")
        .startOf("month")
        .format("YYYY-MM-DD");
    if (editedmonths && editedmonths?.length > 0)
      InputDataForCampaignDetails.date_end = moment(
        editedmonths[editedmonths?.length - 1],
        "MMM"
      )
        .endOf("month")
        .format("YYYY-MM-DD");

    await HandleUpdateCampaignDetails(InputDataForCampaignDetails);

    let campaignRequestData = {
      account: account[0],
      name: record["channel-campaign"],
      id: record["id"],
      metadata: JSON.stringify({ tableEdited: true }),
    };

    dispatch(updateCampaign(campaignRequestData))
      .then(() => {
        message.success("Campaign Edited Successfully!");
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong!");
      })
      .then(() => {
        settableLoading(false);
        sethasUnsavedChanges(false);
        setCampaignToUpdateFromTable({});
        dispatch(getBudget(budgetData?.id));
      });
  };

  // MODEL TO UPDATE LEADS
  const [CampaignToUpdateFromTable, setCampaignToUpdateFromTable] = useState(
    {}
  );

  // RESET THE TABLE DATA (REMOVED EDITED)
  const resetTableData = () => {
    setresetTable((old) => !old);
    sethasUnsavedChanges(false);
  };

  const HandleCreateCampaignMonth = (inputData) => {
    return dispatch(createCampaignMonth(inputData));
  };
  const HandleUpdateCampaignMonth = (inputData) => {
    return dispatch(updateCampaignMonth(inputData));
  };
  const HandleUpdateCampaignDetails = (inputData) => {
    return dispatch(updateCampaignDetails(inputData));
  };

  // TABLE IS EMPTY OR NOT
  useEffect(() => {
    let currentBudget = BudgetProfileListDetails?.find(
      (item) => item?.id === BudgetProfile?.id
    );
    if (currentBudget?.campaigns?.items?.length > 0) {
      dispatch(setViewTotal(false));
    } else {
      dispatch(setViewTotal(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BudgetProfile, BudgetProfileListDetails]);

  // SET COLUMNS ARRAY
  useEffect(() => {
    let budget_allocation = {};
    if (budgetData && budgetData["budget_allocation"]) {
      budget_allocation = JSON.parse(budgetData["budget_allocation"]);
    }
    let columns = [
      {
        title: <span style={{fontSize:'16px', color:'#1D2939', fontWeight:'500'}}>Channels</span>,
        dataIndex: "channel-campaign",
        key: "channel-campaign",
        width: 230,
        className: "budgetLeadsViewTableFirstCell",
        render: (text, record) => (
          <>
            {record.children ||
            record.isChannel ||
            record["channel-campaign"] === "Total Spend" ||
            record["channel-campaign"] === TotalLeadValueBudget ? (
              <span className="channel-text" style={{color:"#7F56D9", fontWeight:500, fontSize:'16px'}}> {text}</span>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      marginTop: 6,
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        paddingRight: "4px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      {isCRMConnectedAndEnabled &&
                        props.userPermission?.[
                          "budgetCampaign.campaigns.campaign.mapActual"
                        ] !== "viewonly" &&
                        props.userPermission?.[
                          "budgetCampaign.campaigns.campaign.mapActual"
                        ] !== "hidden" && (
                          // <LinkOutlined
                          //   onClick={() => {
                          //     setIsMapCampaignDrawerOpen(true);
                          //     setLinkRecordId(record.id);
                          //     setLinkRecordInfo(record);
                          //   }}
                          //   style={{ justifyContent: "center" }}
                          // />
                          <div onClick={() => {
                            TrackClickFunction('demand-gen-sync-campaign-to-sfdc');
                            setIsMapCampaignDrawerOpen(true);
                            setLinkRecordId(record.id);
                            setLinkRecordInfo(record);
                            
                          }}
                          style={{cursor:'pointer', marginRight:'10px'}}
                          >
                            {selectedCRM === 'Salesforce' &&  <img src={SalesforceImg} alt={'Salesforce'} style={{width:'38px'}}/>}
                            {selectedCRM === 'Hubspot' &&  <img src={HubspotImg} alt={'Hubspot'} style={{width:'38px'}}/>}   
                            {(selectedCRM !== 'Salesforce' && selectedCRM !== 'Hubspot') && <div className="social_default_image"><ApiOutlined style={{fontSize:'22px', color:'#fff'}}/></div>}
                             
                            
                          </div> 
                        )}
                    </div>
                    <div>
                      <Input.TextArea
                        size="large"
                        className="campaign-name-text-box"
                        value={text}
                        autoSize
                        bordered={false}
                        disabled={
                          record.children ||
                          record.isChannel ||
                          record["channel-campaign"] === "Total Spend" ||
                          record["channel-campaign"] === TotalLeadValueBudget
                        }
                        onChange={(value) => {
                          EditTableCell(
                            record,
                            "channel-campaign",
                            value.target.value
                          );
                        }}
                        style={{fontSize:"14px", color:'#12B76A'}}
                      />
                    </div>
                  </div>
                  {record?.isEdited && (
                    <div className="campaign-table-save-reset-icon">
                      <img
                        src={SaveIconGrey}
                        alt=""
                        style={{ cursor: "pointer", paddingBottom: 5 }}
                        title="Save Changes"
                        onClick={() => {
                          if (LeadsAutoUpdate === undefined)
                            setCampaignToUpdateFromTable(record);
                          else HandleUpdateCampaign(record, LeadsAutoUpdate);
                        }}
                      />
                      <img
                        src={ResetIcon}
                        alt=""
                        style={{ cursor: "pointer", paddingTop: 5 }}
                        title="Reset Changes"
                        onClick={() => resetTableData()}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        ),
      },
      {
        title: "",
        dataIndex: "settings",
        key: "settins",
        width: 40,
        render: (text, record) => (
          <div style={{ flexGrow: 1 }}>
            <Row className="budgetLeadsViewTableRow">
              {((!record.children && !!record.id) || !!record.children) && (
                <Col span={2}>
                  <Dropdown
                    trigger={["click"]}
                    overlay={
                      <Menu>
                        {!record.children &&
                          !record.isChannel &&
                          props.userPermission?.[
                            "budgetCampaign.campaigns.campaign"
                          ] !== "viewonly" &&
                          props.userPermission?.[
                            "budgetCampaign.campaigns.campaign"
                          ] !== "preview" &&
                          props.userPermission?.[
                            "budgetCampaign.campaigns.campaign"
                          ] !== "hidden" &&
                          props.userPermission?.["budgetCampaign"] !==
                            "preview" &&
                          props.userPermission?.["budgetCampaign"] !==
                            "viewonly" && (
                            <Menu.Item onClick={() => handleEditClick(record)}>
                              Edit
                            </Menu.Item>
                          )}
                        {(record.children || record.isChannel) &&
                          props.userPermission?.[
                            "budgetCampaign.campaigns.campaign"
                          ] !== "viewonly" &&
                          props.userPermission?.[
                            "budgetCampaign.campaigns.campaign"
                          ] !== "preview" &&
                          props.userPermission?.[
                            "budgetCampaign.campaigns.campaign"
                          ] !== "hidden" &&
                          props.userPermission?.["budgetCampaign"] !==
                            "preview" &&
                          props.userPermission?.["budgetCampaign"] !==
                            "viewonly" && (
                            <Menu.Item
                              onClick={() => {
                                handleCampaignAddWithChannelClick(record);
                              }}
                            >
                              Add Campaign
                            </Menu.Item>
                          )}
                        {(record.children || record.isChannel) &&
                          props.userPermission?.[
                            "budgetCampaign.campaigns.channel"
                          ] !== "viewonly" &&
                          props.userPermission?.[
                            "budgetCampaign.campaigns.channel"
                          ] !== "preview" &&
                          props.userPermission?.[
                            "budgetCampaign.campaigns.channel"
                          ] !== "hidden" &&
                          props.userPermission?.["budgetCampaign"] !==
                            "preview" &&
                          props.userPermission?.["budgetCampaign"] !==
                            "viewonly" && (
                            <Menu.Item
                              onClick={() => {
                                setChannelToRename({
                                  ...record,
                                  id: record.channelId,
                                });
                                setIsRenameModalOpen(true);
                              }}
                            >
                              Edit
                            </Menu.Item>
                          )}

                        {!record.children && !record.isChannel
                          ? props.userPermission?.[
                              "budgetCampaign.campaigns.campaign"
                            ] !== "viewonly" &&
                            props.userPermission?.[
                              "budgetCampaign.campaigns.campaign"
                            ] !== "preview" &&
                            props.userPermission?.[
                              "budgetCampaign.campaigns.campaign"
                            ] !== "hidden" &&
                            props.userPermission?.["budgetCampaign"] !==
                              "preview" &&
                            props.userPermission?.["budgetCampaign"] !==
                              "viewonly" && (
                              <Menu.Item
                                onClick={() => {
                                  if (!record.children && !record.isChannel) {
                                    HandleCopyCampaign(record);
                                  } else {
                                    setChannelToCopy({
                                      ...record,
                                      id: record.channelId,
                                    });
                                    setIsCopyModalOpen(true);
                                  }
                                }}
                              >
                                Copy
                              </Menu.Item>
                            )
                          : props.userPermission?.[
                              "budgetCampaign.campaigns.channel"
                            ] !== "viewonly" &&
                            props.userPermission?.[
                              "budgetCampaign.campaigns.channel"
                            ] !== "preview" &&
                            props.userPermission?.[
                              "budgetCampaign.campaigns.channel"
                            ] !== "hidden" &&
                            props.userPermission?.["budgetCampaign"] !==
                              "preview" &&
                            props.userPermission?.["budgetCampaign"] !==
                              "viewonly" && (
                              <Menu.Item
                                onClick={() => {
                                  if (!record.children && !record.isChannel) {
                                    HandleCopyCampaign(record);
                                  } else {
                                    setChannelToCopy({
                                      ...record,
                                      id: record.channelId,
                                    });
                                    setIsCopyModalOpen(true);
                                  }
                                }}
                              >
                                Copy
                              </Menu.Item>
                            )}

                        {!record.children && !record.isChannel
                          ? props.userPermission?.[
                              "budgetCampaign.campaigns.campaign"
                            ] !== "viewonly" &&
                            props.userPermission?.[
                              "budgetCampaign.campaigns.campaign"
                            ] !== "preview" &&
                            props.userPermission?.[
                              "budgetCampaign.campaigns.campaign"
                            ] !== "hidden" &&
                            props.userPermission?.["budgetCampaign"] !==
                              "preview" &&
                            props.userPermission?.["budgetCampaign"] !==
                              "viewonly" && (
                              <Menu.Item
                                onClick={() => {
                                  if (!record.children && !record.isChannel) {
                                    record.associatedBudgets =
                                      BudgetProfileListDetails.filter(
                                        (profile) =>
                                          profile.campaigns.items.filter(
                                            (i) => i.campaign.id === record.id
                                          ).length > 0
                                      );
                                    setCampaignToDelete(record);
                                    setIsCampaignDeleteModalOpen(true);
                                  } else {
                                    const channelToDelete = {
                                      ...record,
                                      id: record.channelId,
                                      children: CampaignList.filter(
                                        (campaign) =>
                                          campaign.channel === record.channelId
                                      ),
                                    };
                                    setChannelToDelete(channelToDelete);
                                    setIsDeleteChannelModalOpen(true);
                                  }
                                }}
                              >
                                Delete
                              </Menu.Item>
                            )
                          : props.userPermission?.[
                              "budgetCampaign.campaigns.channel"
                            ] !== "viewonly" &&
                            props.userPermission?.[
                              "budgetCampaign.campaigns.channel"
                            ] !== "preview" &&
                            props.userPermission?.[
                              "budgetCampaign.campaigns.channel"
                            ] !== "hidden" &&
                            props.userPermission?.["budgetCampaign"] !==
                              "preview" &&
                            props.userPermission?.["budgetCampaign"] !==
                              "viewonly" && (
                              <Menu.Item
                                onClick={() => {
                                  if (!record.children && !record.isChannel) {
                                    record.associatedBudgets =
                                      BudgetProfileListDetails.filter(
                                        (profile) =>
                                          profile.campaigns.items.filter(
                                            (i) => i.campaign.id === record.id
                                          ).length > 0
                                      );
                                    setCampaignToDelete(record);
                                    setIsCampaignDeleteModalOpen(true);
                                  } else {
                                    const channelToDelete = {
                                      ...record,
                                      id: record.channelId,
                                      children: CampaignList.filter(
                                        (campaign) =>
                                          campaign.channel === record.channelId
                                      ),
                                    };
                                    setChannelToDelete(channelToDelete);
                                    setIsDeleteChannelModalOpen(true);
                                  }
                                }}
                              >
                                Delete
                              </Menu.Item>
                            )}
                      </Menu>
                    }
                  >
                    <img src={settingsImg} className="cursor-pointer" alt="" />
                  </Dropdown>
                </Col>
              )}
            </Row>
          </div>
        ),
      },
    ];
    let months = [
      { budget_q1: ["Jan", "Feb", "Mar"], type: "monthq1", name: "q1" },
      { budget_q2: ["Apr", "May", "Jun"], type: "monthq2", name: "q2" },
      { budget_q3: ["Jul", "Aug", "Sep"], type: "monthq3", name: "q3" },
      { budget_q4: ["Oct", "Nov", "Dec"], type: "monthq4", name: "q4" },
    ];
    months
      .map((quarter) => {
        let keys = Object.keys(quarter);
        let quarterly = quarter[keys[0]].map((month) => {
          return {
            title: (
              <span style={{ display: "flex", alignItems: "center", fontSize:'16px', color:'#1D2939', fontWeight:'500' }}>
                {month}
                {budgetData?.year > new Date().getFullYear()
                  ? false
                  : (moment(month, "MMM").month() < moment().month() ||
                      budgetData?.year < new Date().getFullYear()) && (
                      <Tooltip
                        title={
                          budgetData?.lockedMonths?.includes(month)
                            ? ""
                            : "Verify your actuals for this month"
                        }
                      >
                        <div style={{ marginLeft: 5 }}>
                          {!budgetData?.lockedMonths?.includes(month) ? (
                            <img
                              src={blueTickImg}
                              alt="verified"
                              onClick={() => {
                                setlockBudgetMonth(month);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <Tooltip
                              title={"You have verified actuals for this month"}
                              placement="right"
                            >
                              <img
                                src={lockBlackImg}
                                alt="verified"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setmonthToUnlock(month);
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </Tooltip>
                    )}{" "}
              </span>
            ),
            dataIndex: `${month}`,
            key: `${month}`,
            type: `${quarter[keys[1]]}`,
            render: (text, record) => (
              <>
                <span
                  className={`${
                    record["channel-campaign"] === "Total Spend"
                      ? "total-spend-text"
                      : record["channel-campaign"] === TotalLeadValueBudget
                      ? "total-leads-text"
                      : ""
                  }`}
                >
                  <>
                    <div className="icon" style={{ position: "relative" }}>
                      <Tooltip
                        className="tooltip"
                        placement="bottomLeft"
                        color="#fff"
                        key="#fff"
                        title={
                          <div style={{ width: "100%" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>Budget Actual: &nbsp;</span>{" "}
                              <span
                                style={{
                                  // color: `${
                                  //   text.projected > text.actual
                                  //     ? "#00cd83"
                                  //     : "#ea4b4b"
                                  // }`,
                                  color: "#303030",
                                  fontWeight: 600,
                                }}
                              >
                                {" $"}
                                {numberWithCommas(text.spend)}
                              </span>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>{nameOfLeadObj} Actual: &nbsp;</span>
                              <span
                                style={{
                                  // color: `${
                                  //   text.projectedLeads < text.actual
                                  //     ? "#00cd83"
                                  //     : "#ea4b4b"
                                  // }`,
                                  color: "#303030",
                                  fontWeight: 600,
                                  paddingLeft: 10,
                                }}
                              >
                                {numberWithCommas(text.actual)}
                              </span>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>Revenue Actual: &nbsp;</span>{" "}
                              <span
                                // style={{ color: "#0F61DB", fontWeight: 600 }}
                                style={{ color: "#303030", fontWeight: 600 }}
                              >
                                {" $"}
                                {numberWithCommas(text.actualRevenue)}
                              </span>{" "}
                              <br />
                            </div>
                          </div>
                        }
                      >
                        <Button
                          type="text"
                          style={{
                            fontFamily: "cursive",
                            fontWeight: 600,
                            fontSize: "18px",
                            color: "#bdb6b6",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            right: -20,
                            top: -20,
                            visibility:
                              text.spend || text.actual || text.actualRevenue
                                ? "visible"
                                : "hidden",
                          }}
                        >
                          i
                        </Button>
                      </Tooltip>
                      {/* {record["channel-campaign"] !== TotalLeadValueBudget && dollarSign}
                                        {record["channel-campaign"] === "Total Spend" &&
                                            !dollarSign &&
                                            "$"} */}
                      {record.children ||
                      record.isChannel ||
                      record["channel-campaign"] === "Total Spend" ||
                      record["channel-campaign"] === TotalLeadValueBudget ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            paddingLeft: "0px",
                            fontStyle: budgetData?.lockedMonths?.includes(month)
                              ? "italic"
                              : "normal",
                              fontSize:'16px'
                          }}
                        >
                          {" "}
                          {record["channel-campaign"] !== TotalLeadValueBudget &&
                            dollarSign}
                          {record["channel-campaign"] === "Total Spend" &&
                            !dollarSign &&
                            "$"}
                          {record["channel-campaign"] === TotalLeadValueBudget &&
                            numberWithCommas(text.projectedLeads.toFixed().toLocaleString())}
                          {record["channel-campaign"] === "Total Spend" &&
                            numberWithCommas(text.projected.toFixed().toLocaleString())}
                          {record["channel-campaign"] !== "Total Spend" &&
                            record["channel-campaign"] !== TotalLeadValueBudget &&
                            numberWithCommas(
                              budgetView ? text.projected.toFixed() :text.projectedLeads.toFixed()
                            )}
                        </div>
                      ) : (
                        <InputNumber
                          step={budgetView && "0.01"}
                          stringMode={budgetView}
                          size="large"
                          formatter={(value) => {
                            if (!isNaN(value)) {
                              return `${budgetView ? "$" : ""}${
                                budgetView ? value : Math.floor(value)
                              }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                          }}
                          style={{
                            width: "100%",
                            color: "black",
                            marginTop: 5,
                            border: "none",
                            background: "#fff",
                            fontStyle: budgetData?.lockedMonths?.includes(month)
                              ? "italic"
                              : "normal",
                          }}
                          value={Number(
                            budgetView ? text.projected: text.projectedLeads
                          ).toFixed()}
                          disabled={
                            record.children ||
                            record.isChannel ||
                            record["channel-campaign"] === "Total Spend" ||
                            record["channel-campaign"] === TotalLeadValueBudget ||
                            budgetData?.lockedMonths?.includes(month)
                          }
                          onChange={(value) =>
                            EditTableCell(record, month, value)
                          }
                        />
                      )}
                    </div>
                  </>
                </span>

                {!searchText && record["channel-campaign"] === "Total Spend" && (
                  <span
                    className="campaign-total-amount-diff"
                    style={{ display: "block" }}
                  >
                    {
                      <Statistic
                        value={getNumberDifference(
                          budgetAllocationDetails?.[keys[0]] / 3,
                          text["projected"]
                        )?.toFixed().toLocaleString()}
                        prefix={`${
                          !getNumberDifferenceIfUnderBudget(
                            budgetAllocationDetails?.[keys[0]] / 3,
                            text["projected"]
                          )
                            ? "("
                            : "$"
                        }`}
                        suffix={`${
                          !getNumberDifferenceIfUnderBudget(
                            budgetAllocationDetails?.[keys[0]] / 3,
                            text["projected"]
                          )
                            ? ")"
                            : ""
                        }`}
                        className={getNumberDifferenceClass(
                          budgetAllocationDetails?.[keys[0]] / 3,
                          text["projected"]
                        )}
                      />
                    }
                  </span>
                )}
                {!searchText && record["channel-campaign"] === TotalLeadValueBudget && (
                  <span
                    className="campaign-total-amount-diff"
                    style={{ display: "block" }}
                  >
                    {
                      <Statistic
                        value={getNumberDifference(
                          ProspectData?.[keys[0]] / 3,
                          text["projectedLeads"]
                        )?.toFixed().toLocaleString()}
                        prefix={`${
                          getNumberDifferenceIfUnderBudget(
                            ProspectData?.[keys[0]] / 3,
                            text["projectedLeads"]
                          )
                            ? "("
                            : ""
                        }`}
                        suffix={`${
                          getNumberDifferenceIfUnderBudget(
                            ProspectData?.[keys[0]] / 3,
                            text["projectedLeads"]
                          )
                            ? ")"
                            : ""
                        }`}
                        className={getNumberDifferenceClassForLeads(
                          ProspectData?.[keys[0]] / 3,
                          text["projectedLeads"]
                        )}
                      />
                    }
                  </span>
                )}
              </>
            ),
          };
        });

        let q = {
          title: (record) => (
            <div
              className="selected-column"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {quarters.includes(quarter["type"]) ? (
                <span style={{fontSize:'16px', color:'#1D2939', fontWeight:'500'}}>{String(quarter["name"]).toUpperCase()} </span>
              ) : (
                <span style={{fontSize:'16px', color:'#1D2939', fontWeight:'500'}}>{`${String(quarter["name"]).toUpperCase()} Totals`}</span>
              )}
              <span className="table-header-img">
                {quarters.includes(quarter["type"]) ? (
                  <Tooltip title="More">
                    <img
                      src={expandImg}
                      onClick={() => handleShowMonths(quarter["type"])}
                      className="primary-color cursor-pointer table-header-img-icon"
                      alt=""
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Less">
                    <img
                      src={collpaseImg}
                      onClick={() => handleShowMonths(quarter["type"])}
                      className="primary-color cursor-pointer table-header-img-icon"
                      alt=""
                    />
                  </Tooltip>
                )}
              </span>
              {budgetData?.year > new Date().getFullYear()
                ? false
                : budgetData?.year < new Date().getFullYear() &&
                  budgetData?.lockedMonths?.includes(
                    quartersAndMonths[quarter["name"]][0]
                  ) &&
                  budgetData?.lockedMonths?.includes(
                    quartersAndMonths[quarter["name"]][1]
                  ) &&
                  budgetData?.lockedMonths?.includes(
                    quartersAndMonths[quarter["name"]][2]
                  )
                ? false
                : ((quarter["name"].split("q")[1] <= moment().quarter() &&
                    !(
                      budgetData?.lockedMonths?.includes(
                        quartersAndMonths[quarter["name"]][0]
                      ) &&
                      budgetData?.lockedMonths?.includes(
                        quartersAndMonths[quarter["name"]][1]
                      ) &&
                      budgetData?.lockedMonths?.includes(
                        quartersAndMonths[quarter["name"]][2]
                      )
                    )) ||
                    budgetData?.year < new Date().getFullYear()) && (
                    <Tooltip title="Verify your actuals for each month this quarter">
                      <div
                        className="small-indicator"
                        style={{ marginLeft: 5 }}
                      ></div>
                    </Tooltip>
                  )}
            </div>
          ),
          dataIndex: quarter["name"],
          key: quarter["name"],
          render: (text, record) => (
            <>
              <span
                className={`${
                  record["channel-campaign"] === "Total Spend"
                    ? "total-spend-text"
                    : record["channel-campaign"] === TotalLeadValueBudget
                    ? "total-leads-text"
                    : ""
                }`}
              >
                <>
                  <div className="icon" style={{ position: "relative" }}>
                    <Tooltip
                      className="tooltip"
                      placement="bottomLeft"
                      color="#fff"
                      key="#fff"
                      title={
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>Budget Actual: &nbsp;</span>{" "}
                            <span
                              style={{
                                // color: `${
                                //   text.projected > text.actual
                                //     ? "#00cd83"
                                //     : "#ea4b4b"
                                // }`,
                                color: "#303030",
                                fontWeight: 600,
                              }}
                            >
                              {" $"}
                              {numberWithCommas(text.spend)}
                            </span>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>{nameOfLeadObj} Actual: &nbsp;</span>
                            <span
                              style={{
                                // color: `${
                                //   text.projected < text.actual
                                //     ? "#00cd83"
                                //     : "#ea4b4b"
                                // }`,
                                color: "#303030",
                                fontWeight: 600,
                                paddingLeft: 10,
                              }}
                            >
                              {numberWithCommas(text.actual)}
                            </span>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>Revenue Actual: &nbsp;</span>{" "}
                            {/* <span style={{ color: "#0F61DB", fontWeight: 600 }}> */}
                            <span style={{ color: "#303030", fontWeight: 600 }}>
                              {" $"}
                              {numberWithCommas(text.actualRevenue)}
                            </span>{" "}
                            <br />
                          </div>
                        </div>
                      }
                    >
                      <Button
                        type="text"
                        style={{
                          fontFamily: "cursive",
                          fontWeight: 600,
                          fontSize: "18px",
                          color: "#bdb6b6",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          right: -20,
                          top: -20,
                          visibility:
                            text.spend || text.actual || text.actualRevenue
                              ? "visible"
                              : "hidden",
                        }}
                        onMouseEnter={()=> TrackClickFunction('demand-gen-i-indicators-sfdc-campaign')}
                      >
                        i
                      </Button>
                    </Tooltip>

                    {record.children ||
                    record.isChannel ||
                    record["channel-campaign"] === "Total Spend" ||
                    record["channel-campaign"] === TotalLeadValueBudget ? (
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          paddingLeft: "0px",
                          color:"#1D2939",
                          fontWeight:400,
                          fontSize:'16px'
                        }}
                      >
                        {" "}
                        {record["channel-campaign"] !== TotalLeadValueBudget &&
                          dollarSign}
                        {record["channel-campaign"] === "Total Spend" &&
                          !dollarSign &&
                          "$"}
                        {record["channel-campaign"] === TotalLeadValueBudget &&
                          numberWithCommas(text.projectedLeads.toFixed()).toLocaleString()}
                        {record["channel-campaign"] === "Total Spend" &&
                          numberWithCommas(text.projected.toFixed()).toLocaleString()}
                        {record["channel-campaign"] !== "Total Spend" &&
                          record["channel-campaign"] !== TotalLeadValueBudget &&
                          numberWithCommas(
                            budgetView ? text.projected.toFixed() : text.projectedLeads.toFixed()
                          )}
                      </div>
                    ) : (
                      <InputNumber
                        step={budgetView ? "0.01" : ""}
                        stringMode={budgetView}
                        size="large"
                        formatter={(value) => {
                          if (!isNaN(value)) {
                            return `${budgetView ? "$" : ""}${
                              budgetView ? value : Math.floor(value)
                            }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                          }
                        }}
                        style={{
                          width: "100%",
                          color: "black",
                          marginTop: 5,
                          border: "none",
                          background: "#fff",
                        }}
                        value={Number(
                          budgetView ?text?.projected: text?.projectedLeads
                        ).toFixed()}
                        disabled={
                          record.children ||
                          record.isChannel ||
                          record["channel-campaign"] === "Total Spend" ||
                          record["channel-campaign"] === TotalLeadValueBudget
                        }
                        onChange={(value) =>
                          EditTableCell(record, quarter["name"], value)
                        }
                      />
                    )}
                  </div>
                </>
              </span>

              {!searchText && record["channel-campaign"] === "Total Spend" && (
                <span
                  className="campaign-total-amount-diff"
                  style={{ display: "block" }}
                >
                  {
                    <Statistic
                      value={getNumberDifference(
                        budgetAllocationDetails?.[keys[0]],
                        text["projected"]
                      )?.toFixed().toLocaleString()}
                      prefix={`${
                        !getNumberDifferenceIfUnderBudget(
                          budgetAllocationDetails?.[keys[0]],
                          text["projected"]
                        )
                          ? "("
                          : "$"
                      }`}
                      suffix={`${
                        !getNumberDifferenceIfUnderBudget(
                          budgetAllocationDetails?.[keys[0]],
                          text["projected"]
                        )
                          ? ")"
                          : ""
                      }`}
                      className={getNumberDifferenceClass(
                        budgetAllocationDetails?.[keys[0]],
                        text["projected"]
                      )}
                    />
                  }
                </span>
              )}
              {!searchText && record["channel-campaign"] === TotalLeadValueBudget && (
                <span
                  className="campaign-total-amount-diff"
                  style={{ display: "block" }}
                >
                  {
                    <Statistic
                      value={getNumberDifference(
                        ProspectData?.[keys[0]],
                        text["projectedLeads"]
                      )?.toFixed().toLocaleString()}
                      prefix={`${
                        getNumberDifferenceIfUnderBudget(
                          ProspectData?.[keys[0]],
                          text["projectedLeads"]
                        )
                          ? "("
                          : ""
                      }`}
                      suffix={`${
                        getNumberDifferenceIfUnderBudget(
                          ProspectData?.[keys[0]],
                          text["projectedLeads"]
                        )
                          ? ")"
                          : ""
                      }`}
                      className={getNumberDifferenceClassForLeads(
                        ProspectData?.[keys[0]],
                        text["projectedLeads"]
                      )}
                    />
                  }
                </span>
              )}
            </>
          ),
        };
        quarterly.push(q);
        return quarterly;
      })
      .map((item) => item.map((data) => columns.push(data)));

    let fyTotals = {
      title: <span style={{fontSize:'16px', color:'#1D2939', fontWeight:'500'}}>FY Total</span>,
      dataIndex: "fy-totals",
      key: "fy-totals",
      render: (text, record) => {
        return (
          <>
            <div className="icon" style={{ position: "relative" }}>
              <Tooltip
                className="tooltip"
                placement="bottomLeft"
                color="#fff"
                key="#fff"
                title={
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Budget Actual: &nbsp;</span>{" "}
                      {/* <span style={{ color: "#ea4b4b", fontWeight: 600 }}> */}
                      <span style={{ color: "#303030", fontWeight: 600 }}>
                        {" $"}
                        {numberWithCommas(
                          record["channel-campaign"] === "Total Spend" ||
                            record["channel-campaign"] === TotalLeadValueBudget
                            ? record["totalspend"]
                            : text.spend
                        )}
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{nameOfLeadObj} Actual: &nbsp;</span>
                      <span
                        style={{
                          // color: "#00cd83",
                          color: "#303030",
                          fontWeight: 600,
                          paddingLeft: 10,
                        }}
                      >
                        {numberWithCommas(
                          record["channel-campaign"] === "Total Spend" ||
                            record["channel-campaign"] === TotalLeadValueBudget
                            ? record["actualLead"]
                            : text.actual
                        )}
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Revenue Actual: &nbsp;</span>{" "}
                      {/* <span style={{ color: "#0F61DB", fontWeight: 600 }}> */}
                      <span style={{ color: "#303030", fontWeight: 600 }}>
                        {" $"}
                        {numberWithCommas(
                          record["channel-campaign"] === "Total Spend" ||
                            record["channel-campaign"] === TotalLeadValueBudget
                            ? record["actualRevenue"]
                            : text.actualRevenue
                        )}
                      </span>{" "}
                      <br />
                    </div>
                  </div>
                }
              >
                <Button
                  type="text"
                  style={{
                    fontFamily: "cursive",
                    fontWeight: 600,
                    fontSize: "18px",
                    color: "#bdb6b6",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    right: -20,
                    visibility:
                      record["totalspend"] ||
                      record["actualLead"] ||
                      record["actualRevenue"]
                        ? "visible"
                        : "hidden",
                    top: -20,
                  }}
                  className="non-print"
                >
                  i
                </Button>
              </Tooltip>
              {record["channel-campaign"] === "Total Spend" ||
              record["channel-campaign"] === TotalLeadValueBudget ? (
                <span
                  style={{
                    color:
                      record["channel-campaign"] === "Total Spend" && "#00aec5",
                  }}
                >
                  {record["channel-campaign"] !== TotalLeadValueBudget && dollarSign}
                  {record["channel-campaign"] === "Total Spend" &&
                    !dollarSign &&
                    "$"}
                  {numberWithCommas(
                    record["channel-campaign"] === "Total Spend"
                      ? text.projected.toFixed()
                      : text.projectedLeads.toFixed()
                  )}
                </span>
              ) : (
                <span
                  className={` ${
                    record["channel-campaign"] !== TotalLeadValueBudget &&
                    record["channel-campaign"] !== "Total Spend"
                      ? "campaign-table-fy-totals"
                      : ""
                  }`}
                  style={{fontSize:'16px', color:"#7F56D9", fontWeight:700}}
                >
                  {record["channel-campaign"] !== TotalLeadValueBudget && dollarSign}
                  {record["channel-campaign"] === "Total Spend" &&
                    !dollarSign &&
                    "$"}
                  {numberWithCommas(
                    budgetView
                      ? text.projected.toFixed()
                      : text.projectedLeads.toFixed()
                  )}
                </span>
              )}
              {!searchText && record["channel-campaign"] === "Total Spend" && (
                <span
                  className="campaign-total-amount-diff"
                  style={{ display: "block" }}
                >
                  {
                    <Statistic
                      value={getNumberDifference(
                        props.selectedBudgetDetails?.budget,
                        text.projected
                      )
                        ?.toFixed()
                        .toLocaleString()}
                      prefix={`${
                        !getNumberDifferenceIfUnderBudget(
                          props.selectedBudgetDetails?.budget,
                          text.projected
                        )
                          ? "("
                          : "$"
                      }`}
                      suffix={`${
                        !getNumberDifferenceIfUnderBudget(
                          props.selectedBudgetDetails?.budget,
                          text.projected
                        )
                          ? ")"
                          : ""
                      }`}
                      className={getNumberDifferenceClass(
                        props.selectedBudgetDetails?.budget,
                        text.projected
                      )}
                    />
                  }
                </span>
              )}
              {!searchText && record["channel-campaign"] === TotalLeadValueBudget && (
                <span
                  className="campaign-total-amount-diff"
                  style={{ display: "block" }}
                >
                  {
                    <Statistic
                      value={getNumberDifference(
                        ProspectData["budget_q1"] +
                          ProspectData["budget_q2"] +
                          ProspectData["budget_q3"] +
                          ProspectData["budget_q4"],
                        text.projectedLeads
                      )
                        ?.toFixed()
                        .toLocaleString()}
                      prefix={`${
                        getNumberDifferenceIfUnderBudget(
                          ProspectData["budget_q1"] +
                            ProspectData["budget_q2"] +
                            ProspectData["budget_q3"] +
                            ProspectData["budget_q4"],
                          text.projectedLeads
                        )
                          ? "("
                          : ""
                      }`}
                      suffix={`${
                        getNumberDifferenceIfUnderBudget(
                          ProspectData["budget_q1"] +
                            ProspectData["budget_q2"] +
                            ProspectData["budget_q3"] +
                            ProspectData["budget_q4"],
                          text.projectedLeads
                        )
                          ? ")"
                          : ""
                      }`}
                      className={getNumberDifferenceClassForLeads(
                        ProspectData["budget_q1"] +
                          ProspectData["budget_q2"] +
                          ProspectData["budget_q3"] +
                          ProspectData["budget_q4"],
                        text.projectedLeads
                      )}
                    />
                  }
                </span>
              )}
            </div>
          </>
        );
      },
    };
    let cpl = {
      title: (
        <span style={{fontSize:'16px', color:'#1D2939', fontWeight:'500'}}>
          Projected
           Cost/{nameOfLeadObj}
        </span>
      ),
      width: 230,
      dataIndex: "cpl",
      key: "costperlead",
      render: (text, record) => (
        <>
          <span
            className={`campaign-table-fy-totals`}
            style={{fontSize:'16px', color:"#7F56D9", fontWeight:700}}
          >
            {record["channel-campaign"] !== TotalLeadValueBudget
              ? !isNaN(text) && text !== "Infinity"
                ? `$${numberWithCommas(parseFloat(text).toFixed())}`
                : "$0"
              : "-"}
          </span>
        </>
      ),
    };
    columns.push(fyTotals, cpl);

    // FOR PDF
    let Channel = {
      title: <b>Channels</b>,
      dataIndex: "channel-campaign",
      key: "channel-campaign",
      width: 200,
      className: "budgetLeadsViewTableFirstCell",
      render: (text, record) => (
        <div style={{ flexGrow: 1 }}>
          <Row className="budgetLeadsViewTableRow">
            <Col span={22}>
              {record.children ||
              record.isChannel ||
              record["channel-campaign"] === "Total Spend" ||
              record["channel-campaign"] === TotalLeadValueBudget ? (
                <span className="channel-text"> {text}</span>
              ) : (
                <span style={{ color: "#A9A9A9" }}> {text}</span>
              )}
            </Col>
          </Row>
        </div>
      ),
    };

    let quarterkey = {
      monthq1: "Quarter 1",
      monthq2: "Quarter 2",
      monthq3: "Quarter 3",
      monthq4: "Quarter 4",
    };
    let finalData = [];

    months
      .map((quarter) => {
        let keys = Object.keys(quarter);
        let quarterly = quarter[keys[0]].map((month) => {
          return {
            title: (
              <b style={{ display: "flex", alignItems: "center" }}>
                {month}{" "}
                {moment(month, "MMM").month() < moment().month() && (
                  <div style={{ marginLeft: 5 }}>
                    {" "}
                    <img
                      src={
                        budgetData?.lockedMonths?.includes(month)
                          ? lockBlackImg
                          : blueTickImg
                      }
                      alt="verified"
                      onClick={() => setlockBudgetMonth(month)}
                      style={{ cursor: "pointer" }}
                    />{" "}
                  </div>
                )}{" "}
              </b>
            ),
            dataIndex: `${month}`,
            key: `${month}`,
            type: `${quarter[keys[1]]}`,
            render: (text, record) => (
              <>
                <span
                  className={`${
                    record["channel-campaign"] === "Total Spend"
                      ? "total-spend-text"
                      : record["channel-campaign"] === TotalLeadValueBudget
                      ? "total-leads-text"
                      : ""
                  }`}
                >
                  <>
                    <div className="icon" style={{ position: "relative" }}>
                      {/* {record["channel-campaign"] !== TotalLeadValueBudget && dollarSign}
                                        {record["channel-campaign"] === "Total Spend" &&
                                            !dollarSign &&
                                            "$"} */}
                      {record.children ||
                      record.isChannel ||
                      record["channel-campaign"] === "Total Spend" ||
                      record["channel-campaign"] === TotalLeadValueBudget ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            paddingLeft: "0px",
                          }}
                        >
                          {" "}
                          {record["channel-campaign"] !== TotalLeadValueBudget &&
                            dollarSign}
                          {record["channel-campaign"] === "Total Spend" &&
                            !dollarSign &&
                            "$"}
                          {record["channel-campaign"] === TotalLeadValueBudget &&
                            text.projectedLeads}
                          {record["channel-campaign"] === "Total Spend" &&
                            text.projected}
                          {record["channel-campaign"] !== "Total Spend" &&
                            record["channel-campaign"] !== TotalLeadValueBudget &&
                            numberWithCommas(
                              budgetView ? text.projected : text.projectedLeads
                            )}
                        </div>
                      ) : (
                        <InputNumber
                          step={budgetView && "0.01"}
                          stringMode={budgetView}
                          size="large"
                          formatter={(value) => {
                            if (!isNaN(value)) {
                              return `${budgetView ? "$" : ""}${
                                budgetView ? value : Math.floor(value)
                              }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                          }}
                          style={{
                            width: "100%",
                            color: "black",
                            marginTop: 5,
                            border: "none",
                            background: "#fff",
                          }}
                          value={Number(
                            budgetView ? text.projected : text.projectedLeads
                          )}
                          disabled={
                            record.children ||
                            record.isChannel ||
                            record["channel-campaign"] === "Total Spend" ||
                            record["channel-campaign"] === TotalLeadValueBudget ||
                            budgetData?.lockedMonths?.includes(month)
                          }
                          onChange={(value) =>
                            EditTableCell(record, month, value)
                          }
                        />
                      )}
                    </div>
                  </>
                </span>

                {!searchText && record["channel-campaign"] === "Total Spend" && (
                  <span
                    className="campaign-total-amount-diff"
                    style={{ display: "block" }}
                  >
                    {
                      <Statistic
                        value={getNumberDifference(
                          budgetAllocationDetails?.[keys[0]] / 3,
                          text["projected"]
                        )?.toLocaleString()}
                        prefix={`${
                          !getNumberDifferenceIfUnderBudget(
                            budgetAllocationDetails?.[keys[0]] / 3,
                            text["projected"]
                          )
                            ? "("
                            : "$"
                        }`}
                        suffix={`${
                          !getNumberDifferenceIfUnderBudget(
                            budgetAllocationDetails?.[keys[0]] / 3,
                            text["projected"]
                          )
                            ? ")"
                            : ""
                        }`}
                        className={getNumberDifferenceClass(
                          budgetAllocationDetails?.[keys[0]] / 3,
                          text["projected"]
                        )}
                      />
                    }
                  </span>
                )}
                {!searchText && record["channel-campaign"] === TotalLeadValueBudget && (
                  <span
                    className="campaign-total-amount-diff"
                    style={{ display: "block" }}
                  >
                    {
                      <Statistic
                        value={getNumberDifference(
                          ProspectData?.[keys[0]] / 3,
                          text["projected"]
                        )?.toLocaleString()}
                        prefix={`${
                          getNumberDifferenceIfUnderBudget(
                            ProspectData?.[keys[0]] / 3,
                            text["projected"]
                          )
                            ? "("
                            : ""
                        }`}
                        suffix={`${
                          getNumberDifferenceIfUnderBudget(
                            ProspectData?.[keys[0]] / 3,
                            text["projected"]
                          )
                            ? ")"
                            : ""
                        }`}
                        className={getNumberDifferenceClassForLeads(
                          ProspectData?.[keys[0]] / 3,
                          text["projected"]
                        )}
                      />
                    }
                  </span>
                )}
              </>
            ),
          };
        });
        let q = {
          title: (record) => (
            <div
              className="selected-column"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {quarters.includes(quarter["type"]) ? (
                <b>{String(quarter["name"]).toUpperCase()} </b>
              ) : (
                <b>{`${String(quarter["name"]).toUpperCase()} Totals`}</b>
              )}
              <span className="table-header-img">
                {quarters.includes(quarter["type"]) ? (
                  <Tooltip title="More">
                    <img
                      src={expandImg}
                      onClick={() => handleShowMonths(quarter["type"])}
                      className="primary-color cursor-pointer table-header-img-icon"
                      alt=""
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Less">
                    <img
                      src={collpaseImg}
                      onClick={() => handleShowMonths(quarter["type"])}
                      className="primary-color cursor-pointer table-header-img-icon"
                      alt=""
                    />
                  </Tooltip>
                )}
              </span>

              {quarter["name"].split("q")[1] <= moment().quarter() &&
                budgetData?.lockedMonths &&
                budgetData?.lockedMonths?.months?.includes("Jan") && (
                  <div
                    className="small-indicator"
                    style={{ marginLeft: 5 }}
                  ></div>
                )}
            </div>
          ),
          dataIndex: quarter["name"],
          key: quarter["name"],
          render: (text, record) => (
            <>
              <span
                className={`${
                  record["channel-campaign"] === "Total Spend"
                    ? "total-spend-text"
                    : record["channel-campaign"] === TotalLeadValueBudget
                    ? "total-leads-text"
                    : ""
                }`}
              >
                <>
                  <div className="icon" style={{ position: "relative" }}>
                    {record.children ||
                    record.isChannel ||
                    record["channel-campaign"] === "Total Spend" ||
                    record["channel-campaign"] === TotalLeadValueBudget ? (
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          paddingLeft: "0px",
                        }}
                      >
                        {" "}
                        {record["channel-campaign"] !== TotalLeadValueBudget &&
                          dollarSign}
                        {record["channel-campaign"] === "Total Spend" &&
                          !dollarSign &&
                          "$"}
                        {record["channel-campaign"] === TotalLeadValueBudget &&
                          text.projectedLeads}
                        {record["channel-campaign"] === "Total Spend" &&
                          text.projected}
                        {record["channel-campaign"] !== "Total Spend" &&
                          record["channel-campaign"] !== TotalLeadValueBudget &&
                          numberWithCommas(
                            budgetView ? text.projected : text.projectedLeads
                          )}
                      </div>
                    ) : (
                      <InputNumber
                        step={budgetView ? "0.01" : ""}
                        stringMode={budgetView}
                        size="large"
                        formatter={(value) => {
                          if (!isNaN(value)) {
                            return `${budgetView ? "$" : ""}${
                              budgetView ? value : Math.floor(value)
                            }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                          }
                        }}
                        style={{
                          width: "100%",
                          color: "black",
                          marginTop: 5,
                          border: "none",
                          background: "#fff",
                        }}
                        value={Number(
                          budgetView ? text.projected : text.projectedLeads
                        )}
                        disabled={
                          record.children ||
                          record.isChannel ||
                          record["channel-campaign"] === "Total Spend" ||
                          record["channel-campaign"] === TotalLeadValueBudget
                        }
                        onChange={(value) =>
                          EditTableCell(record, quarter["name"], value)
                        }
                      />
                    )}
                  </div>
                </>
              </span>

              {!searchText && record["channel-campaign"] === "Total Spend" && (
                <span
                  className="campaign-total-amount-diff"
                  style={{ display: "block" }}
                >
                  {
                    <Statistic
                      value={getNumberDifference(
                        budgetAllocationDetails?.[keys[0]],
                        text["projected"]
                      )?.toLocaleString()}
                      prefix={`${
                        !getNumberDifferenceIfUnderBudget(
                          budgetAllocationDetails?.[keys[0]],
                          text["projected"]
                        )
                          ? "("
                          : "$"
                      }`}
                      suffix={`${
                        !getNumberDifferenceIfUnderBudget(
                          budgetAllocationDetails?.[keys[0]],
                          text["projected"]
                        )
                          ? ")"
                          : ""
                      }`}
                      className={getNumberDifferenceClass(
                        budgetAllocationDetails?.[keys[0]],
                        text["projected"]
                      )}
                    />
                  }
                </span>
              )}
              {!searchText && record["channel-campaign"] === TotalLeadValueBudget && (
                <span
                  className="campaign-total-amount-diff"
                  style={{ display: "block" }}
                >
                  {
                    <Statistic
                      value={getNumberDifference(
                        ProspectData?.[keys[0]],
                        text["projected"]
                      )?.toLocaleString()}
                      prefix={`${
                        getNumberDifferenceIfUnderBudget(
                          ProspectData?.[keys[0]],
                          text["projected"]
                        )
                          ? "("
                          : ""
                      }`}
                      suffix={`${
                        getNumberDifferenceIfUnderBudget(
                          ProspectData?.[keys[0]],
                          text["projected"]
                        )
                          ? ")"
                          : ""
                      }`}
                      className={getNumberDifferenceClassForLeads(
                        ProspectData?.[keys[0]],
                        text["projected"]
                      )}
                    />
                  }
                </span>
              )}
            </>
          ),
        };
        quarterly.push(q);
        return quarterly;
      })
      .map((item) => {
        finalData.push([
          [quarterkey[item[0]?.type]],
          [Channel, ...item, fyTotals, cpl],
        ]);
      });
    setPrintTableColumnData(finalData);

    setQuarters(selectedQuarter);
    const onLoadColumn = columns.filter(
      (columndata) => !quarters.includes(columndata.type)
    );
    let q = [
      "q1",
      "q2",
      "q3",
      "q4",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let a = onLoadColumn.filter((row) => {
      if (q.includes(row.dataIndex) && selectedQuarters) {
        if (
          selectedQuarters["Q1"] &&
          (row.dataIndex === "q1" ||
            row.dataIndex === "Jan" ||
            row.dataIndex === "Feb" ||
            row.dataIndex === "Mar") &&
          budget_allocation["budget_q1"] > 0
        ) {
          return true;
        }
        if (
          selectedQuarters["Q2"] &&
          (row.dataIndex === "q2" ||
            row.dataIndex === "Apr" ||
            row.dataIndex === "May" ||
            row.dataIndex === "Jun") &&
          budget_allocation["budget_q2"] > 0
        ) {
          return true;
        }
        if (
          selectedQuarters["Q3"] &&
          (row.dataIndex === "q3" ||
            row.dataIndex === "Jul" ||
            row.dataIndex === "Aug" ||
            row.dataIndex === "Sep") &&
          budget_allocation["budget_q3"] > 0
        ) {
          return true;
        }
        if (
          selectedQuarters["Q4"] &&
          (row.dataIndex === "q4" ||
            row.dataIndex === "Oct" ||
            row.dataIndex === "Nov" ||
            row.dataIndex === "Dec") &&
          budget_allocation["budget_q4"] > 0
        ) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    });
    setcolumnData(a);
    // eslint-disable-next-line
  }, [
    searchText,
    quarters,
    selectedQuarter,
    BudgetProfileListDetails,
    props.selectedBudgetDetails,
    props.showActuals,
    viewTotalsBackup,
  ]);

  // OPEN QUARTER IN TABLE  TO SHOW MONTHLY DATA
  const handleShowMonths = (quarter) => {
    if (quarters.includes(quarter)) {
      setselectedQuarter(BasicQuarter.filter((item) => item !== quarter));
    } else {
      setselectedQuarter([
        ...selectedQuarter,
        ...BasicQuarter.filter((item) => item === quarter),
      ]);
    }
  };

  // HANDLE SEARCH TEXT
  useEffect(() => {
    if (searchText?.length > 0) {
      setExpandedRowKeys(
        !props.searchText ? undefined : CampaignTableData.map((i) => i.key)
      );
      let tableBackup = JSON.parse(JSON.stringify(CampaignTableDataBackup));
      let data = tableBackup
        .filter(
          (item) =>
            item["channel-campaign"] !== "Total Spend" &&
            item["channel-campaign"] !== TotalLeadValueBudget
        )
        ?.map((item) => {
          let campaign = [];
          if (item?.children?.length > 0) {
            campaign = item?.children?.filter((data) =>
              String(data?.["channel-campaign"])
                .toLowerCase()
                .includes(String(searchText).toLowerCase())
            );
            item.children = campaign;
          }
          if (
            campaign?.length > 0 ||
            String(item["channel-campaign"])
              .toLocaleLowerCase()
              .includes(String(searchText).toLocaleLowerCase())
          )
            return item;
        })
        .filter((i) => i);

      setCampaignTableData((pre) =>
        data.length > 0 ? getTotalsFormatted(data,nameOfLeadObj) : []
      );
    } else {
      setCampaignTableData(CampaignTableDataBackup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, CampaignTableDataBackup]);

  // TO GET MATCHED CHANNEL FOR SELECTED BUDGET
  const getData = (record) => {
    // return CampaignList.find((element) => element.id === record.id);
    return budgetData?.campaigns?.items?.find(
      (item) => item?.campaign?.id === record?.id
    )?.campaign;
  };

  // 0$ CAMPAIGN DATA HANDLE
  useEffect(() => {
    setTableData(viewTotals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCampaignWithZero]);

  // HANDLING DELETE AND EDIT AND COPY :BEGIN
  const deleteSelectedChannel = () => {
    setIsDeleteChannelLoading(true);
    dispatch(deleteCampaignChannel({ id: channelToDelete.id }))
      .then(() => {
        setIsDeleteChannelLoading(false);
        setIsDeleteChannelModalOpen(false);
        message.success("Channel deleted successfully!");
      })
      .catch((err) => {
        console.error(err);
        setIsDeleteChannelLoading(false);
        setIsDeleteChannelModalOpen(false);
        message.error("Something went wrong!");
      });
  };

  const handleCampaignAddWithChannelClick = (record) => {
    if (record) {
      setChannelIdForCampaign(record.channelId);
      setIsCampaignModalAddForChannelOpen(true);
    } else {
      message.error("Something went wrong!");
    }
  };

  const handleEditClick = (record) => {
    const data = getData(record);
    if (data) {
      data.associatedBudgets = BudgetProfileListDetails.filter(
        (profile) =>
          profile.campaigns.items.filter((i) => i.campaign.id === record.id)
            .length > 0
      );
      setCampaignToEdit(data);
      setChannelIdForCampaign(data.channel);
      setIsEditModalOpen(true);
    } else {
      message.error("Something went wrong!");
    }
  };

  const HandleCreateCampaign = (value, budgetProfiles, showMessage = false) => {
    delete value["id"];
    return dispatch(createCampaign(value, budgetProfiles, showMessage));
  };

  const HandlecreateCampaignDetails = (inputData) => {
    return dispatch(createCampaignDetails(inputData));
  };

  const HandleCreateCampaignMonthMultiple = (inputData) => {
    return Promise.all(
      inputData.map((item) => {
        return dispatch(createCampaignMonth(item));
      })
    );
  };
  // const [copyCampaignLoader, setcopyCampaignLoader] = useState(false)
  const HandleCopyCampaign = (record) => {
    const data = getData(record);
    if (data) {
      settableLoading(true);
      data.associatedBudgets = BudgetProfileListDetails.filter(
        (profile) =>
          profile.campaigns.items.filter((i) => i.campaign.id === record.id)
            .length > 0
      ).map((item) => item.id);

      let inputData = {
        account: account[0],
        name: `${data?.name} - copy`,
        channel: data?.channel,
      };

      HandleCreateCampaign(inputData, data.associatedBudgets)
        .then((campaign) => {
          let campaign_details = data?.campaign_details?.items?.[0];
          let inputDataForCampaignDetails = {
            account: account[0],
            campaign_id: campaign?.id,
            date_start: campaign_details?.budget_start,
            date_end: campaign_details?.budget_end,
          };

          HandlecreateCampaignDetails(inputDataForCampaignDetails)
            .then((campaignDetails) => {
              let InputDataForCampaignMonth = [];
              campaign_details?.campaign_months?.items?.map((month) => {
                let dataobj = {
                  account: account[0],
                  campaign_detail_id: campaignDetails?.id,
                  month: month?.month,
                  budget: month?.budget ? month?.budget : 0,
                  leads: month?.leads ? month?.leads : 0,
                  weight: month?.weight ? month?.weight : 0,
                  weight_lead: month?.weight_lead ? month?.weight_lead : 0,
                };
                InputDataForCampaignMonth.push(dataobj);
              });
              HandleCreateCampaignMonthMultiple(InputDataForCampaignMonth)
                .then((budget) => {
                  dispatch(getBudget(props.selectedValue));
                })
                .catch((error) => message.error("Something went wrong"));
            })
            .catch((error) => {
              console.log("Error In Create Campaign Details", error);
            });
        })
        .catch((error) => {
          console.log("Error In creating Campaign", error);
          message.error("Failed to create Campaign");
        })
        .then(() => {
          settableLoading(false);
        });
    } else {
      message.error("Something went wrong!");
    }
  };

  const [
    isCampaignModalAddForChannelOpen,
    setIsCampaignModalAddForChannelOpen,
  ] = useState(false);

  const deleteSelectedCampaign = async () => {
    try {
      setIsDeletCampaignLoading(true);
      // TODO: list all budgetcampaigns, get their id and delete them
      let nextToken = null;
      let budgetCampaigns = [];
      // list all budgetCampaigns
      do {
        const res = await ExploricApi.listBudgetCampaignApi(
          account[0],
          nextToken
        );
        budgetCampaigns.push(...res.data.listBudgetCampaigns.items);
        nextToken = res.data.listBudgetCampaigns.nextToken;
      } while (!!nextToken);

      budgetCampaigns = budgetCampaigns
        .filter(
          (budgetCampaign) => budgetCampaign.campaign.id === campaignToDelete.id
        )
        .filter((budgetCampaign) => {
          return (
            campaignToDelete.associatedBudgets.findIndex(
              (budget) => budget.id === budgetCampaign?.budget.id
            ) > -1
          );
        });
      // delete filtered budgetCampaigns
      // await Promise.all(
      //     budgetCampaigns.map((budgetCampaign) =>
      //         ExploricApi.deleteBudgetCampaignApi({id: budgetCampaign.id})
      //     )
      // );

      let campaign = budgetData?.campaigns?.items?.find(
        (item) => item?.campaign?.id === campaignToDelete?.id
      );
      if (campaign) {
        let campaignMonthsToDelete = [];
        let campaignDetailsToDelete = [];
        campaign?.campaign?.campaign_details.items?.map((campaign_details) => {
          campaignDetailsToDelete.push(campaign_details?.id);
          campaign_details?.campaign_months?.items?.forEach((month) => {
            campaignMonthsToDelete.push(month?.id);
          });
        });

        await Promise.all(
          campaignMonthsToDelete.map((month) =>
            dispatch(deleteCampaignMonth({ id: month }))
          )
        );
        await Promise.all(
          campaignDetailsToDelete.map((detail) =>
            dispatch(deleteCampaignDetails({ id: detail }))
          )
        );
        await Promise.all(
          budgetCampaigns.map((budgetCampaign) =>
            ExploricApi.deleteBudgetCampaignApi({ id: budgetCampaign.id })
          )
        );
        await dispatch(deleteCampaign({ id: campaignToDelete.id }));
        await dispatch(getBudget(props.selectedValue));
        message.success("Campaign Deleted Successfully");
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      console.error(72, err);
      message.error("Something went wrong");
    } finally {
      setCampaignToDelete(null);
      setIsDeletCampaignLoading(false);
      setIsCampaignDeleteModalOpen(false);
    }
  };

  const handleCancelCampign = () => {
    setIsEditModalOpen(false);
    setIsCampaignModalAddForChannelOpen(false);
  };

  const handleCancelChannel = () => {
    setIsRenameModalOpen(false);
    setIsCopyModalOpen(false);
  };

  // ACTUAL DATA FETCH HISTORY
  const [actualDataUpdateStatus, setactualDataUpdateStatus] = useState({});

  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateMaterializerSyncHistory)
    ).subscribe({
      next: ({ provider, value }) => {
        if (value?.data?.onCreateMaterializerSyncHistory) {
          let dataObj = {
            status:
              value?.data?.onCreateMaterializerSyncHistory?.status ===
                "REQUESTED" ||
              value?.data?.onCreateMaterializerSyncHistory?.status ===
                "UPDATING_ACTUALS" ||
              value?.data?.onCreateMaterializerSyncHistory?.status === "FAILED"
                ? "danger"
                : "success",
            message:
              value?.data?.onCreateMaterializerSyncHistory?.status === "DONE" ||
              value?.data?.onCreateMaterializerSyncHistory?.status === "FAILED"
                ? `Actuals Data Last Updated ${gettimeDiff(
                    new Date(),
                    new Date(
                      value?.data?.onCreateMaterializerSyncHistory?.createdAt
                    )
                  )} ago ${
                    value?.data?.onCreateMaterializerSyncHistory?.status ===
                    "FAILED"
                      ? "(Fetch Failed)"
                      : ""
                  }`
                : "Fetching Updated Actuals Data",
          };
          setactualDataUpdateStatus(dataObj);
          value?.data?.onCreateMaterializerSyncHistory?.status === "DONE" &&
            getActualSpendData();
        }
      },
      error: (error) => console.warn(error),
    });

    dispatch(getLastActualSync(account)).then((value) => {
      if (value?.data?.getMaterializerSyncHistoryByAccount?.items[0]) {
        let dataObj = {
          status:
            value?.data?.getMaterializerSyncHistoryByAccount?.items[0]
              ?.status === "REQUESTED" ||
            value?.data?.getMaterializerSyncHistoryByAccount?.items[0]
              ?.status === "UPDATING_ACTUALS" ||
            value?.data?.getMaterializerSyncHistoryByAccount?.items[0]
              ?.status === "FAILED"
              ? "danger"
              : "success",
          message:
            value?.data?.getMaterializerSyncHistoryByAccount?.items[0]
              ?.status === "DONE" ||
            value?.data?.getMaterializerSyncHistoryByAccount?.items[0]
              ?.status === "FAILED"
              ? `Actuals Data Last Updated ${gettimeDiff(
                  new Date(),
                  new Date(
                    value?.data?.getMaterializerSyncHistoryByAccount?.items[0]?.createdAt
                  )
                )} ago ${
                  value?.data?.getMaterializerSyncHistoryByAccount?.items[0]
                    ?.status === "FAILED"
                    ? "(Fetch Failed)"
                    : ""
                }`
              : "Fetching Updated Actuals Data",
        };
        setactualDataUpdateStatus(dataObj);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  const [lockBudgetMonth, setlockBudgetMonth] = useState("");
  const [modalBudgetView, setmodalBudgetView] = useState(true);

  const [totalVerified, settotalVerified] = useState(0);
  const [totalOriginal, settotalOriginal] = useState(0);

  const [totalActuals, settotalActuals] = useState(0);

  const [verifyCampaignTableData, setverifyCampaignTableData] = useState([]);
  const [verifyCampaignTableDataBackup, setverifyCampaignTableDataBackup] =
    useState([]);

  useEffect(() => {
    if (lockBudgetMonth?.length > 0) {
      let dataArray = [];
      let data = _.cloneDeep(CampaignTableData);
      data.map((item) => {
        if (
          !(
            item["channel-campaign"] === "Total Spend" ||
            item["channel-campaign"] === TotalLeadValueBudget
          )
        ) {
          let dataObj = {
            "channel-campaign": item?.["channel-campaign"],
            channelId: item?.channelId,
            isChannel: true,
            key: item?.channelId,
          };
          dataObj[lockBudgetMonth] = item[lockBudgetMonth];
          if (item?.children) {
            dataObj.children = item.children;
          }

          dataArray.push(dataObj);
        }
      });

      let sum = 0;
      let sumOriginal = 0;
      let sumActual = 0;
      dataArray.forEach((item) => {
        // sum +=item?.[lockBudgetMonth]?.verified_budget
        sum += modalBudgetView
          ? item?.[lockBudgetMonth]?.verified_budget
          : item?.[lockBudgetMonth]?.verified_leads;
        sumActual += modalBudgetView
          ? item?.[lockBudgetMonth]?.spend
          : item?.[lockBudgetMonth]?.actual;
        sumOriginal += modalBudgetView
          ? Number(parseFloat(item?.[lockBudgetMonth]?.projected).toFixed(2))
          : item?.[lockBudgetMonth]?.projectedLeads;
      });
      settotalVerified(Number(sum).toFixed(modalBudgetView ? 2 : 0));

      settotalOriginal(Number(sumOriginal).toFixed(modalBudgetView ? 2 : 0));

      settotalActuals(Number(sumActual).toFixed(modalBudgetView ? 2 : 0));

      setverifyCampaignTableData(dataArray);
      setverifyCampaignTableDataBackup(dataArray);
    }
    // eslint-disable-next-line
  }, [lockBudgetMonth, modalBudgetView]);

  useEffect(() => {
    let sum = 0;
    let sumActual = 0;
    let sumOriginal = 0;
    verifyCampaignTableData.forEach((item) => {
      sum += modalBudgetView
        ? item?.[lockBudgetMonth]?.verified_budget
        : item?.[lockBudgetMonth]?.verified_leads;
      sumOriginal += modalBudgetView
        ? item?.[lockBudgetMonth]?.projected
        : item?.[lockBudgetMonth]?.projectedLeads;
      sumActual += modalBudgetView
        ? item?.[lockBudgetMonth]?.spend
        : item?.[lockBudgetMonth]?.actual;
    });

    settotalVerified(Number(sum).toFixed(modalBudgetView ? 2 : 0));
    settotalOriginal(Number(sumOriginal).toFixed(modalBudgetView ? 2 : 0));
    settotalActuals(Number(sumActual).toFixed(modalBudgetView ? 2 : 0));
    // eslint-disable-next-line
  }, [modalBudgetView]);

  const setActualsToVerified = (record, data) => {
    let fieldToEdit = modalBudgetView ? "verified_budget" : "verified_leads";
    let dataToEdit = _.cloneDeep(verifyCampaignTableData);
    // record[lockBudgetMonth][fieldToEdit] = modalBudgetView ? record?.[lockBudgetMonth]?.actualAmount : record?.[lockBudgetMonth]?.actual
    dataToEdit.map((channel) => {
      if (record?.channelId === channel?.channelId) {
        channel?.children?.map((campaign) => {
          if (campaign?.id === record.id || record?.isChannel) {
            // check if month is editded
            campaign[lockBudgetMonth][fieldToEdit] = modalBudgetView
              ? campaign?.[lockBudgetMonth]?.actualRevenue
                ? campaign?.[lockBudgetMonth]?.actualRevenue
                : 0
              : campaign?.[lockBudgetMonth]?.actual
              ? campaign?.[lockBudgetMonth]?.actual
              : 0;
          }
        });
        // Calculations for channel
        let sumOfVerified = 0;
        channel?.children?.map((campaign) => {
          sumOfVerified += isNaN(campaign[lockBudgetMonth][fieldToEdit])
            ? 0
            : campaign[lockBudgetMonth][fieldToEdit];
        });
        channel[lockBudgetMonth][fieldToEdit] = sumOfVerified;
      }
    });

    let sum = 0;
    dataToEdit.forEach((item) => {
      sum += item?.[lockBudgetMonth]?.[fieldToEdit];
    });
    if (dataToEdit?.length > 0) {
      settotalVerified(sum);
      setverifyCampaignTableData(dataToEdit);
    }
  };

  const editVerifiedBudget = (record, name, value) => {
    let fieldToEdit = modalBudgetView ? "verified_budget" : "verified_leads";
    let dataToEdit = verifyCampaignTableData;
    dataToEdit.map((channel) => {
      if (record?.channelId === channel?.channelId) {
        channel?.children?.map((campaign) => {
          if (campaign?.id === record.id) {
            // check if month is editded
            if (months.includes(name)) {
              if (!isNaN(value) && value >= 0) {
                value = Number(parseFloat(value).toFixed(2));
                campaign[name][fieldToEdit] = value;
              }
            }
          }
        });
        // Calculations for channel
        let sumOfVerified = 0;
        channel?.children?.map((campaign) => {
          sumOfVerified += isNaN(campaign[name][fieldToEdit])
            ? 0
            : campaign[name][fieldToEdit];
        });
        channel[name][fieldToEdit] = sumOfVerified;
      }
    });

    let sum = 0;
    dataToEdit.forEach((item) => {
      sum += item?.[lockBudgetMonth]?.[fieldToEdit];
    });
    settotalVerified(sum);
    setverifyCampaignTableData(dataToEdit);
    // setViewTotal(dataToEdit)
  };

  useEffect(() => {
    settableLoadingParent(tableLoading);
    // eslint-disable-next-line
  }, [tableLoading]);

  const handleVerifyBudget = () => {
    let monthToEdit = {};
    budgetData?.campaigns?.items?.map((item) => {
      item?.campaign?.campaign_details?.items?.[0]?.campaign_months?.items?.map(
        (month) => {
          if (moment(month?.month, "M").format("MMM") === lockBudgetMonth) {
            monthToEdit[item.campaign.id] = month;
          }
        }
      );
    });

    let inputData = [];
    verifyCampaignTableData?.map((item) => {
      item?.children?.map((camp) => {
        if (monthToEdit[camp.id]) {
          let dataObj = {
            id: monthToEdit[camp.id]?.id,
            verified_budget: camp[lockBudgetMonth]?.verified_budget,
            verified_leads: camp[lockBudgetMonth]?.verified_leads,
          };
          inputData.push(dataObj);
        }
      });
    });
    dispatch(setLoader(true));
    Promise.all(inputData.map((item) => dispatch(updateCampaignMonth(item))))
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      })
      .then((res) => {
        dispatch(
          updateBudget({
            id: budgetData?.id,
            lockedMonths: budgetData?.lockedMonths
              ? budgetData?.lockedMonths?.includes(lockBudgetMonth)
                ? budgetData?.lockedMonths
                : [...budgetData?.lockedMonths, lockBudgetMonth]
              : [lockBudgetMonth],
          })
        )
          .then((res) => {
            dispatch(getBudget(budgetData?.id));
            setlockBudgetMonth("");
            dispatch(setLoader(false));
          })
          .catch((err) => {
            console.log(err);
            dispatch(setLoader(false));
          });
      });
  };

  const [CSVHeaders, setCSVHeaders] = useState([]);
  useEffect(() => {
    let data = [
      { label: "Campaign name", key: "channel-campaign" },
      { label: "Channel", key: "name" },
    ];
    months.forEach((month) => {
      data.push({ label: `${month}(Budget)`, key: `${month}.projected` });
      data.push({ label: `${month}(Leads)`, key: `${month}.projectedLeads` });
    });
    Object.keys(quartersAndMonths).forEach((key) => {
      data.push({
        label: `${String(key).toUpperCase()}(Budget)`,
        key: `${key}.projected`,
      });
      data.push({
        label: `${String(key).toUpperCase()}(Leads)`,
        key: `${key}.projectedLeads`,
      });
    });

    data.push({ label: "FY-totals", key: "fy-totals.projected" });
    data.push({ label: "FY-totals(Leads)", key: "fy-totals.projectedLeads" });

    setCSVHeaders(data);
  }, [months, quartersAndMonths]);

  // SET COLUMN DATA FOR VERIFY BUDGET

  const [modalTableColumns, setmodalTableColumns] = useState([]);

  useEffect(() => {
    let modalTableColumnsData = [
      {
        title: <b>Channels</b>,
        dataIndex: "channel-campaign",
        key: "channel-campaign",
        width: 300,
        className: "",
        render: (text, record) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
            className={
              record.children ||
              record.isChannel ||
              record["channel-campaign"] === "Total Spend" ||
              record["channel-campaign"] === TotalLeadValueBudget
                ? "channel-text"
                : ""
            }
          >
            {text}
          </div>
        ),
      },
      {
        title: <b>Original Amount</b>,
        dataIndex: lockBudgetMonth,
        key: lockBudgetMonth,
        render: (text, record) => (
          <>
            <span
              className={`${
                record["channel-campaign"] === "Total Spend"
                  ? "total-spend-text"
                  : record["channel-campaign"] === TotalLeadValueBudget
                  ? "total-leads-text"
                  : ""
              }`}
            >
              <>
                <div className="icon" style={{ position: "relative" }}>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      paddingLeft: "0px",
                    }}
                  >
                    {record["channel-campaign"] !== TotalLeadValueBudget &&
                      (record["channel-campaign"] === "Total Spend" ||
                        modalBudgetView) &&
                      "$"}
                    {numberWithCommas(
                      modalBudgetView ? text?.projected : text?.projectedLeads
                    )}
                  </div>
                </div>
              </>
            </span>
          </>
        ),
      },
      {
        title: <b>Verified Amount</b>,
        dataIndex: lockBudgetMonth,
        key: lockBudgetMonth,
        render: (text, record) => (
          <>
            <span
              className={`${
                record["channel-campaign"] === "Total Spend"
                  ? "total-spend-text"
                  : record["channel-campaign"] === TotalLeadValueBudget
                  ? "total-leads-text"
                  : ""
              }`}
            >
              <>
                <div className="icon" style={{ position: "relative" }}>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      paddingLeft: "0px",
                    }}
                  >
                    <InputNumber
                      step={budgetView ? "0.01" : ""}
                      stringMode={budgetView}
                      size="large"
                      formatter={(value) => {
                        if (!isNaN(value)) {
                          return `${modalBudgetView ? "$" : ""}${
                            budgetView ? value : Math.floor(value)
                          }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                      }}
                      style={{
                        width: "100%",
                        color: "black",
                        marginTop: 5,
                        border: "none",
                        background: "#fff",
                      }}
                      value={Number(
                        modalBudgetView
                          ? text?.verified_budget
                          : text?.verified_leads
                      )}
                      disabled={
                        record.children ||
                        record.isChannel ||
                        record["channel-campaign"] === "Total Spend" ||
                        record["channel-campaign"] === TotalLeadValueBudget
                      }
                      onChange={(value) =>
                        editVerifiedBudget(record, lockBudgetMonth, value)
                      }
                    />
                  </div>
                </div>
              </>
            </span>
          </>
        ),
      },
    ];
    if (
      CampaignTableData?.[CampaignTableData?.length - 1]?.["fy-totals"]
        ?.actual > 0 ||
      CampaignTableData?.[CampaignTableData?.length - 1]?.["fy-totals"]
        ?.actualRevenue > 0 ||
      CampaignTableData?.[CampaignTableData?.length - 1]?.["fy-totals"]?.spend >
        0
    )
      modalTableColumnsData.push({
        title: <b>Actual Amount</b>,
        dataIndex: lockBudgetMonth,
        key: lockBudgetMonth,
        render: (text, record) => (
          <>
            <span
              className={`${
                record["channel-campaign"] === "Total Spend"
                  ? "total-spend-text"
                  : record["channel-campaign"] === TotalLeadValueBudget
                  ? "total-leads-text"
                  : ""
              }`}
            >
              <>
                <div className="icon" style={{ position: "relative" }}>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      paddingLeft: "0px",
                    }}
                  >
                    {record["channel-campaign"] !== TotalLeadValueBudget &&
                      (record["channel-campaign"] === "Total Spend" ||
                        modalBudgetView) &&
                      "$"}
                    {numberWithCommas(
                      modalBudgetView ? text?.actualRevenue : text?.actual
                    )}
                  </div>
                </div>
              </>
            </span>
          </>
        ),
      });

    if (
      CampaignTableData?.[CampaignTableData?.length - 1]?.["fy-totals"]
        ?.actual > 0 ||
      CampaignTableData?.[CampaignTableData?.length - 1]?.["fy-totals"]
        ?.actualRevenue > 0 ||
      CampaignTableData?.[CampaignTableData?.length - 1]?.["fy-totals"]?.spend >
        0
    )
      modalTableColumnsData.push({
        title: <b></b>,
        dataIndex: lockBudgetMonth,
        key: lockBudgetMonth,
        render: (text, record) => (
          <>
            <span
              className={`${
                record["channel-campaign"] === "Total Spend"
                  ? "total-spend-text"
                  : record["channel-campaign"] === TotalLeadValueBudget
                  ? "total-leads-text"
                  : ""
              }`}
            >
              <span
                style={{
                  textDecoration: "underline",
                  color: "#0f61db",
                  userSelect: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setActualsToVerified(record, verifyCampaignTableData);
                }}
              >
                Set Actuals To Verified
              </span>
            </span>
          </>
        ),
      });
    setmodalTableColumns(modalTableColumnsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    CampaignTableData,
    modalBudgetView,
    lockBudgetMonth,
    verifyCampaignTableDataBackup,
    verifyCampaignTableData,
  ]);

  const [monthToUnlock, setmonthToUnlock] = useState("");

  const unlockMonth = () => {
    let lockedMonths = _.cloneDeep(budgetData?.lockedMonths);
    try {
      lockedMonths.splice(lockedMonths.indexOf(monthToUnlock), 1);

      let inputData = {
        id: budgetData?.id,
        lockedMonths: lockedMonths,
      };
      setIsDeletCampaignLoading(true);
      dispatch(updateBudget(inputData))
        .then((res) => {
          setIsDeletCampaignLoading(false);
          setmonthToUnlock("");
        })
        .catch((err) => {
          console.log(err);
          setIsDeletCampaignLoading(false);
          message.error("Something went wrong.");
          setmonthToUnlock("");
        });
    } catch (error) {
      console.log(error);
    }
  };

  //   UPLOAD CAMPAIGNS DATA FUNCTIONALITY

  const [fileList, setFileList] = useState([]);

  const fileProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
    maxCount: 1,
    accept: ".csv",
  };

  const downloadSample = (e) => {
    fetch(e.target.href, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "4AMdemand - Budget Template.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleParseCSV = (data) => {
    Papa.parse(fileList[0], {
      header: true,
      skipEmptyLines: true,
      // transformHeader: header => header.toLowerCase().replace(/\W/g, "_"),
      complete: function (results) {
        if (ValidateCSV(results.data)) {
          let CSVCampaignData = results?.data?.map((item) => {
            let dataObj = {};
            dataObj.name = item?.["Campaign"];
            dataObj.Channel = item?.["Channel"];
            let monthlyData = {};
            months.forEach((month) => {
              monthlyData[month] = {
                budget: !isNaN(Number(item[`${month}(Budget)`]))
                  ? Number(item[`${month}(Budget)`])
                  : 0,
                lead: !isNaN(Number(item[`${month}(Leads)`]))
                  ? Number(item[`${month}(Leads)`])
                  : 0,
              };
            });
            dataObj.months = monthlyData;
            return dataObj;
          });
          let channelsToBeCreated = [];
          CSVCampaignData?.forEach((item) => {
            let Channel = ChannelList.find(
              (channel) => channel.name === item?.Channel
            );
            if (!Channel) {
              channelsToBeCreated.push({
                account: account[0],
                name: item?.Channel,
              });
            }
          });
          if (channelsToBeCreated?.length > 0) {
            handleCreateChannels(channelsToBeCreated).then(async (res) => {
              await dispatch(getChannelList())?.then((channels) => {
                addUploadedCampaigns(CSVCampaignData, channels);
              });
            });
          } else {
            addUploadedCampaigns(CSVCampaignData, ChannelList);
          }
        } else {
          message.error("CSV file is not valid");
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  };

  const ValidateCSV = (csvData) =>
    csvData?.length > 0 &&
    compareArrays(Object.keys(csvData?.[0]), CSVImportTemplateHeaders);

  const handleCreateChannels = async (channelsInputData) => {
    return Promise.all(
      channelsInputData?.map((item) => dispatch(createChannel(item, false)))
    );
  };

  const addUploadedCampaigns = async (CampaignData, channelData) => {
    setIsDeleteChannelLoading(true);

    // CEATE SINGLE CAMPAIGN
    let profileArray = [BudgetProfile?.id];

    CampaignData?.map(async (item, index) => {
      let Channel = channelData.find(
        (channel) => channel.name === item?.Channel
      );
      let campaignInputData = {
        account: account[0],
        name: item?.name,
        metadata: JSON.stringify({ tableEdited: false }),
      };
      if (Channel) {
        campaignInputData.channel = Channel?.id;
      }

      if (campaignInputData?.channel)
        HandleCreateCampaign(campaignInputData, profileArray, true)
          .then((campaign) => {
            let inputDataforCampaignDetails = {
              account: account[0],
              campaign_id: campaign?.id,
              // date_start : item?.campaign?.campaign_details?.items[0]?.date_start,
              // date_end : item?.campaign?.campaign_details?.items[0]?.date_end
            };

            HandlecreateCampaignDetails(inputDataforCampaignDetails)
              .then((campaignDetails) => {
                Object.keys(quartersAndMonths)?.map((quarter) => {
                  let budgetTotal = 0;
                  let leadsTotal = 0;
                  quartersAndMonths[quarter]?.map((month) => {
                    budgetTotal += item?.months?.[month]?.budget;
                    leadsTotal += item?.months?.[month]?.lead;
                  });

                  item[quarter] = { budget: budgetTotal, lead: leadsTotal };
                });

                let InputDataForCampaignMonth = [];
                Object.keys(item?.months)?.forEach((campaignMonth) => {
                  let dataobj = {
                    account: account[0],
                    campaign_detail_id: campaignDetails?.id,
                    month: Number(moment(campaignMonth, "MMM").format("M")),
                    budget: item?.months[campaignMonth]?.budget,
                    leads: item?.months[campaignMonth]?.lead,
                    weight: isNaN(
                      Number(
                        parseFloat(
                          (item?.months[campaignMonth]?.budget * 100) /
                            item?.[`q${moment(campaignMonth, "MMM").quarter()}`]
                              ?.budget
                        ).toFixed(2)
                      )
                    )
                      ? 0
                      : Number(
                          parseFloat(
                            (item?.months[campaignMonth]?.budget * 100) /
                              item?.[
                                `q${moment(campaignMonth, "MMM").quarter()}`
                              ]?.budget
                          ).toFixed(2)
                        ),
                    weight_lead: isNaN(
                      Number(
                        parseFloat(
                          (item?.months[campaignMonth]?.lead * 100) /
                            item?.[`q${moment(campaignMonth, "MMM").quarter()}`]
                              ?.lead
                        ).toFixed(2)
                      )
                    )
                      ? 0
                      : Number(
                          parseFloat(
                            (item?.months[campaignMonth]?.lead * 100) /
                              item?.[
                                `q${moment(campaignMonth, "MMM").quarter()}`
                              ]?.lead
                          ).toFixed(2)
                        ),
                  };
                  InputDataForCampaignMonth.push(dataobj);
                });
                Promise.all(
                  InputDataForCampaignMonth?.map((item) =>
                    HandleCreateCampaignMonth(item)
                  )
                )
                  .then((budget) => {
                    if (index === CampaignData?.length - 1) {
                      dispatch(getBudget(BudgetProfile?.id));
                      setuploadCsvModalOpen(false);
                      setFileList([]);
                      setIsDeleteChannelLoading(false);
                      message.success("CSV imported successfully.");
                    }
                  })
                  .catch((error) => {
                    console.log("Error In creating Campaign month", error);
                    message.error("Something went wrong");
                    setIsDeleteChannelLoading(false);
                  });
              })
              .catch((error) => {
                console.log("Error In Create Campaign Details", error);
                setIsDeleteChannelLoading(false);
              });
          })
          .catch((error) => {
            console.log("Error In creating Campaign", error);
            message.error("Failed to create Campaign");
            setIsDeleteChannelLoading(false);
          });
    });
    return Promise.resolve();
  };

  return (
    <>
      {!props.campaignPlanningEmpty ? (
        <>
          {selectedQuarters &&
          !selectedQuarters["Q1"] &&
          !selectedQuarters["Q2"] &&
          !selectedQuarters["Q3"] &&
          !selectedQuarters["Q4"] ? (
            <>
              <Col lg={24} md={24}>
                <Title
                  level={3}
                  style={{
                    color: "gray",
                    padding: 100,
                    textAlign: "center",
                    margin: 0,
                  }}
                  className="empty-funnel-section"
                >
                  Please select the quarter to see the details
                </Title>
              </Col>
            </>
          ) : (
            <>
              {/* <Switch checked={showCampaignWithZero} onChange={(e) => setshowCampaignWithZero(e)} /> HIDE 0$ CAMPAIGNS */}
              <CSVLink
                id="camaign-table-print-btn"
                style={{ display: "none" }}
                data={CSVdata}
                filename={`Demand Generation -${props?.selectedBudgetDetails?.name}-${props?.selectedBudgetDetails?.year}.csv`}
                headers={CSVHeaders}
              >
                Export CSV
              </CSVLink>
              {actualDataUpdateStatus?.status && (
                <div
                  style={{
                    marginBottom: 10,
                    display: "flex",
                    justifyContent: "end",
                    
                  }}
                >
                  <Typography.Text
                    type={actualDataUpdateStatus?.status}
                    style={{ fontWeight: "bold", color:'#12B76A' }}

                  >
                    {" "}
                    {actualDataUpdateStatus?.status === "success" ? (
                      <CheckOutlined />
                    ) : (
                      <SyncOutlined />
                    )}{" "}
                    {actualDataUpdateStatus?.message}{" "}
                  </Typography.Text>
                </div>
              )}

              <Table
                columns={columnData}
                className="campaign-table non-print general-table "
                rowClassName={`${(record, index) =>
                  record["channel-campaign"] === TotalLeadValueBudget ||
                  record["channel-campaign"] === "Total Spend"
                    ? "channel-name-font-style"
                    : ""} campaign-name-font-style campaign-table-row`}
                expandable={{
                  expandedRowKeys: !searchText ? undefined : expandedRowKeys,
                  rowExpandable: (record) => {
                    return record.children?.length > 0;
                  },
                  expandIcon: (props) => {
                    const style = {
                      marginTop: "2.5005px",
                      marginRight: "8px",
                      cursor: "pointer",
                      float: "right",
                      paddingTop: "6px",
                      display: "block",
                    };
                    if (!props?.record?.children) {
                      style.display = "none";
                    }
                    return props.expanded ? (
                      <span>
                        <img
                          style={style}
                          src={verticalCollapseImg}
                          onClick={(e) => props.onExpand(props.record, e)}
                          alt=""
                        />
                      </span>
                    ) : (
                      <span>
                        <img
                          style={style}
                          src={verticalExpandImg}
                          onClick={(e) => props.onExpand(props.record, e)}
                          alt=""
                        />
                      </span>
                    );
                  },
                  onExpand: (expanded, record) => {
                    if (!props.searchText) {
                      return;
                    }
                    setExpandedRowKeys(
                      expanded
                        ? [...expandedRowKeys, record.key]
                        : expandedRowKeys.filter((i) => i !== record.key)
                    );
                  },
                }}
                dataSource={CampaignTableData}
                pagination={false}
                bordered
                scroll={{ y: "68vh" }}
                // loading={tableLoading}
                // scroll={selectedQuarter.length < 4 ? { x: 100 } : { x: 0 }} Removed temporary
              />
              <div className="only-print" style={{ width: "100%" }}>
                {PrintTableColumnData &&
                  PrintTableColumnData.map((item, index) => {
                    return (
                      <div className="campaign-table-print" key={index}>
                        <h2 style={{ color: "#0F61DB", paddingLeft: 15 }}>
                          <b>{item[0]}</b>
                        </h2>
                        <Table
                          columns={item[1]}
                          className="campaign-table "
                          rowClassName={(record, index) =>
                            record["channel-campaign"] === TotalLeadValueBudget ||
                            record["channel-campaign"] === "Total Spend"
                              ? "channel-name-font-style"
                              : "campaign-name-font-style"
                          }
                          expandable={{
                            expandedRowKeys: PrintDataKeys,
                            rowExpandable: (record) => {
                              return record.children?.length > 0;
                            },
                            defaultExpandAllRows: true,
                            expandIcon: (props) => {
                              // need blank function otherwise default icon will show.
                            },
                          }}
                          dataSource={printTableData}
                          pagination={false}
                          bordered
                        />
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </>
      ) : (
        <ColumnEmptyState
          rows={CampaignTableData.slice(0, CampaignTableData?.length - 2)}
          handleCampaignAddWithChannelClick={handleCampaignAddWithChannelClick}
          userPermission={props.userPermission}
        />
      )}
      <MapCampaignDrawer
        selectedYear={selectedYear}
        isMapCampaignDrawerOpen={isMapCampaignDrawerOpen}
        campaignId={linkRecordId}
        linkRecordInfo={linkRecordInfo}
        setIsMapCampaignDrawerOpen={setIsMapCampaignDrawerOpen}
        account={account}
        ConnectedCRMs={ConnectedCRMs}
        dispatch={dispatch}
      />

      <Modal
        className="deleteCampaignModal"
        visible={isCampaignDeleteModalOpen}
        closable={false}
        centered
        footer={[
          <Button
            style={{
              height: "40px",
            }}
            type="secondary"
            key="cancel"
            onClick={() => setIsCampaignDeleteModalOpen(false)}
            disabled={isDeletCampaignLoading}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            size="large"
            loading={isDeletCampaignLoading}
            onClick={deleteSelectedCampaign}
          >
            Delete
          </Button>,
        ]}
      >
        <Typography.Title level={4}>
          Are you sure you'd like to delete this campaign?
        </Typography.Title>
        {campaignToDelete?.associatedBudgets.length > 1 && (
          <Checkbox checked>
            This campaign is connected to{" "}
            {campaignToDelete.associatedBudgets.length - 1} other budget(s).
          </Checkbox>
        )}
      </Modal>

      <Modal
        visible={Object.keys(CampaignToUpdateFromTable)?.length > 0}
        className="delete-budget-camp"
        closable={false}
        centered
        footer={[]}
        style={{ borderRadius: "20px" }}
      >
        <div className="delete-modal-body" style={{ textAlign: "center" }}>
          <img src={LeadsPreferenceImg} alt="" />
          <Typography.Title style={{ marginTop: 10 }} level={3}>
            {nameOfLeadObj} preference
          </Typography.Title>
          <Typography.Text style={{ marginTop: "0px", fontSize: 16 }}>
            Would you like to automatically modify your {nameOfLeadObj} to reflect these
            monthly budget changes, based on your projected CPL for the
            campaign?
          </Typography.Text>
          <div className="delete-modal-footer" style={{ marginTop: 20 }}>
            <Button
              size="large"
              type="primary"
              loading={tableLoading}
              onClick={() =>
                HandleUpdateCampaign(CampaignToUpdateFromTable, true, true)
              }
            >
              Yes
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() =>
                HandleUpdateCampaign(CampaignToUpdateFromTable, false, true)
              }
              disabled={tableLoading}
              ghost
            >
              No
            </Button>
          </div>
        </div>
      </Modal>

      {isRenameModalOpen && (
        <CreateChannel
          isEdit={true}
          visible={isRenameModalOpen}
          handleCancel={handleCancelChannel}
          channelObj={channelToRename}
          listOfYear = {listOfYear}

        />
      )}
      {isCopyModalOpen && (
        <CreateChannel
          isCopy={true}
          visible={isCopyModalOpen}
          handleCancel={handleCancelChannel}
          channelObj={channelToCopy}
          listOfYear={listOfYear}
        />
      )}
      {isEditModalOpen && (
        <CreateCampaign
          isEdit={true}
          visibleCreateCampaignDrawer={isEditModalOpen}
          handleCancelCampign={handleCancelCampign}
          campaignObj={campaignToEdit}
          channelIdForCampaign={channelIdForCampaign}
          year={budgetData?.year}
          selectedValue={props.selectedValue}
          showModal={props.showModal}
          budget={budgetData}
          nameOfLeadObj={nameOfLeadObj}
        />
      )}
      {isCampaignModalAddForChannelOpen && (
        <CreateCampaign
          visibleCreateCampaignDrawer={isCampaignModalAddForChannelOpen}
          handleCancelCampign={handleCancelCampign}
          channelIdForCampaign={channelIdForCampaign}
          selectedValue={props.selectedValue}
          year={budgetData?.year}
          budget={budgetData}
          showModal={props.showModal}
          nameOfLeadObj = {nameOfLeadObj}
        />
      )}
      {channelToDelete && (
        <Modal
          className="deleteCampaignModal"
          visible={isDeleteChannelModalOpen}
          closable={false}
          centered
          footer={[
            <Button
              style={{
                height: "40px",
              }}
              type="secondary"
              key="cancel"
              disabled={isDeleteChannelLoading}
              onClick={() => setIsDeleteChannelModalOpen(false)}
              className="tertiaryButton"
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={channelToDelete.children.length > 0}
              size="large"
              loading={isDeleteChannelLoading}
              onClick={deleteSelectedChannel}
              className="secondaryButton"
            >
              Delete
            </Button>,
          ]}
        >
          <Typography.Title level={4}>
            {channelToDelete.children.length > 0
              ? "There are campaigns still assigned to this channel. Please remove those campaigns before continuing."
              : "Are you sure you'd like to delete this channel?"}
          </Typography.Title>
          <Typography.Text className="delete-warning">
            {
              `Budgets affeted: ${listOfYear} Budget`
            }
          </Typography.Text>
        </Modal>
      )}

      <Modal
        visible={lockBudgetMonth?.length > 0}
        // visible={true}
        onCancel={() => setlockBudgetMonth("")}
        closable={true}
        centered
        footer={null}
        width={"80%"}
        style={{ borderRadius: "20px" }}
      >
        <div className="" style={{ textAlign: "center" }}>
          <Typography.Title style={{ marginTop: 10 }} level={5}>
            {lockBudgetMonth} {budgetData?.year}
          </Typography.Title>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: 10,
            }}
          >
            <div
              className="font-color-budget custom-tab"
              style={{ width: "50%" }}
            >
              <span
                onClick={() => setmodalBudgetView(true)}
                className={`${
                  modalBudgetView
                    ? "custom-selected-tab-selected"
                    : "custom-selected-tab"
                }`}
              >
                Budget
              </span>
              <span
                onClick={() => setmodalBudgetView(false)}
                style={{ marginLeft: 12 }}
                className={`${
                  modalBudgetView
                    ? "custom-selected-tab"
                    : "custom-selected-tab-selected"
                }`}
              >
                Leads
              </span>
            </div>
          </div>
          <Table
            style={{ marginTop: 20, width: "100%" }}
            pagination={false}
            className="campaign-table non-print"
            rowClassName={`campaign-name-font-style campaign-table-row`}
            scroll={{ y: "55vh" }}
            expandable={{
              expandedRowKeys: !searchText ? undefined : expandedRowKeys,
              rowExpandable: (record) => {
                return record.children?.length > 0;
              },
              expandIcon: (props) => {
                const style = {
                  marginTop: "2.5005px",
                  marginRight: "8px",
                  cursor: "pointer",
                  float: "right",
                  paddingTop: "6px",
                  display: "block",
                };
                if (!props.record.children) {
                  style.display = "none";
                }
                return props.expanded ? (
                  <span>
                    <img
                      style={style}
                      src={verticalCollapseImg}
                      onClick={(e) => props.onExpand(props.record, e)}
                      alt=""
                    />
                  </span>
                ) : (
                  <span>
                    <img
                      style={style}
                      src={verticalExpandImg}
                      onClick={(e) => props.onExpand(props.record, e)}
                      alt=""
                    />
                  </span>
                );
              },
              onExpand: (expanded, record) => {
                if (!props.searchText) {
                  return;
                }
                setExpandedRowKeys(
                  expanded
                    ? [...expandedRowKeys, record.key]
                    : expandedRowKeys.filter((i) => i !== record.key)
                );
              },
            }}
            dataSource={verifyCampaignTableData}
            columns={modalTableColumns}
            size="small"
            summary={() => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} className="channel-text">
                      Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      <Typography.Text>
                        {(modalBudgetView ? "$" : "") +
                          numberWithCommas(totalOriginal)}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <Typography.Text>
                        {(modalBudgetView ? "$" : "") +
                          numberWithCommas(totalVerified)}
                      </Typography.Text>
                    </Table.Summary.Cell>
                    {(CampaignTableData?.[CampaignTableData?.length - 1]?.[
                      "fy-totals"
                    ]?.actual > 0 ||
                      CampaignTableData?.[CampaignTableData?.length - 1]?.[
                        "fy-totals"
                      ]?.actualRevenue > 0 ||
                      CampaignTableData?.[CampaignTableData?.length - 1]?.[
                        "fy-totals"
                      ]?.spend > 0) && (
                      <Table.Summary.Cell index={2}>
                        <Typography.Text>
                          {(modalBudgetView ? "$" : "") +
                            numberWithCommas(totalActuals)}
                        </Typography.Text>
                      </Table.Summary.Cell>
                    )}
                  </Table.Summary.Row>
                </>
              );
            }}
          />

          <Button
            type="primary"
            style={{ marginTop: 10 }}
            loading={isLoading}
            onClick={handleVerifyBudget}
          >
            Save
          </Button>

          <Typography.Paragraph style={{ marginTop: 10 }}>
            This will create a budget{" "}
            {totalOriginal - totalVerified < 0 ? "overage" : "shortfall"} of{" "}
            {modalBudgetView ? "$" : ""}
            {Math.abs(totalOriginal - totalVerified)}{" "}
            {modalBudgetView ? "" : "leads"}. Please update your plan to reflect
            that.
          </Typography.Paragraph>
        </div>
      </Modal>

      {monthToUnlock?.length > 0 && (
        <Modal
          className="deleteCampaignModal"
          visible={true}
          closable={false}
          centered
          footer={[
            <Button
              style={{
                height: "40px",
              }}
              type="secondary"
              key="cancel"
              disabled={isDeletCampaignLoading}
              onClick={() => setmonthToUnlock("")}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={isDeletCampaignLoading}
              size="large"
              loading={isDeletCampaignLoading}
              onClick={unlockMonth}
            >
              Unlock
            </Button>,
          ]}
        >
          <Typography.Title
            level={4}
            style={{ width: "100%", textAlign: "center" }}
          >
            Are you sure to unlock {monthToUnlock} month of budget{" "}
            {budgetData?.name}?
          </Typography.Title>
        </Modal>
      )}

      <Modal
        className="deleteCampaignModal"
        visible={uploadCsvModalOpen}
        closable={true}
        onCancel={() => setuploadCsvModalOpen(false)}
        centered
        footer={null}
      >
        <Typography.Title style={{ textAlign: "center" }} level={4}>
          Import CSV file to populate budget{" "}
        </Typography.Title>
        <Row>
          <Col span={8}>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                justifyContent: "center",
                padding: 20,
                backgroundColor: "#E9F2FF",
              }}
            >
              <Space direction="vertical">
                <FileTextOutlined style={{ fontSize: 24 }} />
                <Typography.Text>
                  CSV should be in specific format. Please check the sample CSV
                  format
                </Typography.Text>

                <a
                  href={BudgetTemplateS3url}
                  onClick={(e) => downloadSample(e)}
                >
                  Dowload Sample
                </a>
              </Space>
            </div>
          </Col>
          <Col
            span={16}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{ height: "100%", display: "flex", alignItems: "center" }}
            >
              <Upload {...fileProps}>
                <Button
                  icon={<UploadOutlined />}
                  disabled={isDeleteChannelLoading}
                >
                  Select File
                </Button>
              </Upload>
            </div>
            <Tooltip
              title={fileList.length === 0 ? "Please select CSV file" : ""}
            >
              <Button
                loading={isDeleteChannelLoading}
                onClick={handleParseCSV}
                disabled={fileList.length === 0}
                style={{
                  marginTop: 16,
                }}
              >
                upload
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default BudgetLeadsViewNew;
