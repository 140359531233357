export const numberWithCommas = (x) => {
  if (isNaN(x)) return 0;
  let numberTobeConverted = x || 0;
  numberTobeConverted = Math.round(numberTobeConverted);
  return numberTobeConverted.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const numberWithCommasAndRemovedNoDecimals = (x) => {
  if (isNaN(x)) return 0;
  let numberTobeConverted = x || 0;
  numberTobeConverted = Math.round(numberTobeConverted);
  return numberTobeConverted.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const convertReverseArray = (arr) => {
  let newPlotData = [];
  arr &&
    arr.length > 0 &&
    arr.forEach((element, index) => {
      let obj = { ...arr[arr.length - 1 - index] };
      newPlotData.push(obj);
    });
  return [...newPlotData];
};


let previousData = {}
export const getFunnelCountsValue = (value, conversationRate, isFirst = false) => {
  let perQuarterMarketingLeads = 0
  if (isFirst) {
    perQuarterMarketingLeads = parseInt(value / 4)
    
    let quarterlyMarketingClosedWonGoal = {
      q1: perQuarterMarketingLeads,
      q2: perQuarterMarketingLeads,
      q3: perQuarterMarketingLeads,
      q4: perQuarterMarketingLeads,
    };
    let totalMarketingClosedWon =
      quarterlyMarketingClosedWonGoal["q1"] +
      quarterlyMarketingClosedWonGoal["q2"] +
      quarterlyMarketingClosedWonGoal["q3"] +
      quarterlyMarketingClosedWonGoal["q4"];
    if (totalMarketingClosedWon !== value) {
      let diff = Math.abs(value - totalMarketingClosedWon);
      if (diff > 0) {
        quarterlyMarketingClosedWonGoal["q4"] += diff;
      }
    }

    previousData = {
        qtr1: quarterlyMarketingClosedWonGoal["q1"] * 100 / conversationRate,
        qtr2: quarterlyMarketingClosedWonGoal["q2"] * 100 / conversationRate,
        qtr3: quarterlyMarketingClosedWonGoal["q3"] * 100 / conversationRate,
        qtr4: quarterlyMarketingClosedWonGoal["q4"] * 100 / conversationRate,
      }
  } else {
    previousData = {
        qtr1: previousData["qtr1"] * 100 / conversationRate,
        qtr2: previousData["qtr2"] * 100 / conversationRate,
        qtr3: previousData["qtr3"] * 100 / conversationRate,
        qtr4: previousData["qtr4"] * 100 / conversationRate,
      }
  }
  let finalValue =
    Math.round(previousData["qtr1"] ) +
    Math.round(previousData["qtr2"] ) +
    Math.round(previousData["qtr3"] ) +
    Math.round(previousData["qtr4"] ) 
  return finalValue
  
}

export const nFormatter = (x) => {
  if (isNaN(x)) return x;
  x = Number(parseFloat(x).toFixed(2))
  if (x < 9999) {
    return numberWithCommas(x);
  }

  if (x < 1000000) {
    return (x / 1000).toFixed(2) + "K";
  }
  if (x < 10000000) {
    return (x / 1000000).toFixed(2) + "M";
  }

  if (x < 1000000000) {
    return Math.round(x / 1000000) + "M";
  }

  if (x < 1000000000000) {
    return Math.round(x / 1000000000) + "B";
  }

  return "1T+";
};

export const getTotalsFormatted = (grouped, nameOfLeadObj) => {
  let TotalLeadValue = `Total ${nameOfLeadObj}`
    let dataObj = {}
    const quartersAndMonths = {
      "q1": ['Jan', 'Feb', 'Mar'],
      "q2": ['Apr', 'May', 'Jun'],
      "q3": ['Jul', 'Aug', 'Sep'],
      "q4": ['Oct', 'Nov', 'Dec']
  }

  grouped.forEach(value => {
    Object.keys(quartersAndMonths).forEach(quarter => {

      quartersAndMonths[quarter]?.forEach(month => {
        dataObj[month] = {
          'projected': Number(parseFloat((dataObj[month]?.projected ? dataObj[month]?.projected : 0) + value?.[month].projected).toFixed(2)),
          'projectedLeads': Number(parseFloat((dataObj[month]?.projectedLeads ? dataObj[month]?.projectedLeads : 0) + value?.[month].projectedLeads).toFixed(2)),
          'actual': (dataObj[month]?.['actual'] ? dataObj[month]?.['actual'] : 0) + value?.[month].actual,
          'actualRevenue': (dataObj[month]?.['actualRevenue'] ? dataObj[month]?.['actualRevenue'] : 0) + value?.[month].actualRevenue,
          'spend': (dataObj[month]?.['spend'] ? dataObj[month]?.['spend'] : 0) + value?.[month].spend,
          'verified_budget': (dataObj[month]?.['verified_budget'] ? dataObj[month]?.['verified_budget'] : 0) + value?.[month].verified_budget,
      }
      })
      dataObj[quarter] = {
          'projected': Number(parseFloat((dataObj[quarter]?.projected ? dataObj[quarter]?.projected : 0) + value?.[quarter].projected).toFixed(2)),
          'projectedLeads': Number(parseFloat((dataObj[quarter]?.projectedLeads ? dataObj[quarter]?.projectedLeads : 0) + value?.[quarter].projectedLeads).toFixed(2)),
          'actual': (dataObj[quarter]?.['actual'] ? dataObj[quarter]?.['actual'] : 0) + value?.[quarter].actual,
          'actualRevenue': (dataObj[quarter]?.['actualRevenue'] ? dataObj[quarter]?.['actualRevenue'] : 0) + value?.[quarter].actualRevenue,
          'spend': (dataObj[quarter]?.['spend'] ? dataObj[quarter]?.['spend'] : 0) + value?.[quarter].spend,
          'verified_budget': (dataObj[quarter]?.['verified_budget'] ? dataObj[quarter]?.['verified_budget'] : 0) + value?.[quarter].verified_budget,
      }
  })
  dataObj['fy-totals'] = {
        'projected': Number(parseFloat((dataObj[`fy-totals`]?.projected ? dataObj[`fy-totals`]?.projected : 0) + + value["fy-totals"]['projected']).toFixed(2)),
        'projectedLeads': Number(parseFloat((dataObj[`fy-totals`]?.projectedLeads ? dataObj[`fy-totals`]?.projectedLeads : 0) + value["fy-totals"]['projectedLeads']).toFixed(2)),
        'actual': (dataObj[`fy-totals`]?.['actual'] ? dataObj[`fy-totals`]?.['actual'] : 0) +value["fy-totals"]['actual'],
        'actualRevenue': (dataObj[`fy-totals`]?.['actualRevenue'] ? dataObj[`fy-totals`]?.['actualRevenue'] : 0) + value?.["fy-totals"].actualRevenue,
        'spend': (dataObj[`fy-totals`]?.['spend'] ? dataObj[`fy-totals`]?.['spend'] : 0) + value?.["fy-totals"].spend,
        'verified_budget': (dataObj[`fy-totals`]?.['verified_budget'] ? dataObj[`fy-totals`]?.['verified_budget'] : 0) + value?.["fy-totals"].verified_budget,
}
  dataObj["fy-totals-actual"] = isFinite(value["fy-totals"]?.actual) ? value["fy-totals"]?.actual : 0;
  dataObj["actualLead"] = isFinite(dataObj["actualLead"]) ? dataObj['actualLead'] + value['actualLead'] : value['actualLead']
  dataObj["actualRevenue"] = isFinite(dataObj["actualRevenue"]) ? dataObj["actualRevenue"] + value['actualRevenue'] : 0
  dataObj["totalspend"] = isFinite(dataObj["totalspend"]) ? dataObj["totalspend"] + value['totalspend'] : value['totalspend']
});
  dataObj['cpl'] =  isFinite(parseFloat(dataObj['fy-totals'].projected / dataObj['fy-totals'].projectedLeads).toFixed(2)) ? parseFloat(dataObj['fy-totals'].projected / dataObj['fy-totals'].projectedLeads).toFixed(2) : 0


  grouped.push({...dataObj, 'channel-campaign' :"Total Spend", 'key' :"Total Spend"})
  grouped.push({...dataObj, 'channel-campaign' :TotalLeadValue, 'key' :TotalLeadValue})

  return grouped

};

export const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
};

export const getNumberDifference = (a, b) => {
  let diff = parseInt(a) - parseInt(b);

  if (diff) {
    return Math.abs(diff);
  } else {
    return 0;
  }
};
export const getNumberDifferenceClass = (a, b) => {
  let diff = parseInt(a) - parseInt(b);
  if (Math.sign(diff) === 1) {
    return `under-budget`;
  } else if (Math.sign(diff) === -1) {
    return `over-budget`;
  } else {
    return "";
  }
};
export const getNumberDifferenceClassForLeads = (a, b) => {
  let diff = parseInt(a) - parseInt(b);
  if (Math.sign(diff) === 1) {
    return `over-budget`;
  } else if (Math.sign(diff) === -1) {
    return `under-budget`;
  } else {
    return "";
  }
};
export const getNumberDifferenceIfUnderBudget = (a, b) => {
  let diff = parseInt(a) - parseInt(b);
  if (Math.sign(diff) === 1) {
    return true;
  } else if (Math.sign(diff) === -1) {
    return false;
  } else {
    return "";
  }
};


export const  secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay + sDisplay; 
}

export const GetSortOrder = (prop) => {    
  return (a, b) => {    
      if (a[prop] > b[prop]) {    
          return -1;    
      } else if (a[prop] < b[prop]) {    
          return 1;    
      }    
      return 0;    
  }    
} 


export const gettimeDiff = (curr, prev) => {
  var ms_Min = 60 * 1000; 
  var ms_Hour = ms_Min * 60;
  var ms_Day = ms_Hour * 24;
  var ms_Mon = ms_Day * 30; 
  var ms_Yr = ms_Day * 365; 
  var diff = curr - prev; 
  if (diff < ms_Min) {
      return Math.round(diff / 1000) + ' seconds';
  } else if (diff < ms_Hour) {
      return Math.round(diff / ms_Min) + ' minutes';
  } else if (diff < ms_Day) {
      return Math.round(diff / ms_Hour) + ' hours';
  } else if (diff < ms_Mon) {
      return 'Around ' + Math.round(diff / ms_Day) + ' days';
  } else if (diff < ms_Yr) {
      return 'Around ' + Math.round(diff / ms_Mon) + ' months';
  } else {
      return 'Around ' + Math.round(diff / ms_Yr) + ' years';
  }
}

export const toCapitalize  = (str) => String(str).substring(0,1).toUpperCase() + String(str).toLowerCase().substring(1)
export const capitalize  = (str) => { // for whole string with space
  const words = String(str).split(' ');
  const transformedWords = words.map(w => w.substring(0,1).toUpperCase() + w.toLowerCase().substring(1))
  return transformedWords.join(' ');
}

export const swapKeyValue = (json) => {
  var ret = {};
  for(var key in json){
    ret[json[key]] = key;
  }
  return ret;
}
export const removeDuplicateFromArray = (array) => {
  let unique = [];
array.forEach((c) => {
    if (!unique.includes(c)) {
      unique.push(c);
    }
});
return unique;

}

export const compareArrays = (arr1 = [],arr2 = []) => arr1?.length === arr2?.length && !Boolean(arr1?.filter(item => !arr2?.includes(item))?.length) && !Boolean(arr2?.filter(item => !arr1?.includes(item))?.length)

export const LightenDarkenColor = (col, amt) =>  {
  
  var usePound = false;

  if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
  }

  var num = parseInt(col,16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if  (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if  (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
}
